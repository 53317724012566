// Customizable Area Start
import React from "react";
import { Dialog, Typography, Grid } from "@material-ui/core";
import DeleteTrackController from "./DeleteTrackController";
import { Send } from "blocks/dashboard/src/CustomComponent/SharePlaylistPopup/assets";

export default class InviteLink extends DeleteTrackController {
  render() {
    const { isOpen, handleDMClose, title } = this.props;
    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={handleDMClose}
          className="CopiedModal"
          PaperProps={{ className: "dialog-delete-message-popup" }}
        >
          
              <Grid
                container
                style={{
                  justifyContent: "center",
                  padding: "10px",
                  alignItems: "center",
                  display:"flex",
                  flexDirection: "column",
                 
                }}
              >
                <div
                  onClick={() => {
                    handleDMClose();
                    this.props.handleDelete();
                  }}
                  style={{
                    flexDirection: "row",
                    height: 60,
                    display:"flex",
                    width:"100%",
                    cursor:"pointer",
                    backgroundColor: "#1e1e1e",
                    marginTop: 10,
                    borderRadius: 60,
                    alignItems: "center",
                  }}
                  data-test-id="invitelink-click"
                >
                  <div
                    style={{
                      backgroundColor: "#000",
                      marginLeft: 7,
                      height: 45,
                      width: 45,
                      alignItems: "center",
                      borderRadius: 45,
                      justifyContent: "center",
                    }}
                  >
                    <img src={Send} style={{width:"100%",height:"100%"}} />
                  </div>
                    <Typography
                      style={{
                        color: "#fff",
                        marginLeft: 20,
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      {title}
                    </Typography>
                </div>
              </Grid>
            
        </Dialog>
      </div>
    );
  }
}
// Customizable Area End
