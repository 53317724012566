export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");



//new banner for signup
export const loginBanner = require('../assets/LoginBackground.webp');
export const accountBanner = require('../assets/Account type background.webp');
export const createAccountBanner = require('../assets/Create Account Background.webp');
export const verifyOtpBanner = require('../assets/verify otp background.webp');
export const otpBg = require('../assets/verify otp bg.webp');
export const nxzborderlogo = require('../assets/nxzborderlogo.webp');
export const loginShade = require('../assets/loginShade.webp');
export const AccountTypeBackground = require('../assets/AccountTypeBackground.webp');
export const AccountTypeShade = require('../assets/AccountTypeShade.webp');
export const ListenerBackground = require('../assets/ListenerBackground.webp');
export const CreativeShade = require('../assets/CreativeShade.webp');
export const ListenerCreativeBackground = require('../assets/ListenerBackground.webp');


// Account Input Icon

export const smartphone = require('../assets/smartphone.svg');
export const atSign = require('../assets/at-sign.svg');
export const lock = require('../assets/lock.svg');
export const mail = require('../assets/mail.svg');
export const user = require('../assets/user.svg');
