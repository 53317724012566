// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { HISTORY } from "components/src/common";
export interface Props {

  handleClose: () => void;
  
  open:any;
}
interface S {
}
interface SS {
  id: any;
}

export default class ConfirmPlanAndPayController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   redirectHandler = async () => {
    const temp_id: any = await localStorage.getItem('temp_id');
    const userId: any = await localStorage.getItem('user_id');

    HISTORY.push({
      pathname: `/creatives/beats?creative_id=${temp_id ? temp_id : userId}`,
    });

    localStorage.removeItem('temp_id');
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    // Customizable Area End
  }
}
// Customizable Area End
