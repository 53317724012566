// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { openNotification } from '../../../../components/src/Notification.web';
import { setStorageData } from "../../../../framework/src/Utilities";

export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
    setBackStep: () => void;
}
interface S {
    token: any;
    loading: any;
    scaned: any;
    audioFile: any;
    videoFile: any;
    uploadType: any;
    coverImage: any;
    validationCoverText: any;
}
interface SS {
    id: any;
}

export default class UploadCoverController extends BlockComponent<Props, S, SS> {

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            scaned: false,
            audioFile: "",
            videoFile: "",
            uploadType: window.location.href.split('/')[4],
            coverImage: "",
            validationCoverText: ''
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

     getBase64(video: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(video);
      }

      nextClick = () =>{
        if(this.state.coverImage != ''){
          this.props.setActiveStep();
        }else{
          this.setState({validationCoverText: 'please select image'})
        }
      }
    
       uploadCover = async (options :any) => {
        const {  file } = options
        await setStorageData('uploaded_cover_image', file)
        console.log(file.type, 'upload file');
    
        if(file.type.split("/")[0] == "image") {
          this.getBase64(file, (imageUrl: any) =>
          {
            this.setState({coverImage: imageUrl, validationCoverText: ''});
          }
    
          );
        }else{
          this.setState({validationCoverText: 'please select png/jpeg format image'})
        }
        
      }
}
// Customizable Area End