
// Customizable Area Start
import React from "react";
import "../../../../user-profile-basic/src/Common/TopSongs.web.css"
import { Grid, Box } from "@material-ui/core";
import millify from "millify";
import { Col, Row } from "antd";
import { expandMore,defaultProfile, plays, lockIcon, iconUp, chatPlaylist, nxzloader } from "../../../../dashboard/src/assets";
import LineChart from "components/src/CustomChart/PopUps/LineCharts.web";

const Listing = ({ songsList, index, handleShowPlaylist, handleDeletePlayListClick, musicButton, playlistLoader }: any) => {
  if (playlistLoader) {
    return (
      <Row align="middle" justify="center" style={{
        position: "absolute", left: "50%", top: "29%",
        //  @ts-ignore 
        zIndex: "9999"
      }} >
        <Col>
          {" "}
          <img src={nxzloader} style={{ width: "20%" }} />
        </Col>
      </Row>
    )
  } else {
    let tracksValue
    if (index < 9) {
      tracksValue = "0" + Number(index + 1)
    } else {
      tracksValue = index + 1
    }

    let artWorkOpacity: any;
    if (songsList.attributes.status === "private_playlist") {
      artWorkOpacity = "0.5"
    } else {
      artWorkOpacity = 1
    }

    let trackName = ""
    if (songsList?.attributes?.name) {
      trackName = songsList?.attributes?.name
    } else {
      trackName = "Unknown"
    }

    let artistName = ""
    if (songsList?.attributes?.artist_name) {
      artistName = songsList?.attributes?.artist_name
    } else {
      artistName = "Unknown"
    }


    const returnLockScreen = () => {
      if (songsList.attributes.status === "private_playlist") {
        return (
          <img src={lockIcon} className="playlist-lock-icon" />
        )
      }else {
        return null
      }
    }

    const returnArtWork = () => {
        return (
          <div className="position-playlist-relative">
            {returnLockScreen()}
            {musicButton ?
            <img style={{ borderRadius: "8px", width: "80px", height: "48px", opacity: artWorkOpacity }}
              className="song-cover"
              src={songsList?.attributes?.cover_image || defaultProfile} alt=""
            />
            :
            <img style={{ borderRadius: "8px", width: "50px", height: "50px", opacity: artWorkOpacity }}
            className="song-cover mr_1"
            src={songsList?.attributes?.cover_image || defaultProfile} alt=""
          />
            }
          </div>
        )
    }
    return (
      <Grid container style={{ padding: "6px 0px 4px 0px",justifyContent:"space-between" }} onClick={handleDeletePlayListClick} >
        <Grid item md={4} lg={3} xs={3} sm={4} className="profileDetailBlock">
          <div className=" width_100 dflex" >
            <div>
              <Row className="sn-grid mr_1 ml_05">
                {tracksValue}
              </Row>
              <img src={iconUp} className="icon-up mr_05 ml_05" />
            </div>
            {returnArtWork()}
            <div >
              <Row style={{ marginLeft: musicButton && "10px" }}>
                <Row
                  justify="start" className="width_100 playlist_video_title text_white cursor_pointer "> {trackName}</Row>
                <Row
                  justify="start" className="width_100 text_gray font_12  cursor_pointer artist-name-width">{artistName}</Row>
              </Row>
            </div>
          </div>
        </Grid>
        <Col xs={6} className="HiddnFullScreen hours-list">
          <Row justify="end" className="iconDelete">
            <img src={expandMore} className=" ml-1 mr_1" height="28px" width="28px" onClick={handleShowPlaylist} />
          </Row>
        </Col>
        <Grid item md={1} xs={2} sm={4} lg={3} className="playButtonContain">
        </Grid>
        <Grid item sm={3} md={2} lg={2} className="iconButton">
          <div className=" display-play_dark_back" style={{ paddingTop: 10 }}>
            <Col className="colButton width_100">
              <div style={{ display: "flex" }} className='iconContent'>
                <img className="iconImg" src={plays} height="24px" width="24px" />
                <Box className="play-times iconContent">
                  {millify(songsList?.attributes?.play_count)}
                </Box></div>
            </Col>
            {/* <Col className="colButton width_100">
              <div style={{ display: "flex", alignItems: "end" }} className='iconContent'>
                <img className="iconImg" src={loopIc} height="20px" width="20px" />
                <Box className="play-times iconContent">
                  {millify(songsList?.attributes?.repost_count)}
                </Box>
              </div> </Col> */}
            <Col className="colButton width_100">
              <div style={{ display: "flex", alignItems: "flex-end" }} className='iconContent'>
                <img className="iconImg" src={chatPlaylist} height="19px" width="20px" />
                <Box className="play-times iconContent">
                  0
                  </Box>
              </div>
            </Col>
          </div>
        </Grid>
        <Grid item sm={5} md={5} lg={4} className="hidden_percentage" >
          <LineChart
            seven_days={songsList?.attributes?.last_seven_days_percentage}
            twenty_four_days={songsList?.attributes?.last_twenty_four_hours_percentage}
            seven_days_graph={songsList?.attributes?.last_seven_days_graph}
            fullscreen={expandMore}
            onFullScreen={handleShowPlaylist}
          />

        </Grid>
      </Grid>
    )
  }
}
export default Listing;
// Customizable Area End