// Customizable Area Start
import React,{useState} from 'react'
import { Grid, Button } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import { Input,Row} from "antd";

import { SearchOutlined } from "@ant-design/icons";
import LinkTrackController from './LinkTrackController.web'


import './index.web.css'
import { audioFrame, VideoFrame, LinkTrackCover, filterWhite} from '../assets';

export default class LinkTrack extends LinkTrackController {

  render(){
  const {setActiveStep, setBackStep } = this.props;
  const { linkedId , AudioVideoList} = this.state;
  
  const uploadType = window.location.href.split('/')[4]
  console.log('next');
  

  return (
    <Grid container className='UploadMainScroll'>
          
           <div className='main-div'>
            <Grid container item xs={12} md={12} lg={7} style={{justifyContent: 'center', padding: '10px'}} >

             <div className='link-margin track-details-div-width '>

                <Grid className='link-search-margin' style={{height: '40px', width: '100%'}}>
                  <Row
                    // className="left_part"
                    className="search_track_to_link "
                  >

                    <Input
                      onChange={this.searchLinkInput}
                      //onClick={openSearchScreen}
                      className=" ml-2 "
                      prefix={
                          // @ts-ignore 
                        <SearchOutlined
                         
                          className="text_white1 search_Link_icon"
                        />
                      }
                      suffix={
                        <img style={{height: '15px', width: '15px',marginRight: '5px'}} src={filterWhite}/>
                      }
                      style={{ width: "100%", height: '45px',
                      borderRadius: '60px' }}
                      // placeholder={<p className="text_white"></p>}
                      placeholder="Search Your Songs"
                    />

                    
                  </Row>
                </Grid>

                <Grid className='' style={{width: '100%', padding: '10px', marginTop: '40px'}}>
                  {
                    AudioVideoList.length > 0 ?(
                    AudioVideoList.map((item: any) => {
                      return <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Grid style={{display: 'flex', paddingLeft: '35px', marginBottom: '10px'}}>
                                    {
                                      uploadType == 'audio'?
                                      <img style={{ height: '60px',width: '85px', objectFit: 'contain', borderRadius: '10px' }} src={item?.attributes?.art_work} />
                                      :
                                      <img style={{height: '60px',width: '59px'}} src={item?.attributes?.art_work} />
                                    }
                                    

                                   <div style={{display: 'flex', flexDirection: 'column'}}>
                                     <div style={{color: 'white' ,  margin: '4px 0 9px 8px',  fontFamily: 'Karla', fontSize: '14px',  fontWeight: 'bold'}}>{item?.attributes?.title}</div>
                                     <div style={{  color: 'rgba(255, 255, 255, 0.5)', marginLeft: '9px'}}>{item?.attributes?.artist_name}</div>

                                    </div>
                                  </Grid>
                                
                                  <Grid>
                                  <button type="button" className='button-upload-types' style={{ background: linkedId == item.id ? "#3959F9" : 'rgb(48, 48, 48)'}} onClick={() =>{this.setValue(item?.id)}}>
                                    {
                                      linkedId == item?.id?
                                      <div>Linked</div>
                                      :
                                      <div>Connect</div>
                                    } 
                                  </button>
                                  </Grid>
                      </Grid>
                    })
                    )
                    :(
                      <Grid style={{color: 'white', display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: 'bold', marginTop: '50px'}}>
                        {`No ${uploadType == 'audio'? "Video" : 'Audio'} Songs Found`}
                        </Grid>
                    )
                  }
                </Grid>
                </div>
            </Grid>

            {
              <>
              <Grid item xs={12} md={12} lg = {5} className="hide-side-image-in-responsive">
                <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                   <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414',width: '100%', height: '100%'}} src={LinkTrackCover} />
                   <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  {
                    uploadType == 'audio'? 
                    <>
                    <div className='name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Connect Audio</div>
                    <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>To Video Version</p>
                    </>
                    :
                    <>
                    <div className='name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Connect Video</div>
                    <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>To Audio Version</p>
                    </>
                  }
                   


                   <Button className='buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} onClick={setActiveStep} >Next</Button>
                   <Button className='buttons-font-weight' test-id="link_track_save_test_id" style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} onClick={this.afterSaveLinkTrack}>Save</Button>
                   <Button variant="text" className='buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>
                   </div>
                   </div>
                </div>
                
            </Grid>

            <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
            <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
              <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform: 'capitalize'}}  onClick={setActiveStep} >Next</Button>
              <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={this.afterSaveLinkTrack}>Save</Button>
              <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

            </div>

            </Grid>
            
            </>
            }
            
            </div> 
         </Grid>
  )
          }
}

// Customizable Area End