// Customizable Area Start
import React from 'react'
import {
  Grid,
  Dialog,
} from "@material-ui/core";
import { Row, Col, Typography } from "antd";
import CtrlFilterPopupController from './CtrlFilterPopupController'
import { artist, creativeRole, director, engineer, hook, musician, mySplits, producer, songwriter, trackStatus, trackType, verse } from '../../assets';
export class CtrlFilterPopup extends CtrlFilterPopupController {
  initialState = () => {
    if (this.props.currentTab === "audio"||this.props.currentTab === "video"){
      return(
        <Grid container style={webstyle.containerParentGrid}>
        <Grid container item xs={12} style={webstyle.filterGridList}
        onClick={() => this.setState({ filterState: "trackStatus" })}
        data-test-id="filter-trackStatus"
      >

        <Grid item xs={2}>
          <img src={trackType} style={webstyle.iconContainer} />
        </Grid>

        <Grid item xs={8}>
          <Typography style={webstyle.titleStyle}>Track Status</Typography>
        </Grid>
      </Grid>
      </Grid>
      )
    }else{
    return (
      <Grid container style={webstyle.containerParentGrid}>
        {this.props.currentTab == "Tracks" &&
        <Grid container item xs={12} style={webstyle.filterGridType}
          onClick={() => this.setState({ filterState: "trackType" })}
          data-test-id="filter-trackType"
        >
          <Grid item xs={2}>
            <img src={trackType} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Track Type</Typography>
          </Grid>
        </Grid>
        }
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => this.setState({ filterState: "creativeRole" })}
          data-test-id="filter-creativeRole"
        >
          <Grid item xs={2}>
            <img src={creativeRole} style={webstyle.iconContainer} />
          </Grid>


          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Creative Role</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => this.setState({ filterState: "mySplit" })}
          data-test-id="filter-mySplit"
        >

          <Grid item xs={2}>
            <img src={mySplits} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>My Splits</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => this.setState({ filterState: "trackStatus" })}
          data-test-id="filter-trackStatus"
        >

          <Grid item xs={2}>
            <img src={trackType} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>{this.props.currentTab == "Tracks" ?"Track":"Song"} Status</Typography>
          </Grid>
        </Grid>

      </Grid>
    )
      }
  }

  trackType = () => {
    return (
      <Grid container style={webstyle.containerParentGrid}>
        <Grid container item xs={12} style={webstyle.filterGridType}
          onClick={() => {
            this.filterData("track_type", "beat")
          }}
          data-test-id="track-type-beat"
        >
          <Grid item xs={2}>
            <img src={trackType} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Beat</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("track_type", "hook")
          }}
          data-test-id="track-type-hook"
        >
          <Grid item xs={2}>
            <img src={hook} style={webstyle.iconContainer} />
          </Grid>


          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Hook</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("track_type", "verse")
          }}
          data-test-id="track-type-verse"
        >

          <Grid item xs={2}>
            <img src={verse} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Verse</Typography>
          </Grid>
        </Grid>


      </Grid>
    )
  }

  creativeRole = () => {
    return (
      <Grid container style={webstyle.containerParentGrid}>
        <Grid container item xs={12} style={webstyle.filterGridType}
          onClick={() => {
            this.filterData("creative_type", "artist")
          }}
          data-test-id="creative-artist"
        >
          <Grid item xs={2}>
            <img src={artist} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Artist</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("creative_type", "director")
          }}
          data-test-id="creative-director"
        >
          <Grid item xs={2}>
            <img src={director} style={webstyle.iconContainer} />
          </Grid>


          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Director</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("creative_type", "engineer")
          }}
          data-test-id="creative-engineer"
        >

          <Grid item xs={2}>
            <img src={engineer} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Engineer</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("creative_type", "label")
          }}
          data-test-id="creative-label"
        >

          <Grid item xs={2}>
            <img src={trackType} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Label</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("creative_type", "musician")
          }}
          data-test-id="creative-musician"
        >

          <Grid item xs={2}>
            <img src={musician} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Musician</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("creative_type", "producer")
          }}
          data-test-id="creative-producer"
        >

          <Grid item xs={2}>
            <img src={producer} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Producer</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("creative_type", "song_writer")
          }}
          data-test-id="creative-songwriter"
        >

          <Grid item xs={2}>
            <img src={songwriter} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Songwriter</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }


  mySplit = () => {
    return (
      <Grid container style={webstyle.containerParentGrid}>
        <Grid container item xs={12} style={webstyle.filterGridType}
          onClick={() => {
            this.filterData("split", "50-100")
          }}
          data-test-id="split50"
        >
          <Grid item xs={2}>
            <img src={mySplits} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>50% - 100%</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("split", "25-49")
          }}
          data-test-id="split25"
        >
          <Grid item xs={2}>
            <img src={mySplits} style={webstyle.iconContainer} />
          </Grid>


          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>25% - 49%</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("split", "0-24")
          }}
          data-test-id="split0"
        >

          <Grid item xs={2}>
            <img src={mySplits} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>0% - 24%</Typography>
          </Grid>
        </Grid>

      </Grid>
    )
  }

  trackStatusOpen = () => {
    return (
      <Grid container style={webstyle.containerParentGrid}>
        <Grid container item xs={12} style={webstyle.filterGridType}
          onClick={() => {
            this.filterData("status", "draft_track")
          }}
          data-test-id="status-draft"
        >
          <Grid item xs={2}>
            <img src={trackStatus} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Draft</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("status", "public_track")
          }}
          data-test-id="status-public"
        >
          <Grid item xs={2}>
            <img src={trackStatus} style={webstyle.iconContainer} />
          </Grid>


          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Public</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={webstyle.filterGridList}
          onClick={() => {
            this.filterData("status", "private_track")
          }}
          data-test-id="status-private"
        >

          <Grid item xs={2}>
            <img src={trackStatus} style={webstyle.iconContainer} />
          </Grid>

          <Grid item xs={8}>
            <Typography style={webstyle.titleStyle}>Private</Typography>
          </Grid>
        </Grid>

      </Grid>
    )
  }

  componentrender = () => {
    switch (this.state.filterState) {
      case "initial":
        return this.initialState();
      case "trackType":
        return this.trackType();
      case "creativeRole":
        return this.creativeRole();
      case "mySplit":
        return this.mySplit();
      default:
        return this.trackStatusOpen();
    }
  };
  render() {

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        PaperProps={{ className: "dialog-Paper-filter-popup" }}
      >
        <Row justify="center" style={{ width: '100%' }}>
          <Col style={{ width: '100%' }}>
              {this.componentrender()}
          </Col>
        </Row>
      </Dialog>
    )
  }
}

const webstyle = {
  containerParentGrid: {
    width: "100%",
    justifyContent: "center",
    padding: "30px 24px",
    alignItems: "center"
  },
  filterGridType: {
    borderRadius: "60px",
    background: "#141414",
    padding: "7px 8px",
    alignItems: "center",
    cursor:"pointer"
  },

  filterGridList: {
    borderRadius: "60px",
    background: "#141414",
    padding: "7px 8px",
    alignItems: "center",
    marginTop: "10px",
    cursor:"pointer"

  },

  titleStyle: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    color: "white",


  },

  iconContainer: {
    width: "45px",
    height: "45px"
  }

}
export default CtrlFilterPopup
// Customizable Area End