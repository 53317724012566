// Customizable Area Start

import { Typography, Layout, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import {
    Box,
    Button,
    styled,
    withStyles,
    Hidden,
    Theme,
    createStyles,
    LinearProgress
} from "@material-ui/core";
import React from 'react'
import { backarrow, circle, piechart, push } from '../assets';
import PushPlayerAdResultsController, { ActiveData } from './PushPlayerAdResultsController';
import Areachart from './AreaChart.web';
import { defaultProfile, nxzloader } from 'blocks/dashboard/src/assets';
import '../../../CustomAdvertisements/src/Addsetup/TracksAd.css'


const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 10,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);

const LeftSideSingletrackboxContainer = styled(Box)({
    height: "70px",
    display: "flex",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
    gap: "10px",
    objectFit: "contain",
    color: "white",
    justifyContent: "space-between",

});
const Divider = styled(Box)({
    height: "90vh",
    width: "1px",
    border: "1px solid #212121",
    transform: "scaleY(2.07)",
    "@media(max-width: 1400px)": {
        display: "none"
    },

});
const Tracknames = styled(Box)({
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    "@media(max-width: 400px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
    },
});
const Pushaddfirstbox = styled(Box)({
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    width: "30%",
    overflow: 'hidden auto',
    scrollbarWidth:'none',
    "@media(max-width: 1200px)": {
        width: "100%",
    },
    "@media(max-width: 400px)": {
        width: "95%",
    },
    "@media(max-width: 380px)": {
        width: "85%",
    },
    "@media(max-width: 351px)": {
        width: "80%",
    },
    "@media(max-width: 338px)": {
        width: "75%",
    },


});
const Pushadsecondbox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "30%",
    gap: "20px",
    overflow:'hidden auto',
    scrollbarWidth:'none',
    "@media(max-width: 1200px)": {
        width: "100%",
    },
    "@media(max-width: 400px)": {
        width: "95%",
    },
    "@media(max-width: 380px)": {
        width: "85%",
    },
    "@media(max-width: 351px)": {
        width: "80%",
    },
    "@media(max-width: 338px)": {
        width: "75%",
    },
});
const Pushadthirdbox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "30%",
    gap: "20px",
    overflow: 'hidden auto',
    scrollbarWidth:'none',
    paddingBottom:'15px',
    "@media(max-width: 1200px)": {
        width: "100%",
    },
    "@media(max-width: 545px)": {
        width: "95%",
    },
    "@media(max-width: 520px)": {
        width: "90%",
    },
    "@media(max-width: 500px)": {
        width: "85%",
    },
    "@media(max-width: 475px)": {
        width: "80%",
    },
    "@media(max-width: 450px)": {
        width: "75%",
    },
    "@media(max-width: 420px)": {
        width: "70%",
    },
    "@media(max-width: 400px)": {
        width: "65%",
    },
    "@media(max-width: 375px)": {
        width: "60%",
    },
    "@media(max-width: 345px)": {
        width: "55%",
    },
});
const TrackAdvertisementContainer = styled(Box)({
    width: "100%",
    height: "90vh",
    paddingLeft: "35px",
    overflowX: 'hidden',
    paddingTop: '10px',
    paddingBotton:'10px',
    overflowY: "hidden",
    display: "flex",
    justifyContent: "space-between",
    background: "black",
    gap: "20px",
    paddingRight: "10px",
    "@media(max-width: 1400px)": {
        // flexWrap: "wrap",
        justifyContent: "normal",
        gap: "40px"
    },
    "@media(max-width: 1200px)": {
        display: "grid",
        justifyContent: "normal",
        paddingRight:"35px"
    },
    "@media(max-width: 600px)": {
        justifyContent: "normal"
    },
    "@media(max-width: 400px)": {
        // width: "95%"
    },
})
export default class PushPlayerAdResults extends PushPlayerAdResultsController {
    getLeftValue = (index: number) => {
        if (index == 6) {
            return "7px";
        } else if (index == 3 || index == 4) {
            return "24px";
        } else {
            return "18px";
        }
    };
    renderPushStats = () => {
        return (
            this.state.allcreativetypes.map((item, index) => (
                <LeftSideSingletrackboxContainer
                    key={index}
                >
                    <Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "15px", position: "relative" }}>
                            <img
                                src={circle}
                                style={webStyle.artistleftimage} />
                            <img
                                src={item.image}
                                style={{ position: "absolute", mixBlendMode: "plus-lighter", left: this.getLeftValue(index) }}
                            />
                        </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box>
                                <Tracknames className="tracks_songs_text">
                                    {item.pushcategory}
                                    <span className="Jason-Derulo">
                                    </span>
                                </Tracknames>
                                <Box>
                                    <span style={webStyle.splittexts}>
                                        {item.pushtype}
                                    </span>
                                </Box>

                            </Box>
                            <Box>
                                <Box style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    flexDirection: "column",
                                }}>
                                    <Typography style={webStyle.pushstatstext}>{item.value}</Typography>
                                    <Typography style={webStyle.pluspercenttext}>
                                        
                                        <span style={this.getPctTxt(item.percentage.toString()).sign==="+"?webStyle.plussign:webStyle.minussign}>{this.getPctTxt(item.percentage.toString()).sign}
                                            </span>{this.getPctTxt(item.percentage.toString()).value}</Typography>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </LeftSideSingletrackboxContainer>
            ))
        )
    }

    renderAudioVideo = () => {
        return (
            this.state.activeAdCampaign.length > 0 ?
                this.state.activeAdCampaign.map((item: ActiveData, index: number) => (
                    <LeftSideSingletrackboxContainer
                        key={index}
                        onClick={() => this.getPushStatsData(item.id)}
                        data-test-id="PushStatsData"
                        style={
                            item.id === this.state.selectPushStatIndex
                                ? webStyle.campaignIdBorder
                                : {}
                        }
                    >
                        <Box>
                            <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                              <div 
                              style={this.state.selectedTab == "Audio" ? webStyle.leftimage : webStyle.videoimage}
                              >

                                <img
                                    src={item.attributes.art_work || defaultProfile}
                                    
                                    className={item.attributes.art_work ?
                                        "push-result-art-work":
                                        "default-profile-mediaDMCP"
                                      }
                                    />

</div>
                            </Box>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box>
                                    <Tracknames className="tracks_songs_text">
                                        {item.attributes.title || "Unknown"}
                                        <span className="Jason-Derulo">
                                        </span>
                                    </Tracknames>
                                    <Box>
                                        <span style={webStyle.splittexts}>
                                            {item.attributes.push_content_type == "player_ad" ? "Player Ad" : "Display Ad"}
                                        </span>
                                    </Box>

                                </Box>

                                <Box>
                                    <Box style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                    }}>
                                        <img src={piechart} />
                                    </Box>
                                </Box>

                            </Box>

                        </Box>
                    </LeftSideSingletrackboxContainer>
                ))
                :
                <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center", paddingTop: "120px" }} data-test-id="no-audio-found">
                    No {this.state.selectedAdCampaign} {this.state.selectedTab} Found
                </div>

        )
    }
    pushArtWorkView = (attributes: any) => {
        return (
            <div className="pushPlayer-stats-artwork"
                style={{
                    backgroundImage: attributes.art_work ? `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url(${attributes.art_work})` : `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url(${defaultProfile})`
                }}
            >
                <p
                    className={attributes.art_work ?
                        'pushPlayerAd-avatar' : "default-profile-mediaDMCP"}
                />
            </div>
        )
    }

    returnPushStatsEntries=()=>{
        const entries = Object.entries(this.state.statsPush);
        const pairedObjectsArray = entries.reduce((acc, [key, value], index, arr) => {
            if (index % 2 === 0) {
              const nextPair = arr[index + 1];
              if (nextPair) {
                acc.push({ [key]: value, [nextPair[0]]: nextPair[1] });
              } else {
                acc.push({ [key]: value }); 
              }
            }
            return acc;
          }, [] as Array<{ [key: string]: number }>);
        return(
        <Box style={webStyle.statsbox}>
                {entries.length > 0 ? (
                    <Box style={{ display: 'flex', gap: '20px', width: "100%" }}>
                        <Box style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>


                            {pairedObjectsArray.map((item, index) => (
                                <Box style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                    {Object.entries(item).map(([key, value]) => (
                                        <Box key={index}>
                                            <Typography style={webStyle.downloadtext}>{key}</Typography>
                                            <Typography style={webStyle.downloadstatstext} className='downloadtext'>
                                                {typeof value === 'object' ? JSON.stringify(value) : value}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Box>

                    </Box>
                ) : (
                    <Typography>No data available</Typography>
                )}
            </Box>
        )
}
    pushStatsView = (attributes: any) => {
       

        return (
            attributes.id !== 0 ?
                <>
                    <Box style={{
                        borderRadius: "15px",
                        background: "linear-gradient(180deg, #000000 32.29%, rgba(0, 0, 0, 0.9) 46.73%, #101010 100%)",
                        position: "relative",
                    }}>
                        {this.pushArtWorkView(attributes)}

                        <Box style={webStyle.playeradbox}>
                            <Typography style={webStyle.pushamountText}>{attributes.push_content_type == "player_ad" ? "Player Ad" : "Display Ad"}</Typography>
                        </Box>
                        <Box style={{ background: "linear-gradient(180deg, #000000 32.29%, rgba(0, 0, 0, 0.9) 46.73%, #101010 100%)" }}>
                            {this.returnPushStatsEntries()}
                        </Box>
                    </Box>
                    <Box style={{
                        width: "75%", height: "43px", borderRadius: "0px 0px 45px 45px", border: "1px solid #212121",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "-19px",
                        left: "40px", background: "#101010", padding: "12px 0px 11px 0px"
                    }} data-test-id="checking">
                        <Typography style={webStyle.downloadstatstext}>PUSH:
                            {attributes.status === "active" ?
                                <span style={webStyle.redcolor}>ACTIVE</span>
                                :
                                <span style={webStyle.completedText}>Completed</span>
                            }
                        </Typography>
                    </Box>
                    <Box style={{
                        width: "100%",
                        height: "100px",
                        padding: "26px 201px 27px 20px",
                        gap: "10px",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "10px",
                        border: "1px solid #212121",
                        background: "#090909"

                    }}>
                        <Typography style={webStyle.totaladtext}>Push Spend</Typography>
                        <Typography style={{ ...webStyle.dollartext, textWrap: "nowrap" }}>${attributes.ad_spend.toString().split('.')[0]}
                            {attributes.ad_spend.toString().split('.')[1] &&
                                <span style={webStyle.decimaltext}>.{attributes.ad_spend.toString().split('.')[1]}</span>
                            }
                        </Typography>
                    </Box>
                    <Box>
                    </Box>
                    {attributes.status == "active" ?
                        <Box style={webStyle.stoppushbutton} onClick={() => this.statsStopAndPushData('stop_ad_campaign')}
                            data-test-id="push_ad_campaign">
                            <Button style={{ color: "white", fontSize: "18px", lineHeight: "18px", fontFamily: "Inter", fontWeight: 700 }}>
                                STOP PUSH
                            </Button>
                        </Box>
                        :
                        <Box style={webStyle.pushAgainbutton} onClick={() => this.statsStopAndPushData('push_ad_campaign')}
                            data-test-id="stop_ad_campaign">
                            <Button style={{ color: "white", fontSize: "18px", lineHeight: "18px", fontFamily: "Inter", fontWeight: 700 }}>
                                PUSH AGAIN
                            </Button>
                        </Box>
                    }
                    <Box>
                        <Typography style={webStyle.pushaudienceText}>Push {attributes.status == "active" ? "Placements" : "Impressions"}</Typography>
                    </Box>
                    <Box style={{ height: "300px", width: "500px" }} className='pushaudiencechart'>
                        <Areachart chartType='Push' activechartdata={this.state.activechartdata} chartdata={this.state.chartdata} />
                    </Box>
                    <Box>
                        <Typography style={webStyle.pushaudienceText}>Push Audience</Typography>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        {this.state.pushplacementStats.map((item, index) => (
                            <Box key={index} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography style={webStyle.pushcategoryText}>{Object.keys(item)[0]}</Typography>
                                    <Typography style={webStyle.pushamountText}>{Object.values(item)[0]}</Typography>
                                </Box>
                                <Box>
                                    <BorderLinearProgress variant="determinate" value={Object.values(item)[0]/100} />
                                </Box>

                            </Box>
                        ))}




                    </Box>
                </>
                :

                <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center", paddingTop: "120px", height: "100%", alignItems: "center" }} data-test-id="no-stats-found">
                    Select Audio/Video is view its stats
                </div>
        )
    }


    render() {
        const { attributes } = this.state.PushStatsData
        return (
            <Layout >

                <Hidden smDown>
                    <AsideLeft />
                </Hidden>
                <Content
                    style={{
                        margin: "0px",
                        backgroundColor: "black",
                        display: "flex", flexDirection: "column",
                    }}
                >
                    <HomeHeader

                    />
                    <TrackAdvertisementContainer >

                        {this.state.activestepMobile == "1" && <Pushaddfirstbox style={{}} className="scrollerWidth">
                            <Box
                                className='pushstatsresults'
                                style={{
                                    minHeight: "331px",
                                    padding: "101px 104px 57px 32px",
                                    display: "flex",
                                    borderRadius: "15px",
                                    border: "1px solid #212121",
                                    flexDirection: "column",
                                    gap: "15px",
                                    background: "linear-gradient(180deg, #000000 32.29%, rgba(0, 0, 0, 0.9) 46.73%, #101010 100%)"
                                }}>
                                <Box style={{ flexDirection: "column", display: "flex", gap: "8px" }}>
                                    <Typography style={webStyle.totaladtext}>Total Ad Spend</Typography>
                                    <Typography style={webStyle.dollartext}>${this.state.pushDataList.total_ad_spend} <span style={webStyle.usdtext}>USD</span></Typography>
                                </Box>

                                <Box style={{ justifyContent: "space-between", display: "flex" }}>
                                    <Box style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "4px" }}
                                        onClick={() => this.handlePushActiveComplete("1", "push")} data-test-id="pushstepid1"
                                    >
                                        <img src={this.state.currentImages.push} />
                                        <Typography style={webStyle.snaptext}>Snap</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", gap: "4px", flexDirection: "column", alignItems: "center", }}
                                        onClick={() => this.handlePushActiveComplete("2", "active")} data-test-id="pushstepidActive"
                                    >
                                        <img src={this.state.currentImages.active} />
                                        <Typography style={webStyle.snaptext}>Active</Typography>
                                    </Box>
                                    <Box style={{ flexDirection: "column", display: "flex", alignItems: "center", gap: "4px" }}
                                        onClick={() => this.handlePushActiveComplete("3", "complete")} data-test-id="complete-ad">
                                        <img src={this.state.currentImages.complete} />
                                        <Typography style={webStyle.snaptext}>Complete</Typography>
                                    </Box>
                                    <Box style={{ alignItems: "center", display: "flex", flexDirection: "column", gap: "4px" }}>
                                        <img src={push} onClick={() => this.selectPushStep("3")} data-test-id="pushstepid3" />
                                        <Typography style={webStyle.snaptext}>Push</Typography>
                                    </Box>
                                </Box>

                            </Box>
                            <Hidden only={["xl", "lg", "md", "sm"]}>

                                <Areachart chartType="Active"
                                    activechartdata={this.state.activechartdata}
                                    chartdata={this.state.chartdata}
                                />
                            </Hidden>
                            <Box style={{ display: "flex", justifyContent: "space-between" }} className='pushthisweek'>
                                <Typography style={webStyle.selecttext}>PUSH Stats</Typography>
                                <Typography style={webStyle.selecttextgrey}>This Week</Typography>
                            </Box>
                            <Box className='pushstatscategory'>

                                {this.state.isSnapLoading ? (

                                    <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }} data-test-id="snaploading">
                                        <img src={nxzloader} style={{ width: "5%" }} />
                                    </Row>

                                ) :
                                    this.renderPushStats()
                                }


                            </Box>
                        </Pushaddfirstbox>}


                        <Divider className='dividerAdsetup '>
                            <hr style={{
                                height: "100%",
                                width: "1px",
                                border: "1px solid #212121",

                            }}
                            ></hr>
                        </Divider>

                        {(!this.state.isMobile || this.state.activestepMobile == "2") && <Pushadsecondbox style={{}} className="scrollerWidth">
                            <Hidden only={["xs"]}>
                                <Box style={{
                                    height: "331px",
                                    borderRadius: "15px",
                                    border: "1px solid #212121",
                                    background: "linear-gradient(180deg, #000000 32.29%, rgba(0, 0, 0, 0.9) 46.73%, #101010 100%)",
                                    gap: "15px"

                                }}>
                                    <Areachart chartType='Active' activechartdata={this.state.activechartdata} chartdata={this.state.chartdata} />
                                </Box>
                            </Hidden>
                            <Hidden only={["xl", "lg", "md", "sm"]}>
                                <Box style={{
                                    height: "331px",
                                    borderRadius: "15px",
                                    border: "1px solid #212121",
                                    padding: "101px 104px 57px 32px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "15px",
                                    background: "linear-gradient(180deg, #000000 32.29%, rgba(0, 0, 0, 0.9) 46.73%, #101010 100%)"


                                }}>
                                    <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                        <Typography style={webStyle.totaladtext}>Total Ad Spend</Typography>
                                        <Typography style={webStyle.dollartext}>${this.state.pushDataList.total_ad_spend}5 <span style={webStyle.usdtext}>USD</span></Typography>
                                    </Box>

                                    <Box style={{ display: "flex", justifyContent: "space-between", gap: "8px" }}>
                                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}
                                            onClick={() => this.handlePushActiveComplete("1", "push")} data-test-id="active2-pushstepid1"
                                        >
                                            <img src={this.state.currentImages.push} />
                                            <Typography style={webStyle.snaptext}>Snap</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}
                                            onClick={() => this.handlePushActiveComplete("2", "active")} data-test-id="active2-pushstepid2"
                                        >
                                            <img src={this.state.currentImages.active} />
                                            <Typography style={webStyle.snaptext}>Active</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}
                                            onClick={() => this.handlePushActiveComplete("3", "complete")} data-test-id="active2-complete-ad"
                                        >
                                            <img src={this.state.currentImages.complete} />
                                            <Typography style={webStyle.snaptext}>Complete</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                                            <img src={push} onClick={() => this.selectPushStep("3")} data-test-id="active2-pushstepid3" />
                                            <Typography style={webStyle.snaptext}>Push</Typography>
                                        </Box>
                                    </Box>

                                </Box>
                            </Hidden>
                            <Box style={webStyle.Rightsidetabsbutton}>
                                <Box style={webStyle.tabbox}>
                                    <Button
                                        style={this.state.selectedTab == "Audio" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                        data-test-id="selectaudiopushtabid"
                                        onClick={() => this.selectTab("Audio")}
                                    >
                                        Audio
                                    </Button>
                                </Box>
                                <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        data-test-id="selectvideopushtabid"
                                        onClick={() => this.selectTab("Video")}
                                        style={this.state.selectedTab == "Video" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                    >
                                        Video
                                    </Button>
                                </Box>

                            </Box>
                            <Box>
                                {this.state.isAudioLoading ? (

                                    <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }} data-test-id="audioVideo">
                                        <img src={nxzloader} style={{ width: "5%" }} />
                                    </Row>

                                ) :

                                    this.renderAudioVideo()
                                }
                            </Box>
                        </Pushadsecondbox>}



                        <Divider className='dividerAdsetup'>
                            <hr

                                style={{
                                    height: "100%",
                                    width: "1px",
                                    border: "1px solid #212121",

                                }}
                            ></hr>
                        </Divider>
                        {(!this.state.isMobile || this.state.activestepMobile == "3") && <Pushadthirdbox style={{

                        }} className="scrollerWidth">
                            <Hidden only={["lg", "md", "xl", "sm"]}>
                                <Box style={{ display: "flex", alignItems: "center", gap: "80px" }}>
                                    <img src={backarrow} onClick={() => this.onBack()} data-test-id="pushstepsbackid" />
                                    <Typography style={webStyle.dollarsmall}>Push Stats</Typography>
                                </Box>
                            </Hidden>
                            {this.state.isPushStatsLoading ? (

                                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }} data-test-id="pushStatsLoading">
                                    <img src={nxzloader} style={{ width: "5%" }} />
                                </Row>

                            ) :
                                this.pushStatsView(attributes)
                            }
                        </Pushadthirdbox>
                        }
                    </TrackAdvertisementContainer>


                </Content>
            </Layout>


        )


    }
}

const webStyle = {
    pushcategoryText: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16.94px",
        color: "#8F92A1"
    },
    plussign: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 900,
        lineHeight: "14.52px",
        color: "#3959F9",
    },
    minussign: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 900,
        lineHeight: "14.52px",
        color: "red",
    },
    videoimage: {
        borderRadius: "10px",
        width: "90px",
        minWidth: "90px",
        height: "60px",
        border: "1px solid #212121",
        objectFit: "cover" as 'cover'
    },
    stoppushbutton: {
        width: "100%",
        height: "60px",
        borderRadius: "15px",
        background: "#FF0501",
        display: "flex",
        justifyContent: "center",
        padding: "21px 0px 21px 0px"
    },
    pushAgainbutton: {
        width: "100%",
        height: "60px",
        borderRadius: "15px",
        background: "#3959F9",
        display: "flex",
        justifyContent: "center",
        padding: "21px 0px 21px 0px"
    },
    redcolor: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#FF0501",
    },
    completedText: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#3959F9",
    },

    pluspercenttext: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 900,
        lineHeight: "14.52px",
        color: "#8F92A1",
    },
    pushstatstext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 900,
        lineHeight: "21.78px",
        color: "#FFFFFF",
        textWrap: "nowrap" as "nowrap"
    },
    statsbox: {
        display: "flex",
        justifyContent: "space-around",
        top: "206px",
        left: "30px",
        // gap: "45px",
        position: "absolute" as "absolute",
        width: "90%"
    },
    downloadtext: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#8F92A1"
    },
    selecttextgrey:{
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20px",
        color: "#8F92A1"
    },
    downloadstatstext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#FFFFFF",
        textWrap: "nowrap" as "nowrap"
    },
    playeradbox: {
        width: "112px",
        height: "28px",
        border: "1px solid #212121",
        background: "#00000080",
        borderRadius: "30px",
        position: "absolute" as "absolute",
        left: "30px",
        top: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    decimaltext: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 800,
        lineHeight: "16px",
        color: "#FFFFFF"
    },
    pushaudienceText: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.78px",
        color: "#FFFFFF"
    },
    pushamountText: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16.94px",
        color: "#FFFFFF"
    },
    totaladtext: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#8F92A1",
        textWrap: "nowrap" as "nowrap"
    },
    snaptext: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#F0F0F5",
        textWrap: "nowrap" as "nowrap"
    },
    dollartext: {
        fontFamily: "Inter",
        fontSize: "32px",
        fontWeight: 900,
        lineHeight: "18px",
        color: "#FFFFFF",
        textWrap: "nowrap" as "nowrap"
    },
    usdtext: {
        fontFamily: "Inter",
        fontSize: "22px",
        fontWeight: 900,
        lineHeight: "18px",
        color: "#8F92A1"
    },
    selecttext: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#FFFFFF"
    },
    dollarsmall: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.54px",
        color: "#FFFFFF"
    },

    splittexts: {
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal"
    },
    leftimage: {
        borderRadius: "10px",
        width: "60px",
        minWidth: "60px",
        height: "60px",
        border: "1px solid #212121",
        objectFit: "cover" as 'cover'
    },
    artistleftimage: {
        borderRadius: "60px",
        width: "60px",
        minWidth: "60px",
        height: "60px",
        border: "1px solid #212121",
        objectFit: "cover" as 'cover'
    },
    Rightsidetabsbutton: {
        borderRadius: "30px",
        background: "#070707",
        display: "flex",
        justifyContent: "space-around",
        padding: "5px",
        border: "3px solid #212121",

    },
    tabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },
    tabaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#8F92A1",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",

    },

    tabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#F0F0F5",
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
    },
    campaignIdBorder: {
        borderRadius: "8px",
        background: "#141414"
    }

}

// Customizable Area End