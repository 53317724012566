// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config")

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.playlistUrl = "bx_block_audiomusic/tracks_by_upload_type";
exports.alllistEndpoint = "/bx_block_audiomusic/web_user_tracks_by_upload_type";
exports.alllistTopSongs = "/bx_block_audiomusic/top_tracks";
exports.playListsEndPoint = '/bx_block_playlist/play_lists'
exports.profileNedpoint = '/bx_block_profile/profile'
exports.creativesById = '/bx_block_profile/user_profile'
exports.topVideos = '/bx_block_videos/top_videos'
exports.activityList = '/bx_block_audiomusic/user_history'
exports.allPlaylistEndpoint = '/bx_block_playlist/all_playlists'
exports.publicPlaylistEndpoint = '/bx_block_playlist'
exports.followUserUrl = '/account_block/follow_user?user_id='
exports.unfollowUserUrl = '/account_block/unfollow_user?user_id='
exports.followerLists = '/account_block/followers_list'
exports.endPointHandleFilter = "/bx_block_audiomusic/filter_tracks"
exports.creativeFilter = "/bx_block_audiomusic/creative_filter_tracks"
exports.updatePlaylist = "/bx_block_playlist/play_lists/"
exports.deletePlaylist = "/bx_block_playlist/play_lists/"
exports.getUserPlaylists = "/bx_block_playlist/play_lists"
exports.getVideoUserPlaylists = "/bx_block_playlist/video_play_list"
exports.addTrackToPlaylist = "/bx_block_playlist/add_track_to_playlist?"
exports.createPlaylist = "/bx_block_playlist/play_lists"
exports.getuserprofiledata = "/bx_block_profile/user_profile?"
exports.getnewreleasevideo = "/bx_block_videos/user_profile_new_release_video?"
exports.getcreativeprofiletrack = "/account_block/accounts/creative_profile_track_percentage?"
exports.getallactivity = "/bx_block_activityfeed/activity_tab?"
exports.getfollowingdata = "/account_block/user_following?"
exports.getfollowerdata = "/account_block/user_follower?"
exports.getfollowerUserById = "/account_block/follow_user?"
exports.getunfollowUserById= "/account_block/unfollow_user?"
exports.getTop100Playlist= "/bx_block_playlist/top_playlist?"
exports.getNotificationData= "/bx_block_notifications/notifications/all_notification?"
exports.gettop100UploadList= "/bx_block_audiomusic/creative_top_tracks?"
exports.gettopProfileVideos= "/bx_block_playlist/video_play_list"
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.deleteAPIMethod = "DELETE";
exports.baseUrl = urlConfig.baseURL;
// Customizable Area End