// Customizable Area Start
import React from 'react'
import { Avatar,Row } from "antd";
import { Grid } from "@material-ui/core";
import "./DirectMessage.css"
import {  PubNubConsumer } from 'pubnub-react';
import MessageCard from './MessageCard.web';
import ViewNewMessengerProfile from './ViewNewMessengerProfile.web';
import { AudioContextApi } from '../../dashboard/src/AudioContext/AudioContext.web';
import { nxzloader } from '../../dashboard/src/assets';
import DirectMessageUserController from './DirectMessageUserController';
export const configJSON = require("./config.js");


export type MessageTypes = "profile" | "track" | "video" | "txt"

export default class DirectMessage extends DirectMessageUserController {
  
  render(){
  const { message, getUserDetails,avtarUrl,input,isLoading }: any = this.state;

 const { isPlaylist } = this.context;
 return (
  <PubNubConsumer>
  {(pubnub) => (
    <>
      <div className={isPlaylist?"audio-containerChat":'containerChat'} >
     {isLoading
          ? (<Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
            <img src={nxzloader} style={{ width: "10%" }} />
          </Row>)
          :
          <>
             <div style={{ justifyContent: message.length > 0 ? "flex-start" : "center" ,width:"100%"}} className={isPlaylist?"audio-chatgridHead":"chatgridHead"} id="topGrid" >
              {message.length > 0 ? message.map((chatData:any) => {
                return (
                  <MessageCard chatData={chatData} particularUserDataRes={this.props.particularUserDataRes} isChartMessage={false} />
                )
              }
              )
                :
                <ViewNewMessengerProfile userData={getUserDetails} isExpand={false}/>
              }
            </div>
            {/* <div style={{ float: "left", clear: "both" }} ref={messagesEndRef} /> */}
          </>
        }

       <div className="chatsearchBar">
         <div >
         <div ref={this.messagesEndRef} />
           <div style={{ border: "2px solid white", borderRadius: "40px", display: "flex", width: "100%", padding: "8px" }}>
             <Grid item xs={2}>
               <Avatar
                 alt='avatar'
                 src={avtarUrl}
                 style={{ width: '42px', height: '42px',border:"solid 2px #ffffff" }}
               />
             </Grid>
             <Grid item xs={8}>
               <input
                 type='text'
                 test-id= "test_search_term_id_handle_search_input"
                 placeholder='Say Something'
                 className='comment_send_text'
                 onChange={this.handleChange}
                 value={input}
                 onKeyPress={this.handleKeyPress}
                 style={{ lineBreak: "anywhere" }}
               />
             </Grid>
             <Grid
               container
               item
               xs={2}
               justifyContent='flex-end'
               test-id="search_filter_popup_test_id"
               onClick={this.handleGridOnClick}
               alignItems='center'>
               <span className='comment_send_btn'
                 style={{ cursor: input != "" ?   "pointer":"not-allowed" }}
               >
                 Send
               </span>
             </Grid>
           </div>
         </div>
       </div>
     </div>
   </>
   )}
   </PubNubConsumer>
 )
}
}
DirectMessage.contextType = AudioContextApi;
// Customizable Area End