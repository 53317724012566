// Customizable Area Start
import { Form, Space, Modal, Button, Select, Input, Spin } from 'antd';
import React, { useState } from 'react';
import './PlayListCreateAndAddplayListModel.css'
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { openNotification } from "../../../../components/src/Notification.web";
import { LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
toast.configure()
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
const PlayListCreateAndAddplayListModel = (props?: any) => {
  const [disablity, setdisablity] = useState<boolean>(true)
  const [imageUrl, setimageUrl] = useState<any>('')
  const [playlistName, setplaylistName] = useState<any>('')
  const [fileName, setfileName] = useState<any>('')
  const [loading, setloading] = useState<boolean>(false)
  const { Option } = Select;
  const [form] = Form.useForm();
{/* @ts-ignore */}
  const antIcon = <LoadingOutlined  style={{ fontSize: 24, color: 'antiquewhite' }} spin translate />;
 

  const fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {

    let target = e.target as HTMLInputElement;
    let files = target.files as any;

    if (files.length !== 0) {

      let url = URL.createObjectURL(files[0])
      setimageUrl(url)
      
        setfileName(files)
        if (playlistName && files) {
          setdisablity(false)
        } else {
          setdisablity(true)
        }
      
    }
  };

  const UploadPlayLists = async (file: any) => {
    setloading(true)
    const token: any = localStorage.getItem("token")
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
      
      token: token,
    }
    if (file && playlistName) {
      let formData = new FormData();
      for (const key of file) {
        //formdata object
        formData.append('name', playlistName);
        formData.append('cover_image', key);
        // checking typingg.....
        formData.append('play_list_type', '');
      }

      //@ts-ignore
      document.getElementById('createPlayListBtn')?.setAttribute('disabled', true)
      document.getElementById('createPlayListBtn')?.setAttribute('style', 'background :#1890ff; color:#fff; width:100%;')
      await axios.post(`${baseUrl}/bx_block_playlist/play_lists`, formData, { headers })
        .then((response: any) => {
          props?.closeModal()
          props?.getPlayListsAPICall();
          openNotification('New PlayList Added Successfully', "Success")
        }).catch((err: any) => {
          console.log(err.response, "ggggggggggggg");

          openNotification(err.response.data.data[0].message, "Error")
        }).finally(() => {
          props?.closeModal()

          setloading(false)
          form.resetFields();
          setfileName('')
          setplaylistName('')
          setimageUrl("")
        })
    }
  }
  return (

    <>
      {(props?.addNewPlaylist === 'addNewPlaylist' || props?.addTrackToPlaylist === 'addTrackToPlaylist') ? (
        (props?.addTrackToPlaylist && props?.addTrackToPlaylist === "addTrackToPlaylist") &&
         (
          <Modal title="" open={props?.showModalAddNewPlaylist} footer={null} onCancel={() => {
            props?.closeModal()
          }} >
            <h2> ADD TO PLAYLIST</h2>
            <Form colon={false} form={form}>
              <Space direction='vertical' size='large' style={{ width: '100%', textAlign: 'center' }}>
                <Form.Item label="Add new playlist" style={{ color: '#8f92a1' }} name="playlistName">
                  <Input onChange={(e: any) => {
                    setplaylistName(e.target.value)
                    if (e.target.value && fileName) {
                      setdisablity(false)
                    } else {
                      setdisablity(true)
                    }
                  }} />
                </Form.Item >
                <div style={{ textAlign: 'left' }}>
                  <Form.Item name="coverImg">
                    <Space style={{ textAlign: 'left' }}>
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        name="profilePic"
                        style={{ display: "none" }}
                        onChange={(event) => fileUploadHandler(event)}
                      />
                      <label htmlFor="icon-button-file">
                        <div style={{ display: 'flex' }}>
                          <img style={{ height: "48px", width: "48px", borderRadius: '50%', background: '#f5f5f5' }} src={imageUrl ? imageUrl : "https://images.squarespace-cdn.com/content/v1/56e19ec5e3214084d69d4b7d/1473524254173-BGV5W2Z5FM46F67SYZHJ/PlusIcon_Small_Gray.png"} alt="" />
                          &nbsp;&nbsp;
                          <p aria-label="upload picture" style={{ color: '#8f92a1', marginTop: '11px' }}>Upload cover art</p>
                        </div>
                      </label>
                    </Space></Form.Item>
                </div>
                <Button style={{ width: '100%' }} id="createPlayListBtn" className='red_custom_btn' disabled={disablity} onClick={() => {

                  UploadPlayLists(fileName)
                }}>
                  {loading ?
                    <Spin indicator={antIcon} /> : ' CREATE A PLAYLIST'
                  }
                </Button>
              </Space>
            </Form>
          </Modal>)
      ) : (
        <Modal title="" footer={null} >
          <h2>ADD TO PLAYLIST</h2>
          <Form>
            <Space direction='vertical' size='large' style={{ width: '100%', textAlign: 'center' }}>
              <Form.Item label="Select from your playlist">
                <Select defaultValue="lucy" style={{ width: '100%' }} onChange={() => { console.log(`selected`); }}>
                  <Option value="jack"><img style={{ height: "30px", width: "30px", borderRadius: '50%', }} src="https://images.fastcompany.net/image/upload/w_1280,f_auto,q_auto,fl_lossy/w_596,c_limit,q_auto:best,f_auto/fc/3034007-inline-i-applelogo.jpg" alt="" /> <span>Jack</span></Option>
                  <Option value="lucy"><img style={{ height: "30px", width: "30px", borderRadius: '50%', }} src="https://images.fastcompany.net/image/upload/w_1280,f_auto,q_auto,fl_lossy/w_596,c_limit,q_auto:best,f_auto/fc/3034007-inline-i-applelogo.jpg" alt="" /> <span>Lucy</span></Option>
                  <Option value="Yiminghe"><img style={{ height: "30px", width: "30px", borderRadius: '50%', }} src="https://images.fastcompany.net/image/upload/w_1280,f_auto,q_auto,fl_lossy/w_596,c_limit,q_auto:best,f_auto/fc/3034007-inline-i-applelogo.jpg" alt="" /> <span>yiminghe</span></Option>
                </Select>
              </Form.Item>
              <Button style={{ width: '100%' }} type="primary" >
                SAVE
              </Button>
              <Form.Item label="Add new playlist">
                <Input />
              </Form.Item>
              <Space style={{ textAlign: 'left' }}>
                {/* @ts-ignore */}
                <PlusCircleFilled translate type="primary"  />
                <p style={{ color: 'rgba(255,255,255,0.8)' }}>
                  Upload cover art</p>
              </Space>
              <Button style={{ width: '100%', backgroundColor: "rgb(100 97 97)" }} className="btn_secondaryColor" >
                CREATE A PLAYLIST
              </Button>
            </Space>
          </Form>
        </Modal>
      )}
    </>
  );
};
export default PlayListCreateAndAddplayListModel;
// Customizable Area End
