// Customizable Area Start
import "antd/dist/antd.css";
import { OtpBackground, OtpShade } from "./assets";
import { Grid } from "@material-ui/core";

import { Row, Col, Form, Button } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./otp.css";
import axios from "axios";
import OtpInput from 'react-otp-input';
import { openNotification } from "../../../components/src/Notification.web";
import MyTimer from "../../../components/src/TimerWeb";
import { nxzborderlogo } from "../../email-account-registration/src/assets";

const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export default function Otp() {
  const history = useHistory();
  const [otp, setOtp] = useState('')
  const token: any = localStorage.getItem("token")

  const onFinish = async (values: any) => {
    const data = {
      "otp": [parseInt(values.otp)],
    }
    await axios
      .put(`${baseUrl}/account_block/otp_verification`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        history.push("/TermAndConditions")
        localStorage.setItem("accountType", res.data.data.attributes.user_type);
      })
      .catch((err) => {
        switch (err?.response?.status) {
          case 401:
            openNotification(err?.response?.data?.errors[0]?.pin, "Error");
            break;
          case 404:
            openNotification(err?.response?.data?.errors[0]?.pin, "Error");
            break;
          case 400:
            openNotification(err?.response?.data?.errors[0]?.token, "Error");
            history.push("/Welcome");
            break;
          default:
            console.log("error in otp");
            break;
        }
      });
  };
  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  }
  const [showResendOTP, setShowResendOTP] = useState<boolean>(false);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 150);

  const handleResend = async () => {

    await axios
      .put(`${baseUrl}/account_block/resend_otp`, '', {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        setShowResendOTP(false)
        openNotification(res?.data?.message, "Success")
        // history.push("/TermAndConditions")
      })
      .catch((err) => {

        openNotification(err?.response?.data?.errors[0]?.pin, "Error")
        if(err.response.status === 401){
          openNotification("Token has Expired", "Error")
           history.push("/AccountType")
        }
        
      });
  }
  const handleKeypress = (e: any) => {
    if (e.keyCode === 13) {
      onFinish(otp);
    }
  };

  return (
    <Row className="bgDark" style={{ height: "100%" }} >
      <Grid item lg={6} sm={6} xs={12} className="bgImg-account" >
        <img className="image1" src={OtpBackground} />
        <img className="image2" src={OtpShade} />
      </Grid>
      <Grid item lg={6} sm={6} xs={12} className="accType-form">
        <Row align="middle" justify="center" style={{ height: "100%" }}>
          <Col xxl={10} xl={13} lg={15} md={18} sm={18} xs={14}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <img src={nxzborderlogo} alt="App Logo" className="nxzborderlogo" />
              </div>
            </div>
            <Row
              justify="center"
              className=" width_100 text_white mt-1  font_25 width_100 font_W_600"
              style={{ textAlign: "center" }}
            > Verify Your Phone Number</Row>
            <Row justify="center" className="text_white1 width_100">Enter the OTP we just Text to you</Row>
            <Form
              name="basic"
              className="login_form"
              layout="vertical"
              onFinish={onFinish}
              onKeyPressCapture={handleKeypress}
              onKeyPress={handleKeypress}
              autoComplete="off"
            >
              <Row justify="center" >
                <Form.Item className="otp-input login_form"
                  name="otp">
                  <OtpInput
                    shouldAutoFocus={true}
                    onChange={handleOTPChange}
                    numInputs={4}
                    isInputNum={true}
                    separator={<span>-</span>}
                  /></Form.Item>
              </Row>

              <Row justify="center" className="time" >
                {showResendOTP ?
                  <Button type='link' onClick={handleResend} className="gray1">
                    Didn't Get OTP?<span
                      className="text_red pl_05 cursor_pointer"
                    >RESEND</span> </Button> :
                  <MyTimer expiryTimestamp={time} setShowResendOTP={setShowResendOTP} showResendOTP={showResendOTP} />}</Row>
              <Form.Item className="mt-3">
                <Button
                  className="red_custom_btn login_btn "
                  type="primary"
                  htmlType="submit"

                >
                  Verify OTP
                </Button></Form.Item>
              <Form.Item className="mt-3">
                <Button
                  className="next_back_account_create back_btn "
                  type="primary"
                  htmlType="submit"
                  onClick={() => history.goBack()}
                >
                  Back
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Grid>
      {/* </Row> */}
    </Row>
  );
}
// Customizable Area End
