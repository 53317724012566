import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { minus, plus } from "blocks/dashboard/src/assets";
import React from "react";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
    
  // Customizable Area Start
  data?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  statsList: any;
  
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class StatsController extends BlockComponent<Props, S, SS> {

  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
        statsList: [],
    
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  renderPercentageIcon = (pct: any) => {
    const isZero = parseFloat(pct) === 0|| pct === null;
    const isNegative = pct?.toString().startsWith("-");

    if (!isZero) {
      return (
        <img
          src={isNegative ? minus : plus}
          className={
            isNegative ? "mr4 home_counting_minus" : "mr4 stats_percentage_plus_minus"
          }
        />
      );
    }

    return null; // or handle other cases as needed
  };


  getPctTxt = (data: any): string => {
    if (data === null) {
      return "0%";
    }else{
    if (Number(data)) {
      if (data.toString().startsWith("-")) {
        return data.toString().replace("-", "");
      }
      return data + "%";
    } else {
      return data + "%";
    }
  }
  };
 


  // Customizable Area Start

  // Customizable Area End
}
