// Customizable Area Start
import React from "react";
import "./index.css";
import {Row, Dropdown, Menu } from 'antd';
import { Filter_black, nxzloader, triangle } from "../assets";
import Videos from "./Videos/index.web";
import Songs from "../../../../components/src/CustomChart/Songs/Songs.web";
import 'react-toastify/dist/ReactToastify.css';
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import ChartController from './ChartController.web';
import { Box } from "@material-ui/core";
import { ChartTemplate } from "components/src/CustomChart/ChartTemplate.web";
import TabList from "components/src/CustomChart/TabList.web";

export default class Charts extends ChartController {
   menu = () => {
    return (
      <div>
        <Menu className="custom-drop-menu">
          <Menu.Item key="0" className="chartfiltermenu" onClick={this.handleLoginFilter.bind(this,"today")} >Today</Menu.Item>
          <Menu.Item key="1" className="chartfiltermenu"  onClick={this.handleLoginFilter.bind(this,"this_week")}>This week</Menu.Item>
          <Menu.Item key="3" className="chartfiltermenu"  onClick={this.handleLoginFilter.bind(this,"this_month")}> This month</Menu.Item>
          <Menu.Item key="4" className="chartfiltermenu"  onClick={this.handleLoginFilter.bind(this,"this_year")}>This Year</Menu.Item>

        </Menu>
      </div>
    );
  };
   LoadingScreen = () => (
    <Row className="chartloading">
      <img src={nxzloader} style={{ width: "5%" }} />
    </Row>
  );
  render() {

    this.menu()
    const { songsList, hooksList, beatsList, versesList, playList, Top100List, isPlaylist, handleplay,
      playlistData, loading, alertPopup, open, dialogText,  songIndex, active, filterValue, hideDownloadInPlaylist
    } = this.state
    const token: any = localStorage.getItem("token")

   

   

    const handleBeatsList=()=>{
      return beatsList?.length === 0 && loading ? (
        <Row className="chartloading">
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
        <Songs
          listOfSongs={beatsList}
          playPlaylist={isPlaylist}
          playlistData={playlistData}
          name="Beats" songIndex={songIndex}
          setDialogText={this.setDialogText}
          setOpen={this.setOpen}
          handleplay={handleplay}
          handleAudioVideo={this.state.handleAudioVideo}
          hideDownloadInPlaylist={false}
        />
      );
    }

    const handleHookList=()=>{
      return hooksList?.length < 1 && loading ? (
        <Row className="chartloading">
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
        <Songs
          listOfSongs={hooksList}
          playPlaylist={isPlaylist}
          name="Hooks"
          playlistData={playlistData}
          songIndex={songIndex}
          setDialogText={this.setDialogText}
          setOpen={this.setOpen}
          handleplay={handleplay}
          handleAudioVideo={this.state.handleAudioVideo}
          hideDownloadInPlaylist={false}
        />
      );
    }

    const screenTab=()=> {
      if(token) {
        if(Top100List?.length === 0 && loading) {
        return this.LoadingScreen()
        }
       else 
      {
      return  <Videos/>
      }
      
      } else {
        if(playList?.length === 0 && loading) {
          return (
            <Row className="chartloading">
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          )
        } else {
          return (
            <Songs
              listOfSongs={playList}
              handlePlayList={this.handleRedirectPlaylist}
              name="Playlists"
              setDialogText={this.setDialogText}
              songIndex={songIndex}
              setOpen={this.setOpen}
              playlistData={{}}
              hideDownloadInPlaylist={hideDownloadInPlaylist}
            />
          )
        }
      }
    }
      

    const handleVerseList=()=>{
      return  versesList?.length === 0 && loading ? (
        <Row className="chartloading">
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
        <Songs
          listOfSongs={versesList}
          playPlaylist={isPlaylist}
          name="Verses"
          playlistData={playlistData}
          songIndex={songIndex}
          setDialogText={this.setDialogText}
          setOpen={this.setOpen}
          handleplay={handleplay}
          handleAudioVideo={this.state.handleAudioVideo}
          hideDownloadInPlaylist={false}
        />
      )
    }
    const screen = () => {


      switch (active) {
        case "":
        case 0:
          return handleBeatsList()
        case 1:
          return handleHookList()
        case 2:
          return handleVerseList()
        case 3:
          return  songsList?.length === 0 && loading ? (
            <Row className="chartloading">
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          ) : (
            <Songs listOfSongs={songsList}
              playPlaylist={isPlaylist}
              name="Songs"
              playlistData={playlistData}
              songIndex={songIndex}
              setDialogText={this.setDialogText}
              setOpen={this.setOpen}
              handleplay={handleplay}
              handleAudioVideo={this.state.handleAudioVideo}
              hideDownloadInPlaylist={false}
            />
          )
        case 4:
          return <> {screenTab()} </>;

        case 5:
          return playList?.length === 0 && loading ? (
            <Row className="chartloading">
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          ) : (
            <Songs listOfSongs={playList}
              handlePlayList={this.handleRedirectPlaylist}
              name="Playlists"
              setDialogText={this.setDialogText}
              songIndex={songIndex}
              setOpen={this.setOpen}
              playlistData={{}}
              hideDownloadInPlaylist={hideDownloadInPlaylist}

            />
          )
        default:
          return null;
      }
    }


    return (
      <>
        <Box
          className="chartMain"
        >
          <ChartTemplate isPlaylist={isPlaylist} active={active}>
            <h1 style={{ marginBottom: "0px", fontFamily: "Nunito" }}>Charts</h1>
            <div className="chats-header ">
              <TabList active={active} callback={this.callback} />

              <div style={{ position: "relative" }}>
                <Dropdown
                  overlay={this.menu}
                  trigger={["click"]}

                  getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  <div
                    className="ant-dropdown-link filter_design"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      src={Filter_black}
                      style={{ height: "10px", width: "11px" }}
                      alt=""
                    />
                    <p style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins" }}>{filterValue}</p>
                    <img
                      src={triangle}
                      style={{ height: "10px", width: "11px" }}
                      alt=""
                    />
                  </div>
                </Dropdown>
              </div>
            </div>

            {/* End of tab */}
            {screen()}
          </ChartTemplate>
        </Box>
        {open && <LoginAlert open={open} handleClose={this.handleClose} dialogText={dialogText} />}

        {alertPopup && <LoginAlert open={open} handleClose={this.handleClose} dialogText={dialogText} />}
      </>
    );
  };
}
// Customizable Area End
