// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { defaultProfile, playButton } from "../../dashboard/src/assets";

import ProfileMessageCardController from "./ProfileMessageCardController";

export const configJSON = require("./config.js");

export const PlaylistTrackImg = ({
  image,
  onClickData,
}: {
  image: string;
  onClickData: any;
}) => {
  if (image) {
    return (
      <Box
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          position: "relative",
          cursor:"pointer"
        }}
        id="playlistMsgCard"
        onClick={onClickData}
      >
        <img
          style={{
            height: "90px",
            width: "90px",
            borderRadius: 3,
            padding: "0px 2px",
          }}
        id="playlistMsgCardImg"
          src={image}
        />
        <img
          style={{
            height: 24,
            width: 24,
            position: "absolute",
          }}
          src={playButton}
        />
      </Box>
    );
  } else {
    return (
      <Box
        style={{
          height: "90px",
          width: "90px",
          borderRadius: 3,
          padding: "0px 2px",
        }}
        id="playlistMsgCardImg1"

      />
    );
  }
};


export default class PlaylistMsgCard extends ProfileMessageCardController {
  render() {
    const {
      isRecived,
      avtar,
      onRedirect,
      particularUserDataRes,
      isChartMessage,
    } = this.props;

    const { userData, playlistTracks, isListner } = this.state;

    const user_type: any = localStorage.getItem("user_type");
    const user_id: any = localStorage.getItem("loggedIn_userId");

    let currentuserType = isRecived
      ? particularUserDataRes?.attributes?.artist_role
      : user_type;
    let currentUserId = isRecived ? particularUserDataRes?.id : user_id;
    return (
      <div
        style={{
          height: isChartMessage ? "none" : "100%",
          width: "100%",
          padding: "5px 0px 20px 30px",
          display: "flex",
          alignItems: isRecived ? "flex-start" : "flex-end",
        }}
      >
        <Box
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 15,
            backgroundColor: "#141414",
            position: "relative",
            padding: "10px",
          }}
        >
          <img
            style={{
              height: 60,
              width: 60,
              borderRadius: 60,
              borderWidth: 5,
              borderColor: "#141414",
              position: "absolute",
              marginTop: 100,
              marginLeft: -30,
              zIndex: 99,
              backgroundColor: "#141414",
              padding: 10,
            }}
            onClick={() => {
              this.props.onRedirect(currentuserType, currentUserId);
            }}
            data-test-id="redirect"
            src={avtar ? avtar : defaultProfile}
          />

          <Box
            style={{
              // marginTop: 10,
              marginLeft: 10,
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              style={{
                height: 29,
                width: 29,
                borderRadius: 29,
              }}
              onClick={() => {
                onRedirect(userData?.attributes?.user_type, userData?.id);
              }}
              data-test-id="redirect1"
              src={userData?.attributes?.user_image || defaultProfile}
            />
            <Box style={{ marginLeft: 10 }}>
              <Typography
                style={{ color: "#fff", fontSize: 12, fontWeight: "bold" }}
              >
                {userData?.attributes?.user_name}
              </Typography>
            </Box>
          </Box>
          <Box style={{ margin: 10 }} id="test-playlist">
            {playlistTracks?.length > 0 ? (
              <Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <PlaylistTrackImg
                    image={playlistTracks[0]?.attributes?.art_work}
                    onClickData={() => this.onTrackClick(playlistTracks[0])}
                  />
                  <PlaylistTrackImg
                    image={playlistTracks[1]?.attributes?.art_work}
                    onClickData={() => this.onTrackClick(playlistTracks[1])}
                  />
                  <PlaylistTrackImg
                    image={playlistTracks[2]?.attributes?.art_work}
                    onClickData={() => this.onTrackClick(playlistTracks[2])}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 3,
                  }}
                >
                  <PlaylistTrackImg
                    image={playlistTracks[3]?.attributes?.art_work}
                    onClickData={() => this.onTrackClick(playlistTracks[3])}
                  />
                  <PlaylistTrackImg
                    image={playlistTracks[4]?.attributes?.art_work}
                    onClickData={() => this.onTrackClick(playlistTracks[4])}
                  />
                  <PlaylistTrackImg
                    image={playlistTracks[5]?.attributes?.art_work}
                    onClickData={() => this.onTrackClick(playlistTracks[5])}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  height: 160,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography style={{ color: "#fff" }}>
                  No Tracks Found!{" "}
                </Typography>
              </Box>
            )}
          </Box>

          <div
            onClick={() => {
              this.handleTrackAndVideo();
            }}
            data-test-id="testing"
            style={{
              height: 33,
              backgroundColor: "#000",
              borderRadius: 33,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              style={{ color: "#fff", fontWeight: "bold", cursor: "pointer" }}
              onClick={this.handleTrackAndVideo}
            >
              {isRecived
                ? "You're invited"
                : `${particularUserDataRes?.attributes?.artist_name} is invited`}
            </Typography>
          </div>
        </Box>
      </div>
    );
  }
}
// Customizable Area End
