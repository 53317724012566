// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { HISTORY } from "components/src/common";
import { openNotification } from "../../../../components/src/Notification.web";
import { getStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../../../../blocks/dashboard/src/config.js");

interface Level {
    name: string;
    subscription: string;
    enddate: string
}

interface S {
    selectedTab: string,
    apiToken: any;
    levels: Level[],
    active: number,
    contentName: any;
    content1: any;
    content2: any;
    unlockUserId: any;
    unlockTrackId: any;
}

interface SS {
    id: any;
}
export interface Props {
    navigation: any;
    id: string;
}

export default class StreamUnlockController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiUnlockContentTrackCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            selectedTab: "",
            apiToken: localStorage.getItem('token'),
            active: 1,
            levels: [{ name: "L1", subscription: "50 Streams / Mo", enddate: "50 Streams / Mo" }, { name: "L2", subscription: "100 Streams / Mo", enddate: "100 Streams / Mo" },
            { name: "L3", subscription: "200 Streams / Mo", enddate: "200 Streams / Mo" }
            ],
            contentName: 'L1',
            content1: 'This level unlocks L1',
            content2: 'content only.',
            unlockUserId: '',
            unlockTrackId: ''
            

        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const unlock_details = await getStorageData('unlock_details')
        if(unlock_details){
          this.setState({unlockTrackId: unlock_details.track_id, unlockUserId: unlock_details.user_id, selectedTab: unlock_details.level})
          if(unlock_details.level == 'L2'){
            this.setState({contentName: 'L2', content1: 'This level unlocks L1 and L2', content2: 'premium content.'})
          }else if(unlock_details.level == 'L3'){
            this.setState({contentName: 'L3', content1: 'This level unlocks all', content2: 'premium content from this user.'})
          }
      }
    }

    selectLevels = (level: string) => {
        if(level == 'L2'){
            this.setState({contentName: 'L2', content1: 'This level unlocks L1 and L2', content2: 'premium content.'})
        }else if(level == 'L3'){
            this.setState({contentName: 'L3', content1: 'This level unlocks all', content2: 'premium content from this user.'})
        }
        else{
            this.setState({contentName: 'L1', content1: 'This level unlocks L1', content2: 'content only.'})
        }

        this.setState({ selectedTab: level })
    }

    backClick = () =>{
      HISTORY.push(`/home`);
    }

    redirectProfileRedirect = () =>{
        const userTypeForProfileRedirection = localStorage.getItem('user_type')
        const loggedInUserForProfileRedirection = localStorage.getItem('loggedIn_userId')

        if(userTypeForProfileRedirection?.includes("creative")){
            HISTORY.push(`/creatives/beats?creative_id=${loggedInUserForProfileRedirection}`)
        }
        else if(userTypeForProfileRedirection?.includes("listener")){
            HISTORY.push(`/listeners/activity?listener_id=${loggedInUserForProfileRedirection}`)
        } 
        else if(userTypeForProfileRedirection?.includes("label")){
            HISTORY.push(`/labels/creatives?labels_id=${loggedInUserForProfileRedirection}`)
        }
        else{
            HISTORY.push(`/originals/beats?originals_id=${loggedInUserForProfileRedirection}`)
        }
      
      }

    nextscreen = () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': this.state.apiToken
          };
          
          
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
        
          this.apiUnlockContentTrackCallId = requestMessage.messageId;
        
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_audiomusic/unlock_track_for_listner?user_id=${this.state.unlockUserId}&track_id=${this.state.unlockTrackId}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
        
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
           
        } 

       checkErrorMessage = (responseJson: any) =>{
         if(responseJson.message == 'Play more to unlock song'){
            if(this.state.contentName == 'L1'){
                openNotification('You need 50 streams per month to unlock L1', 'error')
            }else if(this.state.contentName == 'L2'){
                openNotification('You need 100 streams per month to unlock L2', 'error')
            }else{
                openNotification('You need 200 streams per month to unlock L3', 'error')
            }
         }else{
            openNotification(responseJson.error, 'error')
         }
       }

        getUnlockContentSuccessCall = (responseJson: any) =>{
          if(responseJson.message == 'Song unlocked successfully'){
            HISTORY.push(`/confirmation`);
            openNotification(responseJson.message, 'sucess')
          }else{
            this.checkErrorMessage(responseJson);
          }
        }

        async receive(from: string, message: Message) {

            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
              let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
              );
              const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
              if (apiRequestCallId === this.apiUnlockContentTrackCallId) {
                this.getUnlockContentSuccessCall(responseJson)
                this.parseApiCatchErrorResponse(errorReponse);
              } 
              
            }
          }
}



StreamUnlockController.contextType = AudioContextApi;
// Customizable Area End