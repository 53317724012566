// Customizable Area Start
import React from "react";
import { Grid, Avatar } from "@material-ui/core";
import { chartcover } from "blocks/dashboard/src/assets";

export const CommentReplay = ({
  avatarSrc,
  name,
  time,
  duration,
  text,
}: any) => {
  return (
    <>
      <Grid
        container
        item
        // direction='row'
        justifyContent="flex-end"
        style = {{ margin: "5px 0px", width: '100%' }}
      >
        <Grid
          justifyContent="flex-end"
          item
          style = {{ marginTop: "4px", justifyContent: 'flex-start' , width: '12%' }}
        >
          <Avatar
            alt="Remy Sharp"
            src={avatarSrc}
            className="comment_avatar"
          />
        </Grid>
        <Grid
          item
          className="user_comment_msg"
        // alignContent="space-between"
        style = {{ width: '88%' }}
        >
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
          >
            <Grid container item xs={9}>
              <span className="comment_username">{name}</span>
            </Grid>
            <Grid container item xs={3} justifyContent="flex-end">
              <span className="comment_time_font">{duration}</span>
            </Grid>
            <span className="comment_msg">{text}</span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
// Customizable Area End
