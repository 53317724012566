// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
export interface Props {
  planSubscription:any;
  nextPaymentDue:any;
  handleClose: () => void;
  updateSubscription: () => void;
  openConfirmPaymentPopup:any;
  closePayModal: () => void;
  currentPaymentData:any;
  CreateNewPayment:any;
  isCurrentPlan:any;
  next_invoice_no:any;
  planName:any;
}
interface S {
}
interface SS {
  id: any;
}

export default class ConfirmPlanAndPayController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

   

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
 
   
  }

  // Customizable Area End
  handleButtonClick() {
    const { currentPaymentData, isCurrentPlan } = this.props;
  
    if (Object.keys(currentPaymentData).length <= 1) {
      this.updateSubscriptionAndClose();
    } else {
      if (!isCurrentPlan) {
        this.createAndClose();
      } else {
        this.updateSubscriptionAndClose();
      }
    }
  }

  updateSubscriptionAndClose() {
    this.props.updateSubscription();
    this.props.handleClose();
  }
  
  createAndClose() {
    this.props.CreateNewPayment();
    this.props.handleClose();
  }
}
// Customizable Area End
