// Customizable Area Start
import React, { useState, useEffect,useContext, lazy, Suspense ,memo} from "react";
import { Row } from "antd";
import axios from "axios";
import { Grid, Box } from "@material-ui/core";
import { nxzloader } from "../../../dashboard/src/assets";
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import { AudioContextApi } from "../../../dashboard/src/AudioContext/AudioContext.web";
//@ts-ignore
const SongsList = lazy(() => import('./songsList.web'))

const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

const SongItem = (props: any) => {
  const data = props.data;
  const apiToken: any = localStorage.getItem("token")
  const handleChartTrackRepost = async () => {

    await axios
      .post(
        `${baseUrl}/bx_block_audiomusic/repost_count?id=${props.data.id}`,
        null,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: apiToken,
          },
        }
      )
      .then((res: any) => {

      })
      .catch((err) => {
      })
      .finally(() => {
      });
  };
  return (

    <div className="width_100" >
      <div className="beats-image width_100" style={{ overflow: "hidden" }}>
      <Suspense fallback={<div>Loading...</div>}>
        <SongsList
          playlistData={props.playlistData}
          handleChartTrackRepost={handleChartTrackRepost}
          data={data}
          name={props.name}
          mainList={props.allSongs}
          songIndex={props.songIndex}
        />
        </Suspense>
      </div>
    </div>
  );
};


const AllTypeSongs = (props: any) => {
  const [dialogText, setDialogText] = useState('')
  const [open, setOpen] = useState(false)
  let [allSongs, setAllSongs] = useState<any>([])
  //@ts-ignore
  const {isPlaylist} = useContext(AudioContextApi);

  const { songs, playlistData, name, songIndex, loading, type } = props

  const userType = localStorage.getItem('user_type')
  const subscriptionPlan = localStorage.getItem("subscription_plans")
  const getSlug = window.location.href.split('/')


  const apiToken: any = localStorage.getItem("token")

  const _settings = {
    autoplay: false,
    dots: false,
  };

  const getLisnerValidation = () => {
    if (apiToken) {
      if (userType == "listener" && subscriptionPlan == "NXZ") {
        setDialogText("3")
        setOpen(true)
      }
      else {
        setAllSongs(songs);
      }
    }
  }

  useEffect(() => {

    if (getSlug[4] == 'search') {
      if (getSlug.length != 0 && getSlug[5] != '') {
        if (getSlug[5] == ('beats') || getSlug[5] == ('hooks') || getSlug[5] == ('verses')) {
          getLisnerValidation()
        }
        else {
          setAllSongs(songs);
        }

      }
    }


  }, [songs])

  
  const handleLoginClose = () => {
    setOpen(false)
  }


  return (
    <>
      {loading ? (

        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>

      ) :

        (
          //mostly-customized-scrollbar
          <Box className={isPlaylist?" main-scroll-withaudio  ":" search-main-scroll" } >
            <Row
              justify="center"
              style={{
                marginRight: "10px",
              }}
            >
              {/* <div className="customCarousel width_100"> */}

              {

                (allSongs.length !== 0) ?

                  <Grid container style={{ padding: "0px 0px 4px 0px" }} className=" width_100 mt_3">
                    {allSongs
                      .map((_data: any, index: any) => {
                        return (
                          // <Col span={24} style={{ padding: "0px" }} className="musicRow">

                          <Grid container style={{ padding: "6px 0px 4px 0px" }} className="search-container-space">
                            <SongItem mainList={allSongs} data={_data} key={index} playlistData={playlistData} name={name} allSongs={allSongs} songIndex={songIndex} />
                          </Grid>
                          //  </Col>
                        );
                      })}
                  </Grid>

                  :
                  (<div style={{ textAlign: 'center', color: 'grey', fontSize: '20px', fontWeight: 'bold', marginTop: '200px' }}>NO {type} FOUND!</div>)
              }
              {open && <LoginAlert open={open} handleClose={handleLoginClose} dialogText={dialogText} />}

            </Row>
          </Box>
        )

      }
    </>
  );

}
export default AllTypeSongs;
// Customizable Area End