// Customizable Area Start
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';
import AudioVideoInfoCardCP from '../Common/AudioVideoInfoCardCP.web';
import { dialogStyles, SlideTransition } from '../Common/Common.web';
// Customizable Area End


// Customizable Area Start
interface Props {
    isOpen: boolean;
    media: any;
    closeMediaDialog: () => void;
    selectedTrackMediaDetails: any;
    selectedTrackStatsDetails: any;
    handleAddNewUser:any;
    setTrackIndex:any;
    isShowMsgModal:any;
    closeNewMessage:any;
    openNewMessage:any;
}
// Customizable Area End

// Customizable Area Start
export const MediaProfileDialogCP = (props: Props) => {

    const classes = dialogStyles();
    return (
        <Dialog fullScreen open={props.isOpen} onClose={props.closeMediaDialog} TransitionComponent={SlideTransition} className='CommentChatDialog'>
            <AppBar className={classes && classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.closeMediaDialog} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes && classes.title}>
                        Comments
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh', flex: 1, padding: '0px 8px' }}>
                <Box className='CommentChatBox'>
                    <AudioVideoInfoCardCP
                        media={{}}
                        mediaType="audio"
                        playMedia={(media: any, type: string) => { }}
                        viewProfile={() => { }}
                        selectedTrackMediaDetails={props.selectedTrackMediaDetails}
                        selectedTrackStatsDetails={props.selectedTrackStatsDetails}
                        handleAddNewUser={props.handleAddNewUser}
                        setTrackIndex={props.setTrackIndex}
                        isShowMsgModal={props.isShowMsgModal}
                        closeNewMessage={props.closeNewMessage}
                        openNewMessage={props.openNewMessage}
                    />
                </Box >
            </Box>
        </Dialog >
    );
}
// Customizable Area End


// Customizable Area Start
export default MediaProfileDialogCP;
// Customizable Area End
