Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config")

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";

exports.getSubscriptionsData = "/bx_block_subscription_plan/all_subscriptions";
exports.getCurrentPaymentData = "/bx_block_subscription_plan/card_details";
exports.getCardUpdate = "/planpayments?CardUpdate={Success}";
exports.getSubscriptionCardUpdate = "/bx_block_subscription_plan/update_card";
exports.getDefaultCard = "/bx_block_subscription_plan/set_default_card";
exports.getAddNewSubscription = "/bx_block_subscription_plan/add_new_subscription";
exports.getAfterAddSubscription = "/bx_block_subscription_plan/update_subscription";
exports.cancelAddSubscription = "/bx_block_subscription_plan/cancel_subscription";
exports.updateSubscription = "/bx_block_subscription_plan/update_user_subscription";
exports.createNewPayment = "/bx_block_subscription_plan/new_subscribe";


exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.deleteAPIMethod = "DELETE";



exports.baseUrl = urlConfig.baseURL;
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End