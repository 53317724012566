// Customizable Area Start
import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Comment from '../Common/Comment.web';
import CommentSendInput from '../Common/CommentSendInput.web';

interface Props {
    selectedComment: any;
    selectedTrack: any;
    setOnChangeNewComment: any;
    sendNewComment: any;
    newcomment: any;
    selectedComentReply: any;
    selectedCommentForReply: any
    closeCommentReply: any;
    commentEndRef ?: any;
}

export class CommentsChatWeb extends Component<Props> {
  render() {
    const userType = localStorage.getItem('user_type')

   


    return (
      <>
           {
            this.props.selectedTrack != '' ?
           (
            <>
            <Typography variant="h6" style={{color: 'white', fontSize: '16px', fontWeight: 'bold', marginLeft: '10px'}} >
              <span style={{color: 'blue', fontSize: '16px', fontWeight: 'bold'}}>{this.props.selectedComment.length}</span>  Comments
            </Typography>
              
            <Box  style={{ justifyContent:'center', alignItems:'center', width:'100%', height:'100%', flexGrow: 1, padding:'0px 8px', overflow: 'auto' }}>
                <Box className='CommentChatBox' style={{width: '100%', position: 'relative', height:'100%'}}>
                    <Box className='CommentContainerScroll CommentChatContainer lastCommentHeight' style={{ overflowX: 'hidden', margin: '0px', height:'100%', overflow: 'auto'}}>
                      <div id='scroll-new-comment' ref={this.props.commentEndRef}></div>
                      {
                        this.props.selectedComment.map((comments: any, index: any) => {
                         return(
                         <>
                           <Comment selected={false} selectedComment={comments} unread={true} isVideo={true} onUserCommentClick={() => { }} onUserProfileClick={() => { }} selectedComentReply={(comment: any) => {this.props.selectedComentReply(comment)}} index = {index} commentEndRef={this.props.commentEndRef}/>
                          </>
                         )
                        })
                      }
                    </Box>
                    <Box className='CommentChatInput' style={{position: 'absolute', width: '100%', bottom: 7, background: 'black'}}>
                        <CommentSendInput
                            value={this.props.newcomment}
                            sendComment={(value: string) => { this.props.sendNewComment(value) }}
                            onValueChange={(value: string) => { this.props.setOnChangeNewComment(value) }}
                            selectedCommentForReply={this.props.selectedCommentForReply}
                            closeCommentReply = {this.props.closeCommentReply}
                        />
                    </Box>
                </Box >
            </Box>
            </>
           )
           :(
            <> 
            {
              userType == 'listener' ? 
              (<>
              <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{color: 'white', fontSize: '24px', fontWeight: 'bold'}}> No Replies Selected</div>
                <div style={{color: 'gray', fontSize: '16px', fontWeight: 600}}> Select a reply to show comments</div>
              </div>
              </>)
              :
              (
                <> 
                <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{color: 'white', fontSize: '24px', fontWeight: 'bold', marginTop: '100px'}}> No Comments Selected</div>
                <div style={{color: 'gray', fontSize: '16px', fontWeight: 600}}> Select a track to show comments</div>
              </div>
                </>
              )
            }
              
            </>
           )
            }
      </>
    )
  }
}

export default CommentsChatWeb
// Customizable Area End