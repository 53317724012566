// Customizable Area Start
import React from 'react';
import { Typography, Avatar } from "@material-ui/core";
import './UserCard.web.css'
// Customizable Area End


// Customizable Area Start
interface Props {
    user: any;
    selected: boolean;
    onClickUser?: any;
    clickable: boolean
}
// Customizable Area End

// Customizable Area Start
export const UserCard = (props: Props) => {
    const { user, selected, clickable, onClickUser } = props;
    const handleUserClick = () => {
        if (clickable) {
           onClickUser(user)
        }
    }
    return (
        <>
            <div className={`UserCardCommon ${selected && 'Active'} ${clickable && 'CursorPointer'}`} onClick={handleUserClick}>
                <div className="ContentDiv">
                    <Avatar src={user?.artist_image || user[0]?.artist_image} className="UserIcon" />
                    <div className="UserInfoDiv">
                        <div className="ColumnCenter">
                            <Typography className="UserNameText">
                                {user?.user_name || user[0]?.user_name}
                            </Typography>
                            <Typography className="UserTypeText" style={{color: 'gray'}}>{user?.user_role?.name || user[0]?.user_role?.name}</Typography>
                        </div>
                        <div className="ColumnCenter">
                            <Typography className="UserEquityText">
                                {user?.percentage || user[0]?.percentage}%
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default UserCard;
// Customizable Area End
