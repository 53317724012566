// Customizable Area Start
import {
    Box,
    styled,
    Button,
    Typography,
    Hidden
} from "@material-ui/core";
import React from 'react'
import { confirmation } from '../assets';
import StreamUnlockController from "./StreamUnlockController.web";
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import { Content } from 'antd/lib/layout/layout'
import { Layout } from 'antd';
const StreamunlockRightsideContainer = styled(Box)({
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const StreamConfirmationMainBox = styled(Box)({
    width: "100%",
    display: "flex",
    height: "90vh",
    overflowY: "scroll",
    padding: "15px 30px 0px 50px",
    gap: "40px",
    "@media(max-width: 1000px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const StreamunlockconfirmLeftsidebox = styled(Box)({
    width: "50%",
    "@media(max-width: 1000px)": {
        width: "100%"
    },
});

export default class StreamUnlockConfirmation extends StreamUnlockController {
    render() {

        return (
            <>
                <Layout className='streamRepostWrapper'>
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        style={{
                            margin: "0px",
                            backgroundColor: "black",
                            display: "flex", flexDirection: "column",
                        }}
                    >
                        <HomeHeader location="RepostHistory" />
                        <StreamConfirmationMainBox className='streamRepostscroll'>
                            <StreamunlockconfirmLeftsidebox>
                                <Box>
                                    <img src={confirmation} style={{
                                        width: "100%",
                                        gap: "0px",
                                        height: "900px"
                                    }}
                                        className='repostimg' />
                                </Box>
                            </StreamunlockconfirmLeftsidebox>

                            <StreamunlockRightsideContainer >
                                <Box style={webStyle.rightsideInnerbox}>
                                    <Box style={webStyle.rightsidefirstbox}>
                                        <Typography style={webStyle.unlockText}>
                                            TAP IN
                                        </Typography>
                                        <Button style={webStyle.completebutton}>
                                            COMPLETE
                                        </Button>
                                        <Typography style={webStyle.content}>
                                            Continue streaming this creative
                                        </Typography>
                                        <Typography style={webStyle.content}>
                                            to unlock their locked content.
                                        </Typography>
                                        <Typography style={webStyle.content1}>
                                            Check your streaming history to see how
                                        </Typography>
                                        <Typography style={webStyle.content1}>
                                            close you are to unlocking everything
                                        </Typography>
                                    </Box >
                                    <Box style={{ width: "100%", display: 'flex', flexDirection: "column", placeItems: "center", gap: "5px" }}>
                                        <Button style={webStyle.seehistorybutton}>
                                            See History
                                        </Button>
                                        <Button style={webStyle.myprofilebutton} onClick={this.redirectProfileRedirect}>
                                            My profile
                                        </Button>
                                    </Box>
                                </Box>
                            </StreamunlockRightsideContainer>
                        </StreamConfirmationMainBox>
                    </Content>
                </Layout>

            </>

        )
    }
}

const webStyle = {
    rightsideInnerbox: {
        width: "80%",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "30px"
    },
    rightsidefirstbox: {
        width: "100%",
        display: "flex",
        placeItems: "center",
        flexDirection: "column" as "column",
        gap: "5px"
    },
    unlockText: {
        fontFamily: "Inter",
        fontSize: "48px",
        fontWeight: 900,
        lineHeight: "58.09px",
        textWrap: "nowrap" as "nowrap",
        color: "#FFFFFF"
    },
    content: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px",
        color: "#FFFFFF"
    },
    content1: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#8F92A180"
    },
    subcrictionratetext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#FFFFFF",
        paddingLeft: "80px",
        textWrap: "nowrap" as "nowrap"
    },
    seehistorybutton: {
        fontFamily: "Karla",
        fontSize: "20px",
        fontWeight: 800,
        lineHeight: "22px",
        color: "#FFFFFF",
        width: "260px",
        height: "60px",
        textTransform: "capitalize" as "capitalize",
        background: "#FF0000",
        borderRadius: "60px",

    },
    completebutton: {
        fontFamily: "Karla",
        fontSize: "20px",
        fontWeight: 800,
        lineHeight: "15px",
        color: "#B3140A",
        width: "260px",
        height: "60px",
        textTransform: "capitalize" as "capitalize",
        background: "#070707",
        borderRadius: "31px",
        border: "2px solid #212121",
        letterSpacing: "10px"
    },
    myprofilebutton: {
        fontFamily: "Karla",
        fontSize: "20px",
        fontWeight: 800,
        lineHeight: "22px",
        color: "#FFFFFF",
        width: "260px",
        height: "60px",
        background: "#212121",
        borderRadius: "60px",
        textTransform: "capitalize" as "capitalize",
    },
};

// Customizable Area End