// Customizable Area Start
import React, { useRef, useEffect } from "react";
import { playNow, loopIc, down, iconUp, playlistMoreTwoDots, songPlay } from "../../../dashboard/src/assets";
import { Typography, Grid, Box, Card } from "@material-ui/core";
import "./CardItem.web.css";
import millify from "millify";
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
import axios from "axios";
import { useHistory } from "react-router-dom";
import { setStorageData } from "../../../../framework/src/Utilities";
import { HISTORY } from "components/src/common";


const img = {
 width: "320px",
 height: "200px",
 borderRadius: "12px",
};


const VideosList = ({ id, image, play, name, artist, type, refresh, handlePlayList, selected, cardId, setSelected, setHandleAudioVideo, trackFile, handleAudioVideo, currentList, videoList, arrow, lockLevel, lockContentUserId,unlockedTrack }: any) => {
 const vedioPlayer: any = useRef();


 const [hide, setHide] = React.useState(true)
 const history = useHistory();
 const video_List_loggedIn_userId = localStorage.getItem('loggedIn_userId')


 const handlerSingleVideos = async (id: any, trackFile: any, type: any) => {
   let outputData
   if (type == "Video") {
    if(lockLevel && video_List_loggedIn_userId != lockContentUserId && !unlockedTrack){
      const levelVideo = levelVideoReturn(lockLevel)
      const unlock_video_data = {track_id: id, user_id: localStorage.getItem('loggedIn_userId'), level: levelVideo}
      setStorageData('unlock_details', unlock_video_data)
      HISTORY.push('/streamUnlock')
    }else{
     const token: any = localStorage.getItem("token")
     await axios.get(`${baseUrl}/bx_block_audiomusic/expan_track?id=${id}`, {
       headers: {
         "Content-Type": "application/json;charset=UTF-8",
         token: token,
       }
     })
       .then((response: any) => {
         outputData = response?.data?.data
       }).catch((err: any) => {
       })
       history.push({
         pathname: "/home/Charts/videos",
         state: { video_list: trackFile, activeNo: "5", data: outputData, currentList: videoList ? videoList : [] }
       })
    }
   } else {
     const playlistUser = localStorage.getItem('user_id')
     if(playlistUser == video_List_loggedIn_userId){
     localStorage.setItem("playlist_type", "Private")
       history.push({
         pathname: `/home/self-view/${id}`,
         state: { creativeId: id }
       })
      }else{
        localStorage.setItem("playlist_type", "Public");
        history.push({
          pathname: `/home/playlist/public-view/${id}`,
          state: { creativeId: id }
        });
      }    
   }
 }

 const levelVideoReturn = (item: any) =>{
  if(item == 'level_1'){
    return 'L1'
  }else if(item == 'level_2'){
    return 'L2'
  }else{
    return 'L3'
  }
}

 const pause = () => {
   vedioPlayer?.current?.pause()
   setSelected(null)
   setHide(true)

 };

 useEffect(() => {
   if (handleAudioVideo !== 1) {
     pause()
     setHandleAudioVideo(handleAudioVideo)
   }
 }, [handleAudioVideo])

 let vid = document.getElementById("myVideo");
 if (vid) {
   vid.onseeking = function (e) {
     e.stopPropagation()
   };
 }
 return (
   <div >
     <Grid
       container
       xs={12}
       className="video-top-grid"
     >
       <Card className="video-img-slider" style={{ cursor: "pointer" }}
         onClick={() => handlerSingleVideos(id, trackFile, type)}
       >
         {!hide && selected === cardId ?
           <video
             ref={vedioPlayer}
             id={cardId}
             poster={image}
             width="100%"
             height="100%"
             controls={true}
             autoPlay={!hide}
             style={{ objectFit: "cover", borderRadius: "0px" }}
             //@ts-ignore
             playsinline
           >
             <source src={trackFile} type="video/mp4" />
           </video>
           :
           <>
             <div className="pickgradient">
               <img style={{
                 width: "100%",
                 height: "100%",
                 objectFit: "cover",
                 display: "block",
                 borderRadius: "12px",
                 border:"1px solid black"
               }}
                 src={image}
               />
             </div>
             <div style={{ position: 'absolute' , height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

             <img
               id={cardId}
               style={{
                 position: "absolute",
                 height: "50px",
                 cursor: "pointer"
               }}
               onClick={(e) => {
                 handlerSingleVideos(id, trackFile, type);
               }}
               src={songPlay}
             />
             </div>
             { lockLevel && video_List_loggedIn_userId != lockContentUserId && !unlockedTrack &&
             <div style={{ position: 'absolute' , height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span style={{color: 'white', border: '2px solid white', borderRadius: '50%', backgroundColor: 'red', padding: '10px', fontSize: '12px', fontWeight: 'bold'}}>
                  {levelVideoReturn(lockLevel)}
                </span>
              </div>
             }
           </>
         }

         <div
           className="songs-left"
         >
           <p
             className="card-name songs-called">
             {cardId + 1}

             {" "}
             {name || "Unknown"}{" "}
           </p>
           <Grid
             container
             xs={12}
             className="flex"
           >
             <Grid item xs={5}>
               <div style={{ display: "flex" }}>
                 <Typography
                   className="desc videoList-desc"
                   style={{ display: "flex" }}
                 >
                   <img src={arrow ? iconUp : down} className="icon-up-videos mr_05 " />  {artist || "Unknown"}
                 </Typography>
               </div>
             </Grid>
             <Grid item xs={5} sm={3} className="videoList-flex top-grid-video">
               <Grid item xs={6} className="flex">
                 <img src={playNow} className="vide-play-refresh" />
                 <Box className="play-times-videos videoList-color" style={{
                   marginLeft: "-5px", marginRight: "4px"
                 }} >
                   {millify(play) || "0"}
                 </Box>
               </Grid>
               {type != "playlist" &&
               <Grid item xs={6} className="flex">
                 <img src={loopIc} style={{
                   height: "17px",
                   width: "17px",
                   borderRadius: "1px",
                   marginRight: "3px"
                 }} />
                 <Box className="play-times-videos videoList-color" >
                   {refresh || "0"}
                 </Box>
               </Grid>
}
             </Grid>
             <Grid item sm={3} style={{ justifyContent: "flex-end", display: "flex", marginTop: "-10px" }}>
               <img src={playlistMoreTwoDots} style={{ height: '22px',cursor:"pointer" }} onClick={() =>
                {

                 handlerSingleVideos(id, trackFile, type)
               }}                 
                 />               
             </Grid>
           </Grid>
         </div>
       </Card>
     </Grid>
   </div>
 );
};
export default VideosList;
// Customizable Area End