// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

export interface Props {
   
    selectedPage:string;
    selectComment: (page:string) => void;
    selectDM: (page:string) => void;
}

interface S {
    expanded: number;
}
interface SS {
  id: any;
}

export default class CommentDMSelectorController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiHandleCopyCountCallId: any;
  apiHandleVideoListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
        expanded: 0,
    
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


   handleCommentSelect = (e: any) => {
    if(this.props.selectedPage !== 'comment'){
        this.props.selectComment('comment')
    }
}

 handleDMSelect = (e: any) => {
    if(this.props.selectedPage !== 'dm'){
        this.props.selectDM('dm')
    }
}


  
}
// Customizable Area End