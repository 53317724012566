// Customizable Area Start
import React from "react";
import "blocks/dashboard/assets/css/repost.css";
import { Grid, Button, ButtonGroup, Box, } from "@material-ui/core";
import 'blocks/user-profile-basic/src/Common/CardItem.web.css'
import { videoIcon, musicIcon,userDashobard,userdeal} from "blocks/dashboard/src/assets";
import './Buttongroup.css'
export default function Buttongroup({musicButton,setMusicButton,setMusicButtonTrue,setVideoButtonTrue,buttonshow,setDealertrue,width}: any) {
  console.log(musicButton,"musicButton")  
  return (
        <Grid  item md={2} xs={6} lg={2} className="audio-div">
          <Box 
          className="buttonBoxWrapper"
          style={{ width: width}}>
          <ButtonGroup  color="primary" className="mr_1"  aria-label="outlined primary button group"
        >
          <Button className="audio-button-home dashboard_audio_button"
            value="audio"
            data-test-id="audio"
            variant="contained"
            disabled={musicButton == 1}
            onClick={setMusicButtonTrue}>
            <span className="audio-text">
              <img src ={musicIcon} className="music-icons" />
            </span>
          </Button>
          <Button variant="contained"
            className="video-button-home dashboard_video_button"
            data-test-id="video"
            onClick={setMusicButton}
            disabled={musicButton == 2}
          >
            <span className="audio-text">
            <img src ={videoIcon} className="music-icons" />
            </span>
          </Button>
          <Button variant="contained"
            className="button_dashboard"
            data-test-id="user"
            onClick={setVideoButtonTrue}
            disabled={musicButton == 3}
          >
            <span className="audio-text">
            <img src ={userDashobard} className="music-icons" />
            </span>
          </Button>
          {buttonshow && 
           <Button variant="contained"
            className="button_dashboard"
            data-test-id="deal"
            onClick={setDealertrue}
            disabled={musicButton == 4}
          >
            <span className="audio-text">
            <img src ={userdeal} className="music-icons" />
            </span>
          </Button> }
         
        </ButtonGroup>
          </Box>
       
      </Grid>
    );
}
// Customizable Area End
