// Customizable Area Start
import React from "react";
import { Grid, Avatar } from "@material-ui/core";
import { defaultProfile } from "../../assets";
import { useHistory } from "react-router-dom";

export const QueueSplitProgress = ({ userTracks, userTrackLangth }: any) => {
  const history = useHistory()

  const redirectProfile = (id :any) =>{
    const userType = localStorage.getItem('user_type')
    console.log(userType, 'local storage setting')
    if(userType=="creative"){
      history.push(`/creatives/beats?creative_id=${id}`)
    } else if(userType=="listener"){
      history.push(`/listeners/activity?listener_id=${id}`)
    }else{
      history.push(`/originals/beats?originals_id=${id}`)
    }
  
  }

  return (
    <>
      <Grid item xs={12} style={{ padding: "20px 5px" }}>
        <span className="display_chart_font">Song Splits</span>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ height: "280px", overflowX: "hidden", overflowY: "auto" }}
      >
        {userTrackLangth ? (userTracks.map(function (item: any, i: any) {
          if (item?.attributes?.percentage > 0) {
            return (
              <>
                <Grid
                  container
                  item
                  key={i}
                  xs={12}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: "10px" }}
                >
                  <Grid item xs={1} test-id="queue_split_redirect_profile_test_id_0" style={{cursor: 'pointer'}} onClick={() => {redirectProfile(item?.attributes?.artist_id)}}>
                    <Avatar
                      alt="Remy Sharp"
                      src={item?.attributes?.artist_image || defaultProfile}
                      style={{ width: "42px", height: "42px" }}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    item
                    xs={8}
                    style={{ paddingLeft: "30px" }}
                  >
                    {/* <Grid item xs={7}> */}
                    <div test-id="queue_split_redirect_profile_test_id" style={{cursor: 'pointer'}} onClick={() => {redirectProfile(item?.attributes?.artist_id)}} >
                      <span
                        className="display_chart_font"
                        style={{ marginRight: "5px" }}
                      >
                        {item?.attributes?.artist_name || "unknown"}
                      </span>
                      {/* <img
                          src={validIcon}
                          alt='checkmark'
                          style={{ width: '12px', height: '12px' }}
                      /> */}
                    </div>
                    <span className="split_category_font">{item?.attributes?.artist_role || item?.attributes?.user_track_role?.name || "NA"}</span>
                    {/* </Grid> */}
                  </Grid>

                  <Grid
                    container
                    item
                    xs={3}
                    justifyContent="flex-end"
                    style={{ paddingRight: "10px" }}
                  >
                    <span className="display_chart_font">{item?.attributes?.percentage || 0}%</span>
                  </Grid>
                </Grid>
              </>
            );
          }
        })) :
          <>
            <p className="splits-not-available">Splits not available</p>
          </>}
      </Grid>
    </>
  );
};
// Customizable Area End