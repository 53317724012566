// Customizable Area Start
export const settings = {

  autoplay: false,
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 7,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 8.8,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1770,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1566,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1492,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1393,
      settings: {
        slidesToShow: 6.3,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1295,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5.6,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1255,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1180,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1094,
      settings: {
        slidesToShow: 4.6,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1038,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 4.2,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 5.3,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 895,
      settings: {
        slidesToShow: 5.3,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 878,
      settings: {
        slidesToShow: 4.6,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 790,
      settings: {
        slidesToShow: 4.3,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 735,
      settings: {
        slidesToShow: 3.8,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 604,
      settings: {
        slidesToShow: 3.3,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 545,
      settings: {
        slidesToShow: 2.6,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 514,
      settings: {
        slidesToShow: 2.4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 455,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 424,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 406,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 376,
      settings: {
        slidesToShow: 1.6,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 353,
      settings: {
        slidesToShow: 1.6,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 326,
      settings: {
        slidesToShow: 1.4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 308,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }


  ]

};

export const videosettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 4,
  initialSlide: 0,
  arrows: false,
  swipeToSlide: true,
  draggable: true,
  responsive: [

    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1861,
      settings: {
        slidesToShow: 4.2,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1756,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1684,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1610,
      settings: {
        slidesToShow: 3.8,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1553,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1457,
      settings: {
        slidesToShow: 3.3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1376,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1284,
      settings: {
        slidesToShow: 2.8,
        slidesToScroll: 3,
      }
    },

    {
      breakpoint: 1210,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1110,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1058,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1021,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2.7,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 941,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 867,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 808,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 771,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 741,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 713,
      settings: {
        slidesToShow: 1.9,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 673,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1.6,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 1,
        initialSlide: 2
      },

    },
    {
      breakpoint: 553,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      },

    },
    {
      breakpoint: 511,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
        initialSlide: 2
      },

    },
    {
      breakpoint: 465,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        initialSlide: 2
      },

    },
    {
      breakpoint: 395,
      settings: {
        slidesToShow: 1.3,
        slidesToScroll: 1,
        initialSlide: 2
      },

    },
    {
      breakpoint: 358,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        initialSlide: 2
      },

    },
    {
      breakpoint: 330,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      },

    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   }
    // }
  ]
};

export const dashboardVideos = {
  autoplay: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,

      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,

        slidesToScroll: 1,

      }
    },
    {
      breakpoint: 600,
      settings: {
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 410,
      settings: {

        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export const dashboardSongs = {
  autoplay: false,
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 7,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 3,
        dots: false,

      }
    },
    {
      breakpoint: 1770,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1560,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        dots: false,

      }
    },

    {
      breakpoint: 1393,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1318,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1255,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1132,
      settings: {
        slidesToShow: 4.2,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1094,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1032,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 870,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 790,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 696,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 668,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 602,
      settings: {
        slidesToShow: 2.9,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 2.8,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 521,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 470,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 433,
      settings: {
        slidesToShow: 1.9,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 411,
      settings: {
        slidesToShow: 1.7,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 397,
      settings: {
        slidesToShow: 1.7,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 385,
      settings: {
        slidesToShow: 1.6,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 365,
      settings: {
        slidesToShow: 1.4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1.3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 308,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]


}
// Customizable Area End