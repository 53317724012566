// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import {ArrowUp, PlayIcon, VideoUserAvatar, messageIcon, repeatIcon} from 'blocks/dashboard/src/assets'
import { defaultProfile } from '../../../dashboard/src/assets';
export const TopMobileCreatives = (props: any) =>{
    const{data} = props;

    return(
        <>
        <Grid style={{display: 'flex', flexDirection: 'column', backgroundColor: '#141414', padding: '5px 10px', borderRadius: '10px'}}>
        <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
            <Grid style={{display: 'flex'}} >
                <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{color: 'white', fontSize: '16px'}}>{props.index+1}</div>
                <img src={ArrowUp} style={{marginTop: '5px'}}/>
                </Grid>
                <Grid>
                    <img style={{height: '48px', width: '48px', borderRadius: '50%', padding: '4px', objectFit: 'cover'}} src={data?.attributes?.avatar ? data?.attributes?.avatar : defaultProfile}/>
                </Grid>
                <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', marginLeft: '10px'}}>
                    <div style={{color: 'white', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold'}}>{data?.attributes?.user_name}</div>
                    <div style={{color: 'gray', fontFamily: 'Karla', fontSize: '14px', fontWeight: 'bold'}}>{data?.attributes?.role}</div>
                </Grid>
            </Grid>
        </Grid>
        <Grid style={{display: 'flex', backgroundColor: 'black', padding: "2px", borderRadius: '10px', marginBottom: '10px', justifyContent: 'space-around'}}>
                 <Grid className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={PlayIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{data?.attributes?.total_play_count}</span>
                </Grid>
                <Grid  className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={repeatIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{ data?.attributes?.total_repost_count}</span>

                </Grid>
                <Grid  className="" style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={messageIcon} height="24px" width="24px" />
                    <span className="play-songs-times" style={{ marginLeft: "4px" }}>{data?.attributes?.total_comment_count}</span>
                </Grid>

                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <span style={{color: 'blue'}}>24h%</span>
                    <span>{data?.attributes?.last_twenty_four_hours_percentage}</span>
                    </Grid>
                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                  <span style={{color: 'blue'}}>7d%</span>
                    <span>{data?.attributes?.seven_days_percentage}</span>
                </Grid>
            </Grid>
            </Grid>
        </>
    )
}

// Customizable Area End