//Customizable Area Start
'use strict';

const baseURL = '__MARKER_FOR_BACKEND_URL_REPLACEMENT';

//  const baseURL =   'https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe';

Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.baseURL = baseURL;
//Customizable Area End
