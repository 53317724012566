// Customizable Area Start
import {
    Box,
    styled,
    Button,
    Typography,
    Hidden
} from "@material-ui/core";
import React from 'react'
import { Unlock, L1frame, L2frame } from '../assets';
import StreamUnlockController from "./StreamUnlockController.web";

const StreamunlockRightsideContainer = styled(Box)({
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const StreamUnlockMainBox = styled(Box)({
    width: "100%",
    display: "flex",
    height: "90vh",
    overflowY: "scroll",
    padding: "15px 30px 0px 50px",
    gap: "40px",

    "@media(max-width: 1000px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const StreamunlockLeftsidebox = styled(Box)({
    width: "50%",
    "@media(max-width: 1000px)": {
        width: "100%"
    },
});

export default class StreamUnlockLevels extends StreamUnlockController {
    render() {

        return (
            <>
                <StreamUnlockMainBox className='streamRepostscroll'>
                    <StreamunlockLeftsidebox>
                        <Box>
                            <img src={Unlock} style={{
                                width: "100%",
                                gap: "0px",
                                height: "900px"
                            }}
                                className='repostimg' />
                        </Box>
                    </StreamunlockLeftsidebox>

                    <StreamunlockRightsideContainer >
                        <Box style={webStyle.rightsideInnerbox}>
                            <Box style={webStyle.rightsidefirstbox}>
                                <Typography style={webStyle.unlockText}>
                                    UNLOCK {this.state.contentName}
                                </Typography>
                                <Typography style={webStyle.content}>
                                    {this.state.content1}
                                    <br />
                                    <span style={{ paddingLeft: "30px" }}>
                                        {this.state.content2}
                                    </span>
                                </Typography>
                            </Box >
                            <Box style={webStyle.streambox}>
                                {this.state.levels.map((item: any, index: number) =>
                                    <Box style={{
                                        width: "100%",
                                        height: "75px",
                                        border: item.name == this.state.selectedTab ? "3px solid #FFFFFF" : "3px solid #212121",
                                        background: "#000000",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "10px",
                                        justifyContent: "center"
                                    }} onClick={() => this.selectLevels(item.name)} data-test-id="selectedlevelsforsreamunlock">
                                        <Box style={webStyle.streamboxchild}>
                                            <img src={item.name == this.state.selectedTab ? L1frame : L2frame} style={{ height: "30px", width: "30px", borderRadius: '36px', }}

                                            />
                                            <span style={webStyle.alllevels}>{item.name}</span>
                                            <Typography style={webStyle.subcrictionratetext} className="streamText">
                                                {item.subscription}
                                            </Typography>
                                            <Hidden only={["xs"]}>
                                                <Typography>
                                                    {item.subscription}
                                                </Typography>
                                            </Hidden>

                                        </Box>

                                    </Box>
                                )}
                            </Box>
                            <Box style={{ width: "100%", display: 'flex', flexDirection: "column", placeItems: "center", gap: "5px" }}>
                                <Button style={webStyle.subscribeButton} onClick={() => this.nextscreen()}>
                                    Subscribe
                                </Button>
                                <Button style={webStyle.cancelButton} onClick={this.backClick}>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </StreamunlockRightsideContainer>
                </StreamUnlockMainBox>
            </>

        )
    }
}

const webStyle = {
    rightsideInnerbox: {
        width: "80%",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "30px"
    },
    streambox: {
        width: "100%",
        display: "flex",
        flexDirection: "column" as "column",
        gap: '8px'

    },
    alllevels: {
        color: "white",
        position: "absolute" as "absolute",
        left: "1.4%"
    },
    innerstreambox: {
        width: "100%",
        height: "75px",
        border: "3px solid #212121",
        background: "#000000",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        justifyContent: "center"
    },
    streamboxchild: {
        width: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative" as "relative"
    },
    rightsidefirstbox: {
        width: "100%",
        display: "flex",
        placeItems: "center",
        flexDirection: "column" as "column",
    },
    unlockText: {
        fontFamily: "Inter",
        fontSize: "48px",
        fontWeight: 900,
        lineHeight: "58.09px",
        textWrap: "nowrap" as "nowrap",
        color: "#FFFFFF"
    },
    content: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px",
        color: "#FFFFFF",
        display: 'flex',
        flexDirection: "column" as "column",
        alignItems: 'center'
    },
    subcrictionratetext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#FFFFFF",
        paddingLeft: "80px",
        textWrap: "nowrap" as "nowrap"
    },
    subscribeButton: {
        fontFamily: "Karla",
        fontSize: "20px",
        fontWeight: 800,
        lineHeight: "22px",
        color: "#FFFFFF",
        width: "260px",
        height: "60px",
        textTransform: "capitalize" as "capitalize",
        background: "#FF0000",
        borderRadius: "60px",
    },
    cancelButton: {
        fontFamily: "Karla",
        fontSize: "20px",
        fontWeight: 800,
        lineHeight: "22px",
        color: "#FFFFFF",
        width: "260px",
        height: "60px",
        background: "#212121",
        borderRadius: "60px",
        textTransform: "capitalize" as "capitalize",
    },
};

// Customizable Area End