// Customizable Area Start
import React, { useContext, useEffect, useState } from "react";
import "./CardItem.web.css";
import { loader, playOutline, loopIc, defaultProfile } from "../../../dashboard/src/assets";
const img = {
  width: "148px",
  height: "148px",
  borderRadius: "12px",
};
import millify from "millify";
import { AudioContextApi } from "../../../dashboard/src/AudioContext/AudioContext.web";
import { setStorageData } from "../../../../framework/src/Utilities";
import { HISTORY } from "../../../../components/src/common";


const CardItem = ({ image, name, artist, play, refresh, item, index, mainList,handleAdActivityClick }: any) => {
  const { handlePlayList } = useContext(AudioContextApi)

  const card_Item_loggedIn_userId = localStorage.getItem('loggedIn_userId')

  const [audioType, setAudioType] = useState<any>('')
  useEffect(() => {
    if (window.location.href.includes('beat')) {
      setAudioType('Beats')
    } else if (window.location.href.includes('hook')) {
      setAudioType('Hooks')
    } else if (window.location.href.includes('verse')) {
      setAudioType('Verses')
    } else if (window.location.href.includes('song')) {
      setAudioType('Songs')
    } else if (window.location.href.includes('playlist')) {
      setAudioType('Playlists')
    }


  }, [])

  let srcImg;
let currentClassName;
if (image !== null) {
  srcImg = image;
  currentClassName= 'music-Box'
} else {
  srcImg = defaultProfile;
  currentClassName="data-music"
}

const checkUnlock = () =>{
  if(item.type === "list_ad_campaign"){
    handleAdActivityClick(item.id,"play")
   }
  if(item?.attributes.level && card_Item_loggedIn_userId != item.attributes.account_id && !item.attributes.unlocked_track_by_current_user){
    const levelType = levelBeatTypesReturn(item?.attributes.level)
    const levelType_data = {track_id: item.id, user_id: localStorage.getItem('loggedIn_userId'), level: levelType}
    setStorageData('unlock_details', levelType_data)
    HISTORY.push('/streamUnlock')
  }else{
    handlePlayList(item, index, mainList, audioType)
  }
}

const levelBeatTypesReturn = (beatLevel: any) =>{
  if(beatLevel == 'level_1'){
    return 'L1'
  }else if(beatLevel == 'level_2'){
    return 'L2'
  }else{
    return 'L3'
  }
}

  return (


    <div className="video-card-center">
      <div className="music-Box video-card" onClick={() => {
        checkUnlock()
      }}>
        <div className={currentClassName} style={{position: 'relative'}}>
        { item?.attributes.level && card_Item_loggedIn_userId != item.attributes.account_id && !item.attributes.unlocked_track_by_current_user &&
             <div style={{ position: 'absolute' , height: '80%', width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span style={{color: 'white', border: '2px solid white', borderRadius: '50%', backgroundColor: 'red', padding: '10px', fontSize: '12px', fontWeight: 'bold'}}>
                  {levelBeatTypesReturn(item?.attributes.level)}
                </span>
              </div>
             }
        <img
          src={srcImg}
          alt="Profile"
          style={{ cursor: "pointer" }}
        />
</div>

        <div className="song-info" style={{ marginLeft: "2px" }}>
          <h2 style={{width: '152px'}}>{name || "Unknown"}</h2>
          {item.type=="list_ad_campaign"?
           <span className="align-oneLine">
          <p className="ad-placement-text-profile">Ad: </p>

          <p>{artist ? artist : "unknown"}</p>
          </span>:
          <p>{artist ? artist : "unknown"}</p>
            }
        </div>
        <div style={{ display: "flex" }} className="text_blue" >
          <p
            className="mr_05 mb_0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={playOutline} style={{ height: "13.5px", width: "12.5px", cursor: "pointer" }} alt=""
            />
            <span className=" mb_0 plays-carasouel" >
              {play || "0"}
            </span>
          </p>
          <p className="mr_05 mb_0" style={{ display: "flex", alignItems: "center" }}>
            <img
              src={loopIc}
              className="mr_05"
              style={{ height: "12.5px", width: "12.5px", marginLeft: "7px" }}
              alt=""
            />
            <span className=" mb_0 plays-carasouel">
              {refresh || "0"}
            </span>
          </p>
        </div>
      </div>
    </div>




  );
};
export default CardItem;
// Customizable Area End