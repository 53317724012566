const initialState = {
CreativesList:[]
};
export default (state = initialState, action) => {
  switch (action.type) {
         case 'GET_CREATIVE':
          return {
            ...state,
            CreativesList: [action.payload]
          };    
    default:
      return state;
  }
};
