// Customizable Area Start
import React from 'react'
import { nextIC, prevIC } from "blocks/dashboard/src/assets"

export const PrevButtonOrginal = (props: any) => {
  return (
    <p
      style={{
        position: "absolute",
        top: `${props.top}`,
        left: `${props.left}`,
        transform: "translateY(-50%)",
        cursor: "pointer"

      }}
      onClick={() => props.buttonRef.current.prev()}
    >
      <img src={prevIC} style={{ height: "30px", width: "30px" }} />
    </p>
  )
}
export const NextButtonOrginal = (props: any) => {
  return (
    <p
      style={{
        position: "absolute",
        top: `${props.top}`,
        right: `${props.right}`,
        transform: "translateY(-50%)",
        cursor: "pointer"

      }}
      onClick={() => props.buttonRef.current.next()}
    >
      <img src={nextIC} style={{ height: "30px", width: "30px" }} />
    </p>
  )
}

export const errorMessage = (type: any) => {
  return (
    <div style={{ height: "50px" }} >
      <p className='space_add' style={{
        fontSize: "14px", color: "white", fontWeight: 900, textAlign: "center",
        // marginBottom: "50px",
        marginBottom: '30px',
        // position: "absolute",
        left: "0px",
        right: "0px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        height: "100%",


      }}
      >
        No New {type} type Releases
      </p>
    </div>
  )
}
// Customizable Area End