// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
export const configJSON = require("./config.js");
import ProfileTrackImgController from './ProfileTrackImgController.web'

export default class ProfileTrackImg extends ProfileTrackImgController{
  render() {
    const { isTrack,trackType,image,handleTrackAndVideo}=this.props
    return (
    <Box
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        position: "relative",
      }}
    >
      <img
        className={isTrack && trackType !=="videoinvitation"? "trackMsgCard-isTrack" : "trackMsgCard-noTrack"}
        src={image}
      />
      <img
        style={{
          height: 42,
          width: 42,
          position: "absolute",
          cursor: "pointer",
        }}
        onClick={handleTrackAndVideo}
        src={this.showPlayPause(this.props.trackData)}
      />
    </Box>
  );
    }
};

// Customizable Area End
