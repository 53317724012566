// Customizable Area Start
import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import {
    addUser,
    DummyProfileImage,
  } from "../../../../dashboard/src/assets";
  import { removeUser } from "../../assets";

  
import "../../../../../components/src/CustomChart/Carousel/index.css"
import "../../../../user-profile-basic/src/Common/TopSongs.web.css";


function PlaylistNameComponent( props :any) {
    const {play,redirectProfile, followingUsers, unFollowUser,followUser,setOpenRepostPopup,openRepostPopup,setOpenPopUp, setRepostId, onShare,handleAddToPlaylist} = props;

    return (
        <Grid container className='buttonContainer' style={{ display: "flex", flexDirection: 'column', width: '100%' }}>
                            <Grid item md={3} style={{ display: "flex", maxWidth: '100%', margin: "5px 0" }}>
                        <img
                        test-id = "playlistname_component_redirect_test_id"
                            className="profile-image"
                            onClick={()=> redirectProfile(play?.attributes.artist_id)}
                            src={(play.attributes.avatar && play.attributes.avatar !== " ") ? play.attributes.avatar : DummyProfileImage} style={{ borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }} />
                        <div className="ml_1" style={{ width: '100%' }} >
                            <div className="follow-add-user-in-medium" style={{ display: 'flex' }}>
                            <div test-id = "playlistname_component_redirect_test_id_0" style={{ display: "flex", flexDirection: 'column' , cursor: 'pointer'}}
                                onClick={()=> redirectProfile(play?.attributes.artist_id)}
                            >
    
    
                                <p className=" text_white mr_1 artist-name-medium"
                                style={{
    
                                    overflow: "hidden",
                                    display: "inlineBlock",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: "116px",
                                    marginBottom: '1px'
                                }} >
    
                                {play?.attributes?.artist_name || 'Selena gomez'}
                                </p>
                                <Typography style={{ color: "#99999f", marginTop: "2px", fontSize: "6px" }}>
                                {play?.attributes.creative_type_name ? play?.attributes.creative_type_name : ""}
                                </Typography>
                                <Typography
                                //  className="text_blue ml_1 font_16 font_W_600"
                                // onClick={onProfile} 
                                // onClick={() => { handlePlayList(data, songIndex, mainList, name) }}
                                // onClick={() => {onProfile (data, songIndex, mainList, name) }}
                                style={{
                                    cursor: "pointer", marginTop: "-3px",
                                    color: "#8f9092",
                                    overflow: "hidden",
                                    display: "inlineBlock",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: "270px",
                                    fontSize: '10px'
                                }}>
    
    
                                {play?.attributes?.user_type || 'Remember me'}
                                </Typography>
    
                            </div>
                            {
                                followingUsers.includes(play?.attributes.artist_id) &&
                                <div>
                                <img test-id="unfollow_playlistNameComponent_user_test_id" className="add-user-button follow-user-button-margin" src={removeUser} style={{cursor: 'pointer'}} onClick={() => {unFollowUser(play?.attributes.artist_id)}} />
                            </div>
                            }
                            {
                                !followingUsers.includes(play?.attributes.artist_id) &&
                                <div>
                                <img test-id="follow_playlistNameComponent_user_test_id" className="add-user-button follow-user-button-margin" src={addUser} style={{cursor: 'pointer'}} onClick={() => {followUser(play?.attributes.artist_id)}} />
                            </div>
                            }
                            
                            </div>
                        </div>
                        </Grid>
                        <Grid item md={9} style={{ maxWidth: '100%' }}>
    
                        <div style={{ display: 'flex', justifyContent: "flex-start", marginTop: '5px' }}>
                            <button test-id="repost_playlistnamecomponent_test_id" className="repost-button-width" style={{
                            borderRadius: "13px",
                            backgroundColor: "#22252a",
                            //borderColor: 'rgba(0, 0, 0, 0.85)',
                            borderColor: 'rgba(0, 3, 3, 0)',
                            padding: "3px",
                            fontSize: "9px",
                            fontWeight: 600,
                            //textTransform: "capitalize",
                            height: "25px",
                            //width: '50px',
                            //width: "-webkit-fill-available",
                            marginRight: "5px",
                            color: "#8f9092",
                            cursor: 'pointer',
                            display: 'none'
                            }}
    
                            color="secondary"
                            //onClick={props.handleChartTrackRepost}
                            onClick={() => {
                                setOpenRepostPopup(!openRepostPopup)
                                setOpenPopUp(true)
                                setRepostId(play)
                            }
                            }
    
                            >
                            Repost
                            </button>
    
                            <button test-id="share_playlistnamecomponent_test_id" className="share-button-width" style={{
                            borderRadius: "13px",
                            backgroundColor: "#22252a",
                            //borderColor: 'rgba(0, 0, 0, 0.85)',
                            borderColor: 'rgba(0, 3, 3, 0)',
                            padding: "4px",
                            fontSize: "9px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            height: "25px",
                            //width: '50px',
                            //width: "-webkit-fill-available",
                            marginRight: "5px",
                            color: "#8f9092",
                            cursor: 'pointer'
    
                            }}
                            color="secondary"
    
                            // onClick={() => setOpenPopup(true)}
                            // onClick={onShare}
                            onClick={() => {onShare(play)}}
                            >
                            Share
                            </button>
    
                            <button test-id="addtoplaylist_playlistnamecomponent_test_id" className="addplaylist-button-width" style={{
                            borderRadius: "13px",
                            backgroundColor: "#22252a",
                            //borderColor: 'rgba(0, 0, 0, 0.85)',
                            borderColor: 'rgba(0, 3, 3, 0)',
                            padding: "4px",
                            fontSize: "9px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            height: "25px",
                            //width: '100px',
                            //width: "-webkit-fill-available",
                            marginRight: "5px",
                            color: "#8f9092",
                            cursor: 'pointer'
    
                            }}
                            color="secondary"
    
                            // onClick={() => setOpenPopup(true)}
                            // onClick={onShare}
                            onClick={
                            () =>{ handleAddToPlaylist(play)}
                        }
    
                            >
                            Add To Playlist
                            </button>
                           </div>
                          </Grid>
                        </Grid>
    
      )
    
}

export default PlaylistNameComponent
// Customizable Area End