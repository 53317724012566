// Customizable Area Start
import React from "react";
import { Row, Form, Input } from "antd";
import {user} from "../assets";
import "../../assets/listener.css";

interface Props{
}

export const FullNameProfiles = (props: Props) => {

    return(
        <>
         <Row className="width_100 font_12 gray1" >
            <Row className="fullname_acc">
            Full Name

            <Form.Item
                label="Full Legal Name"
                name="full_name"
                className="account_sub_title"

                rules={[
                {
                    required: true,
                    message: "Please Input Your FullName!",
                },
                ]}
            >
                <Input
                prefix={<img src={user} style={{ width: "100%" }} />}
                placeholder="John Doe"
                />
            </Form.Item>
            </Row>
        </Row>
        </>
    )

}