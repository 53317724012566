// Customizable Area Start
import { Grid, Chip, Typography, Tooltip } from "@material-ui/core";
import React from "react";
import "./snapshot.css";
import DownloaderDetailsController from "./DownloaderDetailsController";
import { defaultProfile, nxzloader } from "blocks/dashboard/src/assets";
import { DmAdd, FollowAdd, SendAdd } from "../../assets";
import { Row } from "antd";
import MessageLinkPopup from "../../../../../components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";

export default class DownloaderDetails extends DownloaderDetailsController {
  render() {
    const { userData, threebtnToogle } = this.props;
    const { trackList, loading } = this.state;

    return (
      <>
        {loading ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <Grid container className="downloadArtWorkGrid-downloads">
            <Grid container item xs={12} className="downloadHeightAdjust">
              <Grid
                container
                item
                xs={7}
                sm={7}
                md={7}
                className="downloaderListSecondGrid-downloads"
              >
                <Grid item xs={3} sm={3} md={3}>
                  <img
                    src={userData.attributes?.avatar || defaultProfile}
                    className="downloadArtWork-downloads"
                  />
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={9}
                  md={9}
                  className="downloaderListTopGrid-downloads"
                >
                  <span className="topDownloadersSpan-downloads">
                    {userData?.attributes?.creative_type_name}
                  </span>
                  <span className="artistGrid">
                    {userData?.attributes?.user_name}
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={5} sm={5} md={5} className="displayBetween">
                <Tooltip placement="top" title="Follow User">
                  <img
                    src={FollowAdd}
                    className="imgIcon"
                    onClick={this.followUser}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Start chat with user">
                  <img
                    src={DmAdd}
                    className="imgIcon"
                    onClick={this.openDMScreen}
                  />
                </Tooltip>
                <Tooltip placement="top" title="send link to user">
                  <img
                    src={SendAdd}
                    className="imgIcon"
                    onClick={this.linkopenPopup}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {trackList.length > 0 ? (
                <>
                  <Grid container item xs={12} className="displayMargin">
                    <Grid item xs={6}>
                      <span className="topDownloaders-downloads">
                        {trackList.length} &nbsp;
                      </span>
                      <span className="typeHead"> {threebtnToogle}</span>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <Typography className="downloadTypeName-downloads">
                        Genre/BPM
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className="download-details-disable-scrollbars">
                    {trackList.length > 0 &&
                      trackList.map((item: any, index: any) => (
                        <Grid
                          container
                          item
                          xs={12}
                          className="displayBetween"
                          key={index}
                        >
                          <Grid container item xs={8} className="displayPd">
                            <Grid item xs={3} sm={3} md={3}>
                              <img
                                src={item?.attributes?.art_work}
                                className="artistImage"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={9}
                              sm={9}
                              md={9}
                              className="downloads-tracks-name"
                            >
                              <Typography className="downloadArtName-downloads">
                                {item.attributes.track_name}
                              </Typography>
                              <Typography className="downloadTypeName-downloads">
                                {item?.attributes?.artist_name}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} style={{ textAlign: "end" }}>
                            <Chip
                              label={item?.attributes?.genre}
                              className="chipCss"
                            />
                            <Typography className="downloadTypeName-downloads">
                              <span className="downloadColorBlue">
                                {item?.attributes?.bpm?.replace("BPM ", "")}{" "}
                                &nbsp;
                              </span>
                              bpm
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    ;
                  </div>
                </>
              ) : (
                <p className="noData">No {threebtnToogle} Available</p>
              )}
            </Grid>
          </Grid>
        )}
        {this.state.openMessageSendPopup && (
          <MessageLinkPopup
            id="Profile"
            copiedLinked={this.state.CopiedForLink}
            showMsgModal={this.state.openMessageSendPopup}
            closeMsgModal={this.handleOpenMessageSendPopup}
            closeModalDialog={this.handleOpenMessageSendPopup}
            currentUserId={1}
          />
        )}
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area End
