// Customizable Area Start
import { Typography, Tabs, Row } from 'antd';
import {
    Box,
    Button,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme,
    Hidden
} from "@material-ui/core";
import React from 'react'
import StreamAudioVideo from './StreamAudioVideo.web'
import { bluecheck } from '../assets';
import AudioVideoController, { activeTab, IDataList } from './AudioVideoController';
import { defaultProfile, nxzloader } from 'blocks/dashboard/src/assets';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import MobileAudiovideo from './MobileAudiovideo.web';
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
const LeftSideSingletrackbox = styled(Box)({
    height: "81px",
    padding: "0px 10px 0px 5px",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    gap: "10px",
    color: "white",
    "&:hover": {
    },
});
const Audiohistorycontainer = styled(Box)({
    display: 'flex',
    height: "90vh",
    overflowY: "auto",
    width: "100%",
    justifyContent: "space-between",
    "@media(max-width: 1000px)": {
        flexDirection: "column"
    },
});

const Horizontaltag = styled(Box)({
    height: "100%",
    width: "1px",
    border: "1px solid #212121",

    "@media(max-width: 1100px)": {
        display: "none"
    },
});
const LeftsideAudiohistorycontainer = styled(Box)({
    width: "48%",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});

const Trackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
    "@media(max-width: 400px)": {

    },
});

export default class AudioVideo extends AudioVideoController {
    renderTabs = () => {
        return (
            <>
                {this.state.isListener && <Box style={webStyle.RightSidebuttonBox} data-test-id="Datatest">
                    <Box style={webStyle.Rightsidetabsbutton}>
                        <Box style={webStyle.tabbox}>
                            <Button
                                style={this.state.leftsideSelectedtab == "TopListeners" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                onClick={() => this.handleTab("TopListeners")}
                                data-test-id="TopListenerstab"
                            >
                                Top Listeners
                            </Button>
                        </Box>
                        <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={() => this.handleTab("My History")}
                                data-test-id="Myhistorytab"
                                style={this.state.leftsideSelectedtab == "My History" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                            >
                                My History
                            </Button>
                        </Box>
                    </Box>
                </Box>

                }
            </>
        )
    }
    trackInformation = (item: IDataList) => {
        return (
            <>
                <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Trackname className="tracks_songs_text">
                                {item?.attributes?.user_name}
                                {item?.attributes?.is_verified && (
                                    <span className="Jason-Derulo">

                                        <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                    </span>
                                )}
                            </Trackname>
                            <Box>
                                <span style={webStyle.rightsidesplittext}>
                                    Streams

                                </span>
                            </Box>

                        </Box>
                        <Box>
                            <Box style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}>
                                <span style={webStyle.rightsidesplittextwhite}>
                                    {this.getCountValue(item)}
                                </span>
                            </Box>
                        </Box>

                    </Box>
                    <Box data-test-id="box-container">
                        <BorderLinearProgress variant="determinate" value={this.getProgress(item, this.state.topCreativeListenerData)} data-test-id="border-linear-progress" />
                    </Box>
                </Box>
            </>

        )
    }
    SingleTrackinformation = () => {
        return (
            <>
                {this.state.topCreativeListenerData.length > 0 ?
                    this.state.topCreativeListenerData.map((item: IDataList, index: number) => {
                        return (
                            <LeftSideSingletrackbox
                                key={index}
                                style={this.state.selectedStreamId == item.id ? { background: "#141414" } : {}}
                                onClick={() => this.selectedTrack(item, index)}
                                data-test-id="leftSideSingleTrackBox"
                            >


                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                        <span>{index + 1}</span>
                                        <div style={webStyle.rightsideleftimage} >
                                            <img
                                                src={item?.attributes?.avatar || defaultProfile}

                                                className={item?.attributes?.avatar ? "analytics-avatar-listener" : "default-profile-icon-analytics"}

                                            />
                                        </div>
                                    </Box>
                                </Box>
                                {this.trackInformation(item)}
                            </LeftSideSingletrackbox>
                        )
                    }) :
                    <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center", paddingTop: "120px" }} data-test-id="iiii">
                        No Data Found
                    </div>}
            </>
        )
    }


    topCreativeData = () => {
        return (
            <>
                <Box style={{ paddingBottom: "5px" }}>
                    <Typography style={webStyle.trackname}>
                        {" "}
                        <span style={webStyle.bluetext}>
                            TOP {this.state.topCreativeListenerData.length}:
                        </span>{" "}
                        {this.state.isListener ? "Listeners" : "Creatives"}
                    </Typography>
                    {this.state.isLoading ? (

                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }} data-test-id="ooooo">
                            <img src={nxzloader} style={{ width: "5%" }} />
                        </Row>

                    ) :
                        this.SingleTrackinformation()
                    }
                </Box>
            </>
        )
    }

    render() {
        const { isPlaylist } = this.context;

        return (
            <>

                <Audiohistorycontainer style={isPlaylist ? { height: "75vh" } : { height: "90vh" }} className="streamRepostscroll" >
                    <LeftsideAudiohistorycontainer className={this.state.showmobileview ? "mobileviewhide" : 'mobileshow'}>
                        <div className="chats-header">
                            <Tabs
                                id="search_custom_tab"
                                defaultActiveKey="0"
                                tabPosition="top"
                                className='Streamrepost_custom_tab'
                                onChange={this.handleTabChange}
                                items={this.getLast12MonthsData().map((item: activeTab, index: string | number) => {
                                    const id = String(index);
                                    return {
                                        label: item.title,
                                        key: id,
                                        disabled: index === 12,
                                    };
                                })}
                            />
                        </div>

                        {this.renderTabs()}
                        {this.topCreativeData()}
                    </LeftsideAudiohistorycontainer>
                    <Box>
                        <Horizontaltag
                        ></Horizontaltag>
                    </Box>
                    {this.state.showmobileview ?
                        <Hidden only={["sm", "lg", "md", "xl"]}>
                            <MobileAudiovideo
                                webStyle={webStyle}
                                rightsideSelectedtab={this.state.rightsideSelectedtab}
                                handleAudiovideotab={(tab: string) => this.handleAudiovideotab(tab)}
                                usertype={this.state.usertype}
                                isLoading={this.state.isAudioVideoLoading}
                                tracklistData={this.state.tracklistData}
                                isListener={this.state.isListener}
                                selectedStreamedTrack={this.state.selectedStreamedTrack}
                                onBack={this.onBack}
                                streamedArtist={this.state.streamedArtist}
                                id={2}
                                getCountValue={(item: IDataList) => this.getCountValue(item)}
                            />
                        </Hidden> : ""}
                    <Hidden only={["xs"]}>
                        <StreamAudioVideo
                            webStyle={webStyle}
                            rightsideSelectedtab={this.state.rightsideSelectedtab}
                            handleAudiovideotab={(tab: string) => this.handleAudiovideotab(tab)}
                            usertype={this.state.usertype}
                            isLoading={this.state.isAudioVideoLoading}
                            tracklistData={this.state.tracklistData}
                            isListener={this.state.isListener}
                            selectedStreamedTrack={this.state.selectedStreamedTrack}
                            onBack={this.onBack}
                            streamedArtist={this.state.streamedArtist}
                            id={3}

                            getCountValue={(data: IDataList) => this.getCountValue(data)}
                        />
                    </Hidden>

                    <Box>
                    </Box>
                </Audiohistorycontainer>

            </>

        )
    }
}

const webStyle = {
    RightSidebuttonBox: {
        width: "100%"
    },
    Rightsidetabsbutton: {
        borderRadius: "30px",
        background: "#070707",
        display: "flex",
        justifyContent: "space-around",
        padding: "2px 0 2px 0",
        border: "3px solid #212121",

    },
    tabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },

    leftimage: {
        borderRadius: "10px",
        width: "75px",
        minWidth: "75px",
        height: "75px",
        objectFit: "cover" as "cover",
        border: "1px solid #141414"
    },
    rightsideleftimage: {
        borderRadius: "60px",
        width: "60px",
        minWidth: "60px",
        height: "60px",
        position: "relative" as "relative",
        objectFit: "cover" as "cover",
        border: "solid 2px #212121"
    },
    leftvideoimage: {
        borderRadius: "10px",
        width: "120px",
        minWidth: "120px",
        height: "75px",
        objectFit: "cover" as "cover",
        border: "1px solid #141414"
    },

    singleArtinfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",

    },
    rightsidesingleArtinfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",

    },

    leftsideAlltracksbox: {
        width: "85%",

    },


    trackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
        paddingBottom: "10px"
    },

    bluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
    },

    splittext: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    rightsidesplittext: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    splittextwhite: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    rightsidesplittextwhite: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },

    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
    rightSideSingletrackbox: {
        marginTop: "12px",
        height: "54px",
        padding: "0px 14px 0px 5px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
    Audiovideoleftsideownerstats: {
        gap: "3px",
        display: "flex",
        flexDirection: "column" as "column",
    },
    tabaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",
    },

    tabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
    },
};
AudioVideo.contextType = AudioContextApi;
// Customizable Area End