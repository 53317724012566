//Customizable Area Start
export const QRcodeBlue = require("./assets/QR Code.webp");
export const LinkIcon = require("./assets/Frame 51.webp");
export const Repost = require("./assets/Frame 56.webp");
export const Send = require("./assets/Frame 57.webp");
export const New_message_Blue = require('./assets/new_msg.png')
export const Message_Icon_Blue = require('./assets/Message_Icon_Blue.png')
export const Message_Icon_Black = require('./assets/Message_Icon_Black.png')
export const Add_user_Male = require('./assets/Add User Male.png')
export const Link_Chain_Copy = require('./assets/akar-icons_link-chain.png')
export const Playlist_Icon_Add = require('./assets/akar-icons_trash-can.png')
export const Send_Message = require('./assets/Chat.png')
// Customizable Area End




