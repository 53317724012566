// Customizable Area Start
import React from 'react'
import {Helmet} from "react-helmet";

function MetaTag(props :any) {
    const {trackData} = props;
    console.log(trackData.attributes?.title, typeof(trackData), 'from meta tag');
  return (
    <Helmet>
      
      <meta property='og:title' content={trackData.attributes?.title} />
      
      <meta property='og:description' content={trackData.attributes?.title} />
      <meta property='og:image' content={trackData.attributes?.art_work} />
      
      <meta property='og:url' content={window.location.origin + `/home/expand/song/songId=${trackData.id}`} />

    </Helmet>
  )
}

export default MetaTag
// Customizable Area End