// Customizable Area Start
import React, { useEffect, useRef } from 'react';
import { Box, Avatar, TextField, Button, } from '@material-ui/core'
import { CloseCircleOutlined } from "@ant-design/icons";
import { userLogo } from '../../assets';
// Customizable Area End


// Customizable Area Start
interface Props {
    value:string;
    onValueChange: (value: string) => void;
    sendComment: (value:string) => void;
    selectedCommentForReply: any;
    closeCommentReply: any
}
// Customizable Area End

// Customizable Area Start
export const CommentSendInput = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const logged_in_user_profile = localStorage.getItem('profile_img')

    useEffect(() => {
      if(props.selectedCommentForReply != ''){
        inputRef.current?.focus()
      }
    },[props.selectedCommentForReply])
    const handleSend = (e: any) => {
        if(props.value.trim() != ''){
          props.sendComment(props.value)
        }
    }

    const handleValueChange = (e: any) => {
        props.onValueChange(e.target.value)
    }

    const handleEnterKeyValueChange = (e: any) => {
        if (e.keyCode === 13) {
          props.onValueChange(e.target.value)
          handleSend(e)
        }
    }
   
    const closeReplyComment = () =>{
        props.closeCommentReply()
    }
    return (
        <>
        <div style={{position: 'relative'}}>
         {
            props.selectedCommentForReply != '' &&
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px', backgroundColor: '222121', borderRadius: '10px'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div style={{color: 'blue', fontSize: '16px', fontWeight: 'bold'}} >{props.selectedCommentForReply?.attributes?.user_name}</div>
                <div style={{color: 'white', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.selectedCommentForReply?.attributes?.description}</div>
            </div>
              {/* @ts-ignore */}
             <CloseCircleOutlined translate style={{color: 'white'}} onClick={closeReplyComment} />
            </div>
         }

        <Box className="CommentInputBox">
            {/* user logo */}
            <Avatar src={logged_in_user_profile || userLogo} className='Avatar' />
            {/* user logo END */}
            {/* Input */}
            <TextField
                id ="scroll-web-reply"
                value = {props.value}
                ref = {inputRef}
                className='CommentInput'
                placeholder='Leave a comment'
                onChange={handleValueChange}
                onKeyDown={(e) => handleEnterKeyValueChange(e)}
            />
            {/* Input END */}
            {/* Send Btn */}
            <Button
                onClick={handleSend}
                className='CommentSendBtn'
                type='submit'
            >
                Send
            </Button>
            {/* Send Btn END */}
        </Box>
        </div>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default CommentSendInput;
// Customizable Area End