export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const creative = require('../assets/creative.png');
export const updateCard = require('../assets/updateCard.png');
export const creativeplus = require('../assets/creativeplus.png');
export const getPlanButton = require('../assets/get plan button.png');
export const yourPlanButton = require('../assets/your plan button.png');
export const getPlan = require('../assets/get plan.png');
export const cancelPlan = require('../assets/cancel plan.png');
export const mastercardLogo = require('../assets/Mastercard Logo.png');
export const payButton = require('../assets/pay button.png');
export const keepMyPlan = require('../assets/keepmyplan.png');
export const background = require('../assets/background.png');
export const nxzsoundLogo = require('../assets/nxzsoundlogotwo.png');
export const nxzsoundlogoSmall = require('../assets/nxzsoundlogo.png');
export const visitprofile = require('../assets/visitprofile.png');
export const viewplan = require('../assets/viewplan.png');
export const savecard = require('../assets/savecard.png');
export const cancelPlanRed = require('../assets/cancelplanred.png');
export const cancelPlanBlue = require('../assets/cancelPlanBlueBtn.png');
export const mobileBackground = require('../assets/mobileBackground.png');


export const visaCard = require('../assets/visa-card-logo.png');
export const americanExp = require('../assets/american_exp_logo.png');
export const dinerClubCard = require('../assets/diners-club-logo.png');
export const discoverLogo = require('../assets/Discover-logo.png');
export const jcbLogo = require('../assets/jcb-logo.jpeg');