// Customizable Area Start
import { Typography, Tabs, Layout } from 'antd';
import {
    Box,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme,
    Hidden
} from "@material-ui/core";
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import {  bluecheck } from '../assets';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import RepostHistoryController, { RepostListData } from './RepostHistoryController';
import {  TopArtistType, activeTab } from './AudioVideoController';
import { defaultProfile } from 'blocks/dashboard/src/assets';
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
const LbaelrepostLeftSideSingletrackbox = styled(Box)({
    height: "81px",
    borderRadius: "8px",
    gap: '10px',
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    color: "white",
});
const Labelhistorycontainer = styled(Box)({
    height: "90vh",
    overflowY: "scroll",
    display: 'flex',
    width: "100%",
    justifyContent: "space-between",
    paddingTop: "15px",
    "@media(max-width: 1150px)": {
        flexDirection: "column"
    },
});
const LabelboxRightside = styled(Box)({
    width: "fit-content",
    display: "flex",
    minWidth: "100%",
    flexDirection: "column" as "column",
    gap: "15px",
    "@media(max-width: 1024px)": {
        width: "auto",
    },
});
const RightsideAlltracksboxwrapper = styled(Box)({
    display: "grid",
    scrollbarWidth: "none",
    gridTemplateColumns: "repeat(3, 1fr)",
    width: "fit-content",
    gap: "25px",
    "@media(max-width: 1700px)": {

        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 1600px)": {

        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 1300px)": {

        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 1150px)": {

        gridTemplateColumns: "repeat(4, 1fr)",
    },
    "@media(max-width: 1129px)": {

        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 1024px)": {

        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 959px)": {

        gridTemplateColumns: "repeat(4, 1fr)",
    },
    "@media(max-width: 877px)": {

        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 769px)": {

        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 549px)": {

        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 320px)": {
        gridTemplateColumns: "repeat(1, 1fr)",
    },
    "&::-webkit-scrollbar": {
        display: "none",
        width: "0px",
    },
});

const LabelboxRightsidecontainer = styled(Box)({
    width: "47%",
    alignItems: "center",
    gap: "5%",
    "@media(max-width: 1150px)": {
        width: "100%",
        height: "auto",
        marginTop: " 5%",
        gap: "20px",
    },
});

const LabelRepostHorizontaltag = styled(Box)({
    height: "100%",
    width: "1px",
    border: "1px solid #212121",
    "@media(max-width: 1100px)": {
        display: "none"
    },
});
const LabelLeftsideAudiohistorycontainer = styled(Box)({
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    "@media(max-width: 1150px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});


const LabelrepostTrackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
    "@media(max-width: 400px)": {
    
    },
});

export default class LableRepostHistory extends RepostHistoryController {
    render() {
        const { isPlaylist } = this.context;
        return (
            <>
                <Layout className='streamRepostWrapper'>

                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        style={{
                            margin: "0px",
                            backgroundColor: "black",
                            display: "flex", flexDirection: "column",
                        }}
                    >
                        <HomeHeader location="RepostHistory"
                        redirect={this.redirectToProfile}
                        />

                        <Labelhistorycontainer style={isPlaylist ? { height: "74.4vh" } : { height: "90vh" }} className='streamRepostscroll'>
                            <Hidden only={["xs"]}>
                                <LabelboxRightsidecontainer>
                                    <LabelboxRightside>
                                        {" "}
                                        <Box
                                            style={{ display: " flex ", justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <Typography style={webStyle.labeltrackname}>
                                                {" "}
                                                <span style={webStyle.labelbluetext}>
                                                    TOP {this.state.top40ArtistList.length}:
                                                </span>{" "}
                                                Creatives
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                width: "100%",
                                            }}
                                        >

                                           
                                                {this.state.top40ArtistList.length > 0 ? (
                                                    <RightsideAlltracksboxwrapper>
                                                   { this.state.top40ArtistList.map((item: TopArtistType, index: number) => {
                                                        return (
                                                            
                                                            <Box
                                                                key={index}
                                                                onClick={()=>this.showArtistData(item)}
                                                                style={{ height: "80%", cursor: "pointer", position: 'relative' }}
                                                                data-test-id="handleshowArtist"
                                                                 >
                                                                <div className="reposthistorimage"
                                                                style={{
                                                                    border: item.id == this.state.artistUserID ? "5px solid white" : "5px solid rgb(33, 33, 33)",
                                                                    borderRadius: "15px"
                                                                }}
                                                                >
                                                                <img
                                                                    src={item.attributes.avatar|| defaultProfile}
                                                                    className={item.attributes.avatar?'repost_label_creative-avatar':"defaultProfile-repost_label_creative"}
                                                                />
                                                                </div>
                                                                <Typography style={webStyle.labelrepostimagetext}>
                                                                    {item.attributes.user_name}
                                                                </Typography>

                                                                <Typography style={webStyle.labelrepostaudioartistext}>
                                                                    producer
                                                                </Typography>
                                                            </Box>
                                                          
                                                        )
                                                    })}
                                                    </RightsideAlltracksboxwrapper>)
                                                    :
                                                  (  <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center",paddingTop:'200px'}}>
                                                        No Data Found
                                                    </div>)

                                                }

                                            
                                        </Box>
                                    </LabelboxRightside>
                                </LabelboxRightsidecontainer>
                            </Hidden>

                            <Box>
                                <LabelRepostHorizontaltag
                                ></LabelRepostHorizontaltag>
                            </Box>
                            <LabelLeftsideAudiohistorycontainer >
                                <Box>

                                    <Typography style={webStyle.labeltrackname}>
                                        {" "}
                                        <span style={webStyle.labelbluetext}>
                                            TOP {this.state.listData.length}:
                                        </span>{" "}
                                        Reposters
                                    </Typography>
                                </Box>

                                <Box>
                                    <div className="chats-header" >
                                        <Tabs

                                            className='Streamrepost_custom_tab'
                                            defaultActiveKey="0"
                                            tabPosition="top"
                                            onChange={this.handleTabChange}
                                            items={this.getLast12MonthsData().map((item: activeTab, index: string | number) => {
                                                const id = String(index);
                                                return {
                                                    label: item.title,
                                                    key: id,
                                                    disabled: index === 12,
                                                };
                                            })}
                                        />
                                    </div>
                                </Box>
                                <Box style={{ paddingBottom: " 5px " }}>
                                    {this.state.listData.length > 0 ?
                                        this.state.listData.map((item: RepostListData, index: number) => {
                                            return (
                                                <LbaelrepostLeftSideSingletrackbox
                                                    key={index}
                                                >
                                                    <Box>
                                                        <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                                            <span>{index + 1}</span>
                                                            <div className='reposters_label_user_artwork'>
                                                            <img
                                                                src={item.attributes.avatar||defaultProfile}
                                                                className={item.attributes.avatar?'mediaDMCP-avatar':"default-profile-mediaDMCP"}
                                                            />
                                                            </div>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <Box>
                                                                <LabelrepostTrackname className="tracks_songs_text">
                                                                    {item.attributes.user_name}
                                                                    <span className="Jason-Derulo">

                                                                        <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                                    </span>
                                                                </LabelrepostTrackname>
                                                                <Box>
                                                                    <span style={webStyle.labelrepostsplittext}>
                                                                        Reposts

                                                                    </span>
                                                                </Box>

                                                            </Box>
                                                            <Box>
                                                                <Box style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    flexDirection: "column",
                                                                }}>
                                                                    <span style={webStyle.labelrepostsplittextwhite}>
                                                                        {item.attributes.total_repost_count}
                                                                    </span>
                                                                </Box>
                                                            </Box>

                                                        </Box>
                                                        <Box>
                                                            <BorderLinearProgress variant="determinate" value={this.getProgress(item)} />
                                                        </Box>
                                                    </Box>


                                                </LbaelrepostLeftSideSingletrackbox>
                                            )
                                        })
                                        :
                                        <div style={{ fontSize: "20px",paddingTop:'76px', fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center" }}>
                                            No Data Found
                                        </div>


                                    }
                                </Box>
                            </LabelLeftsideAudiohistorycontainer>
                            <Box>
                            </Box>
                        </Labelhistorycontainer>
                    </Content>
                </Layout>


            </>

        )
    }
}

const webStyle = {
    RightSidebuttonBox: {
        width: "100%"
    },

    Rightsidetabsbutton: {
        borderRadius: "30px",
        backgroundColor: "#212121",
        display: "flex",
        justifyContent: "space-around",
        padding: "2px 0 2px 0",
        border: "3px solid #212121",
    },

    labelrepostaudioartistext: {
        color: "#8f92a1",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Karla",
        lineHeight: "20px",
        position: "absolute" as "absolute",
        bottom: "-15%",
        left: "8%"
    },

    labelrepostimagetext: {
        color: "#F0F0F5",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Karla",
        lineHeight: "20px",
        marginTop: "8px",
        maxWidth: "150px",
        textOverflow: "ellipsis",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap" as 'nowrap',
        position: "absolute" as "absolute",
        bottom: "-5%",
        left: "8%"
    },

    tabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },

    labelrepostleftimage: {
        borderRadius: "60px",
        width: "75px",
        minWidth: "75px",
        height: "75px",
        border: "2px solid #FFFFFF"
    },

    leftvideoimage: {
        borderRadius: "10px",
        width: "75px",
        minWidth: "75px",
        height: "75px",
        border: "1px solid #141414"
    },

    labelrepsotsingleArtinfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",
    },

    leftsideAlltracksbox: {
        width: "85%",
    },
    labeltrackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
    },

    labelbluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
    },

    labelrepostsplittext: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },

    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },

    labelrepostsplittextwhite: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#F0F0F5",
        fontFamily: "Inter",
    },

    labelrepostleftsideownerstats: {
        gap: "3px",
        display: "flex",
        flexDirection: "column" as "column",
    },

    tabaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",
    },

    rightSideSingletrackbox: {
        marginTop: "12px",
        height: "54px",
        padding: "0px 14px 0px 5px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },

    tabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
    },
};
LableRepostHistory.contextType = AudioContextApi;
// Customizable Area End