// Customizable Area Start
import React from 'react'
import { Tabs, Tab } from "@material-ui/core";

const TabList = ({ active, callback }: any) => {
    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }
    const token: any = localStorage.getItem("token")
    return (
        <>
            <Tabs id="custom-tab"
                value={active}
                className="tab-body nxz-tabs "
                variant="scrollable"

                onChange={callback}
                TabIndicatorProps={{ style: { backgroundColor: 'red', color: "red", bottom: "3px" } }}
            >
                <Tab
                    // className="tab-pane" 
                    label="Beats" key="0"
                    className={active === 0 ? "active-chart-tab" : "non-active-chart-tab"}

                    {...a11yProps(0)}


                />

                <Tab label="Hooks" key="1"
                    {...a11yProps(1)}
                    className={active === 1 ? "active-chart-tab" : "non-active-chart-tab"}



                />

                <Tab label="Verses" key="2"
                    {...a11yProps(2)}
                    className={active === 2 ? "active-chart-tab" : "non-active-chart-tab"}



                />

                <Tab label="Songs" key="3"
                    {...a11yProps(3)}
                    className={active === 3 ? "active-chart-tab" : "non-active-chart-tab"}



                />

                {
                    token &&
                    <Tab label="Videos" key="4"
                    className={active === 4 ? "active-chart-tab" : "non-active-chart-tab"}

                    {...a11yProps(4)}


                />
                }
                

                <Tab label="Playlists" key="5"
                    className={active === 5 ? "active-chart-tab" : "non-active-chart-tab"}

                    {...a11yProps(5)}


                />
            </Tabs>
        </>
    )
}

export default TabList
// Customizable Area End