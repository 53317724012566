// Customizable Area Start
import { pauseSmall, playButton } from "blocks/dashboard/src/assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config.js");
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

export interface Props {
    image: string;
    isTrack: boolean;
    handleTrackAndVideo:()=> void;
    trackType:string;
    trackData:TrackDataResponse;
}

interface Attributes {
  art_work: string;
  // Add other properties as needed
}

interface PlaylistTrack {
  id: number;
  attributes: Attributes;
  // Add other properties as needed
}
interface S {
  userData: string;
  imageList: string[];
  isListner: boolean;
  token: any;
  playlistTracks: PlaylistTrack[];
}
interface SS {
  id: any;
}

interface TrackDataResponse {
  "id": string,
  "type": string,
  "attributes": {
    "track_file": string,
    "video_file": string,
    "time_duration": string,
    "art_work": string,
    "artist_image":string,
    "account_id": number,
    "title": string,
    "release_date": string,
    "release_time": string,
    "is_published": boolean,
    "audio_tag": null
  }
}

export default class ProfileMessageCardController extends BlockComponent<
  Props,
  S,
  SS
> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      userData: "",
      imageList: [],
      isListner: false,
      token: localStorage.getItem("token"),
      playlistTracks: []
    };

  }
  showPlayPause = (item: any) => {
    let { handleAudioVideo, playlistData } = this.context;
    if (playlistData?.id == item.id && handleAudioVideo === 2) {
      return pauseSmall;
    } else {
      return playButton;
    }
  };
}
ProfileMessageCardController.contextType = AudioContextApi;
// Customizable Area End