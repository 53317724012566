// Customizable Area Start
import React, { useContext } from 'react'
import { Grid, IconButton } from "@material-ui/core";
import { CloseRounded } from '@material-ui/icons';
import { album11, plays } from '../../assets';
import { AudioContextApi } from '../../AudioContext/AudioContext.web';
import UnlockContentCommon from 'blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web';

const NextUpSongs = ({ playlistItems, nextUpSongs }: any) => {
    const { handlePlayList, setNextUplist} = useContext(AudioContextApi)

    console.log(playlistItems);

    const removeNextUpSong = (item: any) =>{
      let songs = nextUpSongs.filter((song: any) => song.id != item.id)
      localStorage.setItem('nextUpSongs',JSON.stringify(songs))
      setNextUplist(songs)
    }

    const playNextUpQueueSong = (item: any) =>{
      let songs = nextUpSongs.filter((song: any) => song.id != item.id)
      localStorage.setItem('nextUpSongs',JSON.stringify(songs))
      setNextUplist(songs)
      handlePlayList(item, 0, nextUpSongs, 'NextUp')
    }

    return (
        <>

            <Grid item xs={12} className="single_next_Audio_queue" container>
                <Grid item xs={1} style={{ padding: "0px 0px 0px 10px" }} test-id="play_next_queue_song_test_id_0" onClick={() => {playNextUpQueueSong(playlistItems)}}>
                   <div style={{ height: "48px", width: "48px" , position: 'relative'}}>
                    <img src={playlistItems?.attributes?.art_work ? playlistItems?.attributes?.art_work : album11} alt="" style={{ height: "48px", width: "48px", position: 'absolute' }} />
                    <UnlockContentCommon trackData={playlistItems} height="100%" width="100%" padding="3px"/>
                   </div>
                </Grid>
                <Grid item xs={7} style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0px 10px 0px 40px" }} test-id="play_next_queue_song_test_id" onClick={() => {playNextUpQueueSong(playlistItems)}}>
                    <span className="track_name_font">{playlistItems?.attributes?.title}</span>
                    <span className="username_font">{playlistItems?.attributes?.artist_name}</span>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "0px 10px" }}>
                    <img src={plays} alt="plays" style={{ height: "20px", width: "20px", margin: "0px 10px" }} />
                    <span className="track_name_font">{playlistItems?.attributes?.play_count}</span>
                    <IconButton test-id="remove_next_up_song_test_id" onClick = {() =>{ removeNextUpSong(playlistItems)}}>
                        <CloseRounded fontSize="medium" style={{ color: "rgba(255, 255, 255, 0.5)" }} />
                    </IconButton>
                </Grid>

            </Grid>
        </>
    )
}

export default NextUpSongs
// Customizable Area End