// Customizable Area Start
import React from "react";
import './dialogBox.css'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogBoxController from "./DialogBoxController";
export const configJSON = require("blocks/dashboard/src/config");

export default class DeactivateUser extends DialogBoxController {
  render() {
    const { open } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            style: {
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
            },
          }}
          PaperProps={{
            style: {
              backgroundColor: "#121212",
              borderRadius: "20px",
              boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
              border: "solid 1px #1f1f1f",
              width: "600px",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            className="dialog-title repost-head"
            style={{ textAlign: "center", fontWeight: "normal" }}
          >
            <span className="deactive-heading">
              Account Deactivation Confirmation
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description ">
              <p className="deactive-title">
                Are you sure ?<br />
                <br />
              </p>
              <p className="deactive-body">
                Your account will instantly deactivate,then all your content and
                your account will be deleted forever.
                <br />
              </p>
            </DialogContentText>
            <DialogActions>
              <Button
                color="primary"
                onClick={this.deactivateUserAccount}
                style={{
                  color: "red",
                }}
              >
                Close Account
              </Button>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
// Customizable Area End
