// Customizable Area Start


import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { HISTORY } from "components/src/common";




export interface Props {
  navigation: any;
  id: string;
}

interface S {
 active:string
 
 
}

interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  
  subscriptionPlan = localStorage.getItem("subscription_plans")

  getTop100ListsApiCallId: any
  previousHref: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
    active:"1"

    };
  

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }
  async componentDidMount() {
    super.componentDidMount();
    this.previousHref = window.location.href;
   
    this.handleSlug(this.getSlugFromUrl());
    window.addEventListener("popstate", this.handlePopState);
  }

  async componentWillUnmount() {
    window.removeEventListener("popstate", this.handlePopState);
  }

  handlePopState = () => {
    this.handleSlug(this.getSlugFromUrl());
  };
  getSlugFromUrl = () => {
    const urlArr = window.location.href.split("/");
    return urlArr.length !== 0 ? urlArr[4] : "";
  };
  

  handleSlug = (slug: any) => {
  
    if (slug === "revenue") {
      this.setState({ active: "1" });
    }  else if (slug === "analytics") {
      this.setState({ active: "2" });
    } else if (slug == "wallet") {
      this.setState({ active: "3" });
    } else if (slug === "deals") {
      this.setState({ active: "4"});
    } 
  };
  
  
   callback = (key: any) => {
           
            if (key === "1") {
              HISTORY.push(`/dashboard/revenue`);
            }  else if (key === "2") {
              HISTORY.push(`/dashboard/analytics`);
            } else if (key === "3") {
              HISTORY.push(`/dashboard/wallet`);
            } else if (key === "4") {
              HISTORY.push(`/dashboard/deals`);
            } 
            this.setState({active:key});
        };




}
// Customizable Area End
