// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");

export interface Props {
    showDetails: boolean;
    trackDetailsId: any;
    getAllSplits?: any;
    index?: any;
    top40trackList?: any;
    topPlayMusic?: any;
    setShowDetails:any;
}

interface S {
   
}

interface SS {
  id: any;
}

export default class RightSideTrackController extends BlockComponent<Props, S, SS> {
   
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
    
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 

  


}
// Customizable Area End