
// Customizable Area Start
import React, { useContext } from 'react'
import { Grid, IconButton } from "@material-ui/core";
import { CloseRounded } from '@material-ui/icons';
import { album11, plays } from '../../assets';
import { AudioContextApi } from '../../AudioContext/AudioContext.web';



const NextQueue = ({ playlistItems }: any) => {
    const {queueSongs, setAfterRefreshQueueList, handlePlayList} = useContext(AudioContextApi)

    console.log(playlistItems);

    const removeSong = (item: any) =>{
      let songs = queueSongs.filter((song: any) => song.id != item.id)
      setAfterRefreshQueueList(songs)
      localStorage.setItem('queueSongs',JSON.stringify(songs))
    }

    const playQueueSong = (item: any) =>{
      let songs = queueSongs.filter((song: any) => song.id != item.id)
      setAfterRefreshQueueList(songs)
      localStorage.setItem('queueSongs',JSON.stringify(songs))
      handlePlayList(item, 0, songs, 'Queue')
    }

    return (
        <>

            <Grid item xs={12} className="single_next_Audio_queue" container>
                <Grid item xs={1} style={{ padding: "0px 0px 0px 10px" }} test-id="play_next_queue_song_test_id_0" onClick={() => {playQueueSong(playlistItems)}}>
                    {/* <img src={"https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzhhIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8cd4d2b6bd8ad41ab932757da47621a3cd59b0f1/artworks-000504502107-bnjyv3-t500x500.jpg"} alt="" style={{ height: "48px", width: "48px" }} /> */}
                    <img src={playlistItems?.attributes?.art_work ? playlistItems?.attributes?.art_work : album11} alt="" style={{ height: "48px", width: "48px" }} />
                </Grid>
                <Grid item xs={7} test-id="play_next_queue_song_test_id" style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0px 10px 0px 40px" }} onClick={() => {playQueueSong(playlistItems)}}>
                    <span className="track_name_font">{playlistItems?.attributes?.title}</span>
                    <span className="username_font">{playlistItems?.attributes?.artist_name}</span>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "0px 10px" }}>
                    <img src={plays} alt="plays" style={{ height: "20px", width: "20px", margin: "0px 10px" }} />
                    <span className="track_name_font">{playlistItems?.attributes?.play_count}</span>
                    <IconButton test-id="remove_next_up_song_test_id" onClick = {() =>{ removeSong(playlistItems)}}>
                        <CloseRounded fontSize="medium" style={{ color: "rgba(255, 255, 255, 0.5)" }} />
                    </IconButton>
                </Grid>

            </Grid>
        </>
    )
}

export default NextQueue
// Customizable Area End