export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Pushsnap = require("../assets/Push snap.png")
export const active = require("../assets/active.png")
export const complete = require("../assets/complete.png")
export const push = require("../assets/push.png")
export const artistimage = require("../assets/artistimage.png")
export const piechart = require("../assets/pie-chart.png")
export const circle = require("../assets/circle.png")
export const dealtrackimage = require("../assets/Deal Item.png")
export const eye = require("../assets/eye.png")
export const onefingertap = require("../assets/one-finger-tap.png")
export const profile = require("../assets/profile.png")
export const cloud = require("../assets/cloud.png")
export const play = require("../assets/play.png")
export const backarrow = require("../assets/backarrow.png")
export const cardi=require("../assets/cardib.png")
export const activeAd=require("../assets/activeAd.png")
export const completedAd=require("../assets/CompletedAd.png")
export const snapAd=require("../assets/snapAd.png")