// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import type { FormInstance } from 'antd/es/form';
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { openNotification } from "../../../../../components/src/Notification.web";
import { HISTORY } from "../../../../../components/src/common";
export const configJSON = require('../../config');

interface S {
  apiToken: any;
}
interface SS {
}
interface Props {
  id: string;
  open: any;
  handleClose: any;
  playlist: any;
}

export default class DeletePlaylistPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDeletePlaylistCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      apiToken: localStorage.getItem('token')
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  formRef = React.createRef<FormInstance>();
   getBase64(video: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(video);
  }

  async componentDidMount() {
    super.componentDidMount();
  }
  

  deletePlaylist = (values: any) => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeletePlaylistCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.deletePlaylist}${this.props.playlist?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiDeletePlaylistCallId) {
        if (responseJson != null) {
          if (!responseJson.error) {
            openNotification(responseJson?.data[0].message, 'Success')
            this.props.handleClose()
            HISTORY.push('/home/all-playlist')
          } else {
            openNotification(responseJson?.error, 'Error')
            this.props.handleClose()
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

}
// Customizable Area End