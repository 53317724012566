// Customizable Area Start
import { Carousel, Input } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import { searchshape } from "../../../../dashboard/src/assets";
import { playButton} from "blocks/dashboard/src/assets";
import MobileAudioVideoPlaylist from "./MobileAudioVideoPlaylistController.web";

const AudiovideoDivWrapper = styled(Box)({
    display: "flex",
    padding: "30px 50px 0px 27px",
    gap: "1%",
    justifyContent: "space-between",
    minHeight: "calc(100% - 3rem)",
  

    "@media(max-width: 1024px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 5px",
        minHeight: "0px",
        gap: "0%",
    },
});

const AudiovideoSplitleftsidecontainer = styled(Box)({
    width: "46%",
    display: "flex",
    height: "max-content",
    flexDirection: "column" as "column",
    gap: "15px",
    paddingBottom: "20px",
   
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        gap: "15px",
        marginTop: "10px",
    },
});
const AudiovideoLeftsideAlltracksboxwrapper = styled(Box)({
    overflowY: "auto",
    scrollbarWidth: "none",
    maxHeight: "693px",
    "&::-webkit-scrollbar": {
        width: "0px",
        background: "transparent",
    },
});

const AudiovideoTotalSplits = styled(Box)({
    color: "white",
    width: "100%",
    "@media(max-width: 600px)": {
        height: "auto",
    },
});


const AudiovideoSplitboxRightsidecontainer = styled(Box)({
    flexDirection: "column" as "column",
    width: "auto",
    paddingBottom: "20px",
    gap: "15px",
    display: "flex",
});

const AudiovideoLeftsideAlltracksbox = styled(Box)({
    width: "100%",
    "@media(max-width: 1024px)": {
        width: "100%",
    },
});

const AudiovideoTrackName = styled(Box)({
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    lineHeight: "20px",
    letterSpacing: "normal",
    fontFamily: "Inter",
    "@media(max-width: 450px)": {
        fontSize: "14px",
    },
});

const AudiovideoInputboxwrapper = styled(Box)({
    width: "100%",
    background: "#212121",
    padding: "10px",
    position: "relative",
    borderRadius: "27px",
    "& .ant-input": {
        backgroundColor: "#212121",
        width: "88%",
        color: "white",
    },
    "& .ant-input-affix-wrapper": {
        background: "none",
        border: "unset",
    },
    "@media(max-width: 1024px)": {
        width: "100%",
        "& .ant-input": {
            width: "50%",
        },
    },
});



const AudiovideoPlaylistboxRightside = styled(Box)({
    display: "flex",
    gap: "15px",
    flexDirection: "column" as "column",
    
    "@media(max-width: 1024px)": {
    },
});
const AudioVideolistboxRightside = styled(Box)({
    display: "flex",
    gap: "15px",
    flexDirection: "column" as "column",
   
    "@media(max-width: 1024px)": {
       
    },
});

const Audiosettings = {

    autoplay: false,
    dots: false,
    infinite: true,
    swipeToSlide: true,
    draggable: true,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
   
    responsive: [
        {
            breakpoint: 601,
            settings: {
                slidesToShow: 2.6,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 590,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 2.5,
               
            }
        },
        {
            breakpoint: 545,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 2,
              
            }
        },
        {
            breakpoint: 514,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 2,
              
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 462,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 437,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.8,
                
            }
        },
        {
            breakpoint: 326,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.4,
              
            }
        },
        {
            breakpoint: 404,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.8,
              
            }
        },
        {
            breakpoint: 399,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.5,
              
            }
        },
        {
            breakpoint: 330,
            settings: {
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 308,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }


    ]

};
const AuidovideoCtrlvideosettings = {

    autoplay: false,
    dots: false,
    infinite: true,
    swipeToSlide: true,
    draggable: true,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 601,
            settings: {
                slidesToShow: 1.5,
                initialSlide: 2,
                slidesToScroll: 2,
               
            }
        },
        {
            breakpoint: 573,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.4,
               
            }
        },
        {
            breakpoint: 590,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 540,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.35,
                
            }
        },
        {
            breakpoint: 520,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.3,
              
            }
        },
        {
            breakpoint: 503,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.2,
              
            }
        },
        {
            breakpoint: 468,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.1,
               
            }
        },
        {
            breakpoint: 434,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.08,
            }
        },
        {
            breakpoint: 422,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.03,
            }
        },
        {
            breakpoint: 382,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 330,
            settings: {
                slidesToShow: 1.01,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 326,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1.01,
               
            }
        },
        {
            breakpoint: 308,
            settings: {
                slidesToScroll: 1,

                slidesToShow: 1.01,
               
            }
        }


    ]

};
export default class MobileAudioVideoplaylist extends MobileAudioVideoPlaylist {
    constructor(props: any) {
        super(props);
    }

    rigthsideAudioVideo = () => {
        if (this.props.selectedTab == "audio") {
            return (
                <AudiovideoPlaylistboxRightside>

                    <Box style={{ width: "100%" }}>
                        <Box style={this.props.webStyle.tracksbutton}>
                            <Box style={{ width: "50%" }}>
                                <Button
                                    style={
                                        this.props.selectedTab == "audio"
                                            ? this.props.webStyle.activeaudiovideobutton
                                            : this.props.webStyle.audiovideobutton
                                    }
                                    onClick={() => {
                                        this.props.getAllPlaylist("audio");
                                        this.props.setSelectedTabAudio();
                                    }}
                                    data-test-id="ctrl-audio"
                                >
                                    Audio
                                </Button>
                            </Box>
                            <Box style={{ width: "50%" }}>
                                <Button
                                    style={this.props.webStyle.audiovideobutton}
                                    onClick={() => {
                                        this.props.getAllPlaylist("video");
                                        this.props.setSelectedTabVideo();
                                    }}
                                    data-test-id="ctrl-video"
                                >
                                    Video
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: " flex ",
                            alignItems: "center",
                            justifyContent: " space-between ",

                        }}
                    >
                        <Typography style={this.props.webStyle.trackname}>
                            <span style={this.props.webStyle.bluetext}>Top 40</span> Playlists
                        </Typography>

                        <Typography style={this.props.webStyle.splittext}>
                            This week
                        </Typography>
                    </Box>
                    <Box style={{ width: "100%" }}>
                        {this.props.top40playList?.length > 0 ? (
                            <Carousel
                                {...Audiosettings}
                                infinite={false}
                            >
                                {this.props.top40playList.map((item: any, index: any) => {
                                    return (
                                        <Box
                                            key={index}
                                            style={{ width: "300px", height: "300px" }}
                                            className="cursor-pointer"
                                        onClick={() => this.props.openPlaylistScreen(item)}
                                        >
                                            <Box>
                                                <img
                                                  src={item.attributes?.cover_image}
                                                  style={{ objectFit: "cover", height: "170px", width: "200px", borderRadius: "12px" }}
                                                />

                                                <Typography style={this.props.webStyle.audioimagetext}>
                                                    {index + 1}. {`${item.attributes?.name.slice(0, 17)}`}
                                                    1hello
                                                </Typography>
                                                <Typography style={this.props.webStyle.audioartistext}>
                                                    {item.attributes.user_role}
                                                </Typography>
                                            </Box>

                                        </Box>
                                    );
                                })}
                            </Carousel>
                        ) : (
                            <Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#fff",
                                    // padding: "5px",
                                    justifyContent: "center",
                                    width: "100%",
                                    paddingTop: "78px",
                                }}
                            >
                                Data Not Found.
                            </Typography>
                        )}
                    </Box>
                </AudiovideoPlaylistboxRightside>
            );
        } else if (this.props.selectedTab == "video") {
            return (
                <AudioVideolistboxRightside>
                    <Box style={{ width: "100%" }}>
                        <Box style={this.props.webStyle.tracksbutton}>
                            <Box style={ { width: "50%" } }>
                                <Button
                                    style={this.props.webStyle.audiovideobutton}
                                    onClick={() => {
                                        this.props.setSelectedTabAudio();
                                        this.props.getAllPlaylist("audio");
                                    }}
                                    data-test-id="ctrl-audios"
                                >
                                    Audio
                                </Button>
                            </Box>
                            <Box style={{ width: "50%" }}>
                                <Button
                                    style={
                                        this.props.selectedTab == "video"
                                            ? this.props.webStyle.activeaudiovideobutton
                                            : this.props.webStyle.audiovideobutton
                                    }
                                    onClick={() => {
                                        this.props.setSelectedTabVideo();
                                        this.props.getAllPlaylist("video");
                                        
                                    }}
                                    data-test-id="ctrl-videos"
                                >
                                    Video
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: " flex ",
                            justifyContent: " space-between ",
                        }}
                    >
                        <Typography style={this.props.webStyle.trackname}>
                            <span style={this.props.webStyle.bluetext}>Top 40</span> Playlists
                        </Typography>

                        <Typography style={this.props.webStyle.splittext}>
                            This week
                        </Typography>
                    </Box>
                    <Box style={{ width: "100%" }}>
                        {this.props.top40playList?.length >  0 ? (
                            <Carousel {...AuidovideoCtrlvideosettings} infinite={false}>
                                {this.props.top40playList.map((item: any, index: any) => {
                                    return (
                                        <Box style={{ height: "193px", width: "600px" }}>
                                            <Box
                                                key={index}
                                                className="vid_image_tracks"
                                                style={{
                                                    borderRadius: "10px",
                                                    position: "relative",
                                                    background: `url("${item.attributes?.cover_image}") lightgray 50% / cover no-repeat`,
                                                    height: "193px",
                                                    width: "350px",
                                                    display: "flex",
                                                    alignItems: "end",
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                }}
                                            onClick={() => this.props.openPlaylistScreen(item)}
                                            >
                                                <Box className="image_border">
                                                    <Typography style={this.props.webStyle.videoImagetex}>
                                                        {item.attributes.name}
                                                    </Typography>
                                                    <Typography style={this.props.webStyle.mobilevideoArtisttext}>
                                                        {item.attributes.playlist_items?.data?.length}  
                                                        videos
                                                    </Typography>
                                                </Box>
                                                <img
                                                    src={playButton}
                                                    style={{
                                                        height: "49px",
                                                        width: "49px",
                                                        position: "absolute",
                                                        right: "45%",
                                                        top: "30%",
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                    );
                                })}
                            </Carousel>
                        ) : (
                            <Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#fff",
                                    padding: "5px",
                                    justifyContent: "center",
                                    width: "100%",
                                    paddingTop: "78px",
                                }}
                            >
                                Data Not Found.
                            </Typography>
                        )}
                    </Box>
                </AudioVideolistboxRightside>
            );
        }
    };

    render() {
        const {
            searchFilterFunction,
            webStyle,
            playList,
            selectedsong,
            selectedTab,
        } = this.props;
        return (
            <AudiovideoDivWrapper>
                <AudiovideoSplitboxRightsidecontainer>
                    <AudiovideoInputboxwrapper>
                        <Input
                            data-test-id="test_search_term_id_handle_search_input"
                            onChange={(text: any) => searchFilterFunction(text)}
                            //onClick={() => history.push("/home/MainSearchWeb")}
                            className="playlistctrl12"
                            prefix={
                                //@ts-ignore
                                <SearchOutlined className="text_white1" />
                            }
                            // placeholder={<p className="text_white"></p>}
                            placeholder="Search Plyalists"
                        />
                        <img src={searchshape} style={webStyle.searchimage} />
                    </AudiovideoInputboxwrapper>
                    {this.rigthsideAudioVideo()}
                </AudiovideoSplitboxRightsidecontainer>
                <AudiovideoSplitleftsidecontainer>

                    <AudiovideoTotalSplits>
                        <Typography style={webStyle.trackname}>
                            <span style={webStyle.bluetext}>{playList?.length}</span>{" "}
                            Playlists
                        </Typography>
                    </AudiovideoTotalSplits>
                    <AudiovideoLeftsideAlltracksbox>
                        <AudiovideoLeftsideAlltracksboxwrapper>
                            {playList?.length > 0 ? (
                                playList.map((item: any, index: any) => {
                                    let status = "draft";
                                    let typeColor = "#919191";
                                    

                                    if (
                                        item.attributes?.status.toLowerCase() === "public_playlist"
                                    ) {
                                        status = "Public";
                                        typeColor = "red";
                                       
                                    } else if (
                                        item.attributes?.status.toLowerCase() === "private_playlist"
                                    ) {
                                        status = "Private";
                                        typeColor = "#3959f9";
                                      
                                    }
                                    return (
                                        <Box
                                        style={webStyle.leftSideSingletrackbox}
                                            key={index}
                                          
                                            className={
                                                item.attributes?.playlist_items?.data.length > 0
                                                    ? "cursor-pointer"
                                                    : "cursor-notAllowed"
                                            }

                                            onClick={() => {
                                                selectedsong(
                                                    index,
                                                    item.attributes?.id,
                                                    item.attributes?.playlist_items?.data.length
                                                );
                                            }}
                                            data-test-id="audioplaylist-selectSong"
                                        >
                                            <Box style={webStyle.singleArtinfo}>
                                                <img
                                                    src={item.attributes?.cover_image}
                                                    style={
                                                        selectedTab !== "audio"
                                                            ? webStyle.videocard 
                                                            : webStyle.audiocard
                                                    }
                                                />

                                                <Box style={ webStyle.leftsideownerstats }>
                                                    <AudiovideoTrackName>
                                                        {`${item.attributes?.name.slice(0, 17)}`}
                                                    </AudiovideoTrackName>
                                                    <span style={webStyle.splittext}>
                                                        {item.attributes?.playlist_items?.data?.length}{" "}
                                                        {selectedTab == "audio" ? "Tracks" : "Videos"}
                                                    </span>
                                                </Box>
                                            </Box>
                                            <Box style={webStyle.leftsideownerstats}>
                                                <Box>
                                                    <Button
                                                        style={{
                                                            border: `1px solid ${typeColor}`,
                                                            borderRadius: "20px",
                                                            textTransform: "capitalize",
                                                            color: typeColor,
                                                            height: "23px",
                                                          
                                                        }}
                                                    >
                                                        {status}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })
                            ) : (
                                <Typography
                                    style={{
                                        display: "flex",
                                        padding: "5",
                                        justifyContent: "center",
                                        paddingTop: "78px",
                                        alignItems: "center",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#fff",
                                       
                                    }}
                                >
                                    Data Not Found.
                                </Typography>
                            )}
                        </AudiovideoLeftsideAlltracksboxwrapper>
                    </AudiovideoLeftsideAlltracksbox>
                </AudiovideoSplitleftsidecontainer>

            </AudiovideoDivWrapper>
        );
    }
}

// Customizable Area End
