// Customizable Area Start
import { createBrowserHistory } from "history";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");

const history = createBrowserHistory()
export interface Props {
  open:boolean;
  setIsdeactivePopupShow:any;

}
interface S {
    token: any;
}
interface SS {
  id: any;
}

export default class DialogBoxController extends BlockComponent<Props, S, SS> {
    getDeactivateAccount:any;

    constructor(props: Props) {
        super(props);
        
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
    
        this.state = {
            token: localStorage.getItem("token")
            
           
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

     handleClose = () => {
        
            this.props.setIsdeactivePopupShow(false)
    }

    deactivateUserAccount = async () => {
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDeactivateAccount = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/account_block/close_account`
    );


    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
    

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
    
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (apiRequestCallId === this.getDeactivateAccount) {
            if (responseJson!==null){
            localStorage.clear();
            history.go(0)
        }else{
          this.parseApiCatchErrorResponse(errorReponse);
        } 
    }
    
      }
}
// Customizable Area End