// Customizable Area Start
import React from 'react';
import { Typography, Grid } from "@material-ui/core";
import { DealHeaderBg } from './assets';
// Customizable Area End


// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const DealHeader = (props: any) => {
    return (
        <Grid container className="DealHeaderContainer" style={{ background: `linear-gradient(transparent 120px, black 300px), url(${DealHeaderBg}) no-repeat center/cover`, }}>
            <Typography className='Title'>Scared Money</Typography>
            <Typography className='Title'>Don't Make</Typography>
            <Typography className='Title'>No Money.</Typography>
        </Grid>
    );
}
// Customizable Area End


// Customizable Area Start
export default DealHeader;
// Customizable Area End
