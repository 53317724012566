// Customizable Area Start
import React from "react";
import LabelAccountRegistrationController from './LabelAccountRegistrationController.web'
import { SignUpFormLabelAndCreative } from "./Common/SignUpFormLabelAndCreative.web";

export default class Label extends LabelAccountRegistrationController {

  render(){  
  return (
   <SignUpFormLabelAndCreative onSubmit={(values: any) => this.postLabelDetails(values)} emailInvite={""} setInviteEmail={undefined}/>
  );
}
}
// Customizable Area End