// Customizable Area Start
import React from 'react';
import { Typography, Grid, Box } from "@material-ui/core";
// Customizable Area End


// Customizable Area Start
interface Props {
    userStats: any,
    upload:any
}
// Customizable Area End

// Customizable Area Start
export const PerformanceCard = (props: any) => {
    return (
        <Grid item xs={6} >
            <Box className='PerformanceCard'>
                <Typography className='CommonText StatsCardTitle'>{props.title}</Typography>
                <Typography className='CommonText StatsCardSubTitle'>{props.stat}</Typography>
            </Box>
        </Grid>
    );
}

export const UserPerformanceStats = (props: Props) => {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography className='CommonText StatsTitle'>Performance Stats</Typography>
            </Grid>
            <PerformanceCard title="Splits" stat={props.userStats?.spits_count || 0} />
            <PerformanceCard title="Comments" stat={props.userStats?.total_comments || 0} />
            <PerformanceCard title="Reposts" stat={props.userStats?.total_reposts || 0} />
            <PerformanceCard title="Videos" stat={props.upload?.Video || 0} />
            <PerformanceCard title="Songs" stat={props.upload?.Song || 0}/>
            <PerformanceCard title="Beats" stat={props.upload?.Beat || 0} />
            <PerformanceCard title="Hooks" stat={props.upload?.Hook || 0} />
            <PerformanceCard title="Verses"stat={props.upload?.Verse || 0} />
        </Grid >
    );
}
// Customizable Area End


// Customizable Area Start
export default UserPerformanceStats;
// Customizable Area End
