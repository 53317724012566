// Customizable Area Start
import React from "react";
import "../Creatives/ComposeBlock.web.css";
import { Grid, Button, Dialog, Box } from "@material-ui/core";
import ProfileDataUser from "./ProfileDataUser.web";
import {  composeprofile, logo } from "blocks/dashboard/src/assets";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import UserProfileBasicController from "../UserProfileBasicController";
export const configJSON = require("../../../../framework/src/config.js");
export default class ProfileData extends UserProfileBasicController {
  render() {
    const { creativeById, openScanQr } = this.state;
    const { isPlaylist } = this.context;

    return (
      <div className={isPlaylist?"listener-profile-top-grid-audio":"profile-top-grid_listener"}>

        {Object.keys(creativeById).length > 0 &&
          <>
            <div
              className="upperDiv listener_profile"
              style={{
                backgroundImage: creativeById?.attributes?.cover_image ? `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url(${creativeById.attributes.cover_image})` : `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url(${logo})`,
              }}>
              <ProfileDataUser />

              <Dialog
                onClose={() => this.setState({ openScanQr: false })}
                open={openScanQr}
                aria-labelledby="simple-dialog-title"
                BackdropProps={{
                  style: {
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(0, 0, 0, 0.15)",
                  },
                }}
                PaperProps={{ className: "dialog-Paper send-dialog-Paper" }}
              >
                <Grid container xs={12} className="pd20">
                  <Grid container item xs={10} className="mrAuto">
                    <Grid item xs={12}>
                      <Box className="send-message-to">
                        To
                      </Box>
                      <Box className="send-message-box ">
                        <img
                          src={composeprofile}
                          width="30px"
                          height="30px"
                          className="send-message-img"

                        />
                        <input placeholder='Artist Name' className="send-message-input" />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item xs={10} className="message-to-grid mrAuto">
                    <Grid item xs={12}>
                      <Box className="send-message-to">
                        Message
                      </Box>
                      <Box >
                        <textarea
                          rows={4} cols={50}
                          className="send-message-textarea color-white"
                        >
                          Type your text
                        </textarea>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container item xs={8} className="mrAuto" onClick={this.handleRedirectToMessage}>
                    <Button className="sendNow-btn">Send Now</Button>
                  </Grid>
                </Grid>
              </Dialog>
            </div>
          </>
        }

      </div >
    );
  }
}
ProfileData.contextType = AudioContextApi;
// Customizable Area End