// Customizable Area Start
import React from 'react';
import {  Grid } from '@material-ui/core';
import './ProfileFollowers.css';
import { addUser, defaultProfile, removeUserFollower } from 'blocks/dashboard/src/assets';
import { useHistory } from 'react-router-dom';
import { HISTORY } from 'components/src/common';

const ProfileFollowersTemplate = ({
  AvatarSrc,
  fullname,
  category,
  user,
  value,
  data,
  followUserByID,
  unfollowUserById,
  id,
  usertype,
  creativeById,
  handleClose
}: any) => {
  const history = useHistory()
let handleButton:any;

  if(value==="followers"){
    if(data.followed_by_current_user){
      handleButton=false
    }else{
      handleButton=true
    }
  }else if(value==="following"){

     if(data.is_following){
        handleButton=false
      }else{
        handleButton=true
      }
  }
const redirectToProfile=()=>{
  localStorage.removeItem("selectedTab")
  const ids: any = localStorage.getItem("loggedIn_userId")
   localStorage.setItem("temp_id", ids)
   localStorage.removeItem("user_id")
   localStorage.setItem("user_id", id)
   localStorage.setItem("creatives_screen", "true")
   localStorage.setItem("current_type", usertype);
  if(usertype.includes("creative")){
    HISTORY.push(`/creatives/beats?creative_id=${id}`)
    } else if(usertype.includes("listener")){
      HISTORY.push(`/listeners/activity?listener_id=${id}`)
    }else{
      HISTORY.push(`/originals/beats?originals_id=${id}`)

    }
    handleClose()
}

const handleFollowButton=()=>{
  return (
  <img src={handleButton?addUser:removeUserFollower} alt='User' width='75px' style={{cursor:"pointer"}}  onClick={()=>{
    if(handleButton){
      followUserByID(id)

    }else{
      unfollowUserById(id)


    }
  }
}
  />
  )
}
// }
  return (
    <>
      <Grid container wrap='nowrap' className='follower-user'>
        <Grid item xs={2} >
          <div className='follower-avatar'>

          <img
            alt='chartcover'
            src={AvatarSrc||defaultProfile}
            className={AvatarSrc ?
              "follower-avatar":
              "default-profile-mediaDMCP"}
            onClick={redirectToProfile}
            data-test-id="onredirectClick"
          />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Grid item xs={12} className='follower-Detail'>
            <Grid item xs={8} className='follower-info'>
              <h3 className='follow-fullname' onClick={redirectToProfile}>{fullname}</h3>
              <span className='gerycolor follow-fullname'>&#x2022; {category}</span>
            </Grid>
            <Grid item xs={4} className='follower-button'>
             {handleFollowButton()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ProfileFollowersTemplate
// Customizable Area End
