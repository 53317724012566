// Customizable Area Start
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import moment from "moment";
import MSwitchPlanController from './MSwitchPlanController'

export default class MSwitchPlan extends MSwitchPlanController {
render(){
  const{ planName, planPrice, next_invoice_no }= this.props
  console.log(planName, typeof planName, "planName");

  return (
    <Grid item xs={12} container className="m_user_plan_div">
      <Grid item xs={12} container className="m_user_plan_continer">
        <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
          <span className="m_currentPlan_text">Plan Switching To</span>
          <Grid className="m_plan_name_div">
            <Grid className="m_plan_name">
              {planName?.endsWith("+") ? (
                <span className="currentplan-align">
                  {planName?.slice(0, -1)}
                  <AddIcon className="redColor" fontSize="medium" />
                </span>
              ) : (
                <span>{planName}</span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="m_subscriber_content">
          <span className="m_subscriber_title">Subscription:</span>
          <span className="m_subscriber_plan_rupees">${planPrice} Monthly</span>
        </Grid>
        <Grid item xs={12} className="m_subscriber_content">
          <span className="m_subscriber_title">Next Payment Due</span>
          <span className="m_subscriber_plan_rupees">
            {next_invoice_no
              ? moment(next_invoice_no, "MMM-DD-YYYY").format("MMM DD,YYYY")
              : "----"}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
            }
};

// Customizable Area End
