// Customizable Area Start
import React from 'react';
import { Box, Modal } from '@material-ui/core';
import './ProfileFollowers.css';
import { addUser, nxzloader } from 'blocks/dashboard/src/assets';
import  ProfileFollowersTemplate from './ProfileFollowersTemplate.web';
import { Col, Row } from 'antd';

export const ProfileFollowing = ({ open, handleClose, data, loading, value, followUserByID, unfollowUserById, creativeById }: any) => {
  return (
    <>
      <Modal open={open} onClose={handleClose} className='openUpwindow'>
        {
          loading ? (
            <>
              <Row className="width_100" align="middle" justify="center">
                <Col>
                  {" "}
                  <img src={nxzloader} style={{ width: "20%" }} />
                </Col>
              </Row>
            </>
          ) : (
            <Box className='followerBackground'>
              <h2 className='followers-container-head'>{value}</h2>
              <Box className='follower-contain'>
                {data.length > 0 ? data.map((item: any) => {
                  return (
                    <>
                      <ProfileFollowersTemplate
                    AvatarSrc={item.attributes.avatar}
                    fullname={item.attributes.user_name}
                    category={item.attributes.user_type}
                    // user={item.attributes.is_following?removeUserFollower:addUser}
                    user={addUser}
                    value={value}
                    data={item.attributes}
                    followUserByID={followUserByID}
                    unfollowUserById={unfollowUserById}
                    id={item.id}
                    usertype={item.attributes.user_type}
                    creativeById={creativeById}
                    handleClose={handleClose}
                  />
                    </>
                  );

                })
                  :
                  <p style={{
                    fontSize: "12px", color: "white", fontWeight: "bold",
                    position: "absolute",
                    top: "50%",
                    left: "44%"
                  }}>
                    No {value} available
                  </p>
                }
              </Box>
            </Box>
          )
        }
      </Modal>
    </>
  );
};
// Customizable Area End