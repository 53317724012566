// Customizable Area Start
import React from 'react';
import { Row } from 'antd';
import { nxzloader } from '../assets';
// Customizable Area End


// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const Loader = (props: any) => {
    return (
        <>
            <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default Loader;
// Customizable Area End
