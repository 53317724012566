// Customizable Area Start


import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { HISTORY } from "components/src/common";
import { openNotification } from "components/src/Notification.web";
import { setStorageData } from "../../../../../../framework/src/Utilities";
export const configJSON = require("../../../config.js");





export interface Props {
  navigation: any;
  id: string;
}

interface S {
 musicButton:any
 selected:boolean
 selecteddeals:string
 showdealstatus:boolean
 selectedIndex:any
 offerStatus:string,
 Dealdata:any,
 loading:boolean
 firstindexdeal:any
 selectedusersplit:any,
 SplitType:string,
 topDealersdata:any,
 Topdealoffers:any,
 selecteddealoffer:any,
 dealstatusid:any,
 showofferStatus:boolean

}

interface SS {
  id: any;
}

export default class DashboardMusicDealController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAlllDealApiCallId: string = ""
  getTopDealersId: string = ""
  getDealOffersId:string=""
  setDealstatusid:string=""
  previousHref: string = "";

  dealnegotiatingid :string =""
  subscriptionPlan = localStorage.getItem("subscription_plans")

  


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
    musicButton:1,
    selected:false,
    selecteddeals:"Topdeals",
    showdealstatus:false,
    selectedIndex:null,
    offerStatus:"",
    Dealdata:[],
    loading:false,
    firstindexdeal:'',
    selectedusersplit:{},
    SplitType:"Music",
    topDealersdata:[],
    Topdealoffers:[],
    selecteddealoffer:{},
    dealstatusid:0,
    showofferStatus:false
   
    };
  

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }
  async componentDidMount() {
    super.componentDidMount();
    HISTORY.push("/dashboard/deals/music")
    this.getAlldealtype("Music");
    this.setState({loading:true})
    this.previousHref = window.location.href;
    console.log("previous",this.previousHref)
    this.handleSlug(this.getSlugFromUrl());
    window.addEventListener("popstate", this.handlePopState)
}
async componentWillUnmount() {
  window.removeEventListener("popstate", this.handlePopState);
}
handleSlug = (slug: any) => {
  console.log("slug",slug)
  if (slug === "music") {
    this.setState({ musicButton: 1 ,loading:true});
    this.getAlldealtype("Music")
  }  else if (slug === "video") {
    this.setState({ musicButton: 2 ,loading:true});
    this.getAlldealtype("Video");
  } else if (slug == "user") {
    this.setState({ musicButton: 3 ,loading:true});
    this.getAlldealtype("User");
  } else if (slug === "offers") {
    this.setState({ musicButton: 4,loading:true});
    this.getallDealoffers()
  } 
};
handlePopState = () => {
  this.handleSlug(this.getSlugFromUrl());
};
getSlugFromUrl = () => {
  // console.log("window",window.location.href)
  const urlArr = window.location.href.split('/').pop();
  console.log('window',urlArr)
  return urlArr;
};
  async receive(from: string, message: Message) {
   
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      switch (apiRequestCallId) {
        case this.getAlllDealApiCallId:
          this.getallDealresponse(responseJson)
          break;
        case this.getTopDealersId:
          this.setState({ topDealersdata: responseJson, loading: false, selectedusersplit: responseJson[0] })
          break;
        case this.getDealOffersId:
          this.setState({ Topdealoffers: responseJson, loading: false, })
          break;
        case this.setDealstatusid:
          this.setDealstatusresponse(responseJson)
          break;
        case this.dealnegotiatingid:
          if (responseJson?.message) {
            openNotification(responseJson?.message, "success")
            this.setState({ showofferStatus: true })
            this.getallDealoffers()
          }
          else {
            openNotification(responseJson?.error, "error")
          }
          break;
      }
    }
  }
  
getallDealresponse =(response:any)=>{
  if(response?.message){
    this.setState({loading:false,Dealdata:[]})

  }else{
    this.setState({loading:false})
    // console.log("responsedashboard", responseJson)
  this.setState({ Dealdata: response?.data })
  this.setState({selectedusersplit:response?.data[0]})
  }

}
setDealstatusresponse=(responseJson:any)=>{
  if(responseJson?.error){
    openNotification(responseJson?.error,"error")
    this.setState({loading:false,offerStatus:""})
   }
   else{

    console.log("statusdeal", responseJson)
    this.setState({loading:false,showofferStatus:true})
    openNotification("status changed Successfully","success")
    this.getallDealoffers()
   }

}
  setMusicButtonTrue=()=>{
    HISTORY.push("/dashboard/deals/music")
    this.setState({musicButton:1,SplitType:"Music",firstindexdeal:""})
    this.setState({loading:true})
    this.getAlldealtype("Music")
    
  }
  setVideoButtonTrue=()=>{
    HISTORY.push("/dashboard/deals/video")
    this.setState({musicButton:2,SplitType:"Video",firstindexdeal:""}) 
    this.setState({loading:true})
    this.getAlldealtype("Video")
  }


  setUserButtontrue=()=>{
    HISTORY.push("/dashboard/deals/user")
    this.setState({musicButton:3,SplitType:"User",firstindexdeal:""})  
    this.getAlldealtype("User");
    this.setState({loading:true})
  }

  selectanysong = (index: string, item: any) => {
    this.setState({ selected: true, loading: true })
    this.setState({ firstindexdeal: index, selectedusersplit: item, loading: false })
  }

  setDealerbuttontrue=()=>{
    HISTORY.push("/dashboard/deals/offers")
    this.setState({musicButton:4,selecteddeals:'transactions',showdealstatus:false,showofferStatus:false})
    this.getallDealoffers()
  }

  selectedDealSection = (selected: string) => {
    if (selected == "transaction") {
      this.setState({ selecteddeals: "transactions" })
      this.getallDealoffers()
    } else {
      this.setState({ selecteddeals: 'Topdeals' })
      this.getTopDealers()
      this.setState({ firstindexdeal: "" })
    }
  }
   
  selectedDeal = (index: any, item: any) => {
    const user_type: any = localStorage.getItem("user_type");
    const ownerId: any = localStorage.getItem("user_id");

    if (user_type == "creative_plus" && item?.attributes?.status !== "pending") {
      this.setState({ selectedIndex: index, dealstatusid: item.id, selecteddealoffer: item, offerStatus: item?.attributes?.status, showofferStatus: true, showdealstatus: true })
    }
    else if (user_type == "creative_plus" && user_type !== null && item?.attributes?.status == "pending") {
      this.setState({ showdealstatus: true, selectedIndex: index, selecteddealoffer: item, dealstatusid: item.id, offerStatus: "", showofferStatus: false })
    }
    else {
      if (item?.attributes?.status === "negotiating" && ownerId == item.attributes.user_track.creator_id) {
        this.setState({ showdealstatus: false })
      }
      else if (item?.attributes?.status === "negotiating") {

        this.setState({ showdealstatus: true, selecteddealoffer: item, dealstatusid: item.id, offerStatus: "", showofferStatus: false })
      }

      else if (ownerId == item.attributes.user_track.creator_id && item?.attributes?.status == "pending" && item?.attributes?.deal_type == 'User Split') {
        this.setState({ showdealstatus: true, selecteddealoffer: item, dealstatusid: item.id, showofferStatus: false })
      }
      else {
        this.setState({ showdealstatus: false })
      }
    }

  }

  negotiatedeal =async()=>{
    localStorage.setItem("deal_id",JSON.stringify(this.state.selecteddealoffer.id));
    await setStorageData('splitType', 'negotiate');
    HISTORY.push('/deal/enter-deal-amount')
  }

  setofferStatus = async (status: string, deal_type: any) => {
    const id = Number(this.state.dealstatusid)
    const body = {
      deal_id: id,
      status: status
    }
    const user_type: any = localStorage.getItem("user_type");
    if (user_type == "creative_plus" && user_type !== null) {
      await this.setDealstatusapi(body)
    }
    else if (user_type == "label" && user_type !== null && (status !== "negotiating" || this.state.selecteddealoffer.attributes.status == "negotiating")) {

      await this.dealnegotiating(this.state.selecteddealoffer?.attributes?.amount, id, status)
    }
    else if (user_type == "label" && (deal_type == "User Split" || this.state.selecteddealoffer.attributes.status == "pending")) {
      this.negotiatedeal()
    }
  }
  getAlldealtype = async(split:string) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': localStorage.getItem('token')
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAlllDealApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_subscription_plan/dashboard_split_deals?split_name=${split} Split`
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getTopDealers = async() => {
    this.setState({loading: true})

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': localStorage.getItem('token')
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopDealersId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_subscription_plan/top_dealer`
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getallDealoffers = async() => {
    this.setState({loading: true})

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': localStorage.getItem('token')
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealOffersId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_subscription_plan/deal_offers`
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  setDealstatusapi = async(status:any) => {
   
this.setState({offerStatus:status.status})
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': localStorage.getItem('token')
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setDealstatusid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_subscription_plan/change_status`
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(status)
  );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  dealnegotiating =async (amount:number,id:number,status:string)=>{
    const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': localStorage.getItem('token')
    };
   
    this.setState({offerStatus:status})

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dealnegotiatingid = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_subscription_plan/negotiate_deal?amount=${amount}&deal_id=${id}&negotiation_state=${status}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putAPIMethod
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    


    runEngine.sendMessage(requestMessage.id, requestMessage);
}

}
// Customizable Area End
