// Customizable Area Start
import React, { useEffect, useRef, useContext,useState } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import { AudioContextApi } from 'blocks/dashboard/src/AudioContext/AudioContext.web';
const WaveformCaraoussel = ({ url, id }) => {
    const { handleAudioVideo,audioLoader } = useContext(AudioContextApi)
    const [currently,setCurrently] = useState("")

    let selectedSongID;
    const selectedSong = localStorage.getItem("selectedSong")
    if (selectedSong) {
        selectedSongID = JSON.parse(selectedSong)
    }
    const containerRef = useRef();
    const waveSurferRef = useRef([]);
   
    useEffect(() => {
        let currentlyplayed = waveSurferRef.current[id]
        if (selectedSongID === id && currentlyplayed) {
            if (handleAudioVideo === 1) {
                if (currently === id) {
                    currentlyplayed?.pause();
                    currentlyplayed?.setMute(true);
                } else {
                    currentlyplayed?.stop();
                    currentlyplayed?.setMute(true);
                }
            } else if (handleAudioVideo === 2) {
                setCurrently(id)
                currentlyplayed?.play();
                currentlyplayed?.setMute(true);
            } else if (handleAudioVideo === 0) {
                currentlyplayed?.stop();
                currentlyplayed?.setMute(true);
            } else {
    
                currentlyplayed?.stop();
                currentlyplayed?.setMute(true);
            }
        } else {
            currentlyplayed?.stop();
            currentlyplayed?.setMute(true);

        }

    }, [audioLoader, handleAudioVideo,selectedSongID])


    useEffect(() => {
        const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            barWidth: 4,
            barRadius: 5,
            barGap: 2,
            barMinHeight: 1,
            cursorWidth: 1,
            backend: "WebAudio",
            progressColor: "red",
            responsive: true,
            waveColor: "#2e2e2e",
            cursorColor: "transparent",
            interact: false,
            fillParent: true,
            hideScrollbar: true,
            height: 70,
            audioRate:1,
        });
        waveSurfer.load(url);
        waveSurfer.on('ready', () => {
            waveSurferRef.current[id] = waveSurfer;
            let currentlyplayed = waveSurferRef.current[id]
        if (selectedSongID === id && currentlyplayed) {
            if (handleAudioVideo === 1) {
                if (currently === id) {
                    currentlyplayed?.pause();
                    currentlyplayed?.setMute(true);
                } else {
                    currentlyplayed?.stop();
                    currentlyplayed?.setMute(true);
                }
            } else if (handleAudioVideo === 2) {
                setCurrently(id)
                currentlyplayed?.play();
                currentlyplayed?.setMute(true);
            } else if (handleAudioVideo === 0) {
                currentlyplayed?.stop();
                currentlyplayed?.setMute(true);
            } else {
    
                currentlyplayed?.stop();
                currentlyplayed?.setMute(true);
            }
        } else {
            currentlyplayed?.stop();
            currentlyplayed?.setMute(true);

        }
         
        });
        return () => {
            waveSurfer.destroy();
        };
    }, []);
  
    return (
        <div style={{ width: "100%", }} ref={containerRef} />

    );
};

WaveformCaraoussel.propTypes = {
    url: PropTypes.string.isRequired,
};
// {endTime().minutes}:{endTime().seconds}

export default WaveformCaraoussel;
// Customizable Area End