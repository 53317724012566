// Customizable Area Start
import React from 'react'
import {
    defaultProfile,
  } from "../../dashboard/src/assets";
import {
    pauseA,
  } from "./assets";
import {
    Grid,
  } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { CaretRightOutlined } from "@ant-design/icons";
import { Slider } from "antd";
import CircularProgress from '@material-ui/core/CircularProgress';
function CustomAudioPlayerRightIcons(props: any) {
    const { 
      seekBar,
      seekValue, 
      checkType, 
      playlistData, 
      attributes, 
      title, 
      artist_name, 
      loader, 
      finishCheck, 
      restart, 
      IconColor, 
      playBt, 
      pause, 
      play,
      nextTrack
    } = props;

  const playButtonIcons = () =>{
    if(playBt) {

      return (
        <Tooltip placement="top" title="Pause">

          <img src={pauseA} id="music-icon" onClick={pause} className='music-play-icon' alt="" />
        </Tooltip>
      ) 
    }else{ 
      return (
        // play Buttion
        //@ts-ignore
        <Tooltip placement="top" title="Play">
  {/* @ts-ignore */}
          <CaretRightOutlined
            onClick={() => play(playlistData?.id)}
            style={{
              fontSize: "25px ",
              background: "#3959f9 ",
              borderRadius: "50%",
              padding: "7px 6px 7px 8px",
              color: "white"
            }}
            className="music-play-icon-1"
          />

        </Tooltip>
      )}
  } 

  const finalIcons = () =>{
    if(finishCheck()){
        return (
            // @ts-ignore
        <CaretRightOutlined
          onClick={restart}
          style={IconColor}
         
        />
      )}else{
        return playButtonIcons()
      } 
  }
 
  const returnArtistName=()=>{
    if(nextTrack.type=="list_ad_campaign"){
      return(
           <span className="align-oneLine">
          <p className="ad-placement-text">Ad: </p><p className="song-title-custom">{artist_name || "Unknown"}</p>
          </span> 
      )
    }else{
      return(
      <p className="song-title-custom">{artist_name || "Unknown"}</p>
      )
    }        
  }

  return (
    <Grid container xs={12} className="audio-player">
    {/* @ts-ignore */}
    <Slider
      className="seek-bar"
      onChange={seekBar}
      value={seekValue}
      trackStyle={{ backgroundColor: "blue" }}
      tooltip={{
        open: false
      }}
      style={{ margin: "-4px 0px 0px 1px" }}
    />
    <Grid item xs={6}>
      <div className="song-details">

        <img src={checkType === "Playlists" ? playlistData?.attributes?.cover_image : attributes?.art_work ||attributes?.track_artwork|| defaultProfile} alt="" />
        <div className="label">
        <h3 className='mobile-view-audio-title'>{checkType === "Playlists" ? playlistData?.attributes?.name : title || attributes.track_name|| attributes.track_title ||"Default Song(Unknown)"}</h3> 
        {returnArtistName()}
          <p>{artist_name || "Unknown"}</p>
        </div>
      </div>
    </Grid>
    <Grid item xs={6} style={{
      justifyContent: "end",
      display: "flex",
      paddingRight: "30px",
      alignItems: "center"
    }}>
      {loader ?
        <CircularProgress />
        :
        finalIcons()
        }
    </Grid>
  </Grid>
  )
}

export default CustomAudioPlayerRightIcons
// Customizable Area End