Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config")
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DownloadOptions";
exports.labelBodyText = "DownloadOptions Body";

exports.btnExampleTitle = "CLICK ME";
// new changes
exports.dashboardGetUrl = "dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.getAPIMethod = "GET";
exports.labelBodyText = "dashboard Body";
exports.downloadhistory= "/user_download_histories"
exports.getAPIMethod="GET"
exports.baseUrl = urlConfig.baseURL;

// Customizable Area End