// Customizable Area Start
import React from "react";
import {
    Dialog,
} from "@material-ui/core";
import { CommentsFilter, CommentsInactive, PlaysFilter, playsInactive, RepostFilter, RepostInactive } from "../../../blocks/dashboard/src/assets";


export default function ChartsFilter({ open, onClose, chartType,
    chartTime, handlerSelectedFilter, handlerSelectedTime }: any) {



    const renderFilerItem = (name: string, key: any, onClick: any) => {
        return (
            <>
                <button className='filter_time_btn' style={{ backgroundColor: key === chartTime ? "#3959f9" : "#191a1d" }} onClick={onClick}>{name}</button>
            </>
        )
    }


    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(0, 0, 0, 0.15)",
                },
            }}
            PaperProps={{
                style: {
                    backgroundColor: "#121212",
                    borderRadius: "20px",
                    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
                    border: "solid 1px #1f1f1f",
                    width: "300px",
                    padding: "20px"
                },

            }}
        >
            <div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        <img src={chartType === "Plays" ? PlaysFilter : playsInactive} alt="" onClick={() => handlerSelectedFilter("Plays")} className="filter_Items" />
                        <span className='chartTypeFont'>Plays</span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img src={chartType === "Repost" ? RepostFilter : RepostInactive} alt="" onClick={() => handlerSelectedFilter("Repost")} className="filter_Items" />
                        <span className='chartTypeFont'>Reposts</span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img src={chartType === "Comments" ? CommentsFilter : CommentsInactive} alt="" onClick={() => handlerSelectedFilter("Comments")} className="filter_Items" />
                        <span className='chartTypeFont'>Comments</span>
                    </div>
                </div>


                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    {renderFilerItem("This Week", "Week", () => { handlerSelectedTime("Week") })}
                    {renderFilerItem("This Month", "Month", () => { handlerSelectedTime("Month") })}
                    {renderFilerItem("This Year", "Year", () => { handlerSelectedTime("Year") })}
                </div>
            </div>
        </Dialog>
    );
}
// Customizable Area End