// Customizable Area Start
import "antd/dist/antd.css";
import { Row, Col, Form, Input, Checkbox, Button } from "antd";
import React from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../assets/forgotpassword.css";
import { ForgotPasswordBackground, ForgotPShade } from "./assets";
import { nxzborderlogo } from "../../email-account-registration/src/assets";
import { Link } from "react-router-dom";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
const urlConfig = require("./../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export default function ResetPasswordWeb() {
  const history = useHistory();
  const token: any = localStorage.getItem("forget_token")
  const onFinish = async (values: any) => {
    const emailreg = new RegExp(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    )

    await axios
      .put(`${baseUrl}/bx_block_forgot_password/passwords/reset_password?token=${token}`, values
      )
      .then((res) => {
        openNotification(res.data.message, "Success")
        history.push("/welcome");
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0]?.token, "Error")
      });


  };

  return (
    <Row className="bgDark" style={{ height: "100%" }}>
      {/* <Row justify="space-around" align="middle"> */}
      <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create" >
        <img className="image1" src={ForgotPasswordBackground} />
        <img className="image2" src={ForgotPShade} />
      </Grid>
      <Grid item lg={6} md={6} xs={12} className="welcome-form ">

        <Row
          align="middle"
          justify="center"
          style={{
            height: "100%",
            width: "75%",
            margin: "auto",
            textAlign: "center",
          }}
        >

          <Col xxl={15} xl={18} lg={20} md={21} sm={16} xs={21}>

            <Row justify="center" className="welcomeLogo">
           
                <img src={nxzborderlogo} alt="App Logo" />
            </Row>
            <Row justify="center" className="welcome-text">
              <Row
                justify="center"
                className=" width_100 text_white mt-1  font_25 width_100 font_W_600"
              >
                Enter New Password
              </Row>
              <Row justify="center" className="text_white1 width_100">
                Make sure you remember this time.
              </Row>

            </Row>
            <Row justify="center">
              <Col span={24}>
                <Form
                  name="basic"
                  className="login_form reset_input"
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please Input Your New Password!",
                      },
                      {
                        pattern: new RegExp(
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                        ),
                        message:
                          "Password Must Be 8 Character And Contains At Least One A-Z, a-z, 0-9, @$%#",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    className="reset_input_icon"
                    dependencies={['password']}
                    hasFeedback

                    rules={[
                      {
                        required: true,
                        message: 'Please Confirm Your Password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('Your New Password And Confirm Password Should Be Match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item style={{ marginTop: "10%" }}>
                    <Button
                      className="red_custom_btn login_btn "
                      type="primary"
                      htmlType="submit"
                    >
                      Reset Password
                    </Button>
                    <Button
                      className="next_back_account_create back_btn inter"
                      onClick={() => history.goBack()}
                      type="primary"
                      htmlType="submit"
                    >
                      Back
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            {/* <div className="signup-btn">
              <button
                style={{ backgroundColor: "rgb(255 0 0)" }}
                onClick={() => {
                  history.push("/AccountType");
                }}
              >
                SIGN UP
              </button>
              <button className="gglebtn">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="48px"
                    height="48px"
                  >
                    <path
                      fill="#fbc02d"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#e53935"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4caf50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1565c0"
                      d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                </span>
                <span>CONNECT WITH GOOGLE</span>
              </button>
            </div> */}
          </Col>
        </Row>
      </Grid>
      {/* </Row> */}
    </Row>
  );
}
// Customizable Area End
