// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { defaultProfile, playButton } from "../../dashboard/src/assets";
export const configJSON = require("./config.js");
import TrackMsgCardController from "./TrackMsgCardController";
import { Row } from "antd";
import { nxzloader } from "blocks/dashboard/src/assets";
import ProfileTrackImg from './ProfileTrackImg.web'
import TextMsgCard from "./TextMsgCard.web";


export default class TrackMsgCard extends TrackMsgCardController {
  renderLoader = () => {
    return (
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <img src={nxzloader} style={{ width: "5%" }} />
      </Row>
    );
  };

  renderBox = (
    isTrack: any,
    onRedirect: any,
    trackData: any,
    currentuserType: any,
    currentUserId: any,
    isRecived: any,
    trackType:string
  ) => {
    return(
    <Box
      style={{
        height: "100%",
        // width: "100%",
        borderRadius: 15,
        backgroundColor: "#141414",
        position: "relative",
        padding: "10px",
      }}
    >
      <div 
      className="direct-message-track-artwork"
      style={{
        marginTop: isTrack && trackType !=="videoinvitation" ? 140 : 85
      }}
      >
      <img
       className={this.props.avtar?'mediaDMCP-avatar':"default-profile-mediaDMCP"}
        onClick={() => onRedirect(currentuserType, currentUserId)}
        data-test-id="onRedirect"
        src={this.props.avtar || defaultProfile}
      />
      </div>
      <Box
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div className={trackData?.attributes?.artist_image?"direct-message-track-middle-artwork":"dm-creative-defaultimg"}>
        <img
       className={trackData?.attributes?.artist_image?'mediaDMCP-avatar':"dm-child-creative-defaultimg"}
          src={
            trackData?.attributes?.artist_image||defaultProfile
          }
        />
        </div>
        <Box style={{ marginLeft: 10 }}>
          <Typography className="trackDm-artist-style">
            {trackData?.attributes?.artist_name||"Unknown"}
          </Typography>
          <Typography className="trackDm-creativeType-style">
            {trackData?.attributes?.creative_type_name||"Unknown"}
          </Typography>
        </Box>
      </Box>
      <Box 
      // style={{ margin: 10 }}
      >
        <ProfileTrackImg
          isTrack={isTrack}
          image={trackData?.attributes?.art_work}
          handleTrackAndVideo={this.handleTrackAndVideo}
          trackType={trackType}
          trackData={trackData}
        />
      </Box>
      <div
        style={{
          height: 33,
          backgroundColor: "#000",
          borderRadius: 33,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {this.props.trackType.includes("invitation") ? (
          <Typography
            style={{
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            data-test-id="handleRedirectTracks"
            onClick={ this.handleRedirectTracks}
          >
            {isRecived
              ? "You're invited"
              : `${this.props.particularUserDataRes?.attributes?.artist_name} is invited`}
          </Typography>
        ) : (
          <Typography
            style={{
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={this.handleTrackAndVideo}
          >
            {isTrack ? "Listen Now" : "Watch Now"}
          </Typography>
        )}
      </div>
    </Box>
    )
  };
  render() {
    const {
      isRecived,
      isTrack,
      onRedirect,
      particularUserDataRes,
      isChartMessage,
      trackType,
      avtar,
      senderName,
      timetoken,
      text
    } = this.props;

    const { trackData, loader } = this.state;
    const user_type: any = localStorage.getItem("user_type");
    const user_id: any = localStorage.getItem("loggedIn_userId");
    let currentuserType = isRecived
      ? particularUserDataRes?.attributes?.artist_role
      : user_type;
    let currentUserId = isRecived ? particularUserDataRes?.id : user_id;
    if (loader) {
      return this.renderLoader();
    } if(this.state.trackData.length<=0){
      return null
    } 
      return (
          <div
            style={{
              height: isChartMessage ? "none" : "100%",
              width: "100%",
              padding: "5px 0px 20px 30px",
              display: "flex",
              alignItems: isRecived ? "flex-start" : "flex-end",
           justifyContent: isRecived? "flex-start" : "flex-end"
            }}
          >
            {this.renderBox(
              isTrack,
              onRedirect,
              trackData,
              currentuserType,
              currentUserId,
              isRecived,
              trackType
            )}
          </div>
        )
      
  }
}

// Customizable Area End
