// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { HISTORY } from "components/src/common";
import { getStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
import { openNotification } from "components/src/Notification.web";
const user_type: any = localStorage.getItem("loggedIn_userId");


export interface Props {
    webStyle: any;
    rightsideSelectedtab: string;
    handleAudiovideotab: (tab: string) => void;
    usertype: string;
    isLoading: boolean;
    tracklistData: any[];
    isListener: boolean;
    streamedArtist: string;
}


  interface ArtistListResponse{
      data:[{
      id:string,
      type:string,
      attributes:{
      id:number,
      email:string,
      first_name:string,
      last_name:string,
      user_name:string,
      followed_by_current_user:boolean,
      avatar:string,
      index:number,
      track_count:number,
      is_verified:boolean,
      arrow:boolean,
      role:string,
      total_play_count:number | string,
      total_comment_count:number,
      total_repost_count:number,
  }}],
  error?:string
  }

  interface UserRepostResponse {
    user_data: {
      data: [
        RepostListData
      ]
    }
  }

  export interface IDataList {
      id?: string | number;
      type?: string;
      title?: string;
      month?: number; 
      year?: number;
      attributes?: {
      avatar: string
      user_name: string
      count: number
      track_count: number
      is_verified: boolean
      nxz_official_logo: boolean
      total_play_count:number
    }
  }

  export interface RepostListData{
      "id": string,
      "type": string,
      "attributes": {
      "avatar": string,
      "cover_image": string,
      "creative_type_name": string,
      "user_name": string,
      "count": number,
      "listener_count": number,
      "repost_count": number,
      "track_count": number,
      "listener_track_count": number,
      "is_verified": boolean,
      "nxz_official_logo": boolean,
      "total_repost_count":number,
      "listener_repost_count":number
    }
  }
  export interface TopArtistType{

    id:string,
    type:string,
    attributes:{
    id:number,
    email:string,
    first_name:string,
    last_name:string,
    user_name:string,
    followed_by_current_user:boolean,
    avatar:string,
    index:number,
    track_count:number,
    is_verified:boolean,
    arrow:boolean,
    role:string,
    total_comment_count:number,
    total_repost_count:number,

}
}
  export interface ActiveTab {
    title: string;
     month: number; 
     year: number;
    }


interface S {
    usertype: string;
    tabs: Tabs[]
    activeKey:string;
    artistUserID: number | string;
    activeTab: {
        title: string;
        month: number;
        year: number;
      };
    listData: RepostListData[];
    isLoading:boolean;
    token: string;
    top40ArtistList: TopArtistType[];
}

interface SS {
    id: any;
}
export interface Tabs {
    month: string;
}

export default class RepostHistoryController extends BlockComponent<Props, S, SS> {
    getTopListenersAPICallId:string="";
    getUserListApiCallId: string="";
    getTopArtistCallId:string="";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),

            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            activeKey:"1",
            listData: [],
            artistUserID: 0,
            activeTab: {
                title: "This Month",
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear()
            },
            isLoading:false,
            tabs: [{ month: "This Month" }, { month: "July '24" }, { month: "Aug '24" }, { month: "Sep '24" },
             { month: "Oct '24" }, { month: "Nov '24" }, { month: "Dec '24" }, { month: "Jan '24" }, { month: "Feb '24" }, { month: "March '24" }, { month: "April '24" }],
            usertype: localStorage.getItem("user_type") || "",
      token: "",
      top40ArtistList:[]
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("token");
        this.setState({ token: token });
        this._getUserList()
        this._top40ArtistAPICall()
    }

    getLast12MonthsData() {
        const currentDate = new Date();
        const last12MonthsData = []; 
        let isCurrentMonth = true;
        for (let i = 0; i < 12; i++) {
          const month = currentDate.getMonth() + 1;
          const year = currentDate.getFullYear();
          let title;
          if (isCurrentMonth) {
            title = "This Month";
            isCurrentMonth = false;
          } else {
            const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(currentDate);
            title = month === 1 ? `${monthName} '${(year % 100) - 1}` : `${monthName} '${year % 100}`;
          }
          last12MonthsData.push({ title, month, year });
          currentDate.setMonth(currentDate.getMonth() - 1);
        }
    
        return last12MonthsData;
      }

      getProgress = (item:RepostListData) => {
          try {
              let num = 0
              if (this.state.usertype == "label") {
                const data_array = this.state.listData;
                const max_play_count = Math.max(...data_array.map((item: { attributes: { total_repost_count:number }; }) => item.attributes.total_repost_count));
                num = item.attributes?.total_repost_count / max_play_count
            } else {
                num = item.attributes?.repost_count / item.attributes?.track_count

            }

            if (num === Infinity) {
                return 0
            }
            return num
        } catch (error) {

        }
        return 0
  };

      _topListenersAPICall=async()=>{
        this.setState({ isLoading: true })
        const header = {
          "Content-Type": "application/json",
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTopListenersAPICallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/top_reposters?user_id=${this.state.artistUserID}&expYear=${this.state.activeTab.year}&expMonth=${this.state.activeTab.month}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

      handleTabChange = async(activeKey:string) => {
        this.setState({ activeKey});
        const returnedMonthsData=  this.getLast12MonthsData();
        const selectedIndex = parseInt(activeKey);
        const selectedItem=returnedMonthsData[selectedIndex]
        if (selectedItem) {
          this.handleData(selectedItem);
        }
      };
      
      handleData = (item:ActiveTab) => {

        this.setState({ activeTab: item },()=>{
       if (this.state.usertype.includes("listener") || this.state.usertype.includes("creative")) {
         this._getUserList()
     } if (this.state.usertype == "label") {
         this._topListenersAPICall()
     };
   })
   }

   _top40ArtistAPICall(){
    this.setState({ isLoading: true })
    const user_type = localStorage.getItem("loggedIn_userId")
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopArtistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/top_hundred_creative?user_id=${user_type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  showArtistData=(item:TopArtistType)=>{
    this.setState({artistUserID:item.id},
      ()=>this._topListenersAPICall()
      )
    
  }

   _getUserList=() =>{
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/creative_view_histories/stream_history?expYear=${this.state.activeTab.year}&expMonth=${this.state.activeTab.month}&history_type=Repost`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

    redirectToProfile = () => {
        const loggedIn_userId: any = localStorage.getItem("loggedIn_userId");
        const userType: any = localStorage.getItem("user_type");


        if (userType?.includes("listener")) {
            HISTORY.push({
                pathname: `/listeners/activity?listener_id=${loggedIn_userId}`,
            })
        }

        else if (userType?.includes("creative")) {
            HISTORY.push({
                pathname: `/creatives/creatives?creative_id=${loggedIn_userId}`,
            })
        }
        else if (userType?.includes("label")) {
            HISTORY.push({
                pathname: `/labels/creatives?labels_id=${loggedIn_userId}`,
            })
        }

    }

    handleTopArtistApiCallSuccessCallBack=(responseJson:ArtistListResponse)=>{
      if (responseJson !== undefined && !responseJson.error) {
        if (responseJson.data.length>0) {
          const uniqueItems = responseJson.data.filter((obj: any, index: any, self: any) =>
          index === self.findIndex((o: any) => o.id === obj.id)
        );
        this.setState({ top40ArtistList: uniqueItems, 
          artistUserID: responseJson.data[0].id ,
          isLoading:false
        },
          () => this._topListenersAPICall()
        )
       
        }else {
          this.setState({isLoading:false,top40ArtistList:[]})
        }
      }else{
        openNotification(responseJson.error,"Error")
      }
      }


      getUserListApiCallSuccessCallBack(responseJson: UserRepostResponse) {
        const tempResponse = responseJson
        if (tempResponse?.user_data?.data?.length > 0) {
          this.setState({ listData: tempResponse.user_data.data, isLoading: false })
        } else {
          this.setState({ listData: [], isLoading: false })
        }
      }


    handleTopListenersCallSuccessCallBack=(responseJson:any)=>{
      if (responseJson !== undefined && !responseJson.error) {
          this.setState({listData:responseJson.data, isLoading:false})
       
        
      }else{
          this.setState({isLoading:false,listData:[]})
        }
      }
      async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
          )
         if(apiRequestCallId == this.getTopListenersAPICallId){
          this.handleTopListenersCallSuccessCallBack(responseJson)
        }else if(apiRequestCallId == this.getTopArtistCallId){
            this.handleTopArtistApiCallSuccessCallBack(responseJson)
          }else if (apiRequestCallId === this.getUserListApiCallId) {
              this.getUserListApiCallSuccessCallBack(responseJson);
                
          }
        }
        // Customizable Area End
      }
    

}
RepostHistoryController.contextType = AudioContextApi;
// Customizable Area End