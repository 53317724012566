// Customizable Area Start
import React from "react";
import "./TopSongs.web.css";
import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
 iconUp,
 defaultProfile,
 down, expandMore,
 playcountP, loopIc, chatPlaylist,
} from "../../../dashboard/src/assets";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import millify from "millify";
import LineChart from "components/src/CustomChart/PopUps/LineCharts.web";
import { setStorageData } from "../../../../framework/src/Utilities";
import { HISTORY } from   "../../../../components/src/common";


const TopVideo = ({ songsList, trackName }: any) => {
 const history = useHistory();
 const top_Video_loggedIn_userId = localStorage.getItem('loggedIn_userId')
 
 const showVideos = async ( videoFile: any,allVideoList: any,singlesong: any) => {
  if(singlesong.attributes.level && top_Video_loggedIn_userId != singlesong.attributes.account_id && !singlesong.attributes.unlocked_track_by_current_user){
    const belowLevelVideo = levelVideoBelowReturn(singlesong.attributes.level)
    const unlock_below_video_data = {track_id: singlesong.id, user_id: localStorage.getItem('loggedIn_userId'), level: belowLevelVideo}
    setStorageData('unlock_details', unlock_below_video_data)
    HISTORY.push('/streamUnlock')
  }else{
  localStorage.removeItem("selectedTab");
    history.push({
      pathname: "/home/Charts/videos",
      state: {
        video_list: videoFile,
        activeNo: "5",
        currentList: allVideoList,
        data: singlesong,
      },
    });
  }
  };

const levelVideoBelowReturn = (level: any) =>{
  if(level == 'level_1'){
    return 'L1'
  }else if(level == 'level_2'){
    return 'L2'
  }else{
    return 'L3'
  }
}


  const formatIndex = (index: any) => {
    if (typeof index === "number" && index >= 0) {
      const formattedIndex = index + 1;
      return formattedIndex < 10
        ? "0" + formattedIndex
        : formattedIndex.toString();
    } else {
      return "0";
    }
  };

  const getSrc = (trackName: any, item: any, defaultProfile: any) => {
    if (trackName === "Playlists") {
      return item?.attributes?.cover_image;
    } else if (item?.attributes?.art_work) {
      return item?.attributes?.art_work;
    } else {
      return defaultProfile;
    }
  };
  return (
    <Box className="videos-parent-div width100">
      <Grid className="topSongs-grid-head" container>
        <Grid item xs={7} md={7} lg={8}>
          <Row className=" width_100">
            <Typography className="top-head">
              {songsList && "Top " + trackName}
            </Typography>
          </Row>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={3}
          className="second-head-percentage dflex"
        >
          <Hidden xsDown>
            <Grid item xs={3}>
              <p className=" text_white1 top_size_16 hiddnDaysScore mt4">
                24hr%
              </p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_white1 top_size_16 topSongs-7days">7d%</p>
            </Grid>
            <Grid item xs={5} className="hiddnDaysGraph align-center">
              <p className="text_white1 top_size_16 hiddnDaysGraph mt4">
                {" "}
                Last 7d{" "}
              </p>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Box
      >
        {songsList &&
          songsList.map((item: any, index: any) => {
            return (
              <Col
                span={24}
                onClick={() =>
                  showVideos(item.attributes?.video_file, songsList, item)
                }
                className="musicRow"
              >
                <Grid
                  container
                  className="main-container-space songs-head-list-child"
                >
                  <Grid
                    item
                    md={4}
                    lg={3}
                    xs={3}
                    sm={4}
                    className="profileDetailBlock"
                  >
                    <div className=" width_100 dflex">
                      <div>
                        <Row className="sn-grid mr_1 ml_05">
                          {" "}
                          {formatIndex(index)}{" "}
                        </Row>
                        <img
                          src={item.attributes.arrow ? iconUp : down}
                          className="icon-up mr_05 ml_05"
                          onClick={item.handlePlayList}
                        />
                      </div>
                      <div style={{position: 'relative'}}>
                      { item.attributes.level &&  top_Video_loggedIn_userId != item.attributes.account_id && !item.attributes.unlocked_track_by_current_user &&
                        <div style={{ position: 'absolute' , height: '100%', width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{color: 'white', border: '2px solid white', borderRadius: '50%', backgroundColor: 'red', padding: '3px', fontSize: '12px', fontWeight: 'bold'}}>
                          {levelVideoBelowReturn(item.attributes.level)}
                        </span>
                        </div>
                        }
                        <img
                          className="song-cover mr_1 top-video-cover-img topSongs-default-profile"
                          src={getSrc(trackName, item, defaultProfile)}
                          alt=""
                        />
                        
                      </div>
                      <div>
                        <Row>
                          <Row
                            justify="start"
                            className="width_100 playlist_video_title text_white cursor_pointer "
                          >
                            {" "}
                            {item?.attributes?.title || "Unknown"}
                          </Row>
                          <Row
                            justify="start"
                            className="width_100 text_gray font_12  cursor_pointer artist-name-width"
                          >
                            {item?.attributes?.artist_name || "Unknown"}
                          </Row>
                        </Row>
                      </div>
                    </div>
                  </Grid>
                  <Col xs={6} className="HiddnFullScreen hours-list">
                    <Row justify="end" className="iconDelete">
                      <img
                        src={expandMore}
                        className=" ml-1 mr_1"
                        height="28px"
                        width="28px"
                      />
                    </Row>
                  </Col>
                  <Hidden smDown>
                    <Grid
                      item
                      md={1}
                      xs={2}
                      sm={4}
                      lg={3}
                      className="playButtonContain topSongs-aligncenter"
                    >
                      <Row className=" width_100">
                        <Hidden xsDown>
                          <Col span={24} className="topSongs-aligncenter">
                            {" "}
                          </Col>
                        </Hidden>
                      </Row>
                    </Grid>
                  </Hidden>
                  <Grid
                    item
                    sm={3}
                    md={2}
                    lg={2}
                    className="iconButton topSongs-aligncenter"
                  >
                    <div className=" display-play_dark_back video-comment-icons" style={{}}>
                      <Col className="colButton width_100">
                        <div className="iconContent topSongs-aligncenter">
                          <img
                            className="iconImg"
                            src={playcountP}
                            height="13px"
                            width="11.5px"
                          />
                          <Box className="play-times iconContent">
                            {item?.attributes?.play_count
                              ? millify(item?.attributes?.play_count)
                              : "0"}
                          </Box>
                        </div>
                      </Col>
                      <Col className="colButton width_100">
                        <div className="iconContent dflex">
                          <img
                            className="iconImg"
                            src={loopIc}
                            height="19px"
                            width="19px"
                          />
                          <Box className="play-times iconContent">
                            {item?.attributes?.repost_count
                              ? millify(item?.attributes?.repost_count)
                              : "0"}
                          </Box>
                        </div>{" "}
                      </Col>
                      <Col className="colButton width_100">
                        <div className="iconContent topSongs-alignEnd">
                          <img
                            className="iconImg"
                            src={chatPlaylist}
                            height="20px"
                            width="20px"
                          />
                          <Box className="play-times iconContent">
                            {item?.attributes?.comments_count
                              ? millify(item?.attributes?.comments_count)
                              : "0"}{" "}
                          </Box>
                        </div>
                      </Col>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={5}
                    md={5}
                    lg={4}
                    className="hidden_percentage"
                  >
                    <LineChart
                      seven_days={item?.attributes?.last_seven_days_percentage}
                      twenty_four_days={
                        item?.attributes?.last_twenty_four_hours_percentage
                      }
                      seven_days_graph={item?.attributes?.last_seven_days_graph}
                      fullscreen={expandMore}
                      onFullScreen={(e: any) => {}}
                    />
                  </Grid>
                </Grid>
              </Col>
            );
          })}
      </Box>
    </Box>
  );
};
export default TopVideo;
// Customizable Area End
