// Customizable Area Start
import React from 'react'
import { Grid, Button } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import {  Upload } from "antd";

import './index.web.css'
import { CameraIcon, UploadImageCover} from '../assets';
import { openNotification } from '../../../../components/src/Notification.web';
import UpladCoverController from './UploadCoverController.web'
export default class UploadCover extends UpladCoverController {

  render() {
  const {setActiveStep,setBackStep}: any = this.props;

  const uploadType = window.location.href.split('/')[4]



  return (
    <Grid container className='UploadMainScroll'>
          
           <div className='main-div'>
            <Grid container item xs={12} md={12} lg={7} style={{justifyContent: 'center',alignItems: 'center', padding: '10px', display: 'flex', flexDirection: 'column'}} >

              <div className='cover-margin-top'>
              <Upload
                              name="uploadMP3"
                              
                              showUploadList={false}
                              

                              customRequest={(options: any) => {
                                this.uploadCover(options)

                              }}

                            // beforeUpload={beforeUpload}

                            // onChange={handleChange}

                            //style={{alignItems: 'center',justifyContent: 'center',marginTop: '200px', padding: '50px',color: 'white',backgroundColor: '#060404'}}
                            >
                   <Grid item className= {`Frame-135 justify-audio ${uploadType == 'audio'? 'height-width-audio': 'height-width-video'} margin-left-main upload-cover-margin`} style={{justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                   
                      {
                        this.state.coverImage? 
                        <img style={{borderTopLeftRadius: '25px',borderTopRightRadius: '25px',height: '82%',width: '102%',backgroundColor: '#141414'}} src={this.state.coverImage}/>
                        :
                        <Grid item className={`${uploadType == 'audio'? 'drag_and_drop_padding-audio': 'drag_and_drop_padding-video'}`}  style={{borderTopLeftRadius: '25px',borderTopRightRadius: '25px',height: '82%',width: '102%',backgroundColor: '#141414'}}>
                          

                          {
                            
                            <Grid item style={{ alignItems: 'center',justifyContent: 'center', paddingTop: uploadType == 'audio'? '5%' : '2%'}}>     
                              <img src={CameraIcon} style={{height: '43.3px', width: '43.3px'}}/>  
                            </Grid>
                          } 
                            

                           
                        </Grid>
                      }

                     
                      <Grid item style={{borderBottomLeftRadius: '30px',borderBottomRightRadius: '30px', width: '100%', color: 'white', fontFamily: 'Inter', fontSize: '12px', fontWeight: 'bold', padding: '15px'}}>{ uploadType == 'audio'? <div>(Drag And Drop Or Click To Upload) </div> : <div>(Upload: Jpg,Png,Jpeg,Tiff)</div> }</Grid>
  
                  </Grid>
                  </Upload>
                  </div>
                  {this.state.validationCoverText != '' && <Grid style={{marginTop: '5px', color: 'red', fontSize: '14px', fontWeight: 'bold', display: 'flex', justifyContent: 'center'}}>{this.state.validationCoverText}</Grid>}
            </Grid>

            {
              <>
              <Grid item xs={12} md={12} lg = {5} className="hide-side-image-in-responsive">
                <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                   <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414', width: '100%', height: '100%'}} src={UploadImageCover} />

                   <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                   <div className=' name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Make Sure</div>
                   <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> "THEY" See You </p>


                   <Button className='buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold',textTransform: 'capitalize', marginBottom: '10px'}} onClick={this.nextClick} >Next</Button>
                   {/* <Button className='buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)', fontWeight: 'bold',textTransform: 'capitalize', marginBottom: '10px'}}>Save</Button> */}
                   <Button variant="text" className='buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold',textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

                </div>
                </div>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
              <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform: 'capitalize'}}  onClick={this.nextClick} >Next</Button>
                {/* <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}}>Save</Button> */}
                <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

              </div>

            </Grid>
            </>
            
            }
            
            </div> 
         </Grid>
  )
}
}
// Customizable Area End