// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");
import { Message } from "../../../../../framework/src/Message";

export interface Props {
  navigation: any;
  id: string;
  topPlayMusic: any;
  getStatusDetails: any;
}

interface S {
  active: string;
  heading: string;
  index: string;
  loading: boolean;
  top40SplitsList: any;
  masterSplitsList: any;
  mastertop40SplitsList: any;
  splitList: any;
  selectedIndex: any;
  showdetails: boolean;
  threebtnToogle: any;
  splitDetailsId: any;
  searchText: any;
  openFilterPopup:boolean;

}

interface SS {
  id: any;
}

export default class SplitsController extends BlockComponent<Props, S, SS> {
  getTopSplitsApiCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      active: "0",
      heading: "Snapshot",
      index: "Tracks",
      loading: false,
      top40SplitsList: [],
      masterSplitsList: [],
      mastertop40SplitsList: [],
      splitList: [],
      selectedIndex: "",
      showdetails: false,
      threebtnToogle: "",
      splitDetailsId: 0,
      searchText: "",
      openFilterPopup:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getSplitsData("Tracks");
  }

  getSplitsData = (type: any) => {
    if (type.endsWith("s") && type !=="Tracks") {
      type = type.slice(0,-1);
      // if(type=)
    }

    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopSplitsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_audiomusic/get_all_tracks?upload_type=${type}&tab_type=Split`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  selectedsong = (index: any, trackId: any) => {
    const topDiv = document.getElementById("yourTopDivId12");
    if (topDiv) {
      topDiv.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }
    this.setState({
      selectedIndex: index,
      showdetails: true,
      splitDetailsId: trackId,
    });
  };

  handleSplitSearch = (e: any) => {
    let text = e.target.value;
    if (text) {
      const top40data = this.state.mastertop40SplitsList.filter(function(
        item: any
      ) {
        const itemData = item.attributes.title
          ? item.attributes.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ top40SplitsList: top40data });

      const splitData = this.state.masterSplitsList.filter(function(item: any) {
        const itemData = item.attributes.title
          ? item.attributes.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ splitList: splitData, searchText: text });
    } else {
      this.setState({
        top40SplitsList: this.state.mastertop40SplitsList,
        splitList: this.state.masterSplitsList,
        searchText: text,
      });
    }
  };


  changetabs = (tabs: string) => {
    this.setState({
      index: tabs,
      selectedIndex: "",
      showdetails: false,
      threebtnToogle: tabs,
    });
    this.getSplitsData(tabs)
  };

  handleSplitsList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.top_forty_tracks?.data) {
        this.setState({
          top40SplitsList: responseJson?.top_forty_tracks.data,
          mastertop40SplitsList: responseJson?.top_forty_tracks.data,
          loading: false,
        });
      } else {
        this.setState({
          top40SplitsList: [],
          mastertop40SplitsList: [],
          loading: false,
        });
      }
      if (responseJson?.all_tracks?.data) {
        this.setState({
          splitList: responseJson.all_tracks.data,
          masterSplitsList: responseJson.all_tracks.data,
          loading: false,
        });
      } else {
        this.setState({
          splitList: [],
          masterSplitsList: [],
          loading: false,
        });
      }
    } else {
      this.setState({
        top40SplitsList: [],
        splitList: [],
        mastertop40SplitsList: [],
        masterSplitsList: [],
        loading: false,
      });
    }
  };


  handleCloseFilterPopup=()=>{
    this.setState({openFilterPopup:false})
  }

  handleOpenFilterPopup=()=>{
    this.setState({openFilterPopup:true})
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.getTopSplitsApiCallId) {
      this.handleSplitsList(responseJson);
    }

    // Customizable Area End
  }
}
// Customizable Area End
