// Customizable Area Start
import React from "react";
import CardItem from "../Common/CardItem.web";
import VideosList from "../Common/VideosList.web";
import { Col, Row, Carousel } from 'antd';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Creatives/Home.web.css";
import TopSongs from "../Common/TopSongs.web";
import TopVideo from "../Common/TopVideo.web";
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import CustomAudioPlayer from "../../../AudioMusic/src/CustomAudioPlayer.web";
import { nxzloader, rightAngle, leftAngle } from "blocks/dashboard/src/assets";
import { Grid, Box, IconButton, AppBar, Tabs, Tab } from "@material-ui/core";
//@ts-ignore
import { settings, videosettings } from "components/src/CustomChart/PopUps/CaraouselSetting.js";
import { PrevButtonOrginal, NextButtonOrginal, errorMessage } from "components/src/CustomChart/PopUps/CarouselButtonHandler";
import '../Common/Activity-new/Activity.web.css'
import ActivitySelfView from "../Common/Activity-new/ActivitySelfView.web";
import ActivityPublicView from "../Common/Activity-new/ActivityPublicView.web";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import ButtonGroupWeb from "components/src/CustomChart/PopUps/ButtonGroup.web";
import TopVideoPlaylist from "../Common/TopVideoPlaylist.web";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import UserProfileBasicController from "../UserProfileBasicController";
export const configJSON = require("../../../../framework/src/config.js");

interface TabPanelProps { children?: React.ReactNode; index?: any; value: any; beats?: any; isPlaylist?: any }


function getClassName(isPlaylist: any, beats: any) {
  if (isPlaylist && beats > 0) {
    return 'mostly-customized-scrollbar tabpanel-scroll listener-tab-parent-height-audio';
  } else if (beats === 0) {
    return 'mostly-customized-scrollbar tabpanel-scroll listener-tab-parent-without-data';
  } else {
    return 'mostly-customized-scrollbar tabpanel-scroll listener-tab-parent-height';
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, beats, isPlaylist, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={getClassName(isPlaylist, beats)}
    >
      {value === index && (
        <Box p={3} style={{ height: "100%", overflowX: "clip", position: "relative" }}>
          {children}
        </Box>
      )}
    </div>
  );
}
const token: any = localStorage.getItem("token")
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'none',


  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#fff',
      borderRadius: "35px",
      backgroundColor: "#212121",
      padding: "3px 5px ",
      margin: "0px 2px ",
      '&:focus': {
        backgroundColor: 'red',
      },
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export default class TabItems extends UserProfileBasicController {
  slider: React.RefObject<unknown>;
  constructor(props: any) {
    super(props);
    this.slider = React.createRef();
  }

  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <IconButton
          className="arrow-btn prev"
          style={{ position: "absolute", top: this.state.value === 4 || 6 ? "30%" : "26%", left: "0%" }}
          //@ts-ignore
          onClick={() => this.slider.slickPrev()}
        >
          <img src={leftAngle} height="24px" width="24px" />
        </IconButton>
        <IconButton
          className="arrow-btn nextarrow"
          style={{ position: "absolute", top: this.state.value === 4 || 6 ? "30%" : "26%", right: "0%" }}
          //@ts-ignore
          onClick={() => this.slider.slickNext()}
        >
          <img src={rightAngle} height="24px" width="24px" />
        </IconButton>
      </div>
    );
  };

  handleCustomAudioVideo = () => {

    if (this.state.playPlaylist) {
      return (
        <div style={{ position: "fixed", left: 0, bottom: 0, width: "100%" }} >
          <CustomAudioPlayer playlistData={this.state.playlistData} playList={this.state.selectedPlayList} setPlaylistData={(list: any) => this.setState({ playlistData: list })} checkType={this.state.checkType} handleAudioVideo={this.state.handleAudioVideo} setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })} />
        </div>
      )
    }
  }

  renderTopSongs = () => {
    if (this.state.getTopPlaylists?.length > 0) {
      return (
        <TopSongs songsList={this.state.getTopPlaylists} beatList={this.state.getTopPlaylists?.length}
          handlePlayList={this.handleRedirectPlaylist} playlistData={this.state.playlistData} setHandlePlay={(value: any) => this.setState({ handleplay: value })} handleplay={this.state.handleplay} handleAudioVideo={this.state.handleAudioVideo} setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })}
          handlePlayLists={this.handleCardPlaylist}
          trackName="Playlists" />
      )
    }
    else {
      return null
    }
  }




  renderPrevArrow = () => {
    return (
      this.state.beatList?.length > this.state.window_width &&
      <>
        <PrevButtonOrginal top="40%" left="-5px" buttonRef={this.myRef}></PrevButtonOrginal>
        <NextButtonOrginal top="40%" right="-15px" buttonRef={this.myRef}> </NextButtonOrginal>
      </>
    )
  }



  renderTop100 = () => {
    if (this.state.top_playlistVideo?.length > 0) {
      return (
        <TopVideoPlaylist songsList={this.state.top_playlistVideo} beatList={this.state.top_playlistVideo?.length} handlePlayList={this.handleCardPlaylist} playPlaylist={this.state.playPlaylist} trackName="Playlists" />
      )
    } else {
      errorMessage('Videos')
    }

  }

  renderVideos = () => {

    if (this.state.playlistVideo?.length > 0) {
      return (
        <>

          <div className="render-arrow-div " style={{ marginTop: "4px" }}>
            <Carousel ref={this.myRef} {...videosettings} infinite={false} >
              {this.state.playlistVideo?.map((video: any, index: any) => {
                return (
                  <div className="playlist-div video-card-center" >
                    <VideosList id={video.id} arrow={video.attributes.arrow} image={video.attributes.cover_image} name={video.attributes.name} artist={video.attributes.artist_name} play={video.attributes.play_count} refresh={video.attributes.repost_count} playCount={video?.attributes?.play_count} trackFile={video?.attributes?.video_file} repost_count={video?.attributes?.repost_count} cardId={index} selected={this.state.selected} setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideos: value })} handleAudioVideo={this.state.handleAudioVideo} setSelected={(key: any) => { this.setState({ selected: key }) }} type="playlist" videoList={this.state.playlistVideo} lockLevel={video.attributes.level} lockContentUserId = {video.attributes.account_id} unlockedTrack = {video.attributes.unlocked_track_by_current_user}/>
                  </div>);
              })}
            </Carousel>
            {this.renderPrevArrow()}
          </div>
        </>
      )
    } else {
      return (
        errorMessage('Playlists Video')
      )
    }

  }

  renderPlayList = () => {

    if (this.state.musicButton) {
      return (
        this.state.getCreativeTabsLoader ?
          <Row align="middle" justify="center"
            style={{
              position: "absolute", left: "50%", top: "29%",
              //  @ts-ignore
              zIndex: "9999"
            }}
          >
            <Col>
              {" "}
              <img src={nxzloader} style={{ width: "20%" }} />
            </Col>
          </Row>
          :
          <Grid container xs={12}>
            <Row> <Grid container item xs={12} className="tab-content" style={{ width: "180px" }}> New Playlists</Grid> </Row>
            <Grid item xs={12} >
              {this.renderVideos()}
            </Grid>
            <Grid item xs={12} >
              {this.renderTop100()}
            </Grid >
          </Grid>

      )
    }
    else {
      return (
        <>
          {this.state.getCreativeTabsLoader ?
            <Row align="middle" justify="center"
              style={{
                position: "absolute", left: "50%", top: "29%",
                //  @ts-ignore
                zIndex: "9999"
              }}
            >
              <Col>
                {" "}
                <img src={nxzloader} style={{ width: "20%" }} />
              </Col>
            </Row>
            :
            <>
              <Col span={24} style={{ height: this.state.beatList?.length > 0 ? "auto" : "100%" }}>
                <Row>
                  <Grid container item xs={12} className="tab-content" style={{ width: "180px" }}> New Playlists</Grid>
                </Row>
                {this.state.beatList?.length > 0 ? (
                  <>
                    <div className='orginal-content' style={{ position: "relative" }}>
                      <Carousel ref={this.myRef} {...settings} infinite={false} >
                        {this.state.beatList.length !== 0 && [...this.state.beatList].sort((a: any, b: any) => b.attributes.play_count - a.attributes.play_count)?.map((item: any, index: any) => {
                          return (
                            <div key={item?.attributes?.id} >
                              <CardItem image={item?.attributes?.cover_image} name={item?.attributes?.name} artist={item?.attributes?.artist_name} play={item?.attributes?.play_count} refresh={item?.attributes?.repost_count} mainList={this.state.beatList} item={item} handlePlayList={() => this.handleCardPlaylist(item)} />
                            </div>
                          );
                        })}
                      </Carousel>
                      {this.renderPrevArrow()}
                    </div>
                  </>
                )
                  : errorMessage('Playlists Audio')
                }
              </Col>
              <Row style={{ width: "100%" }}>
                {this.renderTopSongs()}
              </Row>
            </>
          }
        </>
      )

    }
  }


  returnTopVideo = () => {
    if (this.state.getTop100?.length > 0) {
      return (
        <TopVideo songsList={this.state.getTopVideos} beatList={this.state.getTopVideos?.length} playPlaylist={this.state.playPlaylist} trackName="Videos" />
      )
    }

  }

  checkSeeAll = () => {

    if (token) {
      return (
        <>
          {this.state.seeAll && (
            <Grid item xs={6} style={{ fontSize: "16px", fontWeight: "bold", color: "#8f92a1", justifyContent: "start", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => this.setState({ seeAll: false })} > Back</Grid>
          )}
        </>
      )


    }
  }
  showButtonGroup = () => {
    if (this.state.listenerValue === 1) {
      return (
        <Grid item md={2} xs={6} lg={2} className="audio-div">
          <ButtonGroupWeb
            musicButton={this.state.musicButton}
            setMusicButtonTrue={() => {
              this.setState({ musicButton: false })
            }}
            setMusicButton={() => {
              this.setState({ musicButton: true })
            }} />
        </Grid>
      )
    }
  }
  getAppbarClassName(playPlaylist: any) {
    if (playPlaylist) {
      return "appbar-height appbar-home";
    } else {
      return "appbar-height-playlist appbar-home";
    }
  }


  render() {

    const { gridSizes, creativeById, beatList, openAlertPopUp, dialogText, OpenUpgradModal, listenertabName, get_All_Activity_data, get_notification, listenerValue }: any = this.state;
    const { xxlSize } = gridSizes
    const currentUser = localStorage.getItem('temp_id') ? localStorage.getItem('temp_id') : localStorage.getItem('user_id')
    const { isPlaylist } = this.context;
    return (


      <div style={{ height: "100%" }}>
        <AppBar position="static"
          className="listener-header-height" >
          <Grid container xs={12} style={{ display: 'flex' }} >
            <Grid item xs={12} sm={8} lg={this.state.listenerValue == 1 ? 9 : 10} xl={this.state.listenerValue == 1 ? 9 : 10} >
              <StyledTabs
                value={this.state.listenerValue} onChange={this.checkListenerCurrentTab} aria-label="nxz-tabs"
               
                // @ts-ignore
                variant="scrollable"
              >
                {listenertabName.map((item: any) => {
                  return (

                    <StyledTab label={item.tabName}
                      //@ts-ignore
                      className={this.state.listenerValue === item.value ? "active-tab" : "non-active-tab"}
                      {...a11yProps(item.value)} />
                  )
                })}

              </StyledTabs>
            </Grid>
            <Grid style={{ backgroundColor: "black" }} item xs={12} sm={3} lg={xxlSize} xl={xxlSize} className="listener-filter-div" >
              <Grid style={{ display: "flex", alignItems: "flex-start" }} alignItems="flex-end">
                <div >
                  {this.showButtonGroup()}
                </div>
                <div style={{position:"relative"}}>
                  <FilterDropDown filterValue={this.state.filterValue}
                    handleFilter={(data: any) => this.handleFilter(data)}
                    listener={true}
                  />
                </div>

              </Grid>

            </Grid>
          </Grid>
        </AppBar>


        <TabPanel index={0} value={listenerValue}
          beats={get_All_Activity_data?.length} isPlaylist={isPlaylist}
        >
          {currentUser == creativeById.id ? <ActivitySelfView get_All_Activity_data={get_All_Activity_data}
            creativeById={creativeById} get_notification={get_notification}
            handleReadNotification={this._readNotification}
          
            
            />
            :
            <ActivityPublicView get_All_Activity_data={get_All_Activity_data} creativeById={creativeById} />
          }
        </TabPanel>
        <TabPanel value={listenerValue} index={1} beats={this.state.musicButton ? this.state.playlistVideo.length : beatList?.length} isPlaylist={isPlaylist}>
          {this.renderPlayList()}
        </TabPanel>
        {this.handleCustomAudioVideo()}
        <LoginAlert open={openAlertPopUp} handleClose={this.handleClose} dialogText={dialogText} handleCloseOpenUpgradModal={this.handleCloseOpenUpgradModal} OpenUpgradModal={OpenUpgradModal} handleUpgradeModal={this.handleUpgradeModal} />
      </div>
    );
  }
}
TabItems.contextType = AudioContextApi;
// Customizable Area End