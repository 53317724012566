// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import React from "react";





export interface Props {
    navigation: any;
    id: string;
    chartData:any;
    isgradient:boolean
    labellength:number
}


interface S {
    graph: any
    options: object
    datas: object
  

}

interface SS {
    id: any;
}

export default class ChartController extends BlockComponent<
    Props,
    S,
    SS
> {


    subscriptionPlan = localStorage.getItem("subscription_plans")

    getTop100ListsApiCallId: any
    chartReference: any;



    constructor(props: Props) {
        super(props);
        this.chartReference = React.createRef();
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
        ];

        this.state = {
             
            graph: [1,2,4,3,2,7,3,2,3,1,2],
            datas: {},
            options: {}


        };

        
            
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }
   
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.updateTracks()
        if (this.chartReference && this.chartReference.current && this.props.isgradient) {
            const chartInstance = this.chartReference.current.chartInstance;
    
            if (chartInstance) {
               
                chartInstance.canvas.parentNode.style.position = 'relative';
     
                const gradient = document.createElement('div');
                gradient.style.position = 'absolute';
                gradient.style.bottom = '0';
                gradient.style.left = '0';
                gradient.style.right = '0';
                gradient.style.top = '50%';
                gradient.style.background = 'linear-gradient(to bottom, rgba(31, 31, 31, 0.8), transparent)';
                // gradient.style.borderTop="5px solid black"
                gradient.style.marginTop='-5px'
                
                chartInstance.canvas.parentNode.appendChild(gradient);
            }
        }
    }
updateTracks=()=>{
    this.setState({
        graph: [1,2,4,3,2,7,3,2,3,1,2],
        datas: {
            labels: new Array(this.props.labellength),
            datasets: [
                {
                    data: this.props.chartData  ,
                    // data: [0,0,0,3,4,5,0],
                    backgroundColor:this.props.isgradient ? "#ff0000" :['#ff0000', 'r#ff0000', '#ff0000', '#ff0000', '#ff0000', '#3959F9', '#ff0000'],
                    barPercentage: 0.9,
                    categoryPercentage: 1,
                    borderWidth: 1,
                    borderSkipped: false,
                    borderRadius: 7,
                    hoverBackgroundColor: '#3959f9',
                },
            ],
        },
        options: {
            layout: {

                padding: {
                    bottom: -12
                }
            },
            cornerRadius: 7,
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: true
                },
                x: {
                    stacked: true, // Stack on x-axis as well
                },

                yAxes: [
                    {
                        gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                        },
                        radius: 7,

                        ticks: {
                            stepSize: 2,
                            display: false,
                            beginAtZero: true,
                            stacked: true
                        },
                    },
                ],


                xAxes: [
                    {
                        gridLines: {
                            color: '#ff0000',
                            display: this.props.chartData?.every((current: any) => current == 0) ? true : false,
                            drawOnChartArea: false,
                            drawTicks: false,
                        },
                        radius: 7,

                        stacked: true,
                        ticks: {
                            padding: 0,
                            display: false,
                        },
                        scaleLabel: {
                            color: '#ff0000',
                            display: true
                        },
                    },
                ],
            },


         

            elements: {
                point: {
                    radius: 7,
                    hoverRadius: 7,
                    pointStyle: 'rectRounded',
                },
            },

            responsive: true,
            legend: {
                display: false,
            },

            maintainAspectRatio: false,

            tooltips: {

                callbacks: {
                    title: function (context: any) {
                        return '';
                    },
                    label: (context: any) => {
                        return context.value;
                    },
                },

                xAlign: 'right',
                displayColors: false,
                intersect: false,
            },

        }

      });
}
   
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.chartData !== this.props.chartData) {
         this.updateTracks()
        }
      }
        // Customizable Area End
    }
   




// Customizable Area End
