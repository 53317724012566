// Customizable Area Start
import { Box, Typography, Grid } from "@material-ui/core";
import React from "react";
import "./snapshot.css";
import { Avatar, Row } from "antd";
import Chart from "../Common/ChartScreen.web";
import StatsBox from "./StatsBox.web";
import SnapshotController from "./SnapshotController";
import TopDownloaders from "./TopDownloaders.web";
import { defaultProfile, nxzloader } from "blocks/dashboard/src/assets";
export default class Snapshot extends SnapshotController {
  returnDownloadListData = (downloadersList: any) => {
    return (
      <Box className="middle_lefside_element">
        <Typography className="top-downloaders">
          Top {downloadersList?.length}
        </Typography>
        <Typography className="download_text">Downloaders</Typography>

        {downloadersList?.length <= 9 ? (
          <Avatar.Group maxCount={9} className="top-downloaders-avatar">
            {downloadersList?.map((item: any) => (
              <Avatar
                key={item.id}
                src={item.attributes?.avatar||defaultProfile}
                className={item.attributes.avatar ?
                  "top-downloaders-avatar":
                  "default-profile-snapshot"
                }
              />
            ))}
          </Avatar.Group>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "9px",
            }}
          >
            <Avatar.Group maxCount={9}>
              {downloadersList?.slice(0, 9).map((item: any) => (
                <Avatar
                  key={item.id}
                  src={item.attributes?.avatar||defaultProfile}
                  style={{
                    width: "37px",
                    height: "37px",
                    border: " 1px solid #212121",
                  }}
                />
              ))}
            </Avatar.Group>
            <Typography
              style={{
                color: "#8F92A1",
                fontSize: "12px",
                fontWeight: 700,
                fontFamily: "Inter",
              }}
            >
              {downloadersList?.length > 0
                ? `+${downloadersList?.length - 9}`
                : 0}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  render() {
    const { seeAllTopDownloaders } = this.props;
    const { loading, snapshotData, count, pct, downloadersList } = this.state;
    
    return (
      //Merge Engine DefaultContainer
      seeAllTopDownloaders ? (
        <TopDownloaders checkAllDownloaders={""} seeAllTopDownloaders={""} />
      ) : (
        <>
          {loading ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          ) : (
            <>
              <Box className="snapshot-top-grid">
                <Grid
                  container
                  className="top_categories_container_stats"
                  spacing={2}
                >
                  <Grid item xs={6} sm>
                    <Box className="top_categories_box">
                      <Typography className="tab-content-genre">
                        Beats
                      </Typography>
                    </Box>

                    <Typography className="top_categories_collection">
                      {count.Beat}
                    </Typography>
                    <span className="collection_percentage">
                      <span className="plus_icon">
                        {this.renderPercentageIcon(pct?.Beat)}
                      </span>
                      {this.getPctTxt(pct?.Beat)}
                    </span>
                  </Grid>
                  <Grid item xs={6} sm>
                    <Box className="top_categories_box">
                      <Typography className="tab-content-genre">
                        Hooks
                      </Typography>
                    </Box>

                    <Typography className="top_categories_collection">
                      {count.Hook}
                    </Typography>

                    <span className="collection_percentage">
                      <span className="plus_icon">
                        {this.renderPercentageIcon(pct?.Hook)}
                      </span>
                      {this.getPctTxt(pct?.Hook)}
                    </span>
                  </Grid>
                  <Grid item xs={6} sm>
                    <Box className="top_categories_box">
                      <Typography className="tab-content-genre">
                        Verses
                      </Typography>
                    </Box>

                    <Typography className="top_categories_collection">
                      {count.Verse}
                    </Typography>
                    <span className="collection_percentage">
                      <span className=" plus_icon">
                        {this.renderPercentageIcon(pct?.Verse)}
                      </span>
                      {this.getPctTxt(pct?.Verse)}
                    </span>
                  </Grid>
                  <Grid item xs={6} sm>
                    <Box className="top_categories_box">
                      <Typography className="tab-content-genre">
                        {" "}
                        Songs
                      </Typography>
                    </Box>

                    <Typography className="top_categories_collection">
                      {count.Song}
                    </Typography>
                    <span className="collection_percentage">
                      <span className="plus_icon">
                        {this.renderPercentageIcon(pct?.Song)}
                      </span>
                      {this.getPctTxt(pct?.Song)}
                    </span>
                  </Grid>
                  <Grid item xs={6} sm>
                    <Box className="top_categories_box">
                      <Typography className="tab-content-genre">
                        Videos
                      </Typography>
                    </Box>

                    <Typography className="top_categories_collection">
                      {count.Video}
                    </Typography>
                    <span className="  collection_percentage">
                      <span className="  plus_icon">
                        {this.renderPercentageIcon(pct?.Video)}
                      </span>
                      {this.getPctTxt(pct?.Video)}
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="middle_categories_container"
                  spacing={3}
                >
                  <Grid item xs={12} sm={12} md={6} style={{}}>
                    <Box
                      className="snapshot-two-weeks--grid"
                    >
                      <Typography
                        className="snapshot-two-weeks-data"
                      >
                        2 Week Change
                      </Typography>
                    </Box>
                    <Box className="chart_leftside_container">
                      <Box className="chart_leftside-aligment">
                        <Chart
                          chartData={
                            snapshotData?.last_two_weeks_download_count_percent
                          }
                          isChartLoading={false}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="middle_leftside_container"
                    onClick={this.props.checkAllDownloaders}
                  >
                    {downloadersList?.length > 0 ? (
                      this.returnDownloadListData(downloadersList)
                    ) : (
                      <Box className="middle_lefside_element">
                        <Typography
                          className="download_text"
                          style={{ textAlign: "center" }}
                        >
                          No Downloaders Available
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Grid container item xs={12} className="ctrl-snapshot-grid">
                <Grid item xs={6} className="ctrl-font">
                  CTRL Stats
                </Grid>
                <Grid item xs={6} className="stats-lastWeek">
                  Last 2 Weeks
                </Grid>
              </Grid>
              <StatsBox data={snapshotData} />
            </>
          )}
        </>

        //Merge Engine End DefaultContainer
      )
    );
  }
}

// Customizable Area End
