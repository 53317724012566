// Customizable Area Start
import React, { useEffect, useState } from "react";
import "./AddTrack.web.css";


const AddTrack = () => {
  const [TrackDetails, setTrackDetails] = useState<any>(3);
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 100)

  }, [TrackDetails])

console.log(loading,setTrackDetails,"loading")

  return (
   
    <p>solve SQ bugs</p>
  );
  // }
};
export default AddTrack;
// Customizable Area End