// Customizable Area Start
import React, { useState } from 'react'
import './Default.css'
import ListofNewChat from './ListofNewChat'

function Default({handleAddNewUser}:any) {

  const [showMsgModal , isShowMsgModal] = useState<boolean>(false)

  const openMsgModal = () =>{
      isShowMsgModal(true)  
 }

 const closeMsgModal = (newUser:any) =>{
   isShowMsgModal(false)
   handleAddNewUser(newUser)
 }

 const closeModal = () =>{
   isShowMsgModal(false)
 }


  return (
    <>
    <div className = "defaultDMcontainer">
        <div className="defaultHeading">
            <p style = {{color : "white"}}>Select a Message</p>
        </div>
        <div className="deafultcaption">
        <p style = {{color : "#8f92a1"}}> Choose from your existing message <br/> or start a new one.</p>
        </div>
        <div>
           <p className='defaultButton' onClick={()=>{openMsgModal()}}>New Message</p> 
        </div>
    </div>

    <ListofNewChat showMsgModal = {showMsgModal}  closeMsgModal = {closeMsgModal}  closeModalDialog={closeModal} />

    </>
  )
}

export default Default;
// Customizable Area End