import React from "react";
import { Line } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import CtrlplaylistController from "./CtrlplaylistController.web"

class PlaylistLineChart extends CtrlplaylistController {
  

  render() {
    return (
      <Box style={{width:"100px"}}>
        <div className="playlist_canvas_width_ctrl" style={{width:"100%"}}>
              <Line
                data={{
                  labels: Object.keys(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']),
                  datasets: [
                    {
                      label: '# of votes',
                      data: Object.values(this.props.data||{}),
                      borderColor: ["blue"],
                      border: "1px solid blue",
                      borderStyle: "solid",
                      borderWidth: 1,
                      pointRadius: 0,
                      pointHoverRadius: 0,
                      showTooltips: false
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          display: false,
                        },
                        grid: {
                          drawBorder: false,
                          display: false,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        ticks: {
                          display: false,
                        },
                        grid: {
                          drawBorder: false,
                          display: false,
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    callbacks: {
                      label: function (tooltipItem:any) {
                        return tooltipItem.yLabel;
                      },
                    },
                    enabled: true,
                  },
                }}
              />
            </div>
      </Box>
     
    );
  }
}

export default PlaylistLineChart;
