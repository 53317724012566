// Customizable Area Start
import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
export interface Props {
    currentplan?: any;
    cancelledSubscription?:any;
    next_invoice_no?:any;
    currentPaymentData?:any;
    status_of_plan?:any;
    isCurrentPlan?:boolean;
  cancelSubscription?:()=>void;
  closeCancelledModal?:()=>void;
  handleUpdateCard?:()=>void;
 }
interface S {
}
interface SS {
  id: any;
}

export default class UserCardAndCurrentPlanController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 
  
  // Customizable Area End



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    // Customizable Area End
  }
}
// Customizable Area End