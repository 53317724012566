// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MCreativePlanController from "./MCreativePlanController";

export default class MCreativePlan extends MCreativePlanController {
  render() {
    const { creativePlanAlt, price, name } = this.props;

    return (
      <>
        <Grid item xs={12} container className="m_user_plan_div">
          <Grid
            item
            xs={12}
            container
            className="m_creativePlan"
            style={{
              border: creativePlanAlt
                ? "10px solid #0f6fd1"
                : "10px solid #141414",
            }}
          >
            <Grid className="m_plan_name_div">
              <Grid className="m_plan_name">
                {name?.endsWith("+") ? (
                  <span className="currentplan-align">
                    {name?.slice(0, -1)}
                    <AddIcon className="redColor" fontSize="medium" />
                  </span>
                ) : (
                  <span>{name}</span>
                )}
              </Grid>
            </Grid>

            <span className="m_creativePlanPriceFont">${price}</span>
            <button
              className={
                creativePlanAlt ? "m_creative_your_btn" : "m_creative_get_btn"
              }
              onClick={() => !creativePlanAlt && this.redirectCreative()}
            data-test-id="creativeplanClick"
            >
              {creativePlanAlt ? "Your Plan" : "Get Plan"}
            </button>
          </Grid>
        </Grid>
      </>
    );
  }
}
// Customizable Area End
