// Customizable Area Start
import React from "react";
import { Box, Modal, Hidden } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import "./PlanUpdateCancel.css";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import { nxzsoundLogo } from "../assets";
import PlanCanceledController from "./PlanCanceledController";
export default class PlanCanceled extends PlanCanceledController {
  render() {
    const { cancelledSubscription, closeCancelledModal } = this.props;

    const body = (
      <Box
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box className="paperBack">
            <Box className="update_plan">
              <img src={nxzsoundLogo} alt="nxzsoundLogo" />
              <h1>Plan Canceled</h1>
              <Box className="plan_description">
                <span className="fontWeight">
                  Your Account has been switched to the limited free plan
                </span>
              </Box>
            </Box>
            <Box className="update_plan updatePlanBtn">
              {/* <img
              src={cancelPlanBlue}
              alt='cenceledPlan'
              className='canceledPlan'
            /> */}

              {/* <img
              src={keepMyPlan}
              alt='KeepMyPlan'
              onClick={() =>
                location.pathname === '/planpayments'
                  ? window.location.reload()
                  : history.push('/planpayments')
              }
              style={{ cursor: 'pointer' }}
            /> */}
              <button
                className="my_plan_Btn"
                onClick={() => this.handlePlanBtn()}
                style={{ backgroundColor: "#3959f9", marginBottom: "10px" }}
              >
                My Plan
              </button>
              <button
                className="my_plan_Btn"
                onClick={() => this.redirectHandler()}
                style={{
                  backgroundColor: "#212121",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                Profile
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
    return (
      <>
        <Modal
          open={cancelledSubscription}
          onClose={closeCancelledModal}
          className="PlanDialog"
        >
          {body}
        </Modal>
      </>
    );
  }
}
// Customizable Area End
