// Customizable Area Start
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';
import DirectMessage from './DirectMessage.web';
import { SlideTransition,dialogStyles } from '../../dashboard/src/Inbox/Common/Common.web';




// Customizable Area Start
interface Props {
   isOpen: boolean;
   id:any;
   otherUserId:any;
   // media: any;
   closeMediaDialog: () => void;
   navigation:any;
   newUserAdded:()=>void;
   selectedTab:any;
   selectedTrackIndexId:any;
}
// Customizable Area End


// Customizable Area Start
export const DMChatDialog = (props: Props) => {
   const classes = dialogStyles();
   return (
       <Dialog fullScreen open={props.isOpen} onClose={props.closeMediaDialog} TransitionComponent={SlideTransition} className='DMChatDialog'>


                <AppBar className={classes.appBar}>
               <Toolbar>
                   <IconButton edge="start" color="inherit" onClick={props.closeMediaDialog} aria-label="close">
                       <CloseIcon />
                   </IconButton>
                   <Typography variant="h6" className={classes.title}>
                       DM
                   </Typography>
               </Toolbar>
           </AppBar>
           <Box style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'90vh', flex: 1, padding:'0px 8px'}}>
               <Box className='DMChatBox'>
               <DirectMessage
               navigation={props.navigation}
               id={props.id}
               otherUserID={props.otherUserId}
               newUserAdded={props.newUserAdded}
               selectedTab={props.selectedTab}
               selectedTrackIndexId={props.selectedTrackIndexId}
                />
               </Box >
           </Box>


       </Dialog >
   );
}
// Customizable Area End




export default DMChatDialog;
// Customizable Area End