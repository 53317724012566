Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
const urlConfig = require("../../../../framework/src/config")
  
exports.getAPIMethod = "GET";
  exports.httpPostType = "POST";
  exports.httpDeleteType = "DELETE";
  exports.httpPutType = "PUT";
  
  exports.endCreativesList = "/account_block/show_all_creatives_data";
  exports.endBannerList = "/bx_block_audiomusic/expan_track";
  exports.MethodCreativesList = "GET";
  
  exports.endPointGetBannerLists = "/bx_block_audiomusic/banners";
  
exports.baseUrl = urlConfig.baseURL;
 
  // Customizable Area End