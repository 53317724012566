// Customizable Area Start
import React, { useState } from "react";
import QRcode from 'qrcode.react';
import { Tooltip } from '@material-ui/core';
import {
    messageShape,
    linkChain,
    qrCode,
    send_button,
    reshareBlue,
    defaultProfile,
} from "blocks/dashboard/src/assets";
import {
    Grid,
    Box,
    Dialog,
} from "@material-ui/core";
import "blocks/user-profile-basic/src/Common/CardItem.web.css";
import 'blocks/user-profile-basic/src/Creatives/index.web.css';
import RepostPopup from "./RepostPopup.web";
import { openNotification } from "../../../../components/src/Notification.web";
import SendSmsDialog from "blocks/dashboard/src/CustomComponent/SendSms/SendSmsDialog.web";

const ScanQr = ({ openPopup, handleOpenPopup, shareItem, TrackId, playData, handleChartTrackRepost }: any) => {
    const [copied, setCopied] = useState(false);
    const userId = localStorage.getItem('user_id')
    const [openRepostPopup, setOpenRepostPopup] = useState<boolean>(false)
    const [openPopUp, setOpenPopUp] = useState<boolean>(false)
    const [textFieldValue, setTextFieldValue] = useState<any>('')
    const [openSmsPopUp, setOpenSmsPopUp] = useState<boolean>(false)
    const [smsShareLink, setSmsShareLink] = useState<any>('')



    let scanTo: any
    const handleScanLink = () => {
        if (shareItem == 'Profile') {
            if (window.location.href.indexOf("comments") > -1) {
                const currentUserId = Number(localStorage.getItem("loggedIn_userId"))

                scanTo = window.location.origin.concat(`/creatives/beats?creative_id=${currentUserId}`)
            } else {

                scanTo = window.location.origin.concat(`/creatives/beats?creative_id=${userId}`)
            }
        } else if (shareItem == 'Track') {
            scanTo = window.location.origin.concat(`/home/expand/song/songId=${TrackId}`)
        } else if (shareItem == 'Playlist') {
            if (TrackId === 0) {
                handleOpenPopup()
                openNotification("Playlist should be available to share", "Error")
            } else {
                scanTo = window.location.origin.concat(`/home/playlist/public-view/${TrackId}`)
            }
        } else if (shareItem == 'SingleTrack') {
            scanTo = window.location.origin.concat(`/home/playlist/public-view/${TrackId}`)
        } else if (shareItem === "Public Playlist") {
            scanTo = window.location.origin.concat(`/home/playlist/public-view/${TrackId}`)

        }

        else {
            scanTo = window.location.href

        }
        return scanTo
    }

    const onCopy = () => {
        const userId = localStorage.getItem('user_id')
        const el = document.createElement('input');
        el.value = handleScanLink()
        document.body.appendChild(el);
        el.select();
        el.focus();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(true);

    }
    const respostSubmit = () => {
        handleChartTrackRepost(textFieldValue)
        handleRepostClose()
    }
    const handleRepostClose = () => {
        setOpenRepostPopup(false)
    }
    const handleCaptionChange = (e: any) => {
        setTextFieldValue(e.target.value)
    }
    // const onScan = () => {
    //     const userId = localStorage.getItem('user_id')
    //     if (shareItem == "Profile") {
    //         scanTo = window.location.origin.concat(`/creatives/beats?creative_id=${userId}`)
    //     } 
    //     return scanTo = window.location.href
    // }


    const sendLinkViaQR = () => {
        return window.location.href
    }

    const handleSms = () => {
        setOpenSmsPopUp(true)
        if (shareItem == 'Profile') {
            setSmsShareLink(window.location.origin.concat(`/creatives/beats?creative_id=${userId}`))
        } else if (shareItem == 'Track') {
            setSmsShareLink(window.location.origin.concat(`/home/expand/song/songId=${TrackId}`))
        } else if (shareItem == 'Playlist') {
            if (TrackId === 0) {
                handleOpenPopup()
                openNotification("Playlist should be available to share", "Error")
            } else {
                setSmsShareLink(window.location.origin.concat(`/home/playlist/public-view/${TrackId}`))
            }
        } else if (shareItem == 'SingleTrack') {
            setSmsShareLink(window.location.origin.concat(`/home/playlist/public-view/${TrackId}`))
        } else if (shareItem === "Public Playlist") {
            setSmsShareLink(window.location.origin.concat(`/home/playlist/public-view/${TrackId}`))

        }

        else {
            setSmsShareLink(window.location.href)

        }
    }

    const handleSmsClose = () => {
        setOpenSmsPopUp(false)
    }

    const list = ["hi", "hello", "welcome"]

    return (

        <Dialog
            aria-labelledby="simple-dialog-title"
            open={openPopup}
            onClose={handleOpenPopup}
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(0, 0, 0, 0.15)",
                },
            }}
            PaperProps={{ className: "dialog-Paper" }}
        >
            <Grid container className="send-div">
                <Grid container item xs={12} sm={4} className="borderRight">
                    <Grid
                        item
                        xs={12}
                        className="ScanQR"
                    >
                        <Box className="share-qr-text">Scan QR</Box>
                    </Grid>
                    <Grid item xs={12} className="img-grig">
                        <QRcode
                            className="qrCode-img"
                            id="myqr"
                            //value={shareItem == 'Profile' ? window.location.origin.concat(`/creatives/beats?creative_id=${userId}`) : shareItem == 'Track' ? window.location.origin.concat(`/home/expand/song/songId=${TrackId}`) : sendLinkViaQR()}
                            value={handleScanLink()}
                            size={135}
                            renderAs="canvas"
                            style={{ border: "4px solid white" }}
                        // includeMargin={true}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={8} className="mt15">
                    <Grid
                        item
                        xs={12}
                        className="activity-dialog-profile"
                    >
                        <Box className="share-qr-text">
                            Share {shareItem}
                            {/* Share Profile */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="share-parent-grid">
                        <Grid item sm={3} xs={12} className="share-profile-grid">
                            <Grid item xs={12} className="activity-dialog-link">
                                <Tooltip title={!copied ? "Copy Link" : "Copied!"} enterTouchDelay={0}>
                                    <img src={linkChain} width="48px" height="38px" onClick={onCopy} />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title={!copied ? "Copy Link" : "Copied!"} enterTouchDelay={0}>
                                    <Box className="profile-text" onClick={onCopy}>{!copied ? "Copy Link" : "Copied!"}</Box>
                                </Tooltip>

                            </Grid>
                        </Grid>
                        <Grid item sm={3} xs={12} className="share-profile-grid">
                            <Grid
                                item
                                xs={12}
                                className="activity-dialog-reshare"
                            >
                                <img src={reshareBlue} width="39.8px" height="40px" />
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="profile-text"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setOpenRepostPopup(!openRepostPopup)
                                        setOpenPopUp(true)
                                    }
                                    }
                                >Repost</Box>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} xs={12} className="share-profile-grid">
                            <Grid
                                item
                                xs={12}
                                className="activity-dialog-reshare"
                            >
                                <img src={send_button} width="42px" height="42px" />
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="profile-text">Send</Box>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} xs={12} className="share-profile-grid" onClick={handleSms}>
                            <Grid
                                item
                                xs={12}
                                className="activity-msg-shape"
                            >
                                <img src={messageShape} width="33px" height="33px" />
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="profile-text">SMS</Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openRepostPopup && <RepostPopup open={openPopUp}
                onClose={handleRepostClose}
                profileImage={playData?.attributes?.art_work || playData?.attributes?.cover_image || defaultProfile}
                artistName={playData?.attributes?.artist_name}
                songName={playData?.attributes?.title}
                repost_count={respostSubmit}
                _handleTextFieldChange={handleCaptionChange}
            />}

            {
                openSmsPopUp &&
                <SendSmsDialog id="Sms" open={openSmsPopUp} handleClose={handleSmsClose} smsLink={smsShareLink} list={list} />
            }

        </Dialog>

    )
}
export default ScanQr;
// Customizable Area End