// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");
import { Message } from "../../../../../framework/src/Message";
import { setStorageData } from "../../../../../framework/src/Utilities";
import { HISTORY } from "components/src/common";

export interface Props {
  navigation: any;
  id: string;
  topPlayMusic:any;
  getStatusDetails:any;
}

interface S {
  active: string;
  selectedIndex: any;
  showdetails: boolean;
  top40songList: any;
  songList: any;
  masterTop40songList: any;
  mastersongList: any;
  loading: boolean;
  searchText: string;
  songDetailsId: any;
  openSongFilter:boolean
}

interface SS {
  id: any;
}

export default class SongsController extends BlockComponent<Props, S, SS> {
  subscriptionPlan = localStorage.getItem("subscription_plans");

  apiSongsList: any;
  apiSongDraftTracksList: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      active: "0",
      selectedIndex: "",
      showdetails: false,
      top40songList: [],
      songList: [],
      masterTop40songList: [],
      mastersongList: [],
      loading: false,
      searchText: "",
      songDetailsId: 0,
      openSongFilter:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getSongs();
  }


  handleCloseFilter=()=>{
    this.setState({openSongFilter:false})
  }

  handleOpenFilter=()=>{
    this.setState({openSongFilter:true})
  }

  searchFilterFunction = (e: any) => {
    let text = e.target.value;
    if (text) {
      const top40data = this.state.masterTop40songList.filter(function(
        item: any
      ) {
        const itemData = item.attributes.title
          ? item.attributes.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ top40songList: top40data });

      const splitData = this.state.mastersongList.filter(function(item: any) {
        const itemData = item.attributes.title
          ? item.attributes.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({
        songList: splitData,
        searchText: text,
      });
    } else {
      this.setState({
        top40songList: this.state.masterTop40songList,
        songList: this.state.mastersongList,
        searchText: text,
      });
    }
  };
  handleShowDetails = () => {
    this.setState({ showdetails: false })
}
  getSongs = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSongsList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getSongsData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  selectedsong = (index: any, songId: any, is_drafted: any) => {
    if(is_drafted){
      this.getSongDraftTracks(songId)
    }else{
    this.setState({
      selectedIndex: index,
      showdetails: true,
      songDetailsId: songId,
    });
  }
  };

  getSongDraftTracks = (trackId: any) => {
    
    const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiSongDraftTracksList = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `/bx_block_audiomusic/show_track?id=${trackId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPIMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
 

  handleSongsList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.top_forty_tracks?.data) {
        this.setState({
          top40songList: responseJson?.top_forty_tracks.data,
          masterTop40songList: responseJson?.top_forty_tracks.data,
          loading: false,
        });
      } else {
        this.setState({
          top40songList: [],
          masterTop40songList: [],
          loading: false,
        });
      }
      if (responseJson?.all_tracks?.data) {
        this.setState({
          songList: responseJson.all_tracks.data,
          mastersongList: responseJson.all_tracks.data,
          loading: false,
        });
      } else {
        this.setState({
          songList: [],
          mastersongList: [],
          loading: false,
        });
      }
    } else {
      this.setState({
        top40songList: [],
        songList: [],
        mastersongList: [],
        masterTop40songList: [],
        loading: false,
      });
    }
  };


  handleSongDraftTrackList = async( resposne: any) =>{
    if(resposne.data[0].id){
      await setStorageData('selected_draft_song', resposne.data[0])
      HISTORY.push('/uploadTrack/audio')
   }  
}
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == null) {
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (apiRequestCallId == this.apiSongsList) {
      this.handleSongsList(responseJson);
    }
     else if (apiRequestCallId == this.apiSongDraftTracksList) {
      this.handleSongDraftTrackList(responseJson);
    }  

    // Customizable Area End
  }
}
// Customizable Area End
