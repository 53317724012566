import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { openNotification } from "components/src/Notification.web";
import { HISTORY } from "components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
  userData: any;
  threebtnToogle: any;
  changetabs?:any;
  onBack:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  trackList: any;
  loading: boolean;
  openMessageSendPopup: boolean;
  CopiedForLink:any;
  inboxOpen:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DownloaderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiTrackList: string = "";
  apiFollowUser: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      trackList: [],
      loading: false,
      openMessageSendPopup: false,
      CopiedForLink:"",
      inboxOpen:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTrack(this.props.threebtnToogle);
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.threebtnToogle !== prevProps.threebtnToogle || this.props.userData.id != prevProps.userData.id) {
      this.getTrack(this.props.threebtnToogle);
    }
  }

  getTrack = (upload_type: any) => {
    let track = upload_type.toLowerCase();
    if (track.endsWith("s")) {
      track = track.slice(0, -1);
    }
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTrackList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `${configJSON.userDownloadListing}?user_id=${this.props.userData.id}&upload_type=${track}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  followUser = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFollowUser = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `${configJSON.userFollow}?user_id=${this.props.userData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  openDMScreen=()=>{
    console.log(this.props.userData,">>>userData")
    localStorage.setItem("trackUserListDataDM",JSON.stringify(this.props.userData))
    HISTORY.push({
      pathname: "/comments"
  })

  }

  onScanQR = () =>{
    let scanTo: any;
      scanTo = window.location.origin.concat(`/creatives/beats?creative_id=${this.props.userData.id}`)
    return scanTo
  }

  handleOpenMessageSendPopup=()=>{
    this.setState({ openMessageSendPopup: false })
  }

  
  linkopenPopup=()=>{
    this.setState({ CopiedForLink: this.onScanQR() })
    this.setState({ openMessageSendPopup: true })
  }

  handleTrackList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        trackList: responseJson.data,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleFollow = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(responseJson.message, "Success");
    } else {
      console.log("error in follow");
    }
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == null) {
      this.parseApiCatchErrorResponse(errorReponse);
      return;
    }
    switch (apiRequestCallId) {
      case this.apiTrackList:
        this.handleTrackList(responseJson);
        break;
      case this.apiFollowUser:
        this.handleFollow(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
