// Customizable Area Start
import { Content } from 'antd/lib/layout/layout'
import { Layout } from 'antd';
import {
    Hidden,
} from "@material-ui/core";
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import StreamUnlockLevels from './StreamUnlockLevels.web';
import StreamUnlockController from './StreamUnlockController.web';
export default class StreamUnlock extends StreamUnlockController {
    render() {
      return (
        <>
          <Layout  className='streamRepostWrapper'>
            {/* .......................SideBar...................................... */}
            <Hidden smDown>
                <AsideLeft />
            </Hidden>
            <Content
                style={{
                    margin: "0px",
                    backgroundColor: "black",
                    display: "flex", 
                    flexDirection: "column",
                }}
            >
                <HomeHeader location="RepostHistory" />
            <StreamUnlockLevels navigation={undefined} id={''}/>
            </Content>
        </Layout>
        </>
       
      )
    }
  }

// Customizable Area End