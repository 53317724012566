// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";

interface S {
  test: any;
}
interface SS {
}
interface Props {
  id: string;
  open: any;
  handleClose: any;
  list: any;
  smsLink: any;
}

export default class SendSmsDialogController extends BlockComponent<
  Props,
  S,
  SS
> {


  constructor(props: Props) {
    super(props);
    this.state = {
      test: 'hello every one'
    }
  }

  onFinish = (values: any) =>{
    return this.props.smsLink
  }
}
// Customizable Area End