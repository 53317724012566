// Customizable Area Start
// @ts-nocheck
import React from "react";
import "../../../../user-profile-basic/src/Common/TopSongs.web.css"
import './index.css'
import { Typography, Grid, Button, Box, Hidden, ButtonGroup } from "@material-ui/core";
import { Col, Row } from "antd";
//@ts-ignore
import WebPlaylistController from "../../WebPlaylistController";
import Listing from "./Listing.web";
import HeadBlock from "./HeadBlock.web";
import MessageLinkPopup from 'components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web';
import LinkCopiedPopup from "../../../../dashboard/src/CustomComponent/LinkCopiedPopup/LinkCopiedPopup.web";
import SharePlaylistPopup from "../../../../dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";
import { AudioContextApi } from "../../../../dashboard/src/AudioContext/AudioContext.web";
import DeletePlaylistPopup from "../../../../dashboard/src/CustomComponent/DeletePlaylistPopup/DeletePlaylistPopUp.web";
import { videoIcon, linkChainW, sendplaylist, deleteplaylist, filterW, musicIcon } from "../../../../dashboard/src/assets";


export default class TopPlaylists extends WebPlaylistController {



  getFilteredVideoPlaylists = (filterCondition, status) => {
    return filterCondition?.filter((filterId) => filterId.attributes.status === status);
  }

  getFilteredAudioPlaylists = (filterCondition, status) => {
    return filterCondition?.filter((filterId) => filterId.attributes.status === status);
  }
  dataListing = (play: any, index: any) => {
    const { selectedDiv, playlistLoader, musicButton } = this.state
    return (
      <Col span={24} className={`musicRow ${index === selectedDiv ? "selected" : undefined}`} style={{ padding: "5px 20px" }}
        onClick={() => {
          this.setState({ selectedDiv: index, selectedPlaylistId: play })
        }}
      >
        <Listing songsList={play}
          index={index}
          // passing cover image and name for changing its status
          handleShowPlaylist={() => this.handleShowPlaylist(play.id, play.attributes.name, play.attributes.cover_image)}
          handleDeletePlayListClick={() => this.handleDeletePlayList(play.id, play.attributes.cover_image)}
          musicButton={musicButton}
          playlistLoader={playlistLoader}
        />
      </Col>

    )

  }



  filterByType = (list, type) =>

    list?.filter((filterId) =>

      filterId.attributes.play_list_type.toLowerCase() === type
    );

  filterPrivate = (list) =>
    list?.filter((filterId) => filterId.attributes.status === "private_playlist");


  renderDataList = (list, dataListing) =>
    list.map((play, index) => dataListing(play, index));

  renderNoFilesMessage = (message, noFilesMethod) => noFilesMethod(message);
  renderList = (list) => this.renderDataList(list, this.dataListing);
  renderMessage = (message) => this.noFilesMethod(message);

  renderPlaylistItems = (items,message) => {
    if (items?.length > 0) {
      return this.renderList(items);
    } else {
      return this.renderMessage(message);
    }
  }

  playlistFollowingToogleHandle = (followingPlaylistList, musicButton, audioFollowingFilteredList, videoFollowingFilteredList) => {
    let itemsToShow = null;
    
    if (followingPlaylistList.length > 0) {
      if (!musicButton) {
        itemsToShow = this.renderPlaylistItems(audioFollowingFilteredList,"No Audio Following Available");
      } else {
        itemsToShow = this.renderPlaylistItems(videoFollowingFilteredList,"No Video Following Available");
      }
    } else {
      itemsToShow = this.renderMessage("No Following List Available");
    }
    
    return itemsToShow;
  }
  
  handleMusicButton = (musicButton, privatePublicToggle, publicAudioPlaylist, privateVideoPlaylist, publicVideoPlaylist, privateAudioPlaylist) => {
    let itemsToShow = null;
    if (musicButton) {
      if (!privatePublicToggle) {
        itemsToShow = this.renderPlaylistItems(privateVideoPlaylist,"No private video playlist available");
      } else {
        itemsToShow = this.renderPlaylistItems(publicVideoPlaylist,"No public video playlist available");
      }
    } else {
      if (!privatePublicToggle) {
        itemsToShow = this.renderPlaylistItems(privateAudioPlaylist,"No private audio playlist available");
      } else {
        itemsToShow = this.renderPlaylistItems(publicAudioPlaylist,"No public audio playlist available");
      }
    }
    
    return itemsToShow;
  }
  
  returnConditional = () => {
    const {
      followingPlaylistList,
      playList,
      getVideoPlaylist,
      musicButton,
      playlistFollowingToogle,
      privatePublicToggle,
    } = this.state;
  
    const audioFollowingFilteredList = this.filterByType(
      followingPlaylistList,
      "audio"
    );
    const videoFollowingFilteredList = this.filterByType(
      followingPlaylistList,
      "video"
    );
  
    const publicVideoPlaylist = getVideoPlaylist?.filter(
      (filterId) => filterId.attributes.status !== "private_playlist"
    );
    const privateVideoPlaylist = this.filterPrivate(getVideoPlaylist);
  
    const publicAudioPlaylist = playList?.filter(
      (filterId) => filterId.attributes.status !== "private_playlist"
    );
    const privateAudioPlaylist = this.filterPrivate(playList);
  
    let itemsToShow = null;
  
    if (playlistFollowingToogle) {
      itemsToShow = this.playlistFollowingToogleHandle(followingPlaylistList, musicButton, audioFollowingFilteredList, videoFollowingFilteredList);
    } else {
      itemsToShow = this.handleMusicButton(musicButton, privatePublicToggle, publicAudioPlaylist, privateVideoPlaylist, publicVideoPlaylist, privateAudioPlaylist);
    }
  
    return itemsToShow;
  }

 
  

  noFilesMethod = (text: any) => {
    return <div className="song-div mostly-customized-scrollbar"><Row align="middle" justify="center" style={{ height: "180px" }} className="no_data_available">{text}</Row></div>
  }

  render() {
    const { musicButton, openPopup, coverImage, total_audio_count, total_video_count, playlistFollowingToogle, privatePublicToggle, isFollowingPlaylist, deletePlaylistId, selectedRepostSong, openDeletePopup, openMessageLink, openLinkPopup }: any = this.state;
    const { isPlaylist } = this.context;
    // Call this.returnConditional() once and store the result in a variable
    const conditionalResult = this.returnConditional();
    const numProperties = Array.isArray(conditionalResult) ? conditionalResult.length : 0;
   
    return (
      <div style={{ height: "90%" }}>
        <HeadBlock
          total_audio_count={total_audio_count}
          total_video_count={total_video_count}
          coverImage={coverImage}
        />
        <div className="track_listing" style={{ height: "55%" }}>
          <>
            <Box className="playlist-outer-box">
              <Grid style={{ alignItems: "center", display: "flex" }} container>
                <Grid container item xs={12} sm={7} md={7} lg={7} style={{ display: "flex" }} spacing={1}>
                  <Grid item xs={8} sm={8} md={7} lg={6} >
                    <Row className=" width_100">
                      <Row className=" width_100" style={{ justifyContent: "center" }}>
                        <ButtonGroup
                          color="primary"
                          // className="btn-group-head"
                          className="outer-div-toggle "
                          style={{ width: "100%" }}
                          aria-label="outlined primary button group"
                        >
                          <Button className="audio-button-playlist"
                            value="audio"
                            variant="contained"
                            disabled={playlistFollowingToogle === false}
                            onClick={() => {
                              this.setState({
                                playlistFollowingToogle: false
                              }, () => {
                                this.getPlayLists();
                              });
                            }}
                          >
                            <span className="audio-text">
                              My Playlists
                            </span>
                          </Button>
                          <Button variant="contained"
                            className="video-button-playlist"
                            onClick={() => {
                              this.setState({
                                playlistFollowingToogle: true
                              }, () => {
                                this.getFollowingPlaylist();
                              });
                            }}
                            disabled={playlistFollowingToogle === true}
                          >
                            <span className="audio-text">
                              Following
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Row>
                    </Row>
                  </Grid>
                  <Grid item xs={4} sm={4} md={5} lg={6} style={{ display: "flex", justifyContent: "end" }}>
                    <Row className=" width_100" style={{ justifyContent: "center" }}>
                      <ButtonGroup
                        color="primary"
                        className="audio-video-group-head outer-div-toggle"
                        aria-label="outlined primary button group"
                      >
                        <Button className="audio-button-playlist"
                          value="audio"
                          variant="contained"
                          disabled={musicButton === false}
                          onClick={() => {
                            this.setState({
                              musicButton: false
                            }, () => {
                              if (isFollowingPlaylist) {
                                this.getFollowingPlaylist()
                              } else {
                                this.getPlayLists();
                              }
                            });
                          }}>
                          <Hidden smDown>
                            <span className="audio-text">
                              Audio
                            </span>
                          </Hidden>
                          <Hidden mdUp>
                            <img src={musicIcon} className="music-icons" />
                          </Hidden>
                        </Button>
                        <Button variant="contained"
                          className="video-button-playlist"
                          onClick={() => {
                            this.setState({
                              musicButton: true
                            }, () => {
                              if (isFollowingPlaylist) {
                                this.getFollowingPlaylist()
                              } else {
                                this.getVideoPlayLists();
                              }
                            });
                          }}
                          disabled={musicButton === true}
                        >
                          <Hidden smDown>

                            <span className="audio-text">
                              Video
                            </span>
                          </Hidden>
                          <Hidden mdUp>
                            <img src={videoIcon} className="music-icons" />
                          </Hidden>
                        </Button>
                      </ButtonGroup>
                    </Row>
                  </Grid>
                </Grid>
                <Grid container item xs={12}
                  // md={4} 
                  sm={5}
                  md={5}
                  lg={5}
                  spacing={1}
                  className="playlist-private-public-toggle"
                >
                  <Grid item xs={6} lg={6} className="dflex-aligncenter">
                    <Row className=" width_100" style={{ justifyContent: "center" }}>
                      {isFollowingPlaylist ?
                        <div className="playlist-btn-groups outer-div-toggle" style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                          <button className="btn-img" style={{
                            fontSize: "16px",
                            fontWeight: 800,
                            textTransform: "capitalize"
                          }}
                            onClick={this.onShare}
                          >Share</button>

                        </div>
                        :

                        <ButtonGroup
                          color="primary"
                          className="audio-video-group-head outer-div-toggle"
                          aria-label="outlined primary button group"
                        >
                          <Button className="private-button-playlist"
                            value="audio"
                            variant="contained"
                            disabled={privatePublicToggle === false}
                            onClick={() => {
                              this.setState({
                                privatePublicToggle: false
                              });
                            }}>
                            <span className="audio-text">
                              Private
                            </span>
                          </Button>
                          <Button variant="contained"
                            className="public-button-playlist"
                            onClick={() => {
                              this.setState({
                                privatePublicToggle: true
                              });
                            }}
                            disabled={privatePublicToggle === true}
                          >
                            <span className="audio-text">
                              Public
                            </span>
                          </Button>
                        </ButtonGroup>

                      }
                    </Row>
                  </Grid>
                  <Grid item xs={6} lg={6} className="dflex-aligncenter">
                    {!isFollowingPlaylist &&
                      <Row style={{ justifyContent: "center", margin: "auto" }}>
                        <div className="playlist-btn-group outer-div-toggle" style={{ display: "flex" }}>
                          <button onClick={() => this.onCopy()}> <img src={linkChainW} className="btn-img" /></button>
                          <button  style={{ display: "flex", alignItems: "center" }}>
                            <input type="image" src={sendplaylist} alt="" disabled={deletePlaylistId === ""} className="btn-img"
                              style={{ cursor: deletePlaylistId === "" ? "not-allowed" : "pointer" }}
                              onClick={this.linkopenPopup}
                            />
                          </button>
                          <button  style={{ display: "flex", alignItems: "center" }}>
                            <input type="image" src={deleteplaylist} alt="" disabled={deletePlaylistId === ""} className="btn-img"
                              style={{ cursor: deletePlaylistId === "" ? "not-allowed" : "pointer" }}
                              onClick={() => { this.deletePlaylistConfirm() }}
                            />
                          </button>
                        </div>
                      </Row>
                    }


                    <LinkCopiedPopup id="Playlist" open={openLinkPopup} handleClose={() => { this.setState({ openLinkPopup: false }) }} />

                    <MessageLinkPopup id="Playlist" showMsgModal={openMessageLink} closeMsgModal={() => { this.setState({ openMessageLink: false }) }}
                      closeModalDialog={() => { debugger
                        this.setState({ openMessageLink: false }) }} copiedLinked={this.state.CopiedForLink} currentUserId={1}
                    />

                    <DeletePlaylistPopup id="Playlist" open={openDeletePopup} handleClose={() => { this.setState({ openDeletePopup: false }) }} playlist={this.state.selectedPlaylistId} />
                    <SharePlaylistPopup
                      id="Playlist"
                      open={openPopup}
                      handleClose={() => {
                        this.setState({ openPopup: false })
                      }}
                      playlist={this.state.selectedPlaylistId}
                      selectedSong={selectedRepostSong}
                      isPlaylistRepost={true}
                    />

                  </Grid>

                </Grid>
              </Grid>
            </Box>
            <div style={{
              height: isPlaylist ? "75vh" : "97%",
              overflowY: "scroll"
            }} className="playlist-scroller" >

              <div style={{ height: "100%", padding: "0%" }} >
                <Box
                  style={{
                    // marginBottom: "13px",
                    // padding: "5px 20px 5px 20px",
                    padding: "0px 20px 0px 20px",
                    width: "100%",
                    maxHeight: "15%",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <Grid style={{ alignItems: "center", display: "flex" }} container>
                    <Grid item xs={12} sm={7} md={7} lg={8} style={{ display: "flex" }}>
                      <Grid item xs={3} lg={5} >
                        <Row className=" width_100">
                          <Typography className="top-head">
                            {`Top ${numProperties}`}
                          </Typography>
                        </Row>
                      </Grid>
                      <Grid item xs={4} lg={4} >

                      </Grid>
                      <Grid item xs={5} lg={4} style={{ display: "flex", justifyContent: "end" }}>

                      </Grid>
                    </Grid>
                    <Grid item xs={4}
                      // md={4} 
                      sm={5}
                      md={5}
                      lg={4}
                      style={{ display: "flex" }}
                    // className="second-head-percentage"
                    >
                      <Hidden xsDown>
                        <Grid item xs={3} style={{marginLeft: '10px'}}>
                          <p className=" text_white1 top_size_16 hiddnDaysScore " style={{ marginTop: "4px", }}>24hrs%</p>
                        </Grid>
                        <Grid item xs={4}>

                          <p className="text_white1 top_size_16" style={{ marginTop: "4px", }}>7 Day %</p>
                        </Grid>
                        <Grid item xs={4} >
                          <p className="text_white1 top_size_16 " style={{ marginTop: "4px" }}>
                            Last 7d
                          </p>
                        </Grid>

                        <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }} >
                          <img src={filterW} style={{
                            width: "20px",
                            height: " 17.8px",
                            marginTop: "9px"
                          }} />
                        </Grid>
                      </Hidden>

                    </Grid>
                  </Grid>
                  {/* <ScanQrDialog
                      openPopup={openPopup}
                      shareItem={"Playlist"}
                      handleOpenPopup={() => this.setState({ openPopup: false })}
                      TrackId={setTrackId}
                    /> */}
                </Box>
                <div
                  className={isPlaylist ? "height-playlist_listing mostly-customized-scrollbar" :
                    "allplaylist_listing mostly-customized-scrollbar"
                  }
                >

                  {this.returnConditional()}
                </div>
              </div>
            </div>

          </>
        </div>
      </div>
    );
  }
}
TopPlaylists.contextType = AudioContextApi;

// Customizable Area End