// Customizable Area Start
import "antd/dist/antd.css";
import { logo } from "./assets";
import { LeftOutlined } from "@ant-design/icons";
import { Row, Col, Form, Button } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../otp-input-confirmation/src/otp.css"
import axios from "axios";
import OtpInput from 'react-otp-input';
import { openNotification } from "../../../components/src/Notification.web";
import MyTimer from "../../../components/src/TimerWeb";
import { Link } from "react-router-dom";
const urlConfig = require("./../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export default function OtpVerifyWeb() {
  const history = useHistory();
  const [otp, setOtp] = useState('')
  const token: any = localStorage.getItem("forget_token")

  const onFinish = async (values: any) => {

    // const data = {
    //   // "otp": parseInt(values.otp),
    //   "otp": [parseInt(values.otp)],
    //   // token:token
    // }
    if (values.otp) {
      await axios
        .put(`${baseUrl}/bx_block_forgot_password/passwords/verify_otp?token=${token}&otp=${[parseInt(values.otp)]}`)
        .then((res) => {
          history.push("/reset-password")
          openNotification(res.data.message, "Success")

        })
        .catch((err) => {
          // console.log(err.response,"kkkkkkk");

          openNotification(err?.response?.data?.message, "Error")
          // err.response.status === 401 ? history.push("/AccountType"):""
          // err.response.status === 401||400 ? 
          //   history.push("/forgot-password")
          //   : ""
          // });
        });
    } else {

      openNotification("Please Enter OTP", "Error")
    }

  };
  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  }
  const [showResendOTP, setShowResendOTP] = useState<boolean>(false);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 150);
  const [form] = Form.useForm();
  const handleResend = async () => {
    form.resetFields();
    await axios
      .put(`${baseUrl}/account_block/resend_otp`, '', {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          
          token: token,
        },
      })
      .then((res) => {
        setShowResendOTP(false)
        openNotification(res?.data?.message, "Success")
        // history.push("/TermAndConditions")
      })
      .catch((err) => {
        if(err.response.status === 401 ){
          openNotification("Token has Expired", "Error")
          history.push("/AccountType")
        }else{
          openNotification(err?.response?.data?.errors[0], "Error")

        }
       
      });
  }
  return (
    <Row className="bgDark" style={{ height: "100%" }} >
      {/* <Row justify="space-around"> */}
      <Col lg={12} md={12} sm={24} xs={12} className="bgImg-welcome">
        {/* <Link to="/"> */}
        <div>
          <img src={logo} className="logo-img" />
        </div>
        {/* </Link> */}
      </Col>
      <Col lg={12} md={12} sm={24} xs={24} className="accType-form">


        <Row className="button-back">
          <button className="gray1"
           style={{backgroundColor:"black"}}
            onClick={() => {
              history.goBack();
            }}
          >
           {/* @ts-ignore */}
            <LeftOutlined translate  /> Back
          </button>
        </Row>
        <Row align="middle" justify="center" className="height_80">
          <Col xxl={10} xl={13} lg={15} md={18} sm={12} xs={14}>
            {/* <div style={{ width: "75%", margin: "auto", textAlign: "center" }}> */}
            {/* <div className="otp-text"> */}
            <Row
              justify="center"
              className=" width_100 text_white mt-1  font_25 width_100 font_W_600"
            >  Enter OTP</Row>
            <Row justify="center" className="text_white1 width_100">To continue enter OTP</Row>
            {/* </div> */}
            {/* <Row  justify="center" className=""> */}
            <Form
              name="basic"
              className="login_form"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row justify="center" >
                <Form.Item className="otp-input login_form"
                  name="otp">
                  <OtpInput
                    onChange={handleOTPChange}
                    numInputs={4}
                    separator={<span>-</span>}
                  /></Form.Item>
              </Row>
              {/* <Row justify="center"> */}
              {/* <Col lg={24} className="otp-button w_100"> */}
              <Row justify="center" className="time">
                {showResendOTP ?
                  <Button type="text" onClick={handleResend} className="gray1">
                    Didn't Get OTP?<span
                      className="text_red pl_05 cursor_pointer"
                    >RESEND</span> </Button> :
                  <MyTimer expiryTimestamp={time} setShowResendOTP={setShowResendOTP} showResendOTP={showResendOTP} />}</Row>
              {/* <button
                      type="button"
                      onClick={() => {
                        history.push("/TermAndConditions");
                      }}
                    >
                      CONTINUE
                    </button> */}
              <Form.Item className="mt-3"> <Button
                className="red_custom_btn login_btn "
                type="primary"
                htmlType="submit"
              // onClick={() => {
              //   history.push("/TermAndConditions");
              // }}
              >
                CONTINUE
              </Button></Form.Item>
              {/* </Col> */}
              {/* </Row> */}
            </Form>
            {/* </Row> */}
          </Col>
        </Row>
      </Col>
      {/* </Row> */}
    </Row>
  );
}
// Customizable Area End
