// Customizable Area Start
import axios from "axios";
import moment from "moment";
export const configJSON = require("./config.js");
// ../../config.js

const token: any = localStorage.getItem("token")
export function getChannelName(currentUserId: string, userId: string): string {
    if (parseInt(currentUserId) < parseInt(userId)) {
        return "Chat-" + currentUserId + "-" + userId
    } else {
        return "Chat-" + userId + "-" + currentUserId
    }
}


export async function sendMessage(receiverId: string, text: string): Promise<any> {
    let formData = new FormData();
    const apitoken: any = localStorage.getItem("token")
    formData.append("data[receiver_id]", String(receiverId));
    formData.append("data[text]", String(text));
    const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: apitoken,
    }
    axios.post(
        `${configJSON.baseUrl}/bx_block_messages/messages`,formData,{headers}
       
    )
}

export const findDuration = (fromDate: number) => {
    let myDate = moment(fromDate * 1000).format("YYYY/MM/DD HH:mm:ss");

    if (myDate === "Invalid date") {
        myDate = moment(fromDate, "MM-DD-YYYY HH:mm:ss").format(
            "YYYY/MM/DD HH:mm:ss"
        );
    }

    const date1 = moment(myDate);

    const date2 = moment(new Date());
  
    const days = date2.diff(date1, "days");

    if (days > 1) {

        return `${days} days`;
    }else if(days == 1){
        return `${days} day`;
    }


    const hours = date2.diff(date1, "hours");
    if (hours > 0) {
        return `${hours} hr`;
    }
    const minutes = date2.diff(date1, "minutes");
    if (minutes > 0) {
        return `${minutes} min`;
    }
    return "Just Now";
};

export async function deleteChat(receiverId: string,): Promise<any> {
    axios.delete(
        `${configJSON.baseUrl}/bx_block_messages/messages/delete_chat?sender_id=` + receiverId,
        {
            headers: {
                "Content-Type": "application/json",
                token: token,
            }
        }
    )
}

export async function readMessage(receiverId: string,): Promise<any> {
let token: any = localStorage.getItem("token")

    axios.put(
        `${configJSON.baseUrl}/bx_block_messages/messages/read_update?receiver_id=` + receiverId,{},
        {
            headers: {
                "Content-Type": "application/json",
                token: token,
            }
        }
    )
}



// DMListCP







export async function readMessageMedia(receiverId: string,): Promise<any> {
let token: any = localStorage.getItem("token")

    axios.put(
        `${configJSON.baseUrl}/bx_block_messages/messages/read_update?receiver_id=` + receiverId,{},
        {
            headers: {
                "Content-Type": "application/json",
                token: token,
            }
        }
    )
    axios.put(
        `${configJSON.baseUrl}/bx_block_messages/messages/read_message?receiver_id=` + receiverId,{},
        {
            headers: {
                "Content-Type": "application/json",
                token: token,
            }
        }
    )
}

export async function unreadMessage(receiverId: string,): Promise<any> {
    return axios.put(
        `${configJSON.baseUrl}/bx_block_messages/messages/unread_message?receiver_id=` + receiverId,{},
        {
            headers: {
                "Content-Type": "application/json",
                token: await token,
            }
        }
    )
}
// Customizable Area End