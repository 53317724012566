Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config")
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ReviewAndApproval";
exports.labelBodyText = "ReviewAndApproval Body";
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.deleteAPIMethod = "DELETE";

exports.getThousandDownloaders= "/bx_block_profile/top_thousand_downloaders"
exports.userDownloadListing="/user_downloads_listing"
exports.userFollow="/account_block/follow_user"
exports.getSnapshotData= "/account_block/user_snapshot"
exports.getAllTracks= "/bx_block_audiomusic/get_all_tracks"
exports.getTrackDetails= "/bx_block_audiomusic/show_track"
exports.deleteTrack= "/bx_block_audiomusic/delete_track"
exports.getSongsData= "/bx_block_audiomusic/get_all_tracks?upload_type=song"
exports.changeStatus= "/bx_block_playlist/user_play_lists/update_track_status"

exports.btnExampleTitle = "CLICK ME";
exports.baseUrl = urlConfig.baseURL;

// Customizable Area End