// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "components/src/common";
import { setStorageData } from "../../../../../framework/src/Utilities";

export const configJSON = require("../../config");

export interface Props {
  navigation: any;
  id: string;
  getStatusDetails:any
}

interface S {
  active: string;
  showdetails: boolean;
  selectedIndex: any;
  top40videoList: any;
  videoList: any;
  searchKey: any;
  selectedSongId: any;
  loading: boolean;
  videoDetailsId: any;

  mastertop40videoList: any;
  mastervideoList: any;
  searchText: any;
  openVideoFilter:boolean
}

interface SS {
  id: any;
}

export default class CtrlvideosController extends BlockComponent<Props, S, SS> {
  subscriptionPlan = localStorage.getItem("subscription_plans");

  apigetAllVideosList: string = "";
  apiVideoDraftTracksList: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      active: "0",
      showdetails: false,
      selectedIndex: "",
      top40videoList: [],
      videoList: [],
      searchKey: "",
      selectedSongId: "",
      loading: false,
      videoDetailsId: 0,
      mastertop40videoList: [],
      mastervideoList: [],
      searchText: "",
      openVideoFilter:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getVideos();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.searchKey !== prevState.searchKey) {
      this.getVideos();
    }
  }

  handleOpenVideo=()=>{
    this.setState({openVideoFilter:true})

  }
  handleCloseFilter=()=>{
    this.setState({openVideoFilter:false})
  }

  selectedsong = (index: any) => {
    this.setState({
      selectedIndex: index,
      showdetails: true,
    });
  };

  selectedSongId = (id: any, is_drafted: any) => {
    if(is_drafted){
      this.getVideoDraftTracks(id)
    }else{
    this.setState({ selectedSongId: id });
    }
  };


  getVideoDraftTracks = (trackId: any) => {
    
    const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiVideoDraftTracksList = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `/bx_block_audiomusic/show_track?id=${trackId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPIMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  getVideos = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetAllVideosList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_audiomusic/get_all_tracks?upload_type=video`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  changeSearchInput = (e: any) => {
    this.setState({ searchKey: e.target.value });
  };

  getStatusDetails = (item: any) => {
    let typeColor = "#919191";
    let status = "draft";
    
      if (item.attributes?.status.toLowerCase() === "public_track") {
        typeColor = "red";
        status = "Public";
      } else if (item.attributes?.status.toLowerCase() === "private_track") {
        typeColor = "#3959f9";
        status = "Private";
      }
    

    return { typeColor, status };
  };

  searchFilterFunction = (e: any) => {
    let text = e.target.value

    if (text) {
      const top40data = this.state.mastertop40videoList.filter(function(
        item: any
      ) {
        const itemData = item.attributes.title
          ? item.attributes.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ top40videoList: top40data });

      const splitData = this.state.mastervideoList.filter(function(item: any) {
        const itemData = item.attributes.title
          ? item.attributes.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      this.setState({
        videoList: splitData,
        searchText: text,
      });
    } else {
      this.setState({
        top40videoList: this.state.mastertop40videoList,
        videoList: this.state.mastervideoList,
        searchText: text,
      });
    }
  };

  handleVideoList = (responseJson: any) => {
    
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.top_forty_tracks?.data) {
        this.setState({
          top40videoList: responseJson?.top_forty_tracks.data,
          mastertop40videoList: responseJson?.top_forty_tracks.data,
          loading: false
        });
      } else {
        this.setState({
          top40videoList: [],
          mastertop40videoList: [],
          loading: false
        });
      }
      if (responseJson?.all_tracks?.data) {
        this.setState({
          videoList: responseJson.all_tracks.data,
          mastervideoList: responseJson.all_tracks.data,
          loading: false
        });
      } else {
        this.setState({
          videoList: [],
          mastervideoList: [],
          loading: false
        });
      }
    } else {
      this.setState({
        top40videoList: [],
        videoList: [],
        mastertop40videoList: [],
        mastervideoList: [],
        loading: false
      });
    }
  };
 

  
  redirectVideo = (item: any) => {
    HISTORY.push({
      pathname: "/home/Charts/videos",
      state: {
        video_list: item.attributes.video_file,
        activeNo: "5",
        data: item,
        currentList: this.state.top40videoList ? this.state.top40videoList : [],
      },
    });
  };

  handleVideoDraftTrackList = async( resposneJson: any) =>{
    if(resposneJson.data[0].id){
       await setStorageData('selected_draft_song', resposneJson.data[0])
       HISTORY.push(`/uploadTrack/audio`)
      }
   }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == null) {
      this.parseApiCatchErrorResponse(errorReponse);
      return;
    }
    switch (apiRequestCallId) {
      case this.apigetAllVideosList:
        this.handleVideoList(responseJson);
        break;
      case this.apiVideoDraftTracksList:
        this.handleVideoDraftTrackList(responseJson);
        break;
      default:
        break;
    }
  }
}
// Customizable Area End
