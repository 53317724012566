// Customizable Area Start

import React from "react";
import AreaChartController, { Props } from "./AreaChartController";
import { AreaChart, Area, XAxis, YAxis } from 'recharts';



interface CustomTickProps {
    x: number;
    y: number;
    payload: { value: string, index: number };
  }

export default class Areachart extends AreaChartController {
    removeNegativeSign = (value: any) => {
        const valueWithM = `${value} M`;
        return valueWithM;
    };
    renderCustomizedTick = ({ x, y, payload }: CustomTickProps) => {
        const day = this.props.activechartdata[payload.index].day;  // Use 'name' to represent the month
        const date = this.props.activechartdata[payload.index].date.split("-")[2];
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={-30} dy={16} textAnchor="middle" fill="#666">
              {day}
            </text>
            <text x={0} y={10} dy={0} textAnchor="middle" fill="#EFEFEF">
            {date}
            </text>
          </g>
        );
      };
    constructor(props: Props) {
        super(props);
    }
    render() {

        return (
            <>
                <AreaChart
                    width={437}
                    className="pushchart"
                    height={300}
                    data={this.props.chartType == "Active" ? this.props.chartdata :this.props.activechartdata}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="rgba(254, 240, 238, 0.24)" />
                            <stop offset="100%" stopColor="rgba(254, 240, 238, 0)" />
                        </linearGradient>
                    </defs>
                    {this.props.chartType == "Active" ? <XAxis dataKey="month" axisLine={false} tickLine={false} padding={{ left: 20 }} /> : 
                    <XAxis  axisLine={false} tickLine={false} padding={{ left: 20 }} tick={this.renderCustomizedTick}/>}

                    <YAxis axisLine={false} tickFormatter={this.removeNegativeSign} tickLine={false} padding={{ top: 20, bottom: 20 }} />
                    <Area type="monotone" dataKey="count" stroke="#3959F9" fill="url(#colorUv)" />
                </AreaChart>
            </>


        )


    }
}



// Customizable Area End