// Customizable Area Start
import {
  Grid,
  Chip,
  Typography,
  ButtonGroup,
  Button,
  Box,
} from "@material-ui/core";
import React from "react";
import "./snapshot.css";
import DownloaderDetailsController from "./DownloaderDetailsController";
import { album1 } from "blocks/dashboard/src/assets";
import { DmAdd, FollowAdd, SendAdd, backArrow } from "../../assets";
import MessageLinkPopup from "../../../../../components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";

export default class MobileDownloaderDetails extends DownloaderDetailsController {
  render() {
    const { userData, threebtnToogle } = this.props;
    const { trackList } = this.state;

    return (
      <Grid container className="downloadArtWorkGrid-downloads">
        
        <Box style={{ display: "flex" ,width:"100%",marginBottom:"2%"}}>
          <img
            src={backArrow}
            style={{
              width: "26px",
              height: "26px",
              cursor:"pointer"
            }}
            onClick={this.props.onBack}
          />
          <Typography
            style={{
              color: "#fff",
              textTransform: "uppercase",
              fontSize: 22,
              letterSpacing: 1.8,
              flex: 1,
              textAlign: "center",
              marginRight: 26,
            }}
          >
            Downloaded
          </Typography>
        </Box>
        <div style={{position :"relative",width:"100%"}}> 

        <img
          src={userData.attributes?.avatar || album1}
          style={{
            height: "375px",
            width: "100%",
            borderRadius: 10,
            position:"relative",
            opacity:0.6
          }}
        />
         <Grid
            container
            item
            xs={12}
            className="downloaderListSecondGrid-downloads"
            style={{ 
                position:"absolute",
                bottom:"10px",
                padding: "0px 30px "
            }}
          >
            <Grid item xs={2}>
              <img
                src={userData.attributes?.avatar || album1}
                className="downloadArtWork"
              />
            </Grid>
            <Grid item xs={10} className="downloaderListTopGrid-downloads">
              <span className="topDownloadersSpan-downloads">
                {userData?.attributes?.creative_type_name}
              </span>
              <span className="artistGrid">
                {userData?.attributes?.user_name}
              </span>
            </Grid>
          </Grid>
        </div>

        <Grid container item xs={12} className="mBStyle">
         
          <Grid item xs={12} style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "15px"
          }}>
            <img
              src={FollowAdd}
              className="imgIcon"
              onClick={this.followUser}
            />
            <img src={DmAdd} className="imgIcon" onClick={this.openDMScreen} />
            <img
              src={SendAdd}
              className="imgIcon"
              onClick={this.linkopenPopup}
            />
          </Grid>
        </Grid>
        <div
          style={{
            minHeight: "60px",
            width: "100%",
            marginBottom: "15px",
            alignItems: "center",
            display: "flex",
          }}
        >
          <ButtonGroup
            color="primary"
            className="outer-div-toggle btnGroup"
            aria-label="outlined primary button group"
          >
            <Button
              className="audio-button-playlist"
              value="audio"
              variant="contained"
              disabled={threebtnToogle === "Beats"}
              onClick={() => this.props.changetabs("Beats")}
            >
              <span className="audio-text">Beats</span>
            </Button>
            <Button
              variant="contained"
              className="video-button-playlist"
              onClick={() => this.props.changetabs("Hooks")}
              disabled={threebtnToogle === "Hooks"}
            >
              <span className="audio-text">Hooks</span>
            </Button>

            <Button
              variant="contained"
              className="versesButtonPlaylist"
              onClick={() => this.props.changetabs("Verses")}
              disabled={threebtnToogle === "Verses"}
            >
              <span className="audio-text">Verses</span>
            </Button>
          </ButtonGroup>
        </div>
        {trackList.length > 0 ? (
          <>
            <Grid container item xs={12} className="displayMargin">
              <Grid item xs={6}>
                <span className="topDownloaders-downloads">
                  {trackList.length} &nbsp;
                </span>
                <span className="typeHead"> {threebtnToogle}</span>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <Typography className="downloadTypeName-downloads">
                  Genre/BPM
                </Typography>
              </Grid>
            </Grid>
            <div style={{ maxHeight: "500px" }} className="main-scroll">
              {trackList.length > 0 &&
                trackList.map((item: any, index: any) => (
                  <Grid container item xs={12} className="displayBetween">
                    <Grid container item xs={10} className="displayPd">
                      <Grid item xs={3}>
                        <img
                          src={item?.attributes?.art_work}
                          className="artistImage"
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography className="downloadArtName-downloads">
                          {item.attributes.track_name}
                        </Typography>
                        <Typography className="downloadTypeName-downloads">
                          {item?.attributes?.artist_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "end" }}>
                      <Chip
                        label={item?.attributes?.genre}
                        className="chipCss"
                      />
                      <Typography className="downloadTypeName-downloads">
                        <span className="downloadColorBlue">
                          {item?.attributes?.bpm?.replace("BPM ", "")} &nbsp;
                        </span>
                        bpm
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              ;
            </div>
          </>
        ) : (
          <p className="noData">No {threebtnToogle} Available</p>
        )}
        {this.state.openMessageSendPopup && (
          <MessageLinkPopup
            id="Profile"
            copiedLinked={this.state.CopiedForLink}
            showMsgModal={this.state.openMessageSendPopup}
            closeMsgModal={this.handleOpenMessageSendPopup}
            closeModalDialog={this.handleOpenMessageSendPopup}
            currentUserId={1}
          />
        )}
      </Grid>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area End
