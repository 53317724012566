// Customizable Area Start
import React from "react";
import { Grid, Tabs, Tab, Box, IconButton } from "@material-ui/core";
import "./Index.web.css";
import MCurrentPlan from "./MCurrentPlan.web";
import PlanCustomCarousel from "./MPlanCarousel.web";
import MCardInformation from "./MCardInformation.web";
import { ArrowBackRounded } from "@material-ui/icons";
import PaymentMobileViewController from "./PaymentMobileViewController";
import { Row } from "antd";
import { nxzloader } from "blocks/dashboard/src/assets";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
export default class MyPlanMobileView extends PaymentMobileViewController {
  render() {
    const {
      loading,
      getsubscriptions,
      current_plan,
      currentPaymentData,
      handleUpdateCard,
    } = this.props;
    const { tabvalue, active } = this.state;

    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }

    return (
      <>
        {loading ? (
          <Row className="index-loader-style">
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              item
              xs={12}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid item xs={1}>
                <IconButton onClick={this.iconClick}>
                  <ArrowBackRounded style={{ color: "white" }} />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={11}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {tabvalue.map((item: any) => {
                  return (
                    item.value == active && (
                      <span className="m_myplan_font">{item.tabName}</span>
                    )
                  );
                })}
                {/* {tabvalue === 0 && (
                )}
                {tabvalue === 1 && (
                  <span className="m_myplan_font">My Card</span>
                )} */}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ margin: "10px 10px" }}>
              <Tabs
                value={active}
                variant="scrollable"
                onChange={this.handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "red",
                    color: "red",
                    borderBottom: "1px solid",
                    bottom: "7px",
                  },
                }}
                className="m_custom_tab_font"
              >
                {tabvalue.map((item: any) => {
                  return (
                    <Tab
                      className={
                        item.value == active
                          ? "active_myplan_tab"
                          : "non_active_myplan_tab"
                      }
                      label={item.tabName}
                      {...a11yProps(item.value)}
                    />
                  );
                })}
              </Tabs>
              <TabPanel value={active} index={0}>
                <MCurrentPlan {...this.props} />
                <PlanCustomCarousel
                  getsubscriptions={getsubscriptions}
                  currentPlan={current_plan}
                  currentPaymentData={currentPaymentData}
                />
              </TabPanel>
              <TabPanel value={active} index={1}>
                <Grid
                  item
                  xs={12}
                  container
                  style={{ margin: "15px 0px 30px" }}
                >
                  <MCardInformation currentPaymentData={currentPaymentData} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  className="m_user_plan_div"
                  justifyContent="center"
                >
                  <button className="m_update_btn" onClick={handleUpdateCard}>
                    {currentPaymentData?.card ? "Update Card" : "Add Your Card"}
                  </button>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

// Customizable Area End
