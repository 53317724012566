// Customizable Area Start
import React from 'react'
import UnlockContentCommonController, { SearchBeatTypesReturn } from './UnlockContentCommonController.web';

export default class UnlockContentCommon extends UnlockContentCommonController {
    render() {
      return (
        <>
        { this.props.trackData?.attributes.level && this.state.loggedInUser != this.props.trackData?.attributes.account_id && !this.props.trackData?.attributes.unlocked_track_by_current_user &&
          <div style={{ position: 'absolute' , height: this.props.height ? this.props.height :'80%', width: this.props.width ? this.props.width : '90%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={this.redirectionClick}>
            <span style={{color: 'white', border: '2px solid white', borderRadius: '50%', backgroundColor: 'red', padding: this.props.padding ? this.props.padding : '10px', fontSize: this.props.fontSize ? this.props.fontSize :'12px', fontWeight: 'bold'}}>
                {SearchBeatTypesReturn(this.props.trackData?.attributes.level)}
            </span>
            </div>
    }
        </>
       
      )
    }
  }

// Customizable Area End