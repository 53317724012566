// Customizable Area Start
import React from "react";
import UserSplitController, {
    Props,
} from "./UserSplitController.web";
import "./UserSplit.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden, Avatar, Typography } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from '../Common/RightPanel.web';
import CheckIcon from '@material-ui/icons/Check';
import UserChart from './UserChart.web';
import UserPerformanceStats from './UserPerformanceStats.web';
import { SelectSplitRightBg } from "../assets";
import { AudioContextApi } from "../../AudioContext/AudioContext.web"
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class UserSplit extends UserSplitController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, selectedUser,userstats,graph,upload_types_count } = this.state;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="AudioSplitContent">
                                <Grid container  style={{height: this.context.isPlaylist ? '75vh' : '90vh' }}>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter'
                                            >
                                                {/* User Detail Start*/}
                                                <div className={`UserCard Active`} >
                                                    <div className="ContentDiv">
                                                        <Avatar src={selectedUser[0]?.artist_image} className="UserIcon" />
                                                        <div className="UserInfoDiv">
                                                            <div className="ColumnCenter">
                                                                <Typography className="UserNameText">
                                                                    {selectedUser[0]?.user_name}<CheckIcon htmlColor='#ffffff' className='CheckIcon' />
                                                                </Typography>
                                                            </div>
                                                            <div className="ColumnCenter">
                                                                <Typography className="UserEquityText">
                                                                    {selectedUser[0]?.percentage}%
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Chart START*/}
                                                <div className="UserChartDiv">
                                                    {graph?.length !== 0 &&  <UserChart   graph={graph}/>}
                                                   
                                                </div>
                                                {/* Chart END */}
                                                <div className={`ChartBottom`} >
                                                    <Typography className="ChartDataText">
                                                        Last Quarter
                                                    </Typography>
                                                </div>
                                                {/* User Detail End*/}

                                                {/* Performance Stats START*/}
                                                <div className={`PerformanceStatDiv`} >
                                                    <UserPerformanceStats
                                                        userStats={userstats}
                                                        upload ={upload_types_count}
                                                    />
                                                </div>
                                                {/* Performance Stats END */}
                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT32">
                                                        <div className='CommonText InfoTextSm'>Select The User You Want</div>
                                                        <p className='CommonText InfoTextSm'>To Buy Split From.</p>
                                                    </div>
                                                </Hidden>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5}>
                                            <RightPanel
                                                    line1={`Select The User You`}
                                                    line2={`Want To Buy Split From.`}
                                                    backgroundImage={SelectSplitRightBg}
                                                    onNextClick={this.onNextClick}
                                                    onBackClick={this.onBackClick} isPlaid={false} linkToken={""}
                                                    onSucessCallApi={(datas:string,accountId:string)=>{alert(`Data:${datas}${accountId}`)}}
                                                    isLinkOpen={()=>{return false}}                                            
                                                                                              />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default UserSplit;
UserSplit.contextType = AudioContextApi;
// Customizable Area End