// Customizable Area Start
import React from "react";
import "./MonthTrack.css";
import TrackInfo from './TrackInfo.web'

const TracksSection = ({ items, isFilter, onClick}:any) => {
  return (
  <>
    {items.map((item:any, index:any) => (
      <TrackInfo
        key={item.id}
        item={item}
        onClick={() => onClick(item, item.id, items, item.type, index, isFilter)}
      />
    ))}
  </>
)
    };
export default TracksSection
// Customizable Area End