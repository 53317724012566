// Customizable Area Start
import React from 'react';
import { Box, Modal, Hidden } from '@material-ui/core';
import './PlanUpdateCancel.css';
import { useHistory } from 'react-router-dom';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import { nxzsoundLogo } from '../assets';
import PlanUpdateController from './PlanUpdateController'
import { HISTORY } from 'components/src/common';

export default class PlanUpdate extends PlanUpdateController{
render(){
const { open, handleClose } = this.props


  

  return (
      <Modal open={open} onClose={handleClose} className='PlanDialog'>  
        <Box style={{ display: 'flex', width: '100%', height: '100%', }}>
          <Hidden smDown>
            <AsideLeft />
          </Hidden>
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
            <Box className='paperBack'>
              <Box className='update_plan'>
                <img src={nxzsoundLogo} alt='nxzsoundLogo' />
                <h1>Plan Updated</h1>
                <Box className='plan_description'>
                  <span className='fontWeight'>
                    This change will reflect on Your next billing statement
                  </span>
                </Box>
              </Box>
              <Box className='update_plan updatePlanBtn'>
                {/* <img
                  src={visitprofile}
                  alt='visitProfile'
                  style={{ paddingBottom: '10px', cursor: 'pointer' }}
                  // onClick={() => setOpenPopup(true)}
                  onClick={() => redirectHandler()}
                /> */}
                {/* <PlanCanceled
              open={openPopup}
              handleClose={() => setOpenPopup(false)}
            /> */}

                {/* <img
                  src={viewplan}
                  alt='viewPlan'
                  onClick={() => window.location.reload()}
                  style={{ cursor: 'pointer' }}
                /> */}
                <button
                  className='my_plan_Btn'
                  onClick={() => this.redirectHandler()}
                  style={{ backgroundColor: '#3959f9', marginBottom: '10px' }}>
                  Visit Profile
                </button>

                <button
                  className='my_plan_Btn'
                  onClick={() =>
                    location.pathname === '/planpayments'
                      ? window.location.reload()
                      : HISTORY.push('/planpayments')
                  }
                  style={{
                    backgroundColor: '#212121',
                    color: 'rgba(255, 255, 255, 0.5)',
                  }}>
                  View Plan
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
   
  );
                }
};
// Customizable Area End