// Customizable Area Start
import React, { useMemo } from 'react';
import { Box, Typography, Grid } from '@material-ui/core'
import { findDuration, readMessageMedia } from '../ChatUtils';
import { defaultProfile } from '../../../dashboard/src/assets';
// Customizable Area End




// Customizable Area Start
interface Props {
   selected: boolean;
   unread: boolean;
   media: any;
   type?: string;
   onUserProfileClick: (comment: any) => void;
   onHandleUserId: (comment: any) => void;
   onHandleNotUserId: (comment: any) => void;
   TrackIndex : any;
   selectedTrackIndex: any;
   setTrackIndex : (index: any,dataid:any) => void;
   onClearNewAddedUser :any;
   keyId?:any;
}
// Customizable Area End


// Customizable Area Start
export const MediaDMCP = (props: Props) => {
   const handleUserClick = (e: any) => {
       props.onUserProfileClick(e)
      


   }


   const currentUserID = localStorage.getItem("loggedIn_userId")
   const readStatus = useMemo(() => {
       return (!props.media.attributes?.read_status && (props.media.attributes.sender_id != currentUserID)) || props.media.attributes?.unread_status
   }, [props.media.attributes, currentUserID])


   return (
    <Box className="MediaCommentCPMB" style={{backgroundColor: props.keyId.includes(props?.selectedTrackIndex?.toString()) ?  '#141414' : "transparent",border:props.keyId.includes(props?.selectedTrackIndex?.toString()) ? 'solid 3px #212121': 'transparent', borderRadius: '10px',cursor:"pointer",padding:"5px 10px",marginTop:"0"}}>
           <Grid container xs className={`CommentSummary ${props.selected && 'Active'}`}
              onClick={() => {
                props.onClearNewAddedUser()
                props.setTrackIndex(props.media.id,props.media.attributes?.sender_id)
                       if (currentUserID == props.media.attributes?.receiver_id) {
                           readMessageMedia(props.media.attributes?.sender_id)
                           props.onHandleNotUserId(props.media.attributes.sender_id)
                       } else {
                           readMessageMedia(props.media.attributes?.receiver_id)
                           props.setTrackIndex(props.media.id,props.media.attributes?.receiver_id)
                           props.onHandleNotUserId(props.media.attributes.receiver_id)
                   }

               }}>
               <Grid item xs={2} className={`LeftImageBox`} style={{ height: "100%", width: "100%" }}>
               <div className='DMProfileImage'>
               <img src={props.media.attributes.profile_image||defaultProfile} className={props.media.attributes.profile_image?'mediaDMCP-avatar':"default-profile-mediaDMCP"} onClick={handleUserClick} />
               </div>
               </Grid>
               <Grid container item xs={10} className='SummaryBox' >
                   <Grid item xs={12} >
                       <Typography className='MediaDMText'>
                           {props.media.attributes.artist_name}
                       </Typography>
                   </Grid>
                   <Grid container item xs={12} style={{ display: "flex" }}>
                   <Grid item xs={10} className='CommentCountBox' style={{marginTop:"0"}}>
                           <Typography className='MediaDMMessage'>{props.media.attributes.message}</Typography>
                           <Box style={{ color: "#fff", fontWeight: "bold",marginLeft:"3px", fontSize: 13 }}>- {findDuration(new Date(props.media.attributes?.message_time).getTime() / 1000)}</Box>
                       </Grid>
                       <Grid item xs={2} className='CommentCountBox'style={{marginTop:"-10px"}}>
                           {readStatus &&
                               <Box className='CPBDContainer'>
                                   <Box className='BlueDot'></Box>
                               </Box>
                           }
                       </Grid>
                   </Grid>
               </Grid>
           </Grid>
       </Box>
   );
}
// Customizable Area End




// Customizable Area Start
export default MediaDMCP;
// Customizable Area End