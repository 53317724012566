// Customizable Area Start
import React from 'react';
import { Typography, Grid, Box, Avatar, createStyles, withStyles, Theme } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
// Customizable Area End


// Customizable Area Start
export interface Props {
    title: string;
    list: any[];
    type: string;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
// Customizable Area End

// Customizable Area Start
export const TrendingList = (props: Props) => {
    const { list } = props;
    return (
        <Grid container className="TrendingListContainer">
            <Grid item xs={12} className='TLTitleGrid'>
                <Typography className='Title' data-test-id="ListTitleText">{props.title}</Typography>
                <Typography className='RightText'>This week</Typography>
            </Grid>
            {
            list.length > 0 ?
            list.map((item: any, index:number) => {
                return (
                    <Grid key={item?.id} item xs={12} className='TLListGrid'>
                        <Box><Typography className='IndexNumber'>{index+1}</Typography></Box>
                        <Box>
                            <Avatar src={props.type == "Label" ? item?.attributes?.label_profile : item?.attributes?.user_profile} className='Image' />
                        </Box>
                        <Box className='detailBox'>
                            <Box className='FlexBetween'>
                                <Typography className='Title FS14'>{props.type == "Label" ? item?.attributes?.creator_name : item?.attributes?.user_name}<CheckIcon htmlColor='#ffffff' className='CheckIcon' /></Typography>
                                <Typography className='Title MR48 FS16'>${props.type == "Label" ? item?.attributes?.amount : item?.attributes?.total_amount}</Typography>
                            </Box>
                            <Box className='FlexBetween'>
                                <Typography className='SubTitle FS12'>{props.type == "Label" ? "Label" : item?.attributes?.user_role}</Typography>
                                <Typography className='SubTitle MR48 FS14'>{props.type == "Label" ? item?.attributes?.deal_count : item?.attributes?.accepted_deals_count} Deals</Typography>
                            </Box>
                            <Box className='ProgressBox'>
                                <BorderLinearProgress variant="determinate" value={64} />
                            </Box>
                        </Box>
                    </Grid>
                )
            })
        : 
        (
            <Grid style={{display: 'flex', color: 'white', fontSize: '18px', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '20px'}}
            >
              No Trending {props.type == "Label" ? "Labels" : 'Creatives'}
            </Grid>
        )
        }

        </Grid>
    );
}
// Customizable Area End


// Customizable Area Start
export default TrendingList;
// Customizable Area End
