// Customizable Area Start
import React, { useState} from "react";
import { useHistory } from "react-router-dom";
import "../assets/listener.css";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import { SignUpFormLabelAndCreative } from "./Common/SignUpFormLabelAndCreative.web";
const urlConfig = require("./../../../framework/src/config");
const baseUrl = urlConfig.baseURL;

export default function Creative() {
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const history = useHistory();
 
  const onFinish = async (values: any) => {
    const data = {
      type: "email_account",
      user_type: localStorage.getItem("accountType"),
      user_name: values.username,
      full_name: values.full_name,
      full_phone_number: `+${values.phone}`,
      email: values.email,
      // email: location.email ? location.email : values.email,
      password: values.password,
      password_confirmation: values.password,
      spotify_id: values.spotify,
      instagram_id: values.instagram,
    };
    let endpoint = "account_block/accounts?devise_type=web";
    if (!!inviteEmail) {
      endpoint = "bx_block_profile/profile/update_genre";
      
    }
    await axios({
      method: inviteEmail ? 'PUT' : 'POST', // Use PUT if inviteEmail is true, otherwise use POST
      url: `${baseUrl}/${endpoint}`,
      data: data
  })
      .then((res) => {
        // history.push("/Otp")
        localStorage.setItem("token", res.data.meta.token);
        history.push("/TermAndConditions");

        // localStorage.setItem("otp", res.data.data.attributes.otp)
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0]?.account, "Error");
      });
  };
  return (
    // <div className="bgDark" >
    <SignUpFormLabelAndCreative
      onSubmit={(values: any) => onFinish(values)}
      emailInvite={inviteEmail}
      setInviteEmail={setInviteEmail}
    />
  );
}
// Customizable Area End
