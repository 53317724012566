// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { HISTORY } from "components/src/common";
export const configJSON = require("../../config");

export interface Props {
    active: string;
    heading: string;
    index: string;
    loading: boolean;
    top40SplitsList: any;
    masterSplitsList: any;
    mastertop40SplitsList: any;
    splitList: any;
    selectedIndex: any;
    showdetails: boolean;
    threebtnToogle: any;
    splitDetailsId: any;
    searchText: any;
    topPlayMusic:any;
    getStatusDetails:any;
    changetabs:any;
}

interface S {
   
}

interface SS {
  id: any;
}

export default class RightSideSplitDetailsController extends BlockComponent<Props, S, SS> {
   
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
    
    };

   
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  redirectVideo = (item: any) => {
    HISTORY.push({
      pathname: "/home/Charts/videos",
      state: {
        video_list: item.attributes.video_file,
        activeNo: "5",
        data: item,
        currentList: this.props.top40SplitsList ? this.props.top40SplitsList : [],
      },
    });
  };

  


}
// Customizable Area End