// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");


//upload assets
export const fire = require("../assets/fire.png");
export const audioCover = require("../assets/dan-asaki-bKI9DEtOqg8-unsplash.png");
export const PlusIcon = require("../assets/Plusbig.png");
export const ScanCover = require("../assets/lvpo-the-golden-age-IPiNVD7VywA-unsplash.png");
export const CheckTick = require("../assets/Check circle.png");
export const ScanProcessedCover = require("../assets/roman-raizen-gts3hdwWSuo-unsplash.png");
export const PlayIcon = require("../assets/song-play.png");
export const UploadImageCover = require("../assets/jc-gellidon-xDsq3u3ZUqc-unsplash.png");

export const CameraIcon = require("../assets/camera.png");

export const TrackDetailsCover = require("../assets/brian-lundquist-TJWM_MsJsfM-unsplash.png");
export const LinkTrackCover = require("../assets/shutterstock_1627173004.png");

export const StartCover = require("../assets/1.png");
export const AddSplitsCover = require("../assets/2.png");

export const VideoFrame = require("../assets/Video Frame.png");
export const filterIcon = require("../assets/filterGray.png");

export const audioFrame = require("../assets/Frame 123.png");
export const SellingPreferenceCover = require("../assets/vitaly-otinov-an9BEVXeii8-unsplash.png");
export const userIcon = require("../assets/Frame 156.png");
export const ArrowIcon = require("../assets/Arrow drop down.png");
export const coverSplitIcon = require("../assets/Oval Copy.png");
export const removeSplitIcon = require("../assets/Frame 142.png");

export const upArrowButton = require("../assets/Group 8.png");
export const downArrowButton = require("../assets/Group 9.png");
export const setDate = require("../assets/setdate.png");
export const setTime = require("../assets/settime.png");
export const AsapButton = require("../assets/asap.png");

export const todayDate = require("../assets/today.png");
export const nxzSoundLogo = require("../assets/nxzsound logo 2.png");
export const uploadCompleteCover = require("../assets/emin-baycan-o3c95mRLJAc-unsplash 1.png");


export const filterWhite = require("../assets/Filter_white.png");
export const armIcon = require("../assets/arm.png");
export const Calendar7 = require("../assets/Calendar7.webp")
export const IconRight = require("../assets/IconRight.webp")
export const WatchesFrontView = require("../assets/WatchesFrontView.webp")
export const DrumSet = require("../assets/DrumSet.webp")
export const ElectronicMusic = require("../assets/ElectronicMusic.webp")
// Customizable Area End







