import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
  index: any;
  selectIndex: any;
  changetabs?:any;
  handleExpandDownloaders:any;
  item:any;
  totalTracksCount:any;
  key:any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
 

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DownloaderListController extends BlockComponent<
  Props,
  S,
  SS
> {
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
     
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  // Customizable Area Start

  // Customizable Area End
}
