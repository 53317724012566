// Customizable Area Start
import React, { useEffect, useState,useContext,useRef } from 'react';
import { Layout } from 'antd';
import { Hidden, Box, Grid } from '@material-ui/core';
import { Content } from 'antd/lib/layout/layout';
import './index.css';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';

import { ChartsComments } from './ChartsComments.web';
import { ChartsMessages } from './ChartsMessages.web';
import { ChartsSplits } from './ChartsSplits.web';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { CarasouelProfile } from './CarasouelProfile.web';
import { openNotification } from '../../Notification.web';
import { comment, nxzloader } from '../../../../blocks/dashboard/src/assets';
import MetaTag from "components/src/CustomSpilt/meta-tag/MetaTag.web";
import { AudioContextApi } from 'blocks/dashboard/src/AudioContext/AudioContext.web';
const urlConfig = require('../../../../framework/src/config');
const baseUrl = urlConfig.baseURL;


export const SingleTrack = () => {
  const [loading, setLoading] = useState(false);
const [filteredLoader,setFilteredLoader]= useState(false);
  const history = createBrowserHistory();
  const token: any = localStorage.getItem('token');
  const trackId = window.location.href.split(/\=/)[1];
  const [graphdata, setGraphData] = React.useState<any>();
  const [graphCategory, setGraphCategory] = useState<any>();
  const [id, setId] = React.useState('');
  const [userTrack, setUserTrack] = React.useState([]);
  const [trackdata, setTrackData] = React.useState([]);
  const [playPlaylist, setPlayPlaylist] = React.useState(false);
  const [playlistData, setPlaylistData] = React.useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = React.useState([]);
  const [handleAudioVideo, setHandleAudioVideo] = React.useState(1);
  const [artistImage, setartistImage] = React.useState("");
  const [artWork, setArtWork] = React.useState("");
  const [playCount, setPlayCount] = React.useState('')
  const [repostCount, setrepostCount] = useState(0);
  const [chartType, setChartType] = useState<any>("Plays");
  const [chartTime, setChartTime] = useState<any>("Week");

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const user_id: any = localStorage.getItem("loggedIn_userId")


  useEffect(() => {
    getFilterTrack();
    let location: any = history?.location?.state
    if (location?.data) {
      setTrackData(location?.data)
      setPlayCount(location?.data.attributes.play_count);
      setrepostCount(location?.data.attributes.repost_count)
      setId(location.data.id)
      setUserTrack(location.data.attributes.user_tracks && location.data.attributes.user_tracks.data || [])
      setartistImage(location.data.attributes.artist_image||location.data.attributes.artwork||location.data.attributes.cover_image)
      setArtWork(location.data.attributes.art_work||location.data.attributes.avatar)
    } else {
      getTrack();
    }
  }, [chartType, setChartType, setChartTime, chartTime]);

  const getTrack = async () => {
    setLoading(true);
    await axios
      .get(
        `${baseUrl}/bx_block_audiomusic/expan_track?id=${trackId}`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
          },
        }
      )
      .then((res: any) => {
        setTrackData(res?.data?.data)
        setId(res?.data?.data?.id)
        setPlayCount(res?.data.data.attributes.play_count)
        setrepostCount(res?.data.data.attributes.repost_count)
        setUserTrack(res?.data.data.attributes.user_tracks.data)
        setartistImage(res?.data?.data?.attributes.artist_image)
        setArtWork(res?.data?.data?.attributes.art_work)
      })
      .catch((err) => {
        console.log("some thing wrong in get Track")

      })
      .finally(() => {
      });
    setLoading(false);

  }

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      const element = messagesEndRef.current;
    element.scrollIntoView({
      behavior: 'smooth', block: 'nearest'
      })
    }
    
  }


  const getFilterTrack = async () => {
    setFilteredLoader(true);
    await axios
      .get(
        `${baseUrl}/bx_block_audiomusic/filtering_data?filter_by_category=${chartType.toLowerCase()}&filter_by=${chartTime.toLowerCase()}&id=${trackId}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            token: token,
          },
        }
      )
      .then((data: any) => {
        setGraphData(Object.values(data.data));
        setGraphCategory(Object.keys(data.data));
      })
      .catch((error: any) => {
      });

    setFilteredLoader(false);
  };



  const handleChartTrackRepost = async (track_id:any,caption: any) => {
    await axios
      .post(

        `${baseUrl}/bx_block_audiomusic/repost?id=${track_id}&caption=${caption}`,

        null,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
          },
        }
      )
      .then((res: any) => {
        openNotification("Successfully Reposted", "Success")
      })
      .catch((err) => {


      })
      .finally(() => {

      });
  };

  const handlePlayList = (data: any, id: any, mainList: any, name: any, songIndex: any) => {
    if (token) {
      setPlayPlaylist(true)
      localStorage.setItem("music_player", "true");
      localStorage.setItem("playListData", JSON.stringify(data));
      localStorage.setItem("selectedPlaylist", JSON.stringify([]));
      localStorage.setItem("selectedSong", JSON.stringify(data?.id));

      setPlaylistData(data)
      setSelectedPlaylist([])
    } else {
      alert('Login First')
    }
  }
  const {isPlaylist} = useContext(AudioContextApi);

  const commentHeight = () => {
    if(comment != ""){
      return "89vh"
    }
    else{
     return "92vh"
    }  
  }

  return (
    <>
      {/* <div className='trackMain'> */}
      <Layout className='trackLayout'>
        {/* .......................SideBar...................................... */}
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
        <Content className='trackContent' style={{ backgroundColor: "black" }}>
          <MetaTag trackData={trackdata} />
          <HomeHeader />
          {loading || filteredLoader ? (
            <Box className='spinloadder'>
              {/* <Spin size='large' /> */}
              <img src={nxzloader} style={{ width: "5%" }} />
            </Box>
          ) : (
            <Box className='track-content' id="scroll-track-more-new">
              <Grid container spacing={2} className='track_container' ref={messagesEndRef}
                style={{ height: isPlaylist ? "75vh" :  commentHeight()}}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  spacing={2}
                  className='track_charts' style={{display: 'flex', height: '100%', flexGrow: 1, overflow: 'hidden auto',scrollbarColor: 'red transparent'}}>
                  <div className='singlesong_carasoul_height' style={{ }}>
                    {/* <div className="one"> */}
                    <CarasouelProfile
                      mainList={trackdata}
                      songIndex="1"
                      name={'Beats'}
                      handlePlayList={handlePlayList}
                      handleAudioVideo={handleAudioVideo}
                      setHandleAudioVideo={setHandleAudioVideo}
                      trackdata={trackdata}
                      handleChartTrackRepost={handleChartTrackRepost}
                      artWork={artWork}
                      artistImage={artistImage}
                      getTrack={getTrack}
                      trackId={trackId}
                      setrepostCount={setrepostCount}
                      repostCount={repostCount}

                    />
                    {/* </div> */}
                    {/* <div style={{}}> */}
                    <ChartsSplits

                      trackdata={trackdata}
                      userSplit={userTrack}
                      userTrackLangth={userTrack.length > 0}
                      playCount={playCount}
                      repostCount={repostCount}
                      chartTime={chartTime}
                      chartType={chartType}
                      handlerSelectedTime={async (time: any) => {

                        setChartTime(time)
                        await getFilterTrack();
                      }}
                      handlerSelectedFilter={async (value: any) => {


                        setChartType(value);
                        await getFilterTrack();
                      }}

                      graphCategory={graphCategory}
                      graphdata={graphdata}


                    />
                    {/* </div> */}
                  </div>
                </Grid>
                {/* <ChartPaper classes={classes}>
                    <ChartsMessage />
                  </ChartPaper> */}
                <ChartsComments trackId = {trackdata}/>
                <ChartsMessages 
                currentUserID = {user_id} 
                trackId={trackdata}
                scrollToBottomParent={scrollToBottom}
                />
              </Grid>
            </Box>
          )}
        </Content>
      </Layout>
      {/* </div> */}
    </>
  );
};
// Customizable Area End