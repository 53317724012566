// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { HISTORY } from "components/src/common";
const user_type: any = localStorage.getItem("user_type");

export interface Props {
    webStyle:any;
    rightsideSelectedtab:string;
    handleAudiovideotab:(tab:string)=>void;
    usertype:string;
    isLoading:boolean;
    tracklistData:any[];
    isListener:boolean;
    streamedArtist:string;
}

interface S {
    usertype: string;
    tabs:Tabs[]
}

interface SS {
  id: any;
}
export interface Tabs {
    month: string; 
   }

export default class StreamRepostparentController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
        usertype:localStorage.getItem("user_type") || "",
        tabs:[{month:"This Month"},{month:"July '24"},{month:"Aug '24"},{month:"Sep '24"},{month:"Oct '24"},{month:"Nov '24"},{month:"Dec '24"},{month:"Jan '24"},{month:"Feb '24"},{month:"March '24"},{month:"April '24"}],
    
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  RedirectSelfProfile=()=>{
  const userTloggedin: any = localStorage.getItem("user_type");
   const loggedIn_userId: any = localStorage.getItem("loggedIn_userId");
   if (userTloggedin?.includes("listener")) {
     HISTORY.push({
       pathname: `/listeners/activity?listener_id=${loggedIn_userId}`,
     })
   } 
    else if (userTloggedin?.includes("label")) {
     HISTORY.push({
       pathname: `/labels/creatives?labels_id=${loggedIn_userId}`,
     })
   }
   else if(userTloggedin?.includes("creative")){
     HISTORY.push({
       pathname: `/creatives/creatives?creative_id=${loggedIn_userId}`,
     })
   }
 }

}
StreamRepostparentController.contextType = AudioContextApi;
// Customizable Area End