// Customizable Area Start
import React, { useState } from "react";
import { AccountTypeBackground, AccountTypeShade, nxzborderlogo } from "./assets";
import { Link } from "react-router-dom";
import "../assets/Accounttype.css";
import { Grid, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function AccountTypes() {
  const [subscriptionClicked, setSubscriptionClicked] = useState("")
  const [currentSubscription, setCurrentSubscription] = useState("")
  const history = useHistory();
  const accountType = [
    {
      id: 1,
      subscriptionName: "Listener",
      description: "Stream Unlimited Songs, and Videos"
    },
    
    {
      id: 2,
      subscriptionName: "Creative",
      description: "Upload Music, Videos, and Monetize"
    },
    {
      id: 3,
      subscriptionName: "Label",
      description: "Manage Catalog, and Make Deals"
    }


  ]
  return (
    <Grid container className="bgDark" style={{ height: "100%" }}>
      <Grid item lg={6} sm={6} xs={12} className="bgImg-account" >
        <img className="image1" src={AccountTypeBackground} />
        <img className="image2" src={AccountTypeShade} />
      </Grid>
      <Grid container item lg={6} sm={6} xs={12} className="account-type-grid">
        <Grid item xs={12} className="logo-acc-grid">
        <div style={{
            borderRadius: "50%",
            border: "1px solid white"
          }}>
            <img src={nxzborderlogo} alt="App Logo" className="nxzborderlogo" />
          </div>
          <Grid item xs={12} className="width_100 text_white  font_25 width_100 font_W_600 select-acc-type" >
            Select Account Type
          </Grid>
        </Grid>
        <Grid container spacing={3} className="account-type-subscription" style={{ flexGrow: 1, gap: 10 }} >
          {accountType.map((item: any) => {
          
            return (
              <Grid item xs={8} className={subscriptionClicked == item.id ? "subscription-list-acc-active" : "subscription-list-acc"} zeroMinWidth
                onClick={() => {
                  setSubscriptionClicked(item.id)
                  setCurrentSubscription(item.subscriptionName)
                }}
              >
                <Box style={{
                  color: "white",
                  fontSize: "22px",
                  fontWeight: "bold"
                }}

                >
                  {item.subscriptionName}
                </Box>
                <Box style={{
                  color: "#8f92a1",
                  fontSize: "13px",
                  fontWeight: 500
                }}>
                  {item.description}
                </Box>
              </Grid>
            )
          })}

        </Grid>
        <Grid container xs={12} zeroMinWidth className="acc-next-back-parent">

          <Grid item xs={8} sm={6} style={{width:"100%"}}>
            <button className="acc-next-btn" disabled={subscriptionClicked == ""}
              onClick={() => {
                history.push(`/${currentSubscription}`);
                localStorage.setItem("accountType", currentSubscription.toLowerCase());
              }}
            >Next</button>
          </Grid>
          <Grid item xs={8} sm={6} style={{width:"100%"}} onClick={() => history.goBack()}>
          <button className="acc-back-btn">

            Back
          </button>
            </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}
// Customizable Area End
