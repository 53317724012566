// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { HISTORY } from "components/src/common";
export const configJSON = require("./config.js");
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

export interface Props {
  isRecived: boolean;
  avtar: string;
  index: number;
  senderName: string;
  timetoken: string;
  text: string;
  userId: string;
  particularUserDataRes: any;
  isChartMessage?: any;
  onRedirect: (currentuserType: any, currentUserId: any) => void;
  trackType: string;
}
interface S {
  userData: any;
  imageList: any;
  isListner: boolean;
  token: any;
  playlistTracks: any;
}
interface SS {
  id: any;
}

export default class ProfileMessageCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  apigetUserCallId: string = "";
  apigetUserDataCallId: string = "";
  apigetPlaylistDetails: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      userData: "",
      imageList: [],
      isListner: false,
      token: localStorage.getItem("token"),
      playlistTracks: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.props.trackType == "playlist") {
      this.getPlaylistDetails(this.props.userId);
    } else {
      this._getUserData();
    }
  }

  _getUserData = () => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetUserCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_profile/user_profile?id=` +
        this.props.userId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    let {
      handlePlayList,
      setHandleAudioVideo,
      handleAudioVideo,
    } = this.context;
    if (handleAudioVideo === 1 || handleAudioVideo === 0) {
      setHandleAudioVideo(2);
      handlePlayList(item, index, songsList, trackName);
    } else {
      setHandleAudioVideo(1);
    }
  };

  onTrackClick=(item:any)=>{
    if(this.state.userData.attributes.playlist_type.includes("audio")){
      this.topPlayMusic(
        item,
        2,
        this.state.playlistTracks,
       "tracks"
    
    
        )
    }else{
      this.redirectVideo(item)
    }
  }

  redirectVideo = (item: any) => {
    HISTORY.push({
      pathname: "/home/Charts/videos",
      state: {
        video_list: item.attributes.video_file,
        activeNo: "5",
        data: item,
        currentList: this.state.userData
          ? this.state.userData.attributes?.playlist_items?.data
          : [],
      },
    });
  };

  getPlaylistDetails = (playlist_id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetPlaylistDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_playlist/user_play_lists/audio_playlist?play_list_id=${playlist_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserTrackImage = async () => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetUserDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_messages/messages/share_user_profile?sender_id=` +
        this.props.userId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getapigetUserCallIdSuccessCall = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        userData: responseJson.data,
      });
      if (
        responseJson?.data?.attributes?.user_type == "listener" ||
        responseJson?.data?.attributes?.user_type == "listener_plus"
      ) {
        this.setState({ isListner: true });
      } else {
        this.getUserTrackImage();
      }
    }
  };

  getUserDataApiSuccessCall = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        imageList: responseJson.data,
      });
    }
  };

  handlePlayListDetails = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.play_list?.data) {
      this.setState({
        playlistTracks:
          responseJson.play_list.data?.attributes?.playlist_items.data,
          userData:responseJson.play_list.data
      });
    }else{
      this.setState({
      playlistTracks:[]
      })
    }
    }
  };

  redirectProfile = (userType: any) => {
    const userId: any = localStorage.getItem("user_id");
    localStorage.setItem("temp_id", userId);
    localStorage.removeItem("user_id");
    localStorage.setItem("user_id", this.props.userId);
    localStorage.setItem("creatives_screen", "true");

    if (userType?.includes("creative")) {
      HISTORY.push(`/creatives/beats?creative_id=${this.props.userId}`);
    } else if (userType?.includes("listener")) {
      HISTORY.push(`/listeners/activity?listener_id=${this.props.userId}`);
    } else {
      HISTORY.push(`/originals/beats?originals_id=${this.props.userId}`);
    }
  };

  handleTrackAndVideo = () => {
    HISTORY.push(`/ctrl/playlist`);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apigetUserCallId) {
        this.getapigetUserCallIdSuccessCall(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetUserDataCallId) {
        this.getUserDataApiSuccessCall(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId == this.apigetPlaylistDetails) {
        this.handlePlayListDetails(responseJson);
      }

      //this.setState({loading: false})
    }
  }
}
ProfileMessageCardController.contextType = AudioContextApi;
// Customizable Area End
