// Customizable Area Start
import React from "react";
import { Row, Form, Input } from "antd";
import {atSign} from "../assets";
import "../../assets/listener.css";

interface Props{
}

export const UserNameListenerCreativeLabel = (props: Props) => {

    return(
        <>
         <Row className="fullname_acc">
                        Username <span className="change-username">(cannot be changed)</span>
                        <Form.Item
                          label="Username will show on your public profile"
                          name="username"
                          className="account_sub_title"

                          rules={[
                            {
                              required: true,
                              message: "Please Input Your Username!",
                            },
                            {
                              pattern: /^[a-z0-9_@.\-]{2,25}$/,
                              message:
                              "Username must have lowercase and contains 2-25 character only",
                            },
                          ]}
                        >
                          <Input
                            prefix={<img src={atSign} style={{ width: "100%" }} />}
                            placeholder="Ex: nxzsound"
                          />
                        </Form.Item>
                      </Row>
        </>
    )

}