// Customizable Area Start
import { Avatar, Button, Col, Row, Spin, Form, Modal, Space, Input, Select } from "antd";
import React, { useState } from "react";
import {
  album3,
  following,
  playButton,
  pauseA,
  pplays,
  request,
  reshare,
  defaultProfile,
  nxzloader,
} from "../../../dashboard/src/assets";
import "./playlistCard.css";
import { UserAddOutlined, LoadingOutlined,CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import millify from "millify";
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
import axios from "axios";
import LoginAlert from "components/src/CustomChart/PopUps/LoginAlert.web";
import { openNotification } from "../../../../components/src/Notification.web";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";

import ScanQrDialog from "components/src/CustomChart/PopUps/ScanQrDialog.web";


const PlayListCard = (props: any) => {
  const [openPopup, setOpenPopup] = React.useState(false);
  const history = useHistory();

  const token: any = localStorage.getItem("token");
  const playListType: any = localStorage.getItem("playlist_type");
  const artist_id: any = localStorage.getItem("artist_id");
  const user_id: any = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false)
  const [userplaylist, setUserplaylist] = useState<any>("")
  const [emptyPlaylist, setEmptyPlaylist] = useState(false)
  const [imageUrl, setimageUrl] = useState<any>('')
  const [fileName, setfileName] = useState<any>('')
  const [playlistId, setPlaylistId] = useState<any>("")
  const [playlistTitle, setplaylistTitle] = useState<any>('')
  const [openAlertLogin, setopenAlertLogin] = useState(false)
  const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false)
  const [open, setOpen] = useState(false);
  const [openRepostPopup, setOpenRepostPopup] = React.useState<boolean>(false)
  const [openPopUp, setOpenPopUp] = React.useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = React.useState<any>('')
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const { Option } = Select;


  const handleAddToPlaylist = () => {
    if (token) {

      setOpenPlaylistModal(true)
      getUserPlaylist()
    } else {
      setopenAlertLogin(true)
      setDialogTextAlertLogin(token ? "1" : "2")
    }
  }

  const getUserPlaylist = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_playlist/play_lists`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          
          token: token,
        },
      })
      .then((response: any) => {
        setLoading(false);
        setUserplaylist(response.data.data);

      })
      .catch((err: any) => {
        setLoading(false);
        if (err?.response?.status === 422) {
          setEmptyPlaylist(true);
        }
      });
  };

  function onChange(value: any) {
    setPlaylistId(value);
  }
{/* @ts-ignore */}
  const antIcon = <LoadingOutlined style={{ fontSize: 18, color: 'antiquewhite' }} spin translate />;

  const playMusic = () => {
    if (!props.handleplay) {
      props.setHandlePlay(true)
      const song: any = props.playList?.attributes?.playlist_items?.data[0]
      props.handlePlayList(song, props.playList, 0)
      props.setHandleAudioVideo(2)

    } else {
      props.setHandleAudioVideo(1)
      props.setHandlePlay(false)

    }
  }

  const redirectHandle = async (id?: any) => {
    if (token) {
      const user_id: any = localStorage.getItem("user_id")
       localStorage.setItem("temp_id", user_id)
       localStorage.removeItem("user_id")
       localStorage.setItem("user_id", id)
       localStorage.setItem("creatives_screen", "true")
      history.push({
        pathname: `/creatives/beats?creative_id=${id}`,
        // state: { user_id: id }
      })
    }
  }

  const handleClosePlaylistModal = () => {
    form.resetFields();
    form1.resetFields();
    setimageUrl('')
    setOpenPlaylistModal(false)
  }

  const handleAddTrackToPlaylist = async (values: any) => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_playlist/merge_playlists?current_playlist_id=${props.playList.id}&play_list_id=${playlistId}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          
          token: token,
        },
      })
      .then((res) => {
        openNotification("This song is successfully added in playlist", "Success")
        setOpenPlaylistModal(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl('')

      })
      .catch((err: any) => {
        if (err?.response?.status === 409) {

          openNotification(err.response.data.data[0].message, "Error")
        } else if (err?.response?.status === 400) {
          openNotification(err?.response?.data?.errors[0]?.token, "Error")
        } else {
          openNotification(err.response.data.message, "Error")
        }
      }).finally(() => {
        setOpenPlaylistModal(false);
        setLoading(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl('')
      })
    setimageUrl('')
  };

  const fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {

    let target = e.target as HTMLInputElement;
    let files = target.files as any;

    if (files.length !== 0) {

      let url = URL.createObjectURL(files[0])
      setimageUrl(url)
        setfileName(files)
      
    }
  };

  function onSearch(val: any) {
    console.log('search:', val);
  }

  const UploadPlayLists = async (fileName: any) => {
    setLoading(true)
    const token: any = localStorage.getItem("token")
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
      
      token: token,
    }
    if (fileName && playlistTitle) {
      let formData = new FormData();
      for (const key of fileName) {
        //formdata object
        formData.append('name', playlistTitle);
        formData.append('add_track_id', props.playList.id);
        formData.append('cover_image', key);
      }

      //@ts-ignore
      document.getElementById('createPlayListBtn')?.setAttribute('disabled', true)
      document.getElementById('createPlayListBtn')?.setAttribute('style', 'background :#1890ff; color:#fff; width:100%;')
      await axios.post(`${baseUrl}/bx_block_playlist/play_lists`, formData, { headers })
        .then(async (response: any) => {
          openNotification('New PlayList Added Successfully', "Success")
          await axios
            .get(`${baseUrl}/bx_block_playlist/merge_playlists?current_playlist_id=${props.playList.id}&play_list_id=${response.data.data.id}`, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                
                token: token,
              },
            })

            .then((res) => {
              // openNotification("This song is successfully added in playlist", "Success")
              setOpenPlaylistModal(false);
              form1.resetFields();

            })
            .catch((err) => {
              console.log("error")
            }).finally(() => {
              setOpenPlaylistModal(false);
              form.resetFields();
              form1.resetFields();
              setimageUrl('')
            })
        }).catch((err: any) => {
          switch (err?.response?.status) {
            case 409:
              openNotification(err?.response?.data?.data[0]?.message, "Error");
              break;
            case 422:
              openNotification(err?.response?.data?.errors[0]?.name, "Error");
              break;
            case 400:
              openNotification(err?.response?.data?.errors[0]?.token, "Error");
              break;
            default:
              console.log("error in footer");
              break;
          }
        }).finally(() => {
          setLoading(false)
          form.resetFields();
          form1.resetFields();
          setimageUrl('')
        })
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlertLogin = () => {
    setopenAlertLogin(false);
  };


  const userType = localStorage.getItem('user_type')
  const subscriptionPlan = localStorage.getItem("subscription_plans")

  const onShare = () => {
    const token: any = localStorage.getItem("token")
    if (token) {
      if (userType == "listener" && subscriptionPlan == "NXZ") {
        setopenAlertLogin(true)
        setDialogTextAlertLogin("3")
      } else {
        setOpenPopup(true)
      }
    } else {
      setopenAlertLogin(true)
      setDialogTextAlertLogin("2")
    }
  }

  const handleRepostClose = () => {
    setOpenRepostPopup(false)
  }

  const respostSubmit = () => {
    props.handleRepostPlaylist(props.id, textFieldValue)
    handleRepostClose()
  }

  const handleCaptionChange = (e: any) => {
    console.log(e.target.value, "caption")
    setTextFieldValue(e.target.value)
  }
  const renderCount = (icon:any, count:any) => (
    <div>
      <img src={icon} alt="" />
      <Row justify="center" className="text_white" style={{ fontFamily: "Poppins" }}>
        {count ? millify(count) : "0"}
      </Row>
    </div>
  );


   const renderPlayIcon = () => {
    if (props.playList.attributes?.playlist_items?.data.length > 0) {
      return (
        <div className="play-icon_playlist" onClick={playMusic}>
          <img src={props.handleplay ? pauseA : playButton} alt="" />
        </div>
      );
    }
    return null;
  };

  const renderFollowButton = () => {
    if (playListType === "Public" && artist_id !== user_id) {
      return (
        <div className="ml-1">
          <Button
            onClick={props?.handleFollowStatus ? props.handleUnfollow : props.handleFollow}
            className="center_div"
            style={{
              height: "30px",
              border: "0px solid",
              width: "70px",
              borderRadius: "17px",
              backgroundColor: props?.handleFollowStatus ? "#5b5b5b" : "#096dd9"
            }}
          >{/* @ts-ignore */}
            {loading ? <Spin indicator={antIcon} /> : <UserAddOutlined style={{ color: "white", fontSize: "16px" }} translate={true}  />}
          </Button>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <Row style={{
        padding: "15px",
        marginTop: "6px",
        borderRadius: "15px",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
        border: "solid 1px #1f1f1f",
        backgroundColor: "#121212",
        width: "90%",
        margin: "0 auto"

      }} className=" height_playlistCard width_100"
      >
        <div className="width_100 profileDetails_bottom">
          <div className="p-img playlist_img_center">
            <img className="cover" style={{ borderRadius: "12px", height: "100%" }}
              src=
              {props?.cover_image || album3}
              alt="" />
             {renderPlayIcon()}
          </div>
          <div className="p-desc  playlist_desc_center width_100" style={{ marginTop: "-11px" }}>
            <Row className="width_100">
              <Col span={24}>
                <Row style={{ color: "#3959f9", }} className="playlistName_hidden font_22 width_100">
                  {props?.playlistName}
                </Row>
                <Row className="font_14 text_white1 width_100 mb_1" >
                  {props?.song_count ? millify(props?.song_count) : "0"}
                  {" "} Tracks</Row>
              </Col>
            </Row>

            <Row justify="space-between" className="p-profile hidden_profileName width_100">
              <Col xs={24}  md={10} lg={11}>
                <Row className="width_100">
                  <Col onClick={() => redirectHandle(props.artist_id)} style={{ cursor: 'pointer' }}>
                    <Avatar className="mr_1" style={{ height: "40px", width: "40px" }} src={ props?.avatar || album3} alt="" />
                  </Col>
                  <Col onClick={() => redirectHandle(props.artist_id)} style={{ cursor: 'pointer' }}>
                    <Row className="font_14 text_white">
                      {props?.artist_name}
                    </Row>
                    <p id="artist">{props?.user_type}</p>
                  </Col>
                  <Col>
                   {renderFollowButton()}
                   </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={10} lg={12} className="p-icons" >
              {renderCount(pplays, props?.play_count)}
  {renderCount(request, props?.following_count)}
  {renderCount(following, props?.followers_count)}
  {renderCount(reshare, props?.repost_count)}
              </Col>
            </Row>

            <Row align="middle" justify="space-between"
              className=" button-group"
            >
              <Col className="mb_1">
                <Button className="mr_05" style={{ marginTop: 5, fontFamily: "Poppins" }} onClick={() => {
                  setOpenRepostPopup(!openRepostPopup)
                  setOpenPopUp(true)
                }
                }>Repost</Button>
                <Button className="mr_05" style={{ marginTop: 5, fontFamily: "Poppins" }} onClick={onShare}>Share</Button>
                <Button className="mr_05" style={{ marginTop: 5, fontFamily: "Poppins" }} onClick={handleAddToPlaylist} >Add to Playlist</Button>
              </Col>
            </Row>
          </div>
        </div>
        <ScanQrDialog
          openPopup={openPopup}
          shareItem={"Playlist"}
          handleOpenPopup={() => setOpenPopup(false)}
        />
      </Row>
      {open && <LoginAlert open={open} handleClose={handleClose} dialogText={dialogTextAlertLogin} />}
      {openAlertLogin && <LoginAlert open={openAlertLogin} handleClose={handleCloseAlertLogin} dialogText={dialogTextAlertLogin} />}

      {
        openPlaylistModal ? (<>
          <Row className="modal_playlist">
            <Modal
              centered
              className='admin-settings-pwd-update-modal'
              destroyOnClose
              footer={false}
              visible={openPlaylistModal}
            >
              {loading ? <Row className="mt-6  width_100" align="middle" justify="center" style={{
                height: "250px"
              }}>
                <Col>
                  {" "}
                  <img src = {nxzloader}  style={{width:"60%"}} />
                </Col>
              </Row> : (<>

                <Row
                  className="font_25 font_W_400 mb_1"
                  align="middle"
                  justify="center"
                >
                  <Col span={22} style={{ textAlignLast: "center" }}>
                    {" "}
                    Add to Playlist
                  </Col>{" "}
                  <Col span={2}>
                    {/* @ts-ignore */}
                    <CloseCircleOutlined
                      onClick={handleClosePlaylistModal}
                     
                    />
                  </Col>
                </Row>
                <Row
                  // className="highlight-head"
                  className="width_100"
                  justify="center"
                  align="middle"
                >
                  {console.log(userplaylist.length, 'userplaylist')}
                  {!emptyPlaylist &&
                    <Form
                      form={form}
                      className="width_100 selectPlaylist title-input login_form"
                      onFinish={handleAddTrackToPlaylist}
                    >
                      <Form.Item name="select_playlist" className=" width_100 mb_4 mt-3"
                        rules={[{
                          required: true,
                          message: "Please Select Your Playlist"
                        }]}
                      >
                        <Select className="width_100"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select playlist"
                          onChange={onChange}
                          onSearch={onSearch}
                          dropdownStyle={{

                          }}
                          filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >

                          {userplaylist &&
                            userplaylist.map((ind: any) => (
                              <Option value={ind?.id} key={ind?.id} >{ind?.attributes?.name}
                              </Option>
                            ))
                          }
                        </Select>

                      </Form.Item>


                      <Form.Item className="width_100 mt-5" >
                        <Row justify="center">  <Button className='mt-5  width_100 red_custom_btn'
                          htmlType="submit"
                        >ADD</Button></Row>
                      </Form.Item>
                      <Row align="middle" justify="center" className="text_white font_15">or</Row>

                    </Form>



                  }
                  <Form
                    form={form1}
                    className="width_100 selectPlaylist"
                    onFinish={() => UploadPlayLists(fileName)}
                  >
                    <Row className="text_white1 font_16 mb_1">
                      Add new playlist
                    </Row>
                    <Form.Item
                      // label="Username"
                      className="width_100 "
                      name="title"
                      rules={[{ required: true, message: 'Please Add Playlist.' }]}
                    >


                      <Input
                        className="mui_input"
                        placeholder="New Playlist"

                        onChange={(e: any) => {
                          setplaylistTitle(e.target.value)
                        }}
                        name="newPlaylist"
                        style={{ width: '100%' }}

                      />
                    </Form.Item>
                    <Form.Item
                      // label="Username"
                      className="width_100 mt-1"
                      name="fileName"
                      rules={[{ required: true, message: 'Please Upload Cover Art.' }]}
                    >
                      <Space style={{ textAlign: 'left' }} >
                        <input
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                          // name="profilePic"
                          style={{ display: "none" }}
                          onChange={(event) => fileUploadHandler(event)}
                        />
                        <label htmlFor="icon-button-file">
                          <div style={{ display: 'flex' }}>
                            <img style={{ height: "48px", width: "48px", borderRadius: '50%', background: '#f5f5f5' }} src={ imageUrl || "https://images.squarespace-cdn.com/content/v1/56e19ec5e3214084d69d4b7d/1473524254173-BGV5W2Z5FM46F67SYZHJ/PlusIcon_Small_Gray.png"} alt="" />
                            &nbsp;&nbsp;
                            <p aria-label="upload picture" style={{ color: '#8f92a1', marginTop: '11px' }}>Upload cover art</p>
                          </div>
                        </label>
                      </Space></Form.Item>
                    <Form.Item className="width_100" >
                      <Row justify="center">
                        <Button type="primary" style={{ marginTop: "17px" }} className='  width_100 red_custom_btn'
                          htmlType="submit"
                        >CREATE A PLAYLIST</Button>
                      </Row>
                    </Form.Item>
                  </Form>
                </Row>
              </>)}
            </Modal></Row>
        </>) : ""
      }

     <RepostPopup open={openPopUp}
        onClose={handleRepostClose}
        profileImage={props.cover_image || defaultProfile}
        artistName={props?.artist_name}
        songName={props?.playlistName}
        repost_count={respostSubmit}
        _handleTextFieldChange={handleCaptionChange}
      />
    </>
  );
};


export default PlayListCard;
// Customizable Area End
