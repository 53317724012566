// Customizable Area Start
import { Carousel, Layout, Row } from "antd";
import React from "react";
import { Grid, Box, Hidden, } from "@material-ui/core";
import './index.css'
import { see_all, nxzloader } from '../assets';
import CreativeBox from 'components/src/CustomChart/PopUps/CreativeBox.web';
import CustomCarousel from 'components/src/CustomChart/PopUps/CustomCarousel.web';
import { AudioContextApi } from '../AudioContext/AudioContext.web';
//@ts-ignore
import {dashboardVideos,settings} from "../../../../components/src/CustomChart/PopUps/CaraouselSetting";
import { Link } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import LoginAlert from "components/src/CustomChart/PopUps/LoginAlert.web";
import MainContentController from "./MainContentController";
import {
  NextButtonOrginal,
  PrevButtonOrginal,
} from "components/src/CustomChart/PopUps/CarouselButtonHandler";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import VideoCard from "../../../../components/src/CustomChart/VideoCard.web";
import VideoPlayer from "components/src/CustomChart/PopUps/VideoPlayer.web";

export default class MainContent extends MainContentController {
  
  seeAllReturn = () => {
    if (this.state.apiToken) {
      if (this.state.userType !== "listener") {
        return (
          <div style={{ display: "flex" }}>
            <img src={see_all} className="dashboard-seeAll" alt="see-all" />
            <Box
              className="home-see-all cursor_pointer"
              style={{ color: "white" }}
              onClick={this.redirectToOriginals}
            >
              {" "}
              SEE ALL
            </Box>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex" }}>
            <img src={see_all} className="dashboard-seeAll" alt="see-all" />
            <Box className="home-see-all" onClick={this.redirectToOriginals}>
              SEE ALL
            </Box>
          </div>
        );
      }
    } else {
      return (
        <div style={{ display: "flex" }}>
          <img src={see_all} className="dashboard-seeAll" alt="see-all" />
          <Box
            className="home-see-all"
            style={{ color: "white" }}
            onClick={this.handleSeeAll}
          >
            SEE ALL
          </Box>
        </div>
      );
    }
  };
  render() {
    const { creativeList, songsList, hooksList, beatsList, versesList, playList, originalList, selected, loading, userType, open, dialogText, handleAudioVideo, banner, apiToken, }: any = this.state;
    const { handlePlayList, isPlaylist } = this.context;
    
    
    return (
      <>
        <div className="dashboard-parent">
          <Layout className="dashboard-layout">
            <Hidden smDown>
              <AsideLeft />
            </Hidden>
            <Content className="dashboard-content">
              <HomeHeader />
              {loading ? (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <img src={nxzloader} style={{ width: "5%" }} />
                </Row>
              ) : (
                <>
                  <div
                    className={
                      isPlaylist
                        ? "width_100 MainBody mainBody-audio-height dashboard-mainBody"
                        : "width_100 MainBody mainContent-withoutaudio dashboard-mainBody"
                    }
                  >
                    {banner?.length && (
                      <CustomCarousel
                        data={banner}
                        handlePreview={this.handleBannerPreview}
                      />
                    )}

                    {originalList.length !== 0 && (
                      <div className="boxes" style={{ marginBottom: "20px" }}>
                        <div className="head_mainContent ">
                          <div>
                            <h2
                              style={{ marginTop: "0px" }}
                              className="dashboard-fonts"
                            >
                              {" "}
                              Originals
                            </h2>
                          </div>
                          {this.seeAllReturn()}
                        </div>
                        <div className="orginal-content  dashboard-originals">
                          {originalList.length !== 0 && (
                            <div>
                              <Carousel
                                ref={this.orginalRef}
                                {...dashboardVideos}
                                infinite={false}
                              >
                                {originalList.length !== 0 && [...originalList] .sort( (a: any, b: any) => b.attributes.play_count - a.attributes.play_count )
                                    .map((video: any, index: number) => {
                                      return (
                                        <div key={video.id}>
                                          <VideoPlayer 
                                            songName={video.attributes?.title}
                                            author={
                                              video.attributes?.artist_name
                                            }
                                            img={video.attributes?.art_work}
                                            playCount={
                                              video.attributes?.play_count
                                            }
                                            trackFile={
                                              video.attributes?.video_file
                                            }
                                            repost_count={
                                              video.attributes?.repost_count
                                            }
                                            cardId={index}
                                            id={video.id}
                                            selected={selected}
                                            setHandleAudioVideo={(value: any) =>
                                            this.handleAudioVideoValue(value)
                                            }
                                            handleAudioVideo={handleAudioVideo}
                                            setSelected={(key: any) => {
                                              this.handleSetSelected(key)
                                            }}
                                            apiToken={apiToken}
                                            currentList={originalList}
                                          />
                                        </div>
                                      );
                                    })}
                              </Carousel>
                            </div>
                          )}
                          {originalList.length > 4 && (
                            <>
                              <PrevButtonOrginal
                                top="40%"
                                left="0%"
                                buttonRef={this.orginalRef}
                              ></PrevButtonOrginal>
                              <NextButtonOrginal
                                top="40%"
                                right="0%"
                                buttonRef={this.orginalRef}
                              >
                                {" "}
                              </NextButtonOrginal>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {creativeList && creativeList?.length !== 0 && (
                      <div className="boxes" style={{ marginBottom: "20px" }}>
                        <div className="head_mainContent mb_1">
                          <div>
                            <h2 className="dashboard-fonts">Creatives </h2>
                          </div>

                          <div>
                            <Box
                              className="home-see-all"
                              style={{ color: "white" }}
                              onClick={this.creativeOnClick}
                            >
                              SEE ALL
                            </Box>
                          </div>
                        </div>
                        <div
                          className="box creative-height"
                          style={{ border: "none", borderRadius: 0 }}
                        >
                          <Grid container className="creative-block scroll-creatives">
                            {creativeList &&
                              creativeList?.length !== 0 &&
                              creativeList.map(
                                (creativeLists: any, index: number) => {
                                  if (index <= 11) {
                                    return (
                                      <Grid
                                        item
                                        md={3}
                                        style={{ marginBottom: 10 }}
                                        className="outer_card_width"
                                      >
                                        <CreativeBox
                                          artistNo={index + 1}
                                          key={index}
                                          id={creativeLists.attributes.id}
                                          avatar={
                                            creativeLists.attributes?.avatar
                                          }
                                          trackCount={
                                            creativeLists.attributes
                                              ?.track_count
                                          }
                                          userName={
                                            creativeLists.attributes?.user_name
                                          }
                                          type={
                                            creativeLists.attributes?.type
                                          }
                                          fullName={
                                            creativeLists.attributes?.user_name
                                          }
                                        />
                                      </Grid>
                                    );
                                  }
                                }
                              )}
                          </Grid>
                        </div>
                      </div>
                    )}

                    {playList.length !== 0 && (
                      <div className="boxes" id="playlist-id">
                        <div className="audio_header_div">
                          <div>
                            <h2 className="mt-1 dashboard-fonts">
                              {" "}
                              Top Playlist
                            </h2>
                          </div>
                          <Link
                            to={{
                              pathname: "/home/Charts/playlists",
                            }}
                          >
                            <Box className="home-see-all">SEE ALL</Box>
                          </Link>
                        </div>
                        <div className="orginal-content dashboard-carousel">
                          {playList.length !== 0 && (
                            <Carousel
                              ref={this.topPlaylistRef}
                              {...settings}
                              infinite={false}
                            >
                              {playList.length !== 0 &&
                                [...playList]
                                  .slice(0, 20)
                                  .map((song: any, index: number) => {
                                    return (
                                      <div
                                        key={song.id}
                                        style={{
                                          width: "fit-content !important",
                                        }}
                                      >
                                        <VideoCard
                                          songName={song.attributes?.name}
                                          author={song.attributes?.artist_name}
                                          img={song.attributes?.cover_image}
                                          playCount={
                                            song.attributes?.play_count
                                          }
                                          key={index}
                                          handlePlayList={() =>
                                            this.hadleRedirectPlaylist(song)
                                          }
                                          type="playlist"
                                          item={song}
                                        />
                                      </div>
                                    );
                                  })}
                            </Carousel>
                          )}
                          {playList.length > this.state.window_width && (
                            <>
                              <PrevButtonOrginal
                                top="40%"
                                left="0%"
                                buttonRef={this.topPlaylistRef}
                              ></PrevButtonOrginal>
                              <NextButtonOrginal
                                top="40%"
                                right="1%"
                                buttonRef={this.topPlaylistRef}
                              >
                                {" "}
                              </NextButtonOrginal>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {songsList.length !== 0 && (
                      <>
                        <div className="boxes" id="playlist-id">
                          <div className="audio_header_div">
                            <div>
                              <h2 className="dashboard-fonts">Top Songs</h2>
                            </div>
                            <Link
                              to={{
                                pathname: "/home/Charts/songs",
                              }}
                            >
                              <Box className="home-see-all">SEE ALL</Box>
                            </Link>
                          </div>
                          <div className="orginal-content dashboard-carousel">
                            {songsList.length !== 0 && (
                              <Carousel
                                // swipeToSlide
                                // draggable
                                ref={this.topSongsRef}
                                {...settings}
                                infinite={false}
                              >
                                {songsList.length !== 0 &&
                                  [...songsList]
                                    .slice(0, 20)
                                    .map((song: any, index: number) => {
                                      return (
                                        <div key={song.id}>
                                          <VideoCard
                                            songName={song.attributes?.title}
                                            author={
                                              song.attributes?.artist_name
                                            }
                                            img={song.attributes?.art_work}
                                            playCount={
                                              song.attributes?.play_count
                                            }
                                            repost_count={
                                              song.attributes?.repost_count
                                            }
                                            key={index}
                                            handlePlayList={() =>
                                              handlePlayList(
                                                song,
                                                index,
                                                songsList,
                                                "songs",
                                                userType
                                              )
                                            }
                                               trackData={song}
                                          type="other"
                                          item={song}

                                          ></VideoCard>
                                        </div>
                                      );
                                    })}
                              </Carousel>
                            )}
                            {songsList.length > this.state.window_width && (
                              <>
                                <PrevButtonOrginal
                                  top="40%"
                                  left="0%"
                                  buttonRef={this.topSongsRef}
                                ></PrevButtonOrginal>
                                <NextButtonOrginal
                                  top="40%"
                                  right="1%"
                                  buttonRef={this.topSongsRef}
                                >
                                  {" "}
                                </NextButtonOrginal>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {beatsList.length !== 0 && (
                      <>
                        <div className="boxes" id="playlist-id">
                          <div className="audio_header_div">
                            <div>
                              <h2 className="dashboard-fonts">Top Beats</h2>
                            </div>

                            <Link
                              to={{
                                pathname: "/home/Charts/beats",
                              }}
                            >
                              <Box className="home-see-all">SEE ALL</Box>
                            </Link>
                          </div>
                          <div className="orginal-content dashboard-carousel">
                            <Carousel
                              ref={this.topBeatRef}
                              {...settings}
                              infinite={false}
                            >
                              {beatsList.length !== 0 &&
                                [...beatsList]
                                  .slice(0, 20)
                                  .map((song: any, index: number) => {
                                    return (
                                      <div key={song.id}>
                                        <VideoCard
                                          songName={song.attributes?.title}
                                          author={song.attributes?.artist_name}
                                          img={song.attributes?.art_work}
                                         trackData={song}
                                          playCount={
                                            song.attributes?.play_count
                                          }
                                          key={index}
                                          track_file={
                                            song.attributes?.track_file
                                          }
                                          repost_count={
                                            song.attributes?.repost_count
                                          }
                                          handlePlayList={() =>
                                            handlePlayList(
                                              song,
                                              index,
                                              beatsList,
                                              "beats",
                                              userType
                                            )
                                          }
                                          type="other"
                                          item={song}
                                        ></VideoCard>
                                      </div>
                                    );
                                  })}
                            </Carousel>
                            {beatsList.length > this.state.window_width && (
                              <>
                                <PrevButtonOrginal
                                  top="40%"
                                  left="0%"
                                  buttonRef={this.topBeatRef}
                                ></PrevButtonOrginal>
                                <NextButtonOrginal
                                  top="40%"
                                  right="1%"
                                  buttonRef={this.topBeatRef}
                                >
                                  {" "}
                                </NextButtonOrginal>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {hooksList.length !== 0 && (
                      <>
                        <div className="boxes" id="playlist-id">
                          <div className="audio_header_div">
                            <div>
                              <h2 className="dashboard-fonts">Top Hooks</h2>
                            </div>
                            <Link
                              to={{
                                pathname: "/home/Charts/hooks",
                                state: { activeNo: "2" },
                              }}
                            >
                              <Box className="home-see-all">SEE ALL</Box>
                            </Link>
                          </div>
                          <div className="orginal-content dashboard-carousel">
                            <Carousel
                              ref={this.topHooksRef}
                              {...settings}
                              infinite={false}
                            >
                              {hooksList.length !== 0 &&
                                [...hooksList]
                                  .slice(0, 20)
                                  .map((song: any, index: number) => {
                                    return (
                                      <div key={song.id}>
                                        <VideoCard
                                          songName={song.attributes?.title}
                                          author={song.attributes?.artist_name}
                                          img={song.attributes?.art_work}
                                          playCount={
                                            song.attributes?.play_count
                                          }
                                          repost_count={
                                            song.attributes?.repost_count
                                          }
                                          key={index}
                                         
                                            trackData={song}
                                          handlePlayList={() =>
                                            handlePlayList(
                                              song,
                                              index,
                                              hooksList,
                                              "hooks",
                                              userType
                                            )
                                          }
                                          type="other"
                                          item={song}
                                        ></VideoCard>
                                      </div>
                                    );
                                  })}
                            </Carousel>
                            {hooksList.length > this.state.window_width && (
                              <>
                                <PrevButtonOrginal
                                  top="40%"
                                  left="0%"
                                  buttonRef={this.topHooksRef}
                                ></PrevButtonOrginal>
                                <NextButtonOrginal
                                  top="40%"
                                  right="1%"
                                  buttonRef={this.topHooksRef}
                                >
                                  {" "}
                                </NextButtonOrginal>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {versesList.length !== 0 && (
                      <>
                        <div className="boxes" id="playlist-id">
                          <div className="audio_header_div">
                            <div>
                              <h2 className="dashboard-fonts">Top Verses</h2>
                            </div>
                            <Link
                              to={{
                                pathname: "/home/Charts/verses",
                              }}
                            >
                              <Box className="home-see-all">SEE ALL</Box>
                            </Link>
                          </div>
                          <div className="orginal-content dashboard-carousel">
                            <Carousel
                              ref={this.topVersesRef}
                              {...settings}
                              infinite={false}
                            >
                              {versesList.length !== 0 &&
                                [...versesList]
                                  .slice(0, 20)
                                  .map((song: any, index: number) => {
                                    return (
                                      <div key={song.id}>
                                        <VideoCard
                                          trackData={song}
                                          songName={song.attributes?.title}
                                          author={song.attributes?.artist_name}
                                          img={song.attributes?.art_work}
                                          playCount={
                                            song.attributes?.play_count
                                          }
                                          key={index}
                                          repost_count={
                                            song.attributes?.repost_count
                                          }
                                          handlePlayList={() =>
                                            handlePlayList(
                                              song,
                                              index,
                                              versesList,
                                              "verses",
                                              userType
                                            )
                                          }
                                          type="other"
                                          item={song}
                                        ></VideoCard>
                                      </div>
                                    );
                                  })}
                            </Carousel>
                            {versesList.length > this.state.window_width && (
                              <>
                                <PrevButtonOrginal
                                  top="40%"
                                  left="0%"
                                  buttonRef={this.topVersesRef}
                                ></PrevButtonOrginal>
                                <NextButtonOrginal
                                  top="40%"
                                  right="1%"
                                  buttonRef={this.topVersesRef}
                                >
                                  {" "}
                                </NextButtonOrginal>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {open && (
                <LoginAlert
                  open={open}
                  handleClose={this.handleClose}
                  dialogText={dialogText}
                />
              )}
            </Content>
          </Layout>
        </div>
      </>
    );
  }
}
MainContent.contextType = AudioContextApi;
// Customizable Area End
