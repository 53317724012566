// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col, Input, Form, Button, Upload } from "antd";
import './edit-playlist-popup.css'
import EditPlaylistPopupController from './EditPlaylistPopupController.web'
import { EditIcon } from './assets.web';
import { nxzloader } from '../../assets';

export class EditPopup extends EditPlaylistPopupController {

  render() {    
    return (
      <>
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-edit"}}
     >
      {this.state.loading ? (
        <Row className='playlistloaderbox'>
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : 
      (
         <Row justify="center" >
                <Col >
                  <Grid container  className='editplaylisttextbox'>
                  <Grid item className='editplaylisttext' data-test-id="send-sms" >Edit Playlist</Grid>
                  <Grid item className='updateplaylistname' >Update Playlist Name</Grid>
                  <Grid item className='updateplaylistname' >and cover art</Grid>


                  <Grid item style={{width: '100%'}}>
                  <Form
                    name="basic"
                    ref={this.formRef}
                    className="Sms_form"
                    layout="vertical"
                    onFinish={this.updatePlaylist}
                    autoComplete="off"
                    initialValues={{
                      Playlist_cover: this.state.values.Playlist_cover,
                      Playlist_name: this.state.values.playlist_name          
                    }}
                  >
                   <Form.Item
                      name="Playlist_cover"
                      className='editplaylistform'
                    >
                    <Upload
                      name="Playlist_cover"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={this.beforeUpload}
                      customRequest={(options: any) => {
                        this.handleChange(options)

                      }}
                    >
                      {this.state.values.Playlist_cover && 
                      <div className='playlistcoverbox'>
                        <img src={this.state.values.Playlist_cover} alt="avatar" className='playlistcoverimage'/>
                        <div className='playlisteditbox'>
                          <img src={EditIcon} className='playlistediticon'/>
                        </div>
                      </div> 
                      
                      }
                    </Upload>
                    </Form.Item>
                    <Form.Item
                      label="Update Playlist Name"
                      name="Playlist_name"
                     className='formiteminputbox'
                    >
                        <Input  className='formiteminput' />
                    </Form.Item>
                    <Form.Item className='formitembuttonbox'>
                      <Button
                        className="red_custom_btn_sms login_btn mt-1"
                        htmlType="submit"
                        data-test-id="send-button"
                      >
                        Update Playlist
                      </Button>
                    </Form.Item>
                    <Form.Item className='formitembuttonbox'>
                      <Button
                        className="grey_custom_btn"
                        htmlType="button"
                        data-test-id="send-button"
                        onClick={this.props.handleClose}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                </Grid>
              </Grid>
            </Col>
         </Row>
      )
                    }
     </Dialog>
      
                    
      </>
    )
  }
}

export default EditPopup
// Customizable Area End