// Customizable Area Start
import { Box, Button, Hidden, Tooltip } from "@material-ui/core";
import React from "react";
import { styled } from "@material-ui/styles";
import {
  dotsicon,
  Playlist,
  Linkicon,
  Editicon,
  messageSendRound,
  DMRound,
  prevIC,
} from "../../../dashboard/src/assets";
import TrackDetailsController from "./TrackDetailsController";
import MessageLinkPopup from "components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";
import InviteLink from "./Tracks/InviteLink.web";
import AddToQueuePopup from "blocks/dashboard/src/CustomComponent/AddToQueue/AddToQueuePopup.web";




const Trackdetailsfirstbox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "10px",
  "@media (min-width: 1500px) and (max-width: 1600px)": {
    flexWrap: "inherit",
  },
  "@media(max-width: 900px)": {
    flexDirection: "column",
    gap: "20px",
  },
});
const Buttonbox = styled(Box)({
  borderRadius: "20px",
  backgroundColor: "#212121",
  display: "flex",
  justifyContent: "space-between",
  padding: "6px",
  "@media(max-width: 1500px)": {
    width: "100%",
  },
});

const Trackdeatilsfunctionlitybox = styled(Box)({
  display: "flex",
  gap: "20px",
  "@media(max-width: 1500px)": {
    width: "100%",
    justifyContent: "space-between",
  },
});

export default class TrackDetails extends TrackDetailsController {
  render() {
    const {
      track_whole_details,
      openPlaylistModal,
      openInvitationModal,
    } = this.state;
    return (
      <>
        <Hidden only={["lg", "md", "xl", "sm"]}> 
        <Box style={{width:"100%"}}>
        <img src={prevIC} onClick={this.props.setShowDetails} style={{height:"30px",width:'30px'}}/>
        </Box>
         </Hidden>

        {!this.state.is_invite_user ? (
          <Trackdetailsfirstbox>
            <Buttonbox>
              <Box>
                <Button
                  className={
                    this.state.renderPrivate
                      ? "statusbuttonsactive"
                      : "statusbuttons"
                  }
                  style={
                    this.state.renderPrivate
                      ? webStyle.statusbuttonsactive
                      : webStyle.statusbuttons
                  }
                  onClick={this.changestatus.bind(this, "private_track")}
                  data-test-id="states"
                // className="stats_tab"
                >
                  Private
                </Button>
              </Box>
              <Box>
                <Button
                  className={
                    this.state.renderPublic
                      ? "statusbuttonsactive"
                      : "statusbuttons"
                  }
                  style={
                    this.state.renderPublic
                      ? webStyle.statusbuttonsactive
                      : webStyle.statusbuttons
                  }
                  onClick={this.changestatus.bind(this, "public_track")}
                  data-test-id="cities"
                >
                  Public
                </Button>
              </Box>
            </Buttonbox>

            <Trackdeatilsfunctionlitybox>
              <Tooltip placement="top" title="Edit">
                <img src={Editicon} style={webStyle.editimageWithoutcursor} onClick={this.editUpload} />
              </Tooltip>
              <Tooltip placement="top" title="Invite">
                <img
                  src={Linkicon}
                  style={webStyle.editimageCursor}
                  onClick={this.handleOpenInvitation}
                />
              </Tooltip>
              <Tooltip placement="top" title="Add To Playlist">
                <img
                  src={Playlist}
                  style={webStyle.editimageCursor}
                  onClick={this.handleOpenPlaylist}
                  data-test-id="edit-playlist"
                />
              </Tooltip>
              <Tooltip placement="top" title="Redirect to details screen">
                <img
                  src={dotsicon}
                  style={webStyle.editimageCursor}
                  onClick={() => this.handleRedirectDots()}
                  data-test-id="dots-playlist"
                />
              </Tooltip>
            </Trackdeatilsfunctionlitybox>
          </Trackdetailsfirstbox>
        ) : (
          <Trackdetailsfirstbox>
            <Trackdeatilsfunctionlitybox>
              <Tooltip placement="top" title="Add To Playlist">
                <img
                  src={Playlist}
                  style={webStyle.editimageCursor}
                  onClick={this.handleOpenPlaylist}
                  data-test-id="edit-playlist"
                />
              </Tooltip>
              <Tooltip placement="top" title="Comments">
                <img
                  src={messageSendRound}
                  style={webStyle.editimageCursor}
                // onClick={this.handleOpenInvitation}
                />
              </Tooltip>
              <Tooltip placement="top" title="Direct Message">
                <img
                  src={DMRound}
                  style={webStyle.editimageCursor}
                  onClick={this.openDMScreen}
                  data-test-id="dots-playlist"
                />
              </Tooltip>
            </Trackdeatilsfunctionlitybox>
          </Trackdetailsfirstbox>
        )}

        <InviteLink
          title={"Send Invite Link"}
          subTitle={""}
          isOpen={openInvitationModal}
          handleDMClose={() => {
            this.setState({ openInvitationModal: false });
          }}
          handleDelete={this.linkopenPopup}
        />
        <MessageLinkPopup
          id="Profile"
          copiedLinked={this.state.CopiedForLink}
          showMsgModal={this.state.openMessageSendPopup}
          closeMsgModal={this.handleOpenMessageSendPopup}
          closeModalDialog={this.handleOpenMessageSendPopup}
          currentUserId={1}
          onInvitedUser={() => { }}
          track_whole_detailsId={track_whole_details.id}
        />
        <AddToQueuePopup
          id="Queue"
          open={openPlaylistModal}
          handleClose={this.handleClosePlaylist}
          playlistType={this.props.id == "1" ? "video" : "audio"}
          trackData={track_whole_details}
        />
      </>
    );
  }
}

const webStyle = {
  trackdetailsfirstbox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusbuttons: {
    fontSize: "16px",
    fontWeight: 900,
    lineHeight: "1.36",
    color: "#ffff",
    borderRadius: "35px",
    textTransform: "capitalize" as "capitalize",
    width: "137px",
  },
  statusbuttonsactive: {
    fontSize: "16px",
    fontWeight: 900,
    backgroundColor: "#274af4",
    lineHeight: "1.36",
    color: "#ffff",
    borderRadius: "35px",
    textTransform: "capitalize" as "capitalize",
    width: "137px",
  },
  editimageWithoutcursor: {
    width: "60px",
    height: "60px",
    cursor: "pointer",
  },
  editimageCursor: {
    width: "60px",
    height: "60px",
    cursor: "pointer",
  },
};
// Customizable Area End
