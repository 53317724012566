// Customizable Area Start
import { Avatar, Col, Row } from "antd";
import React from "react";
import "./playlistCard.css";
import millify from "millify";
import { album3, followplaylist, playplaylist } from "../../../../dashboard/src/assets";
import { defaultProfile } from "blocks/dashboard/src/assets";

const PlaylistBanner = (props: any) => {
  return (
    <>
      <Row id="update_playlist_image_self_container" style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 66%, rgba(0, 0, 0, 0.9) 90%),url(${props?.cover_image
          ? props?.cover_image
          : album3})`,
       

      }} className=" height_playlistCard width_100 p-container-head"
      >
        <div className="width_100 profileDetails_bottom">
          <div className="p-img playlist_img_center">
            <img className="cover p-banner-artwork" 
              src={
                props?.cover_image
                  ? props?.cover_image
                  : album3}
              id="update_playlist_image_self_cover"
              alt="" />
          </div>
          <div className="p-desc  playlist_desc_center width_100" >
            <Row className="width_100" style={{ display: "flex", alignItems: "center" }}>
              <Col xs={24} sm={24} md={10} lg={11}>
                <Row className="width_100 single-playlist-banner">
                  <Col className="comment_avatar_default">
                    <img 
                    // className="mr_1"
                     style={{ height: "48px", width: "48px" }} 
                    src={props?.avatar ? props?.avatar : defaultProfile} 
            className={props?.avatar?'mediaDMCP-avatar':"default-profile-mediaDMCP"}
                    alt="" />
                  </Col>
                  <Col style={{ cursor: 'pointer', display: "flex", alignItems: "center" }}>
                    <Row className="playlist-video-title">
                      {props?.artist_name}
                    </Row>
                  </Col>

                </Row>

                <Row justify="space-between" className="p-profile hidden_profileName width_100 ">
                  <Col span={24}>
                    <Row id="update_palylist_name_pop" style={{ color: "white", }} className="playlistName_hidden playlist-video width_100 single-playlist-banner">
                      {props?.playlistName}
                    </Row>
                    <Row className="playlist-track-video width_100 mb_1 single-playlist-banner" >
                    {props?.song_count ? millify(props?.song_count) : "0"} {" "} {props.playList?.attributes?.play_list_type=="Audio"?"Tracks":"Video"}</Row>

                  </Col>

                </Row>
              </Col>
              <Col xs={24} sm={24} md={10} lg={12} className="p-icons" >
                <div>
                  <img src={playplaylist} alt="" />
                  <Row justify="center" className="text_white" style={{ fontFamily: "Poppins" }}>

                    {props?.play_count ? millify(props?.play_count) : "0"}
                  </Row>
                </div>
                {/* <div>
                <img src={repeatplaylist} alt="" />
                  <Row justify="center" className="text_white" style={{ fontFamily: "Poppins" }}>
                    {props?.repost_count ? millify(props?.repost_count) : "0"}
                  </Row>
                 
                </div> */}
                {/* <div>
                <img src={messageplaylist} alt="" />
                  <Row justify="center" className="text_white" style={{ fontFamily: "Poppins" }}>
                    {props?.following_count ? millify(props?.following_count) : "0"}
                  </Row>
                  
                </div> */}
                <div>
                <img src={followplaylist} alt="" />
                  <Row justify="center" className="text_white" style={{ fontFamily: "Poppins" }}>
                    {props?.followers_count ? millify(props?.followers_count) : "0"}
                  </Row>
                </div>

              </Col>
            </Row>


          </div>
        </div>

      </Row>


    </>
  );
};


export default PlaylistBanner;
// Customizable Area End
