// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");

export interface Props {
    isOpen: boolean;
    handleDMClose: any;
    handleDMOpen?: any;
    handleDelete?: any;
    title?:any;
    subTitle?:any;
}

interface S {
   
}

interface SS {
  id: any;
}

export default class DeleteTrackController extends BlockComponent<Props, S, SS> {
   
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
    
    };
   

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }
}
// Customizable Area End
