// Customizable Area Start
import { Input, Row,  } from "antd";
import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import TracksController from "./TracksController.web";
import { searchshape } from "../../../../dashboard/src/assets";
import { nxzloader } from "blocks/dashboard/src/assets";
import CtrlFilterPopup from '../Common/CtrlFilterPopup.web'
import MobileRightsidetrack from "./MobileRightsidetrack.web";

const MobileBeatTrackWrapper = styled(Box)({
    display: "flex",
    gap: "30px",
    padding: "0px 50px 0px 27px",
    minHeight: "calc(100% - 4rem)",
    justifyContent: "space-between",
    marginTop: "15px",
    "@media(max-width: 1024px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 13px",
        minHeight: "0px"
    },
});

const MobileTrackleftsidecontainer = styled(Box)({
    width: "46%",
    display: "flex",
    flexDirection: "column" as "column",
    height: "max-content",
    gap: "20px",
    marginBottom: "10px",
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        gap: "20px",
        marginTop: "12px",
    },
});

const MobiletrackTotalSplits = styled(Box)({
    width: "100%",
    color: "white",
    "@media(max-width: 1024px)": {
        height: "auto",
    },
});

const MobileLeftSideSingletrackbox = styled(Box)({
    height: "95px",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    color: "white",
    "&:hover": {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        objectFit: "contain",
        background: "#141414",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        // alignItems:"center"
    },
});


const MobiletrackTrackname = styled(Box)({
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#ffffff",
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    "@media(max-width: 400px)": {
        fontSize: "18px"
    },
});

const MobileTracksinputboxwrapper = styled(Box)({
    borderRadius: "27px",
    padding: "10px",
    background: "#212121",
    position: "relative",
    width: "100%",
    "& .ant-input": {
        backgroundColor: "#212121",
        color: "white",
        width: "88%",
    },
    "& .ant-input-affix-wrapper": {
        background: "none",
        border: "unset",
    },
    "@media(max-width: 1024px)": {
        width: "100%",
        padding: "10px",
        "& .ant-input": {
            width: "50%",
        },
    },
});
const MobileLeftsideAlltracksboxwrapper = styled(Box)({
    overflowY: "auto",
    scrollbarWidth: "none",
    marginTop: "-20px",
    maxHeight: "693px",
    "&::-webkit-scrollbar": {
        display: "none",
        width: "0px",
    },
});

const MobiletrakcLeftsideAlltracksbox = styled(Box)({
    width: "100%",
    "@media(max-width: 1024px)": {
        width: "100%",
    },
});

export default class Mobiletracks extends TracksController {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {
            searchText,
            trackList,
            index,
            top40trackList,
            loading
        } = this.state;
        const { topPlayMusic, getStatusDetails } = this.props;

        return (
            //Merge Engine DefaultContainer
            <>
                {loading ? (
                    <Row
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh",
                        }}
                    >
                        <img src={nxzloader} style={{ width: "5%" }} />
                    </Row>
                ) : (
                    <MobileBeatTrackWrapper>
                        <MobileTrackleftsidecontainer id="yourTopDivId1">
                            <MobileTracksinputboxwrapper >
                                <Input
                                    test-id="test_search_term_id_handle_search_input"
                                    className="check123"
                                    onChange={(text) => this.handleTrackSearch(text)}
                                    value={searchText}
                                    prefix={
                                        //@ts-ignore
                                        <SearchOutlined translate className=" text_white1" />
                                    }
                                    placeholder={`Search ${this.state.threebtnToogle}`}
                                />
                                <img src={searchshape} style={webStyle.searchimage}
                                    onClick={() => {
                                        this.handleOpenFilterPopup()
                                    }}
                                    data-test-id="openFilterpopup"
                                />
                            </MobileTracksinputboxwrapper>
                            <ButtonGroup
                                color="primary"
                                aria-label="outlined primary button group"
                                className="outer-div-toggle btnGroup"
                               
                            >
                                <Button
                                    className="audio-button-playlist"
                                    variant="contained"
                                    data-test-id="cities"
                                    value="audio"
                                    disabled={this.state.threebtnToogle === "beats"}
                                    onClick={() => {
                                        this.changetabs("beats");
                                        this.getAllSplits("beat");
                                       
                                    }}
                                >
                                    <span className="audio-text">Beats</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    className="video-button-playlist"
                                    data-test-id="listeners"
                                    
                                    onClick={() => {
                                        this.getAllSplits("hook");
                                        this.changetabs("hooks");
                                    }}
                                    disabled={this.state.threebtnToogle === "hooks"}
                                >
                                    <span className="audio-text">Hooks</span>
                                </Button>

                                <Button
                                    variant="contained"
                                    className="versesButtonPlaylist"
                                    data-test-id="states"
                                    onClick={() => {
                                        this.getAllSplits("verse");
                                        this.changetabs("verses");
                                    }}

                                    disabled={ this.state.threebtnToogle === "verses" }
                                >
                                    <span className="audio-text">Verses</span>
                                </Button>
                            </ButtonGroup>


                            <MobileRightsidetrack
                                showDetails={this.state.showdetails}
                                trackDetailsId={this.state.trackDetailsId}
                                getAllSplits={this.getAllSplits}
                                index={this.state.index}
                                top40trackList={top40trackList}
                                topPlayMusic={topPlayMusic}
                                setShowDetails={this.handleShowDetails}

                            />
                        </MobileTrackleftsidecontainer>
                        {!this.state.showdetails && <>    <MobiletrackTotalSplits>
                            <Typography style={webStyle.trackname}>
                                <span style={webStyle.bluetext}>{trackList?.length}</span> {index}
                            </Typography>
                        </MobiletrackTotalSplits>
                            <MobiletrakcLeftsideAlltracksbox>

                                <MobileLeftsideAlltracksboxwrapper>
                                    {trackList?.length > 0 ? (
                                        trackList.map((item: any, index: any) => {
                                            const { typeColor, status } = getStatusDetails(item, "tracks");

                                            return (
                                                <MobileLeftSideSingletrackbox
                                                    onClick={this.selectedsong.bind(this, index, item.id, item?.attributes?.is_drafted)}
                                                    key={index}
                                                    style={
                                                         index === this.state.selectedIndex
                                                            ? webStyle.leftSideSingletrackbox
                                                            : {}
                                                    }
                                                    id="yourTopDivId"
                                                >
                                                    <Box style={webStyle.singleArtinfo}>
                                                        <img
                                                            style={webStyle.leftimage}
                                                            src={item.attributes?.art_work}
                                                            onClick={topPlayMusic.bind(this,item, index, trackList, "song")}
                                                        />

                                                        <Box style={ webStyle.leftsideownerstats }>
                                                            <MobiletrackTrackname className="tracks_songs_text">
                                                                  {item.attributes.title}
                                                            </MobiletrackTrackname>
                                                            <span style={webStyle.splittext}>
                                                                {item.attributes.user_role}

                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    <Box style={webStyle.leftsideownerstats}>
                                                        <Box>
                                                            <Button
                                                                style={{
                                                                    border: `1px solid ${typeColor}`,
                                                                    borderRadius: "20px",
                                                                    color: typeColor,
                                                                    height: "23px",
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                {status}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </MobileLeftSideSingletrackbox>
                                            );
                                        })
                                    ) : (
                                        <Typography
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: "#fff",
                                                padding: "5",
                                                justifyContent: "center",
                                                marginTop: "10%",

                                            }}
                                        >
                                            Data Not Found.
                                        </Typography>
                                    )}
                                </MobileLeftsideAlltracksboxwrapper>
                            </MobiletrakcLeftsideAlltracksbox>
                        </>
                        }

                    </MobileBeatTrackWrapper>
                )}
                <CtrlFilterPopup
                    open={this.state.openFilterPopup}
                    handleClose={this.handleCloseFilterPopup}
                    currentTab="Tracks"
                    handleTrack={this.handleTrackList}
                />
            </>
            //Merge Engine End DefaultContainer
        );
    }
}

const webStyle = {
    leftsideAlltracksboxwrapper: {
        height: "693px",
        marginTop: "-20px",
        overflowY: "scroll" as "scroll",
    },
    leftimage: {
        minWidth: "75px",
        objectFit: "cover" as "cover",
        height: "75px",
        borderRadius: "10px",
        width: "75px",
    },
    audioimagetext: {
        color: "#F0F0F5",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Karla",
        lineHeight: "20px",
        marginTop: "8px",
    },

    audioartistext: {
        fontSize: "14px",
        fontFamily: "Karla",
        lineHeight: "20px",
        color: "#8f92a1",
        fontWeight: 700,
    },

    singleArtinfo: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        gap: "15px",
       
    },

    leftsideAlltracksbox: {
        width: "85%",
    },

    searchimage: {
        height: "18px",
        width: "21px",
        cursor: "pointer",
        zIndex: 10,
        position: "absolute" as "absolute",
        top: "31%",
        right: "6%",
       
    },

    trackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
       
    },
    statstab: {
        fontSize: "16px",
        fontWeight: 800,
        lineHeight: "1.36",
        color: "#ffff",
        borderRadius: "35px",
        fontFamily: "Inter",
        width: "225px",
        textTransform: "capitalize" as "capitalize",
       
    },
    statstabActive: {
        background: "#3959F9",
    },

    tracksbutton: {
        borderRadius: "20px",
        backgroundColor: "#212121",
        justifyContent: "space-between",
        padding: "6px",
        display: "flex",
       
    },

    singlebutton: {
        border: "1px solid blue",
        borderRadius: "20px",
        color: "blue",
       
    },

    songsinlgebutton: {
        border: "1px solid red",
        height: "23px",
        textTransform: "capitalize" as "capitalize",
        borderRadius: "20px",
        color: "red",
       
    },

    bluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
        lineHeight: "1.29",
        
    },

    splittext: {
        fontSize: "16px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        lineHeight: "20px",
        
    },

    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        alignitems: "center",
        color: "white",
        background: "#141414",
        display: "flex",
        justifyContent: "space-between",
      
    },
    rightSideSingletrackbox: {
        marginTop: "12px",
        height: "54px",
        padding: "0px 14px 0px 5px",
        alignitems: "center",
        color: "white",
        background: "#141414",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
       
    },

    tracksimage: {
        height: "148px",
        width: "100%",
        borderRadius: "12px",
    },
    leftsideownerstats: {
        gap: "10px",
        flexDirection: "column" as "column",
        display: "flex",
        
    },
};
// Customizable Area End
