// Customizable Area Start
import React from 'react';
import { Box } from '@material-ui/core';
import {ArrowUp} from 'blocks/dashboard/src/assets'

const StatCard = ({title, value}: any) =>{
  return (
    <Box style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#141414', padding: '5px 15px',margin: '5px', borderRadius: '10px', width: '50%'}}>
        <Box style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{color: 'gray', fontSize: '12px',fontFamily: 'Inter', fontWeight: 800}}>{title}</div>
            <div style={{color: 'white', fontSize: '24px',fontFamily: 'Inter', fontWeight: 800}}>{value}</div>
        </Box>
        <Box style={{display: 'flex', alignItems: 'center'}}>
            <img style={{height: '35px', width: '35px'}} src={ArrowUp}/>
        </Box>
    </Box>
  )
}

export const LabelStats = (props: any) =>{
    const {labelGraphandStatsData} = props;

    return(
        <>
        <Box style={{width: '100%',height: '100%', flexGrow: 1}}>
            <Box style={{color: 'white', fontSize: '22px',fontFamily: 'Karla', fontWeight: 'bold', margin: '5px'}}>Label Stats</Box>
            <Box style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#141414', padding: '5px 15px', margin: '5px', borderRadius: '10px'}}>
                <Box style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{color: 'gray', fontSize: '12px',fontFamily: 'Inter', fontWeight: 800}}>Revenue</div>
                    <div style={{color: 'white', fontSize: '24px', fontWeight: 800}}>{"0"}</div>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{height: '35px', width: '35px'}} src={ArrowUp}/>
                </Box>
            </Box>
            <Box style={{display: 'flex'}}>
                <StatCard title="Plays" value={labelGraphandStatsData? labelGraphandStatsData?.total_play_count : 0} />
                <StatCard title="Views" value={labelGraphandStatsData?.total_view_count} />
            </Box>
            <Box style={{display: 'flex'}}>
                <StatCard title="Reposts" value={labelGraphandStatsData?.total_repost_count} />
                <StatCard title="Comments" value={labelGraphandStatsData?.total_comment_count} />
            </Box>
            <Box style={{display: 'flex'}}>
                <StatCard title="Creatives" value={labelGraphandStatsData?.total_creatives} />
                <StatCard title="Spilts" value={labelGraphandStatsData?.total_splits} />
            </Box>
        </Box>
        </>
    )
}

// Customizable Area End