import { StyleSheet } from "react-native";
import scale, { verticalScale } from "../../../components/src/Scale";

export default StyleSheet.create({
  container: {
    flex: 1
  },

  bgimg: {
    flex: 1,
    height: scale(900)
  },

  logoview: {
    alignItems: "center",
    justifyContent: "center",
    marginVertical: verticalScale(350)
  },

  logo: {
    height: scale(60),
    width: scale(75)
  },

  projectname: {
    fontSize: scale(30),
    color: "#ffffff",
    marginTop: verticalScale(20),
    fontWeight: "bold"
  }
});
