// Customizable Area Start
import React, { Component } from 'react'
import { Box } from '@material-ui/core';
import AudioVideoInfoCardCP from '../Common/AudioVideoInfoCardCP.web';
import HomeHeaderDropdown from 'components/src/CustomChart/PopUps/HomeHeaderDropdown.web';
import { defaultProfile } from '../../assets';

interface Props {
  selectedMedia: any;
  unreadComments: any;
  selectedTrackMediaDetails: any;
  selectedTrackStatsDetails: any;
  selectedTrack: any;
  handleAddNewUser:any;
  setTrackIndex:any;
  isShowMsgModal:any;
  closeNewMessage:any;
  openNewMessage:any;
}

interface S {
  logged_in_user_profile: any;
  logged_in_user_name: any;
  logged_in_user_type: any;
  profile_image: any;
  user_name: any;
  user_type: any;
}

export class MediaProfileWeb extends Component<Props,S> {
  constructor (props: Props){
    super(props);
    this.state = {
      logged_in_user_profile: localStorage.getItem('profile_img'),
      logged_in_user_name: localStorage.getItem('user_name'),
      logged_in_user_type: localStorage.getItem('user_type'),
      profile_image: '',
      user_name: '',
      user_type: ''
    }
  }
  render() {
    const { logged_in_user_profile, logged_in_user_name,  logged_in_user_type} = this.state;

    return (
        <>
        <div className='CMT-dropdown'>
          <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div className="dropdown-border" style={{}}>
            <HomeHeaderDropdown sizexl={24} dmActive={true}
                anchorEl={null} handleClose={{}} dialogText={""} handleLoginClose={{}} open={false} currentKeyCharts={"3"} setCurrentKeyCharts={{}}
                currentKeyPlaylist={"5"}
                setCurrentKeyPlaylist={{}}
              />
            </div>
          </div>
        
        {
          this.props.selectedTrack != '' ?(
            <>
              <div className='CMNTProfileCP scroll_cp_profile_cmnt'>
        <Box   style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flex: 1, padding: '0px 8px', marginTop: '30px' }}>
        <Box className='CommentChatBox' style={{width: '100%'}}>
            <AudioVideoInfoCardCP
                media={{}}
                mediaType="audio"
                playMedia={(media: any, type: string) => { }}
                viewProfile={() => { }}
                selectedTrackMediaDetails={this.props.selectedTrackMediaDetails}
                selectedTrackStatsDetails={this.props.selectedTrackStatsDetails}
                handleAddNewUser={this.props.handleAddNewUser}
                setTrackIndex={this.props.setTrackIndex}
                isShowMsgModal={this.props.isShowMsgModal}
                closeNewMessage={this.props.closeNewMessage}
                openNewMessage={this.props.openNewMessage}
            />
        </Box >
    </Box> 
    </div>   
    </>
          )
          :(
            <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',height: '100%', justifyContent: 'center'}}>
                <div>
                  <img src={logged_in_user_profile != ('null' || '') ? logged_in_user_profile : defaultProfile} style={{borderRadius: '50%', height: '150px', width: '150px',cursor:"pointer"}}/>
                </div>
                <div style={{marginTop: '10px'}}>
                  <span style={{color: 'white', fontSize: '16px', fontWeight: 900}}>{logged_in_user_name}</span>
                </div>
                <div>
                  <span style={{color: 'gray', fontSize: '16px', fontWeight: 900}}>{`${logged_in_user_type}`.charAt(0).toUpperCase() + `${logged_in_user_type}`.slice(1)}</span>
                </div>

                <div style={{marginTop: '10px'}}>
                  <span style={{color: 'gray', fontSize: '24px', fontWeight: 800}}>You Have <span style={{color: 'blue', fontSize: '22px', fontWeight: 900}}>{this.props.unreadComments}</span></span>
                </div>
                <div>
                  <span style={{color: 'gray', fontSize: '24px', fontWeight: 800}}>New Comments</span>
                </div>
              </div>
            
             </>
          )
        }
      </div>
   
    </>
    )
  }
}

export default MediaProfileWeb
// Customizable Area End