// Customizable Area Start
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';
import AudioVideoInfoCard from '../Common/AudioVideoInfoCard.web';
import { SlideTransition, dialogStyles } from '../Common/Common.web';
import AlertDialogSlide from 'components/src/CustomChart/PopUps/AlertDialog.web';
// Customizable Area End


// Customizable Area Start
interface Props {
    isOpen: boolean;
    media: any;
    closeMediaDialog: () => void;
    selectedTrackMediaDetails: any;
    followUser: (userId: any) => void;
    doUnFollow: (userId: any) => void;
    AlertMessage ?: any;
    openAlertDialog ?: any;
    handleClose ?: () => void;
}
// Customizable Area End

// Customizable Area Start
export const MediaProfileDialog = (props: Props) => {

    const classes = dialogStyles();
    return (
        <Dialog fullScreen open={props.isOpen} onClose={props.closeMediaDialog} TransitionComponent={SlideTransition} className='CommentChatDialog'>
            <AppBar className={classes && classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.closeMediaDialog} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes && classes.title}>
                        Comments
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh', flex: 1, padding: '0px 8px' }}>
                <Box className='CommentChatBox'>
                    <AudioVideoInfoCard
                        media={{}}
                        mediaType="audio"
                        playMedia={(media: any, type: string) => { }}
                        viewProfile={() => { }}
                        selectedTrackMediaDetails={props.selectedTrackMediaDetails}
                        followUser={(userId: any) => {props.followUser(userId)}}
                        doUnFollow ={(userID: any) => props.doUnFollow(userID)}
                    />
                </Box >
            </Box>
            <AlertDialogSlide open={props.openAlertDialog} handleClose={props.handleClose} text={props.AlertMessage} />
        </Dialog >
    );
}
// Customizable Area End


// Customizable Area Start
export default MediaProfileDialog;
// Customizable Area End
