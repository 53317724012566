// Customizable Area Start
export const DealHeaderBg = require("../../assets/DealFlow/deal-header-bg.png");
export const SelectSplitRightBg = require("../../assets/DealFlow/audio_split_right_image.png");
export const SelectSplitPercentageRightBg = require("../../assets/DealFlow/split-percentage-right-bg.png");
export const DealAmountRightBg = require("../../assets/DealFlow/deal_amount_right_bg.png");
export const DealTermRightBg = require("../../assets/DealFlow/deal_term_right_bg.png");
export const OfferPreviewRightBg = require("../../assets/DealFlow/offer_preview_right_bg.png")
export const ImportentNoticeRightBg = require("../../assets/DealFlow/importent_notice_right_bg.png");
export const VerifyDealRightBg = require("../../assets/DealFlow/verify-deal-right-bg.png");
export const OfferSentRightBg = require("../../assets/DealFlow/offer_sent_right_bg.png");
export const UserAvatar = require("../../assets/DealFlow/avaar offer2.png");
export const DealPreviewLeftAvatar = require('../../assets/DealFlow/DealPreviewLeftAvatar.png');
// Customizable Area End