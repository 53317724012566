// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  Accordion,
  AccordionDetails,
  withStyles,
} from "@material-ui/core";
import {defaultProfile, viewreplay } from "../../../../blocks/dashboard/src/assets";
import { TrackComments } from './TrackComments.web'
import { CloseCircleOutlined } from "@ant-design/icons";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordion from "@material-ui/core/Accordion";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { findDuration } from "blocks/dashboard/src/Inbox/Comments/commentTimeConvertion.web";
const urlConfig = require('../../../../framework/src/config');

const baseUrl = urlConfig.baseURL;

export const ChartsComments = (props: any) => {
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "#000000",
      marginBottom: -1,
      flexDirection: "row-reverse",
      justifyContent: "end",
      padding: "0px 0px",
      minHeight: "25px!important" as any,

      "&$expanded": {
        minHeight: "0px",
      },
    },
    content: {
      margin: "0px 12px",
      "&$expanded": {
        margin: "0px 0px",
      },
    },
    expanded: {
      // margin: "20px"
    },
    expandIcon: {
      order: 0,
      margin: "0px -68px",
    },
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles({
    root: {
      padding: "0px 0px",
    },
  })(MuiAccordionDetails);

  const Accordion = withStyles({
    root: {
      "&$expanded": {
        margin: "auto"
      }
    },
    expanded: {}
  })(MuiAccordion);


  // const [comments, setComments] = useState<string>("");
  const token: any = localStorage.getItem('token');
  const [commentsList, setCommentsList] = useState<any>([])
  //   {
  //     key: 0,
  //     name: "@arianna",
  //     image: chartcover,
  //     type: "you",
  //     text: "This %%%",
  //     duration: "10m",
  //     replay: [
  //       {
  //         name: "aan",
  //         duration: "1hr",
  //         image: chartcover,
  //         text: "i am grow",
  //       },
  //       {
  //         name: "arjun",
  //         image: chartcover,
  //         duration: "1hr",
  //         text: "i am grow",
  //       },
  //     ],
  //   },
  //   {
  //     key: 1,
  //     name: "@jasonderulo",
  //     image: chartcover,
  //     type: "you",
  //     text: "on GOD, might have to drop a � on this frfr. #GetMoney",
  //     duration: "2h",
  //     replay: [],
  //   },
  //   {
  //     key: 2,
  //     name: "@djmustard",
  //     image: chartcover,
  //     type: "you",
  //     text: "on GOD, might have to drop a � on this frfr. #GetMoney",
  //     duration: "17d",
  //     replay: [
  //       {
  //         name: "aan",
  //         duration: "1hr",
  //         image: chartcover,
  //         text: "i am grow",
  //       },
  //       {
  //         name: "arjun",
  //         image: chartcover,
  //         duration: "1hr",
  //         text: "i am grow",
  //       },
  //     ],
  //   },
  //   {
  //     key: 3,
  //     name: "Rico Love",
  //     image: chartcover,
  //     type: "me",
  //     text: "With what money....?????",
  //     duration: "1w",
  //     replay: [],
  //   },
  //   {
  //     key: 4,
  //     name: "@rca",
  //     image: chartcover,
  //     type: "you",
  //     text: "on GOD, might have to drop a � on this frfr. #GetMoney",
  //     duration: "42w",
  //     replay: [
  //       {
  //         name: "aan",
  //         duration: "1hr",
  //         image: chartcover,
  //         text: "i am grow",
  //       },
  //       {
  //         name: "arjun",
  //         image: chartcover,
  //         duration: "1hr",
  //         text: "i am grow",
  //       },
  //     ],
  //   },
  //   {
  //     key: 5,
  //     name: "@rca",
  //     image: chartcover,
  //     type: "you",
  //     text: "%%Thanks, I'm glad you liked it. Def was tryna make a vibe.",
  //     duration: "1yr",
  //     replay: [],
  //   },
  //   {
  //     key: 6,
  //     name: "@rocnation",
  //     image: chartcover,
  //     type: "me",
  //     text: "With what money....?????",
  //     duration: "1w",
  //     replay: [
  //       {
  //         name: "aan",
  //         duration: "1hr",
  //         image: chartcover,
  //         text: "i am grow",
  //       },
  //       {
  //         name: "arjun",
  //         image: chartcover,
  //         duration: "1hr",
  //         text: "i am grow",
  //       },
  //     ],
  //   },
  //   {
  //     key: 7,
  //     name: "@rca",
  //     image: chartcover,
  //     type: "you",
  //     text:
  //       "Now the problem is the overflowing, everything seems nice and blobby, except for aaa..., cause it makes the rounded p overflow. It's not just this, but the max-width also does not seem to be working in cases.",
  //     duration: "42w",
  //     replay: [],
  //   },
  //   {
  //     key: 8,
  //     name: "@rocnation",
  //     image: chartcover,
  //     type: "me",
  //     text: "%%Thanks, I'm glad you liked it. Def was tryna make a vibe.",
  //     duration: "2h",
  //     replay: [
  //       {
  //         name: "aan",
  //         duration: "1hr",
  //         image: chartcover,
  //         text: "i am grow",
  //       },
  //       {
  //         name: "arjun",
  //         image: chartcover,
  //         duration: "1hr",
  //         text: "i am grow",
  //       },
  //     ],
  //   },
  //   {
  //     key: 9,
  //     image: chartcover,
  //     name: "@jasonderulo",
  //     type: "you",
  //     text: "This is so good I am hooked!!!",
  //     duration: "10m",
  //     replay: [],
  //   },
  //   {
  //     key: 10,
  //     name: "@username10",
  //     image: chartcover,
  //     type: "you",
  //     text: "I appreciate you frfr. #MuchLove #Respect d",
  //     duration: "1yr",
  //     replay: [
  //       {
  //         name: "aan",
  //         duration: "1hr",
  //         image: chartcover,
  //         text: "i am grow",
  //       },
  //       {
  //         name: "arjun",
  //         image: chartcover,
  //         duration: "1hr",
  //         text: "i am grow",
  //       },
  //     ],
  //   },
  // ]);

  const [addComment, setAddComment] = useState<any>("");

  const [selectedCommentForReply, setselectedCommentForReply] = useState<any>("");

  // const [expand, setExpand] = useState(false);

  const [expanded, setExpanded] = React.useState<number>(0);
  const logged_in_user_profile = localStorage.getItem('profile_img')
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null)

  const handleAccodionChange = (panel: any) => (
    event: any,
    newExpanded: any
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleAddComment = () => {   
    if(addComment.trim() != ''){
      sendNewComment()
    }
  };

  const addNewCommentToTrack = (data: any) => {
    setCommentsList([data, ...commentsList]);
    handleScroll()
    setAddComment("");

  }

  const handleScroll = () =>{
    const element = document.getElementById('scroll-track-more-new-comments')
    if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
    }
  }

  const sendNewComment = () => {
    if(selectedCommentForReply == ''){
      postNewCommentOnTracks()
    }else{
      postReplyOnComment()
      const reply_comment_id = document.getElementById(`comment_id_${selectedCommentForReply?.id}`);
      if (reply_comment_id) {
        reply_comment_id.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }
  }

  const addReplyToComment = (reply: any) => {
    let comments = Object.assign(commentsList, [])
    for (let comment of comments) {
        if (comment.attributes.id === reply.attributes.comment_id) {
            comment.attributes.replies = [reply, ...comment.attributes.replies]
            break;
        }
    }
    return comments;
    }

    const postReplyOnCommetApiSuccessCall = (responseJson: any) => {
      setCommentsList(addReplyToComment(responseJson?.data))
      setselectedCommentForReply("")
      setAddComment("")
      //this.setState({ selectedCommentsList: this.addReplyToComment(this.state.selectedCommentsList, responseJson?.data), selectedCommentForReply: '', newComment: '' })
      }

 const postReplyOnComment = async() =>{
    const data = {
       
        description: addComment,
        comment_id: selectedCommentForReply.id
   
    };  
 
      await axios
      .post(`${baseUrl}/bx_block_activityfeed/replies`, data, {
          headers: {
              "Content-Type": "application/json;charset=UTF-8",
             
              token: token,
          },
      })
      .then((response: any) => {
        postReplyOnCommetApiSuccessCall(response?.data)
      })
      .catch((err: any) => {

      });
  }

  const sendReply = (comment: any) => {
    setselectedCommentForReply(comment);
    inputRef.current?.focus()
    handleReplyscroll()
  }

  const handleReplyscroll = () =>{
    const element = document.getElementById('single_track_input_comment')
    if (element) {
       element.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
    }
  }

  const postNewCommentOnTracks = async() => {
     
    const data = {
       
        description: addComment,
        add_track_id: props.trackId?.id
   
    };
   
    await axios
            .post(`${baseUrl}/bx_block_activityfeed/comments?description=${addComment}&add_track_id=${props.trackId?.id}`, data, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                   
                    token: token,
                },
            })
            .then((response: any) => {
              addNewCommentToTrack(response?.data?.data)
            })
            .catch((err: any) => {

            });  
  }

  useEffect(() =>{
    getTrackComments()
  }, [props.trackId?.id])

  const getTrackComments= async () =>{
    await axios
            .get(`${baseUrl}/bx_block_activityfeed/show_comment?add_track_id=${props.trackId?.id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                   
                    token: token,
                },
            })
            .then((response: any) => {
               setCommentsList(response?.data?.data)
            })
            .catch((err: any) => {

            });
  }

  const redirectToComments = () =>{
     history.push('/comments')
  }
  // const handleAddComment = () => {
  //   const senderComment = {
  //     key: commentsList.length + 1,
  //     username: "@Arianna",
  //     image: chartcover,
  //     type: "me",
  //     msg: comments,
  //     time: "10m",
  //   };

  //   setCommentsList([...commentsList, senderComment]);
  //   setComments("");
  // };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={4}
        xl={4}
        spacing={2}
        className="track_charts"
        style={{position: 'relative',display: 'flex', flexGrow: 1, height: '100%',overflow: 'hidden',}}
      >
        <Paper className={`expand_comment_container`}>
          {/* <Box className=""> */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className="comment_title"
          >
            <Grid item xs={8}>
              <h1 className="comment_font">Comment</h1>
            </Grid>
            <Grid
              container
              item
              xs={4}
              justifyContent="flex-end"
              style={{ paddingRight: "20px" }}
            >
              <h1 className="sellAll_font" style={{cursor: 'pointer'}} onClick={() => redirectToComments()}>See All</h1>
            </Grid>
          </Grid>
          <div className="expand_comment_body">
            <div id = "scroll-track-more-new-comments" ></div>
            {
            commentsList.length > 0 ?
            commentsList.map((item: any, index: any) => {
              return (
                <>
                  {/* <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    direction="row"
                    wrap="nowrap"
                    justifyContent="flex-end"
                    style={{ marginBottom: "15px" }}
                  >
                    <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={comment.type == "you" ? 1 : 3}
                      style={{ marginTop: "4px" }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={chartcover}
                        style={{ width: "42px", height: "42px" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      className="user_comment_msg"
                      // alignContent="space-between"
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid container item xs={9}>
                          <span className="comment_username">
                            {comment.username}
                          </span>
                        </Grid>
                        <Grid container item xs={3} justifyContent="flex-end">
                          <span className="comment_time_font">
                            {comment.time}
                          </span>
                        </Grid>
                        <p className="comment_msg">{comment.msg}</p>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <TrackComments
                    id= "Comment"
                    avatarSrc={item.attributes?.user_image}
                    name={item.attributes?.user_name}
                    duration={findDuration(new Date(item.attributes?.created_at).getTime() / 1000)}
                    text={item.attributes?.description}
                    item = {item}
                  />
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="flex-end"
                    style={{ marginTop: "4px"}}
                  >
                    {item.attributes?.replies[0] ? (
                      <Grid item xs={11} style={{margin: '0px'}}>
                        <Accordion
                          // square
                          expanded={expanded === index}
                          onChange={handleAccodionChange(index)}
                          className="comment_replay_main"
                       
                          style={{margin: '0px'}}
                        >
                          <AccordionSummary
                            expandIcon={
                              <img
                                src={viewreplay}
                                alt="expandUp"
                                className="expandIconUp"
                              />
                            }
                            aria-controls="panel1a-content"
                            IconButtonProps={{ edge: "start" }}
                            id="panel1a-header"
                          >
                            {/* <Grid item xs={3}> */}
                            <div className="replayComment">
                              <span className="repliesFont">
                                {item.attributes?.replies.length} Replies
                              </span>
                              {/* </Grid> */}
                              {/* <Grid item xs={3}> */}
                              <span className="replayFont" onClick={() => sendReply(item)}>Reply</span>
                            </div>
                            {/* </Grid> */}
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: "column" }}>
                            {item.attributes?.replies.map((data: any) => {
                              return (
                                <>
                                  <TrackComments
                                    id="Reply"
                                    avatarSrc={data?.attributes?.user_image}
                                    name={data.attributes?.user_name}
                                    duration={findDuration(new Date(data?.attributes?.created_at).getTime() / 1000)}
                                    text={data?.attributes?.description}
                                    item={item}
                                  />
                                </>
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ) : <>
                      <Grid item xs={11}>
                        <span className="replayFont" style={{marginLeft: '20px'}} onClick={() => sendReply(item)}>Reply</span>
                      </Grid>
                    </>}
                  </Grid>
                </>
              );
            })
            :
            <>
            <div style={{color: 'white', fontSize: '16px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>No Comments Available</div>
            </>
            }

            {/* <Grid
              container
              spacing={2}
              item
              xs={12}
              direction="row"
              wrap="nowrap"
              justifyContent="space-around"
              style={{ marginBottom: "15px" }}
            >
              <Grid
                container
                item
                xs={3}
                justifyContent="flex-end"
                style={{ marginTop: "4px" }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={chartcover}
                  style={{ width: "42px", height: "42px" }}
                />
              </Grid>
              <Grid
                item
                xs={9}
                className="user_comment_msg"
                // alignContent="space-between"
              >
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid container item xs={9}>
                    <span className="comment_username">@arianna</span>
                  </Grid>
                  <Grid container item xs={3} justifyContent="flex-end">
                    <span className="comment_time_font">10m</span>
                  </Grid>
                  <p className="comment_msg">
                    This is my message hdihhd hdhushhdshd uidhshdshdh
                    udihsihdshmlmlmldlsdldlsldlsdldmsmdsmdmsdmsdmmskdm
                  </p>
                </Grid>
              </Grid>
            </Grid> */}
            <div id="last_comment" style={{padding : `${selectedCommentForReply != '' ? '20px' : '0px'}`}}></div>
          </div>
          <div style={{ }} className="bottom_comment_send">
          {
            selectedCommentForReply != '' &&
            <div id="single_track_input_comment" style={{display: 'flex', justifyContent: 'space-between', padding: '10px', backgroundColor: 'black', borderRadius: '10px', width:'100%'}}>
            <div style={{display: 'flex', flexDirection: 'column',width:'100%'}}>
                <div style={{color: 'blue', fontSize: '16px', fontWeight: 'bold'}} >{selectedCommentForReply?.attributes?.user_name}</div>
                <div style={{color: 'white', overflow: 'hidden', textOverflow: 'ellipsis'}}>{selectedCommentForReply?.attributes?.description}</div>
            </div>
              {/* @ts-ignore */}
             <CloseCircleOutlined  translate style={{color: 'white'}} onClick={() => {setselectedCommentForReply("")}}/>
            </div>
         }
            <Grid container  className="comment_send_body">
              <Grid item xs={2} >
              <div 
          className="comment_avatar_default"
          >
                <img
                  alt="Remy Sharp"
                  src={logged_in_user_profile != 'null' ? logged_in_user_profile : defaultProfile}
                  // style={{ width: "42px", height: "42px" }}
              className={logged_in_user_profile != 'null'?'mediaDMCP-avatar':"default-profile-mediaDMCP"}
                
                />
                </div>
              </Grid>
              <Grid item xs={8}>
                <input
                  type="text"
                  ref = {inputRef}
                  placeholder="Leave a comment"
                  className="comment_send_text"
                  value={addComment}
                  onChange={(e: any) => setAddComment(e.target.value)}
                  onKeyPress={(event: any) => {
                    if (event.key === 'Enter') {
                      handleAddComment()
                    }
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <span className="comment_send_btn" onClick={handleAddComment}>
                  Send
                </span>
              </Grid>
            </Grid>
          </div>
          {/* </Box> */}
        </Paper>
      </Grid>
    </>
  );
};
// Customizable Area End