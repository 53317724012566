// Customizable Area Start
import React from 'react';
import CommentDMSelectorController from './CommentDMSelectorController'
import { Box, Button } from '@material-ui/core'
// Customizable Area End


// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export default class CommentDMSelector extends CommentDMSelectorController  {
    render(){
    return (
        <Box className="CDMSBox">
            <Button data-test-id="CMTBtn" className={`CBtn ${this.props.selectedPage === 'comment' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={this.handleCommentSelect}>Comments</Button>
            <Button data-test-id="DMBtn" className={`DMBtn  ${this.props.selectedPage === 'dm' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={this.handleDMSelect}>DM</Button>
        </Box>
    );
}
}
// Customizable Area End


// Customizable Area Start
// Customizable Area End