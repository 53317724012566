// Customizable Area Start
import React, { useContext } from 'react'
import { Grid, Box } from "@material-ui/core";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import './Activity.web.css'
import AudioActivityNew from "./AudioActivityNew.web";
import VideoActivity from "./VideoActivity.web";
import UserActivity from "./UserActivity.web";
import moment from 'moment';
import {  FProfileIcon, RProfileIcon, UProfileIcon } from '../../../../dashboard/src/assets';
import { AudioContextApi } from '../../../../dashboard/src/AudioContext/AudioContext.web';

function ActivityPublicView({ get_All_Activity_data, creativeById,handleNavigation }: any) {
  const findDuration = (fromDate: number) => {
    let myDate = moment(fromDate * 1000).format("YYYY/MM/DD HH:mm:ss");

    if (myDate === "Invalid date") {
      myDate = moment(fromDate, "MM-DD-YYYY HH:mm:ss").format(
        "YYYY/MM/DD HH:mm:ss"
      );
    }

    const date1 = moment(myDate);

    const date2 = moment(new Date());

    const days = date2.diff(date1, "days");

    if (days > 1) {

      return `${days} days`;
    } else if (days == 1) {
      return `${days} day`;
    }


    const hours = date2.diff(date1, "hours");
    if (hours > 0) {
      return `${hours} hr`;
    }
    const minutes = date2.diff(date1, "minutes");
    if (minutes > 0) {
      return `${minutes} min`;
    }
   
    return "Just Now";
  };
  const { handleAudioVideo, setHandleAudioVideo, handlePlayList } = useContext(AudioContextApi);

  const [videos, setVideos] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [selected, setSelected] = React.useState('')
  const [hide, setHide] = React.useState(true)
  const [selectedIndex, SetSelectedIndex] = React.useState(null)
  const topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    if (handleAudioVideo === 1 || handleAudioVideo === 0) {
      SetSelectedIndex(index)
      handlePlayList(item, index, songsList, trackName)
    } else {
      setHandleAudioVideo(1)
    }

  }

  const returnActivity = (item: any, index: any) => {
    let iconComponent;

switch (item.type) {
  case "repost":
    iconComponent = RProfileIcon;
    break;
  case "uploads_track":
    iconComponent = UProfileIcon;
    break;
  default:
    iconComponent = FProfileIcon;
}
    return (
      <Grid item style={{ padding: '10px' }} >
        {
          item.type === "track" && item.attributes.track_upload_type !== "Video" &&
          <AudioActivityNew
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={RProfileIcon}
            creativeById={creativeById}
            PlaySong={() => topPlayMusic(item, index, item, item.attributes.track_upload_type)}
          />
        }
        {
          item.type === "repost" && item.attributes.track_upload_type !== "Video" &&
          <AudioActivityNew
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={RProfileIcon}
            creativeById={creativeById}
            PlaySong={() => topPlayMusic(item, index, item, item.attributes.track_upload_type)}
          />
        }
        {
          item.type === "uploads_track" && item.attributes.track_upload_type !== "Video" &&
          <AudioActivityNew
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={UProfileIcon}
            creativeById={creativeById}
            PlaySong={() => topPlayMusic(item, index, item, item.attributes.track_upload_type)}
          />
        }
        {
          item.attributes.track_upload_type == "Video" &&
          <VideoActivity
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={iconComponent}
                creativeById={creativeById}
                handleAudioVideo={handleAudioVideo}
                setHandleAudioVideo={setHandleAudioVideo}
                selected={selected}
                videos={videos}
                hide={hide}
                setUrl={setUrl}
                setHide={setHide}
                setSelected={setSelected}
                url={url}
                setVideos={setVideos}
                handleNavigation={handleNavigation} key={12}
          />
        }
        {
         item.type === "following"||item.type === "activity_following" &&
          <UserActivity
            data={item.attributes}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            creativeById={creativeById}
            type={FProfileIcon}
          />
        }

      </Grid>
    )
  }
  return (
    <>
      <div className="activity-parentDiv hide_for_mobile_view_activity"
        style={{ flexDirection: "column" }}
      >
        <Box style={{ marginTop: "-11px" }} className="tab-content">
          Activity
        </Box>
        <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 750: 2, 1040:2,1041: 3 }}
          style={{
            width: '100%', 
          }}
          className="main-scroll-activity hide_in_medium-activity"
        >
          <Masonry style={{ width: '100%' }} >
          {get_All_Activity_data.length>0?get_All_Activity_data.map((item: any, index: any) => 
                  returnActivity(item,index)

              ):
              <p style={{
                fontSize: "14px", color: "white", fontWeight: 900, textAlign: "center",
                // marginBottom: "50px",
                marginBottom: '30px',
                position: "absolute",
                left: "0px",
                right: "0px"
              }}
              >
                No New Activity Releases
              </p>
            
            }
          </Masonry>
        </ResponsiveMasonry>


        <Grid container item xs={12} sm={12} className="activity-mr main-scroll-activity hide_in_mobile_and_desktop_activity" style={{ height: '500px', marginTop: '40px' }}>

          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 650: 2, 1200: 3 }}
            style={{ width: '100%' }}
          //className="main-scroll hide_in_medium-activity"
          >
            <Masonry style={{ width: '100%' }} >
            {get_All_Activity_data.length>0?get_All_Activity_data.map((item: any, index: any) => 
                  returnActivity(item,index)

                )
              :
              <p style={{
                fontSize: "14px", color: "white", fontWeight: 900, textAlign: "center",
                // marginBottom: "50px",
                marginBottom: '30px',
                position: "absolute",
                left: "0px",
                right: "0px"
              }}
              >
                No New Activity Releases
              </p>
            
            }
            </Masonry>
          </ResponsiveMasonry>

        </Grid>


      </div>

      <Grid container xs={12} className="hide_in_tab_and_desktop_activity">
        <Box style={{ marginTop: "-11px", marginBottom: '-20px' }} className="tab-content">
          Activity
        </Box>

        <Grid item xs={12} className="main-scroll-activity" style={{ height: '500px', marginTop: '40px', overflowY: 'auto' }}>
        {get_All_Activity_data.length>0?get_All_Activity_data.map((item: any, index: any) => 
              
                  returnActivity(item,index)

                )
              :
              <p style={{
                fontSize: "14px", color: "white", fontWeight: 900, textAlign: "center",
                // marginBottom: "50px",
                marginBottom: '30px',
                position: "absolute",
                left: "0px",
                right: "0px"
              }}
              >
                No New Activity Releases
              </p>
            
            }
        </Grid>
      </Grid>

    </>
  )
}

export default ActivityPublicView
// Customizable Area End