Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const urlConfig = require("../../../../framework/src/config.js")
  // Customizable Area Start
  exports.dashboarContentType = "application/json";
  exports.getbannerdata = "/bx_block_audiomusic/banners";
  exports.gethandlepreview = "/bx_block_audiomusic/expan_track";
  exports.getcreativedata = "/account_block/show_all_creatives_data";
  exports.getsongslist = "/bx_block_audiomusic/tracks_by_upload_type_public";
  exports.getalltablist = "/bx_block_audiomusic/tracks_by_upload_type_public";
  exports.getplaylistlist = "/bx_block_playlist/all_playlists_public";
  exports.getoriginaldata = "/bx_block_audiomusic/original_profile";
  exports.getalloriginallist = "/bx_block_audiomusic/creative_tracks_by_upload_type";
  exports.getalldealtypes="/bx_block_subscription_plan/deal_types";
  exports.getSplittrackid = "/bx_block_audiomusic/track_split_users?";
  exports.getAPIMethod="GET"
  exports.postAPIMethod="POST"
  exports.putAPIMethod="PUT"
  exports.baseUrl = urlConfig.baseURL;