// Customizable Area Start
import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
export interface Props {
  props?: any;
  navigation?: any;
  myProfile?: boolean;
  userData?: boolean;

}
interface S {
  openAlertPopUp: boolean;
  OpenUpgradModal: boolean;
  creativeId: any;
  openPopupFollowers: boolean,
  openPopupFollowing: boolean,
  errorMsg: string;
  loading: boolean;
  apiToken: any;
  dashboardData: any;
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  userProfileData: any;

  vedioPlayer: any;
  previousHref !: string;
  constructor(props: Props) {
    super(props);
    this.vedioPlayer = React.createRef()
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      openAlertPopUp: false,
      OpenUpgradModal: false,
      dashboardData: [],
      openPopupFollowers: false,
      openPopupFollowing: false,
     
      apiToken: localStorage.getItem("token"),
      creativeId: "",
      errorMsg: "",
      loading: false
    };
   

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
   
  }

 
  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId == null) {
      this.parseApiCatchErrorResponse(errorReponse);
      return;
    }

   

  }
}
// Customizable Area End