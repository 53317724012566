// Customizable Area Start
import React from 'react';
import { Box, Button } from '@material-ui/core'
// Customizable Area End


// Customizable Area Start
interface Props {
    selectedPage:string;
    selectPage: (page:string) => void;
}
// Customizable Area End

// Customizable Area Start
export const DMTypeSelector = (props: Props) => {
    const handleTrackSelect = (e: any) => {
        if(props.selectedPage !== 'General'){
            props.selectPage('General')
        }
    }

    const handleSongSelect = (e: any) => {
        if(props.selectedPage !== 'Followers'){
            props.selectPage('Followers')
        }
    }

    const handleVideoSelect = (e: any) => {
        if(props.selectedPage !== 'Subs'){
            props.selectPage('Subs')
        }
    }
    return (
        <Box className="DMTypeSelectorBox" style={{margin:0}}>
            <Button className={`TrackBtn ${props.selectedPage === 'General' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={handleTrackSelect}>General</Button>
            <Button className={`SongBtn  ${props.selectedPage === 'Followers' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={handleSongSelect}>Followers</Button>
            <Button className={`VideoBtn  ${props.selectedPage === 'Subs' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={handleVideoSelect}>Subs</Button>
        </Box>
    );
}
// Customizable Area End


// Customizable Area Start
export default DMTypeSelector;
// Customizable Area End