// Customizable Area Start
import React from "react";
import '../../../../user-profile-basic/src/Common/TopSongs.web.css'   
import AllTypeSongs from "../AllTypeSongs.web";

const SearchSongsWeb = (props: any) => {
  const {allSongsList, handlePlayList ,playlistData, name, songIndex,  loading} = props;
  return (
    <>
    <AllTypeSongs songs = {allSongsList} playlistData={playlistData} name={name} handlePlayList={handlePlayList} songIndex={songIndex} loading={loading} type="SONGS"/>
    </>   
  );
};

export default SearchSongsWeb;
// Customizable Area End

