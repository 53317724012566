// Customizable Area Start
import React, { useContext, useEffect, useState } from 'react'
import { CloseRounded } from '@material-ui/icons';
import NextAudioQueue from "./NextAudioQueue.web";
import CarasouelProfile from "./CarasouelProfile.web";
import { QueueSplitProgress } from "./QueueSplitProgress.web";
import { Grid, IconButton } from "@material-ui/core";
import { defaultImage, plays } from '../../assets';
import { useHistory } from 'react-router-dom';
import { openNotification } from '../../../../../components/src/Notification.web';
import axios from 'axios';
import NextQueue from './NextQueue.web';
import { AudioContextApi } from '../../AudioContext/AudioContext.web';
import NextUpSongs from './NextUpSongs.web'

const urlConfig = require("../../../../../framework/src/config");
const baseUrl = urlConfig.baseURL;
const audio_player = localStorage.getItem("audioplayer");

const NowPlayingContent = (props: any) =>{
    const { setRemoveNowPlayingSong } = useContext(AudioContextApi);
    const { PlaylistData } = props;
    return (
        <>
         <Grid item xs={1} style={{ padding: "0px 0px 0px 10px" }}>
            <img src={`${PlaylistData?.attributes?.art_work || PlaylistData?.attributes?.artist_image || defaultImage}`} alt="" style={{ height: "48px", width: "48px" }} />
        </Grid>
        <Grid item xs={7} style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0px 10px 0px 40px" }}>
            <span className="track_name_font">{PlaylistData?.attributes?.title || PlaylistData?.attributes?.name || 'Remember me'}</span>
            <span className="username_font">{PlaylistData?.attributes?.artist_name || 'Unknown'}</span>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "0px 10px" }}>
            <img src={plays} alt="plays" style={{ height: "20px", width: "20px", margin: "0px 10px" }} />
            <span className="track_name_font">{PlaylistData?.attributes?.play_count || '6.57M'}</span>
            <IconButton>
                <CloseRounded fontSize="medium" style={{ color: "rgba(255, 255, 255, 0.5)" }} onClick= {() => { localStorage.setItem("removingNowPlayingSong", JSON.stringify(PlaylistData?.id)); setRemoveNowPlayingSong(PlaylistData)}} />
            </IconButton>
        </Grid>
        </>
    )
}

const AudioQueueTemp = (props: any) => {


    const { handleChartTrackRepost, handlerFetchRepost, emptyPlaylist, showModalAddNewPlaylist,
    open, dialogTextAlertLogin, openAlertLogin } = props;

    const queueData = localStorage.getItem('queueSongs');

    const {queueSongs,setAfterRefreshQueueList, setQueuePlaylistSongs, playlistQueueSongs, nextPlaylistSong, setNextUplist,nextUplist } = useContext(AudioContextApi);
    const history = useHistory();
    const [PlaylistData, setPlaylistData] = useState<any>(null)
    const [userTrack, setUserTrack] = useState([]);
    const [trackId, settrackId] = useState<any>(0)
    const tokenStr: any = localStorage.getItem("token");
    const [allPlaylist, setAllPlayList] = useState<any>([]);
    const [allPlaylistSongs, setAllPlayListSongs] = useState<any>([]);
    const [queueList, setQueueList] = useState<any>([])
    const [nextUp, setNextUp] = useState<any>([])
    const [top40Songs, setTop40Songs] = useState<any>([])
    const [selectedType, setSelectedType] = useState('')
    const [selectedPlaylistName, setSelectedPlaylistName] = useState('')


    console.log(history?.location?.state);
    
    const {nextSongForPlay} = useContext(AudioContextApi)

    useEffect(() => {
        
        const location: any = history?.location?.state
        if (location?.data && !location.playlist) {
            setPlaylistData(location.data);
            settrackId(location.data.id)
            setSelectedType(location.data.attributes?.track_upload_type)
            setUserTrack(location.data.attributes.user_tracks && location.data.attributes.user_tracks.data || [])
        }else if(location?.data && location?.playlist){
            setPlaylistData(location.data);
            settrackId(location.data.id)
            setSelectedType(location.data.type)
            setUserTrack(location.data.attributes.user_tracks && location.data.attributes.user_tracks.data || [])
            setAllPlayList(location.playlist)
            setSelectedPlaylistName(location.playlist?.attributes?.name)
            localStorage.setItem('queuePlaylistName',JSON.stringify(location.playlist?.attributes?.name))
        }else{
           const track_id = window.location.href.split('=')[1]
           console.log(track_id,'track_id')
           localStorage.setItem("selectedSong", JSON.stringify(track_id))
        }

        
        const localListSongs = JSON.parse(localStorage.getItem('queuePlaylistSongs') || '[]')
          const selectedPlaylist = localListSongs
            if(selectedPlaylist.length > 0){
               setAllPlayListSongs(selectedPlaylist)
               setQueuePlaylistSongs(selectedPlaylist)
               setSelectedType("playlist")
            }

           if(localStorage.getItem('queuePlaylistName')){
            const playlistName = JSON.parse(localStorage.getItem('queuePlaylistName') || '')
            setSelectedPlaylistName(playlistName)
           }
    }, [])

    useEffect(() => {
        if(queueSongs.length > 0){
            setQueueList(queueSongs);
          }
          else{
              if(queueData){
                  let songs = JSON.parse(queueData)
                  if(songs.length > 0){
                    setQueueList(songs);
                    setAfterRefreshQueueList(songs);
                  }else{
                    setQueueList([])
                  }
              }else{
                  setQueueList([])
              }
          }
    },[queueSongs])

    useEffect(() => {
        const songId = localStorage.getItem('selectedSong')
        console.log(songId, 'selected song from localstorage')
        if(songId){
            const songIdFN = JSON.parse(songId);
            getTrack(songIdFN)
        }

    }, [localStorage.getItem('selectedSong')])

    useEffect(() => {
      if(nextSongForPlay?.id){
        setPlaylistData(nextSongForPlay)
      }
    }, [nextSongForPlay?.id])

    useEffect(() => {
        const songId = localStorage.getItem('selectedSong')
        let songIdFN = ''
        if(songId){
          songIdFN = JSON.parse(songId);
        }

        if(queueList.length == 0 && allPlaylistSongs.length == 0){
            let playerData = JSON.parse(localStorage.getItem('nextUpSongs') || '[]')
            if(playerData.length > 0){
                if(nextUplist.length == 0){
                  setNextUplist(playerData)
                }
                let nexupSongsList = []
                nexupSongsList = playerData
                get_next_songs(nexupSongsList,songIdFN)
            }else{
                getTop40Songs()
            }
        }
    },[localStorage.getItem('selectedSong'),localStorage.getItem('nextUpSongs'), nextUplist])

    const get_next_songs = (nexupSongsList: any, songIdFN: any ) => {
        if(nexupSongsList.length > 0){
            let index = nexupSongsList.findIndex((item: any) => item.id === songIdFN)
            if(index >= 0 && index != nexupSongsList.length - 1){
                nexupSongsList.splice(0,index + 1)
            }else if(index == nexupSongsList.length - 1){
                nexupSongsList.splice(0,index + 1)
                setNextUp([])
                localStorage.setItem("nextUpSongs", JSON.stringify([]));
                 getTop40Songs()
            }
            setNextUp(nexupSongsList)
        }
        else{
            setNextUp([])
        }
    }

    useEffect(() =>{
        const songId = localStorage.getItem('selectedSong')
        let songIdFN = ''
        if(songId){
          songIdFN = JSON.parse(songId);
        }
        const localListSongs = JSON.parse(localStorage.getItem('queuePlaylistSongs') || '[]')
        let playlistSongs = localListSongs 

        let index = playlistSongs.findIndex((item: any) => item.id === songIdFN)
        if(index == -1 || index == undefined){
            if(nextPlaylistSong != undefined){
              index = playlistSongs.findIndex((item: any) => item.id === nextPlaylistSong.id)
              playlistSongs.splice(0,index)
            }else{
                playlistSongs.splice(0,index + 1)
            }
        }
        else{
            playlistSongs.splice(0,index + 1)
        }

        setAllPlayListSongs(playlistSongs)

    }, [localStorage.getItem('selectedSong'), playlistQueueSongs])


    const getTop40Songs = async () =>{
        await axios
            .get(
                `${baseUrl}/bx_block_audiomusic/top_forty_tracks?upload_type=Audio`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        token: tokenStr,
                    },
                }
            )
            .then((res: any) => {
                setTop40Songs(res?.data?.data)
                localStorage.setItem("nextUpSongs", JSON.stringify(res?.data?.data));
                setNextUplist(res?.data?.data)
            })
            .catch((err) => {
                openNotification("some thing wrong", "Error")
            })
            .finally(() => {
            });
    }

    const getTrack = async (trackId: any) => {
        console.log(`${baseUrl}/bx_block_audiomusic/expan_track?id=${trackId}`)
        await axios
            .get(
                `${baseUrl}/bx_block_audiomusic/expan_track?id=${trackId}`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        token: tokenStr,
                    },
                }
            )
            .then((res: any) => {
                setPlaylistData(res?.data?.data)
                console.log(res.data.data)
            })
            .catch((err) => {
                openNotification("some thing wrong in get Track", "Error")
            })
            .finally(() => {
            });
    }

    const handlerFetchAllPlaylist = async () => {
        await axios
            .get(`${baseUrl}/bx_block_playlist/play_lists`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    token: tokenStr,
                },
            })
            .then((res) => {
                setAllPlayList(res.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getQueues = async () => {
        await axios
            .get(
                `${baseUrl}/bx_block_playlist/queues/1`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        token: tokenStr,
                    },
                }
            )
            .then((res: any) => {
                console.log(res?.data?.tracks)
                setQueueList(res?.data?.tracks)
            })
            .catch((err) => {
                openNotification("some thing wrong in get Track", "Error")
            })
            .finally(() => {
            });
    }

    const setPlaylistDataFromList = (item: any) =>{
        setPlaylistData(item)
        setUserTrack(item.attributes.user_tracks && item.attributes.user_tracks.data || [])
    }


    return (
        <>
            <img
                src={`${PlaylistData?.attributes?.art_work || PlaylistData?.attributes?.artist_image || defaultImage}`}
                alt="playlist data"
                className="carasouel_bg_img"
            />
            <Grid item xl={12} container className="carasouel_position">
                <Grid
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className=""
                    style={{ padding: "20px" }}
                >
                    <CarasouelProfile
                        artWork={PlaylistData?.attributes?.art_work}
                        artistImage={PlaylistData?.attributes?.artist_image}
                        title={PlaylistData?.attributes?.title || PlaylistData?.attributes?.name || 'Remember me'}
                        artistName={PlaylistData?.attributes?.artist_name || 'Unknown'}
                        handleChartTrackRepost={handleChartTrackRepost}
                        handlerFetchRepost={handlerFetchRepost}
                        trackId={trackId}
                        PlaylistData={PlaylistData}
                        selectedType={selectedType}
                        allPlaylist= {allPlaylist}
                        allPlaylistSongs={JSON.parse(localStorage.getItem('queuePlaylistSongs') || '[]')}
                        emptyPlaylist={emptyPlaylist}
                        showModalAddNewPlaylist={showModalAddNewPlaylist}
                        open={open}
                        dialogTextAlertLogin={dialogTextAlertLogin}
                        openAlertLogin={openAlertLogin}
                    />
                    <Grid
                        item
                        xs={12}
                        style={{ margin: "0px 15px" }}
                        className='hide_splits_in_mobile'
                    >
                        <QueueSplitProgress
                            userTracks={userTrack}
                            userTrackLangth={userTrack.length > 0}

                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className=""
                    style={{ padding: "20px" }}
                >
                    <Grid item xs={12} style={{ padding: "30px 5px 20px" }}>
                        <span className="nowplaying_font">Now Playing</span>
                    </Grid>

                    <Grid item xs={12} className="now_playing_content" container>
                       <NowPlayingContent PlaylistData={PlaylistData}/>
                    </Grid>

                    {
                        queueList.length == 0 && allPlaylistSongs.length == 0 && nextUp.length > 0 &&
                        <>
                    <Grid item xs={12} className="" style={{ padding: "20px 5px"}}>
                        <span className="nowplaying_font">Next Up</span>
                    </Grid>

                    <Grid item xs={12} container className={'next_only_queue_content'} >
                        {
                          nextUp.length > 0 &&
                          nextUp.map((item: any) => {
                                    return (
                                        <NextUpSongs playlistItems={item} nextUpSongs = {nextUp}/>
                                    )
                                }) 
                        }
                    </Grid>
                    </>
               }

{
                        queueList.length == 0 && allPlaylistSongs.length == 0 && nextUp.length == 0 && top40Songs.length > 0 && 
                        <>
                    <Grid item xs={12} className="" style={{ padding: "20px 5px"}}>
                        <span className="nowplaying_font">Next Up</span>
                    </Grid>

                    <Grid item xs={12} container className={'next_only_queue_content'} >
                        {
                          top40Songs.length > 0 &&
                          top40Songs.map((item: any) => {
                                    return (
                                        <NextUpSongs playlistItems={item} nextUpSongs = {top40Songs}/>
                                    )
                                }) 
                        }
                        {/* <NextAudioQueue />
                        <NextAudioQueue />
                        <NextAudioQueue />
                        <NextAudioQueue /> */}
                    </Grid>
                    </>
               }

                    {
                        queueList.length > 0 &&
                        <>
                    <Grid item xs={12} className="" style={{ padding: "20px 5px"}}>
                        <span className="nowplaying_font">Next in Queue</span>
                    </Grid>

                    <Grid item xs={12} container className={`${allPlaylistSongs.length != 0 && queueList.length > 3 ? 'next_queue_content' : 'next_only_queue_content'}`} >
                        {
                          queueList.length > 0 &&
                                queueList.map((item: any) => {
                                    return (

                                        <NextQueue playlistItems={item} />
                                    )
                                }) 
                        }
                        {/* <NextAudioQueue />
                        <NextAudioQueue />
                        <NextAudioQueue />
                        <NextAudioQueue /> */}
                    </Grid>
                    </>
               }
                    { allPlaylistSongs.length > 0 &&
                    //  allPlaylist.length > 0 ?
                    //     allPlaylist.map((item: any, index: any) => {
                    //         if (index === 0) {
                    //             console.log(item?.attributes?.playlist_items)
                    //             return (
                                    <>
                                        <Grid item xs={12} style={{ padding: "20px 5px" }}>
                                            <span className="nowplaying_font">Next From: {selectedPlaylistName}</span>
                                        </Grid>
                                        <Grid item xs={12} container className="next_playlist_content">
                                            <NextAudioQueue playlistItems={allPlaylistSongs} setPlaylistDataFromList = {setPlaylistDataFromList} playlistQueueSongs = {JSON.parse(localStorage.getItem('queuePlaylistSongs') || '[]')}/>
                                            <NextAudioQueue />
                                            <NextAudioQueue />
                                        </Grid>
                                    </>
                        //         )
                        //     }
                        // }) :
                    }

                </Grid>
            </Grid>
        </>
    )
}

export default AudioQueueTemp
// Customizable Area End