// Customizable Area Start

import { Typography, Tabs, Layout, Row } from 'antd';
import {
    Box,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme,
    Hidden
} from "@material-ui/core";
import React from 'react'
import { bluecheck, RepostHistoryimage, listnerimage } from '../assets';
import { Content } from 'antd/lib/layout/layout';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import RepostHistoryController, { ActiveTab } from './RepostHistoryController';
import { defaultProfile, nxzloader } from 'blocks/dashboard/src/assets';

const LeftSideSingletrackboxContainer = styled(Box)({
    height: "81px",
    display: "flex",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
    gap: "10px",
    objectFit: "contain",
    color: "white",
    justifyContent: "space-between",

});
const BorderLineProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
    }),
)(LinearProgress);



const LeftsideReposthistorycontainer = styled(Box)({
    width: "50%",
    display: "flex",
    paddingTop: "20px",
    gap: "40px",
    flexDirection: "column",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const Repostboxcontainer = styled(Box)({
    width: "100%",
    display: "flex",
    height: "90vh",
    overflowY: "auto",
    padding: "15px 30px 0px 50px",
    gap: "40px",
    "@media(max-width: 1000px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const Leftsidebox = styled(Box)({
    width: "50%",
    "@media(max-width: 1000px)": {
        width: "100%"
    },
});


const Tracknames = styled(Box)({
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
});


export default class RepostHistory extends RepostHistoryController {
    renderReposterList() {
        if (this.state.isLoading) {
            return (

                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <img src={nxzloader} style={{ width: "5%" }} />
                </Row>
            )
        } else {
            return (
                this.state.listData.length > 0 ?
                    this.state.listData.map((item: any, index: number) => {
                      return (
                <LeftSideSingletrackboxContainer
                    key={index}
                >

                    <Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                            <span>{index + 1}</span>
                            <div className='repostLabel_icon'>
                            <img
                                src={item.attributes.avatar||defaultProfile}
                                className={item.attributes.avatar?'mediaDMCP-avatar':"default-profile-mediaDMCP"}
                            />
                            </div>
                        </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box>
                                <Tracknames className="tracks_songs_text">
                                    {item.attributes.user_name}
                                    {item?.attributes?.is_verified && (
                                    <span className="Jason-Derulo">

                                        <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                    </span>
                                    )}
                                </Tracknames>
                                <Box>
                                    <span style={webStyle.splittexts}>
                                        Reposts

                                    </span>
                                </Box>

                            </Box>
                            <Box>               
                                <Box style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}>
                                    <span style={webStyle.splittextwhites}>
                                        {this.state.usertype.includes("listener") ? item.attributes.listener_repost_count : item.attributes.repost_count}
                                    </span>
                                </Box>
                            </Box>

                        </Box>
                        <Box>
                            <BorderLineProgress variant="determinate" value={this.getProgress(item)} data-test-id="border-linear-progress"/>
                        </Box>
                    </Box>
                </LeftSideSingletrackboxContainer>
                      )})
                :
                <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center",paddingTop:"90px" }}>
                  No Data Found
                </div>
              
            )
        }
    }
    render() {
        const { isPlaylist } = this.context;
        return (
            <>
                <Layout className='streamRepostWrapper'>
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        style={{
                            margin: "0px",
                            backgroundColor: "black",
                            display: "flex", flexDirection: "column",
                        }}
                    >
                        <HomeHeader location="RepostHistory" redirect={this.redirectToProfile} />

                        <Repostboxcontainer style={isPlaylist ? { height: "75vh" } : { height: "90vh" }} className='streamRepostscroll'>
                            <Leftsidebox>
                                <Box>
                                    <img src={(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus") ? RepostHistoryimage : listnerimage} style={{
                                        width: "100%",
                                        gap: "0px",
                                        height:"100%"
                                    }} 
                                    className='repostimg'/>
                                </Box>
                            </Leftsidebox>
                            <LeftsideReposthistorycontainer >
                                {(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus") ? <Typography style={webStyle.tracknames}>
                                    {" "}
                                    <span style={webStyle.bluetexts}>
                                        TOP {this.state.listData.length}:
                                    </span>{" "}
                                    Reposters
                                </Typography> : <Typography style={webStyle.tracknames}>
                                    {" "}
                                    <span style={webStyle.tracknames}>
                                        Your Repost History
                                    </span>{" "}

                                </Typography>}

                                <Box>
                                    <div className="chats-header" >
                                        <Tabs
                                            className='Streamrepost_custom_tab'
                                            defaultActiveKey="0"
                                            tabPosition="top"
                                            onChange={this.handleTabChange}
                                            items={this.getLast12MonthsData().map((item: ActiveTab, index: string | number) => {
                                                const id = String(index);
                                                return {
                                                    label: item.title,
                                                    key: id,
                                                    disabled: index === 12,
                                                };
                                            })}

                                        />
                                    </div>

                                </Box>
                                <Box>
                                {this.renderReposterList()}
                                </Box>
                            </LeftsideReposthistorycontainer>
                        </Repostboxcontainer>
                    </Content>
                </Layout>


            </>

        )
    }
}

const webStyle = {
    singleArtimginfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",
    },

    tracknames: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
    },


    bluetexts: {
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
    },

    splittexts: {
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal"
    },
    
    splittextwhites: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        letterSpacing: "normal",
        color:"#F0F0F5",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
    },

    leftsideowners: {
        gap: "3px",
        flexDirection: "column" as "column",
        display: "flex",
    },
};
RepostHistory.contextType = AudioContextApi;
// Customizable Area End