// Customizable Area Start
import { Carousel, Col, Row } from "antd";
import React, { useRef } from "react";
import { nextIC, prevIC } from "../../../../blocks/dashboard/src/assets";
import "./index.css";
import Carasouel from "./Carasouel.web";
import axios from "axios";  
import { openNotification } from "../../Notification.web";
import { AnyNsRecord } from "dns";
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

const ChartsCarousel = (props: any) => {
  const { playlistData, mainList, songIndex, name, handlePlayList } = props;

  const carouselRef: any = useRef(null);

  const _settings = {
    autoplay: false,
    dots: true,
  };

  

  const PrevButtonOrginal = (props: any) => {
    return (
      <p
        style={{
          position: "absolute",
          top: `${props.top}`,
          // left: `${props.left}`,
          // transform: "translateY(-50%)",
          marginLeft: "-12px",
          cursor: "pointer",
        }}
        onClick={() => props.buttonRef.current.prev()}
      >
        <img src={prevIC} style={{ height: "27px", width: "27px" }} />
      </p>
    );
  };

  const NextButtonOrginal = (props: any) => {
    return (
      <p
        style={{
          position: "absolute",
          top: `${props.top}`,
          // right: `${props.right}`,
          // transform: "translateY(-50%)",
          marginRight: "-15px",
          cursor: "pointer",
          right: 0
        }}
        onClick={() => props.buttonRef.current.next()}
      >
        <img src={nextIC} style={{ height: "27px", width: "27px" }} />
      </p>
    );
  };

  return (
    <div>
      {mainList.length !== 0 && (
        <Row
          justify="center"
          style={{
            margin: "10px 0px",
            //  height: "24vh"
          }}
        >
          <Col span={24} style={{ padding: "0px" }}>
            <div className="customCarousel width_100">
              <Carousel ref={carouselRef} {..._settings}>
                {mainList
                  .slice(songIndex, songIndex + 4)
                  .map((_data: any, index: any) => (<SlideItem mainList={mainList} data={_data} unique={_data.id} key={_data.id} playlistData={playlistData} songIndex={index} name={name} handlePlayList={handlePlayList} hideDownloadInPlaylist={props.hideDownloadInPlaylist} />)
                  )}
              </Carousel>
              <PrevButtonOrginal top="45%" left="-2%" buttonRef={carouselRef} />
              <NextButtonOrginal top="45%" right="-2%" buttonRef={carouselRef}>
                {" "}
              </NextButtonOrginal>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
const SlideItem = (value: any) => {
  
  const token: any = localStorage.getItem("token");

  const handleChartTrackRepost = async (caption: AnyNsRecord) => {
    await axios
      .post(
        `${baseUrl}/bx_block_audiomusic/repost?id=${value.data.id}&caption=${caption}`,
        null,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
          },
        }
      )
      .then((res: any) => {

        openNotification("Successfully Reposted", "Success")
      })
      .catch((err) => {
        openNotification("Some error occurred", "Error")

      })
  };
  return (
    <div className="width_100">
      <div className="beats-image width_100" style={{ overflow: "hidden" }}>
        <Carasouel
          playlistData={value.playlistData}
          handleChartTrackRepost={handleChartTrackRepost}
          data={value.data}
          name={value.name}
          handlePlayList={value.handlePlayList}
          mainList={value.mainList}
          songIndex={value.songIndex}
          hideDownloadInPlaylist={value.hideDownloadInPlaylist}
          unique={value.unique}
        />
      </div>
    </div>
  );
};
export default React.memo(ChartsCarousel);
// Customizable Area End
