// Customizable Area Start
import React from 'react';
import './Index.css';
import {  Grid } from '@material-ui/core';
import { mastercardLogo, visaCard, americanExp, dinerClubCard, discoverLogo, jcbLogo } from '../assets';
import CardInformationsController from './CardInformationsController'

export default class CardInformations extends CardInformationsController {
  // { currentPaymentData }: any
  render(){
    const {currentPaymentData} = this.props
  let currentCard="";
  if (currentPaymentData?.card?.brand==="visa"){
    currentCard=visaCard
  }else if(currentPaymentData?.card?.brand==="mastercard"){
    currentCard=mastercardLogo
  } else if(currentPaymentData?.card?.brand==="amex"){
    currentCard=americanExp
  }else if(currentPaymentData?.card?.brand==="discover"){
    currentCard=discoverLogo
  }else if(currentPaymentData?.card?.brand==="jcb"){
    currentCard=jcbLogo
  }else{
    currentCard=dinerClubCard
  }
  return (
    <Grid item xs={12} className='payment_Card'>
      <Grid item xs={12} className='cardType'>
        <span className='cardFont'>My Payment Method</span>

        <img src={currentCard} alt='mastercardLogo' className='cardImage' />
      </Grid>
      <Grid item xs={12} className='cardInfo' style={{marginTop:"10%"}}>
        <span className='cardFont'>****&nbsp;****&nbsp;{currentPaymentData?.card?.last4||"****"}&nbsp;
        </span>
        <span className='cardFont'>{currentPaymentData?.billing_details?.name||"*****"}</span>
      </Grid>
    </Grid>
  );
  }
};

// Customizable Area End