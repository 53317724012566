// Customizable Area Start
import React from "react"
import { Dialog, Typography, Grid } from '@material-ui/core';
import { Col, Row } from "antd";
import './conversation.css'
interface Props {
    isOpen: boolean;
    handleDMClose: any;
    handleDMOpen?: any;
    handleDelete?: any;
}

export default function DeleteConversation({ isOpen, handleDMClose, handleDelete }: Props) {
    const handleDeleteConversation = () => {
        handleDelete()
        handleDMClose()
    }
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleDMClose}
                className='CopiedModal'
                PaperProps={{ className: "dialog-delete-message-popup" }}


            >


                <Row justify="center" >
                    <Col >
                        <Grid container style={{ justifyContent: 'center', padding: '10px', flexDirection: 'column', alignItems: 'center' }}>
                            <Grid item>
                                <Typography className="dialog-delete-head" > Delete Conversation ?</Typography>
                                <Typography className="deletemodal-subtitle">
                                    This conversation will be deleted from your inbox. The other person in this conversation will still be able to see it.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <button
                                    className="red_custom_btn_sms  mt-4"
                                    type='button'
                                    // onClick={this.deletePlaylist}
                                    onClick={handleDeleteConversation}
                                >
                                    YES
                                </button>
                            </Grid >
                            <Grid item>
                                <button
                                    className="grey_custom_btn mt-1"
                                    type='button'
                                    // onClick={this.props.handleClose}
                                    onClick={handleDMClose}
                                    style={{
                                        backgroundColor: "transparent ",
                                        borderRadius: "60px",
                                        border: "solid 3px #121212"
                                    }}
                                >
                                    NO
                                </button>
                            </Grid>
                        </Grid>
                    </Col>
                </Row>
            </Dialog>
        </div>
    )
}
// Customizable Area End