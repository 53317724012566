// Customizable Area Start
import React, { Component } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Grid } from "@material-ui/core";
import { circleBlue } from '../../../../components/src/CustomMessage/MessageLinkPopup/assets'
import { SearchOutlined } from "@ant-design/icons";



interface Props {
    selectState: boolean;
    setSelectedSingleState: (state: any) => void;
}

interface SS {
    value: any;
    majorStates: any;
    selectedState: any;
}

class StateFilterApply extends Component<Props,SS> {

    constructor(props: Props) {
        super(props);
        this.state = {
            value: null,
            majorStates: ['Texas, USA','Hawaii, USA','California, USA','Florida, USA','New Jersey, USA'],
            selectedState: ''
        };
    }
    

    componentDidMount() {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBPAAZ67lS_QAFq6sSj0a9Ejix3e2PTyHY&libraries=places`;
        script.defer = true;
        script.async = true;
        script.onload = () => {
          this.fetchMajorStates('');
        };
        document.head.appendChild(script);
      }

  
      fetchMajorStates = (input: any) => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        
        autocompleteService.getPlacePredictions(
          { input: input, types: ['(regions)']  },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const result_list = predictions?.map(prediction => prediction.description);
              const states = result_list?.filter((result) => result.split(',').length == 2);              
              this.setState({ majorStates: states });
            
            }
          }
        );
      };



    handleStateInputChange = (inputValue: any) =>{
        if(inputValue =="" && this.state.majorStates.length == 0){
            this.setState({ majorStates : ['Texas, USA','Hawaii, USA','California, USA','Florida, USA','New Jersey, USA']})
        }else{
            this.fetchMajorStates(inputValue)
        }
      }
    

 
    setStaeLoc = (loc: any) =>{
        if(this.state.selectedState == loc){
            this.setState({ selectedState: ''})
            this.props.setSelectedSingleState('');
        }else{
            this.setState({ selectedState: loc})
            this.props.setSelectedSingleState(loc);
        }
       
    }

    render() {
        const { majorStates } = this.state;
        return (
       
            <>
             {
                this.props.selectState && 
                <Grid style={{marginTop: '10px'}}>
                
               <GooglePlacesAutocomplete
                apiKey="AIzaSyBPAAZ67lS_QAFq6sSj0a9Ejix3e2PTyHY"
                autocompletionRequest={{
                    types: ['(cities)'],
                }}
               
                selectProps={{
                    className: "search_city",
                    defaultValue: this.state.value || '',
                    onInputChange: this.handleStateInputChange,
                
                    placeholder: (
                      <div style={{display: 'flex'}}>  
                       {/* @ts-ignore */} 
                      <SearchOutlined
                        className="text_white1"
                        style={{
                          backgroundColor: "rgb(40 40 40)",
                          fontSize: "18px",
                          padding: "2px",
                        }}
                    
                      />
                      <div className="text_white1" style={{ fontFamily: 'inter', fontWeight: 'bold', marginLeft: '15px'}} >Search location</div>
                      </div>
                    )
                    
                  }}
            />
            </Grid>
                }
            {this.props.selectState && majorStates.length !== 0 &&
                majorStates.map((loc: any) => {
                  return (
                    <Grid
                      style={{
                        height: "30px",
                        margin: "10px 6px",
                        borderRadius: "12px",
                        fontWeight: "bold",
                      }}
                      onClick={() => this.setStaeLoc(loc)}
                    >
                      <div
                        className="hoverloc"
                        style={{
                          textAlign: "left",
                          color: "grey",
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        {loc}

                        { loc == this.state.selectedState && (
                              <Grid item>
                                <img
                                  style={{height: '15px', width: '15px'}}
                                  src={circleBlue}
                                />
                              </Grid>
                            )
                     }
                      </div>
                      
                     
                    </Grid>
                  );
                })}
                
                
                </>
        );
    }
}

export default StateFilterApply;
// Customizable Area End

