// Customizable Area Start
import React from 'react'
import PlaylistSong from './PlaylistSong.web'
import { Box } from "@material-ui/core";


function PlaylistSongsList( props : any) {
    const {all_playlist_songs, play, selectedPlaylistId,selectedPlaylistSong, playlistSongSelected} = props;
  return (
    <Box sx={{
        width: {
          xl: 700
        }
      }}>
        {all_playlist_songs.map((song: any, index: number) => {
          return <div style={{ margin: '10px' }}>

            <PlaylistSong song={song} index={index} play={play} selectedPlaylistId={selectedPlaylistId} selectedPlaylistSong={selectedPlaylistSong} playlistSongSelected={playlistSongSelected}/>


          </div>
        })}
      </Box>
  )
}

export default PlaylistSongsList
// Customizable Area End