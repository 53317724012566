// Customizable Area Start
import React from 'react';
import {Modal} from 'antd'
import { Button } from "@material-ui/core";

interface Props {
    visible: any;
    labelId: any;
    setVisible: (value: any) => void;
    setLoadingFollow: (value: any) => void;
    UnFolloweUser: (value: any) => void;
}

export const UnFollowMessageDialog = (props: Props) =>{

return (
<Modal title="" visible={props.visible} footer={null} onCancel={() => { props.setVisible(false)}} style={{ textAlign: 'center' }} >
                  <h4 style={{ color: 'white' }}>Do you really want to unfollow?</h4>
                  <Button style={{ color: 'white', background: 'red' }} onClick={() => {
                    props.setVisible(false)
                  }}>No</Button> &nbsp;&nbsp;
                  <Button style={{ color: 'white', background: 'green' }} onClick={() => {
                    if (props.labelId) {
                      props.setLoadingFollow(true)
                      props.setVisible(false)
                      props.UnFolloweUser(props.labelId)
                    }
                  }}>Yes</Button>
                </Modal>
)}
// Customizable Area End