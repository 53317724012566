// Customizable Area Start
import React from 'react'
import { keyIcon, messageProfile, profileinvite, profileperson } from 'blocks/dashboard/src/assets'
import { ProfileFollowing } from './ProfileFollowing.web'
import { Typography, Grid, Button, ButtonGroup } from "@material-ui/core";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin ,Modal} from 'antd'
import SendMessage from 'components/src/CustomChart/PopUps/SendMessageDialog.web';
import ComposeBlockFollowController from '../Common/ComposeBlockFollowController';

export default class ComposeBlockFollow extends ComposeBlockFollowController {
     handleFollowState=()=>{
        let currentFollowCheck = ""
        if (this.props.unfollowState) {
            currentFollowCheck = "Unfollow"
        } else {
            currentFollowCheck = "follow"
        }
        return currentFollowCheck

    }

    handlePopupVisible=()=>{
        if (this.props.unfollowState) {
            this.props.setVisible(true)
        } else {
            this.props.FolloweUser(this.props.creativeById?.id)
            this.props.setLoadingFollow(true)
        }
    }
    render() {
        const { creativeById, openPopupFollowers, openPopupFollowing, allFollowerData, followingData, loading } = this.props;
         {/* @ts-ignore */}
        const antIcon = <LoadingOutlined  style={{ fontSize: 24, color: 'antiquewhite' }} spin translate />;
        const token: any = localStorage.getItem("token")
        const user_id: any = localStorage.getItem("loggedIn_userId");
        const id_check= user_id


        if (Object.keys(creativeById).length > 0) {
            return (
                <Grid item xs={12} sm={9} md={9} className="self-profile">
                    {token && creativeById.id !== id_check && (
                        <Grid container item xs={12} sm={8} lg={6} className="buttongroup-grid">
                            <ButtonGroup aria-label="outlined primary button group" className="btn-group-style" >
                                <Button className="one-button" style={{border:"1px solid red"}} onClick={() => this.props.setopenScanQr(true)}>
                                    <img src={messageProfile} className="icons" style={{ fontFamily: "karla" }} />
                                </Button>
                                <Button className="follow-button" id="followBtn" disabled={this.props.loadingFollow}
                                    style={{ fontFamily: "karla" }}
                                    onClick={this.handlePopupVisible}> {this.props.loadingFollow ?
                                        <Spin indicator={antIcon} /> : this.handleFollowState()
                                    }</Button>

                                <Button className="third-button" disabled={true} style={{ fontFamily: "karla" }}>
                                    <img src={keyIcon} className="icons" style={{ fontFamily: "karla" }} />
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    )}
                    <Grid container item xs={12} sm={creativeById.id !== id_check ? 4 : 8} md={6} className="icons-group">
                        <Grid container item xs={12} className="justify-center-compose">
                            <Grid item xs={4} sm={creativeById.id !== id_check ? 12 : 6} md={6} lg={6} className="div-group">
                                <Grid item xs={6}>
                                    <img src={profileperson} className="icons-img cursor-pointer" onClick={() => {
                                        this.props.setopenPopupFollowers(true)
                                        this.props.getFollowerData()
                                    }}
                                    />
                                    <ProfileFollowing open={openPopupFollowers}
                                        unfollowUserById={this.props.unfollowUserById}
                                        followUserByID={this.props.followUserByID}
                                        creativeById={creativeById}
                                        handleClose={() =>
                                        this.props.setopenPopupFollowers(false)
                                        }
                                        data={allFollowerData} loading={loading} value="followers" />


                                    

                                        <Typography className="icons-text cursor-pointer"><Typography className="icons-text"> {creativeById?.attributes?.followers_count} </Typography> </Typography>
                                       

                                    
                                </Grid>
                                <Grid item xs={6}>
                                    <img src={profileinvite} className="icons-img cursor-pointer" onClick={() => {
                                        this.props.setopenPopupFollowing(true)
                                        this.props.getFollowingData()

                                    }} />

                                    <ProfileFollowing open={openPopupFollowing}
                                        handleClose={() => this.props.setopenPopupFollowing(false)
                                        }
                                        data={followingData}
                                        unfollowUserById={this.props.unfollowUserById}
                                        followUserByID={this.props.followUserByID}
                                        creativeById={creativeById}
                                        loading={loading} value="following" />

                                    {/* count */}
                                        <div className="icons-text "> <Typography className="icons-text" style={{ fontFamily: "karla" }}> {creativeById?.attributes?.following_count} </Typography></div>
                                        
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
              <SendMessage openScanQr={this.props.openScanQr} setOpenScanQr={() =>
            this.props.setopenScanQr(false)
            } handleRedirectToMessage={this.props.handleRedirectToMessage} avatar={creativeById?.attributes?.avatar}
              user_name={creativeById?.attributes?.user_name}
              CurrentId={creativeById?.id}
              />

                <Modal title="" visible={this.props.visible} footer={null} onCancel={() => { this.props.setVisible(false)}} style={{ textAlign: 'center' }} >
                  <h4 style={{ color: 'white' }}>Do you really want to unfollow?</h4>
                  <Button style={{ color: 'white', background: 'red' }} onClick={() => {
                    this.props.setVisible(false)
                  }}>No</Button> &nbsp;&nbsp;
                  <Button style={{ color: 'white', background: 'green' }} onClick={() => {
                    if (creativeById?.id) {
                      this.props.setLoadingFollow(true)
                      this.props.setVisible(false)
                      this.props.UnFolloweUser(creativeById?.id)
                    }
                  }}>Yes</Button>
                </Modal>
                </Grid>
            )
        } else {
            return null
        }
    }
}
// Customizable Area End