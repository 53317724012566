// Customizable Area Start
import React from "react"

import { createBrowserHistory } from "history";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { playlistProfile } from "blocks/dashboard/src/assets";
import { HISTORY } from "components/src/common";


export const configJSON = require("../config");

const history = createBrowserHistory()
export interface Props {
  props?: any;
  navigation?: any;
  musicButton?:  any;
  labelId?: any;
  item?: any;
  apiFilterValue?: any;
}
interface S {
    TopPlaylists: any;
    apiToken: any;
    filterValue: any;

}
interface SS {
  id: any;
}

export default class LabelTopSongsController extends BlockComponent<Props, S, SS> {
    
    getTopPlaylistApiId: string = "";

    constructor(props: Props) {
        super(props);
        
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
    
        this.state = {
            TopPlaylists: [],
            apiToken: localStorage.getItem("token"),
            filterValue: ''
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      super.componentDidMount();
      this.getTopPlaylistsData();
      this.setState({filterValue: ''})

    }

    async componentDidUpdate(prevProps: any, prevState: any) {
      
        if (this.props.musicButton !== prevProps.musicButton) {
          this.getTopPlaylistsData();
        }

        if (this.props.apiFilterValue !== prevProps.apiFilterValue) {
          this.getTopPlaylistsData();
        }
      }

    getTopPlaylistsData = () =>{
      const header = {
        "Content-Type": "application/json",
        'token': this.state.apiToken
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getTopPlaylistApiId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_audiomusic/label_profile_filter?time_period=${this.props.apiFilterValue}&user_id=${this.props.labelId}&type=${this.props.musicButton ? 'video' : 'audio'}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

     topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
        const { handleAudioVideo, setHandleAudioVideo, handlePlayList } = this.context;

        
          if (handleAudioVideo === 1 || handleAudioVideo === 0) {
           
            setHandleAudioVideo(2)
           // handlePlayList(item, index, songsList, trackName)
          } else {
            setHandleAudioVideo(1)
          }
        
      }
      
      setCover = (item: any) => {
        if(item?.attributes?.art_work){
          return item?.attributes?.art_work
        }else{
          return playlistProfile
        } 
      }

    getTopPlaylistApiSuccessCall = (response: any) =>{
      if(response.data){
        this.setState({TopPlaylists: response?.data})
      }else if(response.message == "No playlist found for this filter criteria"){
        this.setState({TopPlaylists: []})
      }
    }

    onFullScreen = (item: any) => {     
      if (item.type == "playlist") {
        this.handleRedirectPlaylist(item)
      } else {
      HISTORY.push({
        pathname: "/home/expand/song/songId=banner",
        state: {
          data: item
        }
      })
    }
  }

    handleRedirectPlaylist = (id: any) => {
      const user_id: any = localStorage.getItem("loggedIn_userId");
      if (this.props.labelId !== user_id) {
        localStorage.setItem("playlist_type", "Public");
        HISTORY.push({
          pathname: `/home/playlist/public-view/${id.id}`,
          state: { creativeId: id.id }
        });
        window.location.reload();
      } else {
        localStorage.setItem("playlist_type", "Private");
        HISTORY.push({
          pathname: `/home/self-view/${id.id}`,
          state: { creativeId: id.id }
        });
        window.location.reload();
      }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
    
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
         if( apiRequestCallId === this.getTopPlaylistApiId){
          this.getTopPlaylistApiSuccessCall(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }

      }
}
// Customizable Area End