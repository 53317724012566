// Customizable Area Start
import React from 'react';
import { Box, Button } from '@material-ui/core'
// Customizable Area End


// Customizable Area Start
interface Props {
    selectedPage:string;
    selectPage: (page:string) => void;
}
// Customizable Area End

// Customizable Area Start
export const MediaTypeSelector = (props: Props) => {
    const handleTrackSelect = (e: any) => {
        if(props.selectedPage !== 'Track'){
            props.selectPage('Track')
        }
    }

    const handleSongSelect = (e: any) => {
        if(props.selectedPage !== 'Song'){
            props.selectPage('Song')
        }
    }

    const handleVideoSelect = (e: any) => {
        if(props.selectedPage !== 'Video'){
            props.selectPage('Video')
        }
    }
    return (
        <Box className="MediaTypeSelectorBox">
            <Button className={`TrackBtn ${props.selectedPage === 'Track' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={handleTrackSelect}>Tracks</Button>
            <Button className={`SongBtn  ${props.selectedPage === 'Song' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={handleSongSelect}>Songs</Button>
            <Button className={`VideoBtn  ${props.selectedPage === 'Video' ? 'SelectedBtn' : 'NotSelectedBtn'}`} onClick={handleVideoSelect}>Videos</Button>
        </Box>
    );
}
// Customizable Area End


// Customizable Area Start
export default MediaTypeSelector;
// Customizable Area End