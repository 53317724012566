// Customizable Area Start
import React from 'react';
import { Box, Typography, Avatar, Collapse,Accordion,  withStyles,
} from '@material-ui/core';
import { findDuration } from '../Comments/commentTimeConvertion.web';
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { viewreplay, VideoUserAvatar } from '../../assets';
import CommentController from './CommentController'
import './AudioVideoInfoCardCP.web.css'
// Customizable Area End


// Customizable Area Start


const AccordionSummary = withStyles({
    root: {
      backgroundColor: "#000000",
      marginBottom: -1,
      flexDirection: "row-reverse",
      justifyContent: "end",
      padding: "0px 0px",
      minHeight: "25px!important" as any,

      "&$expanded": {
        minHeight: "0px",
      },
    },
    content: {
      margin: "0px 12px",
      "&$expanded": {
        margin: "0px 0px",
      },
    },
    expandIcon: {
      order: 0,
      margin: "0px -68px",
    },
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles({
    root: {
      padding: "0px 0px",
    },
  })(MuiAccordionDetails);

// Customizable Area End

// Customizable Area Start
export default class Comment extends CommentController {
   render(){
    return (
        <Box id={`${this.commentID(this.props.selectedComment)}`}  className={`CommentContainer`} onClick={this.handleClick}>
            <Box className={`AvatarBox`}>
                <Avatar src={this.props?.selectedComment?.attributes?.user_image || VideoUserAvatar} className={`Avatar`} onClick={() => this.profileRedirection()}/>
            </Box>
            <Box className='CommentBox' style={{}}>
                {/* Main Comment */}
                <Box className='MainCommentBox'>
                    <Box className='FirstRow'>
                        <Typography className='UserNameText'>{this.props?.selectedComment?.attributes?.user_name}</Typography>
                        <Typography className='TimeText'>{findDuration(new Date(this.props?.selectedComment?.attributes?.created_at).getTime() / 1000)}</Typography>
                    </Box>
                    <Box className='CommentTextBox'>
                        <Typography className='CommentText'>{this.props?.selectedComment?.attributes?.description} </Typography>
                    </Box>
                </Box>
                {/* Main Comment END */}

                {/* Replies  */}
                <Accordion
                          expanded={this.state.expanded === this.props?.index}
                          onChange={this.handleAccodionChange(this.props?.index)}
                          className="comment_replay_main"
                          style={{width: '100%'}}
                        >
                          {
                      this.props?.selectedComment?.attributes?.replies?.length > 0 ?
                <AccordionSummary
                    expandIcon={
                      <img
                        src={viewreplay}
                        alt="expandUp"
                        className="expandIconUp"
                      />
                    }
                    aria-controls="panel1a-content"
                    IconButtonProps={{ edge: "start" }}
                    id="panel1a-header"
                  >
                      <div className="replayComment">
                      <span className="repliesFont">
                        {this.props?.selectedComment?.attributes?.replies.length} Replies
                      </span>
                      <span className="replayFont" onClick = {() => this.sendReply(this.props?.selectedComment)}>Reply</span>
                    </div>
                    </AccordionSummary>
                    :
                    (
                      <span className="replayFont" onClick = {() => this.sendReply(this.props?.selectedComment)}>Reply</span>
                    )
                    }
                  <AccordionDetails style={{ flexDirection: "column" }}>
                {           
                    this.props?.selectedComment?.attributes?.replies?.length > 0 &&
                    this.props?.selectedComment?.attributes?.replies.map((reply: any) =>{
                         return (
                            <>
                            <Collapse in={true} style={{width:'100%'}}>
                                <Box className='SubCommentContainer'>
                                    <Box className={`AvatarBox`}>
                                        <Avatar src={reply?.attributes?.user_image} className={`Avatar`} />
                                    </Box>
                                    <Box className='SubCommentBox'>
                                        <Box className='FirstRow'>
                                            <Typography className='UserNameText'>{reply?.attributes?.user_name}</Typography>
                                            <Typography className='TimeText'>{findDuration(new Date(reply?.attributes?.created_at).getTime() / 1000)}</Typography>
                                        </Box>
                                        <Box className='CommentTextBox'>
                                            <Typography className='CommentText'>{reply?.attributes?.description} </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Collapse>
                            </>
                         )
                    })
                }
                </AccordionDetails>
              </Accordion>

                {/* Replies ENd */}
            </Box>
        </Box>
    );
    
}
}
// Customizable Area End


// Customizable Area Start
// Customizable Area End
