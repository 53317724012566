// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

export interface Props {
    selected:boolean;
    isVideo:boolean;
    unread:boolean;
    comment:any;
    onUserCommentClick:(comment:any) => void;
    onUserProfileClick:(comment:any) => void;
    TrackIndex : any;
    setTrackIndex : (index: any) => void;
    selectedTrackIndex: any;
}

interface S {

}
interface SS {
  id: any;
}

export default class CommentRepliesController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiHandleCopyCountCallId: any;
  apiHandleVideoListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {

    
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

   handleClick = () => {
    this.props.onUserCommentClick(this.props.comment)
    this.props.setTrackIndex(this.props.TrackIndex)
}

 handleUserClick = (e: any) => {
    this.props.onUserProfileClick(this.props.comment)
    this.props.setTrackIndex(this.props.TrackIndex)
}

  
}
// Customizable Area End