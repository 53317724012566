// Customizable Area Start
import React from "react";
import "./TopSongs.web.css";
import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  iconUp,
  plays,
  reshared,
  commentBlue,
  defaultProfile,
  expandMore,
  down
} from "../../../dashboard/src/assets";
import millify from "millify";
import { HISTORY } from "../../../../components/src/common";
import { Col, Row } from "antd";
import LineChart from "components/src/CustomChart/PopUps/LineCharts.web";
import { useHistory } from "react-router-dom";


const TopVideoPlaylist = ({ songsList, beatList, audioPlay, handlePlayList, trackName, playPlaylist }: any) => {
const history=useHistory()
  const showVideos =  (songsList: any) => {
    handlePlayList(songsList)
  }

  const onFullScreen = (e: any, item: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (item.type == "playlist") {
      handlePlayList(item)
      console.log("playlist")
    } else {
    history.push({
      pathname: "/home/expand/song/songId=banner",
      state: {
        data: item
      }
    })
  }
  }
  
  return (
    <Box className="videos-parent-div" style={{
      width: "100%"
    }}>
       <Grid style={{ padding: "6px 0px 4px 0px", marginTop: "1%" }} container>
        <Grid item xs={7} md={7} lg={8}>
          <Row className=" width_100">
            <Typography className="top-head">{songsList && 'Top ' + trackName }</Typography>
          </Row>
        </Grid>
        <Grid item xs={4}
          // md={4} 
          md={4}
          lg={3}
          style={{ display: "flex" }}
          className="second-head-percentage"
        >
          <Hidden xsDown>
            <Grid item xs={3}>
              <p className=" text_white1 top_size_16 hiddnDaysScore " style={{ marginTop: "4px", }}>24hrs%</p>
            </Grid>
            <Grid item xs={4}>

              <p className="text_white1 top_size_16" style={{ marginTop: "4px",textAlign:"center" }}>7d%</p>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }} className="hiddnDaysGraph">
              <p className="text_white1 top_size_16 hiddnDaysGraph" style={{ marginTop: "4px" }}>
                Last 7d
              </p>
            </Grid>

          </Hidden>
        </Grid>
      </Grid>
      {/* listing part */}
      {/* <Box className={this.state.videoList.length > 0 ? "scroll-noslider" : beatList > 0 ? "scroll" : "scroll-noslider"}> */}
      {/* <Box className={beatList > 0 && playPlaylist ? "video-with-playList" : beatList > 0 && !playPlaylist ? "scroll" : "scroll-noslider-video"}> */}
      <Box 
      // className={beatList > 0 ? "scroll-noslider-video mostly-customized-scrollbar" : "scroll mostly-customized-scrollbar"}
      >

        {songsList && songsList.map((item: any, index: any) => {
let srcImg = defaultProfile;

if (trackName === "Playlists") {
  srcImg = item?.attributes?.cover_image;
} else if (item?.attributes?.art_work) {
  srcImg = item.attributes.art_work;
}
          return (

<Col span={24} onClick={() => showVideos(item)} className="musicRow">
<Grid container style={{ padding: "6px 0px 4px 0px" }} className="main-container-space">
  <Grid item md={4} lg={3} xs={3} sm={4}
    className="profileDetailBlock">

    <div className=" width_100 " style={{ display: "flex" }}>
      <div>
        <Row className="sn-grid mr_1 ml_05">
          {index < 9 ? "0" + Number(index + 1) : index + 1}
        </Row>

        <img src={item.attributes.arrow?iconUp:down} className="icon-up mr_05 ml_05" onClick={item.handlePlayList} />
      </div>
      <div>
        <img style={{ borderRadius: "8px", border: "solid 1px gray" }}
          className="song-cover mr_1" width="50px" height="50px"
          src={srcImg} alt=""
        />
      </div>
      <div >
        <Row>
          <Row
            justify="start" className="width_100 playlist_video_title text_white cursor_pointer "> { item?.attributes?.name || 'Unknown'}</Row>
          <Row
            justify="start" className="width_100 text_gray font_12  cursor_pointer artist-name-width">{item?.attributes?.artist_name || 'Unknown'}</Row>
        </Row>
      </div>
    </div>
  </Grid>
  <Col xs={6} className="HiddnFullScreen hours-list">
    <Row justify="end" className="iconDelete">
      <img src={expandMore} className=" ml-1 mr_1" height="28px" width="28px" />
    </Row>
  </Col>
  <Grid item
    md={1}
    xs={2} sm={4} lg={3}
    className="playButtonContain">
    <Row className=" width_100" style={{ paddingTop: 10 }}>

      <Hidden xsDown>
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
         
        </Col>
      </Hidden>

    </Row>
  </Grid>
  <Grid item sm={3} md={2} lg={2}
    className="iconButton">

    <div className=" display-play_dark_back" style={{ paddingTop: 10 }}>
      <Col className="colButton width_100">
        <div style={{ display: "flex" }} className='iconContent'>
          <img className="iconImg" 
          src={plays}
          height= "24px"
          width= "24px"
          />
          <Box className="play-times iconContent">
            {item?.attributes?.play_count ? millify(item?.attributes?.play_count) : "0"}
          </Box></div>
      </Col>
      <Col className="colButton width_100">
        <div style={{ display: "flex" }} className='iconContent'>
          <img className="iconImg" src={reshared} height="24px" width="24px" />
          <Box className="play-times iconContent">
            {item?.attributes?.repost_count ? millify(item?.attributes?.repost_count) : "0"}
          </Box>
        </div> </Col>
      <Col className="colButton width_100">
        <div style={{ display: "flex", alignItems: "flex-end" }} className='iconContent'>
          <img className="iconImg" src={commentBlue} height="20px" width="20px" />
          <Box className="play-times iconContent">
            {item?.attributes?.comments_count ? millify(item?.attributes?.comments_count) : "0"} </Box>
        </div>
      </Col>
    </div>
  </Grid>
  <Grid item sm={5} md={5} lg={4}
    className="hidden_percentage"
  >
     {/* <Hidden xsDown> */}
     <LineChart
                       seven_days={item?.attributes?.last_seven_days_percentage}
                       twenty_four_days={item?.attributes?.last_twenty_four_hours_percentage}
                       seven_days_graph={item?.attributes?.last_seven_days_graph}
                       fullscreen={expandMore}
                       onFullScreen={(e: any) => onFullScreen(e, item)}
                     />
                  {/* </Hidden> */}
  </Grid>
</Grid>
</Col>
          );
        })}
      </Box>
    </Box>
  );
}
export default TopVideoPlaylist;
// Customizable Area End