// Customizable Area Start
import React from "react";
import { Text, View, ImageBackground, Image, StatusBar } from "react-native";
import SplashScreenController, { Props } from "./SplashscreenController";
import styles from "./SplashScreenStyles";
import { backgroundImage, brandLogo } from "./assets";

export default class SplashScreen extends SplashScreenController {
  constructor(props: Props) {
    super(props);
  }

  renderLogoView = () => {
    return (
      <View style={styles.logoview}>
        <Image source={brandLogo} style={styles.logo} />
        <Text style={styles.projectname}>NXZSound</Text>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />
        <ImageBackground source={backgroundImage} style={styles.bgimg}>
          {this.renderLogoView()}
        </ImageBackground>
      </View>
    );
  }
}
// Customizable Area End
