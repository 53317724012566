// Customizable Area Start
import React from "react";

const RowAlbum = (props: any) => {
  return (
    <div className="boxes">
      <div className="head head-remove-pd">
        <div>
          <h2>{props.title}</h2>
        </div>
        <div>
          <a href="#">
            <h4 style={{
              fontSize: "18px",
              fontWeight: 500,
              color: "#fff",
              marginLeft: "9px",
              paddingTop: "7px"
            }}>SEE ALL</h4>
          </a>
        </div>
      </div>
    </div>
  );
};

export default RowAlbum;
// Customizable Area End
