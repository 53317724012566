// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";

export const configJSON = require("../config");


export interface Props {
props:any;
}


interface S {
}

interface SS {
    id: any;
}

export default class AdConfirmationController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
           
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    redirectToPushStats=()=>{
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationScreenNameMessage), {});
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PushPlayerAdResults');
        this.send(msg);
        }
    
}

// Customizable Area End