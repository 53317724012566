// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");
import { Message } from "../../../../../framework/src/Message";
import { setStorageData } from "../../../../../framework/src/Utilities";
import { HISTORY } from "components/src/common";

export interface Props {
  navigation: any;
  id: string;
  key: string;
  topPlayMusic:any;
  getStatusDetails:any
}

interface S {
  active: string;
  heading: string;
  index: string;
  status: string;
  threebtnToogle:any;
  showdetails: boolean;
  selectedIndex: any;
  selectedTab: any;
  filterModal: boolean;
  filterDetailModal: boolean;
  loading: boolean;
  selectType: any;
  typeDetail: any;
  searchText: any;
  trackList: any;
  top40trackList: any;
  mastertrackList: any;
  mastertop40trackList: any;
  trackDetailsId:any;
  openFilterPopup:boolean;
}

interface SS {
  id: any;
}

export default class TracksController extends BlockComponent<Props, S, SS> {
  subscriptionPlan = localStorage.getItem("subscription_plans");

  getTop100ListsApiCallId: any;
  apiTracksList: string = "";
  apiDraftTracksList: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      active: "0",
      heading: "tracks",
      index: "beats",
      status: "Private",
      threebtnToogle: "beats",
      showdetails: false,
      selectedIndex: "",
      selectedTab: "beat",
      filterModal: false,
      filterDetailModal: false,
      loading: false,
      selectType: "trackTab",
      typeDetail: "",
      searchText: "",
      trackList: [],
      top40trackList: [],
      mastertrackList: [],
      mastertop40trackList: [],
      trackDetailsId:0,
      openFilterPopup:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAllSplits("beat");
  }

  // componentDidUpdate(prevProps: any, prevState: any) {
  //   if (prevState.index !== this.state.index) {
  //     this.getAllSplits(this.state.index);
  //     // this.setState({index:this.state.index})
  //   }
  // }

  
  handleCloseFilterPopup=()=>{
    this.setState({openFilterPopup:false})
  }
  
handleShowDetails = () => {
     this.setState({ showdetails: false })
}

  handleOpenFilterPopup=()=>{
    this.setState({openFilterPopup:true})
  }
  changetabs = (tabs: string) => {
    this.setState({ index: tabs, selectedIndex: "", showdetails: false ,threebtnToogle:tabs});
  };

  selectedsong = (index: any,trackId:any, is_drafted: any) => {
   if(is_drafted){
      this.getAllDraftTracks(trackId)
   }
   else{
    const topDiv = document.getElementById('yourTopDivId12');
    if (topDiv) {
      topDiv.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
    this.setState({ selectedIndex: index, showdetails: true ,trackDetailsId:trackId});
   }
  };


  getAllDraftTracks = (trackId: any) => {
    
      const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDraftTracksList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/show_track?id=${trackId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 

  handleTrackSearch=(e:any)=>{
    let text = e.target.value
    if (text) {
      const top40data = this.state.mastertop40trackList.filter(
          function (item: any) {
              const itemData = item.attributes.title
                  ? item.attributes.title.toUpperCase()
                  : ''.toUpperCase();
              const textData = text.toUpperCase();
              return itemData.indexOf(textData) > -1;
          }
      );
      this.setState({top40trackList:top40data})


      const splitData = this.state.mastertrackList.filter(
          function (item: any) {
              const itemData = item.attributes.title
                  ? item.attributes.title.toUpperCase()
                  : ''.toUpperCase();
              const textData = text.toUpperCase();
              return itemData.indexOf(textData) > -1;
          }
      );
      this.setState({trackList:splitData,
      searchText:text
      })
    
  } else {
    this.setState({top40trackList:this.state.mastertop40trackList,
      trackList:this.state.mastertrackList,
      searchText:text
      })
    
  }
  }

  getAllSplits = (type: any) => {
    if (type.endsWith("s")) {
      type = type.slice(0, -1); // Remove the last character
    }
    
    this.setState({ loading: true});
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTracksList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getAllTracks}?upload_type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDraftTrackList = async( resposneJson: any) =>{

    if(resposneJson.data[0].id){
      await setStorageData('selected_draft_song', resposneJson.data[0])
      HISTORY.push('/uploadTrack/audio')
    }
    
  }

  handleTrackList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.top_forty_tracks?.data) {
        this.setState({
          top40trackList: responseJson?.top_forty_tracks.data,
          mastertop40trackList: responseJson?.top_forty_tracks.data,
          loading:false
        });
      } else {
        this.setState({
          top40trackList: [],
          mastertop40trackList: [],
          loading:false
        });
      }
      if (responseJson?.all_tracks?.data) {
        this.setState({
          trackList: responseJson.all_tracks.data,
          mastertrackList: responseJson.all_tracks.data,
          loading:false
        });
      } else {
        this.setState({
          trackList: [],
          mastertrackList: [],
          loading:false
        });
      }
    } else {
      this.setState({
        top40trackList: [],
        trackList: [],
        mastertop40trackList: [],
        mastertrackList: [],
        loading:false
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

     if (apiRequestCallId == this.apiTracksList) {
      this.handleTrackList(responseJson);
    } else if (apiRequestCallId == this.apiDraftTracksList) {
      this.handleDraftTrackList(responseJson);
    }  

    // Customizable Area End
  }
}
// Customizable Area End
