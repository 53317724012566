// Customizable Area Start
import React from 'react'
import ScanProcess from './ScanProcess.web';
import UploadCover from './UploadCover.web';
import TrackDetails from './TrackDetails.web';
import LinkTrack from './LinkTrack.web';
import SellingPreferences from './SellingPreferences.web';
import AddSplits from './AddSplits.web';
import UploadComplete from './UploadComplete.web';

const SubStep = (props :any) =>{
  const {step, setActiveStep, setBackStep, redirectSplit} : any = props;
  return(
    <>
    {
      step == 4 &&
      <SellingPreferences  setActiveStep={()=>{setActiveStep(5)}} setBackStep={() =>{setBackStep(3)}} />
    }
    {
      step == 5 &&
      <AddSplits setActiveStep={()=>{setActiveStep(6)}} setBackStep={() =>{setBackStep(4)}} redirectSplit={(step: any) => {redirectSplit(step)}}/>
    }
    {
      step == 6 &&
      <UploadComplete /> 
    }
    </>
  )
}

function ChangeStep(props: any) {
    const {step, setActiveStep, setBackStep, redirectSplit} : any = props;
  return (
    <>
    {
      step == 0 &&
      <ScanProcess setActiveStep={() =>{setActiveStep(1)}} redirectSplit={(step: any) => {redirectSplit(step)}}/>
    }
    {
      step == 1 &&
      <UploadCover setActiveStep={()=>{setActiveStep(2)}} setBackStep={() => {setBackStep(0)}} />
    }
    {
      step == 2 &&
      <TrackDetails setActiveStep={()=>{setActiveStep(3)}} setBackStep={() =>{setBackStep(1)}} /> 
    }
    {
      step == 3 &&
      <LinkTrack setActiveStep={()=>{setActiveStep(4)}} setBackStep={() =>{setBackStep(2)}}/>
    }
    <SubStep step={step} setActiveStep={setActiveStep} setBackStep={setBackStep} redirectSplit={(step: any) => {redirectSplit(step)}}/>
    </>
  )
}
export default ChangeStep
// Customizable Area End