// Customizable Area Start
import moment from "moment";

export const findDuration = (fromDate: number) => {
    let myDate = moment(fromDate * 1000).format("YYYY/MM/DD HH:mm:ss");

    if (myDate === "Invalid date") {
        myDate = moment(fromDate, "MM-DD-YYYY HH:mm:ss").format(
            "YYYY/MM/DD HH:mm:ss"
        );
    }

    const date1 = moment(myDate);

    const date2 = moment(new Date());
    
    const days = date2.diff(date1, "days");

    if (days > 1) {

        return `${days} days`;
    }else if(days == 1){
        return `${days} day`;
    }


    const hours = date2.diff(date1, "hours");
    if (hours > 0) {
        return `${hours} hr`;
    }
    const minutes = date2.diff(date1, "minutes");
    if (minutes > 0) {
        return `${minutes} min`;
    }

    return "Just Now";
};
// Customizable Area End