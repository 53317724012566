// Customizable Area Start
// @ts-nocheck
import React from 'react'
import {
  Grid,
  Dialog,
} from "@material-ui/core";
import { Row, Col } from "antd";
import './share-playlist-popup.css'
import { LinkIcon, Repost, Send } from './assets';
import SharePlaylistPopupController from './SharePlaylistPopupController.web';
import MessageLinkPopup from 'components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web';
import LinkCopiedPopup from '../LinkCopiedPopup/LinkCopiedPopup.web';
import RepostPopup from 'components/src/CustomChart/PopUps/RepostPopup.web';
import { defaultProfile } from '../../assets';
import QRcode from 'qrcode.react';


export class SharePlaylistPopup extends SharePlaylistPopupController {

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        PaperProps={{ className: "dialog-Paper-share-popup" }}
      >
        <Row justify="center" style={{ width: '100%' }}>
          <Col style={{ width: '100%' }}>
            <Grid container className='container_share' style={{ width: '100%', justifyContent: this.handleSpace() }}>

              <Grid item >
                <QRcode
                  className="qrCode-img-playlist"
                  id="myqr"
                  value={this.onScanQR()}
                  size={135}
                  renderAs="canvas"
                />
              </Grid>
              <Grid item >

                <Grid container className='mobile_margin_top' style={{ display: 'flex', justifyContent: `${this.props.isPlaylistRepost ? 'space-evenly' : 'space-between'}`, width: `${this.props.isPlaylistRepost ? '300px' : this.handleProfileWidth()}` }}>
                  <Grid item style={{ display: `${this.props.isPlaylistRepost ? 'none' : this.handleProfileHide()}`, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} onClick={this.handleRepost}>
                    <img src={Repost} style={{ width: '60px', height: '60px' }} />
                    <div style={{ color: 'white', fontSize: '12px', fontWeight: 800 }}>Repost</div>
                  </Grid>
                  <Grid item style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', flexDirection: 'column', alignItems: 'center' }} onClick={this.onSingleCopy}>
                    <img src={LinkIcon} className='image_dimension' />
                    <div className='imagetext'>Copy Link</div>
                  </Grid>
                  <Grid item style={{ display: `${this.props.id == "Profile" ? 'none' : 'flex'}`, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} 
                  onClick={this.linkopenPopup}>
                    <img src={Send} className='image_dimension'/>
                    <div className='imagetext'>Send</div>
                  </Grid>

                  {
                    this.state.openMessageSendPopup &&
                    <MessageLinkPopup id="Playlist"
                      copiedLinked={this.state.CopiedForLink}
                      showMsgModal={this.state.openMessageSendPopup} closeMsgModal={this.handleOpenMessageSendPopup}
                      closeModalDialog={this.handleOpenMessageSendPopup} currentUserId={1}
                    />
                  }

                  {
                    this.state.copied &&
                    <LinkCopiedPopup id="Playlist" open={this.state.openLinkPopup} handleClose={this.handleLinkCopied} />
                  }

                  {this.state.openRepostPopup && <RepostPopup open={this.state.openPopUp}
                    onClose={this.handleRepostClose}
                    profileImage={this.state.repostingSong?.attributes?.art_work || this.state.repostingSong?.attributes?.cover_image || defaultProfile}
                    artistName={this.state.repostingSong?.attributes?.artist_name}
                    songName={this.state.repostingSong?.attributes?.title}
                    repost_count={this.respostSubmit}
                    _handleTextFieldChange={this.handleCaptionChange}
                  />}

                </Grid>
              </Grid>
            </Grid>
          </Col>
        </Row>
      </Dialog>
    )
  }
}

export default SharePlaylistPopup
// Customizable Area End