// Customizable Area Start
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {Dialog ,DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Link} from '@material-ui/core';



const LoginSearchPopup = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
 const handleClose = () => {
    history.push('/home')
    setOpen(false);
  };

  const onLogin =() =>{
    history.push('/welcome')
  }

  const onBack =() =>{
    history.push('/home')
  }

  useEffect(() =>{
    setOpen(true)
  },[])

  return (<>
      {/* <div style={{textAlign: 'center', color: 'grey'}}>Please <button  style={{color: 'white', backgroundColor: 'red', borderRadius: '20px'}} onClick={onLoginPage}>LOGIN</button> to continue</div> */}
       <div style={{justifyContent: 'center'}}>
          <Dialog
          open={open}
          onClose={handleClose}
          //style={{width: '550px', alignItems: 'center'}}
          >
           <DialogTitle id="alert-dialog-title">
              {"NXZSound10"}
            </DialogTitle>
             <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <div>
                  Get a personalized experience,and access all your music.    
                  </div>
                  <div>
                  To access this feature, go to <Link id="LoginSearchPopup_welcomepush_click_test_id" onClick={()=>{history.push('/welcome')}} style={{color: 'red'}}>Login</Link> screen.
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
           <DialogActions>
              <Button id="LoginSearchPopup_login_click_test_id" style={{color: 'blue'}} onClick={onLogin}>Login</Button>
              <Button id="LoginSearchPopup_back_click_test_id" style={{color: 'blue'}} onClick={onBack} autoFocus>
               Go Back
              </Button>
        </DialogActions>
          </Dialog>
        </div>
    </>)
  
}

export default LoginSearchPopup
// Customizable Area End