// Customizable Area Start
import { Carousel, Input, Row } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import {
    searchshape,
    playButton,
} from "../../../../dashboard/src/assets";
import TrackDetails from "../TrackDetails.web";
import { nxzloader } from "blocks/dashboard/src/assets";
import CtrlFilterPopup from "../Common/CtrlFilterPopup.web";
import MobileCtrlvideosController from "./MobileCtrlvideosController.web";



const MobilectrlTotalSplits = styled(Box)({
    color: "white",
    width: "100%",
    "@media(max-width: 600px)": {
        height: "auto",
    },
});

const MobilectrlvideoDivWrapper = styled(Box)({
    display: "flex",
    minHeight: "calc(100% - 3rem)",
    justifyContent: "space-between",
    padding: "30px 50px 0px 27px",
    gap: "1%",
    "@media(max-width: 1024px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 5px",
        gap: "0%",
        minHeight: "0px"
    },
});

const CtrlvideosSplitboxRightsidecontainer = styled(Box)({
    display: "flex",
    gap: "15px",
    flexDirection: "column" as "column",
   

});
const CtrlvideosSplitleftsidecontainer = styled(Box)({
    display: "flex",
    height: "max-content",
    gap: "20px",
    marginTop: "20px",
    flexDirection: "column" as "column",
    marginBottom: "10px",
    "@media(max-width:1024px)": {
        width: "auto",
        height: "auto",
        gap: "20px",
        marginTop: "20px",
    },
});
const VideosctrlInputboxwrapper = styled(Box)({
    borderRadius: "27px",
    padding: "10px",
    position: "relative",
    width: "100%",
    background: "#212121",
    "& .ant-input": {
        backgroundColor: "#212121",
        width: "88%",
        color: "white",
    },
    "& .ant-input-affix-wrapper": {
        background: "none",
        border: "unset",
    },
    "@media(max-width: 1024px)": {
        width: "100%",
        "& .ant-input": {
            backgroundColor: "#212121",
            width: "50%",
        },
    },
});
const CtrlmobileVideosboxRightside = styled(Box)({
    display: "flex",  
    gap: "15px", 
    flexDirection: "column" as "column",

});
const CtrlvideoLeftsideAlltracksbox = styled(Box)({
    width: "100%",
    "@media(max-width: 1024px)": {
        width: "100%",
    },
});
const CtrlvideosLeftSideSingletrackbox = styled(Box)({
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    display: "flex",
    objectFit: "contain",
    cursor: "pointer",
    color: "white",
    justifyContent: "space-between",
    alignItems: "center",
  
    "&:hover": {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        objectFit: "contain",
        background: "#141414",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
    },
});
const CtrlvideosLeftsideAlltracksboxwrapper = styled(Box)({
    overflowY: "auto",
    scrollbarWidth: "none",
    maxHeight: "693px",
    marginTop: "-20px",
    "&::-webkit-scrollbar": {
        width: "0px",
        display: "none",
       
    },
});
const CtrlmobileTrackname = styled(Box)({
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
    lineHeight: "20px",
    letterSpacing: "normal",
    "@media(max-width: 400px)": {
        whiteSpace: "unset",
        fontSize: "14px"
    },
});
const Ctrlvideosettings = {

    autoplay: false,
    dots: false,
    infinite: true,
    swipeToSlide: true,
    draggable: true,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 601,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 590,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 573,
            settings: {
                slidesToShow: 1.4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 540,
            settings: {
                slidesToShow: 1.35,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 520,
            settings: {
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 503,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 468,
            settings: {
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 434,
            settings: {
                slidesToShow: 1.08,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 422,
            settings: {
                slidesToShow: 1.03,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 382,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 330,
            settings: {
                slidesToShow: 1.01,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 326,
            settings: {
                slidesToShow: 1.01,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 308,
            settings: {
                slidesToShow: 1.01,
                slidesToScroll: 1
            }
        }


    ]

};
export default class MobileCtrlvideos extends MobileCtrlvideosController {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { videoList, top40videoList, searchText } = this.state;
        return (
            //Merge Engine DefaultContainer
            <>
                {this.state.loading ? (
                    <Row
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "80vh",
                            alignItems: "center",
                            
                        }}
                    >
                        <img src={nxzloader} style={{ width: "5%" }} />
                    </Row>
                ) : (
                    <MobilectrlvideoDivWrapper>
                        <CtrlvideosSplitboxRightsidecontainer>
                            {this.state.showdetails ? (
                                <TrackDetails
                                    navigation={undefined}
                                    id={"1"}
                                    index={"video"}
                                    setShowDetails={() => this.setState({ showdetails: false })}
                                    trackDetails={this.state.selectedSongId}
                                    updateData={this.getVideos}
                                    topPlayMusic={(item:any)=>this.redirectVideo(item)}
                                   

                                />
                            ) : (
                                <>
                                    < CtrlmobileVideosboxRightside >
                                        <Box
                                            style={{
                                                display: " flex ",
                                                alignItems: "center",
                                                justifyContent: " space-between ",
                                               
                                            }}
                                        >
                                            <Typography style={webStyle.trackname}>
                                                <span style={webStyle.bluetext}>Top 40</span> Videos
                                            </Typography>

                                            <Typography style={webStyle.splittext}>
                                                This week
                                            </Typography>
                                        </Box>
                                        <VideosctrlInputboxwrapper>
                                            <Input
                                                test-id="test_search_term_id_handle_search_input"
                                                onChange={(text: any) => this.searchFilterFunction(text)}
                                                className="testCtrlVideo"
                                                prefix={
                                                    //@ts-ignore
                                                    <SearchOutlined
                                                        className="text_white1"
                                                        value={searchText}
                                                    />
                                                }

                                                placeholder="Search Videos"
                                            />
                                            <img src={searchshape} style={webStyle.searchimage}
                                            data-test-id="openFilterpopup"
                                                onClick={this.handleOpenVideo}
                                                
                                            />
                                        </VideosctrlInputboxwrapper>
                                        <Box style={{ width: "100%" }}>

                                            {top40videoList?.length > 0 ? (
                                                <Carousel {...Ctrlvideosettings} infinite={false}>
                                                    {top40videoList.map((item: any, index: any) => (
                                                        <Box style={{ height: "193px", width: "600px" }}>
                                                            <Box key={item?.id}
                                                                style={{
                                                                    borderRadius: "10px",
                                                                    position: "relative",
                                                                    background: `url("${item.attributes?.art_work}") lightgray 50% / cover no-repeat`,
                                                                    height: "193px",
                                                                    width: "350px",
                                                                    display: "flex",
                                                                    alignItems: "end",
                                                                    backgroundSize: "cover",
                                                                    backgroundPosition: "center",
                                                                   
                                                                }}>
                                                                <Box className="image_border">
                                                                    <Typography style={webStyle.videoImagetex}>
                                                                        {item.attributes.title}
                                                                    </Typography>
                                                                    <Typography style={webStyle.videoArtisttext}>
                                                                        {item.attributes.artist_name}
                                                                    </Typography>
                                                                </Box>

                                                                <img
                                                                    src={playButton}
                                                                    style={{
                                                                        height: "49px",
                                                                        width: "49px",
                                                                        right: "45%",
                                                                        top: "37%",
                                                                        position: "absolute",
                                                                        
                                                                    }}
                                                                    data-test-id="play-img"
                                                                    onClick={() => this.redirectVideo(item)}
                                                                />

                                                            </Box>

                                                        </Box>
                                                    ))}
                                                </Carousel>) :
                                                <Typography
                                                    style={{
                                                        paddingTop: "52px",
                                                        marginTop: "10%",
                                                       
                                                    }}
                                                    className="no_data"
                                                >
                                                    Data Not Found.
                                                </Typography>}


                                        </Box>
                                    </ CtrlmobileVideosboxRightside >
                                </>
                            )}
                        </CtrlvideosSplitboxRightsidecontainer>
                        {!this.state.showdetails && <CtrlvideosSplitleftsidecontainer>
                            <MobilectrlTotalSplits>
                                <Typography style={webStyle.trackname}>
                                    <span style={webStyle.bluetext}>{videoList?.length}</span>{" "}
                                    Videos
                                </Typography>
                            </MobilectrlTotalSplits>
                            <CtrlvideoLeftsideAlltracksbox>
                                <CtrlvideosLeftsideAlltracksboxwrapper>
                                    {videoList.length > 0 ? (
                                        videoList.map((item: any, index: any) => {
                                            const { typeColor, status } = this.getStatusDetails(item);
                                            return (
                                                <CtrlvideosLeftSideSingletrackbox
                                                    key={index}
                                                    onClick={() => {
                                                        this.selectedsong(index);
                                                        this.selectedSongId(item.id, item?.attributes?.is_drafted);
                                                    }}
                                                    style={
                                                        index === this.state.selectedIndex
                                                            ? webStyle.leftSideSingletrackbox
                                                            : {}
                                                    }
                                                    className="ctrl-videos-data-test"
                                                >
                                                    <Box style={webStyle.singleArtinfo}>
                                                        <img
                                                            src={item.attributes.art_work}
                                                            style={webStyle.leftsideimage}
                                                        />

                                                        <Box style={webStyle.leftsideownerstats}>
                                                            <CtrlmobileTrackname >
                                                                {item.attributes.title}
                                                            </CtrlmobileTrackname>
                                                            <span style={webStyle.splittext}>
                                                                {item.attributes.artist_name}
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    <Box style={webStyle.leftsideownerstats}>
                                                        <Box>
                                                            <Button
                                                                style={{
                                                                    border: `1px solid ${typeColor}`,
                                                                    borderRadius: "20px",
                                                                    color: typeColor,
                                                                    height: "23px",
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                {status}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </CtrlvideosLeftSideSingletrackbox>
                                            );
                                        })
                                    ) : (
                                        <Typography
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: "#fff",
                                                padding: "5",
                                                justifyContent: "center", marginTop: "10%"
                                            }}
                                        >
                                            Data Not Found.
                                        </Typography>
                                    )}
                                </CtrlvideosLeftsideAlltracksboxwrapper>
                            </CtrlvideoLeftsideAlltracksbox>
                        </CtrlvideosSplitleftsidecontainer>}

                        <CtrlFilterPopup
                            open={this.state.openVideoFilter}
                            handleClose={this.handleCloseFilter}
                            currentTab="Videos"
                            handleTrack={(data: any) => this.handleVideoList(data)}

                        />
                    </MobilectrlvideoDivWrapper>
                )}
            </>
            //Merge Engine End DefaultContainer
        );
    }
}

const webStyle = {
    leftsideimage: {
        borderRadius: "10px",
        width: "125.25px",
        minWidth: "125.25px",
        border: "1px solid #141414",
        height: "75px",
        objectFit: "cover" as "cover",
       
    },
    imageborder: {
        width: "380px",
        height: "76.275px",
        borderRadius: "8px",
        padding: "20px",
        border: "0px solid #000",
        background: "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)"

    },
    trackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        whiteSpace: "nowrap" as "nowrap",
        lineHeight: "20px",
        letterSpacing: "normal",
        color: "#ffffff",
        fontFamily: "Inter",
    },
    videoImagewrap: {
        width: "335.152px",
        position: "relative" as "relative",
        height: "193px",
        
    },
    imagevideo: {
        borderRadius: "8px",
        height: "193px",
        width: "335.152px",
        minWidth: "300px",
        
    },
    singlebutton: {
        border: "1px solid blue",
        borderRadius: "20px",
        height: "23px",
        color: "blue",
        textTransform: "capitalize" as "capitalize",
    },
    publicbutton: {
        border: "1px solid red",
        height: "23px",
        borderRadius: "20px",
        color: "red",
        textTransform: "capitalize" as "capitalize",
    },
    searchimage: {
        height: "18px",
        right: "6%",
        width: "21px",
        position: "absolute" as "absolute",
        top: "31%",
        zIndex: 10,
        cursor: "ponter"
    },

    videoImagetex: {
        top: "-22%",
        color: "#F0F0F5",
        left: "4%",
        fontFamily: "Karla",
        fontStyle: "normal",
        lineHeight: "20px",
        fontWeight: 700,
        fontSize: "14px",
    },
    videoArtisttext: {
        top: "-24%",
        color: "#8f92a1",
        fontWeight: 700,
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "20px",
        left: "4%",
        fontFamily: "Karla",
       
    },

    singleArtinfo: {
        display: "flex",
        gap: "15px",
        alignItems: "center",
        justifyContent: "center",
      
       
    },
    leftsideAlltracksbox: {
        width: "84%",
    },
    leftsideAlltracksboxwrapper: {
        marginTop: "-11px",
        overflowY: "scroll" as "scroll",
        height: "693px",
    },
    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        display: "flex",
        background: "#141414",
        color: "white",
        justifyContent: "space-between",
        alignitems: "center",
    },

    splittext: {
        fontSize: "16px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        color: "#8f92a1",
        letterSpacing: "normal",
        fontFamily: "Inter",
        lineHeight: "20px",
       
    },
    leftsideownerstats: {
        gap: "10px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column" as "column",
        
    },
    tracksimage: {
        height: "150px",
        borderRadius: "8px",
        width: "150px",
        
    },
    bluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        color: "#3959f9",
        fontWeight: 700,
        lineHeight: "1.29",
        letterSpacing: "normal",
      
    },
};
// Customizable Area End
