// Customizable Area Start
import { Typography, Tabs, Layout} from 'antd';
import {
    Box,
    styled,
    Button,
    Hidden
} from "@material-ui/core";
import React from 'react'
import { eye, profile, pushbanner,wallet } from '../assets';
import { Content } from 'antd/lib/layout/layout';
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import AdConfirmationController from './AdConfirmationController.web';

const LeftsideReposthistorycontainer = styled(Box)({
    width: "50%",
    display: "flex",
    paddingTop: "20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "40px",
    flexDirection: "column",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const Adconfirmationcontainer = styled(Box)({
    width: "100%",
    display: "flex",
    height: "90vh",
    overflowY: "scroll",
    padding: "15px 30px 0px 50px",
    gap: "40px",
    "@media(max-width: 1000px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const Leftsidebox = styled(Box)({
    width: "50%",
    "@media(max-width: 1000px)": {

        width: "100%"
    },
});


const { TabPane } = Tabs;
export default class AdConfirmation extends AdConfirmationController{

    render() {

        return (
            <>
                <Layout className='streamRepostWrapper'>
                <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                <Content
                        style={{
                            margin: "0px",
                            backgroundColor: "black",
                            display: "flex", flexDirection: "column",
                        }}
                    >
                <HomeHeader  />

                <Adconfirmationcontainer >
                    <Leftsidebox>
                        <Box style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
                            <img src={pushbanner} style={{
                                width: "100%",
                                gap: "0px",
                                height: "900px",
                            }}
                                className='repostimg' />
                        </Box>
                    </Leftsidebox>
                    <LeftsideReposthistorycontainer >
                        <Box style={webStyle.pushcontentfirstbox}>
                            <Box style={webStyle.innertextboxcontainer}>
                                <Typography style={webStyle.nxzsoundtext}>
                                    NXZSOUND
                                </Typography>
                                <Typography style={webStyle.pushtexts}>
                                    PUSH
                                </Typography>
                                <Button style={webStyle.completebutton}>ACTIVATED</Button>


                            </Box>
                            <Box style={webStyle.pushcontentcontainer}>
                                <Box style={webStyle.pushcontentbox}>
                                    <img src={eye} />
                                    <Box style={webStyle.pushcontentinnerbox} onClick={this.redirectToPushStats} data-test-id="redirectToPush">
                                        <Typography style={webStyle.pushtext}>
                                            Push Snapshot
                                        </Typography>
                                        <Typography style={webStyle.statstext}>
                                            View PUSH Stats
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box style={webStyle.pushcontentbox}>
                                    <img src={wallet} />
                                    <Box style={webStyle.pushcontentinnerNotAllowed}>
                                        <Typography style={webStyle.pushtext}>
                                            Wallet
                                        </Typography>
                                        <Typography style={webStyle.statstext}>
                                            View Balance
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box style={webStyle.pushcontentbox}>
                                    <img src={profile} />
                                    <Box style={webStyle.pushcontentinnerNotAllowed}>
                                        <Typography style={webStyle.pushtext}>
                                            Profile
                                        </Typography>
                                        <Typography style={webStyle.statstext}>
                                            View Catalog
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </LeftsideReposthistorycontainer>
                </Adconfirmationcontainer>
                </Content>
                </Layout>
            </>

        )
    }
}

const webStyle = {
    completebutton: {
        fontFamily: "Karla",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "16px",
        color: "#B3140A",
        width: "200px",
        height: "32px",
        textTransform: "capitalize" as "capitalize",
        background: "#070707",
        borderRadius: "31px",
        border: "2px solid #212121",
        letterSpacing: "10px",
    },
    pushtext: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "16px",
        color: "#FFFFFF"

    },
    pushcontentbox: {
        width: "325px",
        height: "75px",
        borderRadius: "75px",
        border: "1px solid #212121",
        display: "flex",
        alignItems: "center",
        paddingLeft: "7px",
        gap: "15px"
    },
    pushcontentinnerbox:{
       display:"flex",
       flexDirection:"column" as "column",
       gap:'10px',
       cursor:"pointer"
    },
    pushcontentinnerNotAllowed:{
        display:"flex",
        flexDirection:"column" as "column",
        gap:'10px',
        cursor:"not-allowed"
     },
    statstext: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        color: "#8F92A1BF"
    },
    pushcontentcontainer:{
        display: "flex", 
        gap: "15px", 
        flexDirection: "column" as "column", 
        placeItems: "center"
    },
    pushcontentfirstbox:{
        width: "70%",
        height: "60%", 
        display: 'flex', 
        flexDirection: "column" as "column", 
        gap: '49px', 
        placeItems: "center"
    },
    innertextboxcontainer:{
        display: "flex", 
        gap: "15px", 
        flexDirection: "column" as "column", 
    },
    nxzsoundtext:{
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.92em",
        color: "#FFFFFF"
    },
    pushtexts:{
        fontFamily: "Inter",
        fontSize: "73px",
        fontWeight: 900,
        lineHeight: "60px",
        color: "#FFFFFF"
    }

   
};

// Customizable Area End