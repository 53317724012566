Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config.js")
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PromoteContent";
exports.labelBodyText = "PromoteContent Body";
exports.baseUrl = urlConfig.baseURL;

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End