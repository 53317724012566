// Customizable Area Start
import React, { useState } from 'react'
import{Dialog, Grid } from "@material-ui/core";
import './index.web.css'
import './release_date_popup.web.css'
import { Row} from 'antd';
import { openNotification } from '../../../../components/src/Notification.web';
import { downArrowButton, setDate, todayDate, upArrowButton } from '../assets';

function ReleaseDatePopup({openDatePopUpFromChild,  handleDatePopUpFromChild ,getFullDateFromChild, setFieldValue, values}: any) {

     let today = new Date();

     const [day,setDay] = useState(String(today.getDate()).padStart(2, '0'));
     const [month,setMonth] = useState(String(today.getMonth() + 1).padStart(2, '0'));
     const [year,setYear] =useState(today.getFullYear());
     

  const setEndDay = () =>{
    let endDay = 0

    if (parseInt(month) === 4|| parseInt(month) === 6 || parseInt(month) === 9|| parseInt(month) === 11){
      endDay = 30
    }
    else if (parseInt(month)=== 2){
      endDay = 29
    }
    else{
      endDay = 31
    }
    return endDay;
  }

  const setDateUp =() =>{
    
    const endDay = setEndDay();

     if(parseInt(day) >= endDay){
        setDay('01');
     }
     else if (parseInt(day) === 0){
      if (parseInt(month) === 4|| parseInt(month) === 6 || parseInt(month) === 9|| parseInt(month) === 11){
        setDay('30')
      }
      else if (parseInt(month)=== 2){
        setDay('29')
      }
        else{
          setDay('31');
      }
     }
     else{
      setDay(`${parseInt(day)+ 1}`)
     }
  }

  const setDateDown =() =>{
    const endDay = setEndDay();

    if(parseInt(day) > endDay){
      setDay('01');
    }
   else if (parseInt(day) <= 1){
      if (parseInt(month) === 4|| parseInt(month) === 6 || parseInt(month) === 9|| parseInt(month) === 11){
        console.log(month, "month");
        setDay('30')
      }
      else if (parseInt(month)=== 2){
        setDay('29')
      }
        else{
          setDay('31');
      }
   }
   else{
    setDay(`${parseInt(day) - 1}`)
   }
  }

  const setMonthUp = () =>{
    if(parseInt(month) >= 12){
      setMonth('01');
   }
   else if (parseInt(month) === 0){
    setMonth('12');
   }
   else{
    setMonth(`${parseInt(month)+ 1}`)
   }
  }

  const setMonthDown = () =>{
    if(parseInt(month) > 12){
      setMonth('01');
   }
   else if (parseInt(month) <= 1){
    setMonth('12');
   }
   else{
    setMonth(`${parseInt(month)- 1}`)
   }
  }

  const setYearUp =() =>{
    setYear(year + 1)
  }

  const setYearDown =() =>{
    setYear(year - 1)
  }

  const setFullDate =() =>{

    if ( (parseInt(month) === 4|| parseInt(month) === 6 || parseInt(month) === 9|| parseInt(month) === 11) &&( parseInt(day) > 30)){
       openNotification(`The Day ${day} are not available in the ${month} month`, "Error")
    }
    else if ((parseInt(month)=== 2)&& ( parseInt(day) > 29)){
      openNotification(`The Day ${day} are not available in the ${month} month`, "Error")
    }
    else{
        
        let completedDate = ''

          if(year === parseInt(String(today.getFullYear()))){

          
            if(parseInt(month) === parseInt(String(today.getMonth()))+1){
              if(parseInt(day) < parseInt(String(today.getDate()))){
                console.log("previous date");
                openNotification('You cannot select previous date','Error')
                handleDatePopUpFromChild();
              }
              else{
                completedDate= `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
              }
            }
            else if(parseInt(month) < parseInt(String(today.getMonth()))+1){
              openNotification('You cannot select previous date','Error')
              handleDatePopUpFromChild();
            }
            else{
              completedDate= `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
            }
          }else if (year < parseInt(String(today.getFullYear()))){
            openNotification('You cannot select previous date','Error')
            handleDatePopUpFromChild();
          }
          else{
            completedDate= `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
          }

          if (completedDate !== ''){
              getFullDateFromChild(completedDate);
              setFieldValue('releaseDate', completedDate)
              
              console.log(completedDate);
              
             }
          
      }
      handleDatePopUpFromChild();
  }

  return (
    <Dialog open={openDatePopUpFromChild} 
        onClose={handleDatePopUpFromChild}
        BackdropProps={{
        style: {
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
    }}
    PaperProps={{ className: "dialog-Paper" }}
        >
        <Grid container className='display_flex_rlease_date' >

        <div style={{display: 'flex', flexDirection: 'column'}}>    
          <div className='pop-up-title' style={{color: 'white',textAlign: 'center', fontSize: '32px', fontWeight: 900, fontFamily: 'Inter'}}>Select A</div>
          <div className='pop-up-title' style={{color: 'white',textAlign: 'center', fontSize: '32px', fontWeight: 900, fontFamily: 'Inter', marginBottom: '35px'}}>Release Date</div>
        </div>

                <Grid container xs={12} justifyContent="center"  className="date_picker_popup" style={{gap: 10}}>
                    
                    <div className='date_select' >
                      {/* <div className='top_arrow' onClick={setMonthUp}>
                          <KeyboardArrowUp />
                      </div> */}

                        <img test-id="setting_month_up_in_release_date_popup" className='arrow_up_width' style={{ marginTop: '5px'}} src={upArrowButton} onClick={setMonthUp} />

                        <Row >
                          {`${month.padStart(2, '0')}`}

                        </Row>

                        <img test-id="setting_month_down_in_release_date_popup" className='arrow_up_width' style={{ marginBottom: '5px'}} src={downArrowButton} onClick={setMonthDown} />
                        
                        {/* <div className='top_arrow' onClick={setMonthDown}>
                          <KeyboardArrowDown />
                        </div> */}
                    </div>

                    <div className='date_select' >
                       {/* <div className='top_arrow' onClick={setDateUp}>
                          <KeyboardArrowUp />
                        </div> */}

                        <img test-id="setting_date_up_in_release_date_popup" className='arrow_up_width' style={{ marginTop: '5px'}} src={upArrowButton} onClick={setDateUp} />

                        <Row >
                          {`${day.padStart(2, '0')}`}
                          
                        </Row>


                        {/* <div className='top_arrow' onClick={setDateDown} >
                          <KeyboardArrowDown />
                        </div> */}

                        <img test-id="setting_date_down_in_release_date_popup" className='arrow_up_width' style={{marginBottom: '5px'}} src={downArrowButton} onClick={setDateDown}/>


                    </div>

                    <div className='date_select' >
                      
                      
                      {/* <div className='top_arrow' onClick={setYearUp}>
                      <KeyboardArrowUp />
                      </div> */}

                       <img test-id="setting_year_up_in_release_date_popup" className='arrow_up_width' style={{ marginTop: '5px'}} src={upArrowButton} onClick={setYearUp}/>

                        <Row >
                          {year}
                        </Row>

                        {/* <div className='top_arrow' onClick = {setYearDown}>
                          <KeyboardArrowDown />
                        </div> */}

                        <img test-id="setting_year_down_in_release_date_popup" className='arrow_up_width' style={{marginBottom: '5px'}} src={downArrowButton} onClick = {setYearDown}/>

                    </div>
                </Grid>
                <Grid item test-id="setting_full_date_in_release_date_popup" style={{width: '60%', display: 'flex',justifyContent: 'center', alignItems:'center', marginTop: '10px',marginBottom: '10px'}} onClick={setFullDate}>
                  {/* <Button  variant='text' style={{backgroundColor: 'red', color: 'white'}} > OK </Button> */}
                  <img className='width-date-setdate' src={setDate} />
                </Grid>

                <Grid item style={{width: '60%', display: 'flex',justifyContent: 'center', alignItems:'center'}}  onClick={setFullDate}>
                  {/* <Button  variant='text' style={{backgroundColor: 'red', color: 'white'}} > OK </Button> */}
                  <img className='width-date-setdate' src={todayDate} />
                </Grid>

          
          </Grid>
        </Dialog>
  )
}

export default ReleaseDatePopup;
// Customizable Area End