// Customizable Area Start
import { Input, Row } from "antd";
import { Box, Button, Hidden, Typography } from "@material-ui/core";
import React from "react";
import SplitsController from "./SplitsController.web";
import "./splits.css";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import { searchshape } from "../../../../dashboard/src/assets";
import { nxzloader } from "blocks/dashboard/src/assets";
import RightSideSpiltDetails from './RightSideSplitDetails.web'
import CtrlFilterPopup from "../Common/CtrlFilterPopup.web";
import MobileSplits from "./MobileSplits.web";

const DivWrapper = styled(Box)({
  display: "flex",
  padding: "0px 50px 0px 27px",
  gap: "2%",
  minHeight: "calc(100% - 3rem)",
  marginTop: "15px",
  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 27px",
    gap: "0%",
  },
});
const Borderbox = styled(Box)({
  "@media(max-width: 1024px)": {
    display: "none",
  },
});

const Splitleftsidecontainer = styled(Box)({
  width: "50%",
  display: "flex",
  flexDirection: "column" as "column",
  gap: "4%",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    gap: "20px",
    marginTop: "10px",
  },
});

const TotalSplits = styled(Box)({
  width: "100%",
  color: "white",
  "@media(max-width: 600px)": {
    height: "auto",
  },
});

const Inputboxwrapper = styled(Box)({
  borderRadius: "27px",
  padding: "10px",
  background: "#212121",
  position: "relative",
  width: "100%",
  "& .ant-input": {
    backgroundColor: "#212121",
    color: "white",
    width: "88%",
  },
  "& .ant-input-affix-wrapper": {
    background: "none",
    border: "unset",
  },
  "@media(max-width: 1024px)": {
    width: "100%",
    padding: "10px",
    "& .ant-input": {
      width: "50%",
    },
  },
});
const LeftsideAllsplitboxwrapper = styled(Box)({
  // overflowY: "auto",
  scrollbarWidth: "none",
  maxHeight: "693px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});

export default class Splits extends SplitsController {
  constructor(props: any) {
    super(props);
  }


  render() {
    const {
      searchText,
      splitList,
      index,
      loading,
    } = this.state;
    const { topPlayMusic, getStatusDetails } = this.props;

    return (
      <>
        <Hidden only={["xs"]}>
          {loading ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          ) : (
            <DivWrapper>
              <Splitleftsidecontainer>
                <Inputboxwrapper>
                  <Input
                    test-id=" test_search_term_id_handle_search_input "
                    onChange={(text) => this.handleSplitSearch(text)}
                    value={searchText}
                    prefix={
                      //@ts-ignore
                      <SearchOutlined translate className=" text_white1 " />
                    }
                    placeholder="Search  Splits"
                    className="check123"
                  />
                  <img src={searchshape} style={webStyle.searchimageicon}
                    onClick={() => {
                      this.handleOpenFilterPopup()
                    }}
                    data-test-id="openFilterpopup"
                  />
                </Inputboxwrapper>
                <TotalSplits>
                  <Typography style={webStyle.trackname}>
                    {" "}
                    <span style={webStyle.bluetext}>
                      {splitList?.length}
                    </span>{" "}
                    {index} Splits
                  </Typography>
                </TotalSplits>
                <Box style={webStyle.leftsideAlltracksbox}>
                  <LeftsideAllsplitboxwrapper>
                    {splitList?.length > 0 ? (
                      splitList.map((item: any, index: any) => {
                        const { typeColor, status } = getStatusDetails(
                          item,
                          "tracks"
                        );
                        return (
                          <Box style={webStyle.leftSideSingletrackbox}>
                            <Box style={webStyle.singleArtinfo} id="yourTopDivId">
                              <img
                                src={item.attributes?.art_work}
                                style={
                                  this.state.index == "Videos"
                                    ? webStyle.leftsidevideocard
                                    : webStyle.leftsideudiocard
                                }
                                onClick={() => {
                                  topPlayMusic(item, index, splitList, "song");
                                }}
                                id="yourTopDivId"
                              />

                              <Box style={webStyle.leftsideownerstats}>
                                <span className="tracks_songs_text">
                                  {item.attributes.title}
                                </span>
                                <span style={webStyle.splittext}>
                                  {item.attributes.user_role}
                                </span>
                              </Box>
                            </Box>

                            <Box style={webStyle.leftsideownerstats}>
                              <span style={webStyle.splittext}>
                                <span style={webStyle.whitetext}>
                                  {item.attributes.split_percentage}%
                                </span>{" "}
                                Split
                              </span>
                              <Box>
                                <Button
                                  style={{
                                    border: `1px solid ${typeColor}`,
                                    borderRadius: "20px",
                                    color: typeColor,
                                    height: "23px",
                                    textTransform: "capitalize",
                                    width: "100%"
                                  }}
                                >
                                  {status}
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 16,
                          fontWeight: 600,
                          color: "#fff",
                          padding: "5",
                          justifyContent: "center",
                          paddingTop: "100px"
                        }}
                      >
                        Data Not Found.
                      </Typography>
                    )}
                  </LeftsideAllsplitboxwrapper>
                </Box>
              </Splitleftsidecontainer>
              <Borderbox>
                {" "}
                <hr
                  style={{
                    height: "100%",
                    width: "1px",
                    border: "1px solid #212121",
                  }}
                />
              </Borderbox>
              <RightSideSpiltDetails
                active={this.state.active}
                heading={this.state.heading}
                index={this.state.index}
                loading={this.state.loading}
                top40SplitsList={this.state.top40SplitsList}
                masterSplitsList={this.state.masterSplitsList}
                mastertop40SplitsList={this.state.mastertop40SplitsList}
                splitList={this.state.splitList}
                selectedIndex={this.state.selectedIndex}
                showdetails={this.state.showdetails}
                threebtnToogle={this.state.threebtnToogle}
                splitDetailsId={this.state.splitDetailsId}
                searchText={this.state.searchText}
                topPlayMusic={topPlayMusic}
                getStatusDetails={getStatusDetails}
                changetabs={this.changetabs}
              />
            </DivWrapper>
          )}

          <CtrlFilterPopup
            open={this.state.openFilterPopup}
            handleClose={this.handleCloseFilterPopup}
            currentTab={this.state.index}
            handleTrack={(data: any) => this.handleSplitsList(data)}
          />
        </Hidden>
        <Hidden only={['xl', 'lg', 'md', 'sm']}>
          <MobileSplits navigation={undefined} id={""} topPlayMusic={topPlayMusic} getStatusDetails={getStatusDetails} />
        </Hidden>
      </>
    );
  }
}

const webStyle = {
  totalSplits: {
    width: "100%",
    height: "10vh",
    color: "white",
  },
  rightsidesingertext: {
    left: "4%",
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
    position: "relative" as "relative",
    top: "-22%",
  },
  rightsideaudioartisttext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    position: "relative" as "relative",
    left: "4%",
    top: "-22%",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
  },
  leftsideudiocard: {
    width: "75px",
    height: "75px",
    borderRadius: "10px",
    border: "1px solid #141414",
    objectFit: "cover"
  },
  leftsidevideocard: {
    width: "125.25px",
    height: "75px",
    borderRadius: "10px",
    border: "1px solid #141414",
  },
  videoImagetex: {
    position: "relative" as "relative",
    top: "-22%",
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    left: "4%",
  },
  videoArtisttext: {
    position: "relative" as "relative",
    top: "-24%",
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    left: "4%",
  },
  searchimageicon: {
    height: "18px",
    width: "21px",
    position: "absolute" as "absolute",
    top: "31%",
    right: "6%",
    zIndex: 10,
    cursor: "ponter"
  },
  videoImagewrapper: {
    position: "relative" as "relative",
    // width: "332.848px",
    height: "205px",
    background:
      "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
  },
  tracksbutton: {
    borderRadius: "20px",
    backgroundColor: "#212121",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px",
  },
  singleArtinfo: {
    display: "flex",
    gap: "15px",
    justifyContent: "center",
    alignItems: "center",
  },
  leftsideAlltracksbox: {
    width: "100%",
    // height:"80vh"
    marginTop: "-18px",
  },
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
    // marginTop: "10px"
  },
  leftSideSingletrackbox: {
    height: "95px",
    padding: "4px",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain" as "contain",
  },
  audioimagetext: {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    marginTop: "8px",
    color: "#F0F0F5",
  },
  audioartistext: {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    color: "#8f92a1",
  },

  trackname: {
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontSize: "22px",
    fontFamily: "Inter",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden"
  },

  singlebutton: {
    border: "1px solid blue",
    borderRadius: "20px",
    color: "blue",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },
  songsinlgebutton: {
    border: "1px solid red",
    borderRadius: "20px",
    color: "red",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },

  splittext: {
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontSize: "16px",
    fontFamily: "Inter",
  },
  leftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
    // width:"10%"
  },
  tracksimage: {
    height: "148px",
    width: "148px",
    // width:"100%",
    borderRadius: "12px",
  },

  bluetext: {
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
    fontSize: "22px",
  },
  whitetext: {
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#FFF",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Inter",
  },
};
// Customizable Area End
