// Customizable Area Start
import React from 'react';
import { Box, Typography } from '@material-ui/core'
// Customizable Area End


// Customizable Area Start
interface Props {
    selected: boolean;
    isVideo: boolean;
    unread: boolean;
    media: any;
    type?: string;
    onUserCommentClick: (comment: any) => void;
    onUserProfileClick: (comment: any) => void;
    TrackIndex : any;
    setTrackIndex : (index: any) => void;
    selectedTrackIndex: any;
    selectedTab ?: any;
}
// Customizable Area End

// Customizable Area Start
export const MediaCommentCP = (props: Props) => {
    const handleClick = (e: any) => {
        props.onUserCommentClick(props.media)
        props.setTrackIndex(props.TrackIndex)
    }

    const handleUserClick = (e: any) => {
        props.onUserProfileClick(props.media)
        props.setTrackIndex(props.TrackIndex)
    }
    let track_type_color = '';
    if (props.type === 'Beat') {
        track_type_color = 'Red'
    } else if (props.type === 'Hooks') {
        track_type_color = 'Blue'
    }
    return (
        <Box className="MediaCommentCPMB" style={{backgroundColor: props.selectedTrackIndex == props.TrackIndex ?  '#141414' : "transparent",border: props.selectedTrackIndex == props.TrackIndex ? 'solid 3px #212121': 'transparent', borderRadius: '10px'}}>
            <Box className={`CommentSummary ${props.selected && 'Active'}`}>
                <Box className={`LeftImageBox ${props.isVideo && 'Video'}`}>
                    <img src={props.media.attributes.art_work} className={`${props.isVideo ? 'VideoImage' : 'MusicImage'}`} onClick={handleUserClick} />
                </Box>
                <Box className='SummaryBox' onClick={handleClick}>
                    <Typography className='MediaNameText'>
                        {props.media.attributes.title}
                    </Typography>
                    <Box className='CommentCountBox'>
                        {
                            props.type && props.selectedTab == 'Track' &&
                            <Typography className={`MediaTypeText ${track_type_color}`}>{props.type}</Typography>
                        }
                        <Typography className='CommentNoText'><span className='CommentCountText'>{props.media.attributes.comments_count}</span>Comments</Typography>
                        {props.unread &&
                            <Box className='CPBDContainer'>
                                <Box className='BlueDot'></Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
// Customizable Area End


// Customizable Area Start
export default MediaCommentCP;
// Customizable Area End
