// Customizable Area Start
import { CheckCircleFilled, CheckOutlined } from '@ant-design/icons';
import { Badge, Row } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useState } from 'react';
import 'blocks/dashboard/assets/css/CreativeBox.css'
import {defaultProfile, playNow } from 'blocks/dashboard/src/assets';
import { Grid, } from "@material-ui/core";
import LoginAlert from "./LoginAlert.web";
import { useHistory } from "react-router-dom";

const CreativeBox = (props: any) => {
  const history = useHistory();
  const token: any = localStorage.getItem("token");
  const [open, setOpen] = useState<boolean>(false);
  const [dialogText, setDialogText] = useState<any>("");

  const redirectHandle = async () => {
     localStorage.removeItem("selectedTab");
    const id: any = localStorage.getItem("user_id");
     localStorage.setItem("temp_id", id);
     localStorage.removeItem("user_id");
     localStorage.setItem("user_id", props.id);
     localStorage.setItem("creatives_screen", "true");
    // await localStorage.removeItem("current_type")
    const user = props.userType;
     localStorage.setItem("current_type", user);

    if (token) {
      history.push({
        pathname:
          props.userType === "listener"
            ? "/listener_public"
            : `/creatives/beats?creative_id=${props.id}`,
        // state: { creativeId: props?.id }
      });
    } else {
      setOpen(true);
       setDialogText("2");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className="creative-box card_width"
        onClick={redirectHandle}
        style={{ cursor: "pointer", marginRight: 10 }}
      >
        <Grid
          item
          //  xs={2}
        >
          <Row
            align="top"
            id="digit"
            style={{
              fontWeight: "bold",
              marginLeft: "5px",
              alignSelf: "start",
            }}
          >
            {props.artistNo <= 9 ? "0" + props?.artistNo : props?.artistNo}
          </Row>
        </Grid>
        <Grid
          item
          // xs={4}
        >
          <Badge
            className="badge"
            style={{ backgroundColor: "#3959f9", borderRadius: "50%" }}
            offset={[0, 56]}
            count={
                // @ts-ignore
              <CheckOutlined
               
                className="badge-icon"
                
              />
            }
          >
            <Avatar
              size={61}
              src={props?.avatar ? props?.avatar : defaultProfile}
              style={{
                marginLeft: "10px",
                border: "1px solid #212121"
              }}
              className={props?.avatar ?"":"creative-landing-image"}
            ></Avatar>
          </Badge>
        </Grid>

        <Grid
          item
          // xs={6}
          className="creative-box-number ml-1"
        >
          <Grid
            item
            //  xs={12}
            id="name"
          >
            {props?.userName ? props?.userName : "Artist Name"}
          </Grid>
          <div className="creative-icon">
            <Grid
              container
              item
              // xs={12}
              style={{ marginTop: "2px" }}
            >
              <Grid
                item
                // xs={6}
                id="artist"
                style={{ overflow: "hidden" }}
              >
                {props?.type ? props?.type : "Artist"}
              </Grid>
              <Grid
                item
                // xs={6}
                id="icon"
              >
                <span>
                  <img
                    src={playNow}
                    style={{
                      height: "14px",
                      width: "25px",
                      marginRight: "2px",
                    }}
                    alt=""
                  />
                </span>

                <span className="play_count_ellipse">{props?.trackCount}</span>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
      {open && (
        <LoginAlert
          open={open}
          handleClose={handleClose}
          dialogText={dialogText}
        />
      )}
    </>
  );
};

export default CreativeBox;
// Customizable Area End
