// Customizable Area Start
import React from "react";
import { Grid, Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { defaultProfile } from "blocks/dashboard/src/assets";


export const TrackComments = ({
  id,
  avatarSrc,
  name,
  time,
  duration,
  text,
  item,
}: any) => {

  const history = useHistory();
  const profileRedirection = () => {
    if(item?.attributes?.user_type == "creative"){
      history.push(`/creatives/beats?creative_id=${item?.attributes?.account_id}`)
    }else{
      history.push(`/listeners/activity?listener_id=${item?.attributes?.account_id}`)
    }
  } 
  
  const commentID = (item: any) =>{
    if(id == 'Comment'){
      return `comment_id_${item.id}`
    }else{
      return `reply_id_${item.id}`
    }
     
  }
  
  return (
    <>
      <Grid
        container
        id= {`${commentID(item)}`}
        item
        xs={12}
        spacing={2}
        // direction='row'
        justifyContent="flex-end"
        style={{ margin: "5px 0px" }}
      >
        <Grid
          container
          justifyContent="flex-end"
          item
          xs={2}
          style={{ marginTop: "4px", padding: "4px" }}
          onClick = {() => profileRedirection()}
        >
          <div 
          className="comment_avatar_default"
          >
          <img
            alt="Remy Sharp"
            src={avatarSrc||defaultProfile}
            className={avatarSrc?'mediaDMCP-avatar':"default-profile-mediaDMCP"}
          />
          </div>
        </Grid>
        <Grid
          item
          xs={10}
          className="user_comment_msg"
        // alignContent="space-between"
        >
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
          >
            <Grid container item xs={9}>
              <span className="comment_username">{name}</span>
            </Grid>
            <Grid container item xs={3} justifyContent="flex-end">
              <span className="comment_time_font">{duration}</span>
            </Grid>
            <span className="comment_msg">{text}</span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
// Customizable Area End