// Customizable Area Start
import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
export interface Props {
    creativePlanSrc?: any;
    creativePlanAlt?: boolean;
    creativeBtnSrc?: any;
    creativeBtnAlt?: any;
    price?: any;
    name?: any;
    openConfirmPaymentPopup?: any;
    currentPaymentData?: any;
    next_invoice_no?: any;
    isCurrentPlan?: boolean;
  updateSubscription: () => void;
  CreateNewPayment: (id:any,plan_id:any) => void;
  closePayModal?:()=>void;
 }
interface S {
    OpenPopup:boolean
}
interface SS {
  id: any;
}

export default class ComposeProfileController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
       OpenPopup:false

    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  
  // Customizable Area End



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    // Customizable Area End
  }
}
// Customizable Area End