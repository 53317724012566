// Customizable Area Start
import { Grid, ButtonGroup, Button, Divider, Hidden } from "@material-ui/core";
import React from "react";
import "./snapshot.css";
import SnapshotController from "./SnapshotController";
import { nxzloader } from "blocks/dashboard/src/assets";
import DownloaderList from "./DownloaderList.web";
import { Row } from "antd";
import DownloaderDetails from "./DownloaderDetails.web";
import TopMobileDownloaders from "./TopMobileDownloaders.web";
export default class TopDownloaders extends SnapshotController {
  render() {
    const {
      threebtnToogle,
      downloadersList,
      setDowloadedData,
      downloadLoader
    } = this.state;
    return (
      <>
        {downloadLoader ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <>
            <Hidden only={["xs"]}>
              <Grid container style={{ display: "flex" }} id="parent">
                {downloadersList?.length > 0 ? (
                  <>
                    <Grid item xs={6}>
                      {downloadersList?.length > 0 ? (
                        <Grid
                          id="check"
                          container
                          item
                          className="top-downloaders-length-check"
                        >
                          <Grid
                            container
                            item
                            style={{
                              display: "flex",
                              maxHeight: "100%",
                              padding: "10px",
                              // new changes
                              flex: 1,
                              // flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                minHeight: "60px",
                                width: "100%",
                                // marginBottom: "15px",
                                alignItems: "center",
                                display: "flex",
                                height: "10%",
                                marginTop:"3%"
                              }}
                            >
                              <ButtonGroup
                                color="primary"
                                className="outer-div-toggle btnGroup"
                                aria-label="outlined primary button group"
                              >
                                <Button
                                  className="audio-button-playlist"
                                  value="audio"
                                  variant="contained"
                                  disabled={threebtnToogle === "Beats"}
                                  onClick={() => this.changetabs("Beats")}
                                >
                                  <span className="audio-text">Beats</span>
                                </Button>
                                <Button
                                  variant="contained"
                                  className="video-button-playlist"
                                  onClick={() => this.changetabs("Hooks")}
                                  disabled={threebtnToogle === "Hooks"}
                                >
                                  <span className="audio-text">Hooks</span>
                                </Button>

                                <Button
                                  variant="contained"
                                  className="versesButtonPlaylist"
                                  onClick={() => this.changetabs("Verses")}
                                  disabled={threebtnToogle === "Verses"}
                                >
                                  <span className="audio-text">Verses</span>
                                </Button>
                              </ButtonGroup>
                            </div>

                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                // marginBottom: "15px",
                                alignItems: "center",
                                height: "10%",
                              }}
                            >
                              <span
                                className="topDownloaders"
                                style={{ whiteSpace: "pre" }}
                              >
                                Top {downloadersList?.length} &nbsp;
                              </span>
                              <span className="topDownloadersSpan">
                                {" "}
                                Downloaders
                              </span>
                            </Grid>

                            <div
                              // style={{ maxHeight: "500px", width: "100%" }}
                              className="disable-scrollbars"
                            >
                              {downloadersList?.length > 0 &&
                                downloadersList.map((item: any, index: any) => {
                                  return (
                                    <DownloaderList
                                      index={index}
                                      selectIndex={this.state.selectIndex}
                                      handleExpandDownloaders={
                                        this.handleExpandDownloaders
                                      }
                                      item={item}
                                      totalTracksCount={
                                        this.state.totalTracksCount
                                      }
                                      key={index}
                                    />
                                  );
                                })}
                            </div>
                          </Grid>

                          <Hidden only={["sm", "md", "lg", "xl"]}>
                            <Divider
                              orientation="vertical"
                              style={{
                                height: "100%",
                                width: "1px",
                                background: "#212121",
                              }}
                            />
                          </Hidden>
                        </Grid>
                      ) : (
                        <>
                          <p className="noData-downloads">
                            No Downloaders Found
                          </p>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ padding: "10px", maxHeight: "100%" }}
                    >
                      {Object.keys(setDowloadedData)?.length > 0 ? (
                        <DownloaderDetails
                          threebtnToogle={threebtnToogle}
                          userData={setDowloadedData}
                          changetabs={{}}
                          onBack={{}}
                        />
                      ) : (
                        <p className="noData">
                          Select A User To View <br />
                          Download History
                        </p>
                      )}
                    </Grid>
                  </>
                ) : (
                  <p className="noData-downloads">No Downloaders Found</p>
                )}
              </Grid>
            </Hidden>
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <TopMobileDownloaders
                checkAllDownloaders={""}
                seeAllTopDownloaders={""}
              />
            </Hidden>
          </>
        )}
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area End
