// Customizable Area Start
import React from "react";
import { logo } from "blocks/PremiumPlanComparison/src/assets";
import { Link } from "react-router-dom";

const BrandName = ({ onClick }: any) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: "193px",
        height: "36px",
        margin: "27px 0px 46px 24px",
        display: "flex",
        boxSizing: "border-box",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Link to="/home" style={{
        borderRadius: "50%",
        border: "1px solid white"
      }}>
        <img
          src={logo}
          alt=""
          height={27}
          width={38}
          style={{
            padding: "5px 4px 7px 5px",
            height: "35px",
            width: "36px"
          }}
        />
      </Link>
      <h2
        style={{
          width: "150px",
          height: "30px",
          margin: "0 0 0 13px",
          fontSize: "22px",
          fontWeight: "bold",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.36",
          letterSpacing: "-1px",
          color: "#fff",
        }}
      >
        NXZSOUND
      </h2>
    </div>
  );
};

export default BrandName;
// Customizable Area End
