// Customizable Area Start
import React from 'react';
import 'blocks/dashboard/assets/css/CreativeBox.css'
import { Filter_black, triangle } from 'blocks/dashboard/src/assets';
import { Dropdown, Menu } from "antd";

const FilterDropDown =({filterValue,handleFilter,listener}:any)=> {
    const menu = () => {
      return (
        <div>
          <Menu className="custom-drop-menu">
            <Menu.Item key="0" style={{ color: "white", backgroundColor: "transparent" }} onClick={()=>handleFilter("today")}  >Today</Menu.Item>
            <Menu.Item key="1" style={{ color: "white", backgroundColor: "transparent" }} onClick={()=>handleFilter("this_week")}>This week</Menu.Item>
            <Menu.Item key="3" style={{ color: "white", backgroundColor: "transparent" }} onClick={()=>handleFilter("this_month")}> This month</Menu.Item>
            <Menu.Item key="4" style={{ color: "white", backgroundColor: "transparent" }} onClick={()=>handleFilter("this_year")}>This Year</Menu.Item>

          </Menu>
        </div>
      );
    };

    return (
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={["click"]}

        getPopupContainer={(trigger: any) => trigger.parentNode}
      >
        <div
            className={listener?"ant-dropdown-link filter_profile listener-home-filter":"ant-dropdown-link filter_profile home-filter"}
          onClick={(e) => e.preventDefault()}  >
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "inherit"
          }}>
            <img
              src={Filter_black}
              style={{ height: "10px", width: "11px" }}
              alt=""
            />
             <p style={{ fontSize: "13px", fontWeight: "bold", margin: "auto",minWidth: "73px",textAlign:"center",
    maxWidth: "84px" }}>{filterValue}</p>
          </div>
          <img
            src={triangle}
            style={{ height: "10px", width: "11px" }}
            alt=""
          />
        </div>
      </Dropdown>
    );
  }
export default FilterDropDown;
// Customizable Area End