// Customizable Area Start
import { Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from 'react';
import "../../Playlist.css";
import { Hidden, } from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import WebPlaylistController from "../../WebPlaylistController";
import TopPlaylists from "./TopPlaylist.web";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import { nxzloader } from "../../../../dashboard/src/assets";

export default class PlayListDetails extends WebPlaylistController {
  render() {
    const { loading }: any = this.state;
    return (
      <>
        <div
          className="playListMain">
          <Layout style={{ height: "100vh" }}>
            <Hidden smDown>
              <AsideLeft />
            </Hidden>
            <Content
              style={{
                margin: "0px",
                backgroundColor: "black"
              }}
            >
              <HomeHeader />
              {loading ? (
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                  <img src={nxzloader} style={{ width: "5%" }} />
                </Row>
              ) : (
                 <>
                    <TopPlaylists />
                    </>
              )}
            </Content>
          </Layout>

        </div>
      </>
    );
  }
}
// Customizable Area End
