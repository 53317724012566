// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { HISTORY } from "components/src/common";
import { setStorageData } from "../../../../../framework/src/Utilities";


interface S {
    loggedInUser: any;
    token: any;
}

interface SS {
    id: any;
}
export interface Props {
    trackData: any;
    padding?: any;
    height?: any;
    width?: any;
    fontSize?: any;
}

export function unlockRedirectionCheck(functionReturn: any, args: any, trackData: any) {
    const songsList_loggedIn_userId = localStorage.getItem('loggedIn_userId')

    if (!trackData?.attributes?.unlocked_track_by_current_user && trackData?.attributes?.level && songsList_loggedIn_userId != trackData.attributes?.account_id) {
        const searchLevelType = SearchBeatTypesReturn(trackData?.attributes.level)
        const levelType_data = { track_id: trackData.id, user_id: localStorage.getItem('loggedIn_userId'), level: searchLevelType }
        setStorageData('unlock_details', levelType_data)
        HISTORY.push('/streamUnlock')
    } else {
        if (args) {
            functionReturn(...args);
        } else {
            functionReturn();
        }
    }
}

export function SearchBeatTypesReturn(searchLevel: any) {
    if (searchLevel == 'level_1') {
        return 'L1'
    } else if (searchLevel == 'level_2') {
        return 'L2'
    } else {
        return 'L3'
    }
}

export default class UnlockContentCommonController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiUnlockContentTrackCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            loggedInUser: localStorage.getItem('loggedIn_userId'),
            token: localStorage.getItem('token')
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    redirectionClick = () => {
        if (!this.state.token) {
            HISTORY.push('/Welcome')
        } else {
            unlockRedirectionCheck(() => HISTORY.push('/streamUnlock'), "", this.props.trackData)
        }
    }

}



UnlockContentCommonController.contextType = AudioContextApi;
// Customizable Area End