// Customizable Area Start
import React from "react";
import { Dialog, Typography, Grid } from "@material-ui/core";
import { Col, Row } from "antd";
import DeleteTrackController from "./DeleteTrackController";

export default class DeleteTrack extends DeleteTrackController {
  render() {
    const { isOpen, handleDMClose, handleDelete,title,subTitle } = this.props;
    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={handleDMClose}
          className="CopiedModal"
          PaperProps={{ className: "dialog-delete-message-popup" }}
        >
          <Row justify="center">
            <Col>
              <Grid
                container
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                  
                }}
              >
                <Grid item>
                  <Typography className="dialog-delete-head">
                    {" "}
                    {title}
                  </Typography>
                  <Typography className="deletemodal-subtitle">
                   {subTitle}
                  </Typography>
                </Grid>
                <Grid item>
                  <button
                    className="red_custom_btn_sms  mt-4"
                    type="button"
                    // onClick={this.deletePlaylist}
                    onClick={handleDelete}
                  >
                    YES
                  </button>
                </Grid>
                <Grid item>
                  <button
                    className="grey_custom_btn mt-1"
                    type="button"
                    // onClick={this.props.handleClose}
                    onClick={handleDMClose}
                    style={{
                      backgroundColor: "transparent ",
                      border: "solid 3px #121212",
                      borderRadius: "60px",
                      
                    }}
                  >
                    NO
                  </button>
                </Grid>
              </Grid>
            </Col>
          </Row>
        </Dialog>
      </div>
    );
  }
}
// Customizable Area End
