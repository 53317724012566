// Customizable Area Start
import { Content } from 'antd/lib/layout/layout'
import { Layout } from 'antd';
import {
    Hidden,
} from "@material-ui/core";
import React  from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import AudioVideo from './AudioVideo.web'
import StreamRepostparentController from './StreamRepostparentContoller.web';


export default class StreamRepost extends StreamRepostparentController {
    render() {
      return (
        <>
          <Layout  className='streamRepostWrapper'>
            <Hidden smDown>
                <AsideLeft />
            </Hidden>
            <Content
                style={{
                    margin: "0px",
                    backgroundColor: "black",
                    display: "flex", flexDirection: "column",
                }}
            >
               <HomeHeader location="RepostHistory" redirect={this.RedirectSelfProfile}/>
               <AudioVideo navigation={undefined} id={''}/>
            </Content>
        </Layout>
        </>
       
      )
    }
  }

// Customizable Area End