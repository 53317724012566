// Customizable Area Start
import React, { useContext } from 'react'
import { Grid, IconButton } from "@material-ui/core";
import { CloseRounded } from '@material-ui/icons';
import { album11, plays } from '../../assets';
import { AudioContextApi } from '../../AudioContext/AudioContext.web';



const NextAudioQueue = ({ playlistItems, setPlaylistDataFromList, playlistQueueSongs }: any) => {
    const { handlePlayList, setQueuePlaylistSongs} = useContext(AudioContextApi)

    const removeSong = (item: any) =>{
        console.log(item, 'item for cancel')
        let playlistQueueSongs = JSON.parse(localStorage.getItem('queuePlaylistSongs') || '[]')
        let songs = playlistQueueSongs.filter((song: any) => song.id != item.id)
        setQueuePlaylistSongs(songs)
        localStorage.setItem('queuePlaylistSongs',JSON.stringify(songs))
    }
    return (
        <>
            {
                playlistItems && playlistItems.length > 0 ? (
                    playlistItems.map((item: any, index: any) => {
                        console.log(item)
                        return (
                            <Grid item xs={12} className="single_next_Audio_queue" container
                             >
                                <Grid item xs={1}  test-id="onplayling_song_click_id" style={{ padding: "0px 0px 0px 10px" }} onClick={() =>{
                                handlePlayList(item, index, playlistQueueSongs, 'Playlist')
                                setPlaylistDataFromList(item)
                               }}>
                                    {/* <img src={"https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzhhIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8cd4d2b6bd8ad41ab932757da47621a3cd59b0f1/artworks-000504502107-bnjyv3-t500x500.jpg"} alt="" style={{ height: "48px", width: "48px" }} /> */}
                                    <img src={item?.attributes?.art_work ? item?.attributes?.art_work : album11} alt="" style={{ height: "48px", width: "48px" }} />
                                </Grid>
                                <Grid item xs={7} test-id="onplayling_song_click_id_0" style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0px 10px 0px 40px" }}
                                onClick={() =>{
                                    handlePlayList(item, index, playlistQueueSongs, 'Playlist')
                                    setPlaylistDataFromList(item)
                                }}>
                                    <span className="track_name_font">{item?.attributes?.title}</span>
                                    <span className="username_font">{item?.attributes?.artist_name}</span>
                                </Grid>
                                <Grid item xs={4} style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "0px 10px" }}>
                                    <img src={plays} alt="plays" style={{ height: "20px", width: "20px", margin: "0px 10px" }} />
                                    <span className="track_name_font">{item?.attributes?.play_count}</span>
                                    <IconButton test-id="remove_song_id_add_to_queue" onClick={() => {removeSong(item)}}>
                                        <CloseRounded fontSize="medium" style={{ color: "rgba(255, 255, 255, 0.5)" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    })
                ) : (
                    <>
                        <Grid item xs={12} className="single_next_Audio_queue" container>playlist Item Not Available</Grid>
                    </>
                )
            }

        </>
    )
}

export default NextAudioQueue
// Customizable Area End