Object.defineProperty(exports, "__esModule", {
  value: true
});
 const urlConfig = require("../../../framework/src/config.js");


// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Sms";
exports.labelBodyText = "Sms Body";
exports.dashboarContentType = "application/json";
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.btnExampleTitle = "CLICK ME";
exports.baseUrl = urlConfig.baseURL;

// Customizable Area End