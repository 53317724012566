// Customizable Area Start
import React from "react";

import {  Layout, Row } from 'antd';
import "../Creatives/index.web.css";
import { Hidden, } from "@material-ui/core";
import "../Creatives/Home.web.css";

import LabelController from "./LabelController.web";
import LabelProfileCover from './LabelProfileCover.web';
import LabelHome from './LabelHome.web';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import { nxzloader } from "blocks/dashboard/src/assets";
const { Content } = Layout;


class Labels extends LabelController {

    render() {
      
        console.log("changesss....")
      
          const {  creativeById } = this.state;
          const { isPlaylist } = this.context;
          return (
            <div style={{ overflowX: "hidden", overflowY: "hidden", backgroundColor: "rgb(31, 31, 31)", height: "100vh" }} >
              <Layout style={{ backgroundColor: "black", height: "100vh" }}>
                {/* .......................SideBar...................................... */}
                <Hidden smDown>
                  <AsideLeft/>
                </Hidden>
      
                <Content style={{ margin: "0px", backgroundColor: "black", display: "flex", flexDirection: "column", overflow: 'hidden', height:"100vh" }}>
                  <HomeHeader />
                  {Object.keys(creativeById).length <= 0 ?
                        <Row className="mt-6  width_100 loader-charts" align="middle" justify="center"
                        >
                          
                            <img src={nxzloader} style={{ width: "5%" }} />
                        </Row>
                    : 
                      <>
                        <div className={isPlaylist? "mainBody-audio-withoutheight":"label-mainBody-audio-withoutaudio"}>
                          <LabelProfileCover />
      
                            <div >
                              <LabelHome />
                          </div>
                        </div>
                      </>
                    
                  }
      
      
                </Content>
      
              </Layout>
            </div>
          );
        }
}
export default Labels;
Labels.contextType = AudioContextApi;
// Customizable Area End