// Customizable Area Start
import React, { useState } from 'react'
import{Dialog, Grid } from "@material-ui/core";
import './release_date_popup.web.css'
import {Row} from 'antd';
import { openNotification } from '../../../../components/src/Notification.web';
import { AsapButton, downArrowButton, setTime, upArrowButton } from '../assets';

function ReleaseTimePopup({openTimePopUpFromChild,  handleTimePopUpFromChild ,getFullTimeFromChild, setFieldValue, values, date}: any) {

     let today = new Date();

    const day = date.split('/')[1]
    const month = date.split('/')[0]
    const year = date.split('/')[2]

     let currentHour = parseInt(String(today.getHours()).padStart(2, '0'))
     let AMorPM = "AM"
     if (currentHour > 12){
       currentHour = currentHour - 12;
       AMorPM = "PM"
     }
     
     const [hour,setHour] = useState(`${currentHour}`) 
     const [minutes,setMinutes] = useState(String(today.getMinutes()).padStart(2, '0'));
     const [amOrPm,setAmOrPm ] = useState(`${AMorPM}`)
     const [amActive,setAmActive] = useState<boolean>(AMorPM == "AM" ? true : false);

     const seconds = '00';
     
  const setHourUp =() =>{
     if(parseInt(hour) >= 12){
        setHour('00');
     }
     else if (parseInt(hour) < 0){
      setHour('12');
     }
     else{
      setHour(`${parseInt(hour)+ 1}`)
     }
  }

  const setHourDown =() =>{
    if(parseInt(hour) > 12){
      setHour('00');
   }
   else if (parseInt(hour) <= 0){
    setHour('12');
   }
   else{
    setHour(`${parseInt(hour) - 1}`)
   }
  }

  const setMinutesUp = () =>{
    if(parseInt(minutes) >= 59){
      setMinutes('00');
   }
   else if (parseInt(minutes) < 0){
    setMinutes('59');
   }
   else{
    setMinutes(`${parseInt(minutes)+ 1}`)
   }
  }

  const setMinutesDown = () =>{
    if(parseInt(minutes) > 59){
      setMinutes('00');
   }
   else if (parseInt(minutes) <= 0){
    setMinutes('59');
   }
   else{
    setMinutes(`${parseInt(minutes)- 1}`)
   }
  }

  const setAm =() =>{
    setAmActive(true);
    setAmOrPm('AM')
    console.log('from Am');
  }

  
  
  const setPm = () =>{
    setAmActive(false);
     setAmOrPm('PM')
  
   }

  const setFullTime =() =>{
    
    let changeHour = hour

    if(amOrPm==='PM'){
      if (parseInt(changeHour) < 12) {
        changeHour = `${parseInt(changeHour) + 12}`
        
      } 
    }else if(amOrPm==='AM'){
      if (parseInt(changeHour) === 12) {
        changeHour = `${parseInt(changeHour) - 12}`
      } 
    }

    let newTime = ''
      console.log( openTimePopUpFromChild ,date, parseInt(day),parseInt(String(today.getDate())),'checking for tests time')
    if((parseInt(day) === parseInt(String(today.getDate()))) && (parseInt(month) === parseInt(String(today.getMonth()))+1) && (parseInt(year) === parseInt(String(today.getFullYear())))) {
       if (parseInt(changeHour) < parseInt(String(today.getHours()))){
         openNotification('You cannot select previous time','Error')
         console.log('hours is privious');
         handleTimePopUpFromChild();
       }
       else if (parseInt(changeHour) === parseInt(String(today.getHours()))) {
          if (parseInt(minutes) < parseInt(String(today.getMinutes()))){
            openNotification('You cannot select previous time','Error')
            console.log('minutes is privious');
            handleTimePopUpFromChild();
          }
          else{
            newTime = `${changeHour.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
          }
       }
       else{
        newTime = `${changeHour.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
       }
    }else{
      newTime = `${changeHour.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
    }

    
    if (newTime !== ''){
      getFullTimeFromChild(newTime);
      setFieldValue('releaseTime',newTime)
      console.log(values, "values from release time")
      console.log(newTime); 
    }
    handleTimePopUpFromChild();
  }

  return (
    <Dialog open={openTimePopUpFromChild} 
        onClose={handleTimePopUpFromChild}
        BackdropProps={{
        style: {
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
    }}
    PaperProps={{ className: "dialog-Paper" }}
        >
        <Grid container className='display_flex_rlease_date' >

        <div style={{display: 'flex', flexDirection: 'column'}}>    
          <div className='pop-up-title' style={{color: 'white',textAlign: 'center', fontSize: '32px', fontWeight: 900, fontFamily: 'Inter'}}>Select A</div>
          <div className='pop-up-title' style={{color: 'white',textAlign: 'center', fontSize: '32px', fontWeight: 900, fontFamily: 'Inter', marginBottom: '35px'}}>Release Time</div>
        </div>

                <Grid container xs={12} justifyContent="center"  className="date_picker_popup" style={{gap: 10}}>
                    
                    <div className='time_select' >
                       
                       {/* <div className='top_arrow' onClick={setHourUp}>
                          <KeyboardArrowUp />
                        </div> */}
                        <img test-id="setting_hour_up_in_release_date_popup" className='arrow_time_width' style={{ marginTop: '5px'}} src={upArrowButton} onClick={setHourUp} />

                        <Row >
                          {`${hour.padStart(2, '0')}`}
                          
                        </Row>

                        <img test-id="setting_hour_down_in_release_date_popup" className='arrow_time_width' style={{marginBottom: '5px'}} src={downArrowButton} onClick={setHourDown}/>

                        {/* <div className='top_arrow' onClick={setHourDown} >
                          <KeyboardArrowDown />
                        </div> */}


                    </div>
                    <p style={{color: 'white',fontWeight: 'bold', display: 'flex', flexDirection: 'column', justifyContent: 'center' ,fontSize: '22px'}}>:</p>
                    
                    <div className='time_select' >
                        
                        
                        {/* <div className='top_arrow' onClick={setMinutesUp}>
                          <KeyboardArrowUp />
                        </div> */}
                        <img test-id="setting_minutes_up_in_release_date_popup" className='arrow_time_width' style={{ marginTop: '5px'}} src={upArrowButton} onClick={setMinutesUp} />

                        <Row >
                          {`${minutes.padStart(2, '0')}`}
                        </Row>

                        <img test-id="setting_minutes_down_in_release_date_popup" className='arrow_time_width' style={{marginBottom: '5px'}} src={downArrowButton} onClick={setMinutesDown}/>

                        {/* <div className='top_arrow' onClick={setMinutesDown}>
                          <KeyboardArrowDown />
                        </div> */}


                    </div>
                    <div>
                      
                    </div>
                </Grid>

              <Grid item className='ampm_flex'>
                <Grid item className='am_pm_buttons' style={{padding: '5px', backgroundColor: 'rgb(27,26,26)'}} direction="row" justifyContent='center' alignItems='center'>
                     <button test-id="setting_am_in_release_date_popup" className = { "button1_am" } style={{backgroundColor: amActive ? 'blue' : 'transparent'}} autoFocus onClick={setAm}>AM</button>
                     <button test-id="setting_pm_in_release_date_popup" className= {"button2_pm" } style={{backgroundColor: !amActive ? 'blue' : 'transparent'}} onClick={setPm}>PM</button>
                </Grid>
                <Row style={{margin: '5px'}}>{" "}</Row>
                
                {/* <Grid item className='upload_date_button'  direction="row" justifyContent='center' alignItems='center' onClick={setFullTime} >
                  <Button  variant='text' style={{backgroundColor: 'red', color: 'white'}}  > OK </Button> 
                </Grid> */}
                <Grid item className='width-time-main-settime' style={{display: 'flex',justifyContent: 'center', alignItems:'center', marginTop: '10px',marginBottom: '10px'}} onClick={setFullTime}>
                  {/* <Button  variant='text' style={{backgroundColor: 'red', color: 'white'}} > OK </Button> */}
                  <img className='width-date-settime' src={setTime} />
                </Grid>

                <Grid item test-id="setting_full_time_in_release_date_popup" className='width-time-main-settime' style={{display: 'flex',justifyContent: 'center', alignItems:'center'}}  onClick={setFullTime}>
                  {/* <Button  variant='text' style={{backgroundColor: 'red', color: 'white'}} > OK </Button> */}
                  <img className='width-date-settime' src={AsapButton} />
                </Grid>
             </Grid>
          
          </Grid>
        </Dialog>
  )
}

export default ReleaseTimePopup;
// Customizable Area End