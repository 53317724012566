export const rlove = require("./assets/riplove.png");
export const circleOption = require("./assets/circleoption.png");
export const circleBlue = require("./assets/circleblue.png");
export const epic = require("./assets/epic.png");
export const espn = require("./assets/espn.png");
export const hulu = require("./assets/hulu.png");
export const Netflix = require("./assets/Netflix.webp");
export const warnerbros = require("./assets/warnerbros.png");






