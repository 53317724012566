// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import "./MonthTrack.css";
import axios from "axios";
import { musicDownload } from "../../dashboard/src/assets";
import { openNotification } from "components/src/Notification.web";
const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
type TrackInfoProps = {
    item: {
      id: number;
      title:string;
      track_file: string;
      art_work: string;
      track_name: string;
      artist_name: string;
      type: string;
      genre: string | null;
      bpm: string | null;
      key: string |null;
    };
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  };
  const getTypeStyles = (type:any) => {
    let border, color;
  
    if (type === 'Beat') {
      border = "1px solid #ff0000";
      color = "#ff0000";
    } else if (type === 'Song') {
      border = "solid 1px #3959f9";
      color = "#3959f9";
    } else {
      border = "solid 1px #919191";
      color = "#919191";
    }
  
    return { border, color };
  };
const TrackInfo: React.FC<TrackInfoProps> = ({ item, onClick }) => {
    const { border, color } = getTypeStyles(item.type)
    const [downloadUrlBtn, setDownloadUrlBtn] = useState<string | undefined>(undefined);
    const downloadRefBtn = useRef<HTMLAnchorElement>(null);
    const user_id: any = localStorage.getItem("user_id");
    const token: any = localStorage.getItem("token");
    useEffect(() => {
      if (downloadUrlBtn && downloadRefBtn.current) {
        downloadRefBtn.current.click();
        setDownloadUrlBtn(undefined); // Reset URL after triggering download to avoid repeated downloads
      }
    }, [downloadUrlBtn]);
    const saveDownloadHistory = async (e: any, id: any) => {
      e.stopPropagation()
      const dataSong = {
        account_id: user_id,
        add_track_id: id
  
      };
      await axios
        .post(`${baseUrl}/download_histories`, dataSong, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
  
            token: token,
          },
        })
        .then((res) => {
          if(res.data.message=="download success" ){
  
            setDownloadUrlBtn(`${item.track_file}?response-content-disposition=attachment`);
          }else{
            openNotification(res.data.message, "Error")
          }
        })
        .catch((err: any) => {
          console.log("error in download",err)
  
        })
    };
    return(
    <Box className="downloadTrack" onClick={onClick}>
      <Box className="track_title">
        <img
          alt="image"
          src={item.art_work}
          style={{ width: "50px", height: "50px" }}
        />
        <Box className="track_name_detail">
          <span>{item.track_name}</span>
          <span>{item.artist_name}</span>
        </Box>
      </Box>
      <span
        className="track_types"
        style={{
          border,
          color
        }}
      >{item.type}</span>
      <span className="track_genre">{item.genre || "not available"}</span>
      <span className="track_genre">{item.bpm || "--"}</span>
      <span className="track_genre">{item.key || "--"}</span>
      <a
      ref={downloadRefBtn} 
        href={`${item.track_file}?response-content-disposition=attachment`}
        download={item.title}
        onClick={(e) => 
          {
            if (!downloadUrlBtn) {
              e.preventDefault();
              saveDownloadHistory(e, item.id) 
            }
          }}
          data-test-id="savedownload"
      >
        <button className="downloadBtn">Download</button>
        <img src={musicDownload}
                            className="music-download" />
      </a>
    </Box>
    )
      }
export default TrackInfo
// Customizable Area End