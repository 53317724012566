// Customizable Area Start
import { Content } from 'antd/lib/layout/layout'
import { Layout,Tabs} from 'antd';
import {
    Hidden,
} from "@material-ui/core";
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import DashboardRevenue from './DashboardRevenue/DashboardRevenue.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import DashboardAnalytics from './DashboardAnalytics/DashboardAnalytics.web';
import DashboardWallet from './DashboardWallet/DashboardWallet.web';
import MusicSplitDeal from './DashboardDeal/MusicSplitDeal/DashboardMusicdeal';
import './index.css';
import DashboardController from './DashboardController.web';



const { TabPane } = Tabs;
export default class Dashboard extends DashboardController {
    constructor(props: any) {
        super(props);

    }

        screen = () => {
        if (this.state.active === "" || this.state.active === "1") {

            return <DashboardRevenue navigation={undefined} id={''}  />;
          
        }
        if (this.state.active  === "2") {
            return <DashboardAnalytics navigation={undefined} id={''}  />;
        }
        if (this.state.active  === "3") {
            return <DashboardWallet navigation={undefined} id={''} />
        }
        if (this.state.active  === "4") {
            return <MusicSplitDeal navigation={undefined} id={''} />
        }

    };

    render() {
  
    

   
      
     
        return (
            //Merge Engine DefaultContainer
            <Layout  className='dashboardWrapper'>
            {/* .......................SideBar...................................... */}
            <Hidden smDown>
                <AsideLeft />
            </Hidden>
            <Content
                style={{
                    margin: "0px",
                    backgroundColor: "black",
                    display: "flex", flexDirection: "column",
                }}
            >
                <HomeHeader />
                <div className="chats-header" style={{ marginLeft: '15px' }}>
                    <Tabs
                        id="search_custom_tab"
                        className='dashboard_custom_tab'
                        activeKey={this.state.active}
                        onChange={this.callback}
                    >
                        <TabPane className="tab-pane" tab="REVENUE" key="1" />

                        <TabPane tab="ANALYTICS" key="2" />

                        <TabPane tab="WALLET" key="3" />

                        <TabPane tab="DEALS" key="4" />

                    </Tabs>
                </div>
                {this.screen()}
            </Content>
        </Layout>

            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area End