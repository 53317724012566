// Customizable Area Start
import "antd/dist/antd.css";
import { Row, Col, Form, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory, Link } from "react-router-dom";
import "../assets/forgotpassword.css";
import { logo } from "./assets";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { openNotification } from "../../../components/src/Notification.web";
import { OtpBackground, OtpShade } from "../../otp-input-confirmation/src/assets";
const urlConfig = require("./../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
export default function PhoneNumberVerificationWeb() {
  const history = useHistory();


  const token: any = localStorage.getItem("token")
  const full_phone_number: any = localStorage.getItem("full_phone_number")
  const onFinish = async (values: any) => {
    await axios
      .put(`${baseUrl}/account_block/resend_otp`, '', {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        // new changes
        localStorage.setItem("accountType", res.data.message.token);

        if(values.phone_number == full_phone_number){
          openNotification("Number verified", "Success")
          history.push("/TermAndConditions")


        }else{
          openNotification("Please Enter Your Registered Phone Number", "Error")
        }
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0], "Error")
      })
  }
  return (
    <Row className="bgDark" style={{ height: "100%" }}>
      {/* <Row justify="space-around" align="middle"> */}
      <Grid item lg={6} sm={6} xs={12} className="bgImg-account" >
        <img className="image1" src={OtpBackground} />
        <img className="image2" src={OtpShade} />
      </Grid>
      <Col lg={12} md={12} sm={24} xs={24} className="welcome-form ">
        <Row className="button-back">
          <button className="gray1"
            style={{ backgroundColor: "black" }}
            onClick={() => {
              history.goBack();
            }}
          >
            {/* @ts-ignore */}
            <LeftOutlined translate /> Back
          </button>
        </Row>
        <Row
          align="middle"
          justify="center"
          style={{
            height: "80%",
            width: "75%",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Col xxl={15} xl={18} lg={20} md={21} sm={16} xs={21}>
              <Row justify="center" className="welcomeLogo">
                <img src={logo} alt="App Logo" />
              </Row>
            <Row justify="center" className="welcome-text">
              <Row
                justify="center"
                className=" width_100 text_white mt-1  font_25 width_100 font_W_600"
              >
                Verify Your Phone Number
              </Row>
              <Row justify="center" className="text_white1 width_100">
                Please Enter Your Registered Phone Number
              </Row>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <Form
                  name="basic"
                  className="login_form"
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    //  extra={
                    //     <span className="font_12 text_white1 " >
                    //       PLEASE ENTER MOBILE NUMBER WITH YOUR COUNTRY CODE
                    //     </span>
                    //   }
                    // label="Mobile Number"
                    name="phone_number"
                    className="mb_2"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Registered Phone Number!",
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{
                        background: "#18181C",
                        width: "100%",
                        borderBottom: "1px solid #7a7e8a",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        color: "white",
                      }}
                      buttonStyle={{
                        background: "#18181C",
                        borderBottom: "1px solid #7a7e8a",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                      dropdownStyle={{
                        height: "150px",
                        background: "#18181C",
                        color: "white",
                      }}
                      searchStyle={{
                        background: "#18181C",
                        width: "100%",
                        borderBottom: "1px solid #7a7e8a",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        marginLeft: "-10px",
                        marginTop: "-10px",
                      }}
                      country={"us"}
                      enableSearch
                      disableSearchIcon
                      // value={this.state.phone}
                      onChange={(phone: any) => console.log(phone)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="red_custom_btn mt-1 login_btn"
                      type="primary"
                      htmlType="submit"
                    >
                      Send now
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
           
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
// Customizable Area End
