// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import PaymentMobileViewController from './PaymentMobileViewController'

export default class MCurrentPlan extends PaymentMobileViewController {
  render() {
    const { current_plan, status_of_plan, next_invoice_no } = this.props;

    let now = new Date();
    now.setDate(now.getDate() + 30);

    return (
      <>
        {current_plan ? (
          <Grid item xs={12} container className="m_user_plan_div">
            <Grid item xs={12} container className="m_user_plan_continer">
              <Grid
                item
                xs={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span className="m_currentPlan_text">My Current Plan</span>
                <Grid className="m_plan_name_div">
                  <Grid className="m_plan_name">
                  {current_plan.attributes.name?.endsWith("+") ? (
                <span className="currentplan-align">
                  {current_plan.attributes.name?.slice(0, -1)}
                  <AddIcon className="redColor" fontSize="medium" />
                </span>
              ) : (
                <span>{current_plan.attributes.name}</span>
              )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="m_subscriber_content">
                <span className="m_subscriber_title">Subscription:</span>
                <span className="m_subscriber_plan_rupees">
                  ${current_plan?.attributes?.amount} Monthly
                </span>
              </Grid>
              <Grid item xs={12} className="m_subscriber_content">
                <span className="m_subscriber_title">
                  {status_of_plan ? "Next Payment Due" : "Expire Date"}
                </span>
                <span className="m_subscriber_plan_rupees">
                  {next_invoice_no
                    ? moment(next_invoice_no, "MMM-DD-YYYY").format(
                        "MMM DD,YYYY"
                      )
                    : "----"}
                </span>
              </Grid>
              <Grid item xs={12} className="m_cancel_button">
                {status_of_plan ? (
                  <button
                    className="m_cancelPlan_button"
                    onClick={this.redirectWhenPlanCancel}
                  >
                    Cancel Plan
                  </button>
                ) : (
                  <button
                    className="m_cancelPlan_button"
                    style={{ cursor: "not-allowed" }}
                  >
                    Plan Cancelled
                  </button>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} container className="m_user_plan_div">
            <Grid item xs={12} container className="m_user_plan_continer">
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "25vh",
                }}
              >
                <span className="m_currentPlan_text">My Current Plan</span>
                <span
                  style={{
                    color: "white",
                    fontFamily: "Inter",
                    fontSize: "16px",
                  }}
                >
                  Currently no plans are activated in your account
                </span>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}
// Customizable Area End
