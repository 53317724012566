// Customizable Area Start
import React, { useState } from "react";
import { composeprofile } from "blocks/dashboard/src/assets";
import "blocks/dashboard/assets/css/repost.css";
import { Button, Box, Dialog, Grid } from "@material-ui/core";
import "blocks/user-profile-basic/src/Common/CardItem.web.css";
import { usePubNub } from 'pubnub-react';
import { openNotification } from "../../Notification.web";
import { getChannelName,sendMessage } from "blocks/Sms/src/ChatUtils";

export default function SendMessage({ openScanQr, setOpenScanQr, handleRedirectToMessage, user_name, avatar, CurrentId }: any) {
  const user_id = localStorage.getItem("user_id")
  const [errorMsg, setErrorMsg] = useState("");
  const [input, setInput] = useState<any>("");
  const ChannelName = getChannelName(String(user_id), CurrentId);
  const pubnub = usePubNub();


  const onSend = (msgTxt: string) => {
    if (msgTxt == "") {
      setErrorMsg("Please type your text")
    }

    else {
      const message = {
        content: {
          text: msgTxt.trim(),
          sender: {
            id: CurrentId,
            name: "",
            avtar: avatar,
          }
        },
        type: '',
        id: crypto.getRandomValues(new Uint8Array(16))
        // id: Math.random().toString(16).substr(2)
      };
      sendMessage(CurrentId, msgTxt)
      pubnub.publish({ channel: ChannelName, message }, function (status: any, response: any) {
      });

      setOpenScanQr()
      openNotification("Message has been sent successfully", "Success")
    }
  }


  return (
    <Dialog
      // open={false}
      // onClose={true}
      onClose={setOpenScanQr}
      open={openScanQr}
      // onClose={() => this.setState({ openScanQr: false })}
      aria-labelledby="simple-dialog-title"
      // open={this.state.openScanQr}
      BackdropProps={{ style: { backdropFilter: "blur(10px)", backgroundColor: "rgba(0, 0, 0, 0.15)", }, }}
      PaperProps={{ className: "dialog-Paper send-dialog-Paper" }}>
      <Grid container xs={12} className="pd20">
        <Grid container item xs={10} className="mrAuto">
          <Grid item xs={12}>
            <Box className="send-message-to"> To </Box>
            <Box className="send-message-box ">
              <img src={avatar || composeprofile} width="30px" height="30px" className="send-message-img" />
              <input placeholder={user_name || 'Artist Name'} className="send-message-input message-dialog" style={{
                boxShadow: "none"
              }} value={user_name || 'Artist Name'}/>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={10} className="message-to-grid mrAuto">
          <Grid item xs={12}>
            <Box className="send-message-to">
              Message
            </Box>
            <Box >
              <textarea rows={4} cols={50} className="send-message-textarea color-white"
                placeholder="Type your text here"
                style={{ resize: "none" }}
                onChange={(e) => {
                  setInput(e.target.value);
                  setErrorMsg("")
                }}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') {
                    onSend(input)
                    setInput('')
                    setErrorMsg("")
                  }
                }}
              />
              <p style={{ color: '#aa3b3b' }}>{errorMsg}</p>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={8} className="mrAuto" >
          <Button className="sendNow-btn" onClick={() => {


            onSend(input);
            setInput('')


          }}
          >Send Now</Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
// Customizable Area End