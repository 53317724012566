// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");
import { Message } from "../../../../../framework/src/Message";




export interface Props {
    open: boolean,
    handleClose: () => void,
    currentTab:string,
    handleTrack:(data:any)=>void;
}


interface S {
    filterState:string;
}

interface SS {
    id: any;
}

export default class CtrlFilterPopupController extends BlockComponent<
    Props,
    S,
    SS
> {
    getFilterTabApiCallId:string=""



    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
        ];
        this.state = {
            filterState:"initial"  

        };
       

        
            
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }
    filterData= async (filter_key: string, type: string) => {
        let selectedTab:string=this.props.currentTab
        let currentUrl=`/bx_block_profile/list_user_track_splits?upload_type=${selectedTab}&${filter_key}=${type}`
        if (this.props.currentTab === "audio"||this.props.currentTab === "video"){
        let convertplaylistType= type.replace("track","playlist")
            currentUrl=`/bx_block_audiomusic/get_all_playlists?type=${selectedTab}&playlist_type=${convertplaylistType}`
        }else if (this.props.currentTab.endsWith("s")) {
            selectedTab = selectedTab.slice(0,-1);
            selectedTab = selectedTab.charAt(0).toUpperCase()+selectedTab.slice(1);
          }
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFilterTabApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + currentUrl
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    
    }


      handleClose=()=>{
        this.setState({"filterState":"initial"})
        this.props.handleClose()
      }


      async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
    
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        if (apiRequestCallId == this.getFilterTabApiCallId) {
          this.props.handleTrack(responseJson);
          this.handleClose()
        } 
        // Customizable Area End
      }

}

// Customizable Area End