Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("./../../../framework/src/config")


// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PremiumPlanComparison";
exports.labelBodyText = "PremiumPlanComparison Body";


exports.subscriptionPlan = "/bx_block_subscription_plan/subscription_plans";
exports.handleSubscribe = "/CreativeProfile?session_id={CHECKOUT_SESSION_ID}";
exports.getSubscriptionCardUpdate= "/bx_block_subscription_plan/checkout_session"
exports.acceptPlan="/bx_block_subscription_plan/subscribe"
exports.handleSubscribeListener = "/ListenerProfile?session_id={CHECKOUT_SESSION_ID}";

exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.deleteAPIMethod = "DELETE";

exports.baseUrl = urlConfig.baseURL;

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End