// Customizable Area Start
import { Layout, Menu, Row } from 'antd';
import React from 'react';
import { nxzloader } from '../assets';
import CreativeBox from '../../../../components/src/CustomChart/PopUps/CreativeBox.web';
//@ts-ignore
import {
  Grid,
  Hidden,
} from "@material-ui/core";
import CustomCarousel from '../../../../components/src/CustomChart/PopUps/CustomCarousel.web';
import './index.css'
import { AudioContextApi } from '../AudioContext/AudioContext.web';
import { Content } from 'antd/lib/layout/layout';
import HomeHeader from '../../../../components/src/CustomChart/PopUps/HomeHeader.web';
import CreativeController from './CreativeController';
import AsideLeft from '../../../../components/src/CustomChart/PopUps/AsideLeft.web';


export default class CreativePage extends CreativeController {
  render() {
    const { creativeList, banner,loading }: any = this.state;
    const { isPlaylist } = this.context;
    return (
      <>
        <div
          className='creativemainbox'
        >
          <Layout className='creativeslayout'>
            {/* .......................SideBar...................................... */}
            <Hidden smDown>
              <AsideLeft />
            </Hidden>
            <Content
              className='creativecontentblock'
            >
              <HomeHeader />
              {/* for content page */}

              <div className={isPlaylist ? 'MainBody creative-page-isPlaylist' : 'MainBody creative-page-body'}>
                <div className="head ml-15 mr_2 " style={{ marginBottom: "-25px" }}>
                  <div className=''><h2 className='Creativestext'>Creatives</h2></div>
                  <div style={{ position: "relative" }} >
                  </div>
                </div>
                {loading
                  ? (<Row className='creativeloader'>
                    <img src={nxzloader} className='loadersize' />
                  </Row>) : (<>

                    <div >
                      <div>
                        <CustomCarousel data={banner}
                          handlePreview={this.handleBannerPreview}
                        />
                      </div>

                      <div className="boxes">
                        <div className='mt-1 mb_1 creative-height'>
                          <Grid container className="creative-block mostly-customized-scrollbar creativesccroll" >
                            {
                              creativeList?.length !== 0 &&
                              creativeList?.map((creativeLists: any, index: number) => {
                                return (
                                  <Grid item md={3} style={{ height: 83, marginBottom: 10 }} className="outer_card_width">
                                    <CreativeBox artistNo={index + 1} key={index}
                                      id={creativeLists?.attributes?.id != null && creativeLists?.attributes?.id}
                                      avatar={creativeLists?.attributes?.avatar != null && creativeLists?.attributes?.avatar}
                                      trackCount={creativeLists?.attributes?.track_count}
                                      userName={creativeLists?.attributes?.user_name != null && creativeLists?.attributes?.user_name}
                                      fullName={(creativeLists?.attributes?.first_name != null && creativeLists?.attributes?.last_name != null) && creativeLists?.attributes?.first_name + " " + creativeLists?.attributes?.last_name}
                                      userType={creativeLists?.type}
                                    />
                                  </Grid>
                                )
                              })}
                          </Grid>

                        </div>
                      </div>

                    </div>
                  </>
                  )
                }
                {/* player................. */}
                <div>


                </div>
              </div>
            </Content>
          </Layout>
        </div>

      </>
    );
  }
};
CreativePage.contextType = AudioContextApi;
// Customizable Area End
