// Customizable Area Start
import React from "react";

const MusicBox = (props: any) => {
  return (
    <>
      <div className="music-Box">
        <video width="320" height="240" controls>
          <source src="./SampleVideo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="song-info">
          <h2>{props.songName}</h2>
          <p>{props.author}</p>
        </div>
      </div>
    </>
  );
};

export default MusicBox;
// Customizable Area End
