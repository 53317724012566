// Customizable Area Start
import { Typography, Row } from 'antd';
import {
    Box,
    Button,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme
} from "@material-ui/core";
import React from 'react'
import { bluecheck } from '../assets';
import StreamAudioVideoController from './StreamAudioVideoController';
import { defaultProfile, nxzloader } from 'blocks/dashboard/src/assets';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
const LabelHistoryRightsideAudiohistorycontainer = styled(Box)({
    width: "48%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "@media(max-width: 1000px)": {
        width: "100%",
        padding: "0px 10px 0px 10px"
    },
});

const RightLableSideSingletrackbox = styled(Box)({
    height: "81px",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    color: "white",
});
const Trackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
});


export default class LabelHistoryAudioVideo extends StreamAudioVideoController {
    returnUserListData = () => {
        return (
            this.props.tracklistData?.map((item, index) => (
                <RightLableSideSingletrackbox
                    key={index}
                    data-test-id="labelTopMusicPlay"
                    onClick={() => {
                        this.topPlayMusic(item, index, this.props.tracklistData, "song");
                    }}
                >
                    <Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                            {this.props.rightsideSelectedtab == "Audio" ? <img
                                src={item.attributes.art_work || defaultProfile}
                                style={this.props.webStyle.labelhistoryleftimage}

                            /> : <img
                                src={item.attributes.art_work || defaultProfile}
                                style={this.props.webStyle.labelhistoryleftvideoimage}

                            />}
                        </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box>
                                <Trackname className="tracks_songs_text">
                                    {item.attributes.title}
                                    {item.attributes.is_verified &&
                                        <span className="Jason-Derulo">

                                            <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                        </span>}
                                </Trackname>
                                <Box>
                                    <span style={this.props.webStyle.lablehistorysplittext}>
                                        {item.attributes.title}

                                    </span>
                                </Box>
                            </Box>
                            <Box>
                                <Box style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}>
                                    <span style={this.props.webStyle.labelhistorysplittextwhite}>
                                        {item.attributes.count}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <BorderLinearProgress variant="determinate" value={this.getProgress(item)} />
                        </Box>
                    </Box>

                </RightLableSideSingletrackbox>
            ))
        )
    }

    renderContent = () => {
        const { isLoading, tracklistData } = this.props
        if (isLoading) {
            return (
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <img src={nxzloader} style={{ width: "5%" }} />
                </Row>
            )
        } else if (tracklistData.length > 0) {

            return this.returnUserListData()
        } else {
            return (
                <div
                    style={{
                        color: "#ffffff",
                        fontSize: 16,
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "113px"
                    }}
                >
                    No Stream History Available
                </div>
            )
        }
    }
    render() {
        const { webStyle, rightsideSelectedtab, streamedArtist } = this.props

        return (
            <LabelHistoryRightsideAudiohistorycontainer>

                <Box>
                    <Typography style={webStyle.trackname}>
                        {" "}
                        <span style={webStyle.bluetext}>
                            {streamedArtist && streamedArtist + ":"}
                        </span>{" "}
                        Streamed
                    </Typography>
                </Box>
                <Box style={{ width: "100%" }}>
                    <Box style={webStyle.LabelRightsidetabsbutton}>
                        <Box style={webStyle.labelhistorytabbox}>
                            <Button

                                style={rightsideSelectedtab == "Audio" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}

                                onClick={() => this.props.handleAudiovideotab("Audio")}
                                data-test-id="lableaudiotab"
                            >
                                Audio
                            </Button>
                        </Box>
                        <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={rightsideSelectedtab == "Video" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                onClick={() => this.props.handleAudiovideotab("Video")}
                                data-test-id="lablevideotab"
                            >
                                Video
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Box>
                    {this.renderContent()}

                </Box>
            </LabelHistoryRightsideAudiohistorycontainer>

        )
    }
}

LabelHistoryAudioVideo.contextType = AudioContextApi;
// Customizable Area End