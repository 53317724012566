// Customizable Area Start
import React from 'react';
import { Bar } from 'react-chartjs-2';


export const DashboardRevenueChart = (props: any) => {
   
  
    const graph =[1,2,4,3,2,7,3,2,3,1,2]
    const datas: any = {
        labels:["","","","","","","","","","","",""],
        datasets: [
            {
                data: graph,
                backgroundColor: '#ff0000',
                barPercentage: 0.9,
                hoverBackgroundColor: '#3959f9',
                categoryPercentage: 1,
                borderWidth: 1,
                borderSkipped: false,
                borderRadius: 7,
            },
        ],
    };
    const options = {
        layout: {
            padding: {
                bottom: -12
            }
        },
        cornerRadius: 7,
        scales: {
            y: {
                beginAtZero: true
            },
           
            yAxes: [
                {
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                    },
                    radius: 7,
                    ticks: {
                        stepSize: 2,
                        display: false,
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        color: '#ff0000',
                        display: graph?.every((current: any) => current === 0) ? true : false,
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                    radius: 7,
                    stacked: true,
                    ticks: {
                        padding: 0,
                        display: false,
                    },
                    scaleLabel: {
                        color: '#ff0000',
                        display: true
                    },
                },
            ],
        },
        
      
        legend: {
            display: false,
        },
        elements: {
            point: {
                radius: 7,
                hoverRadius: 7,
                pointStyle: 'rectRounded',
            },
        },
       
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            callbacks: {
                title: function (context: any) {
                    return '';
                },
                label: (context: any) => {
                    return context.value;
                },
            },
            xAlign: 'right',
            displayColors: false,
            intersect: false,
        },
        
    };
    return (
        <>
     
            <Bar data={datas} options={options} />
        </>
    );
}

export default DashboardRevenueChart;
// Customizable Area End

