import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { album1, minus, plus } from "blocks/dashboard/src/assets";
import React from "react";
import { playStats } from "../../assets";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
  checkAllDownloaders: any;
  seeAllTopDownloaders: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  seeAllTopDownloaders: boolean;
  threebtnToogle: any;
  downloadersList: any;
  downloaderDetailsData: any;
  selectIndex: any;
  totalTracksCount: any;
  loading: boolean;
  setDowloadedData: any;
  snapshotData: any;
  count: any;
  pct: any;
  statsList: any;
  downloadLoader:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SnapshotController extends BlockComponent<Props, S, SS> {
  apiDownloadersList: string = "";
  apiSnapshotsList: string = "";
  carouselRef: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.carouselRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      seeAllTopDownloaders: false,
      threebtnToogle: "Beats",
      downloadersList: [],
      downloaderDetailsData: [
        {
          image: album1,
          title: "Renaissance Tour",
          artist: "Artist +2",
        },
      ],
      selectIndex: null,
      totalTracksCount: null,
      loading: false,
      setDowloadedData: {},
      snapshotData: {
        total_play_count: {
          Hook: 0,
          Song: 0,
          Verse: 0,
        },
        total_view_count: {
          Video: 0,
        },
        total_percentage: {
          Hook: 0.0,
          Song: 3.33,
          Verse: 0.0,
          Video: 0.0,
        },
        splits_count: 0,
        last_two_weeks_split_count_percentage: 0.0,
        total_download_count: 0,
        last_two_weeks_download_percentage: 0,
        play_count: 0,
        last_two_weeks_play_percent: "0",
        view_count: 5,
        last_two_weeks_view_percent: "0.0",
        last_two_weeks_download_count_percent: [
          0,
          20,
          -0,
          -0,
          0,
          0,
          0,
          0,
          0,
          -0,
          0,
          -0,
          0,
          0,
        ],
      },
      count: {},
      pct: {
        Beat: "0",
        Hook: "0",
        Song: "0",
        Verse: "0",
        Video: "0",
      },
      statsList: [
        {
          icon: playStats,
          play_count: "",
          last_two_weeks_play_percent: "",
        },
      ],
      downloadLoader:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getSnapShotsData();
    this.getDownloaders();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.snapshotData !== this.state.snapshotData) {
      this.updateCounts();
    }
  }

  goBackRef = () => {
    this.carouselRef.current?.next();
  };
  getPctTxt = (data: any): string => {
    if (Number(data)) {
      if (data.toString().startsWith("-")) {
        return data.toString().replace("-", "");
      }
      return data + "%";
    } else {
      return data + "%";
    }
  };

  updateCounts = () => {
    const { snapshotData } = this.state;
    const defaultCounts = {
      Beat: 0,
      Hook: 0,
      Song: 0,
      Verse: 0,
      Video: 0,
    };

    const count = {
      ...defaultCounts,
      ...snapshotData.total_play_count,
      ...snapshotData.total_view_count,
    };
    const pct = { ...defaultCounts, ...snapshotData.total_percentage };

    this.setState({
      count,
      pct,
    });
  };

  getSnapShotsData = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSnapshotsList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getSnapshotData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getDownloaders = () => {
    this.setState({downloadLoader:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDownloadersList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getThousandDownloaders}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  changetabs = (index: string) => {
    this.setState({ threebtnToogle: index });
  };
  handleExpandDownloaders = (index: any, data: any) => {
    this.setState({ selectIndex: index, setDowloadedData: { ...data } });
  };

  openDownloader = (index: any, item: any) => {
    this.handleExpandDownloaders(index, item);
    this.carouselRef?.current?.next();
  };

  handleDownloadedList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.error) {
      this.setState({
        downloadersList: responseJson.data,
        totalTracksCount: responseJson.total_tracks_count,
        downloadLoader:false,
        loading: false
      });
    } else {
      this.setState({
        loading: false,
        downloadLoader:false
      });
    }
  };

  handleSnapshotList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        snapshotData: responseJson,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  renderPercentageIcon = (pct: any) => {
    const isZero = pct == 0;
    const isNegative = pct?.toString().startsWith("-");

    if (!isZero) {
      return (
        <img
          src={isNegative ? minus : plus}
          className={
            isNegative ? "mr4 home_counting_minus" : "mr4 home_counting_plus"
          }
        />
      );
    }

    return null; // or handle other cases as needed
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

   
    switch (apiRequestCallId) {
      case this.apiDownloadersList:
        this.handleDownloadedList(responseJson);
        break;
      case this.apiSnapshotsList:
        this.handleSnapshotList(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
