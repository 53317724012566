// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
export interface Props {
  open: boolean;
  handleClose: () => void;
  listenerTitle: any;
  subscription: any;
  updateSubscription: () => void;
  openConfirmPaymentPopup: any;
  closePayModal: any;
  currentPaymentData: any;
  CreateNewPayment: any;
  isCurrentPlan: any;
  next_invoice_no: any;
  interval:any;
}
interface S {
  OpenPopup: boolean;
}
interface SS {
  id: any;
}

export default class ConfirmPlanTempController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      OpenPopup: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    

    // Customizable Area End
  }
}
// Customizable Area End
