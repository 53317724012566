export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const album = require("../assets/album 1.png")
export const plusicon=require("../assets/plusicon.jpg")
export const artistimage=require('../assets/artistImage.jpg')
export const backarrow=require("../assets/VectorBack.png")
export const check=require("../assets/Check.png")
export const videoimage=require("../assets/VideoImage.png")
export const songsimage=require("../assets/SongsImage.jpg")
export const pushbanner=require("../assets/Push Banner.png")
export const wallet=require("../assets/wallet.png")
export const profile=require("../assets/Profile.png")
export const eye=require("../assets/eye.png")

