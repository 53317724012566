// Customizable Area Start
// @ts-nocheck
import React from "react";
import { Box, IconButton, Typography, Button } from "@material-ui/core";
import LinkCopiedModal from "./LinkCopiedModal.web";
import {
  Add_user_Male,
  Link_Chain_Copy,
  Playlist_Icon_Add,
  Send_Message,
} from "../../CustomComponent/SharePlaylistPopup/assets";
import MessageLinkPopup from "components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";
import AddToQueuePopup from "../../CustomComponent/AddToQueue/AddToQueuePopup.web";
import { AudioContextApi } from "../../AudioContext/AudioContext.web";
import { nxzloader, pauseA, playButton, removeUser } from "../../assets";
import { Row } from "antd";
import AudioVideoInfoCardController from "./AudioVideoInfoCardController";

export const ActionButton = (props: {
  title: string;
  src: any;
  onClick: () => void;
}) => {
  return (
    <Box className="AVICBtnBox">
      <IconButton className="Btn" onClick={props.onClick}>
        <img style={{ height: "25px", width: "25px" }} src={props.src} />
      </IconButton>
      <Typography className="BtnText">{props.title}</Typography>
    </Box>
  );
};

export default class AudioVideoInfoCard extends AudioVideoInfoCardController {
  render() {
    const { handleAudioVideo, setHandleAudioVideo } = this.context;

    const isFollowing = this.props.selectedTrackMediaDetails?.attributes
      ?.is_following;
    const followUserImg = isFollowing ? removeUser : Add_user_Male;
    const selectedSong = localStorage.getItem("selectedSong");

    return (
      <>
        {this.props.selectedTrackMediaDetails == "" ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <img src={nxzloader} style={{ width: "15%" }} />
          </Row>
        ) : (
          <Box className="AVIMainContainer">
            <Box
              className={`${
                this.props.selectedTrackMediaDetails?.attributes
                  ?.track_upload_type == ("Video" || "video")
                  ? "AVInfoContainer Video"
                  : "AVInfoContainer Audio"
              }`}
              style={{ position: "relative" }}
              onClick={() => {
                this.handlePlayAudioVideo();
              }}
            >
              <img
                src={this.props.selectedTrackMediaDetails?.attributes?.art_work}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              />
              <IconButton className="PlayBtn" onClick={this.handlePlay}>
                <img
                  src={
                    selectedSong &&
                    selectedSong.split('"')[1] ===
                      this.props.selectedTrackMediaDetails?.id &&
                    handleAudioVideo == 2
                      ? pauseA
                      : playButton
                  }
                  style={{ height: "40px" }}
                  onClick={() => {
                    setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1);
                  }}
                />
              </IconButton>
            </Box>
            <Typography className="AVICMediaName">
              {this.props.selectedTrackMediaDetails?.attributes?.title}
            </Typography>
            <Typography className="AVICMediaCreatorName">
              {this.props.selectedTrackMediaDetails?.attributes?.artist_name}
            </Typography>
            <Button
              className="ViewProfileBtn viewProfileButtonWidthHeight"
              onClick={this.handleProfileClick}
            >
              View Profile
            </Button>
            <Box className="AVICButtonsContainer" style={{ width: "100%" }}>
              <ActionButton
                title={isFollowing ? "Unfollow" : "Follow"}
                src={followUserImg}
                onClick={this.handleFollowingClick }
              />
              <ActionButton
                title="Playlist"
                src={Playlist_Icon_Add}
                onClick={this.handleOpenAudioPopup}
              />
              <ActionButton
                title="Send"
                src={Send_Message}
                onClick={this.handleSendMsg}
              />
              <ActionButton
                title="Copy Link"
                src={Link_Chain_Copy}
                onClick={() => {
                  this.linkCopy();
                }}
              />
            </Box>
            <LinkCopiedModal
              isOpen={this.state.isCopiedModalOpen}
              handleClose={this.handleCopiedLinkFalse}
            />
            <MessageLinkPopup
              id="listner_dm"
              showMsgModal={this.state.sendLinkViaDM}
              closeMsgModal={this.handleSendLinkFalse}
              closeModalDialog={this.handleSendLinkFalse}
              copiedLinked={this.state.sendLinkCopy}
              currentUserId={1}
            />
            <AddToQueuePopup
              id="search"
              open={this.state.openAudioPopup}
              handleClose={this.handleAudioPopupFalse}
              playlistType="audio"
              trackData={this.props.selectedTrackMediaDetails}
            />
          </Box>
        )}
      </>
    );
  }
}
AudioVideoInfoCard.contextType = AudioContextApi;
// Customizable Area End
