// Customizable Area Start
import {
  Button,
  Typography,
  Box,
  LinearProgress,
  Grid
} from "@material-ui/core";
import React from "react";
import "./DashboardAnalytics.css";
import { plays, commentBlue, loopIc } from "blocks/dashboard/src/assets";
import DashboardAnalyticscontroller from "./DashboardAnalyticsController.web";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { defaultProfile, nxzloader } from "../../assets";
import { Row } from "antd";

interface Attributes {
    total_view_count: number;
    play_view_count: number;
    total_play_count: number;
    total_repost_count: number;
    total_comment_count: number;
    [key: string]: any; 
  }

interface StateListItem {
    attributes: Attributes;
  }
export default class DashboardAnalytics extends DashboardAnalyticscontroller {


  render() {
    const statesAndCityAnalytics = () => {
      return (
        <>
          {this.state.loading ? (
            <Row className="analytics-loader">
              <img src={nxzloader} className="analytics-nxzloader-width" />
            </Row>
          ) : (
            <div>
              <Box className="analytics_topheader_container">
                <Box>
                  <Typography className="Top-141">Top 100 States</Typography>
                </Box>
                <Box className="analytics_topheader_element">
                  <Box>
                    <Button
                      style={{
                        background:
                          this.state.index === "state" ? "#274af4" : ""
                      }}
                      onClick={() => this.changetabs("state", "top_states")}
                      data-test-id="states"
                      className="stats_tab"
                    >
                      States
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className="stats_tab"
                      style={{
                        background: this.state.index === "city" ? "#274af4" : ""
                      }}
                      onClick={() => this.changetabs("city", "top_cities")}
                      data-test-id="cities"
                    >
                      Cities
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      style={{
                        background:
                          this.state.index === "listeners" ? "#274af4" : ""
                      }}
                      className="stats_tab"
                      onClick={() =>
                        this.changetabs("listeners", "top_thousand_listeners")
                      }
                      data-test-id="listeners"
                    >
                      Listeners
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box className="topAnaliticbox">
          {this.state.stateList.length > 0 ? (
                this.state.stateList.map((item: StateListItem, index: number) => {
                 return (
                    <Grid container className="topstatscontainer">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className="topstats_container_element"
                      >
                        <Typography className="analytics_listing_head">
                          {index + 1}
                        </Typography>
                        <Typography className="analytics_listing_head">
                          {item.attributes[this.state.index]}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className=" analytics_slider"
                        style={{ width: "90%" }}
                      >
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            this.calculateProgressValue(item)
                          }
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={4}
                        className="allplays_stats"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          className="analytics_twentyhr"
                        >
                          <Box className="allplays_stats_element" width={'0px'} style={{gap:"5px"}}>
                            <img className="analytics_icon_image" src={plays} />
                            <Typography className="analytics_listing_head">
                              {" "}
                              {this.formatNumber(
                                item.attributes.total_play_count
                              )}
                            </Typography>
                          </Box>
                          <Box className="allplays_city_element" width={'0px'} style={{gap:"5px"}}>
                            <img
                              src={loopIc}
                              className="analytics_loop_image"
                            />
                            <Typography className="analytics_listing_head">
                              {" "}
                              {this.formatNumber(
                                item.attributes.total_repost_count
                              )}
                            </Typography>
                          </Box>
                          <Box className="allplays_comment_element" width={'0px'} style={{gap:"5px"}}>
                            <img
                              src={commentBlue}
                              className="analytics_loop_image"
                            />
                            <Typography className="analytics_listing_head">
                              {this.formatNumber(
                                item.attributes.total_comment_count
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                ))
                :
                (
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#fff",
                      padding: "5",
                      justifyContent: "center",
                    }}
                  >
                    No {this.state.index.charAt(0).toUpperCase() + this.state.index.slice(1)} Present
                  </Typography>
                )
              }
              </Box>
            </div>
          )}
        </>
      );
    };

    const listenersAnalytics = () => {
      return (
        <div>
          <Box className="analytics_topheader_container">
            <Box>
              <span className="Top-141">Top 1K Listeners</span>
            </Box>
            <Box className="analytics_topheader_buttongroup">
              <Box>
                <Button
                  data-test-id="states"
                  style={{
                    background: this.state.index === "state" ? "#274af4" : ""
                  }}
                  onClick={() => this.changetabs("state", "top_states")}
                  className="stats_tab"
                >
                  States
                </Button>
              </Box>
              <Box>
                <Button
                  className="stats_tab"
                  style={{
                    background: this.state.index === "city" ? "#274af4" : ""
                  }}
                  onClick={() => this.changetabs("city", "top_cities")}
                  data-test-id="cities"
                >
                  Cities
                </Button>
              </Box>
              <Box>
                <Button
                  style={{
                    background:
                      this.state.index === "listeners" ? "#274af4" : ""
                  }}
                  className="stats_tab"
                  onClick={() =>
                    this.changetabs("listeners", "top_thousand_listeners")
                  }
                  data-test-id="listeners"
                >
                  Listeners
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className="topAnaliticbox">
          {this.state.stateList.length > 0 ? (
            this.state.stateList.map((item: StateListItem, index: number) => (
              <Grid container className="topstatscontainer">
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className="topstats_container_element"
                >
                  <Typography className="analytics_listing_head">
                    {index + 1}
                  </Typography>
<div className="default-profile-icon-header-analytics">
                  <img
                    src={item.attributes.avatar || defaultProfile}
                    className={item.attributes.avatar?"analytics-avatar-listener":"default-profile-icon-analytics"}
                  />
                  </div>

                  <Typography className="analytics_listing_head">
                    {item.attributes.user_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className=" analytics_slider"
                  style={{ width: "90%" }}
                >
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      this.calculateProgressValue(item)
                    }
                  />
                </Grid>
                <Grid container item xs={12} sm={4} className="allplays_stats">
                  <Grid item xs={12} sm={10} className="analytics_twentyhr">
                    <Box className="allplays_stats_element" width={'0px'} style={{gap:"5px"}}>
                      <img className="analytics_icon_image" src={plays} />

                      <Typography className="analytics_listing_head">
                        {" "}
                        {this.formatNumber(item.attributes.total_play_count)}
                      </Typography>
                    </Box>
                    <Box className="allplays_city_element" width={'0px'} style={{gap:"5px"}}>
                      <img className="analytics_loop_image" src={loopIc} />
                      <Typography className="analytics_listing_head">
                        {this.formatNumber(item.attributes.total_repost_count)}
                      </Typography>
                    </Box>
                    <Box className="allplays_comment_element" width={'0px'} style={{gap:"5px"}}>
                      <img src={commentBlue} className="analytics_loop_image" />
                      <Typography className="analytics_listing_head">
                        {this.formatNumber(item.attributes.total_comment_count)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )))
            :
            (
              <Typography
               className="nolistenertext"
              >
                No Listener Present
              </Typography>
            )
          }
          </Box>
        </div>
      );
    };

    const compRender = () => {
      switch (this.state.index) {
        case "state":
          return statesAndCityAnalytics();
        case "listeners":
          return listenersAnalytics();
        default:
          return statesAndCityAnalytics();
      }
    };
    return (
      //Merge Engine DefaultContainer
      compRender()

      //Merge Engine End DefaultContainer
    );
  }
}

const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 7,
      borderRadius: 5
    },
    colorPrimary: {
      backgroundColor: "#212121 !important"
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#3959f9"
    }
  })
)(LinearProgress);
// Customizable Area End
