// Customizable Area Start
import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createBrowserHistory } from "history";
export const configJSON = require("../../../../framework/src/config");
const history = createBrowserHistory()
export interface Props {
  creativeById?: any;
  new_released_video?: any;
  userData?: boolean;
  openPopupFollowers?:any;
  openPopupFollowing?:any;
  allFollowerData?:any;
  followingData?:any;
  loading?:any;
  unfollowState?:any;
  loadingFollow?:any;
  openScanQr?:any;
  visible?:any;
  getFollowerData: () => void;
  unfollowUserById: (id:any) => void;
  followUserByID: (id:any) => void;
  handleRedirectToMessage: () => void;
  FolloweUser: (id:any) => void;
  getFollowingData: () => void;
  UnFolloweUser: (id:any) => void;
  handlePopupVisible:()=>void;
  setVisible:(data:any)=>void;
  setLoadingFollow:(data:any)=>void;
  setopenScanQr:(data:any)=>void;
  setopenPopupFollowers:(data:any)=>void;
  setopenPopupFollowing:(data:any)=>void;

}
interface S {
    // openPopupFollowing: boolean,
    
  
}
interface SS {
  id: any;
}

export default class ComposerBlockUpperVideoController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getDataByType: string = "";
  getPlayListId: string = "";
  getTopVideoList: string = "";
  getFollowerListId: string = "";
  getCreativeListId: string = "";
  getactivityListId: string = "";
  getTop100List: string = "";
  getallPlaylistId: string = "";
  getFollowId: string = '';
  getUnFollowId: string = '';
  getUserFollowerListId: string = '';
  handleFilterApiCallId: any
  myRef: any;
  nextRef: any;
  vedioPlayer: any;
  previousHref !: string;
  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    this.nextRef = React.createRef();
    this.vedioPlayer = React.createRef()
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        // openPopupFollowing: false,

    };
   

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


 

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }


    // Customizable Area End
  }
}
// Customizable Area End