// Customizable Area Start
import { Button, Carousel, Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Block } from '../../../../framework/src/Block';
import { nextIC, prevIC } from 'blocks/dashboard/src/assets';
import 'blocks/dashboard/assets/css/carousel.css'
import LoginAlert from './LoginAlert.web'
import { useHistory } from 'react-router-dom'

const CustomCarousel = (props: any) => {
    const history = useHistory();
const carouselRef: any = useRef(null);

    const _settings = {
        autoplay: false,
        dots: true,

    }
    const SlideItem = (data: any) => {
        const [dialogText, setDialogText] = useState("")
        const [open, setOpen] = useState(false)
        const value = data.data
        const currentId = value?.attributes?.banner_button === "Watch" ? value?.attributes?.video_file_id : value?.attributes?.track_file_id
        const currentTrack = value?.attributes?.banner_button === "Watch" ? value?.attributes?.video_file : value?.attributes?.track_file
        const userType: any = localStorage.getItem("user_type")
        const handleClose = () => {
            setOpen(false)
        }
        const token: any = localStorage.getItem("token");

        return (
            <div>
                <div className='image' style={{ overflow: "hidden" }}>
                    <img src={value?.attributes?.image} width="100%" style={{ height: "380px" }} />
                    {
                        value?.attributes?.sub_title && <div className="slider-content">
                                <div style={{ color: "red", fontSize: "16px", zIndex: 9999,fontWeight:600 ,textTransform:"capitalize"}}>{value?.attributes?.title}</div>
                            <h1 >{value?.attributes?.sub_title}</h1>
                            <div style={{fontWeight:600}}>{value?.attributes?.description}</div>
                            <button style={{
                                width: "114px",
                                height: "37px",
                                marginLeft: "1vw",
                                padding: "8px 30px 9px",
                                borderRadius: "23.5px",
                                backgroundColor: "#ff0501",
                                fontSize: "14px",
                                fontWeight: 600,
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    if (!token) {
                                        history.push('/Welcome')
                                    } else {
                                        props.handlePreview(value?.attributes?.banner_button, currentId, currentTrack)
                                    }
                                }
                                }
                            // onClick={() => console.log(currentTrack, "bannerTrack")}
                            >{value?.attributes?.banner_button === "Watch" ? "Watch" : "Listen"}</button>
                        </div>
                    }
                </div>
                {open && <LoginAlert open={open} handleClose={handleClose} dialogText={dialogText} />}

            </div>

        )
    }

    const PrevButtonOrginal = (props: any) => {
        return (
            <p
                style={{
                    position: "absolute",
                    top: `${props.top}`,
                    left: window.matchMedia("(max-width: 400px)").matches?"-5%":`${props.left}`,
                    transform: "translateY(-50%)",
                    cursor: "pointer"

                }}
                onClick={() => props.buttonRef.current.prev()}
            >
                <img src={prevIC} style={{ height: "30px", width: "30px" }} />
            </p>
        )
    }

    const NextButtonOrginal = (props: any) => {
        return (
            <p
                style={{
                    position: "absolute",
                    top: `${props.top}`,
                    right: window.matchMedia("(max-width: 400px)").matches?"-4%":`${props.right}`,
                    transform: "translateY(-50%)",
                    cursor: "pointer"

                }}
                onClick={() => props.buttonRef.current.next()}
            >
                <img src={nextIC} style={{ height: "30px", width: "30px" }} />
            </p>
        )
    }

    useEffect(() => {
        const intervalID = setInterval(() =>  {
            carouselRef.current.next()
        }, 4000);
    
        return () => clearInterval(intervalID);
    }, []);

    return (

        <Row className='width_100 ' style={{ padding: "14px 23px" }} justify="center">
            <Col span={24} style={{ padding: "0px" }}>

                <div className='customCarousel'>
                    <Carousel
                        ref={carouselRef}
                        {..._settings}
                    >

                        {
                            props?.data?.map((_data: any) => <SlideItem 
                            key={_data.id}
                            data={_data} />)
                        }

                    </Carousel>
                    {props.data?.length>1  &&
                    <>
                    <PrevButtonOrginal top="50%" left="-1%" buttonRef={carouselRef}></PrevButtonOrginal>
                    <NextButtonOrginal top="50%" right="-1%" buttonRef={carouselRef}> </NextButtonOrginal>
                   </>
                    }
                    </div>


            </Col>
        </Row>

    );
};

export default CustomCarousel;
// Customizable Area End
