// Customizable Area Start
import React from 'react';
import { Box, Modal, Hidden } from '@material-ui/core';
import  PlanCanceled  from './PlanCanceled.web';
import './PlanUpdateCancel.css';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import { nxzsoundLogo } from '../assets';
import PlanCancelController from './PlanCancelController'


export default class PlanCancel extends PlanCancelController{
render(){
 const { open, handleClose, closeCancelledModal, cancelledSubscription }= this.props
  const body = (
    <Box
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
      }}>
      <Hidden smDown>
        <AsideLeft />
      </Hidden>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
        <Box className='paperBack'>
          <Box className='update_plan'>
            <img src={nxzsoundLogo} alt='nxzsoundLogo' />
            <h1>You Sure?</h1>
            <Box className='plan_description'>
              <span className='fontWeight'>
                All content and future payments will be disabled.
              </span>
            </Box>
          </Box>
          <Box className='update_plan updatePlanBtn'>
            {/* <img
              src={cancelPlanRed}
              alt='visitProfile'
              style={{ paddingBottom: '10px', cursor: 'pointer' }}
              onClick={() => setOpenPopup(true)}
            />
            <PlanCanceled
              open={openPopup}
              handleClose={() => setOpenPopup(false)}
            />
    
            <img
              src={keepMyPlan}
              alt='viewPlan'
              onClick={() =>
                location.pathname === '/planpayments'
                  ? window.location.reload()
                  : history.push('/planpayments')
              }
              style={{ cursor: 'pointer' }}
            /> */}
            <button
              className='my_plan_Btn'
              onClick={() => closeCancelledModal()}
              style={{ backgroundColor: '#ff0000', marginBottom: '10px' }}>
              Cancel Plan
            </button>
            <PlanCanceled
              cancelledSubscription={cancelledSubscription}
              closeCancelledModal={closeCancelledModal}
            />
            <button
              className='my_plan_Btn'
              onClick={() =>
                this.handlePlanBtn()
              }
              style={{
                backgroundColor: '#212121',
                color: 'rgba(255, 255, 255, 0.5)',
              }}>
              Keep My Plan
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
    );
  return (
    <>
      <Modal open={open} onClose={handleClose} className='PlanDialog'>
        {body}
      </Modal>
    </>
  );
 }
};
// Customizable Area End