// Customizable Area Start
import React from 'react';
import {loopIc, playNow,defaultProfile, VideoUserAvatar } from 'blocks/dashboard/src/assets';
import 'blocks/user-profile-basic/src/Common/CardItem.web.css'
import UnlockContentCommon from 'blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web';
import { unlockRedirectionCheck } from 'blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web';

const VideoCard = (props: any) => {
    let currentClassName;
  let srcImg;
  let imgClass;
if (props.img !== undefined) {
  srcImg = props.img;
  currentClassName= 'position-relative'
  imgClass="position-abs"
} else {
  srcImg = defaultProfile;
  currentClassName="landingscreen-defaultCard"
  imgClass= 'landingscreen-img'
}
    return (
        <div className="video-card-center">
            <div className="music-Box video-card">
                <div className={currentClassName} style={{
                    position: 'relative',
                    width: '152px', height: '150px'}}>
                <img src={srcImg || VideoUserAvatar} className={imgClass}
                    onClick={() => unlockRedirectionCheck(props.handlePlayList,"",props.trackData)} id="imgId"
                     />
                <UnlockContentCommon trackData={props.trackData} height="100%" width="100%" />
                </div>
             
                <div className="song-info" style={{ marginLeft: "2px" }}>
                {props.item.type=="list_ad_campaign"?
           <span className="align-oneLine">
          <p className="ad-placement-text-profile">Ad: </p>

          <h2>{props.item.attributes.title.substring(0, 14)  ? props.item.attributes.title.substring(0, 14)  : "unknown"}</h2>
          </span>:

                <h2>{props.songName.substring(0, 14) || "Unknown"}</h2>
                  
                }
                <p>{props.author ? props.author : "unknown"}</p>
                </div>
                <div style={{ display: "flex" }}
                    className='text_blue'
                // className="song-icon"
                >
                    <div className='mr_05 mb_0' style={{ display: "flex", marginLeft: "-5px" }}>
                        <img src={playNow} style={{ height: "10px", width: "25px", cursor: "pointer", }} alt=""
                            onClick={props.handlePlayList}

                        />
                        <p className=' mb_0 songs-plays' >{props?.playCount || "0"}</p></div>
                        {props.type !="playlist" &&
                        
                    <div className='mr_05 mb_0' style={{ display: "flex" }}>
                        <img src={loopIc} className="mr_05" style={{ height: "14px", width: "14px", marginTop: "-2px", marginLeft: "7px" }} alt="" />
                        <p className=' mb_0 songs-plays' style={{ marginTop: "-5px" }}>{props?.repost_count ? props?.repost_count : "0"}</p>
                        </div>
                        }
                        </div>
            </div>
        </div>
    );
};

export default VideoCard;
// Customizable Area End
