// Customizable Area Start
import React, { useEffect, useState } from 'react';
import 'blocks/dashboard/assets/css/asideLeft.css';
import { Layout, Menu } from 'antd';
import BrandName from './BrandName.web';
import { useHistory, useLocation } from 'react-router-dom';
import { Homeblue, Downloadblue, Chartsblue, Playlistblue, Creativeblue, Videoblue, Homeicon, Chartsicon, Videoicon, Playlisticon, DownloadIcon, Creativesicon,originalIcon,OriginalBlue } from "blocks/dashboard/src/assets";
import axios from 'axios';
import { setStorageData } from '../../../../framework/src/Utilities';
export const configJSON = require('../../../../blocks/dashboard/src/config');

const { Sider } = Layout;

const AsideLeft = () => {
  const token: any = localStorage.getItem('token');
  let items: any = [];
const userType = localStorage.getItem("user_type")


  const history = useHistory();
  const location = useLocation();
  const [originals, setOriginals] = useState("")
  const [currentKeyCharts, setcurrentKeyCharts] = useState("")
  const [currentKeyPlaylist, setcurrentKeyPlaylist] = useState("")
  useEffect(() => {
    if (userType === "original") {
      setcurrentKeyCharts("2")
      setcurrentKeyPlaylist("4")
  
    } else {
      setcurrentKeyCharts("3")
      setcurrentKeyPlaylist("5")
  
    }
  }, [userType]);
  let currentPlaylistRedirection= userType==="creative_plus"||userType==="label"?"/ctrl/playlist":"/home/all-playlist"
  if (userType === "original") {
    
    items = [
      { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
      { key: "2", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
      { key: "3", label: "Videos", path: "/home/VideosPage", icon: Videoblue, defaultIcon: Videoicon },
      { key: "4", label: "Playlist", path: currentPlaylistRedirection, icon: Playlistblue, defaultIcon: Playlisticon },
      { key: "5", label: "Download", path: "/home/downloadPage", icon: Downloadblue, defaultIcon: DownloadIcon },
      { key: "6", label: "Creatives", path: "/home/Creatives", icon: Creativeblue, defaultIcon: Creativesicon },

    ];
  } else {
    items = [
      { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
      { key: "2", label: "Originals", path: `/originals/beats?originals_id=${originals}`, icon: originalIcon, defaultIcon: originalIcon },
      { key: "3", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
      { key: "4", label: "Videos", path: "/home/VideosPage", icon: Videoblue, defaultIcon: Videoicon },
      { key: "5", label: "Playlist", path: currentPlaylistRedirection, icon: Playlistblue, defaultIcon: Playlisticon },
      { key: "6", label: "Download", path: "/home/downloadPage", icon: Downloadblue, defaultIcon: DownloadIcon },
      { key: "7", label: "Creatives", path: "/home/Creatives", icon: Creativeblue, defaultIcon: Creativesicon },

    ];
  }
  const items1 = [
    { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
    { key: "2", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
  ];
  const pageNavigate = async (path: any) => {
    if (path == `/originals/beats?originals_id=${originals}`) {
      const id: any = localStorage.getItem("user_id")
      await localStorage.setItem("temp_id", id)
      await localStorage.removeItem("user_id")
      await localStorage.setItem("user_id", originals)
      await setStorageData('user_id', originals)
      await localStorage.setItem("creatives_screen", "true")
      const user = 'original';
      await localStorage.setItem("current_type", user);
      history.push(path);
    }
    history.push(path);
  };
  useEffect(() => {
    if (token) {
      getOriginals()

    }
  }, []);
  const returnLabel=(labelName:any,label:any)=>{
      return(
        <span className={labelName}> {label}</span>
      )

    // }
  }

  const applyOriginalsCondition = (_item:any, loggedInuserType:any) => {
    return location.pathname.split('/').includes('originals') && _item.key === "2" && loggedInuserType !== "original";
  };

  const applyChartsCondition=(_item:any)=>{
  return location.pathname.split('/').includes('Charts') && _item.key == currentKeyCharts
  }
  const returnLoginSidebar = (items: any) => {
    const loggedInuserType = localStorage.getItem("user_type")
    return items.map((_item: any,index:number) => {
      let pathName: any = _item.icon
      let labelName: any = 'seleted_text '
      let widthSpecific:any=24
      let heightSpecific:any=24
      let marginRightSpecific :any = "10px"
      if (applyOriginalsCondition(_item, loggedInuserType)) {
        pathName = OriginalBlue
        heightSpecific=21
      } else if (applyChartsCondition(_item)) {
        pathName = Chartsblue
      } else if (
        (location.pathname.split('/').includes('all-playlist') || location.pathname.split('/').includes('public-view') || location.pathname.split('/').includes('self-view') || location.pathname.split('/').includes('/ctrl/playlist') ) && _item.key == currentKeyPlaylist) {
        if (_item.key == currentKeyPlaylist) {
          pathName = Playlistblue
        } 
      } else if (location.pathname === _item.path) {
        pathName = _item.icon
      } else {
        pathName = _item.defaultIcon
        labelName = 'non_seleted_text'
        if(_item.key === "4"){
          widthSpecific=29
          heightSpecific=26
          marginRightSpecific = "5px"
        }else if(_item.key === "6"){
          heightSpecific = 20
        }
      }

      return (

        <Menu.Item
          style={{ display: "flex", alignItems: "center", marginBottom: "13px" }}
          key={_item.path}
          onClick={() => {
            pageNavigate(_item.path);
          }}>
          <img
            src={pathName}
            width={widthSpecific}
            height={heightSpecific}
            alt=""
            style={{ marginRight: marginRightSpecific,marginTop:index == 2 ? "10px":""}}
          />
          {returnLabel(labelName,_item.label)}
          {/* {userType !== 'original' && <span className={labelName}> {_item.label}</span>} */}
        </Menu.Item>
      )
    }

    )
  }

  const returnWithoutLoginSidebar = (items: any) => {
    return items1.map((_item,index) => {
      let pathName: any = ""
      let labelName: any = ""
        /*eslint no-unused-expressions: ["error", { "allowShortCircuit": true }]*/
      if(location.pathname.split('/').includes('Charts') && _item.key == '2' ){
        pathName=Chartsblue
        labelName='seleted_text'
      } else if(location.pathname === _item.path){
        pathName = _item.icon
        labelName = 'seleted_text '
      } else{
        pathName = _item.defaultIcon
        labelName = 'non_seleted_text'
      }
      return(

      <Menu.Item
        key={_item.path}
        onClick={() => {
          pageNavigate(_item.path);
        }}>
          <img
            src={pathName}
            width={24}
            height={24}
            alt=""
            style={{ marginRight: "10px",marginTop:index == 1 ? "10px":"" }}
          />
           {returnLabel(labelName,_item.label)}
        
      </Menu.Item>
    )
  })
  }

  const getOriginals = async () => {
    await axios.get(`${configJSON.baseUrl}/bx_block_audiomusic/original_profile`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setOriginals(response?.data?.data?.id)
      }).catch((err) => {
        console.log(err);
      });
  }
  return (
    <Sider
      breakpoint='md'
      collapsedWidth='0'
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
      className="mainHeight"
      style={{
        backgroundColor: 'black',
      }}
      width={250}>
      <BrandName onClick={() => history.push('/home')} />

      {/* ..................manu start................................... */}
      <div className='cm-menu custom_menu'>
        <Menu
          style={{
            backgroundColor: 'black',
            paddingLeft: '12px',
          }}
          theme='dark'
          mode='inline'
          selectedKeys={
            location.pathname.split('/').includes('originals')
              ? ['/originals/beats']
              :
              location.pathname.split('/').includes('Charts')
                ? ['/home/Charts/beats']
                : location.pathname.split('/').includes('all-playlist')
                  ? ['/home/all-playlist']
                  : [location.pathname]
          }>
          {token ?
            returnLoginSidebar(items)
            :

            returnWithoutLoginSidebar(items1)
          }
        </Menu>


      </div>
    </Sider>
  );
};

export default AsideLeft;

// Customizable Area End