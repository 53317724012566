import ChartController from "./RevenueChartController.web";
import { Bar } from 'react-chartjs-2';
import React from "react";


export default class CtrlChart extends ChartController {
    constructor(props: any) {
        super(props);

    }
   

    
   

    render() {
        return (
            //Merge Engine DefaultContainer
            <Bar ref={this.chartReference} data={this.state.datas} options={this.state.options} />


            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area End


