import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { loadStripe } from "@stripe/stripe-js";
import { openNotification } from "../../../components/src/Notification.web";
import { HISTORY } from "components/src/common";
export const configJSON = require("./config.js");

const setKey = () => {
  if (configJSON.baseUrl.includes("prod")) {
    return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`;
  } else {
    return `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`;
  }
};
const baseStripePromise: any = setKey();

const stripePromise = loadStripe(baseStripePromise);
// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  planData:any;
  electedPlanData:any;
  enablePlanData:boolean;
  stripeId:any;
  loading:boolean;
  subscriptionClicked:any;
  currentSubscription:any;
  token:any;
  userPlan:any;

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ForCreativeAndListenerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSubscriptionPlan: string = "";
  apiAcceptPlan:string="";
  getHandleUpdateCardApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
        planData:[],
        electedPlanData:"",
        enablePlanData:true,
        stripeId:"",
        loading:false,
        subscriptionClicked:"",
        currentSubscription:"",
        token:localStorage.getItem("token"),
  userPlan:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getSubcriptionPlan(this.state.token)

    // Customizable Area Start
    // Customizable Area End
  }


   getSubcriptionPlan = async (token: any) => {
    this.setState({ loading: true });
    if (token) {
     
    const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem("token"),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiSubscriptionPlan = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.subscriptionPlan}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
        HISTORY.push("/Welcome");
      }
  
  }


  
  handlePlanClick=(plan:any)=>{
    this.setState({subscriptionClicked:plan.id,
    currentSubscription:plan.subscriptionName
    })
  }

  handlePlanData=(plan:any)=>{
    this.selectPlan(plan?.attributes?.id, plan?.attributes?.stripe_plan_id.replace('\t', ''))

  }

  selectPlan = (id: any, stripeId: any) => {
    
    this.setState({stripeId:stripeId,
    electedPlanData:id,
    enablePlanData:false
    })

  }

  

  // Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

 
  urlReturn = () => {
    if (configJSON.baseUrl.includes("prod")) {
      return "https://app.nxzsound.com";
    } else if (configJSON.baseUrl.includes("uat")) {
      return "https://nxzsound10-66173-react.b66173.uat.eastus.az.svc.builder.ai";
    } else {
      return "https://nxzsound10-66173-react.b66173.stage.eastus.az.svc.builder.ai";
    }
  };

  handleSubscribe = async () => {
    const dev_url =
      "https://nxzsound10-66173-react.b66173.dev.eastus.az.svc.builder.cafe";
    const currentRedirect = configJSON.baseUrl.includes("dev")
      ? dev_url
      : this.urlReturn();
    const data = {
      success_url: `${currentRedirect}${this.state.userPlan.includes("Creative")?configJSON.handleSubscribe:configJSON.handleSubscribeListener}`,

      cancel_url: `${currentRedirect}/${this.state.userPlan}`,
      price: this.state.stripeId,
      device_type: "web",
      quantity: 1,
    };

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHandleUpdateCardApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getSubscriptionCardUpdate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  

 

  handleSubscriptionData = (responseJson: any) => {
   
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
      planData:responseJson.data,
      loading: false
      });
    } else {
      this.setState({ loading: false });
    }
  };


  handleUpdateCardApi = async (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      localStorage.setItem("session_id", responseJson.session_id.id);
      const paymentLinkUrl = responseJson.session_id.url;
        window.location.href = paymentLinkUrl;
    } else {
      openNotification(
        responseJson.errors.message
          ? responseJson.errors.message
          : responseJson.errors?.response?.data?.message,
        "Error"
      );
    }
  };

  handleAcceptPlanApi= (responseJson: any) => {
     if (responseJson !== undefined && !responseJson.errors) {
       this.handleSubscribe()
     } else {
        openNotification(responseJson.errors?.response?.data?.errors[0]?.token, "Error")

     }
   };

   handleGoBack=()=>{
    HISTORY.goBack()
   }

   acceptPlan = async (planType:any) => {
    
    this.setState({userPlan:planType})
    const data = {
      subscription_plan_id: this.state.electedPlanData

    }
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem("token"),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAcceptPlan = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.acceptPlan}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPIMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  handleRestApiCall = (apiRequestCallId: any, responseJson: any) => {
   console.log(apiRequestCallId,"apirequestcallid")
    switch (apiRequestCallId) {
      case this.apiSubscriptionPlan:
        this.handleSubscriptionData(responseJson);
        break;
        case this.getHandleUpdateCardApi:
            this.handleUpdateCardApi(responseJson);
        break;
        case this.apiAcceptPlan:
            this.handleAcceptPlanApi(responseJson);
        break;
      default:
        // Handle any other cases if needed
        break;
    }
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.handleRestApiCall(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
