// Customizable Area Start
import { Box,Typography } from "@material-ui/core";
import React from "react";
import RightSideSplitDetailsController from "./RightSideSplitDetailsController";
import "./splits.css";
import { styled } from "@material-ui/styles";
import { playButton } from "../../../../dashboard/src/assets";
import { Carousel } from "antd";

const MobileSplitboxRightsidecontainer = styled(Box)({
    width: "51%",
    flexDirection: "column" as "column",
    display: "flex",
    gap: "5%",
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        marginTop: "10%",
        gap: "20px",
    },
});

const settings = {

    autoplay: false,
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    responsive: [
        {
            breakpoint: 601,
            settings: {
                slidesToShow: 2.6,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 590,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 545,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 514,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
       
        {
            breakpoint: 462,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 437,
            settings: {
                slidesToShow: 1.8,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 404,
            settings: {
                slidesToShow: 1.8,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 399,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 308,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 330,
            settings: {
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 326,
            settings: {
                slidesToShow: 1.4,
                slidesToScroll: 1
            }
        },
       


    ]

};
export default class MobileRigthsideSplitsdetails extends RightSideSplitDetailsController {
    constructor(props: any) {
        super(props);
    }
    // Define a function to render the track items
    renderTrackItems = (items: any) => {
        return (
            <Box >
                <Carousel
                    {...settings}
                    infinite={false}
                >
                    {items.map((item: any, index: any) => (
                        <Box style={{ width: "300px", height: "300px" }}>
                            <Box
                                key={item.id || index}
                                style={{ height: "100%", cursor: "pointer" }}
                            >
                                <img
                                    src={item.attributes.art_work}
                                    alt={`Artwork for ${item.attributes.title}`}
                                    style={{ objectFit: "cover", height: "170px", width: "200px" }}
                                    onClick={() => {
                                        this.props.topPlayMusic(
                                            item,
                                            index,
                                            this.props.top40SplitsList,
                                            item.type
                                        );
                                    }}
                                />
                                <Typography style={webStyle.audioimagetext}>
                                {index + 1}. {item.attributes.title}
                                </Typography>
                                <Typography style={webStyle.audioartistext}>
                                {item.attributes.user_role}
                                </Typography>
                            </Box>
                        </Box>

                    ))}
                </Carousel>


            </Box>
           
        );
    };

    // Define a function to render the video items
    renderVideoItems = (items: any) => {
        const videosettings = {

            autoplay: false,
            dots: false,
            infinite: true,
            speed: 400,
            slidesToShow: 7,
            slidesToScroll: 1,
            swipeToSlide: true,
            draggable: true,
            responsive: [
                {
                    breakpoint: 601,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 590,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 573,
                    settings: {
                        slidesToShow: 1.4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 540,
                    settings: {
                        slidesToShow: 1.35,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 520,
                    settings: {
                        slidesToShow: 1.3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 503,
                    settings: {
                        slidesToShow: 1.2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 468,
                    settings: {
                        slidesToShow: 1.1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 434,
                    settings: {
                        slidesToShow: 1.08,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 422,
                    settings: {
                        slidesToShow: 1.03,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 330,
                    settings: {
                        slidesToShow: 1.01,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 326,
                    settings: {
                        slidesToShow: 1.01,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 308,
                    settings: {
                        slidesToShow: 1.01,
                        slidesToScroll: 1
                    }
                }
        
        
            ]
        
        };
        return (
            <>
              {this.props.top40SplitsList?.length > 0 ? (
                <Carousel {...videosettings} infinite={false}>
                  {items.map((item: any, index: any) => (
                    <Box key={index} style={{height:"193px",width:"600px"}}>
                      <Box style={webStyle.videoImagewrapper}>
                        <img
                          src={item.attributes.art_work}
                          style={{
                            height: "193px",
                            width: "350px",
                            borderRadius: "8px",
                          }}
                          onClick={() => this.redirectVideo(item)}
                        />
                        <img
                          src={playButton}
                          style={{
                            height: "49px",
                            width: "49px",
                            position: "absolute",
                            right: "45%",
                            top: "30%",
                          }}
                        />
                        <Typography style={webStyle.rightsidesingertext}>
                          {this.props.index + 1}. {item.attributes.title}
                        </Typography>
                        <Typography style={webStyle.rightsideaudioartisttext}>
                          {item.attributes.user_role}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Carousel>
              ) : (
                <Typography
                  style={{
                    paddingTop: "72px",
                  }}
                  className="no_data"
                >
                  Data Not Found.
                </Typography>
              )}
            </>
          );
    
    };

    render() {
        const { top40SplitsList, index } = this.props;
        let content;
        if (top40SplitsList?.length > 0) {
            if (index !== "Videos") {
                content = (
                    <>
                        {this.renderTrackItems(top40SplitsList)}
                    </>
                );
            } else {
                content = this.renderVideoItems(top40SplitsList);
            }
        } else {
            content = (
                <Typography
                    style={{
                        // marginTop: "10%",
                        paddingTop: "72px",
                    }}
                    className="no_data"
                >
                    Data Not Found.
                </Typography>
            );
        }
        return (
            <MobileSplitboxRightsidecontainer>
                <Box style={{justifyContent: "space-between", display: "flex" }}>
                    <Typography style={ webStyle.trackname }>
                        <span style={ webStyle.bluetext }>Top 40</span> {this.props.index}
                    </Typography>
                    <Typography style={ webStyle.splittext }>This week</Typography>
                </Box>
                <Box style={{ width: "100%" }}>
                        {content}
                </Box>

            </MobileSplitboxRightsidecontainer>
        );
    }
}

const webStyle = {
    rightsidesingertext: {
        left: "4%",
        color: "#F0F0F5",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Karla",
        fontStyle: "normal",
        lineHeight: "20px",
        maxWidth: "170px",
        textOverflow: "ellipsis",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap" as 'nowrap',
        position: "relative" as "relative",
        top: "-22%",  
    },
    rightsideaudioartisttext: {
        color: "#8f92a1",
        fontWeight: 700,
        top: "-22%",
        fontFamily: "Karla",
        fontStyle: "normal",
        lineHeight: "20px",
        fontSize: "14px",
        position: "relative" as "relative",
        left: "4%",
    },
    videoImagewrapper: {
        height: "205px",
        position: "relative" as "relative",
        background:
            "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
    },
    tracksbutton: {
        borderRadius: "20px",
        backgroundColor: "#212121",
        justifyContent: "space-between",
        padding: "6px",
        display: "flex",
    },
    leftsideAlltracksbox: {
        width: "100%",
        marginTop: "-18px",
    },
    audioimagetext: {
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Karla",
        lineHeight: "20px",
        marginTop: "8px",
        color: "#F0F0F5",
        maxWidth: "150px",
        overflow: "hidden",
        whiteSpace: "nowrap" as 'nowrap',
        textOverflow: "ellipsis",
        display: "inline-block",
        
    },
    audioartistext: {
        fontFamily: "Karla",
        lineHeight: "20px",
        color: "#8f92a1",
        fontWeight: 700,
        fontSize: "14px",
       
    },

    trackname: {
        fontWeight: 700,
        fontStretch: "normal",
        fontSize: "22px",
        fontFamily: "Inter",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
    },

    splittext: {
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "normal",
        color: "#8f92a1",
    },
    tracksimage: {
        height: "148px",
        width: "148px",
        borderRadius: "12px",
    },

    bluetext: {
        fontStretch: "normal",
        color: "#3959f9",
        fontWeight: 700,
        fontSize: "22px",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "normal",
    },
};

// Customizable Area End
