// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col } from "antd";
import './add-to-queue.css'
import AddToQueuePopupController from './AddToQueueController.web';
import { AddtoQueueBlue, PlaylistQueueBlue } from './assets.web';
import {CreateAudioPlaylistPopup} from '../CreateAudioPlaylistPopup/CreateAudioPlaylistPopup.web';


export class AddToQueuePopup extends AddToQueuePopupController {

  render() {    
    const {addSongToQueue} = this.context;
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-addtoqueue-popup"}}
     >
         <Row justify="center" style={{height: '100%'}} >
                <Col >
                  <Grid container  className='addtoqueuemainbox'>
                    <Grid item className='add-to-queue-frame'>
                       <Grid className='addtoqueueinsidebox'>
                         <Grid className='addtotext'>Add To</Grid>
                         <Grid className='queuetext'>QUEUE</Grid>
                         <Grid className='addthistext'>Add this to <span className='bluetext'>Next In Queue</span></Grid>
                         <Grid className='addthistext'>list of things to play</Grid>
                       </Grid>
                       <Grid className='add_to_queue_box' onClick={() => {addSongToQueue(this.props.trackData); this.props.handleClose()}} data-test-id="add-to-queue"> 
                         <img src = {AddtoQueueBlue} />
                         <Grid style={{marginLeft: '15px'}}>Add To Queue</Grid>
                       </Grid>
                    </Grid>
                    <Grid item className='add-to-queue-frame' style={{marginTop: '30px'}}>
                       <Grid className='addtoqueueinsidebox'>
                         <Grid className='addtotext'>Add To</Grid>
                         <Grid className='queuetext'>PLAYLIST</Grid>
                         <Grid className='bluetext'>Save This Vibe <span className='addthistext'>and</span></Grid>
                         <Grid className='addthistext'>play it later</Grid>
                       </Grid>
                       <Grid test-id = "add_to_playlist_button" className='add_to_playlist_box' onClick={() => this.setState({openPlaylist: true})} >
                         <img src = {PlaylistQueueBlue} />
                         <Grid style={{marginLeft: '15px'}}>Add To Playlist</Grid>
                       </Grid>

                       {this.state.openPlaylist &&

                       <CreateAudioPlaylistPopup id="Queue_Playlist" open={this.state.openPlaylist} handleClose={() => {this.setState({openPlaylist: false})}} queuePopupClose={this.props.handleClose} playlistType = {this.props.playlistType} trackData = {this.props.trackData}/>

                       }
                    </Grid>
                 </Grid>
              </Col>
         </Row>
     </Dialog>
    )
  }
}

export default AddToQueuePopup
// Customizable Area End