// Customizable Area Start
import React from 'react';
import { Box } from '@material-ui/core';
import './CustomDownloadCarousel.css';

const CustomDownloadCarousel = ({currentTrack}:any) => {
  let backgroundColor;

  if (!currentTrack.hasOwnProperty('attributes')) {
    backgroundColor = "black";
  } else {
    switch (currentTrack.type) {
      case 'Beat':
        backgroundColor = "#ff0000";
        break;
      case 'Song':
        backgroundColor = "#3959f9";
        break;
      default:
        backgroundColor = "#919191";
    }
  }


  return (
    <Box
      className='download_banner_main'
      style={{
        backgroundImage:
         `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url('${currentTrack.hasOwnProperty('attributes')? currentTrack.attributes.art_work:currentTrack.art_work}')`,
         backgroundPosition:"center"
      }}>
      <Box>
        <span className='banner_heading' >{currentTrack.hasOwnProperty('attributes')?currentTrack.attributes.track_name:currentTrack.track_name}</span>
        <Box style={{ display: 'flex' }}>
          <span className='banner_track'>{currentTrack.hasOwnProperty('attributes')?currentTrack.attributes.artist_name||"Unknown":currentTrack.artist_name||"Unknown"}</span>
          <span className='track_name'
          style={{
            backgroundColor: backgroundColor 
          }}
          />
          <span className='banner_track'>{currentTrack.hasOwnProperty('attributes')?currentTrack.attributes.type||"Unknown":currentTrack.type||"Unknown"}</span>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomDownloadCarousel;
// Customizable Area End