// Customizable Area Start
import { Input, Row } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import SplitsController from "./SplitsController.web";
import "./splits.css";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import { searchshape } from "../../../../dashboard/src/assets";
import { nxzloader } from "blocks/dashboard/src/assets";
import CtrlFilterPopup from "../Common/CtrlFilterPopup.web";
import MobileRigthsideSplitsdetails from "./MobileRightsideSplitsdetails.web";

const MobileDivWrapper = styled(Box)({
  display: "flex",
  minHeight: "calc(100% - 3rem)",
  marginTop: "15px",
  padding: "0px 50px 0px 27px",
  gap: "2%",
  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 27px",
    gap: "0%",
  },
});


const MobileSplitleftsidecontainer = styled(Box)({
  width: "47%",
  gap: "4%",
  display: "flex",
  flexDirection: "column" as "column",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    gap: "20px",
    marginTop: "10px",
  },
});

const MobileTotalSplits = styled(Box)({
  color: "white",
  width: "100%",
  "@media(max-width: 600px)": {
    height: "auto",
  },
});

const MobileInputboxwrapper = styled(Box)({
  borderRadius: "27px",
  background: "#212121",
  position: "relative",
  padding: "10px",
  width: "100%",
  "& .ant-input": {
    backgroundColor: "#212121",
    color: "white",
    width: "88%",
  },
  "& .ant-input-affix-wrapper": {
    border: "unset",
    background: "none",
  },
  "@media(max-width: 1024px)": {
    padding: "10px",
    width: "100%",
    "& .ant-input": {
      width: "50%",
    },
  },
});
const MobileLeftsideAllsplitboxwrapper = styled(Box)({
  scrollbarWidth: "none",
  maxHeight: "693px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});


export default class MobileSplits extends SplitsController {
  constructor(props: any) {
    super(props);
  }


  render() {
    const {
      searchText,
      splitList,
      index,
      loading,
    } = this.state;
    const { topPlayMusic, getStatusDetails } = this.props;

    return (
      <>
        {loading ? (
          <Row
            style={{
              display: "flex",
              height: "80vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <MobileDivWrapper>
            <MobileSplitleftsidecontainer>
              <MobileInputboxwrapper>
                <Input
                  test-id=" test_search_term_id_handle_search_input "
                  value={searchText}
                  onChange={(text) => this.handleSplitSearch(text)}
                 
                  prefix={
                    //@ts-ignore
                    <SearchOutlined translate className=" text_white1 " />
                  }
                  placeholder="Search  Splits"

                  className="check123"
                />
                <img src={searchshape} style={webStyle.searchimageicon} 
                 onClick={()=>{
                  this.handleOpenFilterPopup()}}
                  data-test-id="openFilterpopup"
                />
              </MobileInputboxwrapper>
              <Box style={{ width: "100%" }}>
            <Box style={webStyle.tracksbutton}>
              <Box>
                <Button
                  style={{
                    background: this.state.index == "Tracks" ? "#274af4" : "",
                  }}
                  onClick={this.changetabs.bind(this, "Tracks")}
                  className="stats_tab"
                  data-test-id="states"
                >
                  Tracks
                </Button>
              </Box>

              <Box>
                <Button
                  className="stats_tab"
                  style={{
                    background: this.state.index == "Songs" ? "#274af4" : "",
                  }}
                  data-test-id="cities"
                  onClick={this.changetabs.bind(this, "Songs")}
                 
                >
                  Songs
                </Button>
              </Box>
              <Box>
                <Button
                  style={{
                    background: this.state.index == "Videos" ? "#274af4" : "",
                  }}
                  className="stats_tab"
                  data-test-id="listeners"
                  onClick={this.changetabs.bind(this, "Videos")}
                 
                >
                  Videos
                </Button>
              </Box>
            </Box>
          </Box>
          <MobileRigthsideSplitsdetails 
             active={this.state.active}
             heading={this.state.heading}
             selectedIndex={this.state.selectedIndex}
             showdetails={this.state.showdetails}
             threebtnToogle={this.state.threebtnToogle}
             splitDetailsId={this.state.splitDetailsId}
             index={this.state.index}
             loading={this.state.loading}
             top40SplitsList={this.state.top40SplitsList}
             masterSplitsList={this.state.masterSplitsList}
             mastertop40SplitsList={this.state.mastertop40SplitsList}
             splitList={this.state.splitList}
             searchText={this.state.searchText}
             topPlayMusic={topPlayMusic }
              getStatusDetails ={getStatusDetails}
              changetabs={this.changetabs}
            />
              <MobileTotalSplits>
                <Typography style={webStyle.trackname}>
                  {" "}
                  <span style={webStyle.bluetext}>
                    {splitList?.length}
                  </span>{" "}
                  {index} Splits
                </Typography>
              </MobileTotalSplits>
              <Box style={webStyle.leftsideAlltracksbox}>
                <MobileLeftsideAllsplitboxwrapper>
                  {splitList?.length > 0 ? (
                    splitList.map((singlesplit: any, index: any) => {
                      const { typeColor, status } = getStatusDetails(
                        singlesplit,
                        "tracks"
                      );
                      return (
                        <Box style={ webStyle.mobileleftSideSingletrackbox }>
                          <Box style={ webStyle.singleArtinfo } id="yourTopDivId">
                            <img
                              src={singlesplit.attributes?.art_work}
                              id="yourTopDivId"
                              style={
                                this.state.index == "Videos"
                                  ? webStyle.mobileleftsidevideocard
                                  : webStyle.mobileleftsideudiocard
                              }
                              onClick={() => {
                                topPlayMusic(singlesplit, index, splitList, "song");
                              }}
                             
                            />

                            <Box style={webStyle.mobilesplitleftsideownerstats}>
                              <span  className="tracks_songs_text">
                                { singlesplit.attributes.title }
                              </span>
                              <span style={ webStyle.mobilesplittext }>
                                { singlesplit.attributes.user_role }
                              </span>
                            </Box>
                          </Box>

                          <Box style={ webStyle.mobilesplitleftsideownerstats }>
                            <span style={ webStyle.mobilesplittext }>

                              <span style={ webStyle.whitetext }>

                                {singlesplit.attributes.split_percentage}%
                              </span>{" "}
                              Split
                            </span>
                            <Box>
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  width:"100%",
                                  border: `1px solid ${typeColor}`,
                                  borderRadius: "20px",
                                  color: typeColor,
                                  height: "23px",
                                  
                                }}
                              >
                                {status}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop:"100px",
                        alignItems: "center",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#fff",
                        padding: "5",
                      }}
                    >
                      Data Not Found.
                    </Typography>
                  )}
                </MobileLeftsideAllsplitboxwrapper>
              </Box>
            </MobileSplitleftsidecontainer>
          </MobileDivWrapper>
        )}

  <CtrlFilterPopup
      open={this.state.openFilterPopup}
      currentTab={this.state.index}
      handleTrack={(data:any)=>this.handleSplitsList(data)}
      handleClose={this.handleCloseFilterPopup}
      
      />
      </>
    );
  }
}

const webStyle = {
  totalSplits: {
    height: "10vh",
    color: "white",
    width: "100%",
   
  },
  rightsidesingertext: {
    left: "4%",
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    position: "relative" as "relative",
    top: "-22%",
    fontFamily: "Karla",
   
  },
  rightsideaudioartisttext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
    position: "relative" as "relative",
    left: "4%",
    top: "-22%",
  },
  mobileleftsideudiocard: {
    width: "75px",
    border: "1px solid #141414",
    objectFit:"cover",
    height: "75px",
    borderRadius: "10px",
   
  },
  mobileleftsidevideocard: {
    width: "125.25px",
    borderRadius: "10px",
    border: "1px solid #141414",
    height: "75px",
    
  },
  videoImagetex: {
    position: "relative" as "relative",
    fontWeight: 700,
    fontSize: "14px",
    left: "4%",
    top: "-22%",
    color: "#F0F0F5",
  },
  videoArtisttext: {
    position: "relative" as "relative",
    fontWeight: 700,
    fontSize: "14px",
    left: "4%",
    top: "-24%",
    color: "#8f92a1",
  },
  searchimageicon: {
    height: "18px",
    right: "6%",
    zIndex:10,
    cursor:"ponter",
    width: "21px",
    position: "absolute" as "absolute",
    top: "31%",
  },
  videoImagewrapper: {
    position: "relative" as "relative",
    background:
    "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
    height: "205px",
  },
  tracksbutton: {
    borderRadius: "20px",
    justifyContent: "space-between",
    padding: "6px",
    backgroundColor: "#212121",
    display: "flex",
  },
  singleArtinfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
  },
  leftsideAlltracksbox: {
    marginTop: "-18px",
    width: "100%",
  },
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
  },
  mobileleftSideSingletrackbox: {
    height: "95px",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain" as "contain",
    padding: "4px",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
  },
  audioimagetext: {
    fontWeight: 700,
    lineHeight: "20px",
    marginTop: "8px",
    color: "#F0F0F5",
    fontSize: "14px",
    fontFamily: "Karla",
  },
  audioartistext: {
    fontWeight: 700,
    fontFamily: "Karla",
    lineHeight: "20px",
    color: "#8f92a1",
    fontSize: "14px",
  },

  trackname: {
    fontWeight: 700,
    fontStretch: "normal",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden",
    fontStyle: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontSize: "22px",
    fontFamily: "Inter",
  },

  singlebutton: {
    border: "1px solid blue",
    color: "blue",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "20px",
   
  },
  songsinlgebutton: {
    border: "1px solid red",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "20px",
    color: "red",
   
  },

  mobilesplittext: {
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  mobilesplitleftsideownerstats: {
    gap: "10px",
    flexDirection: "column" as "column",
    display: "flex",
  
  },
  tracksimage: {
    height: "148px",
    borderRadius: "12px",
    width: "148px",
    
  },

  bluetext: {
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#3959f9",
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "20px",
    letterSpacing: "normal",
    
  },
  whitetext: {
    fontStretch: "normal",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#FFF",
  },
};
// Customizable Area End
