// Customizable Area Start
import React, { useState } from 'react';
import {
  chartFilter,
  messageIcon,
  PlayIcon,
  repeatIcon
} from '../../../../blocks/dashboard/src/assets';
import { Col, Row } from 'antd';
import { Grid, Box, Menu } from '@material-ui/core';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Bar } from 'react-chartjs-2';
import { SplitsProgress } from './SplitsProgress.web';
import ChartsFilter from '../ChartsFilter.web';



const useStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: '0!important' as any
    }
  }),
);





// require('./RoundedBars.jsx');


export const ChartsSplits = ({

  trackdata,
  userSplit,
  userTrackLangth,
  playCount,
  repostCount,
  handlerSelectedTime,
  handlerSelectedFilter,
  chartType,
  chartTime,
  graphCategory,
  graphdata,
}: any) => {


  const classes = useStyles();


  // const [graphdata, setGraphData] = React.useState<any>();
  // const [graphCategory, setGraphCategory] = useState<any>();
  const [openFilter, setOpenFilter] = useState(false);





  const month_name = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let txt: any = "";

  switch (chartTime) {
    case "Week":
      let curr = new Date();
      let day = curr.getDay();
      let firstday = new Date(curr.getTime() - 60 * 60 * 24 * day * 1000);
      let lastday = new Date(firstday.getTime() + 60 * 60 * 24 * 6 * 1000);

      if (firstday.getMonth() == lastday.getMonth()) {
        txt = `${month_name[firstday.getMonth()]} ${firstday.getDate()} - ${lastday.getDate()} `
      } else {
        txt = `${month_name[firstday.getMonth()]} ${firstday.getDate()} - ${month_name[lastday.getMonth()]} ${lastday.getDate()} `
      }
      break;

    case "Month":
      let date = new Date();
      txt = `${month_name[new Date().getMonth()]} 1 - ${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()} `
      break;

    case "Year":
      txt = `Year - ${new Date().getFullYear()} `
      break;

    default:
      break;
  }






  const datas: any = {
    labels: graphCategory,
    datasets: [
      {
        // data: [5, 4, 3, 6, 8, 7, 6, 8],
        data: graphdata,
        //   fill: false,
        backgroundColor: '#ff0000',
        // backgroundColor: "#3959f9",
        // borderColor: "#3959f9",
        barPercentage: 0.9,
        categoryPercentage: 1,
        borderWidth: 1,
        borderSkipped: false,
        borderRadius: 15,
        hoverBackgroundColor: '#3959f9',
        // margin: "10px",
        // height: "150px"
      },
    ],
  };
  const options = {
    cornerRadius: 12,
    elements: {
      point: {
        radius: 25,
        hoverRadius: 35,
        pointStyle: 'rectRounded',
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            // color: "#3959f9",
            color: '#ff0000',
            display: graphdata?.every((current: any) => current === 0) ? true : false,
            drawOnChartArea: false,
            drawTicks: false,
            // cornerRadius: 12,
          },
          radius: 25,
          stacked: true,
          ticks: {
            padding: 10,
            display: false,
          },
          scaleLabel: {
            color: '#ff0000',
            display: true
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
          // cornerRadius: 12,
          radius: 25,
          ticks: {
            stepSize: 2,
            display: false,
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
      boxWidth: 9,
    },
    tooltips: {
      callbacks: {
        //   beforeTitle: function (context: any) 
        //     return 'before title';
        //   },
        title: function (context: any) {
          return '';
        },
        label: (context: any) => {
          return context.value;
        },
        //   afterTitle: function (context: any) {
        //     return 'after title';
        //   },
      },
      xAlign: 'right',
      displayColors: false,
      intersect: false,
    },
    maintainAspectRatio: false,
    responsive: true,
  };


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };




  const renderFilerItem = (name: string, key: any, onClick: any) => {
    return (
      <>
        <button className='filter_time_btn' style={{ backgroundColor: key === chartTime ? "#3959f9" : "#191a1d" }} onClick={onClick}>{name}</button>
      </>
    )
  }




  return (
    <>
      <div>

        <div className='chart_container'>
          <Row
            style={{
              /* margin: 10px; */
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '25px 0px',
            }}>
            <Col className='display' style={{ padding: '0px 8px' }}>
              <img
                src={PlayIcon}
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
              <h1 className='display_chart_font'>{playCount}</h1>
            </Col>
            <Col className='display' style={{ padding: '0px 8px' }}>
              <img src={repeatIcon} style={{ height: '24px', width: '24px' }} />
              <h1 className='display_chart_font'>{repostCount}</h1>
            </Col>
            <Col className='display' style={{ padding: '0px 8px' }}>
              <img
                src={messageIcon}
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
              <h1 className='display_chart_font'>{trackdata?.attributes?.comments_count}</h1>
            </Col>
          </Row>
          <div className='chart_body'>
            <div className='chart_bar'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <span className='display_chart_font'>
                  {txt}
                </span>
                {/* <img src={chartFilter} alt="" width="16px" height="14px" /> */}
                {/* <Box style={{ position: 'relative' }}>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                // getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  <Box
                    // className="ant-dropdown-link filter_design"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => e.preventDefault()}>
                    <img src={chartFilter} alt='' width='16px' height='14px' />
                  </Box>
                </Dropdown>
              </Box> */}
                {/* <Box style={{ position: "relative" }}> */}

                <Box
                  // className="ant-dropdown-link filter_design"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpenFilter(true)}
                >
                  <img src={chartFilter} alt='' width='16px' height='14px' />
                </Box>
                {
                  openFilter && (
                    <ChartsFilter
                      open={openFilter}
                      onClose={() => setOpenFilter(false)}
                      chartType={chartType} chartTime={chartTime}
                      handlerSelectedTime={handlerSelectedTime}
                      handlerSelectedFilter={handlerSelectedFilter}
                    />
                  )
                }

                {/* </Box> */}

              </div>
              <Grid style={{ margin: '10px 0px' }} className='chart-Bar-line'>
                {/* <img src={barGraph} alt="" width="163px" height="117px" /> */}
                {/* <img src={barGraph} alt="" width="163px" height="117px" /> */}
                <Bar data={datas} options={options} />
              </Grid>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='this_year_graph_singleTrack'>
                <span>This {chartTime}</span>
              </div>
            </div>
            <Grid item xs={12} style={{ height: '267px', margin: '0px 15px' }}>
              {
                userTrackLangth > 0 && userSplit.length ? (
                  <SplitsProgress userSplit={userSplit} />
                )
                  : (
                    <p className='splits-not-available'>No Splits</p>
                  )
              }
            </Grid>
          </div>
        </div>
      </div>

    </>
  );
};
// Customizable Area End
