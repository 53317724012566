// Customizable Area Start
import React from "react";
import CardItem from "../Common/CardItem.web";
import VideosList from "../Common/VideosList.web";
import { Col, Row,Carousel } from 'antd';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.web.css";
import TopSongs from "../Common/TopSongs.web";
import TopVideo from "../Common/TopVideo.web";
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import { Grid, Box, IconButton, AppBar, Tabs, Tab } from "@material-ui/core";
//@ts-ignore
import { settings, videosettings } from "components/src/CustomChart/PopUps/CaraouselSetting.js";
import { PrevButtonOrginal, NextButtonOrginal, errorMessage } from "components/src/CustomChart/PopUps/CarouselButtonHandler";
import '../Common/Activity-new/Activity.web.css'
import ActivitySelfView from "../Common/Activity-new/ActivitySelfView.web";
import ActivityPublicView from "../Common/Activity-new/ActivityPublicView.web";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import ButtonGroupWeb from "components/src/CustomChart/PopUps/ButtonGroup.web";
import TopVideoPlaylist from "../Common/TopVideoPlaylist.web";
import millify from "millify";
import ComposeProfileController from "../Common/ComposeProfileController";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import CustomAudioPlayer from "../../../AudioMusic/src/CustomAudioPlayer.web";
import { nxzloader, rightAngle, leftAngle, plus, minus } from "blocks/dashboard/src/assets";
export const configJSON = require("../../../../framework/src/config");

interface TabPanelProps { children?: React.ReactNode; index?: any; value: any; beats?: any; isPlaylist?: any }

function getClassName(isPlaylist:any, beats:any) {
  if (isPlaylist && beats > 0) {
    return 'mostly-customized-scrollbar tabpanel-scroll home_audio';
  } else if (beats === 0) {
    return 'mostly-customized-scrollbar tabpanel-scroll-noData home_withoutData';
  } else {
    return 'mostly-customized-scrollbar tabpanel-scroll home_withoutaudio';
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, beats, isPlaylist, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={getClassName(isPlaylist, beats)}
    >
      {value === index && (
        <Box p={3} style={{ height: "100%", overflowX: "clip", position: "relative" , paddingBottom:"24px"}}>
          {children}
        </Box>
      )}
    </div>
  );
}
const token: any = localStorage.getItem("token")
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}


const StyledTabs = withStyles({
  indicator: {
    display: 'none'

  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


interface StyledTabProps {
  label: string;
}


const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#fff',
      borderRadius: "35px",
      backgroundColor: "#212121",
      padding: "3px 5px ",
      margin: "0px 2px ",
      '&:focus': {
        backgroundColor: 'red',
      },
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);


export default class ComposeProfile extends ComposeProfileController {
  slider: React.RefObject<unknown>;
  constructor(props: any) {
    super(props);
    this.slider = React.createRef();
  }


  renderArrows = () => {
   
    return (
      <div className="slider-arrow">
        <IconButton
          className="arrow-btn prev"
          style={{ position: "absolute", top: this.props.value === 4 || 6 ? "30%" : "26%", left: "0%" }}
          //@ts-ignore
          onClick={() => this.slider.slickPrev()}
        >
          <img src={leftAngle} height="24px" width="24px" />
        </IconButton>
        <IconButton
          className="arrow-btn nextarrow"
          style={{ position: "absolute", top: this.props.value === 4 || 6 ? "30%" : "26%", right: "0%" }}
          //@ts-ignore
          onClick={() => this.slider.slickNext()}
        >
          <img src={rightAngle} height="24px" width="24px" />
        </IconButton>
      </div>
    );
  };


  handleCustomAudioVideo = () => {


    if (this.props.playPlaylist) {
      return (
        <div style={{ position: "fixed", left: 0, bottom: 0, width: "100%" }} >
          <CustomAudioPlayer playlistData={this.props.playlistData} playList={this.props.selectedPlayList} setPlaylistData={(list: any) => this.setState({ playlistData: list })} checkType={this.props.checkType} handleAudioVideo={this.props.handleAudioVideo} setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })} />
        </div>
      )
    }
  }
  renderTopSongs = () => {
    if (this.props.getTopPlaylists?.length > 0) {
      return (
        <TopSongs songsList={this.props.getTopPlaylists} beatList={this.props.getTopPlaylists?.length}
          handlePlayList={this.props.handleRedirectPlaylist} playlistData={this.props.playlistData} setHandlePlay={(value: any) => this.setState({ handleplay: value })} handleplay={this.props.handleplay} handleAudioVideo={this.props.handleAudioVideo} setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })}
          handlePlayLists={this.props.handleCardPlaylist}
          trackName="Playlists" 
          handleAdActivityClick={this.handleAdActivityClick}
          />
      )
    }
    else {
      return null
    }
  }


  renderGetTop100 = (isPlaylist: any) => {
const heightValue = this.props.beatList?.length > 0 ? "auto" : "100%";

    return (
      this.props.tabPanelList.map((item: any, index: any) => {
        return (
          <TabPanel index={index} value={this.props.value}
            beats={this.props.beatList?.length}
            isPlaylist={isPlaylist}
          >
            {this.props.getCreativeTabsLoader ?
              <Row align="middle" justify="center"
                style={{
                  position: "absolute",
                  left: "45%",
                  top: "29%",
                  //  @ts-ignore
                  zIndex: "9999"
                }}
              >
                <Col>
                  {" "}
                  <img src={nxzloader} style={{ width: "20%" }} />
                </Col>
              </Row>
              :
              <>
                <Row style={{  height: heightValue}}>
                  <Col span={24}> <Row className="tab-content  width_100" style={{ marginTop: "-11px" }}>{item.heading}</Row></Col>
                  <Col span={24} style={{ height: "100%" }}>
                    {this.props.beatList?.length > 0 ? (
                      <>
                        <div className="render-arrow-div">
                          <Carousel
                            ref={this.myRef}
                            {...settings}
                            infinite={false}
                          >
                            {this.props.beatList.length !== 0 && [... this.props.beatList].sort((a: any, b: any) => b.created_at - a.created_at)?.map((item: any, index: any) => {
                              return (
                                <div className="playlist-div video-card-center" >
                                  <CardItem key={`card-2-${index}`} image={item.attributes?.art_work} name={item.attributes.title} artist={item.attributes.artist_name} play={item.attributes.play_count} refresh={item.attributes.repost_count} item={item} index={index} mainList={this.props.beatList}
                                  handleAdActivityClick={this.handleAdActivityClick}
                                  />
                                </div>
                              );
                            })}
                          </Carousel>
                          {this.props.beatList?.length > this.props.window_width &&
                            <>
                              <PrevButtonOrginal top="40%"
                                left="-5px"
                                buttonRef={this.myRef}></PrevButtonOrginal>
                              <NextButtonOrginal top="40%"
                                right="-15px"
                                buttonRef={this.myRef}> </NextButtonOrginal>
                            </>
                          }
                        </div></>
                    ) :
                      errorMessage(item.value)
                    }
                  </Col>
                </Row>
                <Row>
                  {this.props.getTop100?.length > 0 && (
                    <TopSongs songsList={this.props.getTop100} playlistData={this.props.playlistData} beatList={this.props.getTop100?.length} handlePlayList={this.props.handleTrackPlay} playPlaylist={this.props.playPlaylist} trackName={item.value} setHandlePlay={(value: any) => this.setState({ handleplay: value })} handleplay={this.props.handleplay} handleAudioVideo={this.props.handleAudioVideo} setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })}
                      handlePlayLists={this.props.handleCardPlaylist}
                      handleAdActivityClick={this.handleAdActivityClick}
                    />
                  )
                  }
                </Row>
              </>
            }
          </TabPanel>


        )
      })
    )
  }


  renderPrevArrow = () => {
    return (
      this.props.beatList?.length > this.props.window_width &&
      <>
        <PrevButtonOrginal top="40%" left="-5px" buttonRef={this.myRef}></PrevButtonOrginal>
        <NextButtonOrginal top="40%" right="-15px" buttonRef={this.myRef}> </NextButtonOrginal>
      </>
    )
  }


  renderBeatList = () => {
    return (
      this.props.beatList?.length > 0 ? (
        <>


          <div className="render-arrow-div " >
            <Carousel ref={this.myRef} {...videosettings} infinite={false} >
              {this.props.beatList?.map((video: any, index: any) => {
                return (
                  <div className="playlist-div video-card-center" >
                    <VideosList
                      image={video.attributes?.art_work}
                      arrow={video.attributes.arrow}
                      name={video.attributes.title}
                      artist={video.attributes.artist_name}
                      play={video.attributes.play_count}
                      refresh={video.attributes.repost_count}
                      playCount={video?.attributes?.play_count}
                      trackFile={video?.attributes?.video_file}
                      repost_count={video?.attributes?.repost_count}
                      cardId={index} selected={this.props.selected}
                      setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideos: value })}
                      handleAudioVideo={this.props.handleAudioVideo}
                      setSelected={(key: any) => { this.setState({ selected: key }) }}
                      currentList={video}
                      id={video.id} type="Video"
                      videoList={this.props.beatList}
                      lockLevel={video.attributes.level}
                      lockContentUserId = {video.attributes.account_id}
                      unlockedTrack = {video.attributes.unlocked_track_by_current_user}
                    />
                  </div>
                );
              })}
            </Carousel>
            {this.renderPrevArrow()}
          </div></>
      )
        :
        errorMessage('Videos')
    )
  }


  renderTop100 = () => {
    if (this.props.top_playlistVideo?.length > 0) {
      return (
        <TopVideoPlaylist songsList={this.props.top_playlistVideo} beatList={this.props.top_playlistVideo?.length} handlePlayList={this.props.handleCardPlaylist} playPlaylist={this.props.playPlaylist} trackName="Playlists" />
      )
    } else{
      return null
    }

  }


  renderVideos = () => {
    if (this.props.getCreativeTabsLoader) {
      return (
        <Row align="middle" justify="center"
          style={{
            position: "absolute",
            left: "50%",
            top: "29%",
            //  @ts-ignore
            zIndex: "9999"
          }}
        >
          <Col>
            {" "}
            <img src={nxzloader} style={{ width: "20%" }} />
          </Col>
        </Row>
      )
    } else {
      return (
      this.props.playlistVideo && this.props.playlistVideo.length > 0?(
        <>
        <div className="render-arrow-div " style={{marginTop:"4px"}} >
            <Carousel ref={this.myRef} {...videosettings} infinite={false} >
              {this.props.playlistVideo && this.props.playlistVideo.map((video: any, index: any) => {
                return (
                  <div className="playlist-div video-card-center" >
                    <VideosList
                      id={video.id}
                      arrow={video.attributes.arrow}
                      image={video.attributes.cover_image} name={video.attributes.name} artist={video.attributes.artist_name} play={video.attributes.play_count} refresh={video.attributes.repost_count} playCount={video?.attributes?.play_count} trackFile={video?.attributes?.video_file} repost_count={video?.attributes?.repost_count} cardId={index} selected={this.props.selected} setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideos: value })} handleAudioVideo={this.props.handleAudioVideo}
                      setSelected={(key: any) => { this.setState({ selected: key }) }} type="playlist"
                      videoList={this.props.playlistVideo}
                    /> </div>)
              })}
            </Carousel>
            {this.renderPrevArrow()}
          </div>
          </>
        )
     :
          errorMessage('Playlists Video')
        )
    }
  }

  renderPlayList = () => {
    const heightValue = this.props.playlistVideo?.length > 0 ? "auto" : "100%";

    if (this.props.musicButton) {
      return (
        this.props.getCreativeTabsLoader ?
          <Row align="middle" justify="center"
            style={{
              position: "absolute",
              left: "50%",
              top: "29%",
              //  @ts-ignore
              zIndex: "9999"
            }}
          >
            <Col>
              {" "}
              <img src={nxzloader} style={{ width: "20%" }} />
            </Col>
          </Row>
          :
          <Col span={24} style={{ height: heightValue}}>

            <Row>
              <Grid container item xs={12} className="tab-content" style={{ width: "180px" }}> New Playlists</Grid>
            </Row>
            <Grid item xs={12} >
              {this.renderVideos()}
            </Grid>
            <Grid item xs={12} >
              {this.renderTop100()}
            </Grid >
          </Col>


      )
    }
    else {
      return (
        <>
          {this.props.getCreativeTabsLoader ?
            <Row align="middle" justify="center"
              style={{
                position: "absolute",
                left: "50%",
                top: "29%",
                //  @ts-ignore
                zIndex: "9999"
              }}
            >
              <Col>
                {" "}
                <img src={nxzloader} style={{ width: "20%" }} />
              </Col>
            </Row>
            :
            <>
              <Col span={24} style={{ height: this.props.beatList?.length > 0 ? "auto" : "100%" 
            }}>
                <Row>
                  <Grid container item xs={12} className="tab-content" style={{ width: "180px" }}> New Playlists</Grid>


                </Row>
                {this.props.beatList?.length > 0 ? (
                  <>
                    <div className='orginal-content' style={{ position: "relative" }}>
                      <Carousel ref={this.myRef} {...settings} infinite={false} >
                        {this.props.beatList.length !== 0 && [...this.props.beatList].sort((a: any, b: any) => b.attributes.play_count - a.attributes.play_count)?.map((item: any, index: any) => {
                          return (
                            <div key={item?.attributes?.id} >
                              <CardItem key={`card-1-${index}`} image={item.attributes.cover_image} name={item.attributes.name} artist={item.attributes.artist_name} play={item.attributes.play_count} refresh={item.attributes.repost_count} item={item} index={index} mainList={this.props.beatList} handleAdActivityClick={this.handleAdActivityClick}/>
                            </div>
                          );
                        })}
                      </Carousel>
                      {this.renderPrevArrow()}
                    </div>
                  </>
                )
                  : errorMessage('Playlists Audio')
                }
              </Col>
              <Row style={{ width: "100%" }}>
                {this.renderTopSongs()}
              </Row>
            </>
          }
        </>
      )


    }
  }


  returnTopVideo = () => {
    if (this.props.getTop100?.length > 0 && !this.props.getCreativeTabsLoader) {
      return (
        <TopVideo songsList={this.props.getTop100} beatList={this.props.getTop100?.length} playPlaylist={this.props.playPlaylist} trackName="Videos" />
      )
    }


  }


  checkSeeAll = () => {


    if (token) {
      return (
        <>
          {this.props.seeAll && (
            <Grid item xs={6} style={{ fontSize: "16px", fontWeight: "bold", color: "#8f92a1", justifyContent: "start", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => this.setState({ seeAll: false })} > Back</Grid>
          )}
        </>
      )




    }
  }
  showButtonGroup = () => {
    if (this.props.value === 6) {
      return (
        <Grid item md={2} xs={6} lg={2} className="audio-div">
          <ButtonGroupWeb
            musicButton={this.props.musicButton}
            setMusicButtonTrue={() => {
              this.props.setMusicBtn(false)
            }}
            setMusicButton={() => {
               this.props.setMusicBtn(true)
            }} />
        </Grid>
      )
    }
  }
  getCounts = () => {
    
    return (
      <Grid container xs={12}
        className="profile-count-parent" spacing={2} >
        {this.props.profileCountData.map((item: any, index: any) => {
     
          let getclassName
          if(index==2){
            getclassName="profile-count-value-60 profile-count-value"
          }else if (index==3){
            getclassName="profile-count-value-65 profile-count-value"
          }else{
            getclassName="profile-count-value-50 profile-count-value"
          }
    const getPctTxt = (data: any): string => {
        if (data) {
            if (data.startsWith("-")) {
                return data.replace("-", "")
            }
            return data
        } else {
            return ""
        }
      }
          return (
            <Grid item  xs={4} sm={3} className="profile-count-grid">
              <Box className={getclassName}>
                {item.title}
              </Box>
              <Box className="profile-count-values">
                {item.count ? millify(item.count) : "0"}
              </Box>
              <Box className="profile-count-percentage">
                {item.percentage.charAt(0) !== "0" &&
                  <img src={item.percentage.charAt(0) === "-" ? minus : plus}
                    className={item.percentage.charAt(0) === "-" ? "mr4 home_counting_minus" : "mr4 home_counting_plus"}
                  />}
               {getPctTxt(item.percentage)}
              </Box>
            </Grid>
          )
        })}
      </Grid>


    )


  }

  getAppbarClassName(playPlaylist: any) {
    if (playPlaylist) {
      return "appbar-height appbar-home";
    } else {
      return "appbar-height-playlist appbar-home";
    }
  }

 getCurrentUser(){
  return localStorage.getItem('loggedIn_userId') ? localStorage.getItem('loggedIn_userId') : localStorage.getItem('user_id')
 
}
  render() {
    const { gridSizes, creativeById, beatList, playPlaylist, openAlertPopUp, dialogText, OpenUpgradModal, tabName, get_All_Activity_data, get_notification }: any = this.props;
    const { xsSize, smSize, xlSize, xxlSize } = gridSizes
    const currentUser = this.getCurrentUser()
    const { isPlaylist } = this.context;
    const ActivityViewReturn = () => {
      
      if (currentUser == creativeById.id) {
        return <ActivitySelfView get_All_Activity_data={get_All_Activity_data}
          creativeById={creativeById} get_notification={get_notification} key={121}
          handleReadNotification={this.props.handleReadNotification}
          handleNavigation={this.handleNavigation}
        />
      } else {
        return <ActivityPublicView get_All_Activity_data={get_All_Activity_data} creativeById={creativeById} key={212} handleNavigation={(element:any)=>this.handleNavigation(element)}/>


      }

    }

 

    let beatsCount;
    switch (this.props.musicButton) {
      case true:
        beatsCount = this.props.playlistVideo.length;
        break;
        case false:
        beatsCount = beatList?.length;
        break;
      default:
        break;
    }

const heightValue = this.props.beatList?.length > 0 ? "auto" : "100%";
return (
      <div className={this.getAppbarClassName(playPlaylist)}>
        <AppBar position="static" className={isPlaylist ? "appbar-div_creative_audio" : "appbar-div_creative"} style={{ backgroundColor: "transparent", zIndex: 99 }} >
          <Grid container xs={12} style={{
            display: 'flex'
          }} >
            {this.getCounts()}

          </Grid>
          <Grid container xs={12}  className="profile-tab-Container" >
            <Grid item xs={12} sm={xsSize} md={this.props.value == 6 ? 8 : 10} lg={this.props.value == 6 ? 9 : 10} xl={this.props.value == 6 ? 9 : 10} >
              <StyledTabs
                value={this.props.value} onChange={this.props.checkCurrentTab} aria-label="nxz-tabs"
                // @ts-ignore
                variant="scrollable"
              >
                {tabName.map((item: any) => {
                  return (


                    <StyledTab label={item.tabName}
                      //@ts-ignore
                      className={this.props.value === item.value ? "active-tab" : "non-active-tab"}
                      {...a11yProps(item.value)} />
                  )
                })}


              </StyledTabs>
            </Grid>
            <Grid style={{ backgroundColor: "black" }} item xs={12} sm={smSize} md={xlSize} lg={xxlSize} xl={xxlSize} className={`${this.props.value === 6 ? "playlist-filter" : "filter-div"} filter_align_right`} >
              <Grid style={{ display: "flex", padding: "3px 5px" }} alignItems="flex-end">
                <div >
                  {this.showButtonGroup()}
                </div>
                <FilterDropDown filterValue={this.props.filterValue}
                  handleFilter={(data: any) => this.props.handleFilter(data)} listener={false}
                />
              </Grid>


            </Grid>
          </Grid>
        </AppBar>
        {this.renderGetTop100(isPlaylist)}

        <TabPanel index={4} value={this.props.value} beats={beatList?.length} isPlaylist={isPlaylist}>



          {this.props.getCreativeTabsLoader ?
            <Row align="middle" justify="center"
              style={{
                position: "absolute",
                left: "50%",
                top: "29%",
                //  @ts-ignore
                zIndex: "9999"
              }}
            >
              <Col>
                {" "}
                <img src={nxzloader} style={{ width: "20%" }} />
              </Col>
            </Row>
            :
            <Row style={{ height: heightValue }}>
              <Col span={24}> <Row className="tab-content  width_100" style={{ marginTop: "-11px", marginBottom: "2%" }}>New Videos</Row></Col>
              <Col span={24} style={{ height: "100%" }}>
                {this.renderBeatList()}
              </Col>
            </Row>
          }
          <Row>
            {this.returnTopVideo()}
          </Row>
        </TabPanel>
        <TabPanel index={5} value={this.props.value}
          beats={get_All_Activity_data?.length}
          isPlaylist={isPlaylist}
        >
          {this.props.getCreativeTabsLoader ?
            <Row align="middle" justify="center"
              style={{
                position: "absolute",
                left: "50%",
                top: "29%",
                //  @ts-ignore
                zIndex: "9999"
              }}
            >
              <Col>
                {" "}
                <img src={nxzloader} style={{ width: "20%" }} />
              </Col>
            </Row>
            :
            ActivityViewReturn()
          }


        </TabPanel>
        <div>
          <TabPanel index={111116} value={this.props.value} beats={beatList?.length} isPlaylist={isPlaylist}>
            {this.checkSeeAll()}
          </TabPanel>
        </div>
        <TabPanel value={this.props.value} index={6}  beats={beatsCount}  isPlaylist={isPlaylist}>

          {this.renderPlayList()}
        </TabPanel>
        {this.handleCustomAudioVideo()}
        <LoginAlert open={openAlertPopUp} handleClose={this.props.handleClose} dialogText={dialogText} handleCloseOpenUpgradModal={this.props.handleCloseOpenUpgradModal} OpenUpgradModal={OpenUpgradModal} handleUpgradeModal={this.props.handleUpgradeModal} />
      </div>
    );
  }
}
ComposeProfile.contextType = AudioContextApi;
// Customizable Area End