// Customizable Area Start
import React from "react";
import MessageCardController from "./MessageCardController";

export type MessageTypes = "profile" | "track" | "video" | "txt";
export default class MessageCard extends MessageCardController {
  render() {
    return (
      <div ref={this.messagesEndRef}>
        {this.returnMessageFormat()}
      </div>
    );
  }
}
// Customizable Area End
