import React from 'react'
import { Tabs, Tab } from "@material-ui/core";

const MainSearchTab = ({ active, callback }: any) => {
    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }
    return (
        <>
           <Tabs id="custom-tab"
                  value={active}
                  className="tab-body nxz-tabs "
                  variant="scrollable"

                  onChange={callback}
                  //TabIndicatorProps={{ style: { backgroundColor: 'red', color: "red" } }}
                  TabIndicatorProps={{ style: { backgroundColor: 'red', color: "red", bottom: "3px" } }}
                //TabIndicatorProps={{ style: { background: "#hex-color" } }}
                //</div>={{ style: { backgroundColor: 'red', color: "red" } }}
                >
                  <Tab
                    // className="tab-pane" 
                    label="Beats" value={"0"}
                    className={active === "0" ? "active-chart-tab" : "non-active-chart-tab"}


                    {...a11yProps(0)}


                  />

                  <Tab label="Hooks" value={"1"}
                    {...a11yProps(1)}
                    className={active === "1" ? "active-chart-tab" : "non-active-chart-tab"}



                  />

                  <Tab label="Verses" value={"2"}
                    {...a11yProps(2)}
                    className={active === "2" ? "active-chart-tab" : "non-active-chart-tab"}



                  />

                  <Tab label="Songs" value={"3"}
                    {...a11yProps(3)}
                    className={active === "3" ? "active-chart-tab" : "non-active-chart-tab"}



                  />

                  <Tab label="Videos" value={"4"}
                    className={active === "4" ? "active-chart-tab" : "non-active-chart-tab"}

                    {...a11yProps(4)}


                  />

                  <Tab label="Playlists" value={"5"}
                    className={active === "5" ? "active-chart-tab" : "non-active-chart-tab"}

                    {...a11yProps(5)}


                  />

                  <Tab label="Users" value={"6"}
                    className={active === "6" ? "active-chart-tab" : "non-active-chart-tab"}

                    {...a11yProps(6)}


                  />
                </Tabs>
        </>
    )
}

export default MainSearchTab