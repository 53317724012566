// Customizable Area Start
import { Tooltip } from "@material-ui/core";
import React from "react";
import CustomChartBarController from "./CustomChartBarController";

export default class CustomBar extends CustomChartBarController {
  render() {
    const {
      value,
      containerStyle,
      barStyle,
      isInvert
    } = this.props;
    return (
      <div
        style={{
          margin: "0 2px",
          height: `${value}%`,
          flexGrow: 1,
          justifyContent: "flex-start",
          display: "flex",
          ...containerStyle,
        }}
      >
        {value !== 0 && (
          <Tooltip title={value - 10}>
            <div
              className="custom-bar"
              style={{
                backgroundColor:
               isInvert ? "#3c0606" : "red",
                flexGrow: 1,
                border: "none",
                outline: "none",
                padding: 0,
                cursor: "pointer",
                ...barStyle,
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  }
}
// Customizable Area End
