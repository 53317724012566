// Customizable Area Start


import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config.js");
import { Message } from "../../../../../framework/src/Message";


export interface Props {
  navigation: any;
  id: string;
}

interface WalletBalance{
  wallet_balance:string;
  error:string
  message:string
}

interface S {
 musicButton:any
 enableButton:boolean
 walletBalance:string
}

interface SS {
  id: any;
}

export default class DashboardWalletcontroller extends BlockComponent<
  Props,
  S,
  SS
> {

  
  subscriptionPlan = localStorage.getItem("subscription_plans")

 getWalletBalance:any;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
    musicButton:1,
    enableButton:false,
    walletBalance:"0.0"
    };
  

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async componentDidMount() {
    super.componentDidMount();
    this.getWalletBalanceList()
  }
  setMusicButtonTrue=()=>{
    this.setState({musicButton:1})
    
  }
  setVideoButtonTrue=()=>{
    this.setState({musicButton:2})
   
    
  }
  setUserButtontrue=()=>{
    this.setState({musicButton:3})
   
    
  }

  getWalletBalanceList = async () => {
    const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWalletBalance = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/dashboards/wallet_balance`
    );


    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

}

handleWalletBalanceCount=(responseJson:WalletBalance)=>{
  if(responseJson!==undefined && !responseJson.message && !responseJson.error){
this.setState({walletBalance:responseJson.wallet_balance})
}
}

async receive(from: string, message: Message) {
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.getWalletBalance ){

      this.handleWalletBalanceCount(responseJson)
    }

   
  }
}


}
// Customizable Area End
