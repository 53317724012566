// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config.js");
import { revenue_arrow } from "../../assets";
export interface Props {
    navigation: any;
    id: string;
}

interface S {
    yearStats: any
    revenueStats: any

}

interface SS {
    id: any;
}

export default class DashboardRevenueController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiGetStatesData: string = "";
    apiGetCitiesData: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            yearStats: [{ month: "January", img: revenue_arrow, amount: "$2789.02" }, { month: "February", img: revenue_arrow, amount: "$10,499" }, { month: "March", img: revenue_arrow, amount: "$2,137.15" }, { month: "April", img: revenue_arrow, amount: "$1,234.15" }, { month: "May", img: revenue_arrow, amount: "$1504.50" }, { month: "June", img: revenue_arrow, amount: "$4321.50" }, { month: "July", img: revenue_arrow, amount: "$1090.40" }
                , { month: "August", img: revenue_arrow, amount: "$31,390.50" }, { month: "September", img: revenue_arrow, amount: "$57,150.50" }, { month: "Octrober", img: revenue_arrow, amount: "$67,089.50" }, { month: "November", img: revenue_arrow, amount: "$71,089.50" }, { month: "December", img: revenue_arrow, amount: "$1504.50" }
            ],
            revenueStats: [{ type: "Air", amount: "$ 8,344,653.78", revenuepercent: "9.14%" }, { type: "Splits", amount: "$ 790,450.21", revenuepercent: "7.92%" }, { type: "Ads", amount: "$ 342,653.78", revenuepercent: "9.14%" }
                , { type: "Unlocks", amount: "$ 299,450.21", revenuepercent: "7.92%" }, { type: "Deals", amount: "$ 549,450.21", revenuepercent: "7.92%", }
            ]

        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

    }







}
// Customizable Area End
