// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/common";
export const configJSON = require("./config.js");
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  errorMsg: string;
  loading: boolean;
  banner: any;
  creativeList: any;
  apiToken: any;
  userType: any;
  user_id: any;
  bannerData: any;
  bannerOption: any;
  bannerTrack: any;
  bannerId: any;
}
interface SS {
  id: any;
}

export default class MainContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getCreativesList: any;
  getBannersList: any;
  getBannersData: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      bannerData: [],
      errorMsg: "",
      token: "",
      loading: false,
      creativeList: [],
      banner: [],
      apiToken: localStorage.getItem("token"),
      userType: localStorage.getItem("user_type"),
      user_id: localStorage.getItem("loggedIn_userId"),
      bannerOption: "",
      bannerId: 0,
      bannerTrack: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    localStorage.removeItem("selectedTab");
    this.getBannerList();

    if (this.state.apiToken) {
      this.getCreativeLists(this.state.apiToken);
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getBannerList = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBannersList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.endPointGetBannerLists}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBannerPreview = async (options: any, id: any, track: any) => {
    this.setState({
      loading: true,
      bannerOption: options,
      bannerId: id,
      bannerTrack: track,
    });
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBannersData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.endBannerList}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCreativeLists = async (token: any) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCreativesList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.endCreativesList}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCreativeList=(responseJson:any)=>{
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ creativeList: responseJson?.data });
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleBannerList=(responseJson:any)=>{
    if (responseJson !== undefined && !responseJson.errors && !responseJson.message) {
      this.setState({ banner: responseJson.data });
    } else {
      this.setState({ loading: false });
    }
  }

  handleBannerDatatList=(responseJson:any)=>{
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ bannerData: responseJson?.data });
      if (this.state.bannerOption == "Watch") {
        HISTORY.push({
          pathname: "/home/Charts/videos",
          state: {
            video_list: this.state.bannerTrack,
            activeNo: "5",
            data: this.state.bannerData,
            currentList: this.state.banner ? this.state.banner : [],
          },
        });
      } else {
        HISTORY.push({
          pathname: "/home/expand/song/songId=banner",
          state: {
            data: this.state.bannerData,
          },
        });
      }
    } else {
      this.setState({ loading: false });
    }
  }
  handleRestApiCall = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getCreativesList:
       this.handleCreativeList(responseJson)
        break;
      case this.getBannersList:
        this.handleBannerList(responseJson)
        break;
      case this.getBannersData:
       this.handleBannerDatatList(responseJson)
        break;
      default:
        break;
    }
  }
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId == null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.handleRestApiCall(apiRequestCallId, responseJson)

     
    }
  }
}
// Customizable Area End
