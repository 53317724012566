// Customizable Area Start

import {Tabs, Layout } from 'antd';
import {
    Hidden
} from "@material-ui/core";
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import TracksAdd from './TracksAdd.web';




const { TabPane } = Tabs;
export default class DisplayAd extends React.Component {
    render() {

        return (
            <>
                <Layout >

                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        style={{
                            margin: "0px",
                            // height: "100%",
                            backgroundColor: "black",
                            display: "flex", flexDirection: "column",
                            // justifyContent: "space-between"
                        }}
                    >
                        <HomeHeader 
                        
                         />
                    <TracksAdd props={this.props}/>
                 
                       
                    </Content>
                </Layout>


            </>

        )
    }
}



// Customizable Area End