// Customizable Area Start
import React from 'react'
import { Grid } from "@material-ui/core";
import { americanExp, dinerClubCard, discoverLogo, jcbLogo, mastercardLogo, visaCard } from '../../assets';

const MCardInformation = ({ currentPaymentData }: any) => {

    let currentCard = "";
    if (currentPaymentData?.card?.brand === "visa") {
        currentCard = visaCard
    } else if (currentPaymentData?.card?.brand === "mastercard") {
        currentCard = mastercardLogo
    } else if (currentPaymentData?.card?.brand === "amex") {
        currentCard = americanExp
    } else if (currentPaymentData?.card?.brand === "discover") {
        currentCard = discoverLogo
    } else if (currentPaymentData?.card?.brand === "jcb") {
        currentCard = jcbLogo
    } else {
        currentCard = dinerClubCard
    }

    return (
        <>
            <Grid item xs={12} className="m_payment_Card">
                <Grid item xs={12} className='m_cardType'>
                    <span className='m_cardFont'>My Payment Method</span>

                    <img src={currentCard} alt='mastercardLogo' className='cardImage' />
                </Grid>
                <Grid item xs={12} className='m_cardInfo'>
                    <span className='m_cardFont'>****&nbsp;****&nbsp;****&nbsp;{currentPaymentData?.card?.last4 || "****"}
                    </span>
                    <span className='m_cardFont'>{currentPaymentData?.billing_details?.name || "*****"}</span>
                </Grid>
            </Grid>
        </>
    )
}

export default MCardInformation
// Customizable Area End