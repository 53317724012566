// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "../../../../framework/src/Utilities";
const user_type: any = localStorage.getItem("loggedIn_userId");
import { HISTORY } from "components/src/common";
import { openNotification } from "components/src/Notification.web";

export interface Props {
  navigation: any;
  id: string;

}

export interface IDataList {
    id: string | number;
    type: string;
    title: string;
    month: number;
    year: number;
    attributes: {
    avatar: string
    user_name: string
    count: number
    track_count: number
    is_verified: boolean
    nxz_official_logo: boolean
    total_play_count: number
    cover_image: string,
    creative_type_name: string,
    listener_count: number,
    repost_count: number,
    listener_track_count: number,
    total_repost_count: number,
  }
}

interface IDataListResponse {
  user_data: {
    data:
    [
      IDataList
    ]

  }
}

interface ITrackData {
    id: string
    type: string
    attributes: {
    art_work: string
    user_name: string
    title: string
    is_verified: boolean
    count: number
    total_count: number
  }
}


interface ITrackResponse {
  user_data: {
    data: [
      ITrackData
    ]
  }

}

interface UserListResponse {
  user_data: {
    data: [
      {
        title: string;
        month: number;
        year: number;
      }
    ]
  }
}



export interface Top40CreativesDataResponse {
  user_data: {
    data: []
  },
  error?: ""
}

export interface activeTab {
  title: string;
  month: number;
  year: number;
}

interface TopCreativeListenerDataResponse {
  user_data: {
    data: []
  }
}

export interface Tabs {
  month: string;
}

interface ArtistListResponse {
  data: [{
      id: string,
      type: string,
      attributes: {
      id: number,
      email: string,
      first_name: string,
      last_name: string,
      user_name: string,
      followed_by_current_user: boolean,
      avatar: string,
      index: number,
      track_count: number,
      is_verified: boolean,
      arrow: boolean,
      role: string,
      total_play_count: number | string,
      total_comment_count: number,
      total_repost_count: number,
    }
  }],
  error?: string
}

export interface TopArtistType {
    id: string,
    type: string,
    attributes: {
    id: number,
    email: string,
    first_name: string,
    last_name: string,
    user_name: string,
    followed_by_current_user: boolean,
    avatar: string,
    index: number,
    track_count: number,
    is_verified: boolean,
    arrow: boolean,
    role: string,
    total_comment_count: number,
    total_repost_count: number,
  }
}

export interface TopCreativeList {
    id: string | number,
    type: string,
    title: string;
    month: number;
    year: number;
    attributes: {
    avatar: string,
    cover_image: string,
    creative_type_name: string,
    user_name: string,
    count: number,
    listener_count: number,
    repost_count: number,
    track_count: number,
    listener_track_count: number,
    is_verified: boolean,
    nxz_official_logo: boolean,
    total_play_count: number,
    total_repost_count: number,
  }
}

export interface ITopArtistList {
  id: number
}

export interface TopListenerData {
  id: string | number,
  type: string,
  attributes: {
    user_name: string,
    avatar: string,
    total_play_count: number,
    total_comment_count: number,
    total_view_count: number,
    total_repost_count: number
  }
}

export interface TopListenerDataResponse {
  data:
  [
    TopListenerData
  ], error?: string;
}

export interface S {
  leftsideSelectedtab: any;
  rightsideSelectedtab: any;
  usertype: string;
  activeTab: {
    title: string;
    month: number;
    year: number;
  };
  listData: IDataList[];
  token: string;
  isLoading: boolean;
  isLabelLoading: boolean;
  artistUserID: number | string;
  activeKey: string;
  isListener: boolean;
  top40CreativesData: UserListResponse[]
  topCreativeListenerData: TopCreativeList[]
  tabs: Tabs[]
  index: string | number;
  tracklistData: ITrackData[];
  userTrackIndex: string | number;
  selectedStreamId: string | number;
  top40ArtistList: TopArtistType[];
  topListenersData: TopListenerData[];
  streamedArtist: string;
  showmobileview: boolean;
  isAudioVideoLoading: boolean;
  selectedStreamedTrack: any;
  isLabelStreamLoading: boolean;
  isLabelTabsLoading: boolean;
  isLabelAudioVideoLoading: boolean;
}

interface SS {
  id: any;
}


export default class AudioVideoController extends BlockComponent<Props, S, SS> {

  getUserListApiCallId: string = "";
  getTopArtistCallId: string = "";
  getTop40CreativeHistoryApiCallId: string = "";
  gettopCreativeListenersCallID: string = "";
  getCreativeHistoryCallId: string = "";
  getTrackListApiCallId: string = "";
  getTopListenersAPICallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      rightsideSelectedtab: "Audio",
      leftsideSelectedtab: "TopListeners",
      usertype: localStorage.getItem("user_type") || "",
      showmobileview: false,
      activeTab: {
        title: "This Month",
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      token: "",
      listData: [],
      isLoading: false,
      isAudioVideoLoading: false,
      isLabelLoading: false,
      artistUserID: 0,
      activeKey: "1",
      isListener: false,
      top40CreativesData: [],
      topCreativeListenerData: [],
      tabs: [{ month: "This Month" }, { month: "July '24" }, { month: "Aug '24" }, { month: "Sep '24" }, { month: "Oct '24" }, { month: "Nov '24" }, { month: "Dec '24" }, { month: "Jan '24" }, { month: "Feb '24" }, { month: "March '24" }, { month: "April '24" }],
      index: "",
      tracklistData: [],
      userTrackIndex: "",
      selectedStreamId: "",
      top40ArtistList: [],
      topListenersData: [],
      streamedArtist: "",
      selectedStreamedTrack: {
        id: "",
        type: "",
        attributes: {
          avatar: "",
          cover_image: "",
          creative_type_name: "",
          user_name: "",
          count: 0,
          listener_count: 0,
          repost_count: 0,
          track_count: 0,
          listener_track_count: 0,
          is_verified: false,
          nxz_official_logo: false,
          total_play_count: 0
        }
      },
      isLabelStreamLoading: false,
      isLabelTabsLoading: false,
      isLabelAudioVideoLoading: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("token");
    this.setState({ token: token });
    if (this.state.usertype == "creative_plus") {
      this.setState({ isListener: true })
      this._topCreativeListenersAPICall()
    } else if (this.state.usertype == "label") {
      this._top40ArtistAPICall()
    } else {
      this._topCreativeListenersAPICall()
    }
  }
  onBack = () => {
    this.setState({ showmobileview: false })
  }

  getProgress = (item: IDataList, dataArray: any) => {
    // Find maximum total_play_count
    const data_array = this.state.listData;
    const max_play_count = Math.max(...data_array.map((item: { attributes: { total_play_count: any; }; }) => item.attributes.total_play_count));

    try {
      let num = 0;
      if (this.state.isListener) {
        num =
          item.attributes.total_play_count /
          max_play_count;
      } else {
        num =
          item.attributes.listener_count /
          item.attributes.listener_track_count
      }
      if (isNaN(num) || !isFinite(num)) {
        return 0;
      }
      return num;
    } catch (error) {
      return 0;
    }
  };

  getCountValue = (item: IDataList) => {
    if (this.state.leftsideSelectedtab == "TopListeners" && this.state.usertype.includes("creative")) {
      return item?.attributes?.count
    } else if (this.state.leftsideSelectedtab == "TopListeners" && this.state.usertype.includes("listener")) {
      return item?.attributes?.listener_count
    } else if (this.state.usertype.includes("label")) {
      return item?.attributes?.total_play_count
    } else {
      return item?.attributes?.count
    }
  }

  getLinearProgress = (item: IDataList, dataArray: any) => {
    // Find maximum total_play_count
    const max_play_count = Math.max(...dataArray.map((item: { attributes: { total_play_count: number }; }) => item.attributes.total_play_count));

    try {
      let num = 0;

      num =
        item.attributes?.total_play_count /
        max_play_count


      if (isNaN(num) || !isFinite(num)) {
        return 0;
      }
      return num;
    } catch (error) {
      return 0;
    }
  };


  getLast12MonthsData() {
    const currentDate = new Date();
    const last12MonthsData = [];

    let isCurrentMonth = true;

    for (let i = 0; i < 12; i++) {
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      let title;
      if (isCurrentMonth) {
        title = "This Month";
        isCurrentMonth = false;
      } else {
        const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(currentDate);
        title = month === 1 ? `${monthName} '${(year % 100) - 1}` : `${monthName} '${year % 100}`;
      }
      last12MonthsData.push({ title, month, year });
      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    return last12MonthsData;
  }

  // Dummy handleData function
  handleData = (item: activeTab) => {
    this.setState({ activeTab: item, topCreativeListenerData: [], leftsideSelectedtab: "TopListeners" }, () => {
      if (this.state.usertype.includes("listener") || this.state.usertype.includes("creative")) {
        this._topCreativeListenersAPICall();
      } if (this.state.usertype == "label") {
        this._topListenersAPICall()
      };
    })
  }


  // Handle tab change
  handleTabChange = async (activeKey: string) => {
    this.setState({ activeKey, topCreativeListenerData: [], tracklistData: [], streamedArtist: "", selectedStreamedTrack: [], rightsideSelectedtab: "Audio", selectedStreamId: "" });
    const returnedMonthsData = this.getLast12MonthsData();
    const selectedIndex = parseInt(activeKey);
    const selectedItem = returnedMonthsData[selectedIndex]
    if (selectedItem) {
      this.handleData(selectedItem);
    }
  };


  handleTab = (tab: string) => {
    this.setState({ leftsideSelectedtab: tab, tracklistData: [], selectedStreamedTrack: [], streamedArtist: "", selectedStreamId: "" })
    if (tab == "TopListeners") {
      this._topCreativeListenersAPICall();
    } else {
      this._getTop40CreativeHistory();
    }
  }
  handleAudiovideotab = (tab: string) => {
    this.setState({ rightsideSelectedtab: tab }, () =>
      this._getTrackList(this.state.userTrackIndex)

    )
  }

  selectedTrack = (item: IDataList, index: string | number) => {
    this.setState({
      index: index, userTrackIndex: item.id, selectedStreamId: item.id, tracklistData: [], streamedArtist: item?.attributes?.user_name, showmobileview: true,
      selectedStreamedTrack: item
    })

    this._getTrackList(item.id)
  }

  selectedTrackListener = (item: IDataList, index: string | number) => {

    this.setState({ index: index, userTrackIndex: item.id, selectedStreamId: item.id, tracklistData: [], streamedArtist: item?.attributes?.user_name, showmobileview: true })
    this._getTrackList(item.id)
  }
  redirectToProfile = () => {
    const userType: any = localStorage.getItem("user_type");

    const loggedIn_userId: any = localStorage.getItem("loggedIn_userId");
    if (userType?.includes("listener")) {
      HISTORY.push({
        pathname: `/listeners/activity?listener_id=${loggedIn_userId}`,
      })
    }
    else if (userType?.includes("label")) {
      HISTORY.push({
        pathname: `/labels/creatives?labels_id=${loggedIn_userId}`,
      })
    }
    else if (userType?.includes("creative")) {
      HISTORY.push({
        pathname: `/creatives/creatives?creative_id=${loggedIn_userId}`,
      })
    }
  }

  _getTop40CreativeHistory() {
    this.setState({ isLoading: true, isAudioVideoLoading: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCreativeHistoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/creative_view_histories/stream_history?user_type=listener`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  _top40ArtistAPICall() {
    this.setState({ isLabelLoading: true, isLabelStreamLoading: true })
    const user_type = localStorage.getItem("loggedIn_userId")
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopArtistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/top_hundred_creative?user_id=${user_type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  _getUserList = () => {
    this.setState({ isLoading: true, isAudioVideoLoading: true })
    let listenerUrl = "";
    if (this.state.usertype.includes("listener")) {
      listenerUrl = "&user_type=listener"
    }
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/creative_view_histories/stream_history?expYear=${this.state.activeTab.year}&expMonth=${this.state.activeTab.month}${listenerUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  _topCreativeListenersAPICall = () => {
    this.setState({ isLoading: true, isAudioVideoLoading: false })
    let listenerUrl = "";
    if (this.state.usertype.includes("listener")) {
      listenerUrl = "&user_type=listener"
    } else {
      listenerUrl = "&user_type=creative"
    }
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.gettopCreativeListenersCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/creative_view_histories/stream_history?expYear=${this.state.activeTab.year}&expMonth=${this.state.activeTab.month}${listenerUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  _getTrackList = (userId: string | number) => {
    this.setState({ isAudioVideoLoading: true })
    let listnerUrl = ""
    let url
    if (this.state.isListener) {
      url = "bx_block_profile/creative_view_histories/single_listener?user_id=" + userId + "&upload_type=" + this.state.rightsideSelectedtab
    } else if (this.state.usertype == "label") {
      url = "bx_block_profile/creative_view_histories/single_label_listener?user_id=" + userId + "&upload_type=" + this.state.rightsideSelectedtab + "&creative_id=" + this.state.artistUserID + listnerUrl
    }
    else {
      listnerUrl = "&user_type=listener"
      url = "bx_block_profile/creative_view_histories/single_listener?user_id=" + userId + "&upload_type=" + this.state.rightsideSelectedtab + listnerUrl
    }


    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrackListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  _topListenersAPICall = async () => {
    this.setState({ isLoading: true, isAudioVideoLoading: true, isLabelTabsLoading: true })



    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopListenersAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/top_listeners?user_id=${this.state.artistUserID}&expYear=${this.state.activeTab.year}&expMonth=${this.state.activeTab.month}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTrackListApiCallSuccessCallBack(responseJson: ITrackResponse) {
    if (responseJson?.user_data?.data?.length > 0) {
      this.setState({ tracklistData: responseJson?.user_data?.data, isAudioVideoLoading: false })
    } else {
      this.setState({ tracklistData: [], isAudioVideoLoading: false })
    }
  }

  handleOnClickLabelCreative = (item: TopArtistType) => {
    this.setState({ artistUserID: item?.id, selectedStreamId: "" }, () => this._topListenersAPICall())

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (apiRequestCallId == this.gettopCreativeListenersCallID) {
        this.handleTopCreativeListener(responseJson)
      } else if (apiRequestCallId == this.getCreativeHistoryCallId) {
        this.handleCreativeHistory(responseJson)
      } else if (apiRequestCallId == this.getTrackListApiCallId) {
        this.getTrackListApiCallSuccessCallBack(responseJson)
      } else if (apiRequestCallId == this.getTopArtistCallId) {
        this.handleTopArtistApiCallSuccessCallBack(responseJson)
      } else if (apiRequestCallId == this.getTopListenersAPICallId) {
        this.handleTopListenersCallSuccessCallBack(responseJson)
      }
    }
    // Customizable Area End
  }

  handleTopListenersCallSuccessCallBack = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.error) {
      this.setState({ listData: responseJson.user_data.data, isLoading: false, isAudioVideoLoading: false, isLabelTabsLoading: false })
    } else {
      this.setState({ isLoading: false, listData: [], isAudioVideoLoading: false, topListenersData: [], isLabelTabsLoading: false })
    }
  }

  handleTopArtistApiCallSuccessCallBack = (responseJson: ArtistListResponse) => {
    if (responseJson !== undefined && !responseJson.error) {
      if (responseJson.data.length > 0) {
        const uniqueItems = responseJson.data.filter((obj: any, index: any, self: any) =>
          index === self.findIndex((o: any) => o.id === obj.id)
        );
        this.setState({
          top40ArtistList: uniqueItems,
          artistUserID: responseJson.data[0]?.id,
          isLabelLoading: false,
          isLabelStreamLoading: false
        },
          () => this._topListenersAPICall()
        )

      } else {
        this.setState({ isLabelLoading: false, top40ArtistList: [], isLabelStreamLoading: false })
      }
    } else {
      openNotification(responseJson.error, "Error")
      this.setState({ isLabelLoading: false, top40ArtistList: [], isLabelStreamLoading: false })
    }
  }

  handleCreativeHistory = (responseJson: Top40CreativesDataResponse) => {
    if (responseJson !== undefined && !responseJson.error) {
      this.setState({ topCreativeListenerData: responseJson.user_data.data, isLoading: false, isAudioVideoLoading: false })

    } else {
      this.setState({ isLoading: false, isAudioVideoLoading: true })
    }
  }


  handleTopCreativeListener = (responseJson: TopCreativeListenerDataResponse) => {
    if (responseJson.user_data?.data?.length > 0) {
      this.setState({ topCreativeListenerData: responseJson.user_data.data, isLoading: false, isAudioVideoLoading: false })
    }
    else {
      this.setState({ topCreativeListenerData: [], isLoading: false, isAudioVideoLoading: false })
    }
  }
}
// Customizable Area End
