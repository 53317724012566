// Customizable Area Start
import React from 'react';
import { Box, Typography } from '@material-ui/core'
import CommentRepliesController from './CommentRepliesController'

export default class CommentReplies  extends CommentRepliesController {
  render(){
    return (
        <Box className="UserComments" >
            {/* Music Comment */}
            <Box className={`MusicComment ${this.props.selected && 'Active'}`} style={{backgroundColor: this.props.selectedTrackIndex == this.props.TrackIndex ?  '#141414' : "transparent",border: this.props.selectedTrackIndex == this.props.TrackIndex ? 'solid 3px #212121' : "transparent" , borderRadius: '10px', padding: this.props.selectedTrackIndex == this.props.TrackIndex ? '8px 10px' : '0px'}}>
                <Box className={`LeftImageBox ${this.props.isVideo && 'Video'}`}>
                    <img src={this.props.comment?.art_work} className={`${this.props.isVideo ? 'VideoImage' : 'MusicImage'}`} onClick={this.handleUserClick} />
                </Box>
                <Box className='DetailBox'  onClick={this.handleClick}>
                    <Box className='ContentBox'>
                        <Typography className='UserNameText'>
                            {this.props.comment?.user_name}:<span className='RepliedText'>Replied</span>
                        </Typography>
                        <Typography className='UserReplyText'>{this.props.comment?.reply}</Typography>
                    </Box>
                    {!this.props.comment?.is_read &&
                        <Box className='UnreadBox'>
                            <Box className='BlueDot'></Box>
                        </Box>
                    }
                </Box>
            </Box>
           
        </Box>
    );
                }
}
// Customizable Area End
