// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import { withRouter } from 'react-router';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Payments from '../../blocks/Payments/src/Payments';
import Notifications from '../../blocks/Notifications/src/Notifications';
import FreeTrial from '../../blocks/FreeTrial/src/FreeTrial';
import PhoneVerification from '../../blocks/PhoneVerification/src/PhoneVerification';
import Followers from '../../blocks/Followers/src/Followers';
import DownloadOptions from '../../blocks/DownloadOptions/src/DownloadOptions';
import Videos from '../../blocks/Videos/src/Videos';
// import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Share from '../../blocks/Share/src/Share';
  import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import SavedCards from '../../blocks/SavedCards/src/SavedCards';
import PremiumPlanComparison from '../../blocks/PremiumPlanComparison/src/PremiumPlanComparison';
import BluetoothIntegration from '../../blocks/BluetoothIntegration/src/BluetoothIntegration';
import Settings from '../../blocks/Settings/src/Settings';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import ProductTutorial from '../../blocks/ProductTutorial/src/ProductTutorial';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import PromoteContent from '../../blocks/PromoteContent/src/PromoteContent';
import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Welcome from '../../blocks/email-account-registration/src/Welcome.web';
import AccountType from '../../blocks/email-account-registration/src/AccountType.web';
import Listener from '../../blocks/email-account-registration/src/Listener.web';
import Creatives from '../../blocks/email-account-registration/src/Creative.web';
import Label from '../../blocks/email-account-registration/src/Label.web';
import CreativeProfile from '../../blocks/user-profile-basic/src/CreativeProfile.web';
import ListenerProfile from '../../blocks/user-profile-basic/src/ListenerProfile.web';
import Otp from '../../blocks/otp-input-confirmation/src/Otp.web';
import TermAndConditions from '../../blocks/TermsAndConditions/src/TermAndConditions.web';
import ForListener from '../../blocks/PremiumPlanComparison/src/ForListener.web';
import ForCreative from '../../blocks/PremiumPlanComparison/src/ForCreative.web';
import MainBody from '../../blocks/user-profile-basic/src/MainBody.web';
import PreviewVideo from '../../blocks/PreviewVideo/src/PreviewVideo';
import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
import Playlist from '../../blocks/Playlist/src/Playlist';
import Repost from '../../blocks/Repost/src/Repost';
import UserAvatar from '../../blocks/UserAvatar/src/UserAvatar';
import Sms from '../../blocks/Sms/src/Sms';
import Trending from '../../blocks/Trending/src/Trending';
import OrderDetailView from '../../blocks/OrderDetailView/src/OrderDetailView';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import ActivityFeed from '../../blocks/ActivityFeed/src/ActivityFeed';
import DetailedDownloadHistory from '../../blocks/DetailedDownloadHistory/src/DetailedDownloadHistory';
import CustomAdvertisements from '../../blocks/CustomAdvertisements/src/CustomAdvertisements';
import Sorting from '../../blocks/sorting/src/Sorting';
import NavigationMenu from '../../blocks/NavigationMenu/src/NavigationMenu';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import AudioMusic from '../../blocks/AudioMusic/src/AudioMusic';
// import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Library from '../../blocks/Library/src/Library';
import CfCoinbaseConnectIntegration from '../../blocks/CfCoinbaseConnectIntegration/src/CfCoinbaseConnectIntegration';
import VisualAnalytics from '../../blocks/VisualAnalytics/src/VisualAnalytics';
import SubscriptionBilling from '../../blocks/SubscriptionBilling/src/SubscriptionBilling';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPasswordWeb';
import PhoneNumberVerificationWeb from '../../blocks/forgot-password/src/PhoneNumberVerificationWeb';

import ResetPasswordWeb from '../../blocks/forgot-password/src/ResetPasswordWeb';
import OtpVerifyWeb from '../../blocks/forgot-password/src/OtpVerifyWeb';
import VideoPage from '../../blocks/Videos/src/Videos.web';
import CreativePage from '../../blocks/dashboard/src/Creatives/index.web';
import PlaylistPage from '../../blocks/Playlist/src/PlaylistPage.web';
import ChartsPage from '../../blocks/dashboard/src/Charts/index.web';
import Listeners from '../../blocks/user-profile-basic/src/Creatives/index.web';
import AddTrack from '../../blocks/user-profile-basic/src/Creatives/AddTrack.web';
import PlayListCreateAndAddplayListModel from '../../blocks/Playlist/src/PlayListCard/PlayListCreateAndAddplayListModel.web'
import MainContent from 'blocks/dashboard/src/Home/MainContent.web';
import MainSearchWeb from 'blocks/AdvancedSearch/src/HomeSearchPage/MainSearchWeb';
import MyProfile from '../../blocks/user-profile-basic/src/Listeners/index.web';
import PlaylistDesigns from '../../blocks/Playlist/src/NewDesigns/AllMyPlaylist/index.web';
import SinglePlaylistPublic from '../../blocks/Playlist/src/NewDesigns/SinglePlaylist/Public/index.web';
import SelfView from '../../blocks/Playlist/src/NewDesigns/SinglePlaylist/Self/index.web';
import Queue from 'blocks/dashboard/src/Queue/Audio/index.web';
import EditProfile from 'components/src/CustomSpilt/EditProfile/index.web';
import ChartsVideo from '../../blocks/dashboard/src/Charts/Videos/index.web.tsx'
// ChatBox is in farheen/build/bugs or my-profile
// import ChatBox from '../../blocks/dashboard/src/ChatBox/index.web';
import { HISTORY } from '../../components/src/common';
// import PlanPayments from '../../blocks/dashboard/src/MyplanAndPayments/PlanPayments.web';
// import { UpdateCard } from '../../blocks/dashboard/src/MyplanAndPayments/UpdateCard.web';
// import PlanPayments from '../../blocks/dashboard/src/PlanPayments.web';

import LoginSearchPopup from 'blocks/AdvancedSearch/src/HomeSearchPage/LoginSearchPopup.web';

import PlanPayments from '../../blocks/Payments/src/NewMyPlanAndPayments/Index.web';
import UpdateCard from '../../blocks/Payments/src/NewMyPlanAndPayments/UpdateCard.web';
// import DashboardPage from '../../components/src/CustomSpilt/DashboardPage/DashboardPage.web';
import DashboardPage from 'blocks/dashboard/src/Dashboard/Dashboard.web';
import Ctrl from 'blocks/ReviewAndApproval/src/CTRL/Ctrl.web';
import { openNotification } from '../../components/src/Notification.web';
import UploadTrack from 'blocks/BulkUploading/src/UploadTrack/index.web';
import AudioTrack from 'blocks/BulkUploading/src/UploadTrack/AudioTrack.web';
// import { SingleTrack } from 'blocks/dashboard/src/Charts/SingleTrack/index.web';
import { SingleTrack } from '../../components/src/CustomChart/SingleTrack/index.web'
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web';

import { AudioContextApi } from 'blocks/dashboard/src/AudioContext/AudioContext.web';
import CustomAudioPlayer from 'blocks/AudioMusic/src/CustomAudioPlayer.web';
import Deal from 'blocks/dashboard/src/DealFlow/Deal.web';
import MUpdatePlan from 'blocks/Payments/src/NewMyPlanAndPayments/MobileView/MUpdatePlan.web';
import MPlanCanceled from 'blocks/Payments/src/NewMyPlanAndPayments/MobileView/MPlanCanceled.web';
import MConfirmPlanTemp from 'blocks/Payments/src/NewMyPlanAndPayments/MobileView/MConfirmPlanTemp.web';
import MPlanCancelTemp from 'blocks/Payments/src/NewMyPlanAndPayments/MobileView/MPlanCancelTemp.web';
import AudioVideoSplit from '../../blocks/dashboard/src/DealFlow/Split/AudioVideoSplit.web';
import UserSplit from '../../blocks/dashboard/src/DealFlow/Split/UserSplit.web';
import SplitPercentage from '../../blocks/dashboard/src/DealFlow/SplitPercentage/SplitPercentage.web';
import DealAmount from '../../blocks/dashboard/src/DealFlow/AmountAndTerm/DealAmount.web';
import DealTerm from '../../blocks/dashboard/src/DealFlow/AmountAndTerm/DealTerm.web';
import OfferPreview from '../../blocks/dashboard/src/DealFlow/OfferPreviewAndVerification/OfferPreview.web';
import ImportentNotice from '../../blocks/dashboard/src/DealFlow/OfferPreviewAndVerification/ImportentNotice.web';
import VerifyDeal from '../../blocks/dashboard/src/DealFlow/OfferPreviewAndVerification/VerifyDeal.web';
import OfferSent from '../../blocks/dashboard/src/DealFlow/OfferPreviewAndVerification/OfferSent.web';
import InboxComments from '../../blocks/dashboard/src/Inbox/InboxComments.web';
import Labels from '../../blocks/user-profile-basic/src/Label/index.web';
import DownloadPage from '../../blocks/DownloadOptions/src/DownloadPage.web';
import Originals from '../../blocks/user-profile-basic/src/Originals/index.web';
import StreamRepost from '../../blocks/DetailedDownloadHistory/src/StreamRepostHistory/StreamRepost.web'
import RepostHistory from '../../blocks/DetailedDownloadHistory/src/StreamRepostHistory/RepostHistory.web'
import AudioVideo from '../../blocks/DetailedDownloadHistory/src/StreamRepostHistory/AudioVideo.tsx'
import LableRepostHistory from '../../blocks/DetailedDownloadHistory/src/StreamRepostHistory/LableRepostHistory.web'
import StreamUnlock from "../../blocks/DetailedDownloadHistory/src/StreamUnlock/StreamUnlock.web"
import StreamUnlockConfirmation from "../../blocks/DetailedDownloadHistory/src/StreamUnlock/StreamUnlockConfirmation.web"
import DisplayAdd from "../../blocks/CustomAdvertisements/src/Addsetup/DisplayAd.web"
import AdConfirmation from '../../blocks/CustomAdvertisements/src/Addsetup/AdConfirmation.web.tsx'
import PushPlayerAdResults from "../../blocks/PromoteContent/src/PushAdResult/PushPlayerAdResults.web.tsx" 
const userType = localStorage.getItem('user_type');
const audio_player = localStorage.getItem("audioplayer");
const storedQueueSongs = localStorage.getItem('queueSongs')
import {  removeStorageData } from "../../framework/src/Utilities.ts";



const routeMap = {
  HomePage: {
    component: MainContent,
    exact: true,
    path: ['/', '/home']
  },
  // Planpayments: {
  //   component: PlanPayments,
  //   exact: true,
  //   path: '/planpayments'
  // },
  PlanPayments: {
    component: PlanPayments,
    exact: true,
    path: '/planpayments'
  },
  EditProfile: {
    component: EditProfile,
    exact: true,
    path: '/edit-profile'
  },
  MConfirmPlanTemp: {
    component: MConfirmPlanTemp,
    exact: true,
    path: '/mconfirmplan'
  },
  MUpdatePlan: {
    component: MUpdatePlan,
    exact: true,
    path: '/mupdateplan'
  },
  MPlanCancelTemp: {
    component: MPlanCancelTemp,
    exact: true,
    path: '/mplancancel'
  },
  MPlanCanceled: {
    component: MPlanCanceled,
    exact: true,
    path: '/mplancanceled'
  },
  UpdateCard: {
    component: UpdateCard,
    path: '/updatecard'
  },
  SingleTrack: {
    component: SingleTrack,
    exact: true,
    // path: '/singletrack'
    path: '/home/expand/song/songId=:id'
  },
  Deal: {
    component: Deal,
    exact: true,
    path: '/deal'
  },
  DealAudioVideoSplit: {
    component: AudioVideoSplit,
    exact: true,
    path: '/deal/audio-video-split'
  },
  DealUserSplit: {
    component: UserSplit,
    exact: true,
    path: '/deal/user-split'
  },

  DealSplitPercentage: {
    component: SplitPercentage,
    exact: true,
    path: '/deal/select-split-percentage'
  },
  DealAmount: {
    component: DealAmount,
    exact: true,
    path: '/deal/enter-deal-amount',
  },
  DealTerm: {
    component: DealTerm,
    exact: true,
    path: '/deal/select-deal-term',
  },
  PreviewDeal: {
    component: OfferPreview,
    exact: true,
    path: '/deal/preview-deal'
  },
  DealImportentNotice: {
    component: ImportentNotice,
    exact: true,
    path: '/deal/deal-importent-notice'
  },
  VerifyDeal: {
    component: VerifyDeal,
    exact: true,
    path: '/deal/verify'
  },
  OfferSent: {
    component: OfferSent,
    exact: true,
    path: '/deal/offer-sent'
  },
  InboxComments: {
    component: InboxComments,
    exact: true,
    path: '/comments'
  },
  Dashboard: {
    exact:true,
    component: Dashboard,
    path: '/Dashboard'
  },
  DashboardPage: {
    component: DashboardPage,
    path: '/dashboard'
  },
  Ctrl:{
    component: Ctrl,
    path:'/ctrl/:slug'
  },
  
  MyProfile: {
    component: MyProfile,
    path: '/listeners/:type'
  },
  ListenersProfile: {
    component: Listeners,
    path: '/listener_public'
  },
  Labels: {
    component: Labels,
    path: '/labels/:type'
  },
  CreativesProfile: {
    component: Listeners,
    path: '/creatives/:type'
  },
  OriginalsProfile: {
    component: Originals,
    path: '/originals/:type'
  },
  AddTrack: {
    component: AddTrack,
    path: '/addTrack'
  },
  AdConfirmation:{
    component:AdConfirmation,
    path: "/AdConfirmation"
  },
  PushPlayerAdResults:{
    component:PushPlayerAdResults,
    path : "/PushPlayerAdResults"
  },
  // ChartFullScreenPage: {
  //   component: ChartsFullScreen,
  //   path: '/home/expand/song/songId=:id'
  // },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  FreeTrial: {
    component: FreeTrial,
    path: '/FreeTrial'
  },
  PhoneVerification: {
    component: PhoneVerification,
    path: '/PhoneVerification'
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  DownloadOptions: {
    component: DownloadOptions,
    path: '/DownloadOptions'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  // SocialMediaAccountRegistrationScreen: {
  //   component: SocialMediaAccountRegistrationScreen,
  //   path: '/SocialMediaAccountRegistrationScreen'
  // },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  // ForgotPassword: {
  //   component: ForgotPassword,
  //   path: '/ForgotPassword'
  // },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  SavedCards: {
    component: SavedCards,
    path: '/SavedCards'
  },
  PremiumPlanComparison: {
    component: PremiumPlanComparison,
    path: '/PremiumPlanComparison'
  },
  BluetoothIntegration: {
    component: BluetoothIntegration,
    path: '/BluetoothIntegration'
  },
  Settings: {
    component: Settings,
    path: '/Settings'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ProductTutorial: {
    component: ProductTutorial,
    path: '/ProductTutorial'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  PromoteContent: {
    component: PromoteContent,
    path: '/PromoteContent'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  PreviewVideo: {
    component: PreviewVideo,
    path: '/PreviewVideo'
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: '/PaymentAdmin'
  },
  Playlist: {
    component: Playlist,
    path: '/Playlist'
  },
  Repost: {
    component: Repost,
    path: '/Repost'
  },
  UserAvatar: {
    component: UserAvatar,
    path: '/UserAvatar'
  },
  Sms: {
    component: Sms,
    path: '/Sms'
  },
  CreativeProfile: {
    component: CreativeProfile,
    path: '/CreativeProfile'
  },
  ListenerProfile: {
    component: ListenerProfile,
    path: '/ListenerProfile'
  },
  Trending: {
    component: Trending,
    path: '/Trending'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: '/ActivityFeed'
  },
  DetailedDownloadHistory: {
    component: DetailedDownloadHistory,
    path: '/DetailedDownloadHistory'
  },
  RepostHistory:{
    component:RepostHistory,
    path:'/Reposthistory'
  },
  AudioVideo:{
    component:AudioVideo,
    exact:true,
    path:'/labelhistory'
  },
  StreamRepost :{
    component:StreamRepost,
    path:'/streamRepost'
  },
  LableRepostHistory:{
    component:LableRepostHistory,
    exact:true,
    path:'/labelRepost'
  },
  StreamUnlock:{
    component:StreamUnlock,
    path:"/StreamUnlock"
  },
  StreamUnlockConfirmation:{
    // exact:true,
    component:StreamUnlockConfirmation,
    path:"/confirmation"
  },
  CustomAdvertisements: {
    component: CustomAdvertisements,
    path: '/CustomAdvertisements'
  },
  DisplayAdd: {
    component: DisplayAdd,
    path: '/DisplayAd'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  AudioMusic: {
    component: AudioMusic,
    path: '/AudioMusic'
  },
  // SocialMediaAccountLoginScreen: {
  //   component: SocialMediaAccountLoginScreen,
  //   path: '/SocialMediaAccountLoginScreen'
  // },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Library: {
    component: Library,
    path: '/Library'
  },
  CfCoinbaseConnectIntegration: {
    component: CfCoinbaseConnectIntegration,
    path: '/CfCoinbaseConnectIntegration'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  SubscriptionBilling: {
    component: SubscriptionBilling,
    path: '/SubscriptionBilling'
  },

  Home: {
    component: Welcome,
    path: '/Welcome'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/forgot-password'
  },
  PhoneNumberVerificationWeb: {
    component: PhoneNumberVerificationWeb,
    path: '/PhoneNumberVerification'
  },
  OtpVerifyWeb: {
    component: OtpVerifyWeb,
    path: '/otp-verify'
  },
  ResetPasswordWeb: {
    component: ResetPasswordWeb,
    path: '/reset-password'
  },

  AccountType: {
    component: AccountType,
    path: '/AccountType'
  },
  Listener: {
    component: Listener,
    path: '/Listener'
  },
  Creatives: {
    component: Creatives,
    path: '/Creative'
  },
  Label: {
    component: Label,
    path: '/Label'
  },
  Otp: {
    component: Otp,
    path: '/Otp'
  },
  TermAndConditions: {
    component: TermAndConditions,
    path: '/TermAndConditions'
  },
  ForListener: {
    component: ForListener,
    path: '/ForListener'
  },
  ForCreative: {
    component: ForCreative,
    path: '/ForCreative'
  },
   ForLabel: {
    component: ForCreative,
    path: '/ForLabel'
  },
  MainBody: {
    component: MainBody,
    path: '/MainBody'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },

  MainSearchWeb: {
    component: MainSearchWeb,
    path: '/home/search/:slug'
  },
  videoPage: {
    component: VideoPage,
    path: '/home/VideosPage'
  },
  CreativePage: {
    component: CreativePage,
    path: '/home/Creatives'
  },


  DownloadPage: {
    component: DownloadPage,
    exact: true,
    path: '/home/downloadPage'
  },

  PlaylistPage: {
    component: PlaylistPage,
    exact: true,
    path: '/home/PlaylistPage/:slug'
  },

  PlaylistDesigns: {
    component: PlaylistDesigns,
    exact: true,
    path: '/home/all-playlist'
  },

  SinglePlaylistPublic: {
    component: SinglePlaylistPublic,
    exact: true,
    path: '/home/playlist/public-view/:slug'
  },
  Queue: {
    component: Queue,
    exact: true,
    path: '/home/playlist-queue/audio/id:id'
  },
  SelfView: {
    component: SelfView,
    exact: true,
    path: '/home/self-view/:slug'
  },
  ChartsPage: {
    component: ChartsPage,
    path: '/home/Charts/:slug'
  },
  ChartsVideo: {
    component: ChartsPage,
    exact: true,
    path: '/home/activity/videos'
  },
  PlayListCreateAndAddplayListModel: {
    component: PlayListCreateAndAddplayListModel,
    path: '/PlayListCreateAndAddplayListModel'
  },
  UploadTrack: {
    component: UploadTrack,
    exact: true,
    path: '/uploadTrack'
  },
  AudioTrack: {
    component: AudioTrack,
    exact: true,
    path: '/uploadTrack/:type'
  }
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
//   authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
//   databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
//   projectId: 'rnmasterapp-c11e9',
//   storageBucket: 'rnmasterapp-c11e9.appspot.com',
//   messagingSenderId: '649592030497',
//   appId: '1:649592030497:web:7728bee3f2baef208daa60',
//   measurementId: 'G-FYBCF3Z2W3'
// });

const withoutLoginScreens = {
  HomePage: {
    component: MainContent,
    exact: true,
    path: ['/', '/home']
  },
  ChartsPage: {
    component: ChartsPage,
    path: '/home/Charts/:slug'
  },
  // StreamPage: {
  //   component: StreamPage,
  //   path: '/home/Stream'
  // },
  Home: {
    component: Welcome,
    path: '/Welcome'
  },
  CreativeProfile: {
    component: CreativeProfile,
    path: '/CreativeProfile'
  },
  ListenerProfile: {
    component: ListenerProfile,
    path: '/ListenerProfile'
  },

  LoginSearchPopup: {
    component: LoginSearchPopup,
    path: '/login/search/screen'
  },
  
};


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: localStorage.getItem('token'),
      auth: false,
      isPlaylist: false,
      playlistData: {},
      checkType: '',
      selectedPlayList: [],
      songIndex: 0,
      handleAudio: 0,
      dialogText: '',
      openLogin: false,
      alertPopup: false,
      testIphonePayment: false,
      playlistQueue: [],
      repostSong: '',
      audioLoader: true,
      queueSongs: [],
      contextQueueSongs: [],
      playlistQueueSongs: [],
      nextPlaylistSong: '',
      currentTime: 0,
      endingTime: 0,
      removeNowPlayingSong: '',
      nextUplist: [],
      nextSongForPlay: ''
    };
  }


  componentDidMount() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let profileId = '';
    let trackId = '';
    if (window.location.href.includes('/Welcome')) {
      localStorage.clear()
      removeStorageData("playerCount")
    removeStorageData("trackQueue")
    } else if (audio_player) {

      // if (!localStorage.getItem('token')) {
      //   localStorage.removeItem("audioplayer")
      // } else {
      let playerData = JSON.parse(audio_player)
      this.setState({
        isPlaylist: playerData.isPlaylist,
        playlistData: playerData.playlistData,
        checkType: playerData.checkType,
        selectedPlayList: playerData.selectedPlayList,
        songIndex: playerData.songIndex,
        handleAudio: 1
      });
      // }
    }

    const removesong = localStorage.getItem("removingNowPlayingSong");
    if (removesong) {
      localStorage.removeItem("removingNowPlayingSong")
    }

    if (storedQueueSongs) {
      if (!localStorage.getItem('token')) {
        localStorage.removeItem("queueSongs")
      } else {
        let queueData = JSON.parse(storedQueueSongs)
        this.setState({
          contextQueueSongs: queueData
        });
      }
    }

    if (window.location.href == `${window.location.origin}/`) {
      HISTORY.push('/home');
    }
    if (/iPhone/.test(userAgent) && !window.MSStream) {
      if (window.location.href.includes('/creatives/beats?creative_id=')) {
        const url = window.location.href.split(/\=/);
        profileId = url[1];

        window.location.href = `nxzsound://PublicProfileCreative/${profileId}`;
        setTimeout(function () {
          if (!localStorage.getItem('token')) {
            HISTORY.push('/Welcome');
            alert('Please Login To Continue');
          }
        }, 4000);
        //return "iOS";
      }else if(window.location.href.includes('/listeners/activity?listener_id=')){
          const url = window.location.href.split(/\=/);
          profileId = url[1];
  
          window.location.href = `nxzsound://ListenerProfile/${profileId}`;
          setTimeout(function () {
            if (!localStorage.getItem('token')) {
              HISTORY.push('/Welcome');
              alert('Please Login To Continue');
            }
          }, 4000);
      }
       else if (window.location.href.includes('/home/expand/song/songId=')) {
        const url = window.location.href.split(/\=/);
        trackId = url[1];

        window.location.href = `nxzsound://DeepLinkAudioMusicScreen/${trackId}`;
        setTimeout(function () {
          if (!localStorage.getItem('token')) {
            HISTORY.push('/Welcome');
            alert('Please Login To Continue');
          }
          //   window.location.href = `https://nxzsound10-66173-react.b66173.stage.eastus.az.svc.builder.ai/home/expand/song/songId=${trackId}`  //'https://apps.apple.com/in/app/expo-go/id982107779';
        }, 4000);

        // window.onblur = function () {
        //     alert("app there in iphone")
        // };
        // window.onfocus = function(){
        //   alert("app there in iphone")
        // }
      } else if(window.location.href.includes('/home/Charts/videos?id=')){
        const url = window.location.href.split(/\=/);
        const videoId = url[1];

        window.location.href = `nxzsound://DeepLinkVideoScreen/${videoId}`;
        setTimeout(function () {
          if (!localStorage.getItem('token')) {
            HISTORY.push('/Welcome');
            alert('Please Login To Continue');
          }
          //   window.location.href = `https://nxzsound10-66173-react.b66173.stage.eastus.az.svc.builder.ai/home/expand/song/songId=${trackId}`  //'https://apps.apple.com/in/app/expo-go/id982107779';
        }, 4000);
      }else if (window.location.href.includes('/forgot-password')) {
        HISTORY.push('/forgot-password');
      }
      else if (window.location.href.includes('/PhoneNumberVerification')) {
        HISTORY.push('/PhoneNumberVerification');
      }
      else {
        if (!localStorage.getItem('token')) {
          if (window.location.href.includes('/AccountType')) {
            HISTORY.push('/AccountType')
          } else if (window.location.href.includes('planpayments?fromiphone')) {
            HISTORY.push('/Welcome?iphoneplans')
            alert('Please login to continue');
          }
          else {
            HISTORY.push('/home');
          }
        }
        else {
          const token = localStorage.getItem('token')
          if (token) {
            if (window.location.href.includes('/planpayments?fromiphone')) {
              localStorage.clear();
              removeStorageData("playerCount")
              removeStorageData("trackQueue")
              HISTORY.push('/Welcome?iphoneplans')
              alert('Please login to continue');
            }
          }
        }
      }
    } else if (!localStorage.getItem('token')) {
      // this.setState({ auth: true });
      // this.window.reload()
      // if(window.location.href.includes('/Welcome')){
      //       //openNotification('please login to continue', 'Error');
      //       console.log('shareeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
      // }else{
      if (
        window.location.href.includes('/creatives/beats?creative_id=') ||
        window.location.href.includes('/home/Charts/beats') ||
        window.location.href.includes('/home/expand/song/songId=') ||
        window.location.href.includes('/home/Charts/videos') ||
        window.location.href.includes('/home/Charts/playlists')
      ) {
        HISTORY.push('/Welcome');
        openNotification('Please login to continue', 'Error');
      } else {
        if (
          window.location.href.includes('/Welcome') ||
          window.location.href.includes('/AccountType') ||
          window.location.href.includes('/Creative') ||
          window.location.href.includes('/Listener') ||
          window.location.href.includes('/ListenerProfile') ||
          window.location.href.includes('/CreativeProfile') ||
          window.location.href.includes('/ForCreative') ||
          window.location.href.includes('/ForListener') ||
          window.location.href.includes('/TermAndConditions') ||
          window.location.href.includes('/Otp') ||
          window.location.href.includes('/reset-password') ||
          window.location.href.includes('/otp-verify') ||
          window.location.href.includes('/forgot-password')

        ) {
          let position = window.location.href.split('/').length;

          HISTORY.push(`${window.location.href.split('/')[position - 1]}`);
        } else {
          HISTORY.push('/home');
        }
      }
      //}
      // window.location.assign("/home")
    }

    //function getMobileOperatingSystem() {
    // alert('starting');
    // if (/windows phone/i.test(userAgent)) {
    //     return "Windows Phone";
    // }

    // if (/android/i.test(userAgent)) {
    //    alert('opened in android');
    //     return "Android";
    // }

    //return "unknown";
    //}
  }
  // componentDidMount() {

  // }

  componentDidUpdate() {
    if (typeof window != 'undefined') {
      const token = localStorage.getItem('token')
      window.addEventListener('storage', () => {
        this.setState({ auth: true });
        window.location.reload();
        window.location.assign('/home');
        // window.location.replace("/home")
      });
    }
  }



   componentWillUnmount = () => {
    console.log(">>>>triggered")
    window.removeEventListener('storage', null);
    localStorage.clear();
    removeStorageData("playerCount")
    removeStorageData("trackQueue")
  };

  setEndingTime = (time) => {
    this.setState({
      endingTime: time
    })
  }
  setCurrentTime = (time) => {
    this.setState({
      currentTime: time
    })
  }
  handleClickOpen = () => {
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('user_type');
    const textOfDialog = userType === 'listener' && token ? '3' : '2';
    // this.setState({
    //   openLogin: true,
    //   dialogText: token && userType !== 'listener' ? '1' : textOfDialog
    // });
    HISTORY.push('/Welcome')
  };

  handlePlaylistInnerFunctionOne = name => {
    this.setState({ isPlaylist: false, checkType: name, alertPopup: true });
    this.handleClickOpen();
  };
  handleRedirectPlaylist = (id) => {
    const user_id = localStorage.getItem("loggedIn_userId");
    const token = localStorage.getItem("token")
    const userType = localStorage.getItem("user_type")
    const subscriptionPlan = localStorage.getItem("subscription_plans")
    // token && userType !== "listener" &&  from both if and else-if
    if (id?.attributes?.artist_id == user_id || userType.includes("listener") && token && subscriptionPlan === "Listener+" && id?.attributes?.artist_id == user_id) {
      this.props.history.push(`/home/self-view/${id.id}`)
      localStorage.setItem("playlist_type", "Private")
    } else if (token && id?.attributes?.artist_id !== user_id || userType.includes("listener") && token && subscriptionPlan === "Listener+" && id?.attributes?.artist_id !== user_id) {
      this.props.history.push(`/home/playlist/public-view/${id.id}`)
      localStorage.setItem("playlist_type", "Public")
    } else if (!token) {
      // this.setState({ open: true, dialogText: "2" })
      HISTORY.push('/Welcome')
    } else {

      this.setState({ isPlaylist: false, alertPopup: true })
      this.handleClickOpen()
    }

  }

  handlePlaylistSubfunction = (data, name, arr, index) => {

    const token = localStorage.getItem('token');
    const subscriptionPlan = localStorage.getItem('subscription_plans');

    const userType = localStorage.getItem('user_type');
    // if (token && userType !== 'listener') {
    //   this.setState({
    //     isPlaylist: true,
    //     playlistData: data,
    //     checkType: name,
    //     selectedPlayList: arr,
    //     songIndex: index
    //   });
    //   let obj = {
    //     isPlaylist: true,
    //     playlistData: data,
    //     checkType: name,
    //     selectedPlayList: arr,
    //     songIndex: index
    //   }
    //   localStorage.setItem("audioplayer", JSON.stringify(obj));
    //   localStorage.setItem("nextUpSongs", JSON.stringify(arr));
    //   this.setState({nextUplist: arr});
    // } else if (userType === 'listener' && token) {
    //   this.handlePlaylistInnerFunctionOne(name);
    // } else {
    //   this.handlePlaylistInnerFunctionOne(name);
    // }
    if (token) {
      this.setState({
        isPlaylist: true,
        playlistData: data,
        checkType: name,
        selectedPlayList: arr,
        songIndex: index
      });
      let obj = {
        isPlaylist: true,
        playlistData: data,
        checkType: name,
        selectedPlayList: arr,
        songIndex: index
      }
      localStorage.setItem("audioplayer", JSON.stringify(obj));
      localStorage.setItem("selectedAudioPlayer", JSON.stringify(obj))
      localStorage.setItem("nextUpSongs", JSON.stringify(arr));
      this.setState({ nextUplist: arr });
    } else {
      this.handlePlaylistInnerFunctionOne(name);
    }
  };
  handlePlayList = (data, index, arr, name, id) => {
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('user_type');
    if ((name == 'playlist') || (name == 'Playlist')) {
      localStorage.setItem('queuePlaylistSongs', JSON.stringify(arr))
    } else if (arr.type == ('playlist' || 'Playlist')) {
      localStorage.setItem('queuePlaylistSongs', JSON.stringify(arr?.attributes?.playlist_items?.data))
      localStorage.setItem('queuePlaylistName', JSON.stringify(arr?.attributes?.name))
    } else {
      localStorage.setItem('queuePlaylistSongs', JSON.stringify([]))
      localStorage.setItem('queuePlaylistName', JSON.stringify(''))
    }

    if (
      name == 'Beats' ||
      name == 'Hooks' ||
      name == 'Verses' ||
      name == 'beats' ||
      name == 'hooks' ||
      name == 'verses'
    ) {

      this.handlePlaylistSubfunction(data, name, arr, index);
    } else if ((name === 'Songs') || (name === 'songs')) {
      this.setState({
        isPlaylist: true,
        playlistData: data,
        checkType: name,
        selectedPlayList: arr,
        songIndex: index
      });
      let obj = {
        isPlaylist: true,
        playlistData: data,
        checkType: name,
        selectedPlayList: arr,
        songIndex: index
      }
      localStorage.setItem("audioplayer", JSON.stringify(obj));
      localStorage.setItem("selectedAudioPlayer", JSON.stringify(obj))
      localStorage.setItem("nextUpSongs", JSON.stringify(arr));
      this.setState({ nextUplist: arr });
    } else if ((name === 'Videos' && token) || (name === 'videos' && token)) {
      if (userType !== 'listener' || userType === 'listener') {
        this.setState({
          isPlaylist: true,
          playlistData: data,
          checkType: name,
          selectedPlayList: arr,
          songIndex: index
        });
        let obj = {
          isPlaylist: true,
          playlistData: data,
          checkType: name,
          selectedPlayList: arr,
          songIndex: index
        }
        localStorage.setItem("audioplayer", JSON.stringify(obj));
        localStorage.setItem("selectedAudioPlayer", JSON.stringify(obj))
        localStorage.setItem("nextUpSongs", JSON.stringify(arr));
        this.setState({ nextUplist: arr });
      } else {
        this.handlePlaylistInnerFunctionOne(name);
      }


    } else if (name === 'Playlists') {
      this.handleRedirectPlaylist(data)
    }

    else {
      this.setState({
        isPlaylist: true,
        playlistData: data,
        checkType: name,
        selectedPlayList: arr,
        songIndex: index
      });
      let obj = {
        isPlaylist: true,
        playlistData: data,
        checkType: name,
        selectedPlayList: arr,
        songIndex: index
      }
      localStorage.setItem("audioplayer", JSON.stringify(obj));
      localStorage.setItem("selectedAudioPlayer", JSON.stringify(obj))
      localStorage.setItem("nextUpSongs", JSON.stringify(arr));
      this.setState({ nextUplist: arr });

    }
  };

  setAudioHandle = data => {
    this.setState({ handleAudio: data });
  };

  handleLoginClose = () => {
    this.setState({ openLogin: false });
  };

  setIsPlaylistHandle = () => {
    this.setState({ isPlaylist: false });
  };

  setPlaylistForQueue = (playlist) => {
    this.setState({ playlistQueue: playlist })
  }

  setRepostSong = (song) => {
    this.setState({ repostSong: song })
  }

  addSongToQueue = (song) => {
    const localQueueSongs = localStorage.getItem('queueSongs')
    if (song.type == ('playlist' || 'Playlist')) {
      if (localQueueSongs == null) {
        let songs = []
        song?.attributes.playlist_items.data.map((item) => {
          const notInArray = songs.every(obj => {
            return obj.id !== song.id;
          });

          if (notInArray) {
            songs.push(item)
            this.state.contextQueueSongs.push(item)
          }
        })
        localStorage.setItem('queueSongs', JSON.stringify(songs))
        openNotification('Songs Added to Queue', 'Success')
      } else {
        let songs = JSON.parse(localQueueSongs)
        song?.attributes.playlist_items.data.map((item) => {
          const notInArray = songs.every(obj => {
            return obj.id !== song.id;
          });

          if (notInArray) {
            songs.push(item)
            this.state.contextQueueSongs.push(item)
          }
        })
        localStorage.setItem('queueSongs', JSON.stringify(songs))
        openNotification('Songs Added to Queue', 'Success')
      }
    } else if (song?.attributes?.track_file != '') {
      const localQueueSongs = localStorage.getItem('queueSongs')
      if (localQueueSongs) {
        let songs = JSON.parse(localQueueSongs)
        if (songs.length > 0) {
          const notInArray = songs.every(obj => {
            return obj.id !== song.id;
          });

          if (notInArray) {
            songs.push(song)
            this.state.contextQueueSongs.push(song)
            localStorage.setItem('queueSongs', JSON.stringify(songs))
            openNotification('Song Added to Queue', 'Success')
          } else {
            openNotification('The song is already in the Queue', 'Error');
          }
        }
        else {
          songs.push(song)
          this.state.contextQueueSongs.push(song)
          localStorage.setItem('queueSongs', JSON.stringify(songs))
        }
      }
      else {
        this.state.queueSongs.push(song)
        this.state.contextQueueSongs.push(song)
        localStorage.setItem('queueSongs', JSON.stringify(this.state.queueSongs))
        openNotification('Song Added to Queue', 'Success')
      }
    }
  }

  setAfterRefreshQueueList = (list) => {
    this.setState({ contextQueueSongs: list })
  }

  setQueuePlaylistSongs = (songs) => {
    if (songs.length > 0) {
      this.setState({ playlistQueueSongs: songs })
    } else {
      this.setState({ playlistQueueSongs: [] })
    }
  }

  setNextPlaylistTrackFunction = (track) => {
    this.setState({ nextPlaylistSong: track })
  }

  setRemoveNowPlayingSong = (song) => {
    this.setState({ removeNowPlayingSong: song })
  }

  setNextUplist = (list) => {
    this.setState({ nextUplist: list })
  }

  setNextSongForPlay = (song) => {
    this.setState({ nextSongForPlay: song })
  }

  removeAudioPlayer=() => {
    this.setState({ isPlaylist: false, handleAudio: 0 })
    localStorage.removeItem("audioplayer");
    localStorage.removeItem("selectedSong")
  }

  render() {
    // console.log(this.state.endingTime,this.state.currentTime,"ttttttiiiiimmmmmmmmeeeeeee")

    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    if (!this.state.auth) {
      return (
        <View
          style={{

            // maxWidth: '100%',
            // overflowX:'hidden'

          }}
        >
          {/* <TopNav /> */}
          {/* data={this.data} setState={(data) => {this.setState({data: data})}} */}
          <AudioContextApi.Provider
            value={{
              
              handlePlayList: this.handlePlayList,
              handleAudioVideo: this.state.handleAudio,
              setHandleAudioVideo: this.setAudioHandle,
              setIsPlaylistHandle: this.setIsPlaylistHandle,
              playlistData: this.state.playlistData,
              isPlaylist: this.state.isPlaylist,
              setPlaylistForQueue: this.setPlaylistForQueue,
              repostSong: this.state.repostSong,
              setRepostSong: this.setRepostSong,
              audioLoader: this.state.audioLoader,
              addSongToQueue: this.addSongToQueue,
              queueSongs: this.state.contextQueueSongs,
              setAfterRefreshQueueList: this.setAfterRefreshQueueList,
              playlistQueueSongs: this.state.playlistQueueSongs,
              setQueuePlaylistSongs: this.setQueuePlaylistSongs,
              nextPlaylistSong: this.state.nextPlaylistSong,
              endingTime: this.state.endingTime,
              currentTime: this.state.currentTime,
              setRemoveNowPlayingSong: this.setRemoveNowPlayingSong,
              removeNowPlayingSong: this.state.removeNowPlayingSong,
              setNextUplist: this.setNextUplist,
              nextUplist: this.state.nextUplist,
              setNextSongForPlay: this.setNextSongForPlay,
              nextSongForPlay: this.state.nextSongForPlay,
              removeAudioPlayer:this.removeAudioPlayer
            }}
          >

            {WebRoutesGenerator(routeMap)}
            <ModalContainer />

            {this.state.isPlaylist &&
              // && this.state.data && 
              (
                <div
                  style={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    zIndex: 99999
                  }}
                >
                  <CustomAudioPlayer
                    setEndingTimeApp={() => { }}  //{this.setEndingTime}
                    setCurrentTimeApp={() => { }} //{this.setCurrentTime}
                    playlistData={this.state.playlistData}
                    setPlaylistData={value =>
                      this.setState({ playlistData: value })
                    }
                    playList={this.state.selectedPlayList}
                    checkType={this.state.checkType}
                    handleAudioVideo={this.state.handleAudio}
                    setHandleAudioVideo={value => {
                      this.setState({ handleAudio: value });
                    }}
                    removeAudioPlayer={this.removeAudioPlayer}
                    //search_songs="search"
                    playlistQueue={this.state.playlistQueue}
                    audioLoader={this.state.audioLoader}
                    setAudioLoader={value => {
                      this.setState({ audioLoader: value });
                    }}
                    contextQueueSongs={this.state.contextQueueSongs}
                    setNextPlaylistTrackFunction={this.setNextPlaylistTrackFunction}
                    playlistQueueSongs={this.state.playlistQueueSongs}
                    removeNowPlayingSong={this.state.removeNowPlayingSong}

                  />
                </div>
              )}

            {this.state.openLogin && (
              <LoginAlert
                open={this.state.openLogin}
                handleClose={this.handleLoginClose}
                dialogText={this.state.dialogText}
              />
            )}
          </AudioContextApi.Provider>
        </View>
      );
    } else {
      return (
        <View
          style={{
            //  minHeight: '100vh',
            height: '100vh',
            width: '100vw'
          }}
        >
          {/* <TopNav /> */}

          {WebRoutesGenerator(withoutLoginScreens)}
          <ModalContainer />
        </View>
      );
    }
  }
}

export default withRouter(App);
