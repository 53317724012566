// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";


export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    otpValue: string;
    apidata: any
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VerifyDealController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createuserdealId: string = ""
    createaudiovideodealId: string = ""
    sendotpId: string = ""
   
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            otpValue: '',
            apidata: {}
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    verifyOtpAndContinue = async () => {
        
        const splitType = await getStorageData('splitType')
        this.checkaudiovdeiodeal(splitType)

    }
    checkaudiovdeiodeal = (splitType: string) => {
        if (splitType === "user") {

            this.createuserdeal();
        } else {

            const videoid: string | null = localStorage.getItem("selectedSingleVideo")
            const videotIdParsed = videoid && JSON.parse(videoid)
            const storedValue: string | null = localStorage.getItem("selectedAudio");
            const audioid = storedValue && JSON.parse(storedValue)

            if (splitType == "audio" && audioid != null) {
               this.apicaling(audioid)
            }else if (splitType == "video" && videotIdParsed != null){
               this.apicaling(videotIdParsed)
            } 

        }

    }

    apicaling = (id: any) => {
        this.createaudiovideodeal(id)
    }  
    userdealapiResponse=(responseJson:any)=>{
        if (responseJson != null) {
            this.handleuserResponse(responseJson)
           
        }
    }

    onBackClick = () => {
        HISTORY.goBack();
    }
    handleuserResponse=(responseJson:any)=>{
        if (!responseJson.error) {
            if (responseJson.hasOwnProperty("message")) {
                this.setState({ loading: false })
                openNotification(responseJson?.message || responseJson?.error, 'Error')
            } else {
                if (responseJson?.data && Object.keys(responseJson?.data).length !== 0) {
                    HISTORY.push({
                        pathname: `/deal/offer-sent`,
                        state: {}
                    });
                }
            }
        }
        else {
            openNotification(responseJson?.error, 'Error')

        }
    }
    resendOTP = () => {

        this.sendotp()

    }

    handleOtpChange = (otp: string) => {
        this.setState({
            otpValue: otp
        })
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const splitType = await getStorageData('splitType')
        const dealtypeid = await getStorageData('dealtypeid')
        const userid = localStorage.getItem("user_id")
        const splitid = await getStorageData("trackid")
        const percentage = await getStorageData('selectedPercentage')
        const amount = await getStorageData('dealAmount')
        const term = await getStorageData('selectedTerm')
        const interval = `${term.duration} ${term?.type?.toLowerCase()}${term?.type == "Year" ? "" : 's'}`
        this.setState({
            apidata: { dealtypeid, userid, splitid, percentage, amount, term, interval }
        })
        if (!splitType) {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.createuserdealId) {
                this.userdealapiResponse(responseJson)
            }
            if (apiRequestCallId === this.createaudiovideodealId) {
                this.userdealapiResponse(responseJson)
            }
            if (apiRequestCallId === this.sendotpId) {
                if (responseJson?.message === 'OTP sent successfully') {
                    openNotification(responseJson?.message, "success")
                }
                else {
                    openNotification(responseJson?.message, 'Error')
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    createuserdeal = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': localStorage.getItem('token')
        };

        const data = {
            deal_type_id: this.state.apidata?.dealtypeid,  // pass here deal type
            owner_id: this.state.apidata?.userid,// pass here user id
            user_track_id: this.state.apidata?.splitid,  // pass here split id
            deal_percentage: this.state.apidata?.percentage, // pass - 'half' or 'full'
            interval: this.state.apidata?.interval,
            amount: this.state.apidata?.amount,
            otp: this.state.otpValue
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createuserdealId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_subscription_plan/create_user_deal"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    createaudiovideodeal = async (numberid: number) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': localStorage.getItem('token')
        };
        const data = {
            deal_type_id: this.state.apidata?.dealtypeid,
            add_track_id: numberid,// pass here user id
            user_track_id: this.state.apidata?.splitid,  // pass here split id
            deal_percentage: this.state.apidata?.percentage, // pass - 'half' or 'full'
            interval: this.state.apidata?.interval,
            amount: this.state.apidata?.amount,
            otp: this.state.otpValue
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createaudiovideodealId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_subscription_plan/deals"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);
        

    }
    sendotp = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': localStorage.getItem('token')
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendotpId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/resend_otp"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );



        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

  
    // Customizable Area End
}
