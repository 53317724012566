// Customizable Area Start
import { Input, Row } from "antd";
import { Box, Button, ButtonGroup, Hidden, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import {styled } from "@material-ui/styles";
import TracksController from "./TracksController.web";
import { searchshape } from "../../../../dashboard/src/assets";
import TrackDetails from "../TrackDetails.web";
import {  nxzloader } from "blocks/dashboard/src/assets";
import RightSideTrackDetails from "blocks/ReviewAndApproval/src/CTRL/Tracks/RightSideTrackDetails.web";
import CtrlFilterPopup from '../Common/CtrlFilterPopup.web'
import Mobiletracks from "./Mobiletracks.web";
const MainTrackWrapper = styled(Box)({
  display: "flex",
  padding: "0px 50px 0px 27px",

  gap: "1%",
  justifyContent: "space-between",
  marginTop: "15px",
  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 27px",
    gap: "0%",
  },
});
const BeatTrackWrapper = styled(Box)({
  display: "flex",
  padding: "0px 50px 0px 27px",
  gap: "1%",
  // height:"calc(100% - 5rem)",
  minHeight:"calc(100% - 4rem)",
  justifyContent: "space-between",
  marginTop: "15px",
  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 13px",
    gap: "0%",
    minHeight:"0px"
  },
});

const Trackleftsidecontainer = styled(Box)({
  width: "46%",
  display: "flex",
  height: "max-content",
  // height:"100%",
  flexDirection: "column" as "column",
  gap: "20px",
  marginBottom: "10px",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    gap: "20px",
    marginTop: "12px",
  },
});

const TotalSplits = styled(Box)({
  width: "100%",
  color: "white",
  "@media(max-width: 1024px)": {
    height: "auto",
  },
});



const LeftSideSingletrackbox = styled(Box)({
  // marginTop: '6px',
  height: "95px",
  padding: "10px",
  borderRadius: "8px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  objectFit: "contain",

  // background:"red",
  color: "white",
  "&:hover": {
    //   marginTop: '12px',
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    objectFit: "contain",
    background: "#141414",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
    // alignItems:"center"
  },
});


const Trackname = styled(Box)({
  fontSize: "22px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "20px",
  letterSpacing: "normal",
  whiteSpace: "nowrap" as "nowrap",
  color: "#ffffff",
  fontWeight: 700,
  fontFamily: "Inter",
  "@media(max-width: 400px)": {
    // whiteSpace: "unset",
    fontSize:"18px"
  },
});

const Tracksinputboxwrapper = styled(Box)({
  borderRadius: "27px",
  padding: "10px",
  width: "100%",
  background: "#212121",
  position: "relative",
  "& .ant-input": {
    backgroundColor: "#212121",
    color: "white",
    width: "88%",
  },
  "& .ant-input-affix-wrapper": {
    background: "none",
    border: "unset",
  },
  "@media(max-width: 1024px)": {
    width: "100%",
    padding: "10px",
    "& .ant-input": {
      width: "50%",
    },
  },
});
const LeftsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  // height: "693px",
  scrollbarWidth:"none",
  maxHeight:"693px",
  marginTop: "-20px",
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});
const StatsTab = styled(Box)({
  fontSize: "16px",
  fontWeight: 800,
  lineHeight: "1.36",
  color: "#ffff",
  borderRadius: "35px",
  textTransform: "capitalize",
  fontFamily: "Inter",
  width: "225px",

  padding: "6px",
  display: "flex",
  justifyContent: "center",
  cursor: "pointer",
  "@media(max-width: 1704px)": {
    width: "100px",
  },
  "@media(max-width: 1014px)": {
    width: "150px",
  },
  "@media(max-width: 600px)": {
    width: "137px",
  },
  "@media(max-width: 460px)": {
    width: "100px",
  },
});
const Borderbox = styled(Box)({
 
  "@media(max-width: 1024px)": {
    display: "none",
  },
});
const LeftsideAlltracksbox = styled(Box)({
  width: "100%",
  // height:"80vh",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
});

export default class Tracks extends TracksController {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {
      searchText,
      selectedTab,
      trackList,
      index,
      top40trackList,
      loading
    } = this.state;
    const { topPlayMusic,getStatusDetails } = this.props;

    return (
      //Merge Engine DefaultContainer
     <>
    <Hidden only={["xs"]}> {loading ? (
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
      <BeatTrackWrapper>
        <Trackleftsidecontainer id="yourTopDivId1">
           <ButtonGroup
                      color="primary"
                      className="outer-div-toggle btnGroup"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        className="audio-button-playlist"
                        data-test-id="cities"
                        value="audio"
                        
                        variant="contained"
                        disabled={this.state.threebtnToogle === "beats"}
                        onClick={() => {
                          this.getAllSplits("beat");
                          this.changetabs("beats");
                        }}
                      >
                        <span className="audio-text">Beats</span>
                      </Button>
                      <Button
                        variant="contained"
                        data-test-id="listeners"
                        className="video-button-playlist"
                        onClick={() => {
                          this.getAllSplits("hook");
                          this.changetabs("hooks");
                        }}
                        disabled={this.state.threebtnToogle === "hooks"}
                      >
                        <span className="audio-text">Hooks</span>
                      </Button>

                      <Button
                        variant="contained"
                        data-test-id="states"
                        className="versesButtonPlaylist"
                        onClick={() => {
                          this.getAllSplits("verse");
      
                          this.changetabs("verses");
                        }}
                        disabled={this.state.threebtnToogle === "verses"}
                      >
                        <span className="audio-text">Verses</span>
                      </Button>
                    </ButtonGroup>
          <Tracksinputboxwrapper >
            <Input
              test-id="test_search_term_id_handle_search_input"
              className="check123"
              onChange={(text) => this.handleTrackSearch(text)}
              value={searchText}
              prefix={
                //@ts-ignore
                <SearchOutlined translate className=" text_white1" />
              }
              placeholder={`Search ${this.state.threebtnToogle}`}
            />
            <img src={searchshape} style={webStyle.searchimage}
            onClick={()=>{
              this.handleOpenFilterPopup()}}
              data-test-id="openFilterpopup"
            />
          </Tracksinputboxwrapper>
          <TotalSplits>
            <Typography style={webStyle.trackname}>
              <span style={webStyle.bluetext}>{trackList?.length}</span> {index}
            </Typography>
          </TotalSplits>
          <LeftsideAlltracksbox>
            <LeftsideAlltracksboxwrapper>
              {trackList?.length > 0 ? (
                trackList.map((item: any, index: any) => {
                  const { typeColor, status } = getStatusDetails(item,"tracks");

                  return (
                    <LeftSideSingletrackbox
                      key={index}
                      onClick={this.selectedsong.bind(this, index, item.id, item?.attributes?.is_drafted)}
                      style={
                        index === this.state.selectedIndex
                          ? webStyle.leftSideSingletrackbox
                          : {}
                      }
                      id="yourTopDivId"
                    >
                      <Box style={webStyle.singleArtinfo}>
                        <img
                          src={item.attributes?.art_work}
                          style={webStyle.leftimage}
                          onClick={() => {
                            topPlayMusic(item, index, trackList, "song");
                          }}
                        />

                        <Box style={webStyle.leftsideownerstats}>
                          <Trackname className="tracks_songs_text">
                            {item.attributes.title}
                          </Trackname>
                          <span style={webStyle.splittext}>
                            {item.attributes.user_role} 
                            
                          </span>
                        </Box>
                      </Box>
                      <Box style={webStyle.leftsideownerstats}>
                        <Box>
                          <Button
                            style={{
                              border: `1px solid ${typeColor}`,
                              borderRadius: "20px",
                              color: typeColor,
                              height: "23px",
                              textTransform: "capitalize",
                            }}
                          >
                            {status}
                          </Button>
                        </Box>
                      </Box>
                    </LeftSideSingletrackbox>
                  );
                })
              ) : (
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#fff",
                    padding: "5",
                    justifyContent: "center",
                    marginTop:"10%",
                   
                  }}
                >
                  Data Not Found.
                </Typography>
              )}
            </LeftsideAlltracksboxwrapper>
          </LeftsideAlltracksbox>
        </Trackleftsidecontainer>
        <Borderbox>
          {" "}
          <hr
            style={{
              height: "100%",
              width: "1px",
              border: "1px solid #212121",
            }}
          />
        </Borderbox>

       <RightSideTrackDetails 
        showDetails={this.state.showdetails}
        trackDetailsId={this.state.trackDetailsId}
        getAllSplits={this.getAllSplits}
        index={this.state.index} 
        top40trackList={top40trackList}
        topPlayMusic={topPlayMusic}
        setShowDetails={()=>this.setState({showdetails:false})}

        />
      </BeatTrackWrapper>
      )}
      <CtrlFilterPopup
      open={this.state.openFilterPopup}
      handleClose={this.handleCloseFilterPopup}
      currentTab="Tracks"
      handleTrack={this.handleTrackList}
      />
      </Hidden> 
      <Hidden only={["lg",'md','sm','xl']}>
        <Mobiletracks key={""} navigation={undefined} id={""} topPlayMusic={topPlayMusic} getStatusDetails={getStatusDetails}/>
      </Hidden>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

const webStyle = {
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
    marginTop: "-20px",
  },
  leftimage:{
    borderRadius: "10px",
    width:"75px",
    minWidth:"75px",
    objectFit:"cover" as "cover",
    height:"75px",
   
  },
  audioimagetext: {
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    marginTop: "8px",
  },

  audioartistext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
  },

  singleArtinfo: {
    display: "flex",
    gap: "15px",
    justifyContent: "center",
    alignItems: "center",
  },

  leftsideAlltracksbox: {
    width: "85%",
    // height: "80vh",
  },

  searchimage: {
    height: "18px",
    width: "21px",
    position: "absolute" as "absolute",
    top: "31%",
    right: "6%",
    cursor:"pointer",
    zIndex:10
  },

  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    fontStyle: "normal",
    lineHeight: "1.33",
    color: "#ffffff",
    fontFamily: "Inter",
  },
  statstab: {
    fontSize: "16px",
    textTransform: "capitalize" as "capitalize",
    fontFamily: "Inter",
    width: "225px",
    fontWeight: 800,
    lineHeight: "1.36",
    color: "#ffff",
    borderRadius: "35px",
  },
  statstabActive: {
    background: "#3959F9",
  },

  tracksbutton: {
    borderRadius: "20px",
    justifyContent: "space-between",
    padding: "6px",
    backgroundColor: "#212121",
    display: "flex",
  },

  singlebutton: {
    border: "1px solid blue",
    borderRadius: "20px",
    color: "blue",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },

  songsinlgebutton: {
    border: "1px solid red",
    color: "red",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "20px",
  },

  bluetext: {
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
  },

  splittext: {
    fontSize: "16px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontFamily: "Inter",
  },

  leftSideSingletrackbox: {
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
    color: "white",
    background: "#141414",
  },
  rightSideSingletrackbox: {
    marginTop: "12px",
    justifyContent: "space-between",
    alignitems: "center",
    color: "white",
    background: "#141414",
    height: "54px",
    padding: "0px 14px 0px 5px",
    borderRadius: "8px",
    display: "flex",
   
  },

  tracksimage: {
    height: "148px",
    width:"100%",
    // width: "148px",
    // height: "148px",
    // width: "148px",
    borderRadius: "12px",
  },
  leftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
  },
};
// Customizable Area End
