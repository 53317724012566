// Customizable Area Start
import { Layout } from 'antd';
import { Grid, Hidden, TextField } from '@material-ui/core';
import {
  withStyles,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import React ,{ useContext } from 'react';
import { Content } from 'antd/lib/layout/layout';
import './UpdateCard.css';
import UserCardAndCurrentPlan from './UserCardAndCurrentPlan.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import { AudioContextApi } from '../../../dashboard/src/AudioContext/AudioContext.web';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    input: {
      color: 'white',
    },
  })
);

const CssTextField = withStyles({
  root: {
    // '.Mui-focused': {
    //   // color: 'white',
    //   border: 'none',
    // },
    '& label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
      borderBottom: 'none!important',
      fontFmaily: "Inter",

    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField);

const UpdateCard = () => {
  const classes = useStyles();
  const { isPlaylist} = useContext(AudioContextApi);

  return (
    <>
      <Layout className='myPlanLayout'>
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
        <Content className='myPlanContent'>
          <HomeHeader />

          {/* {!loading ? (
            <Row className='spinloadder'>
              <Col>
                <Spin size='large' />
              </Col>
            </Row>
          ) : (
            <> */}
          <div style={{ margin: '10px 0px 15px 20px' }}>
            <Grid container className={isPlaylist?'audio_planContainer':'myplancontainer'}>
              <Grid xs={12} container spacing={3} className='PlanHeight'>
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    // spacing={3}
                    className='updateCard_Container'>
                    <Grid item xs={12} className='card_title'>
                      <span className='new_card'>New Card</span>
                      <span className='details'>Details</span>
                    </Grid>
                    <Grid item xs={12}>
                      <form action='' style={{ margin: '60px 0px' }}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className='card_textAlign textAlign_end'>
                            <CssTextField
                              variant='outlined'
                              id='custom-css-outlined-input'
                              label='First Name'
                              style={{ width: '70%' }}
                              focused
                              type='text'
                              //   style={{ color: 'white' }}
                              InputProps={{ className: classes.input }}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className='card_textAlign textAlign_start'>
                            <CssTextField
                              variant='outlined'
                              id='custom-css-outlined-input'
                              label='Last Name'
                              style={{ width: '70%' }}
                              focused
                              type='text'
                              //   style={{ color: 'white' }}
                              InputProps={{ className: classes.input }}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className='card_textAlign'>
                            <CssTextField
                              variant='outlined'
                              id='custom-css-outlined-input'
                              label='Card Number'
                              style={{ width: '70%' }}
                              focused
                              type='number'
                              //   style={{ color: 'white' }}
                              InputProps={{ className: classes.input }}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className='card_textAlign textAlign_end'>
                            <CssTextField
                              variant='outlined'
                              id='custom-css-outlined-input'
                              label='Expiration'
                              style={{ width: '70%' }}
                              focused
                              type='text'
                              //   style={{ color: 'white' }}
                              InputProps={{ className: classes.input }}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className='card_textAlign textAlign_start'>
                            <CssTextField
                              variant='outlined'
                              type='number'
                              id='custom-css-outlined-input'
                              label='3 Digit Code'
                              style={{ width: '70%' }}
                              focused
                              //   style={{ color: 'white' }}
                              InputProps={{ className: classes.input }}
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 3);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                    <Grid item xs={12} className='save_card'>
                      <button>Save Card</button>
                    </Grid>
                    {/* <div className={classes.root}>
                            <CssTextField
                              variant='outlined'
                              id='custom-css-outlined-input'
                              label='Customm CSS'
                              //   focused
                              //   style={{ color: 'white' }}
                              InputProps={{ className: classes.input }}
                            />
                          </div> */}
                  </Grid>
                </Grid>

                <UserCardAndCurrentPlan />
              </Grid>
            </Grid>
          </div>
          {/* </>
          )} */}
        </Content>
      </Layout>
    </>
  );
};

export default UpdateCard;
// Customizable Area End