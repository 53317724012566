// Customizable Area Start
import React from 'react'
import { Grid, Box, Hidden } from '@material-ui/core';
import "./Index.web.css"
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import { nxzsoundLogo } from '../../assets';
import { useHistory } from 'react-router-dom';



const MPlanCanceled = () => {


    const history = useHistory();


    const redirectHandler = async () => {
        const temp_id: any = await localStorage.getItem('temp_id');
        const userId: any = await localStorage.getItem('user_id');

        history.push({
            pathname: `/creatives/beats?creative_id=${temp_id ? temp_id : userId}`,
        });

        localStorage.removeItem('temp_id');
    };


    return (
        <>
            <Hidden only={["sm", "md", "lg", "xl"]}>
                <Layout className='myPlanLayout'>
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content className='myPlanContent'>
                        <HomeHeader />

                        <div style={{ margin: '10px 0px 15px 20px' }}>
                            <Grid container className='myplancontainer'>
                                <Grid xs={12} container spacing={3} className=''>

                                    <Grid xs={12} item
                                        style={{
                                            display: 'flex',
                                            // flexDirection: 'row',
                                            // justifyContent: 'center',
                                            // alignItems: 'center',
                                            width: '100%',
                                        }}>
                                        <Grid item xs={12} className='m_paper_back'>
                                            <Grid item xs={12} className='m_plan_update'>
                                                <img src={nxzsoundLogo} alt='nxzsoundLogo' style={{ width: "130px", height: "130px", marginBottom: "40px" }} />
                                                <span className='m_plan_update_font' style={{ marginBottom: "10px" }}>Plan Canceled</span>
                                                <Box className='m_plan_description' style={{ marginBottom: "70px" }}>
                                                    <span className='m_fontWeight'>
                                                        Your account has been switched to the limited free plan.
                                                    </span>
                                                </Box>
                                            </Grid>
                                            <div className='m_plan_update_btn'>
                                                <button
                                                    className='m_plan_btn'
                                                    onClick={() => redirectHandler()}
                                                    style={{ backgroundColor: '#3959f9', margin: "30px 0px 15px" }}>
                                                    Visit Profile
                                                </button>

                                                <button
                                                    className='m_plan_btn'
                                                    onClick={() => history.push('/planpayments')}
                                                    style={{
                                                        backgroundColor: '#212121',
                                                        color: 'rgba(255, 255, 255, 0.5)',
                                                        marginBottom: "30px"

                                                    }}>
                                                    See My Plan
                                                </button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </div>

                    </Content>

                </Layout>
            </Hidden>
        </>
    )
}

export default MPlanCanceled
// Customizable Area End