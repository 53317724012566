// Customizable Area Start
import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import "./Activity.web.css";
import { defaultProfile } from "../../../../dashboard/src/assets";

function ActivityNotifications({
  get_notification,
  handleReadNotification,
}: any) {

  const wrapContent = (content:any) => {
    return `<div>${content}</div>`;
  };

  const applyAdditionalStyles = (content: any) => {
    return content.replace(/<p([^>]*)>/, '<p$1 class="activity-notification-ellipses">');
  };


  return (
    <>
      <Grid
        container
        // style={{ paddingLeft: "10px" }}
        className="main-scroll notifications_height"
      >
        {get_notification.length > 0 &&
          get_notification.map((notifications: any, index: number) => {
            const modifiedContent = applyAdditionalStyles(
              notifications.attributes.color_content
            );

            return (
              <Grid
                container
                item
                key={index}
                xs={12}
                style={{
                  display: "flex",
                  padding: "10px 0px",
                  alignItems: "center",
                  // cursor:notifications.attributes.user_id?"pointer":"not-allowed"
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleReadNotification(
                    notifications.id,
                    notifications.attributes.user_type,
                    notifications.attributes.type,
                    notifications.attributes.user_id
                  );
                }}
                data-test-id="activityNotificationOnClick"
              >
                <Grid item xs={2}>
                  <img
                    src={notifications.attributes.avatar || defaultProfile}
                    className="notification-img"
                  />
                </Grid>
               {/* <Tooltip placement="top" title={<div dangerouslySetInnerHTML={{ __html: notifications.attributes.color_content }} />}> */}
                <Grid
                  item
                  xs={9}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="activity-notification-ellipses">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: wrapContent(modifiedContent),
                      }}
                    />
                  </div>
                </Grid>
                {/* </Tooltip> */}
                <Grid item xs={1}>
                  {!notifications?.attributes?.is_read && (
                    <div
                      style={{
                        backgroundColor: "#3959f9",
                        height: 10,
                        width: 10,
                        borderRadius: 5,
                        alignSelf: "center",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default ActivityNotifications;
// Customizable Area End
