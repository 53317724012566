// Customizable Area Start
import React, { Component } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

interface Props {
  setCountry: (country: any) => void;
  setStateValue: (state: any) => void;
  setFieldValue: (field: any, value: any) => void;

}

interface SS {
    value: any;
}

class CitySelector extends Component<Props,SS> {

    constructor(props: Props) {
        super(props);
        this.state = {
            value: null 
        };
    }
    
    handleSelect = (place: any) => {
        const selectedPlace = place.label.split(',')

        this.props.setFieldValue('city', selectedPlace[0] )
        if(selectedPlace.length == 3){
            this.props.setCountry(selectedPlace[2])
            this.props.setStateValue(selectedPlace[1])
            this.props.setFieldValue('country', selectedPlace[2] )
            this.props.setFieldValue('state', selectedPlace[1] )
        }else{
            this.props.setCountry(selectedPlace[1])
            this.props.setStateValue("")
            this.props.setFieldValue('country', selectedPlace[1] )
        }

         this.setState({value: selectedPlace[0]})
        
    };

    render() {
        return (
            <GooglePlacesAutocomplete
                apiKey="AIzaSyBPAAZ67lS_QAFq6sSj0a9Ejix3e2PTyHY"
                autocompletionRequest={{
                    types: ['(cities)'],
                   // componentRestrictions: { country: ['us'] } // Optional: limit results to a specific country
                }}
                // @ts-ignore
                onSelect={this.handleSelect}
                //onChange={this.handleSelect}
                selectProps={{
                    className: "css-13cymwt-control",
                    //value: this.state.value || '',
                    defaultValue: this.state.value || '', // Use defaultValue instead of value
                    onChange: this.handleSelect,
                  }}
               //   onSelect={this.handleSelect} // Update the state when a place is selected
            />
        );
    }
}

export default CitySelector;
// Customizable Area End