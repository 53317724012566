// Customizable Area Start
import React from "react";
import "../../assets/listener.css";
import { Row, Form } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface Props{
}

export const PhoneInputProfiles = (props: Props) => {

    return(
        <>
        <Row className="width_100 font_12 gray1" >
           <Row className="fullname_acc">
              Phone
                 <Form.Item
                    label="Phone Number"
                    name="phone"
                    className="account_sub_title"
                    rules={[
                    {
                        required: true,
                        message: "Please Input Your Phone Number!",
                        validator: (_, value) => {
                        const isValidPhone =/^\d{11,}$/.test(value);
                        
                        if (isValidPhone) {
                            return Promise.resolve();
                        }
                        return Promise.reject("Please enter a valid phone number with at least 9 digits and only digits.");
                        },
                    },
                    
                    ]}
                >
                <PhoneInput
                    inputStyle={{ background: 'black', width: '100%', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none', color: 'white' }}
                    buttonStyle={{ background: 'black', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                    dropdownStyle={{ height: '150px', background: 'black', color: 'white' }}
                    searchStyle={{ background: 'black', width: '100%', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none', marginLeft: '-10px', marginTop: "-10px" }}
                    country={'us'}
                    enableSearch
                    disableSearchIcon
                    // value={this.state.phone}
                    onChange={(phone: any) => console.log(phone)}
                />
            </Form.Item>
          </Row>
        </Row>
        </>
    )

}