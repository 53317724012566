// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import React from "react";

export interface Props {
    value:any; // Adjust the type based on your actual data type
    style: {
      plus: React.CSSProperties;
      minustext: React.CSSProperties;
      percentagetext: React.CSSProperties;
    };
}

interface S {
 
}

interface SS {
  id: any;
}

export default class PercentageDisplayController extends BlockComponent<
  Props,
  S,
  SS
> {
  subscriptionPlan = localStorage.getItem("subscription_plans");

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

   
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }
   getPlusMinus = (data: string | undefined) => {
    if (data) {
      if (data.toString().startsWith('-')) {
        return '-';
      } else if (data.toString().startsWith('0.00')) {
        return '';
      } else {
        return '+';
      }
    } else {
      return '';
    }
  };

   getPctTxt = (data: string | undefined) => {
    if (data) {
      if (data.startsWith('-')) {
        return data.replace('-', '');
      }
      return data;
    } else {
      return '';
    }
  };
}
 
// Customizable Area End