// Customizable Area Start
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Creatives/Home.web.css";
import {  withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Box, AppBar, Tabs, Tab, Hidden } from "@material-ui/core";
import {  Row } from "antd";
import Typography from "@material-ui/core/Typography";

import '../Common/Activity-new/Activity.web.css'
import ActivitySelfView from "../Common/Activity-new/ActivitySelfView.web";
import ActivityPublicView from "../Common/Activity-new/ActivityPublicView.web";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import ButtonGroupWeb from "components/src/CustomChart/PopUps/ButtonGroup.web";
export const configJSON = require("../../../../framework/src/config.js");
import LabelController from './LabelController.web'
import { LabelGraph } from "./LabelGraph.web";
import { LabelStats } from './LabelStats.web';
import { TopMobileCreatives } from './LabelTopCreativesMobile.web';
import  LabelTopAudioPlaylistMobile  from './LabelTopAudioPlaylistMobile.web'
import './label.web.css'
import { TopDesktopCreatives } from './LabelCreativesDesktop.web'
import  LabelTopSongs  from './LabelTopSongs.web'

interface TabPanelProps { children?: React.ReactNode; index?: any; value: any; beats?: any; isPlaylist?:any}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, beats,isPlaylist, ...other } = props;
   return (
     <div
       role="tabpanel"
       hidden={value !== index}
       id={`simple-tabpanel-${index}`}
       aria-labelledby={`simple-tab-${index}`}
       {...other}
       className="mostly-customized-scrollbar"
     >
       {value === index && (
         <Box p={3} style={{ height: "100%", overflowX: "clip", position: "relative" }}>
           {children}
         </Box>
       )}
     </div>
   );
  }
  const token: any = localStorage.getItem("token")
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  }
  
  const StyledTabs = withStyles({
    indicator: {
      display: 'none',
  
  
    },
  })((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  interface StyledTabProps {
    label: string;
  }
  
  const StyledTab = withStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: 'none',
        color: '#fff',
        borderRadius: "35px",
        backgroundColor: "#212121",
        padding: "3px 5px ",
        margin: "0px 2px ",
        '&:focus': {
          // opacity: 1,
          backgroundColor: 'red',
        },
      },
    }),
  )((props: StyledTabProps) => <Tab disableRipple {...props} />);

 export const ChartAndStats = (props: any) =>{
  const {labelGraphandStatsData} = props;
   return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={7} style={{height: '100%'}}>
        <LabelGraph labelGraphandStatsData={labelGraphandStatsData}/>
      </Grid>
      <Grid item xs={12} sm={6} md={5} className="custom-grid-item">
        <LabelStats labelGraphandStatsData={labelGraphandStatsData}/>
      </Grid>
    </Grid>
   )
 }
  
  
export default class LabelTabItems extends LabelController {
    showButtonGroup = () => {
        if (this.state.labelValue === 2) {
          return (
            <Grid item md={2} xs={6} lg={2} className="audio-div">
              <ButtonGroupWeb
                musicButton={this.state.musicButton}
                setMusicButtonTrue={() => {
                  this.setState({ musicButton: false })
                }}
                setMusicButton={() => {
                  this.setState({ musicButton: true })
                }} />
            </Grid>
          )
        }
      }

      renderActivityView() {
        const { labelId, get_All_Activity_data, get_notification,labelUserDetails } = this.state;
        const currentUser = localStorage.getItem('temp_id') ? localStorage.getItem('temp_id') : localStorage.getItem('user_id')

        if (!this.state.noActivity) {
            if (currentUser === labelId) {
                return (
                    <ActivitySelfView 
                        get_All_Activity_data={get_All_Activity_data}
                        creativeById={labelUserDetails} 
                        get_notification={get_notification}
                        handleReadNotification={this.notificationsRead}
                        key={1}
                    />
                );
            } else {
                return (
                    <ActivityPublicView 
                        get_All_Activity_data={get_All_Activity_data} 
                        creativeById={labelUserDetails} 
                        key={2}
                    />
                );
            }
        } else {
            return (
                <div style={{color: 'white', display: 'flex', justifyContent: 'center', fontSize: '18px', marginTop: '50px'}}>No Activity Found</div>
            );
        }
    }
    render() {
        const { creativeById, beatList, labeltabName, get_All_Activity_data, get_notification, labelValue, musicButton, TopPlaylists }: any = this.state;   
        const { isPlaylist } = this.context;

        const creativeLoop: any = [1,2,3,4,5]
        return (
    
    
          <div style={{height:"100%"}}>
          <AppBar position="static" 
                   className="listener-header-height" >
              <Grid container xs={12} style={{ display: 'flex' }} >
                <Grid item xs={12} sm={8} lg={this.state.labelValue == 2 ? 9 : 10} xl={this.state.labelValue == 2 ? 9 : 10} >
                  <StyledTabs
                    value={this.state.labelValue} onChange={this.checkLabelCurrentTab} aria-label="nxz-tabs"
                    // className="tabs"
                    // @ts-ignore
                    variant="scrollable"
                    style={{marginLeft: '20px'}}
                  >
                    {labeltabName.map((item: any) => {
                      return (
    
                        <StyledTab label={item.tabName}
                        //@ts-ignore
                         style={{width: '110px'}}
                          //@ts-ignore
                          className={this.state.labelValue === item.value ? "active-tab" : "non-active-tab"}
                          {...a11yProps(item.value)} />
                      ) })}
    
                  </StyledTabs>
                </Grid>
                <Grid style={{ backgroundColor: "black", display: 'flex', justifyContent: 'center' }} item xs={12}  sm={3} lg={2} className= "listener-filter-div" >
                  <Grid style={{ display: "flex", alignItems: "flex-start" }} alignItems="flex-end">
                    <div >
                      {this.showButtonGroup()}
                    </div>
                    <FilterDropDown filterValue={this.state.filterValue}
                    handleFilter={(data: any) => {this.handleFilter(data)}}
                    listener={true}
                    />
                  </Grid>
    
                </Grid>
              </Grid>
            </AppBar>

           
            <TabPanel index={0} value={labelValue}
              beats={get_All_Activity_data?.length}  isPlaylist={isPlaylist}
            >
              <Grid style={{display: 'flex', flexDirection: 'column',height: '45vh'}}>
              <Grid style={{ height: '100%', flexGrow: 1}}>
                <ChartAndStats labelGraphandStatsData={this.state.labelGraphandStatsData}/>
                <Grid style={{height: '100px'}} >
                  {/* <div style={{color: 'white', height: '100%'}}>Hello div</div> */}

                  {/* <Grid style={{display: 'flex',justifyContent: 'space-between'}}>
                     <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '22px', fontWeight: 900}}>Top 100</Grid> 
                  </Grid> */}
                  <Grid style={{ padding: "6px 0px 4px 0px", marginTop: "1%" }} container>
                      <Grid item xs={8}>
                        <Row className=" width_100">
                          <Typography className="top-head creative-top-font">{'Top ' + (this.state.labelTop100Creatives.length >= 1 ? this.state.labelTop100Creatives.length : '0')}</Typography>
                        </Row>
                      </Grid>
                      <Grid item xs={4}
                        style={{ display: "flex" }} className="second-head-percentage" >
                        <Hidden xsDown>
                          <Grid item xs={3}>
                            <p className=" text_white1 top_size_16 hiddnDaysScore " style={{ marginTop: "4px",paddingLeft:"10px" }}>24hr%</p>
                          </Grid>
                          <Grid item xs={4}>
                            <p className="text_white1 top_size_16 sevendayHeadPadding" style={{ marginTop: "4px", textAlign: "start"}}>7d%</p>
                          </Grid>
                          <Grid item xs={5} style={{ textAlign: "center" }} className="hiddnDaysGraph">
                            <p className="text_white1 top_size_16 hiddnDaysGraph" style={{ marginTop: "4px" }}>
                              Last 7d
                            </p>
                          </Grid>


                        </Hidden>
                      </Grid>
                    </Grid>

                  <Grid style={{margin: '5px', height: '30vh'}} className="label-scroll">
                  { this.state.labelTop100Creatives.length > 0 ?(
                    this.state.labelTop100Creatives.map((item: any, index: any) => {
                      return(<div style={{margin: '5px'}}>
                      {/* <TopMobileCreatives /> */}
                      {/* <LabelTopAudioPlaylistMobile /> */}
                      <div className="hide_in_mobile">
                        <TopDesktopCreatives data={item} index={index}/>
                      </div>
                      <div className="hide_in_medium_and_screen">
                        <TopMobileCreatives data={item} index={index}/>
                      </div>
                      
                      </div>)
                    })
                  )
                  : (
                    <div style={{color: 'white',display: 'flex', justifyContent: 'center', fontSize: '18px', marginTop: '50px'}}>No Top Creatives</div>
                    )
                  }
                  </Grid>
                  
                </Grid>
               </Grid>
               </Grid>
               
              
            </TabPanel>
            <TabPanel index={1} value={labelValue}
              beats={get_All_Activity_data?.length}  isPlaylist={isPlaylist}
            >
              <Grid style={{display: 'flex', flexDirection: 'column',height: '45vh'}}>
              <Grid style={{ height: '100%', flexGrow: 1}}>
                <ChartAndStats labelGraphandStatsData={this.state.labelGraphandStatsData}/>
                <Grid style={{height: '100px'}} >
                  <Grid style={{margin: '5px', minHeight: '30vh', paddingTop: '35px'}}>
                  {
                  this.renderActivityView()
                  }
                  </Grid>
                  
                </Grid>
               </Grid>
               </Grid>
              
            </TabPanel>
            <TabPanel value={labelValue} index={2}  beats={this.state.musicButton?this.state.playlistVideo.length: beatList?.length}   isPlaylist={isPlaylist}>
            <Grid style={{display: 'flex', flexDirection: 'column',height: '45vh'}}>
              <Grid style={{height: '100%', flexGrow: 1}}>
                <ChartAndStats labelGraphandStatsData={this.state.labelGraphandStatsData}/>
                <Grid style={{height: '100px'}} >
                  <Grid style={{margin: '5px', overflowY: 'auto', minHeight: '30vh'}}>
                   {/* <Hidden xsDown> */}
                   <div className="hide_in_mobile">
                     <LabelTopSongs musicButton={musicButton} labelId = {this.state.labelId} apiFilterValue={this.state.apiFilterValue}/>
                   </div>
                   {/* </Hidden> */}
                   {/* <Hidden smUp> */}
                   <div className="hide_in_medium_and_screen">
                   <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '22px', fontWeight: 900}}>{'Top' + (TopPlaylists.length >= 1 ? TopPlaylists.length : '0')}</Grid>
                      {TopPlaylists.length > 0 ?
                        TopPlaylists.map((item: any) => {
                          return(<div style={{margin: '5px'}}>
                            <LabelTopAudioPlaylistMobile musicButton={this.state.musicButton} labelId = {this.state.labelId} item={item}/>
                        </div>)
                        })
                        :
                        (
                          <div style={{color: 'white',display: 'flex', justifyContent: 'center', fontSize: '18px', marginTop: '50px'}}>{`No ${this.state.musicButton ? 'Video' : 'Audio'} Playlists`}</div>
                        )
                      }
                  </div>
                   {/* </Hidden> */}
                  </Grid>
                  
                </Grid>
               </Grid>
               </Grid>

              
            </TabPanel>
            
            {/* <LoginAlert open={openAlertPopUp} handleClose={this.handleClose} dialogText={dialogText} handleCloseOpenUpgradModal={this.handleCloseOpenUpgradModal} OpenUpgradModal={OpenUpgradModal} handleUpgradeModal={this.handleUpgradeModal} /> */}
          </div>
        );
      }
  
}
// Customizable Area End