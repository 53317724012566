// Customizable Area Start
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CheckIcon from '@material-ui/icons/Check';

// Customizable Area End


// Customizable Area Start
interface Props {
    isOpen: boolean;
    handleClose: () => void;
}
// Customizable Area End

// Customizable Area Start
export const LinkCopiedModal = (props: Props) => {
    return (
        <>
            <Modal
                open={props.isOpen}
                onClose={props.handleClose}
                className='CopiedModal'
            >
                <Box className='CopiedModalBox'>
                        <Typography className="CopiedText">Link</Typography>
                        <Typography className="CopiedText">Copied</Typography>
                        <Box className='CheckBox'>
                            <CheckIcon  className='CheckIcon'/>
                        </Box>
                </Box>
            </Modal>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default LinkCopiedModal;
// Customizable Area End
