// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import React from "react";





export interface Props {
    count: number,
    containerStyle?: React.CSSProperties,
    value: number,
    isActive: boolean,
    onBarPress: () => void,
    barStyle?: React.CSSProperties,
    isInvert?: boolean 
}


interface S {
}

interface SS {
    id: any;
}

export default class CustomChartBarController extends BlockComponent<
    Props,
    S,
    SS
> {




    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
        ];

       

        
            
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }
     

}

// Customizable Area End