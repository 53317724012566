// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col, Input, Form, Button } from "antd";
import PhoneInput from 'react-phone-input-2'
import './send-sms.css'
import SendSmsDialogController from './SendSmsDialogController.web'
import { PhoneInputProfiles } from '../../../../email-account-registration/src/Common/PhoneInputInProfiles.web';

export class SendSmsDialog extends SendSmsDialogController {
 
  render() {    
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-Sms"}}
     >
         <Row justify="center" >
                <Col span={14} className='margin-left-div'>
                  <Grid container className='sendsmsbox'>
                  <Grid item className='sendmsmstext' data-test-id="send-sms" >Send SMS</Grid>
                  <Grid item>
                  <Form
                    name="basic"
                    className="Sms_form"
                    layout="vertical"
                    onFinish={this.onFinish}
                    autoComplete="off"
                    initialValues={{ }}

                  >
                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please Input Your Phone Number!",
                        },
                      ]}
                    >
                      <PhoneInputProfiles />
                    </Form.Item>

                    <Form.Item
                      label="ShareLink"
                      name="share_link"
                    >
                      <Input defaultValue={this.props.smsLink} disabled/>
                    </Form.Item>
                    <Form.Item className='sendbutttonbox'>
                      <Button
                        className="red_custom_btn_sms login_btn mt-1"
                        htmlType="submit"
                        data-test-id="send-button"
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                </Grid>
              </Grid>
            </Col>
         </Row>
     </Dialog>
    )
  }
}

export default SendSmsDialog
// Customizable Area End