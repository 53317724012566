// Customizable Area Start
import { Button, Col, Layout, Modal, Row, } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState, useEffect } from 'react';
import PlayListCard from "./PlayListCard/PlayListCard.web";
import "./playlistPage.css";
import millify from "millify";
import { CloseCircleOutlined } from "@ant-design/icons";
import "../../user-profile-basic/src/Common/TopSongs.web.css";
import SinglePlaylistCardWeb from "./SongCard/SinglePlaylistCardWeb";
import { Hidden } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import PlayListCreateAndAddplayListModel from './PlayListCard/PlayListCreateAndAddplayListModel.web'
import { openNotification } from "../../../components/src/Notification.web";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import { graph, nxzloader, player_Banner } from "../../dashboard/src/assets";
import CustomAudioPlayer from "../../AudioMusic/src/CustomAudioPlayer.web";

const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
const PlaylistPage = () => {
  const [playList, setplayList] = useState<any>([])
  const [activeTab, setactiveTab] = useState<any>('')
  const [handleAudioVideo, setHandleAudioVideo] = useState(0);
  const [follower, setFollower] = useState(0);
  console.log(playList, activeTab, setactiveTab, "playlistPage")
  const [showModalAddNewPlaylist, setshowModalAddNewPlaylist] = useState<boolean>(false)

  const history: any = useHistory();
  const token: any = localStorage.getItem("token")
  useEffect(() => {
    getPlayLists();

  }, []);


  const getPlayLists = async () => {
    await axios.get(`${baseUrl}/bx_block_playlist/play_lists`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response: any) => {
        setplayList(response?.data?.data)
      }).catch((err: any) => {
        console.log(err);
        if (err?.response.status === 400) {
          openNotification("Please LOGIN to continue", "Error")
        } else {
          if (playListType !== "Public") {
            openNotification(err?.response && err?.response?.data && err?.response?.errors && err?.response?.errors[0]?.token || err?.response?.data.message, "Error");
          }
        }
      });
  }

  const closeModal = () => {
    setshowModalAddNewPlaylist(false)
  }

  let { slug }: any = useParams();
  const [showPlaylist, setShowPlaylist] = useState<any>("")
  const [playPlaylist, setPlaylist] = useState(false)
  const [playlistData, setPlaylistData] = useState({})
  const [selectedPlayList, setSelectedPlayList] = useState([])
  const [selectedPlaylistArray, setSelectedPlaylistArray] = useState([])
  const [handleplay, setHandlePlay] = useState<boolean>(false)
  const [loading, setLoading] = useState(false);
  const [follow, setFollow] = useState<any>(false);
  const [playlistId, setPlaylistId] = useState<any>("");

  const getShowPlaylist = async () => {
    setLoading(true);

    await axios.get(`${baseUrl}/bx_block_playlist/play_lists/${slug}`, {

      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response: any) => {
        setPlaylistId(response.data.data[0].id)
        setShowPlaylist(response.data.data[0])
        localStorage.setItem("artist_id", response.data.data[0].attributes.artist_id);
        setSelectedPlaylistArray(response.data.data[0].attributes.playlist_items.data);
        setFollow(response.data.data[0].attributes?.follow_status)
        setFollower(response.data.data[0].attributes?.followers_count)
      }).catch((err: any) => {
        console.log(err);
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getShowPlaylist();
  }, [])



  const handleSelectedSongPlay = (song: any, showPlaylist: any, index: number) => {
    localStorage.setItem("music_player", "true");
    localStorage.setItem("playListData", JSON.stringify(song));
    localStorage.setItem("selectedPlaylist", JSON.stringify(selectedPlaylistArray));
    setPlaylist(true)
    setPlaylistData(song)
    setSelectedPlayList(selectedPlaylistArray)
  }


  useEffect(() => {
    if (handleAudioVideo === 1) {
      setHandlePlay(false)
    } else if (handleAudioVideo === 2) {
      setHandlePlay(true)
    } else {
      console.log("playlistpage handleAudioVideo")
    }
  }, [handleAudioVideo])
  const [deleteSongId, setDeleteSongId] = useState<any>("")
  const [openDeleteModal, setOpenDeleteModal] = useState<any>(false)
  const handleDeleteSong = (id: any) => {
    setDeleteSongId(id);
    setOpenDeleteModal(true)
  }
  const handleClosePlaylistModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeletePerticularSong = async () => {

    const data = {
      add_track_id: deleteSongId,
      play_list_id: playlistId

    };
    setLoading(true)
    await axios
      .delete(`${baseUrl}/bx_block_playlist/delete_particular_track`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },params: data
      })

      .then((response) => {
        getShowPlaylist();
        openNotification(response.data.data[0].message, 'Success')

      }).catch((err) => {
        setLoading(false)
        openNotification(err.response.data.errors[0], 'Error')
      }).finally(() => {
        setOpenDeleteModal(false)
      })
  }



  const playListType: any = localStorage.getItem("playlist_type");
  const handleRepostPlaylist = async (id: any, caption: any) => {
    await axios
      .post(`${baseUrl}/bx_block_playlist/repost_playlist?id=${id}&caption=${caption}`, null, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        }
      })
      .then((res: any) => {
        openNotification("Successfully Reposted", "Success")
      })
      .catch((err) => {
        openNotification("Some error occurred", "Error")
      });
  }
  const handleFollow = async (id: any) => {
    await axios
      .get(`${baseUrl}/account_block/follow_user?user_id=${id}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response: any) => {
        setFollow(true);
        setFollower(follower + 1)
        openNotification(response.data.message, "Success");
      })
      .catch((err: any) => {
        console.log(err);
        openNotification(err.response.data.errors[0].token, "Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleUnfollow = async (id: any) => {
    setLoading(true);

    await axios
      .get(`${baseUrl}/account_block/unfollow_user?user_id=${id}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response: any) => {
        openNotification(response.data.message, "Success");
        setFollow(false)
        setFollower(follower - 1)
      })
      .catch((err: any) => {
        console.log(err);
        openNotification(err.response.data.errors[0].token, "Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlerSingleVideos = async (showPlaylist: any, id: any, trackFile: any) => {
    let outputData
    
    const token: any = localStorage.getItem("token")
    await axios.get(`${baseUrl}/bx_block_audiomusic/expan_track?id=${id}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response: any) => {

        outputData = response?.data?.data

      }).catch((err: any) => {
        console.log(err);
      }).finally(() => {

      })


    if (token) {
      history.push({
        pathname: "/home/Charts/videos",
        state: { currentList: showPlaylist ? showPlaylist : [] }
      })
    }
    else {

      history.push({
        pathname: "/home/Charts/videos",
        state: { video_list: trackFile, activeNo: "5", data: outputData, currentList: showPlaylist ? showPlaylist : [] }
      })
    }

  }

  return (
    <>
      <div
        style={{
          maxWidth: "100vw",
          overflowX: "hidden",
          overflowY: "hidden",
          backgroundColor: "rgb(31, 31, 31)",
          height: "120vh",
        }}
      >
        <Layout style={{ backgroundColor: "black", height: "100vh" }}>
          {/* .......................SideBar...................................... */}
          <Hidden smDown>
            <AsideLeft />
          </Hidden>
          <Content
            style={{
              margin: "0px",
              // height: "100%",
              backgroundColor: "black",
            }}
          >
            <HomeHeader />
            {/* for content page */}

            <div className="playList-body" style={{ height: playPlaylist ? "73vh" : "90vh" }}>
              {/* playlist page */}
              <div className="playlist-label">
                <div className="title-part">
                  {
                    playListType === "Public" ? <h1>  PlayList</h1> : <h1> Your PlayList</h1>
                  }
                </div>
                <div className="add-playlist">
                </div>
              </div>
              {/* playlist card */}


              {loading ? (
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>

                  {" "}
                  <img src={nxzloader} style={{ width: "10%" }} />

                </Row>
              ) : (
                <div className="showPlaylistDiv">
                  {/* <div> */}
                  <PlayListCard
                    setHandleAudioVideo={setHandleAudioVideo}
                    handleplay={handleplay}
                    setHandlePlay={setHandlePlay}
                    setLoading={setLoading}
                    playList={showPlaylist}
                    handlePlayList={handleSelectedSongPlay}
                    id={showPlaylist?.id}
                    // title={showPlaylist?.attributes?.title}
                    artist_id={showPlaylist?.attributes?.artist_id}
                    cover_image={showPlaylist?.attributes?.cover_image}
                    playlistName={showPlaylist?.attributes?.name}
                    song_count={showPlaylist?.attributes?.song_count}
                    avatar={showPlaylist?.attributes?.avatar}
                    artist_name={showPlaylist?.attributes?.artist_name}
                    user_type={showPlaylist?.attributes?.user_type}
                    handleRepostPlaylist={handleRepostPlaylist}
                    // handleUnfollow= {showPlaylist?.attributes?.follow_status}
                    handleFollowStatus={follow}
                    // play_count= {millify(showPlaylist?.attributes?.play_count)}
                    play_count={showPlaylist?.attributes?.play_count}
                    // request={showPlaylist?.attributes?.}
                    following_count={showPlaylist?.attributes?.following_count}
                    playListData={playlistData}
                    followers_count={follower}
                    repost_count={showPlaylist?.attributes?.repost_count}
                    handleFollow={() => handleFollow(showPlaylist?.attributes?.artist_id)}
                    handleUnfollow={() => handleUnfollow(showPlaylist?.attributes?.artist_id)}
                  //  showPlaylist={showPlaylist}
                  />
                  {/* </div> */}
                  {
                    showPlaylist && showPlaylist.attributes.playlist_items.data.length === 0 ? (<>
                      <div className="song-list">
                        <div className="song-div mostly-customized-scrollbar"><Row align="middle" justify="center" style={{ height: "180px" }} className="text_white1 font_25">
                          No Song Available
                        </Row></div></div>
                    </>) :
                      <div className="track_listing"

                      >
                        <div className={playPlaylist ? "trackList_div_audioplayer mostly-customized-scrollbar" : "trackList_div mostly-customized-scrollbar"}>
                          {
                            showPlaylist && showPlaylist.attributes.playlist_items.data.map((song: any, index: any) => (
                              <div key={song.id}>

                                <SinglePlaylistCardWeb
                                  // number={number}
                                  key={index}
                                  number={index}
                                  id={song.id}
                                  playlistData={playlistData}
                                  arrow={song.attributes.arrow}
                                  // handlePlayList={() => setPlaylist(true)}
                                  handlePlayList={() => handleSelectedSongPlay(song, showPlaylist, index)}
                                  playerBanner={player_Banner}
                                  artist_name={song.attributes.artist_name}
                                  image={song.attributes.art_work}
                                  songName={song.attributes.title}
                                  playCount={millify(song.attributes.play_count)}
                                  rePostCount={millify(song.attributes.repost_count)}
                                  commentCount={millify(song.attributes.comments_count)}
                                  lastTwentyFourHours={song.attributes.last_twenty_four_hours_percentage}
                                  lastSevenHours={song.attributes.last_seven_days_percentage}
                                  graph={graph}
                                  handleAudioVideo={handleAudioVideo}
                                  setHandleAudioVideo={setHandleAudioVideo}

                                  track_file={song?.attributes?.track_file}
                                  handleDeleteSong={() => handleDeleteSong(song.id)}
                                  track_upload_type={song.attributes.track_upload_type}
                                  handlerSingleVideos={() => handlerSingleVideos(showPlaylist, song.id, song.attributes.video_file)}
                                />

                              </div>
                            ))
                          }  </div>
                          </div>
                  }


                </div>)
              }

            </div>
          </Content>
        </Layout>
        {
          showModalAddNewPlaylist && (
            <PlayListCreateAndAddplayListModel showModalAddNewPlaylist={showModalAddNewPlaylist} addNewPlaylist={'addNewPlaylist'} closeModal={closeModal} getPlayLists={getPlayLists} />
          )
        }
        {
          openDeleteModal && (
            <Row className="modal_playlist">
              <Modal
                className='admin-settings-pwd-update-modal'
                destroyOnClose
                centered
                // title=" Submitted Queries" 
                footer={false}
                visible={openDeleteModal}
              //  onOk={handleOk} onCancel={handleCancel}
              >
                {loading ? <Row className="mt-6  width_100" align="middle" justify="center" style={{
                  height: "250px"
                }}>
                  <Col>
                    {" "}
                    <img src={nxzloader} style={{ width: "10%" }} />
                  </Col>
                </Row> : (<>

                  <Row
                    className="font_25 font_W_400 mb_1"
                    align="middle"
                    justify="center"
                  >
                    <Col span={22} style={{ textAlignLast: "center" }}>
                      {" "}
                      <Row align="middle" justify="center" className="font_25 mb-1 ml-1">
                        Delete Track
                      </Row>
                    </Col>{" "}
                    <Col span={2}>
                      {/* @ts-ignore */}
                      <CloseCircleOutlined
                      
                        onClick={handleClosePlaylistModal}

                      
                      />
                    </Col>
                  </Row>
                  <Row
                    // className="highlight-head"
                    className="width_100 mt-2 mb-2"
                    justify="center"
                    align="middle"
                  >


                    <Row align="middle" justify="center" className=" font_16 width_100">
                      Are You Sure To Delete the selected
                    </Row>
                    <Row align="middle" justify="center" className=" font_16 width_100">


                      Track From Playlist?</Row>
                    <Row align="middle" justify="center" className="width_100 mt-3" style={{ textAlign: "center" }}>
                      <Col>
                        <Button
                          onClick={() => setOpenDeleteModal(false)}
                          className="btn_secondary mt-1"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          // loading={btnLoading}
                          onClick={handleDeletePerticularSong}
                          className="btn_primary mt-1"
                        >
                          Yes
                        </Button>
                      </Col>
                    </Row>


                  </Row>
                </>)}
              </Modal></Row>
          )
        }
      </div>


    </>
  );
};
export default PlaylistPage;
// Customizable Area End
