import React from 'react'
import { CloseRounded } from '@material-ui/icons';
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'

const CustomAudioCloseLogin = ({ closeAudio, open,dialogText,handleClose,closeAudioPlayer }: any) => {
   
    return (
        <>
        {
            closeAudio &&
            <div style={{position: 'absolute', top: 5, right: 2, cursor: 'pointer'}} onClick= {closeAudioPlayer}>
              <CloseRounded fontSize="medium" style={{ color: "rgba(255, 255, 255, 0.5)" }} />
            </div>
          }
            
      {open && <LoginAlert open={open} handleClose={handleClose} dialogText={dialogText} />}
      </>
    )
}

export default CustomAudioCloseLogin