// Customizable Area Start
import MusicBox from "../../../components/src/MusicBox.web";
import React from "react";
import "../assets/MainBody.css";
import { Carousel } from "antd";
import RowAlbum from "../../../components/src/RowAlbum.web";
import Creative from "../../../blocks/email-account-registration/src/Creative.web";

const MainBody = () => {
  const contentStyle = {
    height: "160px",
    color: "white",
    lineHeight: "160px",
    textAlign: "center",
    background: "black",
  };

  return (
    <div className="MainBody mostly-customized-scrollbar">
      <Carousel autoplay>
        <div className="image">
          <img src="https://png.pngtree.com/background/20210711/original/pngtree-music-and-landscape-poster-background-picture-image_1123969.jpg" />
          {/* <h3 style={contentStyle}>1</h3> */}
        </div>
        <div className="image">
          <img src="https://nmbx.newmusicusa.org/wp-content/uploads/nmbx/2015/11/ocean-headphones.jpg" />
          {/* <h3 style={contentStyle}>2</h3> */}
        </div>
        <div className="image">
          <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
          {/* <h3 style={contentStyle}>3</h3> */}
        </div>
        <div className="image">
          <img src="https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270" />
          {/* <h3 style={contentStyle}>4</h3> */}
        </div>
      </Carousel>
      <div className="boxes">
        <div className="head">
          <div>
            <h2>Top Beats</h2>
          </div>
          <div>
            <a href="#">
              <h4>SEE ALL</h4>
            </a>
          </div>
        </div>
        <div className="box">
          <MusicBox />
          <MusicBox />
          <MusicBox />
          <MusicBox />
          <MusicBox />
          <MusicBox />
          <MusicBox />
          <MusicBox />
          <MusicBox />
        </div>
      </div>
      <audio controls>
        <source src="horse.ogg" type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
      <RowAlbum title="Top Hooks" />
      <div className="box">
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
        <MusicBox songName="You should be said" author="Halsey" />
      </div>
      <RowAlbum title="Top Chart" />
      <div className="box">
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
        <MusicBox songName="Weekly Top 20" />
      </div>
      <RowAlbum title="Creative" />
      <div className="box">
        <Creative />
        <Creative />
        <Creative />
        <Creative />
        <Creative />
        <Creative />
        <Creative />
        <Creative />
        <Creative />
        <Creative />
      </div>
      <RowAlbum title="Top Videos" />
      <div className="box">
        <MusicBox />
        <MusicBox />
        <MusicBox />
        <MusicBox />
        <MusicBox />
        <MusicBox />
        <MusicBox />
        <MusicBox />
        <MusicBox />
        <MusicBox />
      </div>
      <RowAlbum title="NXZ Sound" />
      <div className="nxz-sound">
        <div className="nxz-row1">
          <div className="distribution">
            <h2>Distribution</h2>
            <img
              src="https://i1.wp.com/haulixdaily.com/wp-content/uploads/2019/10/Music-Distribution-Explained.png?fit=1920%2C1200&ssl=1"
              alt="distribution"
            />
          </div>
          <div className="marketing">
            <h2>Marketing</h2>
            <img
              src="https://fazewp-fazemediainc.netdna-ssl.com/cms/wp-content/uploads/2019/06/music-headphones-pc-2.jpg"
              alt="marketing"
            />
          </div>
        </div>
        <div className="nxz-row2">
          <div className="fm-radio nxz-col">
            <h2>FM Radio</h2>
            <img
              src="https://images.unsplash.com/photo-1487180144351-b8472da7d491?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmFkaW98ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80"
              alt="fm radio"
            />
          </div>
          <div className="licensing nxz-col">
            <h2>Licensing</h2>
            <img
              src="https://previews.123rf.com/images/kittiyaporn1027/kittiyaporn10271603/kittiyaporn1027160300138/54373497-blue-headphones-with-cartoon-listening-music-on-wooden-background.jpg"
              alt="licensing"
            />
          </div>
          <div className="press nxz-col">
            <h2>Press</h2>
            <img
              src="https://images.unsplash.com/photo-1614680376573-df3480f0c6ff?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8bXVzaWMlMjB3YWxscGFwZXJ8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80"
              alt="press"
            />
          </div>
        </div>
      </div>
      <div className="nxz-financing">
        <h1>NXZSOUND Financing</h1>
        <h3>
          Get Financed Up To $25k Via NXZSound+ And
          <br /> Fund Your Music Career.
        </h3>
        <button type="button">Sign up</button>
      </div>
      <div className="nxz-download">
        <h1>Download the app</h1>
        <h3>
          Listen to beats, connect with other musicians, and
          <br /> write lyrics all from the Nxzsound app!
        </h3>
        <img src="./google-play-store" alt="google-play-store" />
      </div>
    </div>
  );
};

export default MainBody;
// Customizable Area End
