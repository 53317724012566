// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import axios from "axios";
import { openNotification } from "../../../../../../components/src/Notification.web";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { follower } from "../../../../../dashboard/src/assets";
import { AudioContextApi } from "../../../../../dashboard/src/AudioContext/AudioContext.web";
import { HISTORY } from "components/src/common";

export const configJSON = require("../../../../../dashboard/src/config");

export interface Props {
  navigation?: any;
  id?: string;
  addNewPlaylist?: any;
  addTrackToPlaylist?: any;
}
interface S {
  handleAudioVideo: any;
  handleplay: boolean;
  dashboardData: any;
  playList: any;
  activeTab: any;
  follower: any;
  showModalAddNewPlaylist: boolean;
  playlistData: any;
  selectedPlayList: any;
  loading: boolean;
  follow: boolean;
  playlistId: string;
  token: any;
  playListType: any;
  errorMsg: string;
  playlistType: any;
  openEditPlaylistModal: any;
  BlankplayList: any;
  deletePlaylistId: any;
  openDeletePlaylistModal: any;
  editPlaylistId: any;
  oldPlaylistName: any;
  imageUrl: any;
  fileName: any;
  playlistName: any;
  showPlaylistNameError: any;
  disablity: any;
  disablityToSaveBtn: any;
  playListVal: any;
  musicButton: boolean;
  playlistFollowingToogle: boolean;
  privatePublicToggle: boolean;
  privatetoggle: boolean;
  privateData: any;
  publictoggle: boolean;
  publicData: any;
  showPlaylist: string;
  selectedPlaylistArray: any;
  playPlaylist: boolean;
  deleteSongId: string;
  openDeleteModal: boolean;
  openAlertLogin: boolean;
  dialogTextAlertLogin: any;
  openPopup: boolean;
  delete_track_id: any;
  delete_playlist_id: any;
  total_audio_count: number;
  total_video_count: number;
  selectedDiv: any;
  getVideoPlaylist: any;
  singleTrack: any;
  currentPlaylistName: any;
  currentPlaylistCoverImg: any;
  currentPlaylistId: any;
  coverImage: any;
  isFollowingPlaylist: boolean;
  followingPlaylistList: any;
  followPublicPlaylistToggle: boolean;
  followPublicPlaylist: any;
  openRepostPopup: boolean;
  openPopUp: boolean;
  dialogText: any;
  open: boolean;
  repost_track: any;
  textFieldValue: any;
  openEditPopup: any;
  openDeletePopup: any;
  copied: any;
  openLinkPopup: any;
  openMessageLink: any;
  selectedPlaylistId: any;
  selectedRepostSong: any;
  allListLength: any;
  playlistLoader: boolean;
  displayedArray: any;
  selfPlaylistLoader: boolean;
  CopiedForLink: any;
  topVideosList: any;
  trackFile: any;
  videosOutputData: any;
}
interface SS {
  id: any;
}

export default class SinglePlaylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetQueryStrinurl: string = "";
  getTopVideoList: string = "";
  getPlaylistByIDApi: string = "";
  getTopVideosApi: string = "";
  deleteTrackFromPlaylistApi: string = "";
  getFollowPublicPlaylistApi: string = "";
  getSingleVideoDataApi: string = "";
  postRepostPlaylistApi: string = "";
  getFollowPlaylistApi: string = "";
  getUnfollowPlaylistApi: string = "";
  postChartTrackRepostApi: string = "";
  getFollowingPlaylisttApi: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      handleplay: false,

      displayedArray: [],
      dashboardData: [],
      playList: [],
      activeTab: "",
      handleAudioVideo: 0,
      follower: 0,
      showModalAddNewPlaylist: false,
      playlistData: {},
      selectedPlayList: [],
      loading: false,
      follow: false,
      playlistId: "",
      token: localStorage.getItem("token"),
      playListType: localStorage.getItem("playlist_type"),
      errorMsg: "",
      playlistType: "audioPlaylist",
      openEditPlaylistModal: false,
      BlankplayList: "",
      deletePlaylistId: "",
      openDeletePlaylistModal: false,
      editPlaylistId: "",
      oldPlaylistName: "",
      imageUrl: "",
      fileName: "",
      playlistName: "",
      showPlaylistNameError: false,
      disablity: true,
      disablityToSaveBtn: true,
      playListVal: "",
      musicButton: false,
      privatePublicToggle: false,
      playlistFollowingToogle: false,
      privatetoggle: false,
      showPlaylist: "",
      selectedPlaylistArray: [],
      playPlaylist: false,
      deleteSongId: "",
      openDeleteModal: false,
      openAlertLogin: false,
      dialogTextAlertLogin: "",
      openPopup: false,
      delete_track_id: null,
      delete_playlist_id: null,
      total_audio_count: 0,
      total_video_count: 0,
      selectedDiv: "",
      getVideoPlaylist: [],
      privateData: [],
      publicData: [],
      publictoggle: false,
      singleTrack: "",
      currentPlaylistName: "",
      currentPlaylistCoverImg: "",
      currentPlaylistId: "",
      coverImage: localStorage.getItem("cover_img"),
      isFollowingPlaylist: false,
      followingPlaylistList: [],
      followPublicPlaylistToggle: false,
      followPublicPlaylist: [],
      openRepostPopup: false,
      openPopUp: false,
      dialogText: "",
      open: false,
      repost_track: "",
      textFieldValue: "",
      openEditPopup: false,
      openDeletePopup: false,
      copied: false,
      openLinkPopup: false,
      openMessageLink: false,
      selectedPlaylistId: "",
      selectedRepostSong: "",
      allListLength: "",
      playlistLoader: false,
      selfPlaylistLoader: false,
      CopiedForLink: "",
      topVideosList: [],
      trackFile: "",
      videosOutputData: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTopVideos();
    localStorage.removeItem("selectedTab");
    if (this.state.token) {
      this.setState({ loading: true, selfPlaylistLoader: true });

      let urlArr = window.location.href.split("/");
      let slug: any = urlArr.length !== 0 ? urlArr.slice(-1) : "";
      this.setState({ singleTrack: slug });
      if (!isNaN(parseFloat(slug[0])) && isFinite(slug[0])) {
        this.getShowPlaylistByID();
      }
    } else {
      HISTORY.push("/");
    }
  }

  //first render-private//
  getShowPlaylistByID = async () => {
    this.setState({ selfPlaylistLoader: true });
    console.log(this.state.loading, "loading...");
    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr.slice(-1) : "";
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPlaylistByIDApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_playlist/play_lists/${slug}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTopVideos = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopVideosApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_videos/top_videos`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteTrackFromPlaylist = async () => {
    let formData = new FormData();
    let urlArr = window.location.href.split("/");
    let slug: any = urlArr.length !== 0 ? urlArr.slice(-1) : "";
    formData.append("add_track_id", this.state.delete_track_id);
    formData.append("play_list_id", slug);
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTrackFromPlaylistApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `bx_block_playlist/delete_particular_track`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  followPublicPlaylist = async (id: any) => {
    ;
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowPublicPlaylistApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_playlist/follow_playlist?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlerSingleVideos = async (id: any, trackFile: any) => {
    this.setState({ trackFile: trackFile });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleVideoDataApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/expan_track?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleRepostPlaylist = async (id: any, caption: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRepostPlaylistApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_playlist/repost_playlist?id=${id}&caption=${caption}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFollow = async (id: any) => {
    ;
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowPlaylistApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/account_block/follow_user?user_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUnfollow = async (id: any) => {
    ;
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUnfollowPlaylistApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `account_block/unfollow_user?user_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChartTrackRepost = async (caption: any) => {
    ;
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postChartTrackRepostApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `bx_block_audiomusic/repost_count?id=${this.state.delete_track_id}&caption=${caption}}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFollowingPlaylist = async () => {
    ;
    this.setState({ isFollowingPlaylist: true });
    this.setState({
      loading: true,
      playlistLoader: true,
    });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowingPlaylisttApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_playlist/playlist_followings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSelectedSongPlay = (song: any, showPlaylist: any, index: number) => {
    this.handleDeleteSong(song.id);
    this.setState({ repost_track: song });
    if (
      song.attributes.track_upload_type == "Video" ||
      song.attributes.play_list_type == "Video"
    ) {
      //    this.handlerSingleVideos(showPlaylist,song.id,song.attributes.video_file)
    } else {
      localStorage.setItem("music_player", "true");
      localStorage.setItem("playListData", JSON.stringify(song));
      localStorage.setItem(
        "selectedPlaylist",
        JSON.stringify(this.state.selectedPlaylistArray)
      );
      let { handlePlayList, setPlaylistForQueue } = this.context;
      setPlaylistForQueue(this.state.showPlaylist);
      handlePlayList(song, index, this.state.selectedPlaylistArray, "Playlist");
    }
  };

  handleShowPlaylist = async (id: any, name: any, cover_image: any) => {
    if (this.state.playlistFollowingToogle) {
      localStorage.setItem("playlist_type", "Public");
      HISTORY.push(`/home/playlist/public-view/${id}`);
    } else {
      localStorage.setItem("playlist_type", "Private");
      HISTORY.push(`/home/self-view/${id}`);
    }
    localStorage.setItem("currentPlaylistId", id);
  };

  changeMusicToogleTrue = () => {
    this.setState({ musicButton: true });
  };
  changeMusicToogleFalse = () => {
    this.setState({ musicButton: false });
  };

  onSingleCopy = (playlist: any) => {
    const el = document.createElement("input");
    el.value = window.location.origin.concat(
      `/home/playlist/public-view/${playlist.id}`
    );
    document.body.appendChild(el);
    el.select();
    el.focus();
    navigator.clipboard.writeText(
      window.location.origin.concat(`/home/playlist/public-view/${playlist.id}`)
    );
    document.body.removeChild(el);
    this.setState({ copied: true });
    this.setState({ openLinkPopup: true });
  };

  linkopenPopup = () => {
    this.setState({ CopiedForLink: this.onScanQR() });
    this.setState({ openMessageLink: true });
  };

  onScanQR = () => {
    let scanTo: any;
    let playlist: any = this.state.showPlaylist;
    scanTo = window.location.origin.concat(
      `/home/playlist/public-view/${playlist.id}`
    );
    return scanTo;
  };

  deletePlaylistConfirm = () => {
    if (this.state.selectedPlaylistId.id) {
      this.setState({ openDeletePopup: true });
    } else {
      openNotification("select any playlist to delete", "Error");
    }
  };

  onCopy = () => {
    if (
      this.state.selectedPlaylistId?.attributes?.status != ("" || undefined)
    ) {
      const el = document.createElement("input");
      el.value = window.location.origin.concat(
        `/home/playlist/public-view/${this.state.selectedPlaylistId.id}`
      );
      document.body.appendChild(el);
      el.select();
      el.focus();
      navigator.clipboard.writeText(
        window.location.origin.concat(
          `/home/playlist/public-view/${this.state.selectedPlaylistId.id}`
        )
      );
      document.body.removeChild(el);
      this.setState({ copied: true });
      this.setState({ openLinkPopup: true });
    } else {
      openNotification("select any playlist", "Error");
    }
  };

  changeplaylistFollowingToogleTrue = () => {
    this.setState({ playlistFollowingToogle: true });
  };
  changeplaylistFollowingToogleFalse = () => {
    this.setState({ playlistFollowingToogle: false });
  };
  changeprivatePublicToggleToogleTrue = () => {
    this.setState({ privatePublicToggle: true });
  };
  changeprivatePublicToggleToogleFalse = () => {
    this.setState({ privatePublicToggle: false });
  };

  handleRepost = () => {
    if (this.state.token && this.state.repost_track != "") {
      this.setState({
        openRepostPopup: !this.state.openRepostPopup,
        openPopUp: true,
      });
    } else {
      this.setState({
        dialogText: "2",
        open: true,
      });
      openNotification("Select Single Tracks To Proceed Your Request", "Error");
    }
  };
  handleRepostClose = () => {
    this.setState({
      openRepostPopup: false,
      openPopUp: false,
    });
  };

  respostSubmit = () => {
    this.handleChartTrackRepost(this.state.textFieldValue);
    this.handleRepostClose();
  };
  handleCaptionChange = (e: any) => {
    this.setState({ textFieldValue: e.target.value });
  };

  onShare = () => {
    const token: any = localStorage.getItem("token");
    const userType = localStorage.getItem("user_type");
    const subscriptionPlan = localStorage.getItem("subscription_plans");

    if (token) {
      if (!isNaN(+this.state.singleTrack[0])) {
        if (userType == "listener" && subscriptionPlan == "NXZ") {
          this.setState({ openAlertLogin: true });
          this.setState({ dialogTextAlertLogin: "3" });
        } else {
          this.setState({ openPopup: true });
        }
      } else {
        openNotification("Please select playlist to share", "Error");
      }
    } else {
      this.setState({ openAlertLogin: true });
      this.setState({ dialogTextAlertLogin: "2" });
    }
  };

  handleSelection = (selected: any) => {
    this.setState({ playListType: selected });
  };

  handleCloseDeletePlaylistModal = () => {
    this.setState({ openDeletePlaylistModal: false });
  };

  handleDeletePlayList = (id: any, cover_img: any) => {
    this.setState({ deletePlaylistId: id });
    this.setState({ singleTrack: id });
    this.handleBannerCover(cover_img);
  };

  handleBannerCover = (img: any) => {
    const cover_img: any = localStorage.getItem("cover_img");
    this.setState({ coverImage: img ? img : cover_img });
  };

  handleDeleteSong = (id: any) => {
    this.setState({ delete_track_id: id });
    console.log(this.state.delete_track_id, "handledelete");
  };

  handleClosePlaylistModals = () => {
    this.setState({ openDeleteModal: false });
  };

  // private to public and vice-versa

  handleChangeStatusOfPlaylist = async (status: any) => {
    const currentPlaylistId: any = await localStorage.getItem(
      "currentPlaylistId"
    );
    let urlArr: any = window.location.href.split("/");
    let slug = urlArr.length !== 0 && urlArr[urlArr.length - 1];
    let formData = new FormData();
    formData.append("status", status);
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
      token: token,
    };
    await axios
      .put(
        `${
          configJSON.baseUrl
        }/bx_block_playlist/play_lists/${currentPlaylistId || slug}`,
        formData,
        { headers }
      )
      .then(async (response: any) => {
        openNotification(
          `Your playlist status has changed from ${
            status === "public_playlist" ? "private" : "public"
          } to ${status === "public_playlist" ? "public" : "private"}`,
          "Success"
        );
        this.setState({
          privatetoggle:
            response?.data?.data[0].playlist.status == "private_playlist"
              ? false
              : true,
        });
      })
      .catch((err: any) => {
        openNotification("We Cannot Proceed Your Request,Try Again!", "Error");
      });
  };

  handleShowPlaylistByID = (responseJson: any) => {
    console.log(responseJson,">>>responseJson")
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      ;
      this.setState({
        privatetoggle:
          responseJson?.data?.attributes.status == "private_playlist"
            ? false
            : true,
        playlistId: responseJson.data.id,
        showPlaylist: responseJson.data,
        privateData: responseJson.data.attributes.playlist_items.data,
        publicData: responseJson.data.attributes.playlist_items.data,
        displayedArray: responseJson.data.attributes.playlist_items.data,
        selectedPlaylistArray: responseJson.data.attributes.playlist_items.data,
        follow: responseJson.data.attributes?.follow_status,
        follower: responseJson.data.attributes?.followers_count,
        selfPlaylistLoader: false,
      });

      localStorage.setItem("artist_id", responseJson.data.attributes.artist_id);
    } else {
      this.setState({ selfPlaylistLoader: false });
      console.log(responseJson.errors, "error in showplaylistid");
    }
  };

  handleGetTopVideos = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ topVideosList: responseJson?.data });
    } else {
      openNotification("Something went wrong", "Error");
    }
  };

  handleDeleteTrackFromPlaylist = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("track deleted", "Success");
      this.getShowPlaylistByID();
    } else {
      const token: any = localStorage.getItem("token");

      if (token) {
        openNotification(
          "Select Single Tracks To Proceed Your Request",
          "Error"
        );
      }
    }
  };
  handleFollowPublicPlaylist = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ followPublicPlaylistToggle: false });
      openNotification("UnFollowing", "Success");
      this.getShowPlaylistByID();
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });

      if (
        responseJson.errors.response.status === 401 ||
        responseJson.errors.response.status === 400
      ) {
        openNotification("Please login to continue", "Error");
      } else {
        responseJson.errors.response.status == 422 &&
          this.setState({ followPublicPlaylist: [] });
      }
    }
  };

  handleSingleVideoApi = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      const token: any = localStorage.getItem("token");
      this.setState({ videosOutputData: responseJson?.data });
      if (token) {
        HISTORY.push({
          pathname: "/home/Charts/videos",
          state: {
            video_list: this.state.trackFile,
            activeNo: "5",
            data: this.state.publicData[0],
            currentList: this.state.publicData ? this.state.publicData : [],
          },
        });
      } else {
        HISTORY.push({
          pathname: "/home/Charts/videos",
          state: {
            video_list: this.state.trackFile,
            activeNo: "5",
            data: this.state.videosOutputData,
            currentList: this.state.publicData ? this.state.publicData : [],
          },
        });
      }
    } else {
      console.log(responseJson.errors, ">>error");
    }
  };

  handleRepostPlaylistApi = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("Successfully Reposted", "Success");
    } else {
      openNotification("Some error occurred", "Error");
    }
  };

  handleFollowPlaylistApi = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ follow: true, follower: follower + 1 });
      openNotification(responseJson.data.message, "Success");
    } else {
      openNotification(
        responseJson.errors.response.data.errors[0].token,
        "Error"
      );
    }
  };

  handleUnfollowPlaylistApi = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ follow: false, follower: follower - 1 });
      openNotification(responseJson.data.message, "Success");
    } else {
      openNotification(
        responseJson.errors.response.data.errors[0].token,
        "Error"
      );
    }
  };

  postChartTrackRepost = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("Successfully Reposted", "Success");
    } else {
      openNotification("Select Single Tracks To Proceed Your Request", "Error");
    }
  };

  getHandleFollowPlaylist = (responseJson: any) => {
    ;
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ isFollowingPlaylist: true });
      this.setState({
        followingPlaylistList:
          responseJson?.data == undefined ? [] : responseJson?.data,
        loading: false,
        playlistLoader: false,
      });
    } else {
      this.setState({ loading: false, playlistLoader: false });

      console.log(responseJson.errors, "getHandleFollowPlaylist");
      if (
        responseJson.errors.response?.status === 401 ||
        responseJson.errors.response?.status === 400
      ) {
        openNotification("Please login to continue", "Error");
      } else {
        responseJson.errors.response.status == 422 &&
          this.setState({ followingPlaylistList: [] });
      }
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    
    switch (apiRequestCallId) {
      case this.getPlaylistByIDApi:
        this.handleShowPlaylistByID(responseJson);
        break;
      case this.getTopVideosApi:
        this.handleGetTopVideos(responseJson);
        break;
      case this.deleteTrackFromPlaylistApi:
        this.handleDeleteTrackFromPlaylist(responseJson);
        break;
      case this.getFollowPublicPlaylistApi:
        this.handleFollowPublicPlaylist(responseJson);
        break;

      case this.getSingleVideoDataApi:
        this.handleSingleVideoApi(responseJson);
        break;
      case this.postRepostPlaylistApi:
        this.handleRepostPlaylistApi(responseJson);
        break;
      case this.getFollowPlaylistApi:
        this.handleFollowPlaylistApi(responseJson);
        break;
      case this.getUnfollowPlaylistApi:
        this.handleUnfollowPlaylistApi(responseJson);
        break;
      case this.postChartTrackRepostApi:
        this.postChartTrackRepost(responseJson);
        break;
      case this.getFollowPlaylistApi:
        this.getHandleFollowPlaylist(responseJson);
        break;
      default:
        break;
    }
  }
}
SinglePlaylistController.contextType = AudioContextApi;
// Customizable Area End
