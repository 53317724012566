// Customizable Area Start
import React from 'react'
import "./searchPlaylist.css";
import "../../../../../components/src/CustomChart/Carousel/index.css"
import "../../../../user-profile-basic/src/Common/TopSongs.web.css";
import PlaylistTracks from './PlaylistTracks.web';
import PlaylistTrackMobile from './PlaylistTrackMobile.web';

function PlaylistSong(props: any) {
  const { song, index, play, selectedPlaylistId, playlistSongSelected } = props;
  let selectedSong = localStorage.getItem("selectedSong")

  return (
    <>

      <PlaylistTracks song={song} play={play} selectedPlaylistId={selectedPlaylistId} selectedSong={selectedSong} playlistSongSelected={playlistSongSelected} index={index} />
      <PlaylistTrackMobile song={song} index={index} />
    </>
  )
}

export default PlaylistSong
// Customizable Area End