Object.defineProperty(exports, "__esModule", {
  value: true
});
const urlConfig = require("../../../framework/src/config.js")

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";

exports.btnExampleTitle = "CLICK ME";
exports.getbannerdata = "/bx_block_audiomusic/banners";
exports.gethandlepreview = "/bx_block_audiomusic/expan_track";
exports.getcreativedata = "/account_block/show_all_creatives_data";
exports.getsongslist = "/bx_block_audiomusic/tracks_by_upload_type_public";
exports.getalltablist = "/bx_block_audiomusic/tracks_by_upload_type_public";
exports.getplaylistlist = "/bx_block_playlist/all_playlists_public";
exports.getoriginaldata = "/bx_block_audiomusic/original_profile";
exports.getalloriginallist = "/bx_block_audiomusic/creative_tracks_by_upload_type";
exports.getTrendingList="/bx_block_videos/trending_videos"
exports.getReleaseList="/bx_block_videos/new_release_videos"
exports.getAPIMethod="GET"
exports.baseUrl = urlConfig.baseURL;
// Customizable Area End