import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { HISTORY } from "components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

  export interface Props {
    planName?: any;
    planPrice?: any;
    next_invoice_no:any;

    updateSubscription:any;
    CreateNewPayment:any;
    handleUpdateCard:any;

    // Customizable Area Start
    // Customizable Area End
  }
  interface S {
    // Customizable Area Start
    tabvalue:any;
    switchPlan:any;
    // Customizable Area End
  }
  interface SS {
    id: any;
  }
  
  export default class MSwitchPlanController extends BlockComponent<
    Props,
    S,
    SS
  > {
    // Customizable Area Start
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),
      ];
      this.state = {
        tabvalue:0,
        switchPlan:{}
      };
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
  

    async componentDidMount() {
      super.componentDidMount();
      const storedUserData = localStorage.getItem("redirectToConfirmPlan");
      const parsedUserData = storedUserData
        ? JSON.parse(storedUserData)
        : null;

      if (parsedUserData) {
        this.setState({switchPlan:parsedUserData})
    }
  }

  async componentWillUnmount() {
      if (localStorage.getItem("redirectToConfirmPlan")) {
        localStorage.removeItem("redirectToConfirmPlan");
      }
  }


   handleChange = (event:any, newValue: number) => {
    this.setState({tabvalue:newValue})
  };

  handleRedirect=()=>{
    HISTORY.push("/planpayments")
  }
    // Customizable Area End
  
    // Customizable Area Start
    // Customizable Area End
  }  
  