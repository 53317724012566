// Customizable Area Start
import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import LinkCopiedModal from "./LinkCopiedModal.web";
import "./AudioVideoInfoCardCP.web.css";
import {
  LinkIcon,
  Message_Icon_Black,
  Send,
} from "../../CustomComponent/SharePlaylistPopup/assets";
import MessageLinkPopup from "../../../../../components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";

import { pauseA, playButton } from "../../assets";
import { AudioContextApi } from "../../AudioContext/AudioContext.web";
const urlConfig = require("../../../../../framework/src/config");
const baseURL = urlConfig.baseURL;
import ListofNewChat from "../../../../Sms/src/ListofNewChat";
// Customizable Area End
import AudioVideoInfoCardCPController from "./AudioVideoInfoCardCPController";

export const ActionButton = (props: {
  title: string;
  src: any;
  onClick: () => void;
}) => {
  return (
    <Box className="AVICBtnBoxCP">
      <IconButton className="BtnCP" onClick={props.onClick}>
        <img style={{ height: "60px", width: "60px" }} src={props.src} />
      </IconButton>
      <Typography className="BtnTextCP">{props.title}</Typography>
    </Box>
  );
};

export const StatesCard = (props: { title: string; value: string }) => {
  return (
    <Box className="StatsCardCP">
      <Typography className="StatsTitleCP">{props.title}</Typography>
      <Typography className="StatsValueCP">{props.value}</Typography>
    </Box>
  );
};

export default class AudioVideoInfoCardCP extends AudioVideoInfoCardCPController {
  render() {
    const { handleAudioVideo } = this.context;
    const selectedSong = localStorage.getItem("selectedSong");

    return (
      <Box className="AVIMainContainerCP">
        <Box
          className={`${
            this.props.selectedTrackMediaDetails?.attributes
              ?.track_upload_type == ("Video" || "video")
              ? "AVInfoContainerCP VideoCP"
              : "AVInfoContainerCP AudioCP"
          } `}
          style={{ position: "relative" }}
          onClick={() => {
            this.handlePlayAudioVideo();
          }}
        >
          <img
            src={this.props.selectedTrackMediaDetails?.attributes?.art_work}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "5px",
            }}
          />
          <IconButton className="PlayBtn" onClick={this.handlePlay}>
            <img
              src={
                selectedSong &&
                selectedSong.split('"')[1] ===
                  this.props.selectedTrackMediaDetails?.id &&
                handleAudioVideo == 2
                  ? pauseA
                  : playButton
              }
              style={{ height: "40px" }}
              onClick={() => {
                this.playOrPauseSong();
              }}
            />
          </IconButton>
        </Box>
        <Typography className="AVICMediaNameCP">
          {this.props.selectedTrackMediaDetails?.attributes?.title}
        </Typography>
        <Typography className="AVICMediaCreatorNameCP">
          {this.props.selectedTrackMediaDetails?.attributes?.artist_name}
        </Typography>
        <Box className="AVICButtonsContainerCP">
          <ActionButton
            title="DM"
            src={Message_Icon_Black}
            onClick={() => {
              //  setsendDm(true)
              this.props.openNewMessage();
            }}
          />
          <ActionButton
            title="Send"
            src={Send}
            onClick={this.handleSendBtn}
          />
          <ActionButton
            title="Copy Link"
            src={LinkIcon}
            onClick={() => {
              this.linkCopy();
            }}
          />
        </Box>
        <Box className="StatsMainBox">
          <Box className="StatsTitleBox">
            <Typography className="StatsTitle">
              <span style={{ color: "#3959f9" }}>Inbox:</span> Stats
            </Typography>
          </Box>
          <Box className="">
            <Box className="StatsRow">
              <StatesCard
                title="Views"
                value={this.props.selectedTrackStatsDetails?.plays}
              />
              <StatesCard
                title="Profile Views"
                value={this.props.selectedTrackStatsDetails?.profile_views}
              />
            </Box>
            <Box className="StatsRow">
              <StatesCard
                title="Follows"
                value={this.props.selectedTrackStatsDetails?.followers}
              />
              <StatesCard
                title="Playlisted"
                value={this.props.selectedTrackStatsDetails?.playlisted}
              />
            </Box>
            <Box className="StatsRow">
              <StatesCard
                title="Link Copies"
                value={this.props.selectedTrackStatsDetails?.link_copies}
              />
              <StatesCard
                title="Sends"
                value={this.props.selectedTrackStatsDetails?.sends}
              />
            </Box>
          </Box>
        </Box>
        <LinkCopiedModal
          isOpen={this.state.isCopiedModalOpen}
          handleClose={this.handleCopiedLinkFalse}
        />
        {/* code changes only this file */}
        {this.state.sendLinkViaDM && (
          <MessageLinkPopup
            id="Playlist"
            copiedLinked={this.state.sendLinkCopy}
            showMsgModal={this.state.sendLinkViaDM}
            closeMsgModal={this.handleSendLinkFalse}
            closeModalDialog={this.handleSendLinkFalse}
            currentUserId={1}
          />
        )}
        <ListofNewChat
          showMsgModal={this.props.isShowMsgModal}
          closeMsgModal={this.closeMsgModalComment}
          closeModalDialog={this.props.closeNewMessage}
        />
        {/* <MessageLinkPopup id = "listner_Message" showMsgModal={sendLinkViaDM}  closeMsgModal={closeMsgModal} closeModalDialog={closeMsgModal} copiedLinked={sendLinkCopy} currentUserId={this.props.selectedTrackMediaDetails?.id}/> */}
      </Box>
    );
  }
}
AudioVideoInfoCardCP.contextType = AudioContextApi;
// Customizable Area End
