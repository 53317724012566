Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";

exports.updatePasswordAPIEndPoint = "profile/password/";
exports.getUserDataAPIEndPoint = "profiles/";
exports.getSubscribeAPIEndPoint = "/subscribe_for_notifications/";
exports.subscribeAPIEndPoint = "/subscribe_for_notifications";

exports.endPointGetTop100Lists = "/bx_block_audiomusic/top_tracks";
exports.MethodGetTop100Lists = "GET";

exports.MethodGetSongsLists = "GET";
exports.endPointGetSongsLists = "/bx_block_audiomusic/tracks_by_upload_type_public?upload_type=Song";

exports.endPointGetBeatsLists = "/bx_block_audiomusic/tracks_by_upload_type_public?upload_type=Beat";
exports.MethodGetBeatsLists = "GET";

exports.endPointGetHooksLists = "/bx_block_audiomusic/tracks_by_upload_type_public?upload_type=Hook";
exports.MethodGetHooksLists = "GET";

exports.endPointGetVersesLists = "/bx_block_audiomusic/tracks_by_upload_type_public?upload_type=Verse";
exports.MethodGetVersesLists = "GET";

exports.endPointGetplayLists = "bx_block_playlist/all_playlists_public";
exports.MethodGetplayLists = "GET";

exports.MethodHandleFilter = "GET";
exports.endPointHandleFilter = "bx_block_audiomusic/filter_tracks"

// Customizable Area End