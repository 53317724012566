// Customizable Area Start
import React from 'react'
import PlanAndPaymentsController from '../PlanAndPaymentsController'
import MPlanCancel from './MPlanCancel.web'

export default class MPlanCancelTemp extends PlanAndPaymentsController {
    render() {
        return (
            <>
                <MPlanCancel closeCancelledModal={this.closeCancelledModal} />
            </>
        )
    }
}
// Customizable Area End