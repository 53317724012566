// Customizable Area Start
import React from "react";
import { Grid, Box } from "@material-ui/core";
import { Row } from "antd";
import "../assets/ChoosePlan.css";
import { PlanShade, subscriptionBackground } from "./assets";
import ForCreativeAndListenerController from "./ForCreativeAndListenerController";
import { nxzloader } from "../../dashboard/src/assets";
import { nxzborderlogo } from "../../email-account-registration/src/assets";

export default class ForListener extends ForCreativeAndListenerController {
  render() {
    const {
      planData,
      enablePlanData,
      loading,
      subscriptionClicked,
    } = this.state;

    return (
      <Grid container className="ForListener">
        <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create">
          <img className="image1" src={subscriptionBackground} />
          <img className="image2" src={PlanShade} />
        </Grid>
        <Grid item lg={6} md={6} xs={12} className="listener-plan">
          <Grid item xs={12}>
            <div>
              <img
                src={nxzborderlogo}
                alt="App Logo"
                className="nxzborderlogo"
              />
            </div>
          </Grid>
          <Grid item xs={12} className="termsandcondition-head">
            <h1 className="mb_0 text_white tc_acc">Select Your Plan</h1>
          </Grid>
          {loading ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <img src={nxzloader} style={{ width: "15%" }} />
            </Row>
          ) : (
            <>
              <Grid className="listener-buttons ">
                {planData?.length !== 0 &&
                  planData.map((plan: any, index: any) => {
                    return (
                      <Grid
                        container
                        item
                        xs={8}
                        sm={8}
                        key={index}
                        className={
                          subscriptionClicked == plan.id
                            ? "subscription-list-acc-active"
                            : "subscription-list-acc"
                        }
                        zeroMinWidth
                        test-id="subscription-list-acc-active_test_id"
                        onClick={() => {
                          this.handlePlanClick(plan);
                        }}
                        style={{ marginBottom: "2%" }}
                      >
                        <button
                          type="submit"
                          key={index + index}
                          id={"plan" + plan?.attributes?.id}
                          test-id="plan-btn-active_test_id"
                          style={{ margin: "0" }}
                          onClick={() => this.handlePlanData(plan)}
                        >
                          <Grid item xs={12} className="p-10 plan_align">
                            <Box className="font_22 width_100 font_W_600 plan_name_acc">
                              {plan?.attributes?.name}
                            </Box>
                            {/* <Box className="font_16" style={{ color: "#d8d8d8" }}>{plan?.attributes?.sub_title}</Box> */}

                            <Box className="font_28 width_100 font_W_600 plan_name_acc">
                              $
                              {plan?.attributes?.amount +
                                "/" +
                                plan?.attributes?.interval
                                  .charAt(0)
                                  .toUpperCase() +
                                plan?.attributes?.interval.slice(1)}
                            </Box>
                          </Grid>
                        </button>
                      </Grid>
                    );
                  })}
              </Grid>

              <Grid
                container
                xs={12}
                zeroMinWidth
                className="acc-next-back-parent"
              >
                <Grid item xs={8} sm={6} style={{ width: "100%" }}>
                  <button
                    className="acc-next-btn"
                    test-id="acc-next-btn_test_id"
                    //   disabled={subscriptionClicked == ""}
                    onClick={() => this.acceptPlan("ForListener")}
                    disabled={enablePlanData}
                  >
                    Next
                  </button>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={6}
                  style={{ width: "100%" }}
                  test-id="acc-back-btn_test_id"
                  onClick={() => this.handleGoBack}
                >
                  <button className="acc-back-btn">Back</button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  }
}

// Customizable Area End
