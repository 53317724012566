// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { HISTORY } from "components/src/common";

export interface Props {
  webStyle: any;
  rightsideSelectedtab: string;
  handleAudiovideotab: (tab: string) => void;
  usertype: string;
  isLoading: boolean;
  tracklistData: any[];
  isListener: boolean;
  selectedStreamedTrack: IDataList;
  onBack: () => void;
  streamedArtist: string;
  id: number;
  getCountValue: (data: IDataList) => number;
}

export interface IDataList {
    id: string | number;
    type: string;
    title: string;
    month: number;
    year: number;
    attributes: {
    avatar: string
    user_name: string
    count: number
    track_count: number
    is_verified: boolean
    nxz_official_logo: boolean
    total_play_count: number
    cover_image: string,
    creative_type_name: string,
    listener_count: number,
    repost_count: number,
    listener_track_count: number,
    total_repost_count: number,
    total_count:number
  }
}

interface S {
}

interface SS {
  id: any;
}

export default class StreamAudioVideoController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {

    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleRedirectionOfAudioVideo = (item: any, index: any) => {
    if (this.props.rightsideSelectedtab == "Audio") {
      this.topPlayMusic(item, index, this.props.tracklistData, "song");

    } else {
      HISTORY.push({
        pathname: "/home/Charts/videos",
        state: { video_list: item.attributes.video_file, activeNo: "5", data: item, currentList: this.props.tracklistData }
      })
    }
  }

  topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    let {
      handlePlayList,
      setHandleAudioVideo,
      handleAudioVideo,
    } = this.context;
    if (handleAudioVideo === 1 || handleAudioVideo === 0) {
      setHandleAudioVideo(2);
      handlePlayList(item, index, songsList, trackName);
    } else {
      setHandleAudioVideo(1);
    }
  };

  getProgress = (item: IDataList) => {
      try {
          let num = 0
          num = item.attributes?.count / item.attributes?.total_count
          if (num === Infinity || isNaN(num)) {
              return 0
          }

          return num
      } catch (error) {

      }
      return 0
  };

}
StreamAudioVideoController.contextType = AudioContextApi;
// Customizable Area End