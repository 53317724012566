// Customizable Area Start
import React from 'react';
import PlanAndPaymentsController from "../PlanAndPaymentsController";
import MConfirmPlan from "./MConfirmPlan.web";



export default class MConfirmPlanTemp extends PlanAndPaymentsController {

    render() {

        const { next_invoice_no }: any = this.state;
        return (
            <>
                <MConfirmPlan
                    updateSubscription={this.updateSubscription}
                    CreateNewPayment={this.CreateNewPayment}
                    handleUpdateCard={this.handleUpdateCard}
                    next_invoice_no={next_invoice_no}
                    {...this.props}

                />
            </>
        )
    }
}
// Customizable Area End