// Customizable Area Start
import React from "react";
import { Layout } from "antd";
import { Hidden, Box, Grid } from "@material-ui/core";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import { Content } from "antd/lib/layout/layout";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import "./index.web.css";
import QueueController from "../QueueController";
import { nxzloader } from '../../assets'
import AudioQueueTemp from "./AudioQueueTemp.web";




export default class Index extends QueueController {
  render() {
    const { loading, allPlaylist } = this.state;

   const {isPlaylist} = this.context;

    return (
      <>
        {/* <div
          style={{
          
            overflow: "hidden",
            backgroundColor: "black"
          }}
        > */}
        <Layout style={{ backgroundColor: "black", overflow: "hidden" }}>
          <Hidden smDown>
            <AsideLeft />
          </Hidden>
          <Content
            style={{
              backgroundColor: "black",
            }}
          >
            <HomeHeader />
            {loading ? (
              <Box className="spinloadder">
                <img src={nxzloader} />

              </Box>
            ) : (
              <>
                <Grid item xl={12} className="queue_contant" style={{ height: isPlaylist ? "75vh" : "92vh", overflowX: "hidden", overflowY: "auto" }}>
                  <AudioQueueTemp
                    handleChartTrackRepost={this.handleChartTrackRepost}
                    handlerFetchRepost={this.handlerFetchRepost}
      
                  />
                </Grid>
              </>
            )}
            {/* </div> */}
          </Content>
        </Layout>
        {/* </div> */}
      </>
    );
  }
}

// Customizable Area End
