// Customizable Area Start
import React from 'react';
import { Typography, Avatar, IconButton } from "@material-ui/core";
import { Playcirclefill } from "../../assets";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import './AudioVideoCard.web.css'
// Customizable Area End


// Customizable Area Start
interface Props {
    media: any,
    type: string,
    onPlay: any;
}
// Customizable Area End

// Customizable Area Start
export const AudioVideoCard = (props: Props) => {
    const { media, type } = props;
    const handlePlay = () => {
        props.onPlay()
    }
    return (
        <div className="AudioVideoCard">
            <div className="AudioVideoBackgroundLeft"></div>
            <div className="ContentDiv">
                <div className="AudioVideoImageDiv">
                    <img src={ media?.image} className={`AudioVideoImage ${type === 'video' && 'VideoImage'}`} />
                    <IconButton data-test-id="PlayBtn" onClick={handlePlay} className={`AudioVideoPlayBtn ${type === 'video' && 'VideoType'}`}>
                        <Avatar src={Playcirclefill} className='Avatar'/>
                    </IconButton>
                </div>
                <div className="AudioVideoInfoDiv">
                    <div className="ColumnCenter">
                        <Typography className="SongNameText">
                            {media?.name}
                        </Typography>
                        <Typography className="SongWriterText">{media?.writer}</Typography>
                    </div>
                    <div className="ColumnCenter">
                        <Typography className="PlayCountText">
                            <PlayArrowIcon htmlColor='#3959f9' /> {media?.play_count}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
// Customizable Area End


// Customizable Area Start
export default AudioVideoCard;
// Customizable Area End
