// Customizable Area Start
import { Input, Upload } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { styled } from "@material-ui/styles";
import EditPlaylistController from "./EditPlaylistController";
import {
  menuicon,
  Editicon,
  playblue,
  deletecircle,
} from "../../../../dashboard/src/assets";
import { defaultImage } from "blocks/dashboard/src/assets";
import DraggableList from "react-draggable-list";

const EditMainWrapper = styled(Box)({
  display: "flex",
  padding: "30px 15px 0px 27px",
  gap: "35px",
  minHeight: "calc(100% - 3rem)",
  justifyContent: "space-between",

  "@media(max-width: 1400px)": {
    minHeight: "0px",
    gap: "20px",
  },
  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 5px",
    gap: "20px",
  },
});

const EditVideoleftsidecontainer = styled(Box)({
  width: "60%",
  display: "flex",
  flexDirection: "column" as "column",
  gap: "2%",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    gap: "20px",
    marginTop: "10px",
  },
});
const EditvideoLeftsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "693px",
  scrollbarWidth: "none",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});

const TotalVideoSplits = styled(Box)({
  width: "100%",
  color: "white",
  "@media(max-width: 600px)": {
    height: "auto",
  },
});
const VideoBorderboxweb = styled(Box)({
  "@media(max-width: 1024px)": {
    display: "none",
  },
});

const VideoPlyalistinputboxwrapper = styled(Box)({
  width: "100%",
  borderBottom: "5px solid #212121",
  background: "transparent",
  "& .ant-input": {
    border: "unset",
    padding: "15px 0px 17px 0px",
    backgroundColor: "transparent",
    width: "88%",
    color: "white",
  },
  "& :focus": {
    border: "unset",
    boxShadow: "none",
  },

  "& .ant-input-affix-wrapper": {
    background: "none",
    border: "unset",
    "&:focus, &:focus-within": {
      border: "unset ",
      boxShadow: "none ",
    },
  },
  "& ::placeholder": {
    fontWeight: 700,
    lineHeight: "30px",
    marginLeft: "-10px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
  },

  "@media(max-width: 1024px)": {
    width: "100%",
    "& .ant-input": {
      width: "50%",
    },
  },
});

const LeftsideditAlltracksbox = styled(Box)({
  width: "100%",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
});

const SinglevideoPlaylistRightideboxwrapper = styled(Box)({
  width: "40%",
  display: "flex",

  marginTop: "-35px",
  justifyContent: "center",
  "@media(max-width: 1024px)": {
    width: "100%",
    marginTop: "10px",
    paddingBottom: "60px",
  },
});
const Singlevideoplaylistrightsidebox = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "30px",
  flexDirection: "column" as "column",
  "@media(max-width: 1400px)": {
    width: "100%",
    // marginTop: "10px",
    // paddingBottom:'60px'
  },
});

interface PlaylistVideoItemProps {
  items: {
    id: string;
    attributes: {
      play_count: string;
      art_work: string;
      title: string;
      artist_name: string;
    };
  };
  dragHandleProps: any;
  selectedTrack: (item: any) => void;
  playlistdetailsdata: any;
  removeTrackByIndex: (id: string) => void;
}
export class PlaylistVideoItem extends React.Component<PlaylistVideoItemProps> {
  render() {
    const {
      items,
      dragHandleProps,
      selectedTrack,
      playlistdetailsdata,
      removeTrackByIndex,
    } = this.props;

    const {
      dragHandleProps: { onMouseDown, onTouchStart },
    } = dragHandleProps;

    return (
      <Box
        key={items.id}
        style={videoStyle.videoleftSideSingletrackbox}
        
        data-test-id="edit-playlistVideo-click"
      >
        <Box style={videoStyle.videosingleArtinfo} className="disable-select">
          <img
            src={deletecircle}
            style={{ width: "24px", height: "24px" }}
            onClick={() => {
              removeTrackByIndex(items.id);
            }}
            data-test-id="edit-playlistVideo-img"
          />
          <img
            src={items.attributes?.art_work || defaultImage}
            style={videoStyle.imagecover}
          />
          <Box style={videoStyle.videoleftsideownerstats}>
            <span style={videoStyle.trackname}>{items.attributes?.title}</span>

            <Box style={videoStyle.ownerStatsbox}>
              <span style={videoStyle.videosplittext}>
                {items.attributes?.artist_name}
              </span>

              <img src={playblue} style={videoStyle.playimage} />
              <Typography style={videoStyle.totalPlays}>
                {items?.attributes?.play_count}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          className="disable-select dragHandle"
          onTouchStart={(e) => {
            e.preventDefault();
            console.log("touchStart");
            //@ts-ignore
            document.body.style.overflow = "hidden";
            onTouchStart(e);
          }}
          onMouseDown={(e) => {
            document.body.style.overflow = "hidden";
            onMouseDown(e);
          }}
          onTouchEnd={(e) => {
            //@ts-ignore
            document.body.style.overflow = "visible";
          }}
          onMouseUp={() => {
            document.body.style.overflow = "visible";
          }}
        >
          <img src={menuicon} style={videoStyle.backgroundimage} />
        </Box>
      </Box>
    );
  }
}

export class PlaylistVideoItemTemplate extends React.Component<{
  dragHandleProps: any;
  item: any;
  selectedTrack: (item: any) => void;
  playlistdetailsdata: any;
  removeTrackByIndex: (id: string) => void;
}> {
  render() {
    const {
      dragHandleProps,
      item,
      selectedTrack,
      playlistdetailsdata,
      removeTrackByIndex,
    } = this.props;

    return (
      <PlaylistVideoItem
        items={item}
        dragHandleProps={dragHandleProps}
        selectedTrack={selectedTrack}
        playlistdetailsdata={playlistdetailsdata}
        removeTrackByIndex={removeTrackByIndex}
      />
    );
  }
}

export default class EditplaylistVideo extends EditPlaylistController {
  fileInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.fileInputRef = React.createRef<HTMLInputElement>();
  }

  handleIconClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  render() {
    return (
      //Merge Engine DefaultContainer
      <EditMainWrapper>
        <EditVideoleftsidecontainer>
          <TotalVideoSplits>
            <Typography style={videoStyle.trackname}>Edit Playlist</Typography>
          </TotalVideoSplits>
          <LeftsideditAlltracksbox>
            <EditvideoLeftsideAlltracksboxwrapper>
              <div ref={this.containerRef}>
                <DraggableList
                  itemKey="id"
                  // @ts-ignore
                  template={(dragHandleProps: any, item: any) => (
                    <PlaylistVideoItemTemplate
                      dragHandleProps={dragHandleProps}
                      item={dragHandleProps.item}
                      selectedTrack={this.props.selectedTrack}
                      playlistdetailsdata={this.props.playlist_details_data}
                      removeTrackByIndex={this.removeTrackByIndex}
                    />
                  )}
                  list={this.state.editData}
                  onMoveEnd={(newList: any) => this._onListChange(newList)}
                  container={() => this.containerRef.current}
                />
              </div>
            </EditvideoLeftsideAlltracksboxwrapper>
          </LeftsideditAlltracksbox>
        </EditVideoleftsidecontainer>
        <VideoBorderboxweb>
          <hr
            style={{
              height: "100%",
              width: "1px",
              border: "1px solid #212121",
            }}
          />
        </VideoBorderboxweb>
        <SinglevideoPlaylistRightideboxwrapper>
          <Singlevideoplaylistrightsidebox>
            <Box
              style={{
                width: "100%",
                borderRadius: "12px",
                alignItems: "end",
                height: "349px",
                position: "relative",
                display: "flex",
                background: `url("${this.state.coverImgURL}") lightgray 50% / cover no-repeat`,
              }}
            >
               <Upload
                name="avatar"
                showUploadList={false}
                customRequest={(options: any) =>
                  this.uploadImage(options, "avatar")
                }
                beforeUpload={this.beforeUpload}
              >
              <img
                src={Editicon}
                style={videoStyle.videoedituploadimage}
                onClick={this.handleIconClick}
              />
              <Typography style={videoStyle.videochangeImagetext}>
                Change Image
              </Typography>
              </Upload>

              <Box style={videoStyle.videoimageborder}></Box>
            </Box>
            <Box style={videoStyle.videoeditinputbuttonbox}>
              <Box>
                <Box>
                  <Typography style={videoStyle.splittext}>
                    Update Playlist Name
                  </Typography>
                </Box>
                <VideoPlyalistinputboxwrapper>
                <Input
                    prefix=""
                    placeholder="Enter Playlist Name"
                    value={this.state.playlistName}
                    onChange={this.handleOnChangePlaylist}
                    data-test-id="playlist-enter"
                  />
                </VideoPlyalistinputboxwrapper>
              </Box>

              <Box style={videoStyle.videobuttonwrapper}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                    borderRadius: "53px",
                    border: "3px solid #212121",
                    background: "#070707",
                    height: "45px",
                    padding: "1px",
                    width: "50%",
                  }}
                >
                  <Button
                  style={
                    this.state.activateTab == "Private"
                      ? videoStyle.videoprivatebutton
                      : videoStyle.videopublicbutton
                  }
                  data-test-id="playlist-activate-tab"
                  onClick={() => this.setActivateTab("Private")}
                  >
                    <span className="audio-text">Private</span>
                  </Button>
                  <Button 
                   style={
                    this.state.activateTab == "Public"
                      ? videoStyle.videoprivatebutton
                      : videoStyle.videopublicbutton
                  }
                  data-test-id="playlist-public-tab"
                  onClick={() => this.setActivateTab("Public")}
                  >
                    <span className="audio-text">Public</span>
                  </Button>
                </Box>
                <Box style={{ width: "50%", display: "flex", gap: "30px" }}>
                  <Button
                    style={{
                      borderRadius: "53px",
                      border: "3px solid #FF0501",
                      width: "50%",
                      background: "#FF0501",
                      color: "white",
                    }}
                    onClick={() => this.onSave()}
                    data-test-id="playlist-save"
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      width: "50%",
                      border: "3px solid #212121",
                      borderRadius: "53px",
                      background: "#141414",
                      color: "white",
                    }}
                    onClick={() => {
                      this.props.showDetailsPlaylistOpen("playist");
                    }}
                    data-test-id="playlist-cancel"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Singlevideoplaylistrightsidebox>
        </SinglevideoPlaylistRightideboxwrapper>
      </EditMainWrapper>

      //Merge Engine End DefaultContainer
    );
  }
}

const videoStyle = {
  videoeditinputbuttonbox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "30px",
  },
  playimage: {
    width: "12px",
    height: "12px",
  },
  backgroundimage: {
    width: "16.571px",
    height: "16.571px",
  },
  totalPlays: {
    color: "#3959F9",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
  },
  ownerStatsbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoimageborder: {
    width: "100%",
    height: "94px",
    border: "0px solid #000",
    // borderRadius: "12px",
    padding: "20px",
    background:
      "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
  },
  videochangeImagetext: {
    color: "#8F92A1",
    // textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    position: "absolute" as "absolute",
    left: "41%",
    bottom: "33%",
    whiteSpace: "nowrap" as "nowrap",
  },
  videosplittext: {
    fontSize: "16px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontFamily: "Inter",
    whiteSpace: "nowrap" as "nowrap",
  },
  videoedituploadimage: {
    position: "absolute" as "absolute",
    left: "44%",
    bottom: "45%",
    border: "2px solid white",
    borderRadius: "60px",
  },
  videobuttonwrapper: {
    display: "flex",
    // flexDirection: "column" as "column",
    gap: "15px",
    borderRadius: "53px",
    // border: "3px solid #212121",
    // background: "#070707",
    height: "53px",
    padding: "5px",
  },
  imagecover: {
    borderRadius: "10px",
    width: "125.25px",
    minWidth: "125.25px",
    height: "75px",
    objectFit:"cover" as "cover",
    border: "1px solid #141414",
  },

  singleArtinfo: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    gap: "10px",
  },
  videoleftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
  },
  leftsideAlltracksbox: {
    width: "84%",
  },
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
  },

  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    lineHeight: "20px",
    color: "#ffffff",
    fontFamily: "Inter",
    fontStyle: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
  },

  videosingleArtinfo: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  videoleftSideSingletrackbox: {
    marginTop: "6px",
    height: "75px",
    padding: "0px 14px 0px 0px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
  },

  splittext: {
    color: "#8F92A1",

    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
  },
  videoprivatebutton: {
    width: "166px",
    height: "37px",
    // flexShrink: "0",
    borderRadius: "60px",
    background: "#3959F9",
    color: "white",
  },
  videopublicbutton: {
    width: "166px",
    height: "37px",
    borderRadius: "60px",
    color: "white",
  },

  singlevideoplaylistrightsidebox: {
    width: "80%",
    display: "flex",
    gap: "30px",
    flexDirection: "column" as "column",
    "@media(max-width: 1400px)": {
      width: "80%",
      // marginTop: "10px",
      // paddingBottom:'60px'
    },
  },
};
// Customizable Area End
