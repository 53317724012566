// Customizable Area Start
import React, { useContext, useEffect, useState } from "react";
import {
  addToPlaylist,
  dealTrack,
  defaultImage,
  nxzloader,
  pauseA,
  playButton,
  repostIcon,
  shareTrack,
} from "../../assets";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Box } from "@material-ui/core";
import axios from "axios";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
import ScanQrDialog from 'components/src/CustomChart/PopUps/ScanQrDialog.web';
import PlaylistCompAudioQueue from "./PlaylistCompAudioQueue.web";
import { AudioContextApi } from "../../AudioContext/AudioContext.web";
import AddToQueuePopup from "../../CustomComponent/AddToQueue/AddToQueuePopup.web";
import SharePlaylistPopup from "../../CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web"
import { useHistory } from "react-router-dom";
import { expandAudioDeal } from "components/src/CustomChart/SingleTrack/CarasouelProfile.web";



const urlConfig = require("../../../../../framework/src/config");
const baseUrl = urlConfig.baseURL;


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "13px",
    fontFamily: "Inter",
  },
}));

function MuiTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const CarasouelProfile = (props: any) => {
  const {
    artWork,
    artistImage,
    title,
    artistName,
    handleChartTrackRepost,
    trackId,
    handlerFetchRepost,
    PlaylistData,
    selectedType,
    allPlaylist,
    allPlaylistSongs
  }: any = props;

  const token: any = localStorage.getItem("token")
  const [loading, setLoading] = useState<boolean>(false);
  const [repostPopup, setRepostPopup] = useState<boolean>(false);
  const [repostTextValue, setRepostTextValue] = useState<string>("");
  const [ScanQropenPopUp, setScanQropenPopUp] = useState<boolean>(false);

  const [userplaylist, setUserplaylist] = useState<any>("")
  const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false);
  const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false);
  const [openAlertLogin, setopenAlertLogin] = useState(false);
  const [emptyPlaylist, setEmptyPlaylist] = useState(false);
  const history=useHistory()

  const {handleAudioVideo, setHandleAudioVideo, handlePlayList} = useContext(AudioContextApi)
  useEffect(() => {
    if (trackId) {
      handlerFetchRepost(trackId);
    }
  }, [trackId])

  const handleRepostClose = () => {
    setRepostPopup(false);
  };



  const respostSubmit = async () => {
    handleChartTrackRepost(trackId, repostTextValue);
    setLoading(true);
    await handlerFetchRepost(trackId);
    setLoading(false);
    handleRepostClose();
  };

  const getUserPlaylist = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_playlist/play_lists`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((response: any) => {
        setLoading(false);
        setUserplaylist(response.data.data);

      })
      .catch((err: any) => {
        setLoading(false);
        err?.response.status === 422 && setEmptyPlaylist(true);
      });
  };

  const handleAddToPlaylist = () => {

    if (token) {
      setOpenPlaylistModal(true);
      getUserPlaylist();
    } else {
      setopenAlertLogin(true);
      setDialogTextAlertLogin(token ? "1" : "2");
    }
  }

  const handleClosePlaylistModal = () => {
    setOpenPlaylistModal(false)
}

  const handleListSongs = () =>{
    if(allPlaylistSongs.length > 0){
      return allPlaylistSongs
    }else{
      return allPlaylist
    }
  }

  return (
    <>
      {
        loading ?
          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50%" }}>
            <img src={nxzloader} height="50px" width="50px" />
          </Box>

          : <>
            <div style={{ position: "relative" }}>
              <div className="carasouel_profile_position">
                <img
                  src={artWork || artistImage || defaultImage}
                  alt="bg images"
                  className="blur_bg_carasouel_profile_img"
                />
                <div className="blur_bg_carasouel_profile_img_border"></div>
              </div>
              <div className="carasouel_profile_bg_text">
                <div onClick={() => {handlePlayList(PlaylistData, 0, handleListSongs(), selectedType)}}>
                  <img
                    src={artWork || artistImage || defaultImage}
                    alt="bg images"
                    className="track-image-dialog"
                  />
                  <div test-id="setting_handle_audio_id" onClick={() =>{setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1)}}>
                    <img src={handleAudioVideo == 2 ? pauseA : playButton} className="playbtn_image" />
                  </div>
                </div>

                <span className="track_title_name">{title}</span>

                <div className="track_username_info">
                  <span className="track_username_font">{artistName}</span>
                  {/* <img
              src={validIcon}
              alt='checkmark'
              style={{ width: '12px', height: '12px' }}
            /> */}
                </div>

                <div className="display_play_icon">
                  <MuiTooltip title="Repost" placement="bottom">
                    <img
                    test-id = "setting_id_repost_add_to_queue"
                      src={repostIcon}
                      alt="repostIcon"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      onClick={() => setRepostPopup(true)}
                    />
                  </MuiTooltip>
                  {repostPopup && (
                    <RepostPopup
                      open={repostPopup}
                      onClose={handleRepostClose}
                      profileImage={artWork}
                      artistName={artistName}
                      songName={title}
                      repost_count={respostSubmit}
                      _handleTextFieldChange={(e: any) =>
                        setRepostTextValue(e.target.value)
                      }
                    />
                  )}

                  <MuiTooltip title="Playlist" placement="bottom">
                    <img
                    test-id="add_to_queue_handle_add_to_playlist"
                      src={addToPlaylist}
                      alt="addToPlaylist"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      onClick={handleAddToPlaylist}
                    />
                  </MuiTooltip>

                  <MuiTooltip title="Share" placement="bottom">
                    <img
                    test-id = "handle_scan_qr_id_add_to_queue"
                      src={shareTrack}
                      alt="shareTrack"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      onClick={() => setScanQropenPopUp(true)}
                    />
                  </MuiTooltip>
                  {/* <ScanQrDialog
                    openPopup={ScanQropenPopUp}
                    handleOpenPopup={() => setScanQropenPopUp(false)}
                    shareItem={window.location.href.includes('playlists') ? "Playlist" : "Track"}
                    TrackId={PlaylistData && PlaylistData.id}
                    playData={PlaylistData}
                    handleChartTrackRepost={handleChartTrackRepost}
                  /> */}

<SharePlaylistPopup
          id="Track"
          open={ScanQropenPopUp}
          handleClose={() => setScanQropenPopUp(false)}
          track={PlaylistData}
        />

                  <MuiTooltip title="Deal" placement="bottom">
                    <img
                     onClick={()=> expandAudioDeal(history, PlaylistData)}
                      src={dealTrack}
                      alt="dealTrack"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "not-allowed",
                      }}
                    />
                  </MuiTooltip>
                </div>
              </div>
            </div>

            {
                openPlaylistModal && 
                <AddToQueuePopup id='charts' open={openPlaylistModal} handleClose={handleClosePlaylistModal} playlistType="audio" trackData={PlaylistData} />
             }
          </>
      }
    </>
  );
};

export default CarasouelProfile;
// Customizable Area End
