// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import './ConfirmplanAndPay.css';
import './UserCurrentPlan.css';
import CardInformations from './CardInformations.web';
import  PlanUpdate  from './PlanUpdate.web';
import ConfirmPlanAndPayController from './ConfirmPlanAndPayController'
import moment from 'moment';
//Next Payment Due
//Invalid date- hide
export default class  ConfirmplanAndPay extends ConfirmPlanAndPayController{
 render(){
  const { handleClose, planName, planSubscription, openConfirmPaymentPopup, closePayModal, currentPaymentData, next_invoice_no }=this.props

  return (
    <>
      {/* <Modal open={open} onClose={handleClose} className='PlanDialog'>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
          }}>
          <Hidden smDown>
            <AsideLeft />
          </Hidden> */}
      <Grid container justifyContent='center' alignItems='center'>
        <Grid
          item
          lg={8}
          md={10}
          sm={10}
          xs={10}
          justifyContent='space-evenly'
          alignItems='center'
          container
          className='confirmMain'>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={12}
            xs={12}
            className='current_Info'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='currentPlan_text'>Plan Switching To</span>
              {/* <img src={creative} alt={'creative'} width='200px' /> */}
              {planName}
            </div>
            <div className='subscriber_content'>
              <span className='subscriber_title'>Subscription:</span>
              <span className='subscriber_plan_rupees'>${planSubscription}</span>
            </div>
            <div className='subscriber_content'>
              <span className='subscriber_title'>Next Payment Due</span>
              <span className='m_subscriber_plan_rupees'>{next_invoice_no?moment(next_invoice_no, "MMM-DD-YYYY").format("MMM DD,YYYY"):"----"}</span>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div style={{ margin: '0px 10px 15px' }}>
              <CardInformations
                currentPaymentData={currentPaymentData}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '10px',
              }}>
              <button
                className='pay_button'
                style={{ marginBottom: '15px' }}
                onClick={() => {
                  this.handleButtonClick()
                }} >
                PAY
              </button>
              <PlanUpdate
                open={openConfirmPaymentPopup}
                handleClose={() => closePayModal}
              />
              <button
                className='keepmyplan_button'
                style={{ marginBottom: '10px' }}
                onClick={handleClose}>
                Keep My Plan
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* </Box>
      </Modal> */}
    </>
  );
    }
};
// Customizable Area End