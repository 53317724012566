// Customizable Area Start
import React from "react"

import { createBrowserHistory } from "history";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import {HISTORY} from "../../../components/src/common";
import { openNotification } from "../../../components/src/Notification.web";


export const configJSON = require("../../user-profile-basic/src/config");

const history = createBrowserHistory()
export interface Props {
  props?: any;
  navigation?: any;
}
interface S {
    apiToken: any;

}
interface SS {
  id: any;
}

export default class LabelAccountRegistrationController extends BlockComponent<Props, S, SS> {
    
    postLabelDetailsApiCallID: string = "";

    constructor(props: Props) {
        super(props);
        
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
    
        this.state = {
            apiToken: localStorage.getItem("token"),
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      super.componentDidMount();
    }

    

    postLabelDetails = (values: any) =>{
      const header = {
        "Content-Type": "application/json",
        'token': this.state.apiToken
      };

      const data = {
      type: "email_account",
      user_type: "label",
      user_name: values.username,
      full_name: values.full_name,
      full_phone_number: `+${values.phone}`,
      email: values.email,
      // email: location.email ? location.email : values.email,
      password: values.password,
      password_confirmation: values.password,
      spotify_id: values.spotify,
      instagram_id: values.instagram,
    };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.postLabelDetailsApiCallID = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/account_block/accounts?devise_type=web`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getTopPlaylistApiSuccessCall = (response: any) =>{
      if(!response.errors){
        localStorage.setItem("token", response.meta.token)
        HISTORY.push("/TermAndConditions")
       // this.setState({TopPlaylists: response?.data})
      }else{
        openNotification(Object.values(response?.errors[0]), "Error")
      }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
    
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
         if( apiRequestCallId === this.postLabelDetailsApiCallID){
          this.getTopPlaylistApiSuccessCall(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }

      }
}
// Customizable Area End