// Customizable Area Start
import React from "react";
import "./Index.css";
import { Grid, Hidden } from "@material-ui/core";
import CardInformations from "./CardInformations.web";
import UserCurrentPlan from "./UserCurrentPlan.web";
import UserCardAndCurrentPlanController from "./UserCardAndCurrentPlanController";

import { creativeplus } from "../assets";

export default class UserCardAndCurrentPlan extends UserCardAndCurrentPlanController {
  render() {
    const { currentplan, cancelledSubscription, next_invoice_no, currentPaymentData, status_of_plan } = this.props;
  
    return (
      <>
        <Hidden only={["xs"]}>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <Grid
              container
              style={{
                borderRadius: "20px",
                backgroundColor: "#040404",
                height: "700px",
                display: "flex",
                alignContent: "space-evenly",
                padding: "15px",
              }}
            >
              <>
                <CardInformations currentPaymentData={currentPaymentData} />
                <Grid item xs={12} className="updateCardbtn">
                  <button onClick={this.props.handleUpdateCard}>
                    {currentPaymentData?.card ? "Update Card" : "Add Your Card"}
                  </button>
                </Grid>
              </>
              {/* } */}

              {/* </div> */}
              {Object.keys(currentplan).length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <UserCurrentPlan
                      title={"My Current Plan"}
                      userType={currentplan?.attributes?.name}
                      price={`${currentplan?.attributes?.amount} ${currentplan?.attributes?.interval}`}
                      nextPaymentDue={next_invoice_no.replace(/-/g, " ")}
                      creativeSrc={creativeplus}
                      creativeAlt="creativePlus"
                      current_plan={true}
                      cancelSubscription={this.props.cancelSubscription}
                      cancelledSubscription={cancelledSubscription}
                      closeCancelledModal={this.props.closeCancelledModal}
                      status_of_plan={status_of_plan}
                      data=""
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <UserCurrentPlan
                    title={"My Current Plan"}
                    data="Currently no plans are activated in your account"
                    current_plan={false}
                    userType="no-plan"
                    price={""}
                    nextPaymentDue={""}
                    creativeSrc={""}
                    creativeAlt="creativePlus"
                    cancelSubscription={{}}
                    cancelledSubscription={""}
                    closeCancelledModal={{}}
                    status_of_plan={""}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Hidden>
      </>
    );
  }
}
// Customizable Area End
