// Customizable Area Start
import React , { createRef } from 'react'
import { Grid, Box,  Typography } from "@material-ui/core";
import {arrowup, prevIC, nextIC} from '../../../../../blocks/dashboard/src/assets';
import './DashboardRevenue.css'
import DashboardRevenueChart from './DashboardRevenueChart.web';
import { Carousel } from "antd";
import DashboardRevenueController from './DashboardRevenueController.web';
import { CarouselRef } from 'antd/lib/carousel';

const settings = {

  autoplay: false,
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 7,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  responsive: [
    {
      breakpoint: 1480,
      settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
          initialSlide: 2
      }
  },
    {
      breakpoint: 1350,
      settings: {
          slidesToShow: 3.8,
          slidesToScroll: 1,
          initialSlide: 2
      }
  },
    {
      breakpoint: 1300,
      settings: {
          slidesToShow: 3.8,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 1250,
      settings: {
          slidesToShow: 3.9,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 1200,
      settings: {
          slidesToShow: 3.7,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 1150,
      settings: {
          slidesToShow: 3.4,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    
    {
      breakpoint: 1100,
      settings: {
          slidesToShow: 3.25,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 1050,
      settings: {
          slidesToShow: 3.1,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 1015,
      settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 959,
      settings: {
          slidesToShow: 3.8,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 950,
      settings: {
          slidesToShow: 3.7,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 900,
      settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 850,
      settings: {
          slidesToShow: 3.2,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 800,
      settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 750,
      settings: {
          slidesToShow: 2.9,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 700,
      settings: {
          slidesToShow: 2.7,
          slidesToScroll: 2,
          initialSlide: 2
      }
  },
    {
      breakpoint: 650,
      settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          initialSlide: 2
      }
  },
      {
          breakpoint: 610,
          settings: {
              slidesToShow: 2.4,
              slidesToScroll: 1,
              initialSlide: 2
          }
      },
      {
          breakpoint: 590,
          settings: {
              slidesToShow: 2.3,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 560,
          settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 535,
          settings: {
              slidesToShow: 2.1,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 515,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 500,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 485,
          settings: {
              slidesToShow: 1.9,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 462,
          settings: {
              slidesToShow: 1.8,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 440,
          settings: {
              slidesToShow: 1.7,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 418,
          settings: {
              slidesToShow: 1.6,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 398,
          settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 373,
          settings: {
              slidesToShow: 1.4,
              slidesToScroll: 1
          }
      },
      {
        breakpoint: 348,
        settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1
        }
    },
    {
      breakpoint: 329,
      settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1
      }
  }


  ]

};


export default class DashboardRevenue extends DashboardRevenueController {
  carouselRef = createRef<CarouselRef>()
  render() {
    return (
      <Box sx={{ width: "100%" }}>

     
        <Box className='mobile_revenue' style={{position:"relative"}}>

        
          <Carousel {...settings} infinite={false} ref={this.carouselRef}>


            {this.state.revenueStats.map((stats:any, index:any) => (
              <Box style={{width:'255px'}}>
              <Box className='mobile_revenue_topheader' key={index}>
                <div className='revenue_topheader_title'>{stats.type}</div>
                <Typography className='revenue_topheader_text'>{stats.amount}</Typography>
                <Grid item className='arrow_up_lable'>
                  <img className='arrow_up' src={arrowup} />
                  <Typography className='arrow_up_text'>{stats.revenuepercent}</Typography>
                </Grid>
              </Box>
              </Box>
            ))}
          </Carousel>
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "1%",
              transform: "translateY(-50%)",
              cursor: "pointer"

            }}
            onClick={() => this.carouselRef.current?.prev()}
            data-test-id="previousbuttoncarasouel"
          >
            <img src={prevIC} style={{ height: "30px", width: "30px" }} />
          </p>

          <p 
          className='prevcara'
            style={{
              position: "absolute",
              top: "36%",
              left: "90%",
              cursor: "pointer",
              right: 0
            }}
            onClick={() => this.carouselRef.current?.next()}
            data-test-id="nextbuttoncarasouel"
          >
            <img src={nextIC} style={{ height: "27px", width: "27px" }} />
          </p>
   
          </Box>
     
       
    
      <Box className='revenue_topheader_containers'>
     
     {this.state.revenueStats.map((item:any, index:any) => (
       <Box className='revenue_topheader' key={index}>
         <div className='revenue_topheader_title'>{item.type}</div>
         
         <Typography className='revenue_topheader_text'>{item.amount}</Typography>
         <Grid item className='arrow_up_lable'>
           <img  className='arrow_up' src={arrowup} />
           <Typography className='arrow_up_text'> {item.revenuepercent} </Typography>
         </Grid>
       </Box>
     ))}

   </Box>
    
      <Box  className='revenue_topheader_container'>
        <Box  className='revenue_leftside_wrapper'>
          {this.state.yearStats.map((item:any, index:any) => (
            <Box className='revenue_leftside_element_box'>
              <Box style={{width:"40%"}}><Typography className='revenue_month'>{item.month}</Typography></Box>
              <Box style={{width:"20%"}}>  <img src={item.img} className='revenue_arrow'/></Box>
              <Box style={{width:"40%"}}><Typography className='revenue_sales_data'>{item.amount}</Typography></Box>
            </Box>
          ))}

        </Box>
        <Box className='revenue_rightside_content'>
          <Box className='revenue_right_box'>
          <div  className='revenue_balance revenue_balance_media' style={{width:"50%"}}>
          <span className="balance_text">BALANCE</span>
        </div>
        <div>
        <span className="revenue_amount">
       $1,36,58<span style={{fontSize:"22px"}}>.19</span>
        </span>
        </div>
        <div>
        <span className="revenue_amount_data revenue_amount">
        <span  className='plus_icon'>+</span >135%
         </span>
        </div>
        <div className='revenue_chart'>
          <DashboardRevenueChart/>
        </div>
        <div className="revenue_lastquarter">
        <span className="Last-Quarter">
        Last Quarter
        </span>
        </div>
          </Box>
        
        </Box>
      </Box>
    </Box>
    )
  }
}




// Customizable Area End