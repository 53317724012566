// Customizable Area Start
import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { getStorageData } from "../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";
export const configJSON = require("../config.js");
export interface Props {
  window_width?: any;
  handleAudioVideos?: number;
  selected?: any;
  playlistLoading?: boolean;
  selectedPre?: boolean;
  playList?: any;
  songsList?: any;
  value: number;
  activityList?: any;
  musicButton?: boolean;
  open?: boolean,
  openScanQr?: boolean,
  seeAll?: boolean,
  TrackDetails?: any;
  isHover?: boolean;
  songByName?: any;
  dashboardData?: any;
  token?: string;
  errorMsg?: string;
  loading?: boolean;
  toggleComment?: boolean;
  commentTabs?: any;
  beatList?: any;
  top_playlistVideo?: any;
  playlistVideo?: any;
  getTopPlaylists?: any;
  apiToken?: any;
  followerData?: any;
  loader?: boolean;
  creativeById?: any;
  openPopupFollowers?: boolean,
  openPopupFollowing?: boolean,
  loadingFollow?: boolean;
  unfollowState?: boolean;
  userFollowerListData?: any;
  visible?: boolean;
  getTop100?: any;
  beatErrorResponse?: any;
  topTrackErrorResponse?: any;
  playPlaylist?: boolean;
  playlistData?: any;
  checkType?: any;
  selectedPlayList?: any;
  openAlertPopUp?: boolean;
  OpenUpgradModal?: boolean;
  dialogText?: any;
  slickCount?: any;
  handletokenError?: boolean;
  handleAudioVideo?: any;
  creativeId?: any;
  tabLoading?: any;
  crativeIdtabLoading?: any;
  playlistGraph?: any;
  userType?: any;
  subscription_plans?: any;
  handleplay?: boolean;
  filterValue?: any;
  videoList?: any;
  tabPanelList?: any;
  playLists?: any;
  tabName?: any
  listenertabName?: any
  profileCountData?: any;
  gridSizes?: any;
  new_released_video?: any;
  get_All_Activity_data?: any;
  getTopVideos?: any;
  get_notification?: any;
  getCreativeTabsLoader?: any;
  followingData?: any;
  allFollowerData?: any;
  listenerValue?: any;
  profileLoader?: any;
  followstatuscheck?: any;
  navigation?:any;
  checkCurrentTab: (event:any, newValue: any) => void;
  handleRedirectPlaylist: (id:any) => void;
  handleCardPlaylist: (id:any) => void;
  handleTrackPlay: (data: any, index: any, songsList: any, name: any) => void;
  handleFilter: (value:any) => void;
  handleClose: () => void;
  handleCloseOpenUpgradModal: () => void;
  handleUpgradeModal: () => void;
  checkCurrentOriginalsTab:(event:any, newValue: any)=>void;
  handlePlayList:(data: any, index: number, arr: [], id: any, type: any, name: any)=>void;
  setMusicBtn:(data:any)=>void;
  handleReadNotification:(id:any,userType:any,notificationType:any,userid:any)=>void
}
interface S {
 
}
interface SS {
  id: any;
}

interface AdActitvityResponse{
  error:string;
  message:string
}
export default class ComposeProfileController extends BlockComponent<Props, S, SS> {
  apiAdActivityCallId:any;
  myRef: any;
  nextRef: any;
  vedioPlayer: any;
  previousHref !: string;
  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    this.nextRef = React.createRef();
    this.vedioPlayer = React.createRef()
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

   

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleAdActivityClick=async(id:string,activity:string)=>{
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };
    let paramsBody={
      id:id,
      status:activity
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAdActivityCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_promotecontent/ad_campaigns/activity_in_ad_campaign'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(paramsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAdActivityCall=(responseJson:AdActitvityResponse)=>{
    if (responseJson !== undefined && !responseJson.error) {
      return
  }else{
      openNotification(`Activity for Campaign throwing ${responseJson.error} `,"Error")
  }
  }

  handleNavigation=(navigationData:any)=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(getName(MessageEnum.NavigationTargetMessage), 'ChartsVideo');
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), navigationData);
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
    }
  
  // Customizable Area End



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      )
    if (apiRequestCallId === this.apiAdActivityCallId) {
        this.handleAdActivityCall(responseJson);
          
    }
  }

    // Customizable Area End
  }
}
// Customizable Area End