// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createBrowserHistory } from "history";
import React from "react";
import { HISTORY } from "../../../../components/src/common";
import { openNotification } from "../../../../components/src/Notification.web";
import axios from "axios";
import { AudioContextApi } from "../AudioContext/AudioContext.web";

const urlConfig = require("../../../../framework/src/config");
const baseUrl = urlConfig.baseURL;

const tokenStr: any = localStorage.getItem("token");



export interface Props {
  navigation: any;
  id: string;
}
interface S {

  dashboardData: any;
  token: any;
  errorMsg: string;
  loading: boolean;
  repostName: string;
  allPlaylist: any;
}
interface SS {
  id: any;
}

export default class QueueController extends BlockComponent<Props, S, SS> {

  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";



  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      loading: false,
      token: tokenStr,
      repostName: "",
      allPlaylist: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }



  handleChartTrackRepost = async (trackId: any, caption: any) => {
    await axios
      .post(
        `${baseUrl}/bx_block_audiomusic/repost_count?id=${trackId}&caption=${caption}`,
        null,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: this.state.token,
          },
        }
      )
      .then((res: any) => {
        openNotification("Successfully Reposted", "Success");
      })
      .catch((err) => { })
      .finally(() => { });
  };

  handlerFetchRepost = async (trackId: any) => {
    await axios
      .get(`${baseUrl}/bx_block_audiomusic/expan_track?id=${trackId}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: tokenStr,
        },
      })
      .then((res) => {

        this.setState({
          ...this.state,
          repostName: res.data.data.attributes.repost_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // Customizable Area End
}
// Customizable Area Start
QueueController.contextType = AudioContextApi;
// Customizable Area End
