// Customizable Area Start
import React, { forwardRef, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import { Hidden, Dialog } from "@material-ui/core";
import {
  Cast,
  Forward10,
  FullscreenExit,
  FullscreenExitRounded,
  FullscreenRounded,
  PictureInPictureAltRounded,
  Replay10,
} from "@material-ui/icons";
import { nxzloader } from "../../../blocks/dashboard/src/assets";
import { unlockRedirectionCheck } from "blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web";
import UnlockContentCommon from "../../../blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web";
import './Carousel/index.css'


const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.1)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",

    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    padding:"0px",
    "&:hover": {
      color: "#fff",
    },
  },

  volumeSlider: {
    width: 73,
  },
}));

const AdPrettoSlider = withStyles({
  root: {
    height: 3,
    padding: 0,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "yellow",
    border: "2px solid yellow",
   

    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    backgroundColor: "yellow",
  },
  rail: {
    display: "none",
  },
})(Slider);

const PrettoSlider = withStyles({
  root: {
    height: 3,
    padding: 0,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid #fff",
   

    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    backgroundColor: "#fff",
  },
  rail: {
    display: "none",
  },
})(Slider);

const VolumeSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
   
    display: "none",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#ff0000",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#3a4045",
    border: "4px solid #3a4045",
    opacity: 1,
  },
})(Slider);

function ValueLabelComponent(props: any) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const VideosControls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRewind,
      onPlayPause,
      selectedVideo,
      onFastForward,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      onToggleFullScreen,
      volume,
      onVolumeChange,
      onBookmark,
      showFullScreen,
      onPipMode,
      playVideoFromRedirection,
      topVideo,
      playingAd,
      playingVideo,
      isPlayingAd,
      playerRef
    }: any,
    ref: any
  ) => {
    const classes = useStyles();
    const handlerVolumeButton = () => {
      if (muted) {
        return <VolumeMute style={{ color: "white" }} />;
      }

      return volume > 0.5 ? (
        <VolumeUp style={{ color: "white" }} />
      ) : (
        <VolumeDown style={{ color: "white" }} />
      );
    };

    const [openCastPopUp, setOpenCastPopUp] = useState(false);
    const [openPipPopUp, setOpenPipPopUp] = useState(false);
    const [isAdPlayDisable, setIsAdPlayDisable] = useState(false);
    const [skipCount, setSkipCount]  = useState(0)

    useEffect(() => {
      let timeoutId: ReturnType<typeof setTimeout>
      if(playingVideo.push_content_type == "display_ad"){
        setSkipCount(0)
      }

      if(playerRef.current){
      if (playingVideo.push_content_type == "display_ad" && skipCount ==0 && playerRef?.current.getCurrentTime() < 16) {
        setIsAdPlayDisable(true);
        timeoutId = setTimeout(() => {
          setIsAdPlayDisable(false);
          setSkipCount(1)
        }, 15000);
      } else {
        setIsAdPlayDisable(false);
      }
     }
  
      return () => clearTimeout(timeoutId);
  
    }, [playingAd, playing,topVideo]); 

   
    const handleCastPopUp = () =>{
      setOpenCastPopUp(true)
     }
    
     const handleCloseCastPopUp = () =>{
      setOpenCastPopUp(false)
     }

     
    
     const handleClosePipPopUp = () =>{
      setOpenPipPopUp(false)
     }


    return (
      <div ref={ref} className={classes.controlsWrapper}>
        
        {
          topVideo != null &&
          (
<Grid
          container
          direction="column"
          justifyContent="flex-end"
          style={{ flexGrow: 1 }}
        >
          
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            

            <Grid container item xs={12} alignItems="center">
              {playingAd ?
              (<AdPrettoSlider
              min={0}
              max={100}
              ValueLabelComponent={(props) => (
                <ValueLabelComponent {...props} value={elapsedTime} />
              )}
              aria-label="custom thumb label"
              value={played * 100}
              onChange={onSeek}
              onMouseDown={onSeekMouseDown}
              onChangeCommitted={onSeekMouseUp}
            />)
            :
            (<PrettoSlider
                min={0}
                max={100}
                ValueLabelComponent={(props) => (
                  <ValueLabelComponent {...props} value={elapsedTime} />
                )}
                aria-label="custom thumb label"
                value={played * 100}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
              />)
              }
              
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                style={{
                  backgroundColor: "black",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  padding: '1px'
                }}
              >
                <Hidden xsDown>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    container
                    alignItems="center"
                  >
                    <Button
                      variant="text"
                      onClick={
                        onChangeDispayFormat
                     
                      }
                    >
                      <span className="elapsedTimeFont">
                        {elapsedTime}
                      </span>
                    </Button>

                    <IconButton
                      onClick={onMute}
                      className={`${classes.bottomIcons}`}
                    >
                      {handlerVolumeButton()}
                    </IconButton>
                    <VolumeSlider
                      min={0}
                      max={100}
                      value={muted ? 0 : volume * 100}
                      onChange={onVolumeChange}
                      aria-labelledby="input-slider"
                      className={classes.volumeSlider}
                      onMouseDown={onSeekMouseDown}
                      onChangeCommitted={onVolumeSeekDown}
                    />
                  </Grid>
                </Hidden>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                   { !isAdPlayDisable &&

                  <IconButton
                    onClick={onRewind}
                    className={classes.controlIcons}
                    aria-label="rewind"
                    style={{ padding: "0px 5px" }}
                    disabled={isPlayingAd}
                  >
                   
                    <Replay10 style={{ color: "white" }} fontSize="large" />
                  </IconButton>
                  }
                  <IconButton
                    onClick={() => unlockRedirectionCheck(onPlayPause, "", selectedVideo)}
                    className={classes.bottomIcons}
                    style={{ padding: "0px 5px" }}
                  >
                    <UnlockContentCommon trackData={selectedVideo} padding="3px"/>
                    {
                    playing ? (
                      <PauseIcon style={{ color: "white" }} fontSize="large" />
                    ) : (
                      <PlayArrowIcon
                        style={{ color: "white" }}
                        fontSize="large"
                      />
                    )}
                  </IconButton>
                  { !isAdPlayDisable &&
                  <IconButton
                    onClick={onFastForward}
                    className={classes.controlIcons}
                    aria-label="forward"
                    style={{ padding: "0px 5px" }}
                    disabled={true}
                  >
                    <Forward10 style={{ color: "white" }} fontSize="large" />
                  </IconButton>
  }
                </Grid>

                <Hidden smDown>
                  <Grid
                    item
                    xs={6}
                   
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    

                    <IconButton style={{ padding: "0px 5px" }} onClick={handleCastPopUp}>
                      <Cast style={{ color: "white" }} />
                    </IconButton>
                    {
                      openCastPopUp &&
                      <Dialog open={openCastPopUp}
                       onClose={handleCloseCastPopUp}
                       PaperProps = {{className: "dialog-Paper-Deal"}}
                      >
                           <div style={{color: 'white', fontSize: '20px', fontWeight: 'bold', padding: '40px'}}>Screen Cast <img src={nxzloader} style={{ width: "20px", height: '20px' }} /> coming soon </div>
                      </Dialog>
                    }
                    <IconButton style={{ padding: "0px 5px" }}>
                      <PictureInPictureAltRounded
                        style={{ color: "white" }}
                        onClick={() => onPipMode()}
                      />

     
                    </IconButton>

                    {
                      openPipPopUp &&
                      <Dialog open={openPipPopUp}
                       onClose={handleClosePipPopUp}
                       PaperProps = {{className: "dialog-Paper-Deal"}}
                      >
                           <div style={{color: 'white', fontSize: '20px', fontWeight: 'bold', padding: '40px'}}> Feature <img src={nxzloader} style={{ width: "20px", height: '20px' }} /> coming soon </div>
                      </Dialog>
                    }
                   

                    <IconButton
                      onClick={onToggleFullScreen}
                      className={classes.bottomIcons}
                      style={{ padding: "0px 0px" }}
                    >
                      {showFullScreen ? (
                        <FullscreenExitRounded style={{ color: "white", }} className="control_icon" />
                      ) : (
                        <FullscreenRounded style={{ color: "white" }} className="control_icon" />
                      )}
                    </IconButton>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
          )
        }

        
      </div>
    );
  }
);

export default VideosControls;
// Customizable Area End