// Customizable Area Start

import { Typography, Row } from 'antd';
import {
    Box,
    Button,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme
} from "@material-ui/core";
import React from 'react'
import { bluecheck } from '../assets';
import StreamAudioVideoController from './StreamAudioVideoController';
import { defaultProfile, nxzloader } from 'blocks/dashboard/src/assets';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
const RightsideAudiohistorycontainer = styled(Box)({
    width: "48%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
"@media(max-width: 1000px)": {
    width: "100%",
    padding: "0px 10px 0px 10px"
},

});

const RightSideSingletrackbox = styled(Box)({
    height: "81px",
    borderRadius: "8px",
    gap:"10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    padding: "0px 10px 0px 5px",
    color: "white",
    "&:hover": {
    },
});
const Trackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",


});
export default class StreamAudioVideo extends StreamAudioVideoController {
    TracklistDatainfo=(webStyle:any,rightsideSelectedtab:any,usertype:any,isLoading:any,tracklistData:any,isListener:any)=>{
        return (
            <>
            {
            tracklistData.length>0?
                        tracklistData.map((item:any, index:number) => (
                            <RightSideSingletrackbox
                            key={index}
                            data-test-id="topMusicPlay"
                            onClick={() => {
                                this.handleRedirectionOfAudioVideo(item, index)
                              }}
                            >
                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                        {rightsideSelectedtab == "Audio" ? <img
                                            src={item?.attributes?.art_work||defaultProfile}
                                            style={webStyle.leftimage}
                                    /> : <img
                                        src={item?.attributes?.art_work||defaultProfile}
                                        style={webStyle.leftvideoimage}

                                    />}
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box>
                                        <Trackname className="tracks_songs_text">
                                        {item.attributes.artist_name}
                                        {item?.attributes?.is_verified &&
                                            <span className="Jason-Derulo">

                                                <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                            </span>
 }
                                        </Trackname>
                                        <Box>
                                            <span style={webStyle.rightsidesplittext}>
                                            {item?.attributes?.title}

                                            </span>
                                        </Box>

                                    </Box>
                                    <Box>
                                        <Box style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        }}>
                                            <span style={webStyle.rightsidesplittextwhite}>
                                            {this.props.getCountValue(item)}
                                            </span>
                                        </Box>
                                    </Box>

                                </Box>
                                <Box>
                                    <BorderLinearProgress variant="determinate" value={this.getProgress(item)} />
                                </Box>
                            </Box>
                         

                        </RightSideSingletrackbox>
                    ))
                :
                <div
                                style={{
                                    color: "#ffffff",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    paddingTop: "132px",
                                    justifyContent:"center",
                                    display:"flex",
                                }}
                            >
                                No Stream History Available
                            </div> }</>
    )
}
render() {
    const {webStyle,rightsideSelectedtab,usertype,isLoading,tracklistData,isListener}=this.props
  
    return (
        <RightsideAudiohistorycontainer style={{ paddingTop: (usertype !== "listener" && usertype !== "listener_plus") ? "100px" : "0px" }}>
                <Box>
                    <Box style={{ width: "100%" }}>
                        <Box style={webStyle.Rightsidetabsbutton}>
                            <Box style={webStyle.tabbox}>
                                <Button

                                    style={rightsideSelectedtab == "Audio" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}

                                    onClick={() => this.props.handleAudiovideotab("Audio")}
                                    data-test-id="audiotab"
                                >
                                    Audio
                                </Button>
                            </Box>
                            <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    style={rightsideSelectedtab == "Video" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                    onClick={() => this.props.handleAudiovideotab("Video")}
                                    data-test-id="Myvideotab"
                                >
                                    Video
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={(usertype !== "listener" && usertype !== "listener_plus")? {paddingTop:"0px"} : {paddingTop:"22px"} }>
                    <Typography style={webStyle.trackname}>
                        {" "}
                        <span style={webStyle.bluetext}>
                        {!usertype.includes("listener") ? this.props.streamedArtist||"" : "You:"}
                        </span>{" "}
                        Streamed
                    </Typography>
                    {isLoading ? (

                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                        <img src={nxzloader} style={{ width: "5%" }} />
                        </Row>

                        ) :
                        this.TracklistDatainfo(webStyle,rightsideSelectedtab,usertype,isLoading,tracklistData,isListener)
                }
                </Box>
            </RightsideAudiohistorycontainer>

    )
}

}

StreamAudioVideo.contextType = AudioContextApi;
// Customizable Area End