// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import millify from "millify";
import {ArrowUp, PlayIcon, expandMore, messageIcon, playlistProfile, repeatIcon} from 'blocks/dashboard/src/assets'
import LabelTopSongsController from './LabelTopSongsController.web';
export default class LabelTopAudioPlaylistMobile extends LabelTopSongsController {
    render(){
    const {musicButton, item} = this.props;
    return(
        <>
        <Grid style={{display: 'flex', flexDirection: 'column', backgroundColor: '#141414', padding: '5px 10px', borderRadius: '10px'}}>
        <Grid style={{display: 'flex', justifyContent: 'space-between', padding: '15px 0px'}}>
            <Grid style={{display: 'flex'}} >
                <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{color: 'white', fontSize: '16px'}}>1</div>
                <img src={ArrowUp}/>
                </Grid>
                <Grid style={{margin: '0px 10px', borderRadius: '10px'}}>
                    <img style={{height: '48px', width: musicButton ? '80px' : '48px', borderRadius: '10px'}} src={item?.attributes?.cover_image}/>
                </Grid>
                <Grid style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{color: 'white', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold'}}>{item?.attributes?.name}</div>
                    <div style={{color: 'white', fontFamily: 'Karla', fontSize: '14px', fontWeight: 'bold'}}>{item?.attributes?.playlist_items?.data.length > 0 ? item?.attributes?.playlist_items?.data.length + ' Tracks' : '0 Tracks'}</div>
                </Grid>
            </Grid>
            <Grid style={{display: 'flex'}}>
                <Grid>
                <img style={{height: '28px', width: '28px', margin: '0px 15px'}} src = {expandMore } onClick={() => this.onFullScreen(item)}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid style={{display: 'flex', backgroundColor: 'black', padding: "2px", borderRadius: '10px', marginBottom: '10px', justifyContent: 'space-evenly'}}>
                <Grid className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={PlayIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{item?.attributes?.play_count ? millify(item?.attributes?.play_count) : "0"}</span>
                </Grid>
                {/* <Grid  className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={repeatIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{item?.attributes?.repost_count ? millify(item?.attributes?.repost_count) : "0"}</span>

                </Grid>
                <Grid  className="" style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={messageIcon} height="24px" width="24px" />
                    <span className="play-songs-times" style={{ marginLeft: "4px" }}>{item?.attributes?.comments_count ? millify(item?.attributes?.comments_count) : "0"}</span>
                </Grid> */}
                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <span style={{color: 'blue'}}>24h%</span>
                    <span>{item?.attributes?.last_twenty_four_hours_percentage}</span>
                    </Grid>
                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                  <span style={{color: 'blue'}}>7d%</span>
                    <span>{item?.attributes?.seven_days_percentage}</span>
                </Grid>
            </Grid>
            </Grid>
        </>
    )
    }
}

// Customizable Area End