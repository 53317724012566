import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { HISTORY } from "components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
  creativePlanAlt?: boolean;
  price?: any;
  name: any;
  currentPlan: any;
  isCurrentPlan: any;
  currentPaymentData: any;
  activatedPlan: any;

  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class MCreativePlan extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {};
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  redirectCreative = () => {
    let dataRedirectToConfirmPlan = {
      planName: this.props.name,
      planPrice: this.props.price,
      currentPaymentData: this.props.currentPaymentData,
      currentPlan: this.props.currentPlan,
      isCurrentPlan: this.props.isCurrentPlan,
      activatedPlan: this.props.activatedPlan,
    };
    localStorage.setItem(
      "redirectToConfirmPlan",
      JSON.stringify(dataRedirectToConfirmPlan)
    );

    HISTORY.push({
      pathname: "/mconfirmplan",
    });
  };

  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
