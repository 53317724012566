// Customizable Area Start
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Avatar,
  Accordion,
  withStyles,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { defaultProfile, send_icon, viewreplay } from 'blocks/dashboard/src/assets';
import { CommentReplay } from './CommentReplay.web';
import axios from 'axios';
import { findDuration } from 'blocks/dashboard/src/Inbox/Comments/commentTimeConvertion.web';
import { CloseCircleOutlined } from "@ant-design/icons";
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export default function CommentSection({
  toggleComment,
  handleToggle,
  comments,
  id,
}: any) {
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#000000',
      marginBottom: -1,
      flexDirection: 'row-reverse',
      justifyContent: 'end',
      padding: '0px 0px',

      '&$expanded': {
        minHeight: '0px',
      },
    },
    content: {
      margin: '0px 12px',
      '&$expanded': {
        margin: '0px 0px',
      },
    },
    expanded: {
      // margin: "20px"
    },
    expandIcon: {
      order: 0,
      margin: '0px -68px',
    },
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles({
    root: {
      padding: '0px 0px',
    },
  })(MuiAccordionDetails);

  const [commentList, setCommentList] = useState<any>([])
  //   {
  //     id: 1,
  //     name: 'Athar Malakooti',
  //     text: 'This is so good I am hooked!!!',
  //     duration: '2h',
  //     replay: [
  //       {
  //         name: 'aan',
  //         time: '2:30',
  //         duration: '3h',
  //         text: 'i am grow',
  //       },
  //       {
  //         name: 'arjun',
  //         time: '2:30',
  //         duration: '4h',
  //         text: 'i am grow',
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: 'Athar Malakooti',
  //     text: 'This is so good I am hooked!!!',
  //     duration: '2h',
  //     replay: [
  //       {
  //         name: 'aan',
  //         time: '2:30',
  //         duration: '3h',
  //         text: 'i am grow',
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     name: 'Athar Malakooti',
  //     text: 'This is so good I am hooked!!!',
  //     duration: '3h',
  //     replay: [],
  //   },
  //   {
  //     id: 4,
  //     name: 'Athar Malakooti',
  //     text: 'This is so good I am hooked!!!',
  //     time: '2:30',
  //     duration: '1h',
  //     replay: [
  //       {
  //         name: 'aan',
  //         time: '2:30',
  //         duration: '1h',
  //         text: 'i am grow',
  //       },
  //       {
  //         name: 'ram',
  //         time: '2:30',
  //         duration: '1h',
  //         text: 'i am grow',
  //       },
  //     ],
  //   },
  //   {
  //     id: 5,
  //     name: 'Athar Malakooti',
  //     text:
  //       'This is so good I am hooked!!! bsdjbsjbdjbsjdbjbs bsdhsvhdvhsvhvsvhsv',
  //     duration: '1h',
  //     replay: [],
  //   },
  // ]);

  const token: any = localStorage.getItem('token');
  const [addComment, setAddComment] = useState<any>('');
  const [selectedCommentForReply, setselectedCommentForReply] = useState<any>("");
  const logged_in_user_profile = localStorage.getItem('profile_img')

  // const [expand, setExpand] = useState(false);

  const [expanded, setExpanded] = React.useState<number>(0);

  const handleAccodionChange = (panel: any) => (
    event: any,
    newExpanded: any
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const addNewCommentToTrack = (data: any) => {
    setCommentList([data,  ...commentList]);
    setAddComment("");

  }

  const handleAddComment = () => {
    if(addComment != ''){
      sendNewComment()
    }
  };

  const handleEnterKeyValueChange = (e: any) => {
    if (e.keyCode === 13) {
      setAddComment(e.target.value)
      handleAddComment()
    }
}

  const postNewCommentOnTracks = async() => {
     
    const data = {
        
        description: addComment,
        add_track_id: id
    
    };
   
    await axios
            .post(`${baseUrl}/bx_block_activityfeed/comments?description=${addComment}&add_track_id=${id}`, data, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    
                    token: token,
                },
            })
            .then((response: any) => {
              addNewCommentToTrack(response?.data?.data)
            })
            .catch((err: any) => {

            });  
  }

  const sendNewComment = () => {
    if(selectedCommentForReply == ''){
      postNewCommentOnTracks()
    }else{
      postReplyOnComment()
    }
  }

  const addReplyToComment = (reply: any) => {
    let comments = Object.assign(commentList, [])
    for (let comment of comments) {
        if (comment.attributes.id === reply.attributes.comment_id) {
            comment.attributes.replies = [reply, ...comment.attributes.replies]
            break;
        }
    }
    return comments;
    }

  const postReplyOnCommetApiSuccessCall = (responseJson: any) => {
    setCommentList(addReplyToComment(responseJson?.data))
    setselectedCommentForReply("")
    setAddComment("")
    //this.setState({ selectedCommentsList: this.addReplyToComment(this.state.selectedCommentsList, responseJson?.data), selectedCommentForReply: '', newComment: '' })
    }

  useEffect(() =>{
    getTrackComments()
  }, [id])

  const postReplyOnComment = async() =>{ 
    const data = {
        
        description: addComment,
        comment_id: selectedCommentForReply.id
    
    };   
  
      await axios
      .post(`${baseUrl}/bx_block_activityfeed/replies`, data, {
          headers: {
              "Content-Type": "application/json;charset=UTF-8",
              
              token: token,
          },
      })
      .then((response: any) => {
        postReplyOnCommetApiSuccessCall(response?.data)
      })
      .catch((err: any) => {

      }); 
  }

  const getTrackComments= async () =>{
    await axios
            .get(`${baseUrl}/bx_block_activityfeed/show_comment?add_track_id=${id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    
                    token: token,
                },
            })
            .then((response: any) => {
               setCommentList(response?.data?.data)
            })
            .catch((err: any) => {

            });
  }

  const sendReply = (comment: any) => {
    setselectedCommentForReply(comment);
  }

  return (
    <Grid
      container
      xs={12}
      style={{
        backgroundColor: '#000000',
        padding: '20px',
        borderRadius: '15px',
        height: "420px"
      }}>
        {
            selectedCommentForReply != '' &&
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px', backgroundColor: '222121', borderRadius: '10px',width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'column',width: '100%'}}>
                <div style={{color: 'blue', fontSize: '16px', fontWeight: 'bold'}} >{selectedCommentForReply?.attributes?.user_name}</div>
                <div style={{color: 'white',overflow: 'hidden', textOverflow: 'ellipsis'}}>{selectedCommentForReply?.attributes?.description}</div>
            </div>
              {/* @ts-ignore */}
             <CloseCircleOutlined translate style={{color: 'white', padding: '6px 0px 0px 10px'}} onClick={() => {setselectedCommentForReply("")}} />
            </div>
         }
      <Grid item xs={12} container spacing={2} style={{height: '70px'}}>
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Avatar
            alt='Remy Sharp'
            src={logged_in_user_profile != ('null' || '')? logged_in_user_profile : defaultProfile}
            className="comment_avatar"
            style={{marginLeft: '5px'}}
          />
        </Grid>
        <Grid
          item
          xs={11}
          style={{ position: 'relative', paddingLeft: '15px' }}>
          <div className='comment_send_display'>
            <input
              placeholder='Add a comment…'
              className='addComment_msg add_Comment_Input'
              value={addComment}
              onChange={(e: any) => setAddComment(e.target.value)}
              onKeyDown={(e: any) => handleEnterKeyValueChange(e)}
            />
            <button className='comment_button' type="submit" onClick={handleAddComment}>
              Comment
            </button>
            <img src={send_icon} alt="send button" className='comment_send_button comment_avatar'  onClick={handleAddComment} />

          </div>
          <div className='comment_input_border'></div>
        </Grid>

        {/* <Box className="send-message-box ">
          <img
            src={profile}
            width="40px"
            height="40px"
            className="send-message-img"
          />
          <input
            placeholder="Add a comment…"
            className="add-comment"
            style={{ color: "#8f92a1", fontFamily: "Inter", fontSize: "12px", fontWeight: "bold" }}
          /> */}
        {/* <img
            src={send_button}
            width="35px"
            height="35px"
            className="sendIcon_in_addComment"
            style={{
              position: "absolute",
              top: "50%",
              right: "8px",
              transform: "translateY(-50%)",
            }}
          /> */}
        {/*</Grid></Box> */}
      </Grid>
      <Grid
        container
        xs={12}
        className='commentBlock'
        style={{ margin: '10px 0px', height: selectedCommentForReply != '' ? '200px' : '300px' }}>

       <div style={{width: '100%'}}>
         
        {
        
        commentList.length > 0 ?

        commentList.map((item: any, index: any) => {
              return (
                <>
                  {/* <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    direction="row"
                    wrap="nowrap"
                    justifyContent="flex-end"
                    style={{ marginBottom: "15px" }}
                  >
                    <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={comment.type == "you" ? 1 : 3}
                      style={{ marginTop: "4px" }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={chartcover}
                        style={{ width: "42px", height: "42px" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      className="user_comment_msg"
                      // alignContent="space-between"
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid container item xs={9}>
                          <span className="comment_username">
                            {comment.username}
                          </span>
                        </Grid>
                        <Grid container item xs={3} justifyContent="flex-end">
                          <span className="comment_time_font">
                            {comment.time}
                          </span>
                        </Grid>
                        <p className="comment_msg">{comment.msg}</p>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <CommentReplay
                    avatarSrc={item.attributes?.user_image}
                    name={item.attributes?.user_name}
                    //time={item.time}
                    duration={findDuration(new Date(item.attributes?.created_at).getTime() / 1000)}
                    text={item.attributes?.description}
                  />
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="flex-end"
                    style={{ marginTop: "4px" }}
                  >
                    {item.attributes?.replies[0] ? (
                      <Grid item xs={11}>
                        <Accordion
                          // square
                          expanded={expanded === index}
                          onChange={handleAccodionChange(index)}
                          className="comment_replay_main"
                        >
                          <AccordionSummary
                            expandIcon={
                              <img
                                src={viewreplay}
                                alt="expandUp"
                                className="expandIconUp"
                              />
                            }
                            aria-controls="panel1a-content"
                            IconButtonProps={{ edge: "start" }}
                            id="panel1a-header"
                          >
                            {/* <Grid item xs={3}> */}
                            <div className="replayComment">
                              <span className="repliesFont">
                                {item.attributes?.replies.length} Replies
                              </span>
                              {/* </Grid> */}
                              {/* <Grid item xs={3}> */}
                              <span className="replayFont" onClick={() => sendReply(item)}>Reply</span>
                            </div>
                            {/* </Grid> */}
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: "column" }}>
                            {item.attributes?.replies.map((data: any) => {
                              return (
                                <>
                                  <CommentReplay
                                    avatarSrc={data?.attributes?.user_image}
                                    name={data.attributes?.user_name}
                                    duration={findDuration(new Date(data?.attributes?.created_at).getTime() / 1000)}
                                    text={data?.attributes?.description}
                                  />
                                </>
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ) : <>
                      <Grid item xs={11}>
                        <span className="replayFont" style={{paddingLeft: '35px'}} onClick={() => {sendReply(item)}}>Reply</span>
                      </Grid>
                    </>}
                    
                  </Grid>
                </>
              );
            })
          
            :
            <>
            <div style={{color: 'white', fontSize: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Comments Available</div>
            </>
          }
        {/* <Grid container xs={12} className="commentBlock mostly-customized-scrollbar">
        {[1, 2, 3, 4, 5, 6].map(function () {
          return (
            <Grid
              container
              wrap="nowrap"
              spacing={2}
              style={{ marginTop: "6px" }}
            >
              <Grid item>
                <Avatar
                  alt="Remy Sharp"
                  src={chartcover}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
              <Grid item xs zeroMinWidth>
                <p className="commenterName">
                  Athar Malakooti
                  <span
                    style={{
                      borderRadius: "50px",
                      width: "3px",
                      height: "3px",
                      margin: "12px 4px 8px",
                      backgroundColor: "#8f92a1",
                    }}
                  />
                  <span>at 1:23</span>
                  <span
                    style={{
                      margin: "0 0 0 18px",
                    }}
                  >
                    1hr{" "}
                  </span>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    color: "#fff",
                    fontSize: "14px",
                    marginBottom: "3px",
                  }}
                >
                  Feel so good!!!{" "}
                </p>
                <p
                  style={{
                    textAlign: "left",
                    color: "#8e8e8e",
                    textDecoration: "underline #2a2a2a",
                    fontSize: "10px",
                    fontWeight: 300,
                  }}
                >
                  Reply
                </p>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <img
                    src={path3}
                    width="10px"
                    height="6px"
                    className="viewReplyIcon_in_commentsection"
                    style={{ margin: "3px 5px 3px 0" }}
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: "#3959f9",
                      fontSize: "10px",
                      fontWeight: 600,
                      margin: "0 0 0 5px",
                      cursor: "pointer",
                    }}
                    onClick={handleToggle}
                  >
                    {toggleComment ? "Hide reply" : "View reply"}
                  </p>
                </Grid>
                {toggleComment && (
                  <Grid item xs={12}>
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={2}
                      style={{ marginTop: "6px" }}
                    >
                      <Grid item>
                        <Avatar
                          alt="Remy Sharp"
                          src={chartcover}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <h4
                          style={{
                            margin: 0,
                            textAlign: "left",
                            display: "flex",
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "#8f92a1",
                            lineHeight: 2,
                          }}
                        >
                          Athar Malakooti
                          <span
                            style={{
                              borderRadius: "50px",
                              width: "3px",
                              height: "3px",
                              margin: "12px 4px 8px",
                              backgroundColor: "#8f92a1",
                            }}
                          />
                          <span>at 1:23</span>
                          <span
                            style={{
                              margin: "0 0 0 18px",
                            }}
                          >
                            1hr{" "}
                          </span>
                        </h4>
                        <p
                          style={{
                            textAlign: "left",
                            color: "#fff",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          Feel so good!!!{" "}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid> */}
      </div>
      </Grid>
    </Grid>
  );
}
// Customizable Area End
