import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { HISTORY } from "components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

  export interface Props {
    loading?: boolean;
    getsubscriptions?: any;
    current_plan:any;
    openConfirmPaymentPopup:any;
    cancelledSubscription:any;
    currentPaymentData:any;
    next_invoice_no:any;
    status_of_plan:any;
    handleUpdateCard:any;
  
    // Customizable Area Start
    // Customizable Area End
  }
  interface S {
    // Customizable Area Start
    tabvalue:any;
    active:any
    // Customizable Area End
  }
  interface SS {
    id: any;
  }
  
  export default class PlanAndPaymentsController extends BlockComponent<
    Props,
    S,
    SS
  > {
    // Customizable Area Start
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),
      ];
      this.state = {
        tabvalue:[
          {
            tabName: "My Plan",
            value: 0,
          },
          {
            tabName: "My Card",
            value: 1,
          },
        ],
        active:0
      };
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
  
  iconClick=()=>{
    HISTORY.push("/home")
  }
  
  handleChange = (event:any, newValue: number) => {
    this.setState({active:newValue})

};

redirectWhenPlanCancel=()=>{
    HISTORY.push("/mplancancel")
}
  
    // Customizable Area End
  
    // Customizable Area Start
    // Customizable Area End
  }  
  