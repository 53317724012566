// Customizable Area Start
import React from 'react';
import { Bar } from 'react-chartjs-2';

// Customizable Area End


// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const UserChart = (props: any) => {
    const { graph } = props
    
    const label = graph?.map((item:any,i:any)=> '')
  
    
    const datas: any = {
        labels:label,
        datasets: [
            {
                data: graph,
                backgroundColor: '#ff0000',
                barPercentage: 0.9,
                categoryPercentage: 1,
                borderWidth: 1,
                borderSkipped: false,
                borderRadius: 7,
                hoverBackgroundColor: '#3959f9',
            },
        ],
    };
    const options = {
        layout: {
            padding: {
                bottom: -12
            }
        },
        cornerRadius: 7,
        elements: {
            point: {
                radius: 7,
                hoverRadius: 7,
                pointStyle: 'rectRounded',
            },
        },
        scales: {
            y: {
                beginAtZero: true
            },
            xAxes: [
                {
                    gridLines: {
                        color: '#ff0000',
                        display: graph?.every((current: any) => current === 0) ? true : false,
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                    radius: 7,
                    stacked: true,
                    ticks: {
                        padding: 0,
                        display: false,
                    },
                    scaleLabel: {
                        color: '#ff0000',
                        display: true
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                    },
                    radius: 7,
                    ticks: {
                        stepSize: 2,
                        display: false,
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                title: function (context: any) {
                    return '';
                },
                label: (context: any) => {
                    return context.value;
                },
            },
            xAlign: 'right',
            displayColors: false,
            intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true,
    };
    return (
        <>
     
            <Bar data={datas} options={options} data-testid="user-chart"/>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default UserChart;
// Customizable Area End
