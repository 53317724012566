// Customizable Area Start
import { Grid, LinearProgress, Typography} from "@material-ui/core";
import React from "react";
import "./snapshot.css";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { blueCheck, defaultProfile } from "blocks/dashboard/src/assets";
import {  Carousel } from "antd";
import MobileDownloaderDetails from './MobileDownloaderDetails.web'
import SnapshotController from "./SnapshotController";

export default class TopMobileDownloaders extends SnapshotController {
  render() {
    const {
        threebtnToogle,
        downloadersList,
        setDowloadedData,
        
      } = this.state;

    const _settings = {
      autoplay: false,
      dots: false,
    };
    return (
     
        <Carousel ref={this.carouselRef} {..._settings} style={{ padding: "10px 30px 30px 30px"}}>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              // height: "100%",
              margin: "auto",
              height:"100%"

            }}
          >
            <Grid
              container
              item
              xs={12}
              sm={6}
              style={{ display: "flex", height: "100%", padding: "10px" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  // marginBottom: "15px",
                  alignItems: "center",
                  height: "20%"

                }}
              >
                <span className="topDownloaders" style={{ whiteSpace: "pre" }}>
                  Top {downloadersList?.length} &nbsp;
                </span>
                <span className="topDownloadersSpan"> Downloaders</span>
              </Grid>
              <div
                // style={{ maxHeight: "500px", width: "100%" }}
                className="disable-scrollbars"
              >
                {downloadersList?.length > 0 ? (
                  downloadersList.map((item: any, index: any) => {
                    return (
                      <Grid
                        container
                        item
                        xs={12}
                        key={index}
                        className={
                          this.state.selectIndex == index
                            ? "openDownloaders"
                            : "closeDownloaders"
                        }
                        onClick={() =>{
                          this.openDownloader(index, item)
                          
                        }
                        }
                      >
                        <Grid
                          item
                          xs={4}
                          sm={3}
                          md={3}
                          lg={2}
                          className="downloaderListSpaceAround"
                        >
                          <Typography className="indexStyle">
                            {index + 1}
                          </Typography>
                          <img
                            src={item.attributes?.avatar || defaultProfile}
                            className="downloadArtWork"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          sm={9}
                          md={9}
                          lg={10}
                          className="downloadArtWorkGrid"
                        >
                          <span className="downloadArtName">
                            {item.attributes.user_name}
                            {item?.attributes?.is_verified && (
                              <img src={blueCheck} className="verified-snapshot" />
                            )}
                          </span>
                          <Grid
                            item
                            xs={12}
                            className="downloaderListSecondGrid"
                          >
                            <span className="downloadTypeName">Downloads</span>
                            <span className="downloadArtName">
                              {item?.attributes.downloads_count}
                            </span>
                          </Grid>
                          <div className="linearProgressDiv">
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                item?.attributes?.downloads_count /
                                this.state.totalTracksCount
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <>
                    <p className="noData-downloads">No Downloaders Found</p>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
          {Object.keys(setDowloadedData)?.length > 0 ? (
          <MobileDownloaderDetails 
          threebtnToogle={threebtnToogle}
          userData={setDowloadedData}
          changetabs={this.changetabs}
          onBack={this.goBackRef}
          />
          ): (
            <p className="noData">
              Select A User To View <br />
              Download History
            </p>
          )}
        </Carousel>
    
      //Merge Engine End DefaultContainer
    );
  }
}
const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#212121 !important",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#3959f9",
    },
  })
)(LinearProgress);

// Customizable Area End
