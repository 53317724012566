// Customizable Area Start
//@ts-ignore
import React, { useContext, useEffect } from "react";
import "./TopSongs.web.css";
import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { playcountP, loopIc, chatPlaylist, defaultProfile, down, iconUp, expandMore } from "../../../dashboard/src/assets";
import millify from "millify";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { AudioContextApi } from "../../../dashboard/src/AudioContext/AudioContext.web";
//@ts-ignore
import LineChart from "components/src/CustomChart/PopUps/LineCharts.web";
//@ts-ignore
import Waveform from "../../../../components/src/CustomChart/Songs/Waveform";
import { HISTORY } from "components/src/common";
import { setStorageData } from "../../../../framework/src/Utilities";

const TopSongs = ({ songsList, beatList, audioPlay, trackName, playPlaylist, handleplay, handlePlayLists ,handleAdActivityClick}: any) => {


  const { handleAudioVideo, setHandleAudioVideo, handlePlayList } = useContext(AudioContextApi);
  const loggedIn_userId = localStorage.getItem('loggedIn_userId')
  const history = useHistory();

  useEffect(()=>{
    songsList.map((item: any, index: any) => {
      if(item.type === "list_ad_campaign"){
        handleAdActivityClick(item.id,"impression")
       }
    })
  },[songsList.length])

  const topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    if(item.type === "list_ad_campaign"){
    handleAdActivityClick(item.id,"play")
   }
    if (trackName == "Playlists") {
      handlePlayLists(item)
    } else {
      if(item.attributes.level && loggedIn_userId != item.attributes.account_id && !item.attributes.unlocked_track_by_current_user){  
        unlockContentRedirection(item)
      }else{
      if (handleAudioVideo === 1 || handleAudioVideo === 0) {
        setHandleAudioVideo(2)
        handlePlayList(item, index, songsList, trackName)
      } else {
        setHandleAudioVideo(1)
      }
    }
    }
  }

  const unlockContentRedirection = (item: any) =>{
    const level = levelReturn(item)      
    const unlock_data = {track_id: item.id, user_id: localStorage.getItem('loggedIn_userId'), level: level}
    setStorageData('unlock_details', unlock_data)
    HISTORY.push('/streamUnlock')
  }

  const levelReturn = (item: any) =>{
    if(item.attributes.level == 'level_1'){
      return 'L1'
    }else if(item.attributes.level == 'level_2'){
      return 'L2'
    }else{
      return 'L3'
    }
  }

  const onFullScreen = (e: any, item: any) => {
    if(item?.attributes.level && loggedIn_userId != item.attributes.account_id && !item.attributes.unlocked_track_by_current_user ){
      unlockContentRedirection(item)
    }else{
    e.preventDefault()
    e.stopPropagation()
    if (item.type == "playlist") {
      handlePlayLists(item)
    } else {
   
    history.push({
      pathname: "/home/expand/song/songId=banner",
      state: {
        data: item,
      }
    })
  }
  }
  }

  let counter=1
 
  const renderArrowCounter=(item:any)=>{
    if(item.type === "list_ad_campaign"){
    return(
      <div className="ad-div-round mr_1 ml_05"/>
    )}else{
      return(

        <div>
          <Row className="sn-grid mr_1 ml_05">
            {counter < 9 ? "0" + counter : counter}
        <p style={{display:"none"}}>{counter++} </p>
          </Row>
          <img src={item.attributes.arrow ? iconUp : down} className="icon-up mr_05 ml_05" onClick={item.handlePlayList} />
        </div>
      )
    }
  }
  
  return (
    <Box className="topSongs-parent">
      <Grid className="topSongs-head-parent" container>
        <Grid item xs={7} md={7} lg={8}>
          <Row className=" width_100">
            <Typography className="top-head">{songsList && 'Top ' + trackName}</Typography>
          </Row>
        </Grid>
        <Grid item xs={4} sm={5} md={5} lg={4} className="second-head-percentage dflex" >
          <Hidden xsDown>
            <Grid item xs={3}>
              <p className=" text_white1 top_size_16 hiddnDaysScore topSongs-24per">24hr%</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_white1 top_size_16 topSongs-7day">7d%</p>
            </Grid>
            <Grid item xs={5} className="hiddnDaysGraph align-center">
              <p className="text_white1 top_size_16 hiddnDaysGraph mt4"> Last 7d </p>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Box
        className={beatList > 0 ? "scroll-noslider mostly-customized-scrollbar" : "scroll mostly-customized-scrollbar"}>
        {songsList.length>0&&songsList.map((item: any, index: any) => {
        let srcImg;
        let classesArtwork;
        if (trackName === "Playlists" && item?.attributes?.cover_image) {
          srcImg = item.attributes.cover_image;
          classesArtwork="topSong-without-artwork"
        } else if (item?.attributes?.art_work) {
          srcImg = item.attributes.art_work;
          classesArtwork="topSong-without-artwork"
        } else {
          srcImg = defaultProfile;
          classesArtwork="topSongs-artwork0default-img"
        }
        return (
            <Col key={item.id} span={24} onClick={() => topPlayMusic(item, item.id, songsList, trackName)} className="musicRow">
              <Grid container style={{ padding: "6px 0px 4px 0px" }} className="main-container-space">
                <Grid item md={4} lg={3} xs={3} sm={4}
                  className="profileDetailBlock">


                  <div className=" width_100 " style={{ display: "flex",alignItems:"center" }}>
                   
                    {renderArrowCounter(item)}
                    <div>
                    <div
                        className="song-cover mr_1 topsongs-cover-listing topSongs-artwork" style={{position: 'relative'}}>
                        {item.attributes.level && loggedIn_userId != item.attributes.account_id && !item.attributes.unlocked_track_by_current_user &&
                          <div style={{ position: 'absolute' , height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <span style={{color: 'white', border: '2px solid white', borderRadius: '50%', backgroundColor: 'red', padding: '3px', fontSize: '12px', fontWeight: 'bold'}}>
                              {levelReturn(item)}
                            </span>
                          </div>
                        }
                      <img 
                        src={srcImg} className={classesArtwork} alt="Profile"
                      />
                      </div>
                    </div>
                    <div >
                      <Row>
                        <Row justify="start" className="width_100 name_hiddne text_white cursor_pointer "> {trackName === "Playlists" ? item?.attributes?.name : item?.attributes?.title || 'Default Song(Unknown)'}</Row>
                        <Row justify="start" className="width_100 text_gray cursor_pointer artist-name-width">{item?.attributes?.artist_name || 'Unknown'}</Row>
                      </Row>
                    </div>
                  </div>
                </Grid>
                <Col xs={6} className="HiddnFullScreen hours-list">
                  <Row justify="end" className="iconDelete">
                    <img src={expandMore} className=" ml-1 mr_1" height="28px" width="28px" />
                  </Row>
                </Col>
                <Hidden smDown >
                <Grid item
                  md={1}
                  xs={2} sm={4} lg={3}
                  className="playButtonContain topSongs-aligncenter">
                  <Row className=" " style={{width: '95%'}}>
                    
                    <Hidden mdDown>
                      <Col span={24} >
                          {trackName !== "Playlists" && item?.attributes?.track_file &&
                            <Waveform url={item?.attributes?.track_file} id={item?.id} height={24} />
                          }
                      </Col>
                        </Hidden>


                  </Row>
                </Grid>
                </Hidden>
                <Grid item xs={3} md={2} lg={2} className="iconButton play-repost-comment-container">
                  <div className=" display-play_dark_back" style={{width: '100%'}} >
                    <Col className="colButton width_100">
                      <div className='iconContent topSongs-aligncenter'>
                        <img className="iconImg" src={playcountP} height="13px" width="11.5px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.play_count ? millify(item?.attributes?.play_count) : "0"}
                        </Box></div>
                    </Col>
                    <Col className="colButton width_100">
                      <div className='iconContent dflex' style={{alignItems:"center"}}>
                        <img className="iconImg" src={loopIc} height="19px" width="19px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.repost_count ? millify(item?.attributes?.repost_count) : "0"}
                        </Box>
                      </div> </Col>
                    <Col className="colButton width_100">
                      <div className='iconContent topSongs-alignEnd'>
                        <img className="iconImg" src={chatPlaylist} height="19px" width="19px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.comments_count ? millify(item?.attributes?.comments_count) : "0"} </Box>
                      </div>
                    </Col>
                  </div>
                </Grid>
                <Grid item sm={5} md={5} lg={4} className="hidden_percentage" >
                  <LineChart
                       seven_days={item?.attributes?.last_seven_days_percentage}
                       twenty_four_days={item?.attributes?.last_twenty_four_hours_percentage}
                       seven_days_graph={item?.attributes?.last_seven_days_graph}
                       fullscreen={expandMore}
                       onFullScreen={(e: any) => onFullScreen(e, item)}
                     />
                </Grid>
              </Grid>
            </Col>
          );


        })}
      </Box>
    </Box>
  );
}
export default TopSongs;
// Customizable Area End