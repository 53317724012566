// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import "./MonthTrack.css";
import TracksSection from './TracksSection.web'

const MonthTrack = ({ data, handleCurrentTrack, filteredData, isFilter, topPlayMusic }: any) => {
  function filteredAndSortedData(data:any) {
    return [...data]
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort((a, b) => b.attributes.download_histories_id - a.attributes.download_histories_id);
  }

  const tracksSectionOrMessage = filteredData.length > 0 ? (
    
    <TracksSection
      // items={[
      //   ...filteredData
      //     .filter((value:any, index:any, array:any) => array.indexOf(value) === index)
      // ].sort((a: any, b: any) => b.attributes.download_histories_id - a.attributes.download_histories_id)}
      items={filteredAndSortedData(filteredData).map(item => item.attributes)}
      isFilter={isFilter}
      onClick={topPlayMusic}
    />
  ) : (
    <p className="no-downloads">
      You have no download history
    </p>
  );

  return (
  <Box style={{ height: "100%" }}>
    {!isFilter ? (
      Object.keys(data).map((month, monthIndex) => (
        <>
          <p className="month_wise_track" key={monthIndex}>{month}</p>
          <TracksSection
            items={[
              ...new Map(data[month].map((item:any) => [item.id, item])).values()
            ].sort((a:any, b:any) => b.download_histories_id - a.download_histories_id)}
            isFilter={isFilter}
            onClick={topPlayMusic}
          />
        </>
      ))
    ) : (
      tracksSectionOrMessage
    )}
  </Box>
  )
};
export default MonthTrack
// Customizable Area End