// Customizable Area Start
import PercentageDisplayController from './PercentageDisplayController'
import React from 'react'
export default class PercentageDisplay extends PercentageDisplayController {
 render(){
  const{ value,
  style,
}= this.props
 
  return (
    <span style={this.getPlusMinus(value) === "+" ? style.plus : style.minustext}>
      {this.getPlusMinus(value)}

      <span style={style.percentagetext}>
        {" "}
        {this.getPctTxt(
          value === "100" ? "100" : parseFloat(value || "0.00").toFixed(2)
        )}
        %
      </span>
    </span>
  );
}
}
// Customizable Area End