// Customizable Area Start
import React from "react";
import Home from "./Home.web";
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import {  Layout, Row } from 'antd';
import "../Creatives/index.web.css";
import { Hidden, } from "@material-ui/core";
import "../Creatives/Home.web.css";
import ProfileData from "./ProfileData.web";
import UserProfileBasicController from "../UserProfileBasicController";
import { nxzloader } from "blocks/dashboard/src/assets";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

const { Content } = Layout;

export default class Listener extends UserProfileBasicController {
  render() {
    const { get_All_Activity_data,beatList,  creativeById } = this.state;
    const { isPlaylist } = this.context;
    let className = "";

if (this.state.value === 5) {
  if (get_All_Activity_data.length > 0) {
    className = "listener_div_creative_withData";
  } else {
    className = "listener_div_creative";
  }
} else {
  if (beatList.length > 0) {
    className = "listener_div_creative_withData";
  } else {
    className = "listener_div_creative";
  }
}
    return (
      <div style={{ overflowX: "hidden", overflowY: "hidden", backgroundColor: "rgb(31, 31, 31)", height: "100vh" }} >
        <Layout style={{ backgroundColor: "black", height: "100vh" }}>
          {/* .......................SideBar...................................... */}
          <Hidden smDown>
            <AsideLeft/>
          </Hidden>

          <Content style={{ margin: "0px", backgroundColor: "black", display: "flex", flexDirection: "column", overflow: 'hidden', height:"100vh" }}>
            <HomeHeader />
            {Object.keys(creativeById).length <= 0 ?
                  <Row 
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                  // className="mt-6  width_100 loader-charts" align="middle" justify="center"
                  >
                    
                      <img src={nxzloader} style={{ width: "5%" }} />
                  </Row>
              : 
                <>
                  <div className={isPlaylist? "mainBody-audio-withoutheight":"mainBody-audio-withoutaudio"}>
                      <ProfileData myProfile={true} />

                      <div className={className} style={{marginTop:"20px"}}>
                        <Home />
                    </div>
                  </div>
                </>
              
            }


          </Content>

        </Layout>
      </div>
    );
  }
}
Listener.contextType = AudioContextApi;

// Customizable Area End