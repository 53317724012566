// Customizable Area Start
import React from "react";
import "./ComposeBlock.web.css";
// Customizable Area Start
//@ts-ignore
export const configJSON = require("../../../../framework/src/config.js");
import ComposeBlockUpperVideo from "./ComposeBlockUpperVideo.web";
import ComposerBlockController from "../Common/ComposerBlockController";
import { HISTORY } from "components/src/common";
import { logo, profileplay } from "blocks/dashboard/src/assets";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

export default class ComposeBlock extends ComposerBlockController {

  render() {
    const { creativeById, new_released_video ,new_released_video_attributes} = this.props;
    const { isPlaylist } = this.context;
    return (
      <div className={isPlaylist ? "profile-top-grid-audio" : "profile-top-grid"}>
        {Object.keys(creativeById).length > 0 &&
          <> 
            <div className={new_released_video?"upperDiv":"upperDiv-width100"} style={{ backgroundImage: creativeById?.attributes?.cover_image ? `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url(${creativeById.attributes.cover_image})` : `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url(${logo})`}}>
              <ComposeBlockUpperVideo
                creativeById={creativeById}
                new_released_video={this.props.new_released_video}
                openPopupFollowers={this.props.openPopupFollowers}
                openPopupFollowing={this.props.openPopupFollowing}
                allFollowerData={this.props.allFollowerData}
                followingData={this.props.followingData}
                loading={this.props.loading}
                unfollowState={this.props.unfollowState}
                loadingFollow={this.props.loadingFollow}
                openScanQr={this.props.openScanQr}
                visible={this.props.visible}
                getFollowerData={this.props.getFollowerData}
                unfollowUserById={this.props.unfollowUserById}
                followUserByID={this.props.followUserByID}
                handleRedirectToMessage={this.props.handleRedirectToMessage}
                FolloweUser={this.props.FolloweUser}
                getFollowingData={this.props.getFollowingData}
                UnFolloweUser={this.props.UnFolloweUser}
                handlePopupVisible={this.props.handlePopupVisible}
                setVisible={this.props.setVisible}
                setLoadingFollow={this.props.setLoadingFollow}
                setopenScanQr={this.props.setopenScanQr}
                setopenPopupFollowers={this.props.setopenPopupFollowers}
                setopenPopupFollowing={this.props.setopenPopupFollowing}
              />


            </div>
            <div className={new_released_video?"upper-video-div":"upper-video-div-none"}>
              <div className='cover-img' style={{ position: "relative", height: "100%" }}>
                <img
                  src={new_released_video && new_released_video.art_work}
                  className="new-released-video"
                />
                <img
                  style={{
                    marginRight: "5px",
                    position: "absolute",
                    top: "40%",
                    left: "45%",
                    height: "50px",
                    cursor: "pointer",

                  }}
                  onClick={(e) => {
                    HISTORY.push({
                      pathname: "/home/Charts/videos",
                      state: { video_list: new_released_video.video_file, activeNo: "5",data:new_released_video_attributes,currentList:[] }
                    })
                  }}
                  src={profileplay}

                />
              </div>
            </div>
          </>

        }
      </div >
    );
  }
}
ComposeBlock.contextType = AudioContextApi;
// Customizable Area End