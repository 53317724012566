// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../../components/src/common";
export const configJSON = require("./config.js");
import { AudioContextApi } from "../../AudioContext/AudioContext.web";

export interface Props {
  media: any;
  mediaType: string;
  playMedia: (media: any, type: string) => void;
  viewProfile: () => void;
  selectedTrackMediaDetails: any;
  followUser: (userId: any) => void;
  doUnFollow: (userId: any) => void;
}

interface S {
  isCopiedModalOpen: boolean;
  sendLinkViaDM: boolean;
  openAudioPopup: boolean;
  videoList: any;
  sendLinkCopy: any;
}
interface SS {
  id: any;
}

export default class AudioVideoInfoCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiHandleCopyCountCallId: any;
  apiHandleVideoListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isCopiedModalOpen: false,
      sendLinkViaDM: false,
      openAudioPopup: false,
      videoList: [],
      sendLinkCopy: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getVideoList();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getVideoList = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiHandleVideoListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endVideoList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      null
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePlay = () => {
    this.props.playMedia({}, this.props.mediaType);
  };

  handlePlayAudioVideo = () => {
    const { handlePlayList } = this.context;
    if (this.props.selectedTrackMediaDetails?.attributes?.track_upload_type !=("Video" || "video") && typeof(handlePlayList) === 'function') {
      handlePlayList(this.props.selectedTrackMediaDetails,
        0,[],
 this.props.selectedTrackMediaDetails?.attributes?.track_upload_type
      );
    } else {
      HISTORY.push({
        pathname: "/home/Charts/videos",
        state: {
          video_list: this.props.selectedTrackMediaDetails?.attributes
            ?.video_file,
          activeNo: "5",
          data: this.props.selectedTrackMediaDetails,
          currentList: this.state.videoList,
        },
      });
    }
  };

  handleProfileClick = () => {
    if (
      this.props.selectedTrackMediaDetails?.attributes?.creative_type_id != ""
    ) {
      HISTORY.push(
        `/creatives/beats?creative_id=${this.props.selectedTrackMediaDetails?.attributes?.account_id}`
      );
    } else {
      HISTORY.push(
        `/listeners/activity?listener_id=${this.props.selectedTrackMediaDetails?.attributes?.account_id}`
      );
    }
  };

  linkCopy = () => {
    const el = document.createElement("input");
    el.value = window.location.origin.concat(
      `/home/expand/song/songId=${this.props.selectedTrackMediaDetails?.id}`
    );
    document.body.appendChild(el);
    el.select();
    el.focus();
    navigator?.clipboard?.writeText(
      window.location.origin.concat(
        `/home/expand/song/songId=${this.props.selectedTrackMediaDetails?.id}`
      )
    );
    document.body.removeChild(el);
    this.setState({ isCopiedModalOpen: true });
    this.increaseCopylinkCount();
  };

  increaseCopylinkCount = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiHandleCopyCountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getIncreaseCopylinkCount}?id=${this.props.selectedTrackMediaDetails?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      null
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  followUser = (userId: any) => {
    this.props.followUser(userId);
  };

  handleFollowingClick=()=>{
    const isFollowing = this.props.selectedTrackMediaDetails?.attributes
    ?.is_following;

    if(isFollowing){
     this.doUnFollow(
        this.props.selectedTrackMediaDetails?.attributes
          ?.account_id
      )
     }else{
       this.followUser(
        this.props.selectedTrackMediaDetails?.attributes
          ?.account_id
      )
       }
  }

  handleOpenAudioPopup=()=>{
    this.setState({ openAudioPopup: true });

  }

  handleCopiedLinkFalse=()=>{
    this.setState({ isCopiedModalOpen: false });

  }

  handleSendLinkFalse=()=>{
    this.setState({ sendLinkViaDM: false });

  }

  handleAudioPopupFalse=()=>{
    this.setState({ openAudioPopup: false });

  }


  handleSendMsg=()=>{
    this.setState({
      sendLinkCopy: window.location.origin.concat(
        `/home/expand/song/songId=${this.props.selectedTrackMediaDetails?.id}`
      ),
      sendLinkViaDM: true,
    })
  }

  doUnFollow = (userId: any) => {
    this.props.doUnFollow(userId);
  };

  handleCopyCountCallId = (responseJson: any) => {
   
  };

  handleSetVideosCallId = (responseJson: any) => {
    this.setState({
      videoList: responseJson.data,
    });
  };
  handleRestApiCall = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.apiHandleCopyCountCallId:
        this.handleCopyCountCallId(responseJson);
        break;
      case this.apiHandleVideoListCallId:
        this.handleSetVideosCallId(responseJson);
        break;
      default:
        break;
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId == null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.handleRestApiCall(apiRequestCallId, responseJson);
    }
  }
}
AudioVideoInfoCardController.contextType = AudioContextApi;
// Customizable Area End
