// Customizable Area Start
import React from "react";
import { Row, Col, Form, Button, Input } from "antd";
import {
  ListenerCreativeBackground,
  lock,
  CreativeShade,
  mail,
  nxzborderlogo,
} from "../assets";
import { Grid } from "@material-ui/core";
import { HISTORY } from "../../../../components/src/common";
import "../../assets/listener.css";
import "react-phone-input-2/lib/style.css";
import { UserNameListenerCreativeLabel } from "./UserNameListenerCreativeLabel.web";
import { PhoneInputProfiles } from "./PhoneInputInProfiles.web";
import { FullNameProfiles } from "./FullNameProfiles.web";
import { useLocation } from "react-router-dom";

interface Props {
  onSubmit: (values: any) => void;
  emailInvite: string;
  setInviteEmail: any;
}

export const SignUpFormLabelAndCreative = (props: Props) => {
  const location = useLocation();
  const localStoragemailId = localStorage.getItem("email") || "";
  const url = location.pathname + location.search;
  const queryStringIndex = url.indexOf("?");
  let emailValue:string|null="";
  if (queryStringIndex !== -1) {
    const params = new URLSearchParams(url.substring(queryStringIndex + 1));
    if (params.has("link_type") && params.has("email")) {
      emailValue = params.get("email");
      props.setInviteEmail && props.setInviteEmail(emailValue);
    }
  } 
  return (
    <div className="listenerBgDark">
      <Row justify="space-around" style={{ height: "100%" }}>
        <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create">
          <img
            className="listener-creative-image1 check2"
            style={{}}
            src={ListenerCreativeBackground}
          />
          <img className="image2" src={CreativeShade} />
        </Grid>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Row
            align="middle"
            justify="center"
            className="width_100 "
            style={{ height: "100%" }}
          >
            <Col span={23} className="listener_acc_parent">
              <div>
                <img
                  src={nxzborderlogo}
                  alt="App Logo"
                  className="nxzborderlogo"
                />
              </div>

              <div>
                <h1 className="mb_0 text_white create-account-title">
                  Create Your Account
                </h1>
                <h4 className="text_white1 mb_0 text-align">
                  For Creatives By Creatives
                </h4>
              </div>
              <Row justify="center">
                <Col span={22}>
                  <Form
                    name="basic"
                    className="login_form"
                    layout="vertical"
                    onFinish={(values: any) => props.onSubmit(values)}
                    autoComplete="off"
                    initialValues={{
                      email: emailValue?emailValue:localStoragemailId,
                    }}
                  >
                    <Col md={24} sm={24} className="aligncolumn">
                      <FullNameProfiles />
                      <Row className="width_100 font_12 gray1 ">
                        <UserNameListenerCreativeLabel />
                      </Row>
                    </Col>

                    <Col md={24} sm={24} className="aligncolumn">
                      <Row className="width_100 font_12 gray1">
                        <Row className="fullname_acc">
                          Password
                          <Form.Item
                            label="Enter a password for your account"
                            name="password"
                            style={{ width: "98%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please Input Your Password!",
                              },
                              {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
                                message:
                                  "Password Must Be 8 Character And Contains At Least One A-Z, a-z, 0-9, @$%#",
                              },
                            ]}
                          >
                            <Input.Password
                              prefix={
                                <img src={lock} style={{ width: "100%" }} />
                              }
                              placeholder="*******************"
                            />
                          </Form.Item>
                        </Row>
                      </Row>
                      <Row className="width_100 font_12 gray1">
                        <Row
                          className="fullname_acc"
                          style={{ width: "98%", height: "80%" }}
                        >
                          Email
                          <Form.Item
                            label="Enter your email address"
                            name="email"
                            className="account_sub_title"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message:
                                  "Please Enter Your Valid Email Address!",
                              },
                            ]}
                          >
                            <Input
                              prefix={
                                <img src={mail} style={{ width: "100%" }} />
                              }
                              placeholder="youremail@gmail.com"
                              readOnly={!!props.emailInvite}
                            />
                          </Form.Item>
                        </Row>
                      </Row>
                    </Col>
                    {/* <Col md={24} sm={24} style={{ display: "flex", marginBottom: "2%" }}> */}
                    <Col md={24} sm={24} className="aligncolumn">
                      <PhoneInputProfiles />

                      <Row className="width_100 font_12 gray1">
                        <Row className="fullname_acc" style={{ width: "98%" }}>
                          Instagram
                          <Form.Item
                            label="Enter your username"
                            name="instagram"
                            className="account_sub_title"
                            rules={[
                              {
                                required: true,
                                message: "Please Input Your Instagram ID!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Row>
                      </Row>
                    </Col>
                    <Col md={24} sm={24} style={{ display: "flex" }}>
                      <Row className="width_100 font_12 gray1">
                        <Form.Item style={{ width: "100%", marginBottom: "0" }}>
                          <Button
                            className="red_custom_btn login_btn mt-1 next_back_account_create"
                            type="primary"
                            htmlType="submit"
                            // style={{
                            //   width: "50%",
                            //   margin: "auto"
                            // }}
                          >
                            Next
                          </Button>
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col md={24} sm={24} style={{ display: "flex" }}>
                      <Row className="width_100 font_12 gray1">
                        <Form.Item style={{ width: "100%", marginBottom: "0" }}>
                          <Button
                            className="login_btn mt-1 next_back_account_create back_btn"
                            type="primary"
                            onClick={() => HISTORY.goBack()}
                          >
                            Back
                          </Button>
                        </Form.Item>
                      </Row>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
// Customizable Area End
