// Customizable Area Start
import React from "react";
import '../../../../user-profile-basic/src/Common/TopSongs.web.css'   
import AllTypeSongs from "../AllTypeSongs.web";


const SearchVersesWeb = (props: any) => {
  const {versesList, handlePlayList ,playlistData, name, songIndex, loading} = props;
  return (
    <>
    <AllTypeSongs songs = {versesList} playlistData={playlistData} name={name} handlePlayList={handlePlayList} songIndex={songIndex} loading={loading} type="VERSES"/>
    </>   
  );
};

export default SearchVersesWeb;
// Customizable Area End

