// Customizable Area Start
import { Input, Row, Carousel } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import SongsController from "./SongsController.web";
import { searchshape } from "../../../../dashboard/src/assets";
import TrackDetails from "../TrackDetails.web";
import { defaultImage, nxzloader } from "blocks/dashboard/src/assets";
import CtrlFilterPopup from "../Common/CtrlFilterPopup.web";

const MobilesongsWrapper = styled(Box)({
    display: "flex",
    minHeight: "calc(100% - 3rem)",
    gap: "1%",
    padding: "30px 50px 0px 27px",
    justifyContent: "space-between",
    "@media(max-width: 1024px)": {
        flexDirection: "column",
        padding: "0px 10px 0px 10px",
        gap: "0%",
        minHeight: "0px"
    },
});
const Mobiletrackname = styled(Box)({
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: "22px",
    "@media(max-width: 400px)": {
        // whiteSpace: "unset",
        fontSize: "14px"
    },
});
const MobileleftsideAlltracksboxwrapper = styled(Box)({
    overflowY: "auto",
    scrollbarWidth: "none",
    marginTop: "-20px",
    maxHeight: "693px",
    "&::-webkit-scrollbar": {
        display: "none",
        width: "0px",
    },
});

const MobileleftSideSingletrackbox = styled(Box)({
    // marginTop: '6px',
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    objectFit: "contain",
    color: "white",
    cursor: "pointer",
    "&:hover": {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        objectFit: "contain",
        background: "#141414",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
    },
});
const MobileSongsboxRightside = styled(Box)({
    height: "50vh",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "15px",
    "@media(max-width: 1024px)": {

    },
});

const MobileSongsleftsidecontainer = styled(Box)({
    width: "46%",
    display: "flex",
    marginBottom: "10px",
    height: "max-content",
    flexDirection: "column" as "column",
    gap: "20px",
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        gap: "20px",
        marginTop: "12px",
    },
});

const MobileSongsboxRightsidecontainer = styled(Box)({
    width: "47%",
    flexDirection: "column" as "column",
    height: "50vh",
    display: "flex",
    gap: "15px",
    "@media(max-width:  1024px)": {
        width: "auto",
        height: "auto",
        marginTop: " 5%",
        // alignItems: "center",
        gap: "20px",
    },
});

const MobileTotalSplits = styled(Box)({
    width: "100%",
    color: "white",
    "@media(max-width:  1024px)": {
        height: "auto",
    },
});
const MobileInputboxwrapper = styled(Box)({
    borderRadius: "27px",
    padding: "8px",
    background: "#212121",
    width: "100%",
    position: "relative",
    "& .ant-input": {
        backgroundColor: "#212121",
        width: "88%",
        color: "white",
    },
    "& .ant-input-affix-wrapper": {
        background: "none",
        border: "unset",
    },
    "@media(max-width:  1024px)": {
        width: "100%",
        "& .ant-input": {
            backgroundColor: "#212121",
            width: "50%",
        },
    },
});
export const  MobileTrackBoxfordtails = styled(Box)({
    width: "47%",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "20px",
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        marginTop: " 5%",
        gap: "20px",

    },
});

const MobileLeftsideAlltracksbox = styled(Box)({
    width: "100%",
    "@media(max-width: 1024px)": {
        width: "100%",
    },
});
const settings = {

    autoplay: false,
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    responsive: [
        {
            breakpoint: 601,
            settings: {
                slidesToShow: 2.6,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 590,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 545,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 514,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 462,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 437,
            settings: {
                slidesToShow: 1.8,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 404,
            settings: {
                slidesToShow: 1.8,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 399,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 330,
            settings: {
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 326,
            settings: {
                slidesToShow: 1.4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 308,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }


    ]

};

export default class MobileSongs extends SongsController {
    constructor(props: any) {
        super(props);
    }
    Mobileleftsidecomp=(songList:any,getStatusDetails:any)=>{
        return (
                                <MobileleftsideAlltracksboxwrapper>
                                    {songList?.length > 0 ? (
                                        songList.map((item: any, index: any) => {
                                            const { typeColor, status } = getStatusDetails(item, "tracks");
                                            return (
                                                <MobileleftSideSingletrackbox
                                                    style={
                                                        index === this.state.selectedIndex
                                                            ? webStyle.leftSideSingletrackbox
                                                            : {}
                                                    }
                                                    onClick={this.selectedsong.bind(this, index, item.id, item?.attributes?.is_drafted)}
                                                    data-test-id="selected-mobile-song"
                                                >
                                                    <Box style={webStyle.singleArtinfo}>
                                                        <img
                                                           style={webStyle.leftsidesongimage}
                                                            src={item.attributes.art_work || defaultImage}
                                                           
                                                        />

                                                        <Box style={webStyle.leftsideownerstats}>
                                                            <Mobiletrackname className="tracks_songs_text">
                                                                {item.attributes.title}
                                                            </Mobiletrackname>
                                                            <span style={ webStyle.splittext }>
                                                                {item.attributes.user_role}
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    <Box style={webStyle.leftsideownerstats}>
                                                        <Box>
                                                            <Button
                                                                style={{
                                                                    border: `1px solid ${typeColor}`,
                                                                    borderRadius: "20px",
                                                                    textTransform: "capitalize",
                                                                    color: typeColor,
                                                                    height: "23px",
                                                                }}
                                                            >
                                                                {status}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </MobileleftSideSingletrackbox>
                                            );
                                        })
                                    ) : (
                                        <Typography
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: 16,
                                                marginTop: "10%",
                                                fontWeight: 600,
                                                color: "#fff",
                                                padding: "5",
                                                justifyContent: "center"
                                            }}
                                        >
                                            Data Not Found.
                                        </Typography>
                                    )}
                                </MobileleftsideAlltracksboxwrapper>
        )
    }

    render() {
        const {
            loading,
            searchText,
            top40songList,
            songList,
            songDetailsId,
        } = this.state;
        const { topPlayMusic, getStatusDetails } = this.props;
        return (
            //Merge Engine DefaultContainer
            <>
                {loading ? (
                    <Row
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh",
                        }}
                    >
                        <img src={nxzloader} style={{ width: "5%" }} />
                    </Row>
                ) : (
                    <MobilesongsWrapper>
                        {this.state.showdetails ? (
                            <MobileTrackBoxfordtails>
                                <TrackDetails
                                    navigation={undefined}
                                    id={""}
                                    trackDetails={songDetailsId}
                                    updateData={this.getSongs}
                                    topPlayMusic={topPlayMusic}
                                    index={"song"}
                                    setShowDetails={this.handleShowDetails}
                                    mobile="songs"

                                />
                            </MobileTrackBoxfordtails>

                        ) : (
                            <>
                                {top40songList?.length > 0 ? (
                                    <MobileSongsboxRightsidecontainer>
                                        <MobileSongsboxRightside>
                                            {" "}
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Typography style={webStyle.mobiletrackname}>
                                                    <span style={webStyle.bluetext}>
                                                        Top {top40songList?.length}
                                                    </span>{" "}
                                                    Songs
                                                </Typography>
                                                <Typography style={webStyle.splittext}>
                                                    This week
                                                </Typography>
                                            </Box>
                                            <Box >
                                                <Carousel
                                                    {...settings}
                                                    infinite={false}
                                                >
                                                    {top40songList.map((item: any, index: any) => (
                                                        <Box style={{ width: "300px", height: "300px" }}>
                                                            <Box
                                                                key={item.id || index}
                                                                style={{ height: "100%", cursor: "pointer" }}
                                                            >
                                                                <img
                                                                    src={item.attributes.art_work || defaultImage}
                                                                    alt={`Artwork for ${item.attributes.title}`}
                                                                    style={{ objectFit: "cover", height: "170px", width: "200px",borderRadius: "12px" }}
                                                                    onClick={topPlayMusic.bind(this,item, index, top40songList, "song")}
                                                                />
                                                                <Typography style={webStyle.audiotracktitle}>
                                                                    {index + 1}. {item.attributes.title}
                                                                </Typography>
                                                                <Typography style={webStyle.audioartisttitle}>
                                                                    {item.attributes.user_role}
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                    ))}
                                                </Carousel>


                                            </Box>
                                        </MobileSongsboxRightside>
                                    </MobileSongsboxRightsidecontainer>
                                ) : (
                                    <MobileSongsboxRightsidecontainer>
                                        <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography style={webStyle.mobiletrackname}>
                                                <span style={webStyle.bluetext}>
                                                    Top {top40songList?.length}
                                                </span>{" "}
                                                Songs
                                            </Typography>
                                            <Typography style={webStyle.splittext}>
                                                This week
                                            </Typography>
                                        </Box>
                                        <Typography
                                            style={{
                                                padding: "5",
                                                marginTop: "10%",
                                                paddingTop: "61px"
                                            }}
                                            className="no_data"
                                        >
                                            No Data Found.
                                        </Typography>
                                    </MobileSongsboxRightsidecontainer>

                                )}
                                <CtrlFilterPopup
                                    open={this.state.openSongFilter}
                                    handleClose={this.handleCloseFilter}
                                    currentTab="Songs"
                                    handleTrack={this.handleSongsList}

                                />

                            </>
                        )}
                        {!this.state.showdetails && <MobileSongsleftsidecontainer>
                            <MobileInputboxwrapper>
                                <Input
                                    className=""
                                    onChange={(text) => this.searchFilterFunction(text)}
                                    test-id="test_search_term_id_handle_search_input"
                                    prefix={
                                        //@ts-ignore
                                        <SearchOutlined
                                            value={searchText}
                                            className="text_white1"
                                        />
                                    }
                                    style={webStyle.inputbox}
                                    placeholder="Search Songs"
                                />
                                <img src={searchshape} style={webStyle.songsearchicon}
                                    data-test-id="openFilterpopup"
                                    onClick={() => this.handleOpenFilter()}
                                />
                            </MobileInputboxwrapper>
                            <MobileTotalSplits>
                                <Typography style={ webStyle.mobiletrackname }>
                                    <span style={ webStyle.bluetext }>{top40songList?.length}</span>{" "}
                                    Songs
                                </Typography>
                            </MobileTotalSplits>
                            <MobileLeftsideAlltracksbox>

                               {this.Mobileleftsidecomp(songList,getStatusDetails)}
                            </MobileLeftsideAlltracksbox>
                        </MobileSongsleftsidecontainer>}



                    </MobilesongsWrapper>
                )}
            </>
            //Merge Engine End DefaultContainer
        );
    }
}

const webStyle = {
    leftsideAlltracksbox: {
        width: "85%",
    },
    leftsideAlltracksboxwrapper: {
        height: "693px",
        marginTop: "-20px",
        overflowY: "scroll" as "scroll",
       
    },
    songsearchicon: {
        height: "18px",
        width: "21px",
        position: "absolute" as "absolute",
        right: "6%",
        zIndex: 10,
        cursor: "ponter",
        top: "31%",
       
    },
    leftsidesongimage: {
        borderRadius: "10px",
        minWidth: "75px",
        height: "75px",
        objectFit: "cover" as "cover",
        width: "75px",
        
    },
    inputbox: {
        padding: "10px",
        width: "100%",
        borderRadius: "27px",
        
    },
    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
        display: "flex",
        
    },
    mobiletrackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
      
    },
    singleArtinfo: {
        display: "flex",
        gap: "10%",
        alignItems: "center",
        justifyContent: "center",
      
    },
    tracksbutton: {
        borderRadius: "20px",
        backgroundColor: "#212121",
        justifyContent: "space-between",
        padding: "6px",
        display: "flex",
        
    },
    audiotracktitle: {
        color: "#F0F0F5",
        marginTop: "12px",
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Karla",
        lineHeight: "20px",
        textOverflow: "ellipsis",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap" as 'nowrap',
        maxWidth: "150px",
        
    },
    audioartisttitle: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#8f92a1",
        lineHeight: "20px",
        whiteSpace: "nowrap" as 'nowrap',
        fontFamily: "Karla",
       

    },

    songsinlgebutton: {
        border: "1px solid red",
        borderRadius: "20px",
        height: "23px",
        textTransform: "capitalize" as "capitalize",
        color: "red",
       
    },
    singlebutton: {
        border: "1px solid blue",
        borderRadius: "20px",
        height: "23px",
        textTransform: "capitalize" as "capitalize",
        color: "blue",
       
    },
    bluetext: {
        fontStretch: "normal",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "1.29",
        letterSpacing: "normal",
        color: "#3959f9",
    },
    splittext: {
        fontWeight: 700,
        fontStretch: "normal",
        fontSize: "16px",
        fontStyle: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        whiteSpace: "nowrap" as 'nowrap',
        lineHeight: "20px",
        letterSpacing: "normal",
       
    },
    leftsideownerstats: {
        gap: "10px",
        flexDirection: "column" as "column",
        display: "flex",
       
    },
    songtracksimage: {
        width: "100%",
        borderRadius: "12px",
        height: "148px",
        
    }

}
// Customizable Area End
