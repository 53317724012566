// Customizable Area Start
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  AppBar,
  Tab,
  Tooltip
} from "@material-ui/core";
import '../../../blocks/dashboard/src/Charts/Videos/MessageSection.web.css'
import ChartTopSongs from "components/src/CustomChart/PopUps/ChartTopSongs.web";
import { useTheme, makeStyles, createStyles } from "@material-ui/core/styles";
import { withRouter, useHistory } from "react-router-dom";
import {
  Col, Form, Row
} from "antd";
import { createBrowserHistory } from "history";
// @ts-ignore
import axios from "axios";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
import MuiTabs from "@material-ui/core/Tabs";
import { withStyles, Dialog } from "@material-ui/core";
import PlayListCreateAndAddplayListModel from "../../../blocks/Playlist/src/PlayListCard/PlayListCreateAndAddplayListModel.web";
import SharePlaylistPopup from "blocks/dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";
import { VideosPlayer } from "./VideosPlayer.web";
import ChartsFilter from "./ChartsFilter.web";
import AddToQueuePopup from "blocks/dashboard/src/CustomComponent/AddToQueue/AddToQueuePopup.web";
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { openNotification } from "../Notification.web";
import {
  defaultProfile,
  RepostIconfill,
  AddToPlaylistfill,
  DealIconfill,
  ShareIconfill,
  PlayIcon,
  messageIcon,
  repeatIcon,
  chartFilter,
  nxzloader,
  Sort_Up_large
} from "blocks/dashboard/src/assets";
import MessageSection from "./MessageSection.web";
import SplitsSection from "./SplitsSection.web";
import CommentSection from "components/src/CustomChart/PopUps/CommentSection.web";
import { getStorageData } from "../../../framework/src/Utilities";


const useStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: '0!important' as any
    },
    MuiPopover: {
      "& paper": {
        top: "65%",
        left: "50%",
        border: "2px solid red"
      }

    }
  }),
);


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "13px",
    fontFamily: "Inter",
  },
}));

function MuiTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}



function Comments(props: any) {
  const { isPlaylist } = useContext(AudioContextApi);

  const { songsList, commentTabs, handleCommentTabs, video_list,newRedirectionVideo }: any = props

  const [value, setValue] = React.useState<any>(0);
  const [topVideo, setTopVideo] = React.useState<any>(
    "https://minio.b80845.dev.eastus.az.svc.builder.cafe/sbucket/cxay43nid24o1qihynuvrfhuowgc"
  );
  const [topVideoObj, setTopVideoObj] = React.useState<any>(null);
  const [selectedVideo, setSelectedVideo] = React.useState<any>(null);
  const [currentKey, setSetCurrentKey] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(true);
  const history = createBrowserHistory()
  const History = useHistory()
  const [userplaylist, setUserplaylist] = React.useState<any>("")
  const [emptyPlaylist, setEmptyPlaylist] = React.useState(false)
  const [openModal, setOpenModal] = React.useState<any>(false);
  const [openRepostPopup, setOpenRepostPopup] = React.useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = React.useState<any>('')
  const [id, setId] = React.useState<any>('')
  const [openScanPopup, setOpenScanPopup] = React.useState(false)
  const [currentTrack, setCurrentTrack] = React.useState<any>([])
  const token: any = localStorage.getItem("token");
  const [graphdata, setGraphData] = React.useState<any>();
  const [graphCategory, setGraphCategory] = useState<any>();
  const [filterValue, setFilterValue] = useState<any>('This Week');
  const [trackId, setTrackId] = useState("0");
  const [userTrack, setUserTrack] = React.useState([]);
  const [openAlertLogin, setopenAlertLogin] = useState(false);
  const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [chartType, setChartType] = useState<any>("Plays");
  const [chartTime, setChartTime] = useState<any>("Week");
  const [repostCount, setRepostCount] = useState<any>(0)
  const [showModalAddNewPlaylist, setshowModalAddNewPlaylist] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [playVideoFromRedirection, setPlayVideoFromRedirection] = useState(false);
  const [openDealPopUp, setOpenDealPopUp] = useState(false);
  const [commentsCount, setCommentsCount] = useState<any>(0)
  const [messageCount, setMessageCount] = useState<any>(0)
  const [videoId, setVideoId] = useState<any>('')
  const [shareVideo, setShareVideo] = useState<any>('')
  const [adsData, setAdsData] = React.useState<any[]>([]);



  const classes = useStyles();

  const Tabs = withStyles({
    root: {
      "& div.MuiTabs-scroller": {
        "& .MuiTabs-flexContainer": {
          ['@media only screen and (max-width:600px)']: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }


        }
      }
    },
  })(MuiTabs);

  let SingleVideo: any = localStorage.getItem("selectedSingleVideo")
  let selectedSingleVideo = JSON.parse(SingleVideo);

const [location, setLocations] = useState<any>( null);
const [isLocationLoaded, setIsLocationLoaded] = useState(false); // Loading state



useEffect(() => {
  const fetchStoredData = async () => {
    let storedData = await getStorageData('redirectedVideosData');
    let attempts = 0;

    // Retry if storedData is still null or undefined
    while (!storedData && attempts < 2) { // Retry up to 3 times
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms
      storedData = await getStorageData('redirectedVideosData');
      attempts += 1;
    }

    if (storedData) {
      setLocations(storedData);
      setIsLocationLoaded(true);
    } else {
      setLocations(history?.location?.state || null);
      setIsLocationLoaded(true);
    }

  };

  fetchStoredData();
}, []);

  const handlerVideosList = (data: any) => {
    console.log(location,">>>location")
    if(adsData.length > 0 && !location){
      setTopVideoObj(adsData[0]?.attributes);
      setSelectedVideo(adsData[0])
      setId(adsData[0]?.id)
      setTrackId(adsData[0]?.id)
      setRepostCount(adsData[0]?.attributes?.repost_count);
      setUserTrack([])
      getFilterTrack(adsData[0]?.id)
      setShareVideo(adsData[0])
    }else{
    setTopVideoObj(data?.attributes);
    setSelectedVideo(data)
    setId(data?.id)
    setTrackId(data?.id)
    setRepostCount(data?.attributes?.repost_count);
    setUserTrack(data?.attributes?.user_tracks?.data)
    getFilterTrack(data?.id)
    setShareVideo(data)
    }
  }

  const handlerSingleTrack = (data: any) => {
    if (data.length == 0) {
      setCurrentTrack(songsList);
    } else if(!location){
      setCurrentTrack([...adsData, ...data])
    }else{
      setCurrentTrack(data)
    }
  }

  React.useEffect(() => {
    if (!isLocationLoaded) return; 
    setSetCurrentKey(selectedSingleVideo?.currentKey)
    getAdvertisementsForVideo()
    setLoading(false)
    if (location?.video_list) {
      setTopVideo(location?.video_list)
      setPlayVideoFromRedirection(true)
      if (location?.data) {
        handlerVideosList(location?.data);
        handlerSingleTrack(location?.currentList)
        location?.currentList.forEach((item: any, index: any) => {
          if (item.id == location?.data?.id)
            setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1)
        })

        if (selectedSingleVideo) {
          location?.currentList||songsList.forEach((item: any, index: any) => {
            if (item.id == selectedSingleVideo.id) {
              setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1);
              let selectedNice: any = location?.data?.id == selectedSingleVideo.id && selectedSingleVideo;
              if (selectedNice) {
                handlerVideosList(selectedNice);
                handlerSingleTrack(location?.currentList);
              }
            }
          })
        }

      }
    } else if (location?.currentList) {
      setPlayVideoFromRedirection(true)
      handlerVideosList(location?.currentList[0]);
      handlerSingleTrack(location?.currentList)
      location?.currentList.forEach((item: any, index: any) => {
        if (item.id == location?.currentList[0]?.id)
          setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1)
      })
      if (selectedSingleVideo) {
        location?.currentList.forEach((item: any, index: any) => {

          if (item.id == selectedSingleVideo.id) {
            setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1);
            handlerVideosList(selectedSingleVideo);
            handlerSingleTrack(location?.currentList)
          }
        })
      }
    } else if (songsList && songsList.length !== 0) {
      if(adsData.length > 0){
        handlerVideosList(adsData[0]);
        handlerSingleTrack(songsList);
      }else{
        handlerVideosList(songsList[0]);
        handlerSingleTrack(songsList);
      }
      
      songsList.forEach((item: any, index: any) => {
        if (item.id == songsList[0]?.id)
          setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1)
      })
      if (selectedSingleVideo) {
        songsList.forEach((item: any, index: any) => {
          if (item.id == selectedSingleVideo.id) {
            setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1);
            handlerVideosList(item);
            handlerSingleTrack(songsList);
          }
        })
      }
    }

    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr[5] : "";
    if (slug.includes('videos?id=')) {
      setVideoId(slug.split('=')[1])
      getVideoByID(videoId)
    }

  }, [isLocationLoaded,songsList, video_list, chartType, chartTime, location]);

  const getVideoByID = async (id: any) => {
    await axios
      .get(
        `${baseUrl}/bx_block_audiomusic/show_track?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            token: token,
          },
        }
      )
      .then((response: any) => {
        handlerVideosList(response?.data?.data?.[0])
      })
      .catch((error: any) => {
      });
  }

  const getFilterTrack = async (id: any) => {
    let SingleVideo: any = localStorage.getItem("selectedSingleVideo")
    let selectedSingleVideo = JSON.parse(SingleVideo);

    setOpenFilter(false);
    await axios
      .get(
        `${baseUrl}/bx_block_audiomusic/filtering_data?filter_by_category=${chartType.toLowerCase()}&filter_by=${chartTime.toLowerCase()}&id=${selectedSingleVideo ? selectedSingleVideo.id : id}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            token: token,
          },
        }
      )
      .then((data: any) => {

        setGraphData(Object.values(data.data));
        setGraphCategory(Object.keys(data.data));
      })
      .catch((error: any) => {
      });

  };




  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    handleCommentTabs(newValue);
  };

  const handleChartTrackRepost = async (caption: any, trackId: any) => {
    await axios
      .post(
        `${baseUrl}/bx_block_audiomusic/repost_count?id=${trackId}&caption=${caption}`,
        null,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: token,
          },
        }
      )
      .then((res: any) => {

        openNotification("Successfully Reposted", "Success")
      })
      .catch((err) => {
        openNotification("Some error occurred", "Error")

      })
      .finally(() => {
      });
  };

  const handlerFetchRepost = async () => {
    await axios
      .get(
        `${baseUrl}/bx_block_audiomusic/expan_track?id=${trackId}`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
          },
        }
      ).then((res) => {
        setRepostCount(res.data.data.attributes.repost_count);
      }).catch((error) => {
      })
  }

  const respostSubmit = async () => {
    setLoading(true);
    handleChartTrackRepost(textFieldValue, trackId);
    await handlerFetchRepost();
    setLoading(false);
    handleRepostClose();

  }

  const handleCaptionChange = (e: any) => {
    setTextFieldValue(e.target.value)
  }

  const handleClosePlaylistModal = () => {
    form.resetFields();
    form1.resetFields();
    setOpenModal(false);
  }
  const handleAddToPlaylist = () => {

    if (token) {
      setOpenModal(true);
    } else {
      setOpenModal(false)
    }

  }


  const userType = localStorage.getItem('user_type')
  const subscriptionPlan = localStorage.getItem("subscription_plans")

  const onShare = () => {
    const token: any = localStorage.getItem("token")
    if (token) {
      if (userType == "listener" && subscriptionPlan == "NXZ") {
        setopenAlertLogin(true);
        setDialogTextAlertLogin("3");
      } else {
        setOpenScanPopup(true);
      }
    } else {
      setopenAlertLogin(true);
      setDialogTextAlertLogin("2");
    }

  }




  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={commentTabs !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ width: '100%' }}
      >
        {commentTabs === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  const getUserPlaylist = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_playlist/play_lists`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((response: any) => {
        setLoading(false);
        setUserplaylist(response.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        err?.response.status === 422 ? setEmptyPlaylist(true) : ''
      });
  };

  const handleRepostClose = () => {
    setOpenRepostPopup(false)
  }


  const closeModal = () => {
    setshowModalAddNewPlaylist(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlertLogin = () => {
    setopenAlertLogin(false);
  };


  const videosPlayRef = useRef<any>(null);
  const handleMouseMoveVal = () => {

    videosPlayRef.current.style.visibility = "visible";

  };

  const hanldeMouseLeaveval = () => {
    videosPlayRef.current.style.visibility = "hidden";

  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openNUll = Boolean(anchorEl);
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handlerSelectedTime = async (time: any) => {
    setChartTime(time)
    await getFilterTrack(trackId);
  }

  const handlerSelectedFilter = async (value: any) => {
    setChartType(value);
    await getFilterTrack(trackId);
  }


  const setVideoCount = async (id: any) => {
    await axios
      .get(`${baseUrl}/bx_block_audiomusic/play_track?id=${id}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((res: any) => {
      })
      .catch((err) => {
      })
      .finally(() => { });

  };

  const user_id: any = localStorage.getItem("loggedIn_userId")

  useEffect(() => {
    getTrackComments()
  }, [trackId])

  const getAdvertisementsForVideo = async () => {
    await axios
      .get(`${baseUrl}/bx_block_promotecontent/ad_campaigns/get_display_ads_list?upload_type=video`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response: any) => {
         setAdsData(response.data.data)
      })
      .catch((err: any) => {
      });
  }

  const getTrackComments = async () => {
    await axios
      .get(`${baseUrl}/bx_block_activityfeed/show_comment?add_track_id=${trackId}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response: any) => {
        if (response?.data?.comments_count) {
          setCommentsCount(response?.data?.comments_count)
          setMessageCount(response?.data?.messages_count)
        }
      })
      .catch((err: any) => {
        setCommentsCount(0)
      });
  }


  const redirectToProfile = (videoFile: any) => {
    const userType = localStorage.getItem('user_type')

    if (userType == "creative") {
      window.location.assign(`/creatives/beats?creative_id=${videoFile.account_id}`)
    } else if (userType == "listener") {
      window.location.assign(`/listeners/activity?listener_id=${videoFile.account_id}`)
    } else {
      window.location.assign(`/originals/beats?originals_id=${videoFile.account_id}`)
    }
  }

  const handleDealPopUp = () => {
    setOpenDealPopUp(true)
  }

  const handleCloseDealPopUp = () => {
    setOpenDealPopUp(false)
  }

  const videoDealRedirection = () =>{
    const user_type = localStorage.getItem('user_type')
    if(user_type == "label"){
      window.location.assign('/deal')
      localStorage.setItem('splitType', "video")
      localStorage.setItem('selectedSingleVideo', trackId)
      localStorage.setItem('DealVideoDetails', JSON.stringify(topVideoObj))
      localStorage.removeItem("selectedAudio")
    }
  }

  const returnVideoContent = () => {
    return (
      <>
        {
          topVideoObj == null ? (<>

            <Row className="mt-6  width_100" align="middle" justify="center">
              <Col style={{ width: '25px' }}>
                {" "}
                <img src={nxzloader} style={{ width: "100%" }} />
              </Col>
            </Row>
          </>) : (
            <div
              className="videoPlayerWrapper_in_Comments videoplayer_background"
            >

              <div onMouseMove={handleMouseMoveVal}
                onMouseLeave={hanldeMouseLeaveval}
              >
                <VideosPlayer topvideo={topVideoObj && topVideoObj.video_file}
                  trackId={trackId}
                  selectedVideo={selectedVideo}
                  setVideoCount={setVideoCount}
                  playVideoFromRedirection={playVideoFromRedirection}
                  setPlayVideoFromRedirection={(value: any) => {setPlayVideoFromRedirection(value)}}
                  currentTrack={currentTrack}
                  setTopVideoObj={setTopVideoObj}
                  setSetCurrentKey={setSetCurrentKey}
                  setTrackId={setTrackId}
                  setUserTrack={setUserTrack}
                  setRepostCount={setRepostCount}
                  getFilterTrack={getFilterTrack}
                  artWork={topVideoObj?.art_work}
                  playingVideo = {topVideoObj && topVideoObj} />
                  

                <div
                  ref={videosPlayRef}
                  className="videos_features_icons"
                >
                  <div >
                    <MuiTooltip title="Repost" placement="left">
                      <img src={RepostIconfill} alt="" width="32px" height="32px"
                        className="videos_icon_margin" onClick={() => setOpenRepostPopup(true)} />
                    </MuiTooltip>
                    {openRepostPopup &&
                      <RepostPopup
                        open={openRepostPopup}
                        onClose={handleRepostClose}
                        profileImage={topVideoObj?.art_work || topVideoObj?.cover_image || defaultProfile}
                        artistName={topVideoObj?.artist_name}
                        songName={topVideoObj?.title}
                        repost_count={respostSubmit}
                        handleChartTrackRepost={handleChartTrackRepost}
                        _handleTextFieldChange={handleCaptionChange}
                      />
                    }
                  </div>
                  <div>
                    <MuiTooltip title="Share" placement="left">
                      <img src={ShareIconfill} alt="" width="32px" height="32px" className="videos_icon_margin"
                        onClick={onShare} />
                    </MuiTooltip>

                    

                    <SharePlaylistPopup
                      id="Video"
                      open={openScanPopup}
                      handleClose={() => setOpenScanPopup(false)}
                      track={shareVideo}
                    />

                  </div>
                  <div>
                    <MuiTooltip title="Playlist" placement="left">
                      <img src={AddToPlaylistfill} alt="" width="32px" height="32px" className="videos_icon_margin" onClick={handleAddToPlaylist} />
                    </MuiTooltip>
                  </div>
                  <div 
                  
                  className={`${localStorage.getItem('user_type') != 'label' && 'cursor-not-allowed'}`}
                  >
                    <MuiTooltip title="Deal" placement="left">
                      <img src={DealIconfill} alt="" width="32px" height="32px" className="videos_icon_margin" onClick={() => videoDealRedirection()}/>
                    </MuiTooltip>
                  </div>
                  {
                    openDealPopUp &&
                    <Dialog open={openDealPopUp}
                      onClose={handleCloseDealPopUp}
                      PaperProps={{ className: "dialog-Paper-Deal" }}
                    >
                      <div style={{ color: 'white', fontSize: '20px', fontWeight: 'bold', padding: '40px' }}>Deal <img src={nxzloader} style={{ width: "20px", height: '20px' }} /> coming soon </div>
                    </Dialog>
                  }
                </div>
              </div>

            </div >
          )
        }
      </>
    )
  }

  const returnHiddenXsContent = () => {
    return (
      <Grid container item xs={12} className="topbannerWrapper videosWarraper">
        

        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className="currentkey_direction">
          <span className="currentKeyFont">{currentKey ? currentKey : '1'}</span>
          
          <img src={Sort_Up_large} height="20px" width="20px" />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} onClick={() => { redirectToProfile(topVideoObj) }}>
          <img style={{ cursor: 'pointer' }} src={topVideoObj ? topVideoObj.art_work || topVideoObj.artwork : defaultProfile} id="profilepic_in_statsTab" />
        </Grid>
        
        <Grid item xs={10} sm={5} md={5} lg={5} xl={5} className="video_title">
          <span className="currentKeyFont" style={{ color: "white" }} >{topVideoObj ? topVideoObj.track_title ? topVideoObj.track_title : topVideoObj.title : "NA"}</span>
          <div className="video_username">
            <span className="video_username_font" style={{ paddingRight: "10px", cursor: 'pointer' }} onClick={() => { redirectToProfile(topVideoObj) }}>{topVideoObj ? topVideoObj.artist_name : "Unknown"}</span>
           
          </div>
        </Grid>
        
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5} className="display_playcount">
          <div style={{display: 'flex'}}>

            <img
              src={PlayIcon}
              style={{
                height: '24px',
                width: '24px',
              }}
            />
            <span className='currentKeyFont'>{topVideoObj ? topVideoObj.play_count : "0"}</span>
          </div>
          <div style={{display: 'flex'}}>

            <img src={repeatIcon} style={{ height: '24px', width: '24px' }} />
            <span className='currentKeyFont'>{topVideoObj ? topVideoObj.repost_count : "0"}</span>
          </div>
          <div style={{display: 'flex'}}>
            <img
              src={messageIcon}
              style={{
                height: '22px',
                width: '22px',
                marginTop: '3px'
              }}
            />
            <span className='currentKeyFont' style={{marginLeft: '2px'}}>{topVideoObj ? topVideoObj.comments_count : "0"}</span>
          </div>
        </Grid>

      </Grid>
    )
  }

  const returnHiddenXsUpContent = () => {
    return (
      <Grid container item xs={12} className="topbannerWrapper videosWarraper margin-top-mobiles-icons">
        
        <Grid container item xs={12}>
          <Grid item className="currentkey_direction">
            <span className="currentKeyFont">{currentKey ? currentKey : '1'}</span>
            <img src={Sort_Up_large} height="20px" width="20px" />
          </Grid>
          <Grid item onClick={() => { redirectToProfile(topVideoObj) }} style={{ marginLeft: '10px' }}>
            <img style={{ cursor: 'pointer' }} src={topVideoObj ? topVideoObj.artist_image || topVideoObj.artwork : defaultProfile} id="profilepic_in_statsTab" />
          </Grid>
          <Grid item className="video_mobile_title">
            <span className="currentKeyFont" >{topVideoObj ? topVideoObj.title : "NA"}</span>
            <div className="video_username">
              <span className="video_username_font" style={{ paddingRight: "10px", cursor: 'pointer' }} onClick={() => { redirectToProfile(topVideoObj) }}>{topVideoObj ? topVideoObj.artist_name : "Unknown"}</span>
              
            </div>
          </Grid>

        </Grid>
        <Grid container item xs={12}>
          <Grid item container spacing={2} style={{ marginLeft: '80px' }} className="display_mobile_playcount">
            <Grid item>
              <div>

                <img
                  src={PlayIcon}
                  style={{
                    height: '24px',
                    width: '24px',
                  }}
                />
                <span className='currentKeyFont'>{topVideoObj ? topVideoObj.play_count : "0"}</span>
              </div>
            </Grid>
            <Grid item>
              <div>

                <img src={repeatIcon} style={{ height: '24px', width: '24px' }} />
                <span className='currentKeyFont'>{topVideoObj ? topVideoObj.repost_count : "0"}</span>
              </div>
            </Grid>
            <Grid item>
              <div>
                <img
                  src={messageIcon}
                  style={{
                    height: '24px',
                    width: '24px',
                  }}
                />
                <span className='currentKeyFont'>{topVideoObj ? topVideoObj.comments_count : "0"}</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const returnCommentsFirstContent = () => {
    return (
      <Grid item xs={12} sm={10} md={10}>

        <AppBar position="static" className="appbar-div" >
          <Tabs
            value={commentTabs}
            onChange={handleChange}
            aria-label="simple tabs example"
            TabIndicatorProps={{
              style: { backgroundColor: "transparent" },
            }}
            className="tabsWarpper_in_statsTab videos_TabsWarpper"
          >

            <Tab
              label="Splits"
              {...a11yProps(0)}
              style={{
                backgroundColor: "#212121",
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Inter",
                margin: "0 12px 0px 24px",
                minWidth: "80px",
                minHeight: "30px",
                borderRadius: "20px",
                textTransform: "none",
              }}
              id="tabs_in_statsTab"
              className={
                value === 0
                  ? "video-comment-split-active"
                  : "video-comment-split-inactive"
              }
            />
          

            <Tab
              label={`Comments (${commentsCount})`}
              {...a11yProps(1)}
              style={{
                backgroundColor: "#212121",
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Inter",
                margin: "0px 12px 0px 6px",
                minWidth: "80px",
                minHeight: "30px",
                borderRadius: "20px",
                textTransform: "none",
              }}
              id="tabs_in_statsTab"

              className={
                value === 1
                  ? "video-comment-split-active"
                  : "video-comment-split-inactive"
              }
            />
            <Tab
              label={`Messages (${messageCount})`}
              {...a11yProps(2)}
              style={{
                backgroundColor: "#212121",
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Inter",
                margin: "0px 0px 0px 5px",
                minWidth: "80px",
                minHeight: "30px",
                borderRadius: "20px",
                textTransform: "none",
              }}
              id="tabs_in_statsTab"

              className={
                value === 2
                  ? "video-comment-split-active"
                  : "video-comment-split-inactive"
              }
            />

          </Tabs>
        </AppBar>
      </Grid>
    )
  }
  return (
    <>
      {
        loading ? (<>

          <Row className="mt-6  width_100 loader-charts" align="middle" justify="center">
            <Col>
              {" "}
              <img src={nxzloader} style={{ width: "5%" }} />
            </Col>
          </Row>
        </>) : (<>

          <Grid container spacing={1} style={{ overflowX: "hidden", overflowY: "auto" }} className={`${isPlaylist ? 'video_height_with_audio' : 'video_height_without_audio'} video-audio-scroll`}>
            <Grid container item xs={12} sm={12} md={12} lg={7} xl={8} className="videos_stream_container videos_scroll" style={{ height: '100%', flexGrow: 1 }}>
              {returnVideoContent()}




              <div
                className="tabsection_container"
                style={
                  { width: '100%' }
                }
              >

                <div className="hidden_comments_icons_in_mobile">
                  {returnHiddenXsContent()}
                </div>

                <div className="hidden_comments_icons_in_Desktop">
                  {returnHiddenXsUpContent()}
                </div>

                <Grid
                  container
                  xs={12} sm={12} md={12}
                  className="splitSection"
                >

                  {returnCommentsFirstContent()}

                  <Grid item xs={12} sm={2} md={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 0px" }}>
                    <div
                     
                      className="filterIconWrapper_in_statsTab filterPosition"

                    >
                      <Box
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpenFilter(true)}
                      >
                        <img src={chartFilter} alt='' width='16px' height='14px' />
                      </Box>
                      {
                        openFilter && (
                          <ChartsFilter
                            open={openFilter}
                            onClose={() => setOpenFilter(false)}
                            chartType={chartType} chartTime={chartTime}
                            handlerSelectedTime={handlerSelectedTime}
                            handlerSelectedFilter={handlerSelectedFilter}
                          />
                        )
                      }

        
                    </div>
                  </Grid>



                  <div style={{ width: "100%" }}>
                    <TabPanel
                      value={commentTabs}
                      index={0}
                    >

                      <SplitsSection
                        graphdata={graphdata}
                        graphCategory={graphCategory}
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                        userSplit={userTrack && userTrack}
                        userTrackLangth={userTrack && userTrack.length > 0}
                        chartTime={chartTime}
                        chartType={chartType}

                      />
                    </TabPanel>
                  </div>
                  <TabPanel value={commentTabs} index={1}>
                    <CommentSection
                      id={trackId}
                      comments={topVideoObj?.comments}
                    />
                  </TabPanel>
                  <TabPanel value={commentTabs} index={2}>
                    <MessageSection
                      currentUserID={user_id}
                      trackId={selectedVideo}
                      isChartMessage={true}
                      setMessageCount={setMessageCount}
                    />
                  </TabPanel>


                </Grid>
              </div>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={5} xl={4} className="border-left-top-videos" style={{ padding: "10px", height: '100vh', overflow: 'scroll' }}>
              <Grid container style={{ borderRadius: "15px", padding: "2px 2px 0px 2px", height: '100%' }} className={` `}>
                {
                  currentTrack.length == 0 ? (<>

                    <Row className="mt-6  width_100" justify="center" style={{ alignItems: "top", marginTop: "5%" }}>

                      <Col style={{ width: '25px' }}>
                        {" "}
                        <img src={nxzloader} style={{ width: "100%" }} />
                      </Col>
                    </Row>
                  </>) :
                    currentTrack[0].hasOwnProperty("data") ?
                      <Row className="mt-6  width_100" justify="center" style={{ alignItems: "top", marginTop: "10%" }}>

                        <Col style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                          {" "}
                          <Typography style={{ color: "white", fontSize: "18px", fontFamily: "Inter", fontWeight: "bold" }}>No video available</Typography>
                        </Col>
                      </Row>
                      : (<>
                        <Grid container className="videos_scroll" style={{ height: '100%' }}>


                          <ChartTopSongs
                            songsList={currentTrack}
                            setTopVideoObj={setTopVideoObj}
                            setSelectedVideo={setSelectedVideo}
                            setSetCurrentKey={setSetCurrentKey}
                            setTrackId={setTrackId}
                            setUserTrack={setUserTrack}
                            setRepostCount={setRepostCount}
                            trackId={trackId}
                            getFilterTrack={getFilterTrack}
                            setPlayVideoFromRedirection={(value: any) => {setPlayVideoFromRedirection(value)}}
                            setShareVideo={setShareVideo}
                            setSplitsTabIndex={(e: any,index: any) => {handleChange(e,index)}}
                          />
                        </Grid>
                      </>
                      )
                }
              </Grid>

            </Grid>
          </Grid>
        </>)
      }

      {
        showModalAddNewPlaylist && (
          <PlayListCreateAndAddplayListModel closeModal={closeModal} showModalAddNewPlaylist={showModalAddNewPlaylist} addTrackToPlaylist={'addTrackToPlaylist'} />
        )
      }
      {open && <LoginAlert open={open} handleClose={handleClose} />}
      {openAlertLogin && <LoginAlert open={openAlertLogin} handleClose={handleCloseAlertLogin} dialogText={dialogTextAlertLogin} />}

      <AddToQueuePopup id="Queue" open={openModal} handleClose={() => { setOpenModal(false) }} playlistType="video" trackData={selectedVideo} />
    </>

  )
}

export default withRouter(Comments);
// Customizable Area End
