// Customizable Area Start
import React from "react";
import './index.css'
import {
    Typography,
    Grid,
    Button,
} from "@material-ui/core";
import '../../../../user-profile-basic/src/Creatives/ComposeBlock.web.css'

//@ts-ignore
// export const configJSON = require("../config.js");

const HeadBlock = ({ total_audio_count, total_video_count, coverImage }: any) => {

    return (

        <div
            className="playlist-upperDiv"
            style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000),url(${coverImage})`,
                height:"45%"
            }}
        >
            <Grid container className="head-parent">
                <Grid item xs={6}
                    sm={4}
                    md={2}
                    className="head-sub-parent" >
                    <Typography className="head-count">
                        {total_audio_count < 10 ? `${0}${total_audio_count}` : total_audio_count + 1}
                    </Typography>
                    <Button className="audio-video-btn"> Audio Playlist </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={2} className="head-sub-parent1" >
                    <Typography className="head-count">
                        {total_video_count < 10 ? `${0}${total_video_count}` : total_video_count + 1}
                    </Typography>
                    <Button className="audio-video-btn"> Video Playlist </Button>
                </Grid>
            </Grid>
        </div>
    )

}

export default HeadBlock
// Customizable Area End