// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col } from "antd";
import { CheckBlueLink } from './assets';
import './link_popup.css'

interface Props{
    id: any;
    open: any;
    handleClose: any;
}

export class LinkCopiedPopup extends React.Component<Props> {

  render() {    
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-link"}}
     >
         <Row justify="center" >
                <Col >
                 <Grid container  className='dialogplaylistbox'>

                  <Grid item className='dialogplaylistinsodebox'>
                    <Grid className='dialogling' data-test-id="send-sms" >Link</Grid>
                    <Grid className='linkedpcopied' data-test-id="send-sms" >Copied</Grid>
                  </Grid>
                  <Grid item> 
                    <img src={CheckBlueLink} className='checkbluelinkimage'/>
                  </Grid>
              </Grid>
            </Col>
         </Row>
     </Dialog>
    )
  }
}

export default LinkCopiedPopup
// Customizable Area End