// Customizable Area Start
import { notification } from "antd";
import "./notification.css"
export const openNotification = (messages: any, types: any) => {
  notification.open({
    message: types,
    type: types.toLowerCase(),
    duration: 4,
    description: messages,
    className: 'notification_style',
  });
};
// Customizable Area End
