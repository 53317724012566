Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
const urlConfig = require("../../../../../framework/src/config")
  
exports.getAPIMethod = "GET";
  exports.httpPostType = "POST";
  exports.httpDeleteType = "DELETE";
  exports.httpPutType = "PUT";
  
  exports.endIncreaseCopylinkCount = "/bx_block_audiomusic/views/update_copy_link_coun";
  exports.endBannerList = "/bx_block_audiomusic/expan_track";
  exports.getIncreaseCopylinkCount = "/bx_block_audiomusic/views/update_copy_link_count";
  exports.MethodCreativesList = "GET";
  
  exports.endPointGetBannerLists = "/bx_block_audiomusic/banners";
  exports.endVideoList = "/bx_block_videos/top_videos";
  
exports.baseUrl = urlConfig.baseURL;
 
  // Customizable Area End