// Customizable Area Start
import { Card} from "antd";
import React, { useRef, useEffect, useState } from "react";
import "blocks/dashboard/assets/css/videoPlayer.css";
import {  loopIc, playButton, playNow } from "blocks/dashboard/src/assets";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import 'blocks/user-profile-basic/src/Common/CardItem.web.css'
import axios from "axios";
import { openNotification } from "../../Notification.web";
import { useHistory } from "react-router-dom";
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
const style = { background: '#0092ff', padding: '8px 0' };


const VideoPlayer = (props: any) => {
    const vedioPlayer: any = useRef();
    const [songId, setSongId] = useState<number>()
    const [elapsedTime, setElapsedTime] = useState<number>(500)
    const [hide, setHide] = React.useState(true)
    const history = useHistory();

    const mediaClick = (e: any, key: any) => {
        e.stopPropagation();
        props.setHandleAudioVideo(1)
        setInterval(() => {
            setElapsedTime((elapsedTime) => elapsedTime + 10);
        }, 10);
        props.setSelected(key)
        setHide(false)
    }
    const pause = () => {
        vedioPlayer?.current?.pause();
        props.setSelected(null)
        setHide(true)

        // setPlayBt(false);
    };
    const addPlayTrackCount = async (id: any) => {
        const token: any = localStorage.getItem("token")
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
        }
        await axios.get(`${baseUrl}/bx_block_audiomusic/play_track?id=${id}`, { headers })
            .then((response: any) => {

            }).catch((err: any) => {

                if (token) {
                    openNotification(err?.response?.data?.errors && err?.response?.data?.errors[0]?.token, "Error")
                } else {
                    ""
                }
            });
        // }


    }
    useEffect(() => {
        elapsedTime == 10000 ? addPlayTrackCount(props.id) : null
        if (props.handleAudioVideo !== 1) {
            pause()
            props.setHandleAudioVideo(props.handleAudioVideo)

        }
    }, [props.handleAudioVideo, elapsedTime])
    const seekBar = (value: any) => {
        // setSeekValue((value / endingTime) * 100);
        const seek = (vedioPlayer.current.duration * value) / 100;
        vedioPlayer.current.currentTime = seek;
        // audioPlayer.current.play();
    };
    let vid = document.getElementById("myVideo");
    if (vid) {
        vid.onseeking = function (e) {
            e.stopPropagation()
            // confirm("Seek operation began!");
        };
    }

    const handlerSingleVideos = async (id: any, trackFile: any) => {
        let outputData
        const token: any = localStorage.getItem("token")
        await axios.get(`${baseUrl}/bx_block_audiomusic/expan_track?id=${id}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
            }
        })
            .then((response: any) => {

                outputData = response?.data?.data

            }).catch((err: any) => {
                console.log(err);
            }).finally(() => {

            })


       

            history.push({
                pathname: "/home/Charts/videos",
                state: { video_list: trackFile, activeNo: "5", data: outputData, currentList: props.currentList ? props.currentList : [] }
            })

    }

    return (
        <Card className='custom_card video-top-grid'  style={{}}>

            <div className='cover-img' style={{ position: "relative",
             height: "185px"
              }}>

                {!hide && props.selected === props.cardId ?
                    <video
                        ref={vedioPlayer}
                        id="myVideo"
                        poster={props.img}
                        width="100%"
                        height="100%"
                        controls={true}
                        
                        autoPlay={!hide}
                        style={{ objectFit: "cover" }}
                        //@ts-ignore
                        playsinline
                    >
                        <source src={props.trackFile} type="video/mp4" />
                    </video>

                    :
                    <>
                  <div className="pickgradient">
                        <img style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: '15px',
                            objectFit: "cover"
                        }}
                            src={props?.img} alt=""

                        />
                         </div>
                        <img
                            id={props.cardId}
                            style={{
                                marginRight: "5px",
                                position: "absolute",
                                top: "40%",
                                left: "45%",
                                height: "50px",
                                cursor: "pointer"

                            }}
                            onClick={(e) => {
                                // mediaClick(e, props.cardId)
                                localStorage.removeItem("selectedSingleVideo");
                                handlerSingleVideos(props.id, props.trackFile);
                            }}
                            src={playButton}

                        />
                    </>
                }

<div
                className="songs-left"
              >
                {/* <div style={{display:"flex"}}> */}
     
     
                <div className="originals-fonts-head">{props?.songName}</div>

                {/* </div> */}
                <Grid
                container
                className="flex"
            >
                <Grid item xs={12} className="flex">
                    <Typography
                        className="mr_1 userName_width originals-subhead-font">
                        {props?.author}
                    </Typography>
                    <Typography className="flex" style={{ alignItems: "center" }}>
                        <img src={playNow} height="18px" width="25px" />
                        <span className="play-times_blue mr_05 text_blue videoList-color" style={{ marginLeft: "-3px" }}>
                            {props?.playCount}
                        </span>
                    </Typography>
                    <Typography className="flex" style={{ alignItems: "center" }}>
                        <img src={loopIc} height="14px" width="14px" />
                        <span className="play-times_blue text_blue videoList-color" style={{ marginLeft: "3px" }} >
                            {props?.repost_count}
                        </span>
                    </Typography>
                </Grid>
            </Grid>
              </div>
            </div>


            
        </Card>
    );
};

export default VideoPlayer;
// Customizable Area End
