import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Router } from 'react-router';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import 'antd/dist/antd.css';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import combineReducers from './reducers';
import { HISTORY } from '../../components/src/common';
import { PubNubProvider } from 'pubnub-react';
import PubNub from 'pubnub';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk, promise];

const pubnub = new PubNub({
  subscribeKey: 'sub-c-e40f16f9-ff5d-4831-828d-afdda84141c3',
  publishKey: 'pub-c-a04a6d86-6161-42b7-83e9-e405d0be04e2',
  uuid: 'sec-c-ZmZjYjU1NzgtZDY0NC00NWE0LWExMzMtNzYzZDllZTIzODk4',
});

// Export pubnub as the default export
export default pubnub;

const store = createStore(
  combineReducers(),
  composeEnhancers(applyMiddleware(...middleware))
);

const initializeApp = async () => {
  // Any asynchronous initialization if needed

  // Assign the PubNub instance to the pubnub variable
  ReactDOM.render(
    <PubNubProvider client={pubnub}>
      <Router history={HISTORY}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </PubNubProvider>,
    document.getElementById('root')
  );
  registerServiceWorker();
};


initializeApp();
