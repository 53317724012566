// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { HISTORY } from "components/src/common";
export interface Props {
  closeCancelledModal: () => void;
  cancelledSubscription: any;
}
interface S {}
interface SS {
  id: any;
}

export default class PlanCanceledController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area End

  redirectHandler = async () => {
    const temp_id: any = await localStorage.getItem("temp_id");
    const userId: any = await localStorage.getItem("user_id");

    HISTORY.push({
      pathname: `/creatives/beats?creative_id=${temp_id ? temp_id : userId}`,
    });

    localStorage.removeItem("temp_id");
  };

  handlePlanBtn = () => {
    location.pathname === "/planpayments"
      ? window.location.reload()
      : HISTORY.push("/planpayments");
  };
}
// Customizable Area End