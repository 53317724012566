// Customizable Area Start
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {  useHistory } from "react-router-dom";

export default function PlanCancelledPopup({ open, setIsplanPopupShow }: any) {
    const history = useHistory();

    const handleClose = (reason: any) => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        if(url.pathname.includes("planpayments")&&reason == "backdropClick"){
            setIsplanPopupShow(false)
        }else if (reason && reason == "backdropClick") {

            return;
        } else {
            setIsplanPopupShow(false)
        }
    }

    const handleUpgradeModal = () => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        if(url.pathname.includes("planpayments")){
            setIsplanPopupShow(false)
        }else{

            history.push('/planpayments')
        }
    }
    const getUser = localStorage.getItem("user_name")
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{
                    style: {
                        backdropFilter: "blur(10px)",
                        backgroundColor: "rgba(0, 0, 0, 0.15)",
                    },
                }}
                disableBackdropClick={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#121212",
                        borderRadius: "20px",
                        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
                        border: "solid 1px #1f1f1f",
                        width: "600px",
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" className="dialog-title repost-head" style={{ textAlign: "center", fontWeight: "normal" }}>
                    <span style={{
                        fontSize: "19px",
                        fontWeight: 800,
                        color: "white"
                    }}> Subscription Plan Failed</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description ">
                        <span style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "white"
                        }}>
                            Dear {getUser},<br />

                          <p style={{color:"red",fontSize:"14px",fontFamily:"Inter",fontWeight:"normal"}}>
                            
                            We regret to inform you that the payment for your subscription plan has failed. Our records indicate that there was an issue processing the payment method associated with your account. To continue enjoying uninterrupted access to our music app and its premium features, we kindly request your attention to resolve this matter.<br /><br/>
                            Here are some common reasons for a failed subscription payment:
                            </p>  

                            
                            1. Insufficient Funds:<p style={{color:"red",fontSize:"14px",fontFamily:"Inter",fontWeight:"normal"}}>
                                 Please ensure that the payment method linked to your account has sufficient funds to cover the subscription fee.<br />
                                 </p>

                            2. Expired or Invalid Payment Method:<p style={{color:"red",fontSize:"14px",fontFamily:"Inter",fontWeight:"normal"}}> If the payment method on file has expired or is no longer valid, please update your payment details promptly.<br />
                            </p>
                            3. Blocked or Declined Transaction:<p style={{color:"red",fontSize:"14px",fontFamily:"Inter",fontWeight:"normal"}}> In some cases,
                            </p>
                        </span>
                    </DialogContentText>
                    <DialogActions>
                        {/* <Link to="/Welcome"> */}
                        <Button color="primary" onClick={handleUpgradeModal}>
                            Recheck Payment
                        </Button>
                        {/* </Link> */}
                        <Button onClick={() => {
                            setIsplanPopupShow(false)
                            localStorage.clear()
                            history.push("/Welcome")
                        }} color="primary" autoFocus>
                            Logout
                        </Button>
                    </DialogActions>

                </DialogContent>

            </Dialog>

        </div>
    );
}
// Customizable Area End