// Customizable Area Start
import React from 'react';
import { Button } from '@material-ui/core';
import { usePlaidLink } from 'react-plaid-link';

// Customizable Area End


// Customizable Area Start
interface Props {
    onNextClick: () => void;
    onBackClick: () => void;
    nextButtonName?: string;
    backButtonName?: string;
    isPlaid:boolean;
    linkToken:string;
    onSucessCallApi:(plaid_user_id:string,accountId:string)=>void;
    isLinkOpen:()=>boolean;
}
// Customizable Area End

// Customizable Area Start


export const NextBackButtonGroup = (props: Props) => {
    const { open } = usePlaidLink({
        token: props.linkToken,
        onSuccess: (public_token: any, metadata: any) => {
          // send public_token to server
                props.onSucessCallApi(metadata.accounts[0].id,metadata.institution?.institution_id)
        },
      })
    const handleLinkAccountClick=async()=>{
        if(props.isLinkOpen()){
        open()
        }
    }
    const shouldRenderParagraph = props.isPlaid && 
    typeof props.onSucessCallApi === 'function' && 
    props.onSucessCallApi.toString().includes('_addPlaidAccountAPICall');
    return (
        <>
        {shouldRenderParagraph?
         <Button
            data-test-id="nextbuttonid"
            className='SplitButton Next'
            onClick={handleLinkAccountClick}>
             LINK ACCOUNT 
         </Button>
         :
            <Button
            data-test-id="nextbuttonid"
                className='SplitButton Next'
                onClick={props.onNextClick}
            >
                {props.nextButtonName || 'Next'}
            </Button>
}
            <Button
            data-test-id="prevbuttonid"
                className='SplitButton Back'
                onClick={props.onBackClick}
            >
                {props.backButtonName || 'Back'}
            </Button>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default NextBackButtonGroup;
// Customizable Area End
