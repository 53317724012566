export const EditIcon = require("./assets/Edit Icon.png");
export const PlusMath = require("./assets/Plus Math.webp");
export const UPLOAD = require("./assets/UPLOAD.webp");
export const Group3 = require('./assets/Group 3.webp');
export const AddUserIcon = require('./assets/add-user copy.webp');
export const PlaylistCoverAdd = require('./assets/Playlist Cover.webp');





