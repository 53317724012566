export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const FollowAdd = require("../assets/followingPlaylist.png");
export const SendAdd = require("../assets/directMsgPlaylist.png");
export const DmAdd = require("../assets/messagePlaylist.png");
export const backArrow = require("../assets/VectorBack.png");
export const playStats = require("../assets/ctrlplaylist.png");
export const downloadStats = require("../assets/ctrldownload.png");
export const splitStats = require("../assets/ctrlSplitRow.png");

//CtrlFilter
export const trackType = require("../assets/TrackType.png");
export const creativeRole = require("../assets/CreativeRole.png");
export const mySplits = require("../assets/MySplit.png");
export const trackStatus = require("../assets/TrackStatus.png");
export const hook = require("../assets/hook.png");
export const artist = require("../assets/artist.png");
export const director = require("../assets/director.png");
export const engineer = require("../assets/engineer.png");
export const producer = require("../assets/producer.png");
export const musician = require("../assets/musician.png");
export const songwriter = require("../assets/songwriter.png");
export const verse = require("../assets/Verse.png");
