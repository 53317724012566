import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";

const MyTimer = ({ expiryTimestamp, setShowResendOTP, showResendOTP }: any) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => setShowResendOTP(true),
  });
  useEffect(() => {
    showResendOTP ? start : "";
  }, [showResendOTP]);
  return (
    <div style={{ textAlign: "center" }}>
      <div className="gray">
        <span>{minutes}</span>:<span>{seconds}</span>
        <span>s</span>
      </div>
      {/* <button
        onClick={() => {
          const time = new Date();
          time.setSeconds(time.getSeconds() + 150);
          restart(time);
        }}
      >
        Restart
      </button> */}
    </div>
  );
};
export default MyTimer;
