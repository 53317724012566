// Customizable Area Start

import { Typography } from 'antd';
import {
    Box,
    Button,
    createStyles,
    styled,
    withStyles,
    Theme,
    LinearProgress
} from "@material-ui/core";
import React from 'react'
import { backarrow, bluecheck} from '../assets';
import { defaultProfile } from 'blocks/dashboard/src/assets';
import StreamAudioVideoController from './StreamAudioVideoController';

const RightSideMobileSingletrackbox = styled(Box)({
    height: "81px",
    borderRadius: "8px",
    gap: "10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    padding: "0px 10px 0px 5px",
    color: "white",
    "&:hover": {
    },
});

const RightsideAudioMobilehistorycontainer = styled(Box)({
    width: "48%",
    flexDirection: "column",
    gap: "30px",
    display: "flex",
    "@media(max-width: 1000px)": {
        width: "100%",
        padding: "0px 10px 0px 10px"
    },
});

const MobileTrackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    color: "#ffffff",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Inter",
});
export const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);

export default class MobileAudiovideo extends StreamAudioVideoController {
trackInformation=(item:any,index:any)=>{
    return(
    <RightSideMobileSingletrackbox
                                key={index}
                                onClick={() => {
                                    this.handleRedirectionOfAudioVideo(item, index)
                                  }}
                                  data-test-id="redirectionOfAudio"
                            >
                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}
                                    >
                                        {this.props.rightsideSelectedtab == "Audio" ? <img
                                            src={item.attributes.art_work||defaultProfile}
                                            style={webStyle.leftimage}

                                        /> : <img
                                            src={item.attributes.art_work||defaultProfile}
                                            style={webStyle.leftmobilevideoimage}

                                        />}
                                    </Box>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box>
                                            <MobileTrackname className="tracks_songs_text">
                                            {item.attributes.title}
                                            {item.attributes.is_verified &&
                                                <span className="Jason-Derulo">

                                                    <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                </span>
                                            }
                                            </MobileTrackname>
                                            <Box>
                                                <span style={webStyle.rightsidesplittext}>
                                                {item.attributes.user_name}
                                                </span>
                                            </Box>

                                        </Box>
                                        <Box>
                                            <Box style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                            }}>
                                                <span style={webStyle.rightsidesplittextwhite}>
                                                {this.props.getCountValue(item)}
                                                </span>
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box>
                                    <BorderLinearProgress variant="determinate" value={64} />
                                </Box>
                                </Box>
                            </RightSideMobileSingletrackbox>
    )
                        }

    render() {
        return (
            <>

<RightsideAudioMobilehistorycontainer style={{ paddingTop: "40px"}}>
                    <Box>
                        <img
                            src={backarrow}
                            style={{
                                width: "26px",
                                height: "26px",
                                cursor: "pointer",
                                marginBottom:"10px"
                            }}
                            onClick={() => this.props.onBack()}
                            data-test-id="onbacktest"
                        />
                        <img
                            src={this.props.selectedStreamedTrack?.attributes.avatar||defaultProfile}
                            style={{
                                height: "375px",
                                width: "100%",
                                borderRadius: 10,
                                position: "relative",
                                opacity: 0.6
                            }}
                           
                        />
                    </Box>
                    <Box>

                        <Box style={{ width: "100%" }}>
                            <Box style={webStyle.RightsideMobiletabsbutton}>
                                <Box style={webStyle.tabbox}>
                                    <Button

                                        style={this.props.rightsideSelectedtab == "Audio" ? webStyle.mobiletabactiveaudiovideobutton : webStyle.tabmobileaudiovideobutton}

                                        onClick={() => this.props.handleAudiovideotab("Audio")}
                                        data-test-id="audiotab"
                                    >
                                        Audio
                                    </Button>
                                </Box>
                                <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        style={this.props.rightsideSelectedtab == "Video" ? webStyle.mobiletabactiveaudiovideobutton : webStyle.tabmobileaudiovideobutton}
                                        onClick={() => this.props.handleAudiovideotab("Video")}
                                        data-test-id="Myvideotab"
                                    >
                                        Video
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={(this.props.usertype !== "listener" && this.props.usertype !== "listener_plus") ? { paddingTop: "11px" } : { paddingTop: "22px" }}>
                        <Typography style={webStyle.trackname}>
                            {" "}
                            <span style={webStyle.mobilebluetext}>
                            {!this.props.usertype.includes("listener") ? this.props.selectedStreamedTrack.attributes.user_name : "You:"}
                            </span>{" "}
                            Streamed
                        </Typography>
                        {this.props.tracklistData.length>0?
                        this.props.tracklistData.map((item:any, index:any) => (
                            this.trackInformation(item,index)
                        ))
                        :
                        <div
                                style={{
                                    color: "#ffffff",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    paddingTop: "132px",
                                    paddingLeft: "264px"
                                }}
                            >
                                No Stream History Available
                            </div> }
                        
                    </Box>
                </RightsideAudioMobilehistorycontainer>

            </>

        )
    }
}

const webStyle = {
    
    RightsideMobiletabsbutton: {
        background: "#070707",
        display: "flex",
        justifyContent: "space-around",
        padding: "2px 0 2px 0",
        border: "3px solid #212121",
        borderRadius: "30px",
    },

    tabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },

    leftimage: {
        borderRadius: "10px",
        width: "75px",
        minWidth: "75px",
        height: "75px",
        objectFit:"cover" as "cover",
        border: "1px solid #141414"
    },

    rightsideleftimage: {
        borderRadius: "60px",
        width: "60px",
        minWidth: "60px",
        height: "60px",
        border: "2px solid #FFFFFF"
    },

    leftmobilevideoimage: {
        borderRadius: "10px",
        height: "75px",
        border: "1px solid #141414",
        width: "120px",
        minWidth: "120px",
        objectFit:"cover" as "cover"
    },
 
    leftsideAlltracksbox: {
        width: "85%",
    },

    trackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
    },

    mobilebluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: "1.29",
    },

    splittext: {
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
    },

    rightsidesplittext: {
        fontSize: "14px",
        fontWeight: 700,   
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
    },

    splittextwhite: {
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
    },

    rightsidesplittextwhite: {
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#F0F0F5",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
    },

    tabmobileaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",
    },

    mobiletabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
        color: "#ffff",
    },
};

// Customizable Area End