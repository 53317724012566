// Customizable Area Start
import React from 'react';
import { Box } from '@material-ui/core';
import AudioVideoInfoCard from '../Common/AudioVideoInfoCard.web';
import HomeHeaderDropdown from 'components/src/CustomChart/PopUps/HomeHeaderDropdown.web';
import { defaultProfile } from '../../assets';
// Customizable Area End


// Customizable Area Start
 interface Props {
    selectedTrack: any;
    unreadReplies: any;
    selectedTrackMediaDetails: any;
    setFollowUser: (userId: any) => void;
    doUnFollow: (userId: any) => void;
 }

 
// Customizable Area End

// Customizable Area Start
export const MediaProfileWebLP = (props: Props) => {
    const logged_in_user_profile = localStorage.getItem('profile_img')
    const logged_in_user_name = localStorage.getItem('user_name')
    const logged_in_user_type = localStorage.getItem('user_type')
    return (
        <>
        <div className='CMT-dropdown'>
          <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div className="dropdown-border" style={{}}>
            <HomeHeaderDropdown sizexl={24} dmActive={true}
              anchorEl={null} handleClose={{}} dialogText={""} handleLoginClose={{}} open={false} currentKeyCharts={"3"} setCurrentKeyCharts={{}}
              currentKeyPlaylist={"5"}
              setCurrentKeyPlaylist={{}}
            />
            </div>
          </div>
        
        {
            
          props.selectedTrack != '' ?(
            <>
            <div style={{height: '100%',flexGrow: 1, overflowY: 'auto', scrollbarWidth: 'none'}}>
            
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh', flex: 1, padding: '0px 8px' }}>
                <Box className='CommentChatBox'>
                    <AudioVideoInfoCard
                        media={{}}
                        mediaType="audio"
                        playMedia={(media: any, type: string) => { }}
                        viewProfile={() => { }}
                        selectedTrackMediaDetails={props.selectedTrackMediaDetails}
                        followUser={(userId: any) => {props.setFollowUser(userId)}}
                        doUnFollow ={(userID: any) => props.doUnFollow(userID)}
                    />
                </Box >
            </Box>
            </div>
            </>
          )
          :(
            <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                <div>
                  <img src={logged_in_user_profile != ('null' || '')? logged_in_user_profile : defaultProfile} style={{borderRadius: '50%', height: '150px', width: '150px',cursor:"pointer"}}/>
                </div>
                <div style={{marginTop: '10px'}}>
                  <span style={{color: 'white', fontSize: '16px', fontWeight: 900}}>{logged_in_user_name}</span>
                </div>
                <div>
                  <span style={{color: 'gray', fontSize: '16px', fontWeight: 900}}>{`${logged_in_user_type}`.charAt(0).toUpperCase() + `${logged_in_user_type}`.slice(1)}</span>
                </div>

                <div style={{marginTop: '10px'}}>
                  <span style={{color: 'gray', fontSize: '22px', fontWeight: 900}}>You Have <span style={{color: 'blue', fontSize: '22px', fontWeight: 900}}>{props.unreadReplies}</span></span>
                </div>
                <div>
                  <span style={{color: 'gray', fontSize: '22px', fontWeight: 900}}>New Replies</span>
                </div>
              </div>
            
             </>
          )
        }
         </div>
    
            </>
    );
}
// Customizable Area End


// Customizable Area Start
export default MediaProfileWebLP;
// Customizable Area End
