// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { Message } from "../../../../framework/src/Message";
import { openNotification } from "components/src/Notification.web";

export interface Props {
  accessList: any;
  showAccessListData: any;
  accessListLoader: boolean;
  accessListTrack: any;
  trackDetails: any;
  handleArtClick: any;
  track_id: any;
}

interface S {}

interface SS {
  id: any;
}

export default class AccessListController extends BlockComponent<Props, S, SS> {
  apiRemoveUserAccess: any;
  apiRemoveAllUserAccess: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {};

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  removeAccessUser = (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRemoveUserAccess = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/account_block/invite_users/remove_invite?track_id=${this.props.track_id}&user_list[]=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  removeAllAccessUser = () => {
    let endpoint = `/account_block/invite_users/remove_invite?track_id=${this.props.track_id}`;
    this.props.accessList.forEach((ids: any) => {
      endpoint += "&user_list[]=" + ids.id;
    });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRemoveAllUserAccess = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleRemoveUserAccess = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(responseJson.message, "Success");
      this.props.accessListTrack();
    } else {
      openNotification(responseJson.errors, "Error");
    }
  };

  handleRemoveAllUserAccess = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(responseJson.message, "Success");
      this.props.showAccessListData();
    } else {
      openNotification(responseJson.errors, "Error");
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId == this.apiRemoveUserAccess) {
      this.handleRemoveUserAccess(responseJson);
    } else if (apiRequestCallId == this.apiRemoveAllUserAccess) {
      this.handleRemoveAllUserAccess(responseJson);
    }

    // Customizable Area End
  }
}
// Customizable Area End
