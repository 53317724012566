// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import axios from "axios";
import { HISTORY } from "../../../components/src/common";
import { openNotification } from "../../../components/src/Notification.web";
import { follower } from "../../dashboard/src/assets";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";

export const configJSON = require("../../dashboard/src/config.js");
export interface Props {
    navigation?: any;
    id?: string;
    addNewPlaylist?: any;
    addTrackToPlaylist?: any;
}
interface S {
    dashboardData: any;
    playList: any;
    activeTab: any;
    handleAudioVideo: any;
    follower: any;
    showModalAddNewPlaylist: boolean;
    playlistData: any;
    selectedPlayList: any;
    handleplay: boolean;
    loading: boolean;
    follow: boolean;
    playlistId: string;
    token: any;
    playListType: any;
    errorMsg: string;
    playlistType: any;
    openEditPlaylistModal: any;
    BlankplayList: any;
    deletePlaylistId: any;
    openDeletePlaylistModal: any;
    editPlaylistId: any;
    oldPlaylistName: any;
    imageUrl: any;
    fileName: any;
    playlistName: any;
    showPlaylistNameError: any;
    disablity: any;
    disablityToSaveBtn: any;
    playListVal: any;
    musicButton: boolean;
    playlistFollowingToogle: boolean;
    privatePublicToggle: boolean;
    privatetoggle: boolean;
    privateData: any;
    publictoggle: boolean;
    publicData: any;
    showPlaylist: any;
    selectedPlaylistArray: any;
    playPlaylist: boolean;
    deleteSongId: string;
    openDeleteModal: boolean;
    openAlertLogin: boolean;
    dialogTextAlertLogin: any;
    openPopup: boolean;
    delete_track_id: any;
    delete_playlist_id: any;
    total_audio_count: number;
    total_video_count: number;
    selectedDiv: any;
    getVideoPlaylist: any;
    singleTrack: any;
    currentPlaylistName: any;
    currentPlaylistCoverImg: any;
    currentPlaylistId: any;
    coverImage: any;
    isFollowingPlaylist:boolean;
    followingPlaylistList:any;
    followPublicPlaylistToggle:boolean;
    followPublicPlaylist:any;
    openRepostPopup:boolean;
    openPopUp:boolean;
    dialogText:any,
    open:boolean,
    repost_track:any,
    textFieldValue:any;
    openEditPopup: any;
    openDeletePopup: any;
    copied: any;
    openLinkPopup: any;
    openMessageLink: any;
    selectedPlaylistId: any;
    selectedRepostSong: any;
    allListLength: any;
    playlistLoader: boolean;
    displayedArray:any;
  CopiedForLink:any;

}
interface SS {
    id: any;
}

export default class WebPlaylistController extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiCallId: string = "";
    apiGetQueryStrinurl: string = "";
    orginalRef: any;
    topBeatRef: any;
    topHooksRef: any;
    topPlaylistRef: any;
    topSongsRef: any;
    topVersesRef: any;


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            displayedArray:[],
            dashboardData: [],
            playList: [],
            activeTab: '',
            handleAudioVideo: 0,
            follower: 0,
            showModalAddNewPlaylist: false,
            playlistData: {},
            selectedPlayList: [],
            handleplay: false,
            loading: false,
            follow: false,
            playlistId: "",
            token: localStorage.getItem("token"),
            playListType: localStorage.getItem("playlist_type"),
            errorMsg: "",
            playlistType: "audioPlaylist",
            openEditPlaylistModal: false,
            BlankplayList: "",
            deletePlaylistId: "",
            openDeletePlaylistModal: false,
            editPlaylistId: "",
            oldPlaylistName: "",
            imageUrl: "",
            fileName: "",
            playlistName: "",
            showPlaylistNameError: false,
            disablity: true,
            disablityToSaveBtn: true,
            playListVal: '',
            musicButton: false,
            privatePublicToggle: false,
            playlistFollowingToogle: false,
            privatetoggle: false,
            showPlaylist: "",
            selectedPlaylistArray: [],
            playPlaylist: false,
            deleteSongId: "",
            openDeleteModal: false,
            openAlertLogin: false,
            dialogTextAlertLogin: '',
            openPopup: false,
            delete_track_id: null,
            delete_playlist_id: null,
            total_audio_count: 0,
            total_video_count: 0,
            selectedDiv: "",
            getVideoPlaylist: [],
            privateData: [],
            publicData: [],
            publictoggle: false,
            singleTrack: "",
            currentPlaylistName: "",
            currentPlaylistCoverImg: "",
            currentPlaylistId: "",
            coverImage: localStorage.getItem("cover_img"),
            isFollowingPlaylist:false,
            followingPlaylistList:[],
            followPublicPlaylistToggle:false,
    followPublicPlaylist:[],
    openRepostPopup:false,
    openPopUp:false,
    dialogText:"",
    open:false,
    repost_track:"",
    textFieldValue:"",
    openEditPopup: false,
    openDeletePopup: false,
    copied: false,
    openLinkPopup: false,
    openMessageLink: false,
    selectedPlaylistId: '',
    selectedRepostSong: "",
            allListLength: "",
            playlistLoader: false,
      CopiedForLink:""


        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
                this.getToken();
            });
        }
        localStorage.removeItem("selectedTab")
        if (this.state.token) {
            this.setState({ loading: true })
            this.getPlayLists()
            this.playlistPlayCount()
            this.getVideoPlayLists()
            let urlArr = window.location.href.split("/");
            let slug: any = urlArr.length !== 0 ? urlArr.slice(-1) : "";
            this.setState({ singleTrack: slug })
            if (!isNaN(parseFloat(slug[0])) && isFinite(slug[0])) {
                this.getShowPlaylistByID()

            }
        } else {
            HISTORY.push("/");
        }

    }


    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    onCopy = () => {
        if(this.state.selectedPlaylistId?.attributes?.status != ("" || undefined)){
            const el = document.createElement('input');
            el.value =  window.location.origin.concat(`/home/playlist/public-view/${this.state.selectedPlaylistId.id}`)
            document.body.appendChild(el);
            el.select();
            el.focus();
            navigator.clipboard.writeText(window.location.origin.concat(`/home/playlist/public-view/${this.state.selectedPlaylistId.id}`));
            document.body.removeChild(el);
            this.setState({copied: true})
            this.setState({openLinkPopup: true})
        }
        else{
           openNotification('select any playlist','Error') 
        }
    }

    onCopyForPlaylist = () => {
        if(this.state.selectedPlaylistId?.attributes?.status != ("" || undefined)){
         let scanTo: any;
            scanTo =  window.location.origin.concat(`/home/playlist/public-view/${this.state.selectedPlaylistId.id}`)
            return scanTo
           
        }
        else{
           openNotification('select any playlist','Error') 
        }
    }

    onSingleCopy = (playlist: any) =>{
        const el = document.createElement('input');
        el.value =  window.location.origin.concat(`/home/playlist/public-view/${playlist.id}`)
        document.body.appendChild(el);
        el.select();
        el.focus();
        navigator.clipboard.writeText(window.location.origin.concat(`/home/playlist/public-view/${playlist.id}`));
        document.body.removeChild(el);
        this.setState({copied: true})
        this.setState({openLinkPopup: true})
    }

    linkopenPopup=()=>{
        this.setState({ CopiedForLink: this.onCopyForPlaylist() })
        this.setState({ openMessageLink: true })
      }

    changeMusicToogleTrue = () => {
        this.setState({ musicButton: true })
    }
    changeMusicToogleFalse = () => {
        this.setState({ musicButton: false })
    }
    changeplaylistFollowingToogleTrue = () => {
        this.setState({ playlistFollowingToogle: true })
    }
    changeplaylistFollowingToogleFalse = () => {
        this.setState({ playlistFollowingToogle: false })
    }
    changeprivatePublicToggleToogleTrue = () => {
        this.setState({ privatePublicToggle: true })
    }
    changeprivatePublicToggleToogleFalse = () => {
        this.setState({ privatePublicToggle: false })
    }
    deleteTrackFromPlaylist = async () => {
        let formData = new FormData();
        const token: any = localStorage.getItem("token")
        let urlArr = window.location.href.split("/");
        let slug: any = urlArr.length !== 0 ? urlArr.slice(-1) : "";
        formData.append('add_track_id', this.state.delete_track_id);
        formData.append('play_list_id', slug);
        await axios.delete(`${configJSON.baseUrl}/bx_block_playlist/delete_particular_track`,
            {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    token: token,
                },
                data: formData,
            },

        )
            .then((response: any) => {
                openNotification('track deleted', "Success")
                console.log("response",response)
                this.getShowPlaylistByID()
            }).catch((err: any) => {

                if (token) {
                    openNotification("Select Single Tracks To Proceed Your Request", "Error")
                } 
            });
    }
    handleRepost = () => {
        if (this.state.token && this.state.repost_track != "") {
            this.setState({
                openRepostPopup: !this.state.openRepostPopup,
                openPopUp: true,
            })

        } else {
            this.setState({
                dialogText: "2",
                open: true,
            })
            openNotification("Select Single Tracks To Proceed Your Request", "Error")

        }
    }
    handleRepostClose = () => {
        this.setState({
            openRepostPopup: false,
            openPopUp:false
        })
    }

    respostSubmit = () => {
        this.handleChartTrackRepost(this.state.textFieldValue)
        this.handleRepostClose()
    }
    handleCaptionChange = (e: any) => {
        this.setState({ textFieldValue: e.target.value })
    }

    handleChartTrackRepost = async (caption: any) => {
        await axios
            .post(
                `${configJSON.baseUrl}/bx_block_audiomusic/repost_count?id=${this.state.delete_track_id}&caption=${caption}`,
                null,
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",

                        token: this.state.token,
                    },
                }
            )
            .then((res: any) => {
                openNotification("Successfully Reposted", "Success")
            })
            .catch((err) => {
                openNotification("Select Single Tracks To Proceed Your Request", "Error")

            })
    };

    onShare = () => {
        const token: any = localStorage.getItem("token")
        const userType = localStorage.getItem('user_type')
        const subscriptionPlan = localStorage.getItem("subscription_plans")

        if (token) {
            if (!isNaN(+this.state.singleTrack[0])) {
                if (userType == "listener" && subscriptionPlan == "NXZ") {
                    this.setState({ openAlertLogin: true })
                    this.setState({ dialogTextAlertLogin: '3' })
                } else {
                    this.setState({ openPopup: true })
                }
            } else {
                openNotification("Please select playlist to share", "Error")
            }
        } else {
            this.setState({ openAlertLogin: true })
            this.setState({ dialogTextAlertLogin: '2' })
        }
    }

    followPublicPlaylist = async (id: any) => {
        this.setState({ loading: true })
        const token: any = localStorage.getItem("token")
        await axios.get(`${configJSON.baseUrl}/bx_block_playlist/follow_playlist?id=${id}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                token: token,
            }
        })
            .then((response: any) => {
                this.setState({ followPublicPlaylistToggle: false })
                openNotification(response.data.message, "Success")
                this.getShowPlaylistByID()
                this.setState({ loading: false })

            }).catch((err: any) => {
                this.setState({ loading: false })

                console.log(err);
                if (err?.response?.status === 401 || err?.response?.status === 400) {
                    openNotification("Please login to continue", "Error");
                }
                else {
                    err.response.status == 422 && this.setState({ followPublicPlaylist: [] })
                }

            });
    }

    UnfollowPublicPlaylist = async (id: any) => {
        this.setState({ loading: true })
        const token: any = localStorage.getItem("token")
        await axios.get(`${configJSON.baseUrl}/bx_block_playlist/unfollow_playlist?id=${id}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                token: token,
            }
        })
            .then((response: any) => {
                this.setState({ followPublicPlaylistToggle: true })
                this.setState({ loading: false })
                this.getShowPlaylistByID()
                openNotification(response.data.message, "Success")

            }).catch((err: any) => {
                this.setState({ loading: false })

                console.log(err);
                if (err?.response?.status === 401 || err?.response?.status === 400) {
                    openNotification("Please login to continue", "Error");
                }
                else {
                    err.response.status == 422 && this.setState({ followPublicPlaylist: [] })
                }

            });


    }
    getFollowingPlaylist = async () => {
        this.setState({ isFollowingPlaylist: true })
        this.setState({
            loading: true,
            playlistLoader: true

        })
        const token: any = localStorage.getItem("token")
        await axios.get(`${configJSON.baseUrl}/bx_block_playlist/playlist_followings`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                token: token,
            }
        })
            .then((response: any) => {
                this.setState({ isFollowingPlaylist: true })
                this.setState({ followingPlaylistList: response?.data?.data == undefined ? [] : response?.data?.data })
                this.setState({ loading: false, playlistLoader: false })

            }).catch((err: any) => {
                this.setState({ loading: false, playlistLoader: false })

                console.log(err);
                if (err?.response?.status === 401 || err?.response?.status === 400) {
                    openNotification("Please login to continue", "Error");
                }
                else {
                    err.response.status == 422 && this.setState({ followingPlaylistList: [] })
                }

            });
    }

    getPlayLists = async () => {
        // this.setState({ loading: true })
        this.setState({ isFollowingPlaylist: false })
        const token: any = localStorage.getItem("token")
        await axios.get(`${configJSON.baseUrl}/bx_block_playlist/play_lists`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                token: token,
            }
        })
            .then((response: any) => {
                this.setState({ playList: response?.data?.data == undefined ? [] : response?.data?.data })
                // this.setState({ loading: false })

            }).catch((err: any) => {
                // this.setState({ loading: false })
                this.setState({ isFollowingPlaylist: false })
                console.log(err);
                if (err?.response?.status === 401 || err?.response?.status === 400) {
                    openNotification("Please login to continue", "Error");
                }
                else {
                    err.response.status == 422 && this.setState({ playList: [] })
                }

            });

    }

    getVideoPlayLists = async () => {
        const token: any = localStorage.getItem("token")
        await axios.get(`${configJSON.baseUrl}/bx_block_playlist/video_play_list`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                token: token,
            }
        })
            .then((response: any) => {
                this.setState({
                    getVideoPlaylist: response?.data?.data
                })
                this.setState({ loading: false })

            }).catch((err: any) => {
                this.setState({ getVideoPlaylist: [] })
                this.setState({ loading: false })
                if (err?.response?.status === 401 || err?.response?.status === 400) {
                    openNotification("Please login to continue", "Error");
                }
                else {
                    err.response.status == 422 && this.setState({ playList: [] })
                }

            });

    }


    handleSelection = (selected: any) => {
        this.setState({ playListType: selected })

    };

    handleCloseDeletePlaylistModal = () => {
        this.setState({ openDeletePlaylistModal: false })
    }

    deletePlaylistConfirm = () =>{
        if(this.state.selectedPlaylistId.id){
            this.setState({openDeletePopup: true})
        }else{
            openNotification('select any playlist to delete', 'Error')
        }
    }

    handleDeletePerticularPlaylist = async () => {
        this.setState({ loading: true })
        const token: any = localStorage.getItem("token")
        await axios.delete(`${configJSON.baseUrl}/bx_block_playlist/play_lists/${this.state.deletePlaylistId}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
            }
        })
            .then((response) => {
                openNotification("Your Playlist Has Been Successfully Deleted", 'Success')
                this.setState({ loading: false })
                window.location.reload()
                this.getPlayLists();


            }).catch((err) => {
                this.setState({ loading: false })
                openNotification(err.response.data?.errors[0], 'Error')
            })
    }
    handleShowPlaylist = async (id: any, name: any, cover_image: any) => {
        if (this.state.playlistFollowingToogle){
            localStorage.setItem("playlist_type", "Public");
            HISTORY.push(`/home/playlist/public-view/${id}`);
        }else{

            localStorage.setItem("playlist_type", "Private");
            HISTORY.push(`/home/self-view/${id}`);
        }
        localStorage.setItem("currentPlaylistId", id)
    }

    handleDeletePlayList = (id: any, cover_img: any) => {
        this.setState({ deletePlaylistId: id })
        this.setState({ singleTrack: id })
        this.handleBannerCover(cover_img)
    }

    handleBannerCover = (img: any) => {
        const cover_img: any = localStorage.getItem("cover_img")
        this.setState({ coverImage: img ? img : cover_img })
    }

    getShowPlaylistByID = async () => {
        // this.setState({ loading: true })
        let urlArr = window.location.href.split("/");
        let slug = urlArr.length !== 0 ? urlArr.slice(-1) : "";
        await axios.get(`${configJSON.baseUrl}/bx_block_playlist/play_lists/${slug}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                token: this.state.token,
            }
        })
            .then((response: any) => {
                console.log(response?.data?.data.attributes.status, "data")
                this.setState({
                    privatetoggle: response?.data?.data.attributes.status == "private_playlist" ? false : true
                })
                this.setState({ playlistId: response.data.data.id })
                this.setState({ showPlaylist: response.data.data })
                this.setState({ privateData: response.data.data.attributes.playlist_items.data})
                this.setState({ publicData: response.data.data.attributes.playlist_items.data})
                this.setState({displayedArray: response.data.data.attributes.playlist_items.data
            })
                localStorage.setItem("artist_id", response.data.data.attributes.artist_id);
                this.setState({ selectedPlaylistArray: response.data.data.attributes.playlist_items.data })
                this.setState({ follow: response.data.data.attributes?.follow_status })
                this.setState({ follower: response.data.data.attributes?.followers_count })
                this.setState({ loading: false })
            }).catch((err: any) => {
                this.setState({ loading: false })
                console.log(err);
            }).finally(() => {
                this.setState({ loading: false })
            })
    }

    playlistPlayCount = async () => {
        await axios.get(`${configJSON.baseUrl}/bx_block_playlist/profile_playlist_count`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                token: this.state.token,
            }
        })
            .then((response: any) => {
                this.setState({
                    total_audio_count: response.data.audio_playlist_count,
                    total_video_count: response.data.video_playlist_count
                })
            }).catch((err: any) => {
                console.log(err);
            })
    }

    handleSelectedSongPlay = (song: any, showPlaylist: any, index: number) => {
        this.handleDeleteSong(song.id)
        this.setState({ repost_track: song })
        if (song.attributes.track_upload_type == "Video" || song.attributes.play_list_type == "Video") {
            //    this.handlerSingleVideos(showPlaylist,song.id,song.attributes.video_file)
        } else {

            localStorage.setItem("music_player", "true");
            localStorage.setItem("playListData", JSON.stringify(song));
            localStorage.setItem("selectedPlaylist", JSON.stringify(this.state.selectedPlaylistArray));
            let { handlePlayList,setPlaylistForQueue } = this.context;
            setPlaylistForQueue(this.state.showPlaylist)
            handlePlayList(song, index, this.state.selectedPlaylistArray, 'Playlist')
        }
    }
    handlerSingleVideos = async (showPlaylist: any, id: any, trackFile: any) => {
        let outputData
        const token: any = localStorage.getItem("token")
        await axios.get(`${configJSON.baseURL}/bx_block_audiomusic/expan_track?id=${id}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
            }
        })
            .then((response: any) => {

                outputData = response?.data?.data

            }).catch((err: any) => {
                console.log(err);
            })

        if (token) {
            HISTORY.push({
                pathname: "/home/Charts/videos",
                state: { currentList: showPlaylist ? showPlaylist : [] }
            })
        }
        else {
            HISTORY.push({
                pathname: "/home/Charts/videos",
                state: { video_list: trackFile, activeNo: "5", data: outputData, currentList: showPlaylist ? showPlaylist : [] }
            })
        }

    }

    handleDeleteSong = (id: any) => {
        this.setState({ delete_track_id: id })
        console.log(this.state.delete_track_id, "handledelete")
    }

    handleClosePlaylistModals = () => {
        this.setState({ openDeleteModal: false })
    }

    handleRepostPlaylist = async (id: any, caption: any) => {
        await axios
            .post(`${configJSON.baseUrl}/bx_block_playlist/repost_playlist?id=${id}&caption=${caption}`, null, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    token: this.state.token,
                }
            })
            .then((res: any) => {
                openNotification("Successfully Reposted", "Success")
            })
            .catch((err) => {
                openNotification("Some error occurred", "Error")
            })
    }

    handleFollow = async (id: any) => {
        await axios
            .get(`${configJSON.baseUrl}/account_block/follow_user?user_id=${id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",

                    token: this.state.token,
                },
            })
            .then((response: any) => {
                this.setState({ follow: true })
                this.setState({ follower: follower + 1 })
                openNotification(response.data.message, "Success");
            })
            .catch((err: any) => {
                console.log(err);
                openNotification(err.response.data.errors[0].token, "Error");
            })
            .finally(() => {
                this.setState({ loading: false })

            });
    };


    handleUnfollow = async (id: any) => {
        this.setState({ loading: true })

        await axios
            .get(`${configJSON.baseUrl}/account_block/unfollow_user?user_id=${id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    token: this.state.token,
                },
            })
            .then((response: any) => {
                openNotification(response.data.message, "Success");

                this.setState({ follow: false })
                this.setState({ follower: follower - 1 })
            })
            .catch((err: any) => {
                console.log(err);
                openNotification(err.response.data.errors[0].token, "Error");
            })
            .finally(() => {
                this.setState({ loading: false })

            });
    };

    // private to public and vice-versa

    handleChangeStatusOfPlaylist = async (status: any) => {
        const currentPlaylistId: any = await localStorage.getItem("currentPlaylistId")
        let urlArr: any = window.location.href.split("/");
        let slug = urlArr.length !== 0 && urlArr[urlArr.length - 1];
        let formData = new FormData();
        formData.append('status', status);
        const token: any = localStorage.getItem("token")
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
            token: token,
        }
        await axios.put(`${configJSON.baseUrl}/bx_block_playlist/play_lists/${currentPlaylistId || slug}`, formData, { headers })
            .then(async (response: any) => {
                openNotification(`Your playlist status has changed from ${status === "public_playlist" ? "private" : "public"} to ${status === "public_playlist" ? "public" : "private"}`, "Success")
            }).catch((err: any) => {
                openNotification("We Cannot Proceed Your Request,Try Again!", "Error")
            })
    }




    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors && responseJson.data) {
                if (responseJson.data.length === 0) {
                    this.setState({
                        errorMsg: "Data Not Found",
                        loading: false
                    });
                } else {
                    this.setState({
                        dashboardData: responseJson.data,
                        errorMsg: "",
                        loading: false
                    });
                }
            } else {
                let errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage)
                );
                if (errorReponse === undefined) {
                    this.setState({
                        errorMsg: "Something went wrong",
                        loading: false
                    });
                } else {
                    this.setState({
                        errorMsg: errorReponse,
                        loading: false
                    });
                }
            }
        }
    }

}
WebPlaylistController.contextType = AudioContextApi;
// Customizable Area End