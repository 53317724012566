// Customizable Area Start
import React, { useContext } from "react";
import "../Common/TopSongs.web.css";
import "./label.web.css"
import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { playcountP, loopIc, chatPlaylist, down, iconUp, expandMore, playlistProfile,player_Banner } from "blocks/dashboard/src/assets";
import millify from "millify";
import { Col, Row } from "antd";
import LineChart from "components/src/CustomChart/PopUps/LineCharts.web";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import LabelTopSongsController from "./LabelTopSongsController.web";


export default class LabelTopSongs extends LabelTopSongsController {
  topGrid = (props: any) =>{
    const {item, musicButton, index} = props;
    return(
      <Grid item md={4} lg={3} xs={3} sm={4}
                  className="profileDetailBlock">


                  <div className=" width_100 " style={{ display: "flex" }}>
                    <div>
                      <Row className="sn-grid mr_1 ml_05">
                        {index < 9 ? "0" + Number(index + 1) : index + 1}
                      </Row>


                      <img src={item.attributes.arrow ? iconUp : down} className="icon-up mr_05 ml_05" onClick={item.handlePlayList} />
                    </div>
                    <div>
                      <img style={{ borderRadius: "8px", border: "solid 1px gray", width: musicButton ? '80px' : '48px' }}
                        className="song-cover mr_1 topsongs-cover-listing"
                        src={item.type === "playlist" ?
                          item?.attributes?.cover_image : this.setCover(item)} alt=""
                      />
                    </div>
                    <div >
                      <Row>
                        <Row
                          justify="start" className="width_100 name_hiddne text_white cursor_pointer "> {item?.attributes?.name || 'Default Song(Unknown)'}</Row>
                        <Row
                          justify="start" className="width_100 text_gray cursor_pointer artist-name-width">{item?.attributes?.playlist_items?.data.length > 0 ? item?.attributes?.playlist_items?.data.length + ' Tracks' : '0 Tracks'}</Row>
                      </Row>
                    </div>
                  </div>
                </Grid>
    )
  }
  render() {
  const { TopPlaylists }: any = this.state;
  const { musicButton } = this.props;
 
  return (
    <Box
      style={{
        borderRadius: "20px",
        marginBottom: "13px",
        width: "100%",
        // marginTop: "22px",
        maxHeight: "415px",
        display: "flex",
        flexDirection: "column"
      }}>
      <Grid style={{ padding: "6px 0px 4px 0px", marginTop: "1%" }} container>
        <Grid item xs={7} md={7} lg={8}>
          <Row className=" width_100">
            <Typography className="top-head">{TopPlaylists && 'Top ' + (TopPlaylists.length >= 1 ? TopPlaylists.length : '0')}</Typography>
          </Row>
        </Grid>
        <Grid item xs={4} sm={5}
        md={5}
        lg={4}
          style={{ display: "flex" }} className="second-head-percentage" >
          <Hidden xsDown>
            <Grid item xs={3}>
              <p className=" text_white1 top_size_16 hiddnDaysScore " style={{ marginTop: "4px",paddingLeft:"10px" }}>24hr%</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_white1 top_size_16" style={{ marginTop: "4px", textAlign: "start",paddingLeft:"7px" }}>7d%</p>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }} className="hiddnDaysGraph">
              <p className="text_white1 top_size_16 hiddnDaysGraph" style={{ marginTop: "4px" }}>
                Last 7d
              </p>
            </Grid>


          </Hidden>
        </Grid>
      </Grid>

{/* beatList > 0 ? "scroll-noslider mostly-customized-scrollbar" : */}
      <Box
        className={"label-scroll mostly-customized-scrollbar"}
      >
        {TopPlaylists.length>0 ? TopPlaylists.map((item: any, index: any) => {
          return (
            <Col span={24} onClick={() => this.topPlayMusic(item, item.id, TopPlaylists, '')} className="musicRow">
              <Grid container style={{ padding: "6px 0px 4px 0px" }} className="main-container-space">
                {
                  this.topGrid({item, musicButton, index})
                }
                <Col xs={6} className="HiddnFullScreen hours-list"
                // onClick={item.handlePlayList} 
                >
                  <Row justify="end" className="iconDelete">
                    <img src={expandMore} className=" ml-1 mr_1" height="28px" width="28px" />
                  </Row>
                </Col>
                <Hidden smDown >
                <Grid item
                  // md={3}
                  md={1}
                  xs={2} sm={4} lg={3}
                  className="playButtonContain" style={{ display: "flex", alignItems: "center" }}>
                  <Row className=" width_100" >
                    <Hidden xsDown>
                      <Col span={24} >last_seven_days_graph

                       
                        {/* <img
                          src={playlistData?.id === item.id && handleAudioVideo === 2 ? pauseA : playButton}
                          height={selectedIndex === item.id && handleAudioVideo === 2 ? "16px" : "24px"}
                          width={selectedIndex === item.id && handleAudioVideo === 2 ? "17px" : "24px"}
                          className="play_whites mr_1 hiddnGraph"
                        /> */}
                        {/* <Hidden mdDown>
                          {trackName !== "Playlists" && item?.attributes?.track_file &&
                            <Waveform url={item?.attributes?.track_file} id={item?.id} height={24} />
                          }
                        </Hidden> */}
                      </Col>
                    </Hidden>


                  </Row>
                </Grid>
                </Hidden>
                <Grid item sm={3} md={2} lg={2}
                  
                  className="iconButton play-repost-comment-container">


                  <div className=" display-play_dark_back" >
                    <Col className="colButton width_100">
                      <div style={{ display: "flex", alignItems: "center" }} className='iconContent'>
                        <img className="iconImg"
                          src={playcountP}
                          height="13px"
                          width="11.5px"
                        />
                        <Box className="play-times iconContent">
                          {item?.attributes?.play_count ? millify(item?.attributes?.play_count) : "0"}
                        </Box></div>
                    </Col>
                    {/* <Col className="colButton width_100">
                      <div style={{ display: "flex" }} className='iconContent'>
                        <img className="iconImg" src={loopIc} height="19px" width="19px"  style={{marginTop: '2px'}}/>
                        <Box className="play-times iconContent">
                          {item?.attributes?.repost_count ? millify(item?.attributes?.repost_count) : "0"}
                        </Box>
                      </div> </Col>
                    <Col className="colButton width_100">
                      <div style={{ display: "flex", alignItems: "flex-end" }} className='iconContent'>
                        <img className="iconImg" src={chatPlaylist} height="20px" width="20px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.comments_count ? millify(item?.attributes?.comments_count) : "0"} </Box>
                      </div>
                    </Col> */}
                  </div>
                </Grid>
                <Grid item sm={5} md={5} lg={4}
                  className="hidden_percentage"
                // onClick={item.handlePlayList}
                >
                  {/* <Hidden xsDown> */}
                  <LineChart
                    seven_days={item?.attributes?.seven_days_percentage}
                    twenty_four_days ={item?.attributes?.last_twenty_four_hours_percentage} 
                    seven_days_graph ={item.attributes?.playlist_last_seven_days_graph_data}
                    fullscreen={expandMore}
                    onFullScreen={() => this.onFullScreen(item)}
                     />
                  {/* </Hidden> */}
                </Grid>
              </Grid>
            </Col>
          );


        })
      :
      (
        <div style={{color: 'white',display: 'flex', justifyContent: 'center', fontSize: '18px', marginTop: '50px'}}>{`No ${musicButton ? 'Video' : 'Audio'} Playlists`}</div>
      )  
      }
      </Box>
    </Box>
  );
      }
}
LabelTopSongs.contextType = AudioContextApi;
// Customizable Area End