// Customizable Area Start
import { Content } from 'antd/lib/layout/layout'
import { Layout, Typography, Tabs, Carousel, Row } from 'antd';
import {
  Box,
  Hidden,
  LinearProgress,
  createStyles,
  styled,
  withStyles,
  Theme,
} from "@material-ui/core";
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import AudioVideoController, { activeTab, IDataList, TopArtistType } from './AudioVideoController';
import LabelHistoryAudioVideo from './LabelHistoryAudioVideo.web'
import { defaultProfile, nxzloader } from 'blocks/dashboard/src/assets';
import MobileAudiovideo from './MobileAudiovideo.web';
import { bluecheck } from '../assets';

export const settings = {

  autoplay: true,
  dots: false,
  infinite: true,
  speed: 100,
  slidesToShow: 7,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1770,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1566,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1492,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1393,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1295,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1255,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1180,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1094,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1038,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 895,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false
      }
    },
    {
      breakpoint: 878,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 790,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 735,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 604,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 545,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 514,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 455,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 424,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 406,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 376,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 353,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 326,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 308,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    }
  ]

};
const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: '#212121',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#3959f9',
    },
  }),
)(LinearProgress);
const LeftSideSingletrackbox = styled(Box)({
  height: "81px",
  borderRadius: "8px",
  cursor: "pointer",
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
  alignItems: "center",
  objectFit: "contain",
  color: "white",
});

const LableAudiohistorycontainer = styled(Box)({
  display: 'flex',
  width: "100%",
  justifyContent: "space-between",
  paddingTop: "20px",

  "@media(max-width: 1000px)": {
    flexDirection: "column"
  },
});
const Carasouelmainbox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  border: "1px solid #141414",
  background: "#090909",
  padding: "15px",

  "@media(max-width: 950px)": {
    paddingLeft: "15px"
  },
});
const LeftsideAudiohistorycontainer = styled(Box)({
  width: "48%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  "@media(max-width: 1000px)": {
    padding: "0px 10px 0px 10px",
    width: "100%"
  },
});

const Trackname = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "16.94px",
  letterSpacing: "normal",
  whiteSpace: "nowrap" as "nowrap",
  color: "#ffffff",
  fontFamily: "Inter",

});


export default class AudioVideo extends AudioVideoController {
  renderLoading = () => {
    return (
      <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
        <img src={nxzloader} style={{ width: "5%" }} />
      </Row>
    )
  }

  renderMobileViewScreen = () => {
    const commonProps = {
      webStyle: webStyle,
      rightsideSelectedtab: this.state.rightsideSelectedtab,
      handleAudiovideotab: (tab: string) => this.handleAudiovideotab(tab),
      usertype: this.state.usertype,
      isLoading: this.state.isAudioVideoLoading,
      tracklistData: this.state.tracklistData,
      isListener: this.state.isListener,
      selectedStreamedTrack: this.state.selectedStreamedTrack,
      onBack: this.onBack,
      streamedArtist: this.state.streamedArtist,
      getCountValue: (data: IDataList) => this.getCountValue(data),
      id: 2
    };
    return (
      this.state.showmobileview ?
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <MobileAudiovideo
            {...commonProps}
          />
        </Hidden> : ""
    )
  }

  renderActiveDeals = () => {
    return (
      <Carasouelmainbox >
        <Box >
          <Typography style={{
            fontFamily: "Inter",
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "30px",
            color: "#F0F0F5"
          }}>Active Deals</Typography>
          <Typography style={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "30px",
            textAlign: "left",
            color: "#8F92A1"
          }}>Creatives I have deals with</Typography>
        </Box>
        <Box>
          <Carousel

            {...settings}
            infinite={false}
          >
            {this.state.top40ArtistList.length > 0 ?
              this.state.top40ArtistList.map((item: TopArtistType, index) => (
                <Box
                  onClick={() => this.handleOnClickLabelCreative(item)}
                  data-test-id="top40ArtistId"
                >
                  <div
                    style={{
                      width: "200px",
                      height: '270px',
                      background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
                      borderRadius: "15px",
                      border: item.id == this.state.artistUserID ? "5px solid white" : "5px solid #212121",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                    }}
                  >
                    <img src={item.attributes.avatar || defaultProfile}
                      className={item.attributes.avatar ? 'stream_history_img' : "stream_history_img_default"}
                    />
                  </div>
                  <div style={{
                    position: "relative",
                    bottom: "53px",
                    left: "16px"
                  }}>
                    <p style={webStyle.carasouelimagetextupper}>{item.attributes.user_name}</p>
                    <p style={webStyle.carasouelimagelowertext}>{item.attributes.role}</p>
                  </div>

                </Box>

              )) :
              <div style={{
                ...webStyle.noStreamHistory, display: "flex",
                justifyContent: "center",
                paddingTop: "113px"
              }}>
                No Stream History Available
              </div>
            }
          </Carousel>
        </Box>
      </Carasouelmainbox>
    )
  }

  renderTabs() {
    return (
      <Box>
        <div className="chats-header">
          <Tabs
            className='Streamrepost_custom_tab'
            defaultActiveKey="0"
            tabPosition="top"
            onChange={this.handleTabChange}
            items={this.getLast12MonthsData().map((item: activeTab, index: any) => {
              const id = String(index);
              return {
                label: item.title,
                key: id,
                disabled: index === 12,
              };
            })}

          />
        </div>

      </Box>
    )
  }

  renderListener() {
    if (this.state.isLabelTabsLoading) {
      return (

        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }} id="tabs-changes">
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      )
    } else {
      return (
        <Box style={{ paddingBottom: "5px" }}>
          {this.state.listData.length > 0 ?
            this.state.listData.map((item: IDataList, index: number) => {
              return (
                <LeftSideSingletrackbox
                  style={this.state.selectedStreamId == item.id ? { background: "#141414" } : {}}
                  onClick={() => this.selectedTrackListener(item, index)}
                  key={index}
                  data-test-id="leftSideSingleTrack"
                >
                  <Box >
                    <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                      <span>{index + 1}</span>
                      <div style={webStyle.labelhistoryleftsideimage} >
                        <img
                          src={item.attributes.avatar || defaultProfile}
                          className={item.attributes.avatar ? "analytics-avatar-listener" : "default-profile-icon-analytics"}

                        />
                      </div>
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box >
                        <Trackname className="tracks_songs_text">
                          {item.attributes.user_name}
                          {item.attributes.is_verified && (
                            <span className="Jason-Derulo">

                              <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                            </span>
                          )}
                        </Trackname>
                        <Box>
                          <span style={webStyle.lablehistorysplittext}>
                            Streams

                          </span>
                        </Box>
                      </Box>
                      <Box>
                        <Box style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center"
                        }}>
                          <span style={webStyle.labelhistorysplittextwhite}>
                            {this.getCountValue(item)}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <BorderLinearProgress variant="determinate" value={this.getLinearProgress(item, this.state.topListenersData)} data-test-id="border-linear-progress" />
                    </Box>
                  </Box>

                </LeftSideSingletrackbox>
              )
            }) :
            <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center", paddingTop: "100px" }}>
              No Data Found
            </div>
          }
        </Box>
      )
    }
  }

  renderArtistList() {
    const { isPlaylist } = this.context;
    if (this.state.top40ArtistList.length > 0) {
      return (
        <Box style={{ paddingTop: "10px", height: isPlaylist ? "75vh" : "90vh", overflowY: "scroll" }} >
          {this.renderActiveDeals()}
          <Box>
            <LableAudiohistorycontainer  >
              <LeftsideAudiohistorycontainer className={this.state.showmobileview ? "mobileviewhide" : 'mobileshow'} >
                <Box>

                  <Typography style={webStyle.trackname}>
                    {" "}
                    <span style={webStyle.bluetext}>
                      TOP {this.state.listData.length}:
                    </span>{" "}
                    Listeners
                  </Typography>
                </Box>
                {this.renderTabs()}
                {this.renderListener()}
              </LeftsideAudiohistorycontainer>
              <Hidden only={["xs"]}>
                <LabelHistoryAudioVideo
                  webStyle={webStyle}
                  rightsideSelectedtab={this.state.rightsideSelectedtab}
                  handleAudiovideotab={(tab: string) => this.handleAudiovideotab(tab)}
                  usertype={this.state.usertype}
                  isLoading={this.state.isAudioVideoLoading}
                  tracklistData={this.state.tracklistData}
                  isListener={this.state.isListener}
                  selectedStreamedTrack={this.state.selectedStreamedTrack}
                  onBack={this.onBack}
                  streamedArtist={this.state.streamedArtist}
                  id={4}
                  getCountValue={(data: IDataList) => this.getCountValue(data)}
                />
              </Hidden>
              {this.renderMobileViewScreen()}
              <Box>
              </Box>
            </LableAudiohistorycontainer>
          </Box>
        </Box>
      )
    } else {
      return (
        <div style={{
          ...webStyle.noStreamHistory, display: "flex",
          justifyContent: "center",
          paddingTop: "113px"
        }}>
          No Repost History Available
        </div>
      )
    }
  }
  render() {
    return (
      <>
        <Layout className='streamRepostWrapper'>
          <Hidden smDown>
            <AsideLeft />
          </Hidden>
          <Content
            style={{
              margin: "0px",
              backgroundColor: "black",
              display: "flex", flexDirection: "column",

            }}
          >
            <HomeHeader location="RepostHistory" redirect={this.redirectToProfile} />
            {this.state.isLabelStreamLoading ? (
              this.renderLoading()
            ) :
              this.renderArtistList()
            }
          </Content>
        </Layout>
      </>

    )
  }
}
const webStyle = {
  RightSidebuttonBox: {
    width: "100%"
  },
  LabelRightsidetabsbutton: {
    borderRadius: "30px",
    background: "#070707",
    display: "flex",
    justifyContent: "space-around",
    padding: "2px 0 2px 0",
    border: "3px solid #212121",
  },
  labelhistorytabbox: {
    width: "50%",
    display: 'flex',
    justifyContent: 'center'
  },

  labelhistoryleftimage: {
    borderRadius: "10px",
    width: "75px",
    minWidth: "75px",
    border: "1px solid #141414",
    height: "75px",
  },
  labelhistoryleftsideimage: {
    borderRadius: "60px",
    minWidth: "75px",
    height: "75px",
    border: "2px solid #FFFFFF",
    width: "75px",
    position: "relative" as "relative"
  },
  labelhistoryleftvideoimage: {
    borderRadius: "10px",
    width: "120px",
    minWidth: "120px",
    height: "75px",
    border: "1px solid #141414"
  },

  labelhistorysingleArtinfo: {
    display: "flex",
    gap: "15px",
    justifyContent: "space-between",

  },

  leftsideAlltracksbox: {
    width: "85%",

  },
  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
  },

  bluetext: {
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
  },

  lablehistorysplittext: {
    fontSize: "14px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "18px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontFamily: "Inter",
  },
  carasouelimagetextupper: {
    fontFamily: "Inter",
    fontsize: "14px",
    fontweight: 800,
    lineHeight: "16.94px",
    color: "#FFFFFF"
  },
  carasouelimagelowertext: {
    fontFamily: "Inter",
    fontsize: "12px",
    fontweight: 600,
    lineHeight: "14.92px",
    color: "#8F92A1"
  },
  labelhistorysplittextwhite: {
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "18px",
    letterSpacing: "normal",
    fontSize: "14px",
    fontWeight: 700,
    color: "#F0F0F5",
    fontFamily: "Inter",
  },

  leftSideSingletrackbox: {
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    justifyContent: "space-between",
    alignitems: "center",
    color: "white",
    background: "#141414",
    display: "flex",
  },
  rightSideSingletrackbox: {
    marginTop: "12px",
    height: "54px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 14px 0px 5px",
    borderRadius: "8px",
    alignitems: "center",
    color: "white",
    background: "#141414",
  },
  labelhistoryleftsideownerstats: {
    gap: "3px",
    display: "flex",
    flexDirection: "column" as "column",
  },
  tabaudiovideobutton: {
    fontSize: "16px",
    fontWeight: 800,
    color: "#ffff",
    borderRadius: "60px",
    width: "100%",
    textTransform: "capitalize" as "capitalize",
  },

  tabactiveaudiovideobutton: {
    fontSize: "16px",
    fontWeight: 800,
    color: "#ffff",
    borderRadius: "60px",
    width: "100%",
    background: "#274af4",
    textTransform: "capitalize" as "capitalize",
  },
  noStreamHistory: {
    fontSize: "20px",
    fontWeight: 800,
    color: "white",
    fontFamily: "Inter",
    display: "flex",
    justifyContent: "center",
    height: "90vh",
    alignItems: "center",

  },
  artistUserName: {
    color: "white",
    fontWeight: 800,
    fontSize: "14px",
    maxWidth: "200px",
    overflow: "hidden",
    display: "inlineBlock",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap"

  }
};
AudioVideo.contextType = AudioContextApi;
// Customizable Area End
