// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
type userID = string

type ResponseJson = {
    link_token: string;
    value: boolean;
    message?: string;
}

interface PlaidIntitutionIdResponse {
    "data": [
        {
            "id": string,
            "type": string,
            "attributes": {
                "id": number,
                "plaid_account_id": string,
                "institutions": [{
                    "institution_id": string
                }],
                "created_at": string,
                "updated_at": string
            }
        }
    ],
    "error": string
}

interface CheckBalanceResponse {
    accounts: {
        0: {
            balances: {
                available: number
            }
        }
    }
}

interface PlaidSuccess {
    institution_id: string,
    plaid_id: string
}

interface AccessTokenResponse {
    plaid_access_id: string;
    error: string
}

interface InstitutionResponse {
    error: "An error occured"
}

interface CreatePublicTokenResponse {
    "public_token": "123"
}


type UserId = string | null | number;

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    selectedUser: any;
    dealAmount: number;
    amountValid: boolean;
    linkToken: string;
    isAccountLinkedToPlaid: boolean;
    availableAccountBalance: number;
    plaid_accountId: string;
    plaid_access_id: string;
    plaidSuccessObj: PlaidSuccess
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DealAmountController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    dealnegotiatingid: string = ""
    getCreateLink: string = "";
    addPlaidAccount: string = "";
    checkLinkAccountToPlaid: string = "";
    checkBankAccountApi: string = "";
    getAccessTokenApiCallId: string = "";
    apiInstitutionIDApiCall: string = "";
    apiInstitutionIDCall: string = "";
    getCreatePublicTokenApiCall: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            selectedUser: {
                id: 1,
                name: 'yfrs',
                type: 'Producer',
                equity: 60
            },
            dealAmount: 0,
            amountValid: true,
            linkToken: '',
            isAccountLinkedToPlaid: false,
            availableAccountBalance: 0,
            plaid_accountId: "",
            plaid_access_id: "",
            plaidSuccessObj: {
                institution_id: "",
                plaid_id: ""
            }
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start


    onAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            dealAmount: Number(e.target.value)
        })
    }

    onNextClick = async () => {
        let availableData = await getStorageData('availableBalance') ?? 0
        const data = await getStorageData('splitType');
        if (this.state.dealAmount <= 0) {
            openNotification("Enter amount", 'Error')
        }
        else if (data == "negotiate") {
            this.dealnegotiating()
        }
        else if (this.state.dealAmount > availableData) {
            openNotification("You cannot enter an amount more than your available balance", "Error")
            this.setState({ dealAmount: 0 })
        } else {
            await setStorageData('dealAmount', this.state.dealAmount)
            HISTORY.push({
                pathname: `/deal/select-deal-term`,
                state: {}
            });
        }
    }


    isLinkOpen = () => {
        if (this.state.dealAmount <= 0) {
            openNotification("Enter amount", 'Error')
            return false
        } else {
            return true
        }
    }


    onBackClick = () => {
        HISTORY.goBack();
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const user_id: UserId = await localStorage.getItem('user_id');
        await this._createLinkTokenAPICall();
        await this._checkLinkAccountToPlaidAPICall(user_id)
        const user = await getStorageData('selectedUser')
        if (user) {
            this.setState({
                selectedUser: user,
            })
        }
        else {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
        if (prevState.isAccountLinkedToPlaid !== this.state.isAccountLinkedToPlaid) {
            const user_id: string | null | number = await localStorage.getItem('user_id');
            await this._createLinkTokenAPICall();
            await this._checkLinkAccountToPlaidAPICall(user_id)
        }
    }
    handleAddPlaidResponse = async (responseJson: ResponseJson) => {
        if (responseJson.message) {
            this._add_inst_id_inDB(this.state.plaidSuccessObj.institution_id, this.state.plaidSuccessObj.plaid_id)
        }
    }

    handleCheckList = async (responseJson: ResponseJson) => {
        if (responseJson.link_token) {
            this.setState({ linkToken: responseJson.link_token })
        } else if (responseJson.message) {
            return
        } else {
            openNotification("Error occurred while fetching the link token,Please try it after a while.", "Error")
        }
    }

    handleCheckAccountToPlaid = (responseJson: ResponseJson) => {
        if (responseJson.value) {
            this.setState({ isAccountLinkedToPlaid: true })
            this._getInstIDFromDB();
        }

        else {
            this.setState({ isAccountLinkedToPlaid: false })

        }
    }
    handleCheckBalanceAccount = (responseJson: CheckBalanceResponse) => {
        let availableBalance = responseJson.accounts[0].balances.available
        if (availableBalance === null || availableBalance === 0) {
            openNotification('Insufficient Balance', "Error")
        } else {
            this.setState({ availableAccountBalance: availableBalance })
            setStorageData('availableBalance', availableBalance)
        }
    }

    handleAccessToken = (responseJson: AccessTokenResponse) => {
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ plaid_access_id: responseJson.plaid_access_id })
            this._checkBankAccountAmountAPICall(responseJson.plaid_access_id)
        }
    }

    handleInstitutionCall = (responseJson: InstitutionResponse) => {
        if (responseJson !== undefined && !responseJson.error) {
            this._getInstIDFromDB()
        }

    }

    handleInstitutionID = (responseJson: PlaidIntitutionIdResponse) => {
        if (responseJson !== undefined && !responseJson.error) {
            let inst_id = responseJson.data[0]?.attributes?.institutions[0]?.institution_id;
            this._createPublicTokenAPICall(inst_id)
        }
    }

    handleCreatePublicToken = (responseJson: CreatePublicTokenResponse) => {
        this._getAccessTokenAPICall(responseJson?.public_token);
    }

    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )
            if (apiRequestCallId === this.dealnegotiatingid) {
                if (responseJson?.message) {
                    openNotification(responseJson?.message, "success")
                    HISTORY.push({
                        pathname: `/deal/offer-sent`,
                        state: {}
                    });
                }

                else {
                    openNotification(responseJson?.error, "error")
                }

            } else if (apiRequestCallId === this.getCreateLink) {
                this.handleCheckList(responseJson)
            } else if (apiRequestCallId === this.addPlaidAccount) {
                this.handleAddPlaidResponse(responseJson)
            }
            else if (apiRequestCallId === this.checkLinkAccountToPlaid) {
                this.handleCheckAccountToPlaid(responseJson)
            } else if (apiRequestCallId === this.checkBankAccountApi) {
                this.handleCheckBalanceAccount(responseJson)
            } else if (apiRequestCallId === this.getAccessTokenApiCallId) {
                this.handleAccessToken(responseJson)
            } else if (apiRequestCallId === this.apiInstitutionIDApiCall) {
                this.handleInstitutionCall(responseJson)
            } else if (apiRequestCallId == this.apiInstitutionIDCall) {
                this.handleInstitutionID(responseJson)
            } else if (apiRequestCallId == this.getCreatePublicTokenApiCall) {
                this.handleCreatePublicToken(responseJson)
            }


        }
        // Customizable Area End
    }

    // Customizable Area Start
    dealnegotiating = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': localStorage.getItem('token')
        };
        const item = localStorage.getItem("deal_id");
        const id = item ? JSON.parse(item) : null;
        const data = {
            amount: this.state.dealAmount,

        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.dealnegotiatingid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_subscription_plan/negotiate_deal?amount=${data.amount}&deal_id=${id}&negotiation_state=negotiating`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );



        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    _add_inst_id_inDB = async (inst_id: string, plaid_account_id: string) => {
        let formData = new FormData();
        formData.append('institution_id', inst_id);
        formData.append('plaid_account_id', plaid_account_id);
        const header = {
            token: await localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiInstitutionIDApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_payments/create_plaid_institution`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    _checkLinkAccountToPlaidAPICall = async (accountId: string | number | null) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.checkLinkAccountToPlaid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_payments/check_account_link?account_id=${accountId}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    _checkBankAccountAmountAPICall = async (plaid_access_id: userID) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.checkBankAccountApi = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_payments/fetch_plaid_account_balance?access_token=${plaid_access_id}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    _getInstIDFromDB = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiInstitutionIDCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_payments/plaid_payments`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }


    _getAccessTokenAPICall = async (publicToken: userID) => {
        this.setState({ isAccountLinkedToPlaid: true })
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAccessTokenApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_payments/get_access_token`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ "public_token": publicToken })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    _addPlaidAccountAPICall = async (plaid_acc_id: userID, inst_id: string) => {
        this.setState({
            plaidSuccessObj: {
                institution_id: inst_id,
                plaid_id: plaid_acc_id
            }
        })
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addPlaidAccount = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_payments/add_plaid_account?plaid_account_id=${plaid_acc_id}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({})
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    _createLinkTokenAPICall = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCreateLink = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_payments/create_link_token`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({})
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    _createPublicTokenAPICall = async (institution_id: string) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': localStorage.getItem('token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCreatePublicTokenApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_payments/create_public_token?institution_id=${institution_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );



        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    accountLinkedState = () => {
        this.setState({ isAccountLinkedToPlaid: !this.state.isAccountLinkedToPlaid })
    };
    // Customizable Area End
}
