// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import React from "react";
import { styled } from "@material-ui/styles";
import TrackDetails from "../TrackDetails.web";
import RightSideTrackController from "./RightSideTrackController";
import { Carousel } from "antd";

const MobileTracksboxRightsidecontainer = styled(Box)({
    width: "47%",
    gap: "5%",
    height: "50vh",
    alignItems: "center",
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        marginTop: " 5%",
        gap: "20px",

    },
});
const MobileTrackBoxfordtails = styled(Box)({
    width: "47%",
    // height: "50vh",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "15px",
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        marginTop: " 5%",
        gap: "20px",

    },
});



const MobileTracksboxRightside = styled(Box)({
    width: "fit-content",
    minWidth: "100%",
    flexDirection: "column" as "column",
    display: "flex",

    gap: "15px",
    "@media(max-width: 1024px)": {
        width: "auto",
    },
});

const settings = {
    autoplay: false,
    swipeToSlide: true,
    draggable: true,
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 590,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 601,
            settings: {
                slidesToShow: 2.6,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 514,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },

        {
            breakpoint: 545,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 437,
            settings: {
                slidesToShow: 1.8,
                slidesToScroll: 1
            }
        },

        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 462,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 399,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1
            }
        },

        {
            breakpoint: 404,
            settings: {
                slidesToShow: 1.8,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 326,
            settings: {
                slidesToShow: 1.4,
                slidesToScroll: 1
            }
        },

        {
            breakpoint: 330,
            settings: {
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        },

        {
            breakpoint: 308,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }


    ]

};


export default class MobileRightsidetrack extends RightSideTrackController {
    tracklist = (tracklist: any, topPlayMusic: any, index: any) => {
        return (<>
            {tracklist?.length > 0 ? (
                <MobileTracksboxRightsidecontainer>
                    <MobileTracksboxRightside>
                        {" "}
                        <Box
                            style={{ display: " flex ", justifyContent: "space-between", alignItems: "center" }}
                        >
                            <Typography style={webStyle.trackname}>
                                <span style={webStyle.bluetext}>Top 40</span> {index}
                            </Typography>
                            <Typography style={webStyle.splittext}>This week</Typography>
                        </Box>
                        <Box
                            style={{
                                width: "100%",
                                // display: "flex",
                                // justifyContent: "center",
                            }}
                        >
                            <Carousel
                                {...settings}
                                infinite={false}
                            >
                                {tracklist.map((item: any, index: any) => (
                                    <Box style={{ width: "300px", height: "300px" }}>
                                        <Box
                                            key={item.id || index}
                                            style={{ height: "100%", cursor: "pointer" }}
                                        >
                                            <img
                                                src={item.attributes.art_work}
                                                alt={`Artwork for ${item.attributes.title}`}
                                                style={{ width: "200px", objectFit: "cover", height: "170px", }}
                                                onClick={topPlayMusic.bind(this, item, index, tracklist, "song")}
                                            />
                                            <Typography style={webStyle.audioimagetext}>
                                                {index + 1}. {item.attributes.title}
                                            </Typography>

                                            <Typography style={webStyle.audioartistext}>
                                                {item.attributes.user_role}
                                            </Typography>
                                        </Box>
                                    </Box>

                                ))}
                            </Carousel>
                        </Box>
                    </MobileTracksboxRightside>
                </MobileTracksboxRightsidecontainer>

            ) : (
                <MobileTracksboxRightsidecontainer>
                    <Box
                        style={{ justifyContent: "space-between", display: " flex ", alignItems: "center" }}
                    >
                        <Typography style={webStyle.trackname}>
                            <span style={webStyle.bluetext}>Top 40</span> {index}
                        </Typography>
                        <Typography style={webStyle.splittext} >This week</Typography>
                    </Box>
                    <Typography
                        style={{
                            paddingTop: "127px",
                            marginTop: "10%",
                        }}
                        className="no_data"
                    >
                        Data Not Found.
                    </Typography>
                </MobileTracksboxRightsidecontainer>

            )}
        </>)
    }
    render() {
        const {
            topPlayMusic,
            top40trackList,
            showDetails,
            trackDetailsId,
            getAllSplits,
            index,
        } = this.props;
        return (
            <>

                {showDetails ? (
                    < MobileTrackBoxfordtails>
                        <TrackDetails
                            navigation={undefined}
                            id={""}
                            trackDetails={trackDetailsId}
                            updateData={() => getAllSplits(index)}
                            topPlayMusic={topPlayMusic}
                            index={index}
                            setShowDetails={this.props.setShowDetails}

                        />
                    </ MobileTrackBoxfordtails>

                ) : (
                    <>

                        {this.tracklist(top40trackList, topPlayMusic, index)}

                    </>
                )}

            </>
        );
    }
}

const webStyle = {
    audioimagetext: {
        color: "#F0F0F5",
        fontWeight: 700,
        fontSize: "14px",
        textOverflow: "ellipsis",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap" as 'nowrap',
        fontFamily: "Karla",
        lineHeight: "20px",
        marginTop: "8px",
        maxWidth: "150px",
    },

    audioartistext: {
        color: "#8f92a1",
        fontFamily: "Karla",
        lineHeight: "20px",
        fontWeight: 700,
        fontSize: "14px",
    },

    trackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
        lineHeight: "1.33",

    },
    statstab: {
        fontSize: "16px",
        fontWeight: 800,
        textTransform: "capitalize" as "capitalize",
        lineHeight: "1.36",
        color: "#ffff",
        borderRadius: "35px",
        fontFamily: "Inter",
        width: "225px",
    },

    bluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        lineHeight: "1.29",
        letterSpacing: "normal",
        color: "#3959f9",
        fontStyle: "normal",
        fontWeight: 700,
    },

    splittext: {
        fontSize: "16px",
        fontWeight: 700,
        letterSpacing: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "20px",
        color: "#8f92a1",
        fontFamily: "Inter",
    },

    tracksimage: {
        height: "148px",
        width: "148px",
        borderRadius: "12px",
    },
};
// Customizable Area End
