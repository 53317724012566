export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const userlog0 = require("../assets/Video User Avatar.png");
export const bluecheck =require("../assets/blueCheck.png")
export const videoimage =require("../assets/videoimage.png")
export const RepostHistoryimage=require('../assets/RepostImage.png')
export const carsouelimage=require("../assets/carasouelimage.png")
export const listnerimage = require("../assets/Listenerimage.png")
export const album =require("../assets/album 1.png")
export const backarrow =require("../assets/VectorBack.png")
export const Unlock=require("../assets/Unlock.png")
export const L1frame=require("../assets/L1frame.png")
export const L2frame=require("../assets/L2Frame.png")
export const confirmation=require("../assets/Confirmation.png")