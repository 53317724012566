export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const nextA = require('../assets/nextButton.png');
export const nextBlue = require('../assets/next-Blue.png');
export const pauseA = require('../assets/Pause.png');
export const prevA = require('../assets/previous.png');
export const previousBlue = require('../assets/Previous-Blue.png');
export const queueA = require('../assets/queueButton.png');
export const repeatA = require('../assets/repeat.webp');
export const shuffleA = require('../assets/shuffleButton.png');
export const volumeA = require('../assets/volumeButton.png');
export const repeatBlue = require('../assets/Repeat-Blue.png');