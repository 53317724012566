// Customizable Area Start
import React from "react";
import SplitPercentageController, {
    Props,
} from "./SplitPercentageController.web";
import "./SplitPercentage.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from "../Common/RightPanel.web";
import AudioVideoCard from "../Common/AudioVideoCard.web";
import UserCard from "../Common/UserCard.web";
import SelectSplitPercentage from './SelectSplitPercentage.web'
import { SelectSplitPercentageRightBg } from "../assets";
import { AudioContextApi } from '../../AudioContext/AudioContext.web'
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class SplitPercentage extends SplitPercentageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, splitType, selectedMedia, selectedUser, selectedPercentage } = this.state;
        const { isPlaylist } = this.context;
        console.log("selectedd", selectedUser)
        return (
            <>
                <Layout className="MainLayoutContainerSP">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContentSP"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="SPContent" style={{ height: isPlaylist ? '75vh' : '90vh' }}>
                                <Grid container className='SPMainContentDiv'>
                                    <Grid item xs={12} sm={12} md={6} lg={7} className='ColumnCenter'>
                                        <div className='ColumnCenter AICenter'
                                        >
                                            {/* Media Detail Start*/}
                                            {
                                                splitType !== 'user' &&
                                                < AudioVideoCard
                                                    media={selectedMedia}
                                                    type={splitType}
                                                    onPlay={() => { }} />

                                            }
                                            {/* Media Detail End*/}

                                            {/* User Detail Start */}
                                            <UserCard
                                                user={selectedUser}
                                                selected={true}
                                                clickable={false} />
                                            {/* User Detail End */}
                                            <Hidden only={['lg', 'xl']}>
                                                <div className="ColumnCenter AICenter InfoTextContainerSm">
                                                    <div className='CommonText InfoTextSm'>Enter Split Percentage You</div>
                                                    <p className='CommonText InfoTextSm'>Want To Buy From User.</p>
                                                </div>
                                            </Hidden>
                                            {/* Select Split Percentage */}
                                            <SelectSplitPercentage
                                                maxPercentage={Number(selectedUser?.percentage || selectedUser[0]?.percentage)}
                                                selectedPercentage={selectedPercentage}
                                                setSelectedPercentage={this.onPercentageChange}
                                            />
                                            {/* Select Split Percentage End */}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={5}>
                                        <RightPanel
                                            line1={`Enter Split You Want`}
                                            line2={`To Buy From owner`}
                                            backgroundImage={SelectSplitPercentageRightBg}
                                            onNextClick={this.onNextClick}
                                            onBackClick={this.onBackClick} isPlaid={false} linkToken={""}
                                            onSucessCallApi={(data: string, accountId: string) => { alert(`Data:${data}${accountId}`) }}
                                            isLinkOpen={() => { return false }} />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default SplitPercentage;
SplitPercentage.contextType = AudioContextApi;
// Customizable Area End